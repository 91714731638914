import React, { useState } from 'react';
import SvgIcon from '../SvgIcon/SvgIcon';
import { CloseLineIcon, Search2LineIcon } from '../Icons';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

SearchBar.proopTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    state: PropTypes.oneOf(['default', 'disabled']),
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    withSubmitIcon: PropTypes.bool,
    withClearIcon: PropTypes.bool,
    showClearIconIfValueIsNotEmpty: PropTypes.bool, // by default Clear Icon is shown after submit. If clear Icon should be shown always when the value is not empty add also prop showClearIconIfValueIsNotEmpty = true
    tipText: PropTypes.string,
    autoFocus: PropTypes.bool,
};

function SearchBar({
    value,
    placeholder,
    size = 'sm',
    state = 'default',
    onChange,
    onSubmit,
    withSubmitIcon = false,
    withClearIcon = false,
    showClearIconIfValueIsNotEmpty = false,
    onClear,
    tipText,
    autoFocus = false,
}) {
    const [isInitial, setIsInitial] = useState(true);

    const handleChange = (e) => {
        setIsInitial(false);
        onChange(e);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsInitial(true);
        onSubmit(e);
    };

    const inputSize = {
        xs: `py-1.5 pl-[36px] text-body-regular-s ${
            value && (withClearIcon || withSubmitIcon) ? 'pr-[36px]' : 'pr-3'
        }`,
        sm: `py-2 pl-[36px] text-body-regular-s ${
            value && (withClearIcon || withSubmitIcon) ? 'pr-[36px]' : 'pr-3'
        }`,
        md: `py-[9px] pl-[46px] text-body-regular-m ${
            value && (withClearIcon || withSubmitIcon) ? 'pr-[36px]' : 'pr-3'
        }`,
        lg: `py-3 pl-[46px] text-body-regular-m ${
            value && (withClearIcon || withSubmitIcon) ? 'pr-[46px]' : 'pr-3'
        }`,
    };

    const iconSize = {
        xs: 'medium',
        sm: 'medium',
        md: 'large',
        lg: 'large',
    };

    const inputClassName = classNames(
        'w-full bg-white rounded-2 font-body text-neutral-500 placeholder-neutral-300 focus:placeholder-transparent caret-neutral-500 border-1 focus:outline-0 focus:border-1 focus:shadow-l3 border-neutral-300 focus:shadow-neutral-300 transition-all ease-in',
        inputSize[size]
    );
    return (
        <form className="w-full" onSubmit={handleSubmit}>
            <div className="w-full relative">
                <input
                    className={inputClassName}
                    type="text"
                    value={value}
                    disabled={state === 'disabled'}
                    placeholder={placeholder || 'Search'}
                    onChange={handleChange}
                    autoFocus={autoFocus}
                />
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                    <SvgIcon color="#000000" icon={Search2LineIcon} size={iconSize[size]} />
                </span>
                {value && withSubmitIcon && !isInitial && (
                    <span
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        onClick={handleSubmit}
                    >
                        <SvgIcon color="#000000" icon={ArrowRightIcon} size={iconSize[size]} />
                    </span>
                )}
                {value && withClearIcon && (showClearIconIfValueIsNotEmpty || isInitial) && (
                    <span
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        onClick={onClear}
                    >
                        <SvgIcon color="#000000" icon={CloseLineIcon} size={iconSize[size]} />
                    </span>
                )}
            </div>
            {tipText && (
                <p className="font-body text-body-regular-xs text-[#A0A2A5] mt-1">{tipText}</p>
            )}
        </form>
    );
}

export default SearchBar;
