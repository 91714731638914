import React from 'react';
import classNames from 'classnames';

const CALLOUT_COLORS = ['#5E6470', '#17B4E5', '#754DCF', '#D9E56E', '#F2AA85'];

const CalloutColorPicker = ({ selectedColor, handleColorChange }) => {
    return (
        <div className="flex flex-row xs:flex-col gap-0.5 p-2 rounded-2 border-1 border-neutral-200 shadow-l1 bg-white">
            {CALLOUT_COLORS.map((color) => {
                const isCurrentColorSelected = color === selectedColor;
                const borderStyle = isCurrentColorSelected ? 'border-neutral-200' : 'border-white';

                return (
                    <label
                        key={color}
                        className={classNames(
                            'w-6 h-6 min-w-[24px] rounded-[5px] border-2 pointer transition-colors cursor-pointer',
                            borderStyle
                        )}
                        style={{ backgroundColor: color }}
                    >
                        <input
                            type="radio"
                            name="callout_color"
                            value={color}
                            checked={isCurrentColorSelected}
                            onChange={handleColorChange}
                            style={{ display: 'none' }}
                        />
                    </label>
                );
            })}
        </div>
    );
};

export default CalloutColorPicker;
