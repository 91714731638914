import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Alert from '../../../design-system/Alert/Alert';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { Input } from '../../../design-system';

const InlineEditableField = ({
    value,
    isEditing,
    stopEditing,
    onSave,
    textStyles = 'font-body text-body-regular-m text-neutral-500',
    shouldEditInPopup = false,
    popupTitle = '',
}) => {
    const [editingValue, setEditingValue] = useState(value);
    const [errorAlert, setErrorAlert] = useState(null);

    useEffect(() => {
        setEditingValue(value);
    }, [value]);

    const handleSaveChanges = async (e) => {
        e?.preventDefault();
        if (!editingValue) {
            setErrorAlert({ message: 'This can not be an empty field.' });
            return;
        }

        stopEditing();

        if (value === editingValue) {
            return;
        }

        try {
            await onSave(editingValue);
        } catch (e) {
            setEditingValue(value);
        }
    };

    const containerClassName = classNames('p-1 rounded-2 w-full min-h-[24px]', {
        'bg-transparent truncate': !isEditing,
        'bg-neutral-50': isEditing,
    });

    const inputClassNames = classNames(
        'p-1 border-1 rounded-sm w-full focus:outline-0 bg-transparent',
        textStyles,
        {
            'border-transparent truncate': !isEditing,
            'border-purple-300': isEditing,
        }
    );

    const handleClosePopup = () => {
        setEditingValue(value);
        stopEditing();
    };

    return (
        <form className={containerClassName} onSubmit={handleSaveChanges}>
            {(!isEditing || shouldEditInPopup) && (
                <p className={inputClassNames}>{shouldEditInPopup ? value : editingValue}</p>
            )}

            {isEditing && !shouldEditInPopup && (
                <input
                    value={editingValue}
                    onChange={(e) => setEditingValue(e.target.value)}
                    onBlur={handleSaveChanges}
                    className={inputClassNames}
                    autoFocus
                />
            )}

            {isEditing && shouldEditInPopup && (
                <MobilePopup
                    onClose={handleClosePopup}
                    title={popupTitle}
                    withSaveButton
                    closeButtonText="Cancel"
                    onSave={handleSaveChanges}
                >
                    <Input
                        name="name"
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        autoFocus
                    />
                </MobilePopup>
            )}

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </form>
    );
};

export default InlineEditableField;
