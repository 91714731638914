import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import * as API from '../../../constants/api';
import client from '../../../services/library-api';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import UnconfirmedProjectBlock from './UnconfirmedProjectBlock/UnconfirmedProjectBlock';
import ConfirmedProjectBlock from './ConfirmedProjectBlock/ConfirmedProjectBlock';

const ProjectBlock = ({
    blockData,
    editMode = false,
    handleDeleteBlock,
    handleBlockPropertyChange,
    projectDetails,
    setConnectedProjectsData,
}) => {
    const {
        properties: { scenario_id, label },
    } = blockData;
    const { playbookId } = useParams();

    const [isConfirmedMode, setIsConfirmedMode] = useState(!!scenario_id);

    const projectsOptionHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.scenario,
        searchParams: { playbook: playbookId, is_live: true },
        formatResponseToOptions: (results) =>
            results?.map(({ id, name, description }) => ({ id, name, description })),
    });

    const setProjectDetails = (projectId, cb) => {
        setConnectedProjectsData((prevProjects) => ({
            ...prevProjects,
            [projectId]: cb(prevProjects[projectId]),
        }));
    };

    const handleConfirmProject = ({ project, label }) => {
        handleBlockPropertyChange('scenario_id', project.id);
        handleBlockPropertyChange('label', label);
        setProjectDetails(project.id, () => project);
        setIsConfirmedMode(true);
    };

    const formattedConfirmedAgentData = projectDetails || null;

    return (
        <>
            {isConfirmedMode && (
                <ConfirmedProjectBlock
                    isEditable={editMode}
                    projectId={scenario_id}
                    label={label}
                    projectDetails={projectDetails}
                    setProjectDetails={setProjectDetails}
                    handleDeleteBlock={handleDeleteBlock}
                    setIsConfirmedMode={setIsConfirmedMode}
                />
            )}

            {!isConfirmedMode && (
                <UnconfirmedProjectBlock
                    isEditable={editMode}
                    initiallySelectedProjectData={formattedConfirmedAgentData}
                    initialLabel={formattedConfirmedAgentData ? label : null}
                    projectsOptionHookResponse={projectsOptionHookResponse}
                    handleDeleteBlock={handleDeleteBlock}
                    handleConfirmProject={handleConfirmProject}
                />
            )}
        </>
    );
};

export default ProjectBlock;
