import React from 'react';

const AddLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24">
            <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill={props.color} />
        </svg>
    );
};

export default AddLineIcon;
