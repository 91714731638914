import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useWindowSize } from '../../../hooks/useWindowSize';

import SvgIcon from '../../../design-system/SvgIcon/SvgIcon';
import { Button } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';

const InboxTaskApprovedSnackAlert = ({ data: { subject, threadId }, isExpandedView, onClose }) => {
    const { width: screenWidth } = useWindowSize();
    const isMobile = screenWidth < 768;

    useEffect(() => {
        const id = setTimeout(handleClose, 3000);

        return () => {
            clearTimeout(id);
        };
    }, []);

    const handleViewJobDetail = () => {
        onClose();
        window.open(`/job/${threadId}`, '_blank');
    };

    const handleClose = (e) => {
        e?.stopPropagation();
        onClose();
    };

    const isAlertClickable = isMobile && threadId;

    const containerClassNames = classNames(
        'fixed left-3 right-3 sm:left-auto z-10 max-w-full sm:w-full sm:max-w-[480px] xl:max-w-[500px] rounded-2 p-5 bg-neutral-500 flex flex-col gap-2 transition-all',
        {
            'cursor-pointer': isAlertClickable,
            'bottom-[70px] min-[1160px]:bottom-3': isExpandedView,
            'bottom-3': !isExpandedView,
        }
    );

    return (
        <div
            className={containerClassNames}
            onClick={isAlertClickable ? handleViewJobDetail : () => {}}
        >
            <div className="flex items-center justify-between">
                <p className="font-heading-bold text-[16px] sm:text-heading-bold-s text-white">
                    Inbox Task Approved!
                </p>
                <button type="button" onClick={handleClose} className="p-2">
                    <SvgIcon
                        icon={CloseLineIcon}
                        size={screenWidth >= 640 ? 'large' : 'medium'}
                        color="#FFFFFF"
                    />
                </button>
            </div>

            {isMobile && threadId && (
                <button className="flex gap-1 items-center font-body text-body-regular-s text-white">
                    View Job Details ->
                </button>
            )}

            {!isMobile && (
                <div className="flex flex-col gap-4">
                    <p className="font-body text-body-regular-m text-white">
                        Your task <span className="font-body-bold text-body-bold-m">{subject}</span>{' '}
                        has been successfully approved.
                    </p>
                    {threadId && (
                        <div className="flex justify-end">
                            <Button
                                type="secondary"
                                size="sm"
                                text="View Job Details"
                                onClick={handleViewJobDetail}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default InboxTaskApprovedSnackAlert;
