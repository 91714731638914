import React from 'react';

import JobSubmissionDetails from '../JobSubmissionDetails/JobSubmissionDetails';
import TriggeredTasksDetails from '../TriggeredTasksDetails/TriggeredTasksDetails';
import DoNextSection from '../DoNextSection/DoNextSection';

const ExistingJobDetails = ({ jobData, isExpanded, toggleSectionExpand, mappedDocsLabels }) => {
    return (
        <div className="flex flex-col gap-0 sm:gap-3 md:gap-6">
            <JobSubmissionDetails
                inputs={jobData.inputs}
                agentName={jobData.process.name}
                isExpanded={isExpanded.jobSubmissionDetails}
                toggleSectionExpand={toggleSectionExpand}
                mappedDocsLabels={mappedDocsLabels}
            />
            <TriggeredTasksDetails
                jobData={jobData}
                isExpanded={isExpanded.triggeredTasksDetails}
                toggleSectionExpand={toggleSectionExpand}
            />
            <DoNextSection agentId={jobData.process.id} />
        </div>
    );
};

export default ExistingJobDetails;
