import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useSwrRequestKeysToMutateFromContext } from '../../../hooks/useSwrRequestKeysToMutateFromContext';

import Loading from '../../../components/Loading';
import DocDetailTopControls from './DocDetailTopControls/DocDetailTopControls';
import DocDetailMainContent from './DocDetailMainContent/DocDetailMainContent';

const DocDetailPage = () => {
    const { docId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    // const backLinkHref = location.state?.from || '/docs';
    const backLinkHref = '/docs';

    const [docDetail, setDocDetail] = useState(null);

    const {
        requestKeysToMutate: { folderDocs: docsRequestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    useEffect(() => {
        const fetchDocDetail = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.knowledge.document}${docId}/`);
                setDocDetail(data);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        if (docDetail) {
            setDocDetail(null);
        }
        fetchDocDetail();
    }, [docId]);

    useDocumentTitle(docDetail?.label);

    return (
        <div className="page-position bg-white overflow-y-auto py-5 px-4 xs:px-5 sm:p-8 lg:py-9 lg:px-[40px] xl:py-[40px] xl:px-[60px] flex flex-col gap-3 xs:gap-5">
            {docDetail && (
                <>
                    <DocDetailTopControls
                        id={docDetail.id}
                        label={docDetail.label}
                        backLinkHref={backLinkHref}
                        docsRequestKeysToMutate={docsRequestKeysToMutate}
                    />
                    <DocDetailMainContent
                        docDetail={docDetail}
                        setDocDetail={setDocDetail}
                        docsRequestKeysToMutate={docsRequestKeysToMutate}
                    />
                </>
            )}

            {!docDetail && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default DocDetailPage;
