import { useEffect, useRef, useState } from 'react';

export const useModalAutoMinHeight = ({
    maxHeight,
    shouldFixHeight = true,
    dependenciesArray = [],
}) => {
    const modalRef = useRef(null);

    const [modalMinHeight, setModalMinHeight] = useState(0);

    useEffect(() => {
        if (modalRef.current && shouldFixHeight) {
            const modalHeight = modalRef.current.offsetHeight;
            if (modalHeight > modalMinHeight) {
                const newMinHeight = maxHeight ? Math.min(modalHeight, maxHeight) : modalHeight;

                setModalMinHeight(newMinHeight);
            }
        }
    }, [modalRef.current, ...dependenciesArray]);

    return { modalRef, modalMinHeight };
};
