import React from 'react';
import { TAB } from '../../../constants/jobsAndProjects';

const PageHeader = ({ activeTab }) => {
    const title = {
        [TAB.jobs]: 'Jobs',
        [TAB.projects]: 'Projects',
    }[activeTab];

    const subtitle = {
        [TAB.jobs]: 'A comprehensive record of jobs in progress and completed for your business.',
        [TAB.projects]: 'Collections of jobs run together automatically from a Playbook.',
    }[activeTab];

    return (
        <div>
            <h1 className="font-heading-bold text-heading-bold-l text-black">{title}</h1>
            <p className="font-body text-body-regular-xs text-neutral-300">{subtitle}</p>
        </div>
    );
};

export default PageHeader;
