import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import classNames from 'classnames';

import client from '../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { CONTENT_TYPE_LABEL, ROUTE } from '../../../constants/docs';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import {
    getFoldersDataFromPromiseAllSettledResponse,
    mutateCashedDataAfterAction,
    showErrorAlertIfRequestError,
} from '../../../helpers/docsUtils';
import { updateSidePanelStateAfterFoldersInsert } from '../../../helpers/docsSidePanelUtils';

import Alert from '../../../design-system/Alert/Alert';
import Loading from '../../../components/Loading';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';

const SuccessArchiveAlert = ({
    archivedItems,
    parentFolderLocation,
    requestKeysToMutate,
    currentRequestKeyToMutate,
    setErrorAlert,
    handleClose,
}) => {
    const archivedItemsCount = archivedItems?.length;

    const [searchParams] = useSearchParams();
    const isSearch = !!searchParams.get('search');

    const [isLoading, setIsLoading] = useState(false);

    const { setSidePanelFolders } = useDocsPageContext() || {};

    useEffect(() => {
        setIsLoading(false);
    }, [archivedItems]);

    if (!archivedItemsCount) {
        return <></>;
    }

    const isSingleArchivedItem = archivedItemsCount === 1;

    const handleUnarchive = async () => {
        try {
            setIsLoading(true);

            const data = await Promise.allSettled(
                archivedItems.map(({ id, content_type }) => {
                    const route = ROUTE[content_type];
                    return client.patch(`${route}${id}/?is_deleted=true`, { is_deleted: false });
                })
            );

            showErrorAlertIfRequestError({ data, setErrorAlert });

            await mutateCashedDataAfterAction({
                requestKeysToMutate,
                currentRequestKeyToMutate,
                parentLocation: parentFolderLocation,
            });

            const shouldUpdateFoldersInSideBar =
                setSidePanelFolders && parentFolderLocation && !isSearch;
            if (shouldUpdateFoldersInSideBar) {
                const unarchivedFolders = getFoldersDataFromPromiseAllSettledResponse({
                    data,
                    selectedItemsArray: archivedItems,
                });

                updateSidePanelStateAfterFoldersInsert({
                    insertedFolders: unarchivedFolders,
                    parentLocation: parentFolderLocation,
                    setSidePanelFolders,
                });
            }

            handleClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
            handleClose();
        }
    };

    let alertMessage = '';
    if (isSingleArchivedItem) {
        const { label, content_type } = archivedItems[0];
        alertMessage = `${CONTENT_TYPE_LABEL[content_type]} ”${label}” archived`;
    }

    if (!isSingleArchivedItem) {
        alertMessage = `${archivedItemsCount} selected items archived`;
    }

    const buttonClassName = classNames('font-[inherit] text-[inherit] underline', {
        'opacity-0': isLoading,
        'opacity-100': !isLoading,
    });
    const description = (
        <div className="relative">
            <button
                type="button"
                className={buttonClassName}
                disabled={isLoading}
                onClick={handleUnarchive}
            >
                Undo
            </button>

            {isLoading && (
                <div className="absolute top-1/2 transform translate-y-[-50%] left-1">
                    <Loading withText={false} size="extraSmall" />
                </div>
            )}
        </div>
    );

    return (
        <Alert
            status="positive"
            message={alertMessage}
            description={description}
            icon={CheckLineIcon}
            position="top-center"
            autoCloseDisabled={isLoading}
            autoCloseInMS={5000}
            handleClose={handleClose}
        />
    );
};

export default SuccessArchiveAlert;
