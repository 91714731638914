import React, { useState } from 'react';

import { USER_ROLE } from '../../../../constants/organization';
import { capitalizeFirstLetter } from '../../../../services/strings';

import { ArrowDownSLineIcon } from '../../../../design-system/Icons';
import SelectUserRolePopup from '../SelectUserRolePopup/SelectUserRolePopup';

const AvailableMemberCard = ({ data, isOwnerOrAdmin, updateClientData }) => {
    const {
        user: { email },
    } = data;

    const organizationMembership = data['organization-membership'];

    const role = organizationMembership?.role;
    const isEditable = role !== USER_ROLE.owner;

    const formattedRole = capitalizeFirstLetter(role || 'No access');

    const [isPopupOpened, setIsPopupOpened] = useState(false);

    return (
        <li className="max-w-full py-1.5 border-b-1 border-neutral-200 flex items-center gap-3 md:gap-4 justify-between">
            <p className="font-body text-body-regular-s text-neutral-500 flex-1 truncate">
                {email}
            </p>

            <div className="relative">
                {(!isOwnerOrAdmin || !isEditable) && (
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        {formattedRole}
                    </p>
                )}
                {isOwnerOrAdmin && isEditable && (
                    <button
                        className="font-body text-body-regular-xs text-neutral-300 flex items-center gap-1"
                        onClick={() => setIsPopupOpened(true)}
                    >
                        {formattedRole}
                        <ArrowDownSLineIcon width={16} height={16} color="#5E6470" />
                    </button>
                )}

                {isPopupOpened && (
                    <SelectUserRolePopup
                        email={email}
                        organizationMembership={organizationMembership}
                        updateClientData={updateClientData}
                        onClose={() => setIsPopupOpened(false)}
                    />
                )}
            </div>
        </li>
    );
};

export default AvailableMemberCard;
