import React, { useEffect, useRef } from 'react';

import { isFile } from '../../../helpers/fileUtils';

const JobSubmissionDetailItem = ({ label, value }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!ref) return;

        let formattedValue = null;

        if (isFile(value)) {
            formattedValue = 'File';
        } else {
            formattedValue = typeof value === 'object' ? JSON.stringify(value, null, 4) : value;
        }

        ref.current.innerHTML = formattedValue;
    }, [ref, value]);

    return (
        <li>
            <p className="font-body-bold text-body-bold-s">{label}</p>
            <div
                ref={ref}
                className="font-body text-body-regular-s"
                style={{ lineHeight: '150%' }}
            ></div>
        </li>
    );
};

export default JobSubmissionDetailItem;
