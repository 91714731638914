import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { parseJobsSearchParams } from '../../../helpers/parseJobsSearchParams';

import { TAB, TAB_LABEL } from '../../../constants/jobsAndProjects';
import { generalSortOptions, SORT_TYPES } from '../../../constants/sort';

import { Tabs } from '../../../design-system';
import FilterBlock from '../FilterBlock/FilterBlock';
import SortControls from '../../../components/SortControls/SortControls';
import SearchBarWithUrlParamsHandling from '../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';

export const tabs = [TAB.jobs, TAB.projects];
const formattedTabs = tabs.map((tab) => ({ name: TAB_LABEL[tab], key: tab }));

const PageTopControls = ({ activeTab, isMobile, resetPageState }) => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseJobsSearchParams(searchParams), [searchParams]);

    const activeTabIndex = Math.max(tabs.indexOf(activeTab), 0);

    // const handleAddProcessFilterOption = (process) => {
    //     handleAddNewFilterOption({
    //         searchParams,
    //         setSearchParams,
    //         currentFilters: parsedParams.process,
    //         newFilter: process.id,
    //         usePagination: !isMobile,
    //         usePageState: true,
    //         resetPageState,
    //         searchParamKey: activeTab === TAB.jobs ? 'process__in' : 'playbook__in',
    //         shouldSortFilters: true,
    //     });
    // };
    //
    // const handleRemoveProcessFilterOption = (process) => {
    //     handleRemoveFilterOption({
    //         searchParams,
    //         setSearchParams,
    //         currentFilters: parsedParams.process,
    //         filterToDelete: process.id,
    //         usePagination: !isMobile,
    //         usePageState: true,
    //         resetPageState,
    //         searchParamKey: activeTab === TAB.jobs ? 'process__in' : 'playbook__in',
    //     });
    // };

    const handleTabChange = (tab) => {
        resetPageState();

        const URL = isMobile
            ? `/${tab.key}?sort=${SORT_TYPES.created}`
            : `/${tab.key}?page=1&sort=${SORT_TYPES.created}`;
        navigate(URL);
    };

    return (
        <div className="flex flex-col ql:flex-row ql:items-center gap-3 w-full relative justify-between">
            <div>
                <div className="flex items-center gap-4 relative min-w-max">
                    <Tabs
                        tabs={formattedTabs}
                        tabIndex={activeTabIndex}
                        onTabChanged={handleTabChange}
                    />

                    <SortControls
                        sortOptions={generalSortOptions}
                        variant="secondary"
                        usePageState
                        usePagination={!isMobile}
                        resetPageState={resetPageState}
                        dropdownPosition={{ top: '120%', left: 0 }}
                        buttonContainerClassName="min-[680px]:relative"
                    />

                    <FilterBlock
                        activeTab={activeTab}
                        parsedParams={parsedParams}
                        isMobile={isMobile}
                        resetPageState={resetPageState}
                    />
                </div>
            </div>
            <div className="ql:max-w-[300px] w-full">
                <SearchBarWithUrlParamsHandling
                    size="xs"
                    placeholder="Search by keyword"
                    usePagination={!isMobile}
                    resetPageState={resetPageState}
                    usePageState={true}
                />
            </div>
        </div>
    );
};

export default PageTopControls;
