import React from 'react';
import TemplateFileBlock from '../TemplateFileBlock/TemplateFileBlock';

const TemplateMainDetailBlock = ({ templateData }) => {
    return (
        <div className="flex-grow flex flex-col gap-4">
            <TemplateFileBlock
                content={templateData.current_content}
                isLive={templateData.is_live}
                isMainBranch
                editable={false}
            />
        </div>
    );
};

export default TemplateMainDetailBlock;
