import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import * as API from '../../../constants/api';
import libraryClient from '../../../services/library-api';
import knowledgeClient from '../../../services/knowledge-api';

import Modal from '../../../design-system/Modal/Modal';
import { Button, SvgIcon } from '../../../design-system';
import EscapeIcon from '../../../design-system/Icons/EscapeIcon';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';
import { updateContextState } from '../../../helpers/updateContextState';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

const AddContextModal = ({ handleClose, taskId, currentContext, setContext, promptText }) => {
    const [selectedContext, setSelectedContext] = useState(null);
    const [selectedContextError, setSelectedContextError] = useState(false);

    const [contextList, setContextList] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const currentContextIdArr = currentContext.map((context) => context.id);

    const setContextOptions = (prevData, data) => {
        // remove the context elements that are already used in the task
        const filteredData = data.results.filter((item) => !currentContextIdArr.includes(item.id));
        setContextList((prevData) => [...prevData, ...filteredData]);

        const newData = filteredData?.map((item) => ({ id: item.id, name: item.label })) || [];
        return [...prevData, ...newData];
    };

    const {
        options: contextOptions,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions,
    } = useFetchOptionsForPaginatedSelect({
        client: knowledgeClient,
        route: API.ROUTES.knowledge.context,
        searchParams: { ordering: 'key' },
        callback: setContextOptions,
    });

    const handleSelectChange = (id) => {
        setSelectedContext(id);
        if (selectedContextError) {
            setSelectedContextError(false);
        }
    };

    const addNewContext = async () => {
        if (!selectedContext) {
            setSelectedContextError(true);
            return;
        }
        try {
            const requestBody = {
                context: [...currentContextIdArr, selectedContext],
            };
            setIsLoading(true);
            await libraryClient.patch(`${API.ROUTES.library.task}${taskId}/`, requestBody);
            const newContext = contextList.find((item) => item.id === selectedContext);
            if (newContext) {
                const newContextObj = {
                    id: newContext.id,
                    key: newContext.key,
                    label: newContext.label,
                    value: newContext.default_version?.value,
                };
                setContext((prevContext) =>
                    updateContextState([...prevContext, newContextObj], promptText)
                );
            }
            handleClose();
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ show: true, statusCode: e.response.status });
        }
    };

    // used to increase the height of the modal so that the dropdown fits entirely on the screen
    const selectMarginBottom = dropdownOpen && !!contextOptions.length ? 76 : 0;

    return (
        <>
            <Modal onClose={handleClose} size="medium">
                <div className="flex flex-col gap-8">
                    <div className="flex items-center justify-between gap-3">
                        <p className="font-heading-bold text-heading-bold-m text-neutral-400 w-[calc(100%-63px)]">
                            Add Context to Prompt
                        </p>
                        <div onClick={handleClose} className="cursor-pointer">
                            <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle" />
                        </div>
                    </div>
                    <div
                        className="flex flex-col gap-1"
                        style={{
                            marginBottom: `${selectMarginBottom}px`,
                            transition: 'margin 0.2s ease-in-out',
                        }}
                    >
                        <PaginatedSelect
                            size="sm"
                            name="context"
                            options={contextOptions}
                            optionsLoading={optionsLoading}
                            value={selectedContext}
                            isRequired
                            placeholder="Choose a Context"
                            onChange={handleSelectChange}
                            state={selectedContextError ? 'error' : 'default'}
                            errorMessage={'Please select an option'}
                            fetchOptions={() => setPage((page) => page + 1)}
                            canLoadMore={canLoadMoreOptions}
                            dropdownHeight={176}
                            info={
                                <>
                                    Or{' '}
                                    <Link to="/bases" className="underline">
                                        click to create new
                                    </Link>
                                </>
                            }
                            useExternalDropdownState
                            dropdownOpen={dropdownOpen}
                            setDropdownOpen={setDropdownOpen}
                            includeClientSideFiltering
                            totalOptionsCount={totalOptions}
                        />
                    </div>
                    <div className="flex items-center justify-center gap-1 xs:gap-4">
                        <Button type="ghost" size="sm" text="Cancel" onClick={handleClose} />
                        <Button
                            type="primary"
                            size="sm"
                            text="Add Context to Prompt"
                            state={isLoading ? 'loading' : 'default'}
                            onClick={addNewContext}
                        />
                    </div>
                </div>
            </Modal>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </>
    );
};

export default AddContextModal;
