import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';

import { Button } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';

const PlaybookBuilderTopControls = ({ backLinkHref, setPageTopControlsHeight }) => {
    const navigate = useNavigate();

    const containerRef = useRef(null);
    const { width: screenWidth } = useWindowSize();

    useEffect(() => {
        if (containerRef.current) {
            setPageTopControlsHeight(containerRef.current?.clientHeight || 0);
        }
    }, [containerRef.current, screenWidth]);

    return (
        <div ref={containerRef} className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-5">
                <Button
                    type="link"
                    size="xs"
                    text="Back"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(backLinkHref)}
                />
                <p className="font-body text-body-regular-s text-neutral-300">Playbook /</p>
            </div>

            <div id="share-playbook-button"></div>
        </div>
    );
};

export default PlaybookBuilderTopControls;
