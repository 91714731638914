import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import client from '../../../services/library-api';
import { API } from 'constants';
import Modal from '../../../design-system/Modal/Modal';
import { Button, Input } from '../../../design-system';
import { collectionColorOptions } from '../../../constants/collections';
import ColorPicker from '../../../design-system/ColorPicker/ColorPicker';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { defaultErrorMessage } from '../../../constants/errorMessages';

CollectionActionModal.propTypes = {
    action: PropTypes.oneOf(['create', 'edit', 'delete']),
    handleClose: PropTypes.func,
    showAlert: PropTypes.func, // pass alert message as an argument
    setCollections: PropTypes.func,
};

const confirmButtonText = {
    create: 'Add Collection',
    edit: 'Save Collection',
    delete: 'Yes, Delete',
};

const successAlertMessage = {
    create: 'New collection successfully created!',
    edit: 'Collection successfully updated!',
    delete: 'Collection successfully deleted',
};

function CollectionActionModal({
    action = 'create',
    data,
    handleClose,
    showAlert,
    setCollections,
}) {
    const [collectionData, setCollectionData] = useState({
        name: '',
        description: '',
        icon_color: collectionColorOptions[0],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [nameFieldError, setNameFieldError] = useState({ empty: false, notUnique: false });
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const handleChange = (e) => {
        setCollectionData((prevData) => ({ ...prevData, name: e.target?.value }));
        if (nameFieldError.empty || nameFieldError.notUnique) {
            setNameFieldError({ empty: false, notUnique: false });
        }
    };

    const handleColorSelect = (color) => {
        setCollectionData((prevData) => ({ ...prevData, icon_color: color }));
    };

    useEffect(() => {
        if (action === 'edit' && data) {
            setCollectionData({
                name: data.name,
                description: data.description,
                icon_color: data.icon_color,
            });
        }
        if (action === 'delete' && data) {
            setCollectionData({
                name: data.name,
                description: data.description,
                icon_color: data.icon_color,
            });
        }
    }, [action, data]);

    const createCollection = async () => {
        const requestBody = {
            ...collectionData,
            description: `This is description for ${collectionData.name}`,
        };
        const { data: result } = await client.post(API.ROUTES.library.collection, requestBody);
        setCollections((prevState) => [...prevState, result]);
    };

    const editCollection = async () => {
        // const requestBody =
        //     collectionData.name === data.name
        //         ? { icon_color: collectionData.icon_color }
        //         : collectionData; // if user doesn't change name we shouldn't send it to backend because we will get an error of not unique name
        const { data: result } = await client.patch(
            `${API.ROUTES.library.collection}${data.id}/`,
            collectionData
        );
        setCollections((prevState) =>
            prevState.map((item) => {
                if (item.id === result.id) {
                    return result;
                }
                return item;
            })
        );
    };

    const deleteCollection = async () => {
        await client.delete(`${API.ROUTES.library.collection}${data.id}/`);
        setCollections((prevState) => prevState.filter((item) => item.id !== data.id));
    };

    const actionHandlers = {
        create: createCollection,
        edit: editCollection,
        delete: deleteCollection,
    };

    const handleAction = async () => {
        if (action !== 'delete' && !collectionData.name) {
            setNameFieldError({ empty: true, notUnique: false });
            return;
        }
        try {
            setIsLoading(true);
            await actionHandlers[action]();
            showAlert(successAlertMessage[action]);
            handleClose();
        } catch (e) {
            if (
                e.response.data?.non_field_errors?.includes(
                    'A collection with that name already exists.'
                )
            ) {
                setNameFieldError({ empty: false, notUnique: true });
            } else {
                setErrorAlert({ show: true, statusCode: e.response.status });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal size="extraSmall" onClose={handleClose}>
            <div className="flex flex-col gap-4">
                {(action === 'create' || action === 'edit') && (
                    <div className="flex flex-col gap-4">
                        <p className="font-body text-body-bold-m text-black py-2">
                            {action === 'create' ? 'Add New' : 'Edit'} Collection
                        </p>
                        <Input
                            size="xs"
                            label="Collection Name"
                            value={collectionData.name}
                            name="collection"
                            placeholder="Unique collection name"
                            isRequired
                            state={
                                nameFieldError.empty || nameFieldError.notUnique
                                    ? 'error'
                                    : 'default'
                            }
                            errorMessage={
                                nameFieldError.empty
                                    ? 'Please fill in this field'
                                    : 'Oops! It looks like this is not a unique collection name.'
                            }
                            onChange={handleChange}
                        />
                        <div className="pb-4">
                            <p className="font-body text-body-bold-s text-neutral-500 mb-2">
                                Colors
                            </p>
                            <ColorPicker
                                options={collectionColorOptions}
                                selected={collectionData.icon_color}
                                handleSelect={handleColorSelect}
                            />
                        </div>
                    </div>
                )}
                {action === 'delete' && (
                    <div className="flex flex-col gap-4">
                        <p className="font-heading-bold text-heading-bold-m text-black py-2">
                            Delete Collection
                        </p>
                        <p className="font-body text-body-regular-m text-neutral-500 pb-4">
                            Are you sure you want to delete the {collectionData.name} collection
                            from your library?
                        </p>
                    </div>
                )}
                <div className="flex items-center justify-between">
                    <Button type="neutral" size="sm" text="Cancel" onClick={handleClose} />
                    <Button
                        type="secondary"
                        size="sm"
                        text={confirmButtonText[action]}
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleAction}
                    />
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </Modal>
    );
}

export default CollectionActionModal;
