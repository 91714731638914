import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EmojiPicker, { Emoji, EmojiStyle } from 'emoji-picker-react';

export default function EmojiButtonWithSelection({ initiallySelectedEmoji, onEmojiUpdated }) {
    const [selectedEmoji, setSelectedEmoji] = useState(initiallySelectedEmoji || '2699-fe0f');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    function toggleEmojiPicker() {
        setShowEmojiPicker(!showEmojiPicker);
    }

    function onClick(emojiData) {
        setSelectedEmoji(emojiData.unified);
        setShowEmojiPicker(false);
        onEmojiUpdated(emojiData.unified);
    }

    return (
        <div className="relative">
            <div
                className="w-[36px] h-[36px] rounded-2 bg-neutral-100 flex items-center justify-center font-body text-body-bold-m"
                onClick={toggleEmojiPicker}
            >
                <Emoji unified={selectedEmoji} emojiStyle={EmojiStyle.APPLE} size={22} />
            </div>
            {showEmojiPicker && (
                <div className="absolute z-50 shadow-lg bg-white p-2">
                    <EmojiPicker onEmojiClick={onClick} emojiStyle={EmojiStyle.APPLE} />
                </div>
            )}
        </div>
    );
}

EmojiButtonWithSelection.propTypes = {
    initiallySelectedEmoji: PropTypes.string,
    onEmojiUpdated: PropTypes.func,
};
