import React, { memo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import { baseEntriesSortOptions } from '../../../../constants/sort';

import { mutateDataAfterRenamingBase } from '../../../../helpers/basesUtils';
import { handleSortOptionSelect } from '../../../../helpers/handleUpdateSearchParams';
import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';

import { Button, SvgIcon } from '../../../../design-system';
import { ArrowGoBackLineIcon } from '../../../../design-system/Icons';
import ArchiveLineIcon from '../../../../design-system/Icons/ArchiveLineIcon';
import SearchBarWithUrlParamsHandling from '../../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';
import TextFieldWithCustomTextStyles, {
    emptyFieldError,
} from '../../../../design-system/TextFieldWithCustomTextStyles/TextFieldWithCustomTextStyles';
import SortDropdown from '../../../../components/SortDropdown/SortDropdown';

const BaseDetailPageHeader = ({ baseDetail, backLinkHref, setBaseDetail, hasEditAccess }) => {
    const { id, name } = baseDetail;

    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    const sort = searchParams.get('sort');

    const [isSortPopupOpened, setIsSortPopupOpened] = useState(false);

    const {
        requestKeysToMutate: { bases: requestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const { isMobile } = useResponsiveBreakpoints();

    const saveUpdatedName = async (name) => {
        if (!name) {
            throw new Error(emptyFieldError);
        }

        setBaseDetail((prevData) => ({ ...prevData, name }));
        mutateDataAfterRenamingBase({
            id,
            requestKeysToMutate,
            updatedName: name,
        });
        await client.patch(`${API.ROUTES.knowledge.base}${id}/`, { name });
    };

    const onSearchDropdownClose = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsSortPopupOpened(false);
    };

    const handleJobsSortOptionSelect = (sortOption) => {
        handleSortOptionSelect({
            sortOption,
            setSearchParams,
            searchParams,
            onSearchDropdownClose,
        });
    };

    const sortDropdownPosition = isMobile ? { top: '34px', right: 0 } : { top: '120%', left: 0 };

    return (
        <div className="px-5 sm:px-6 py-5 bg-white">
            <Button
                type="link"
                size="xs"
                text="Back to Knowledge Bases"
                leadingIcon={ArrowGoBackLineIcon}
                onClick={() => navigate(backLinkHref, { state: { from: location } })}
            />

            <div className="flex gap-1 mb-2">
                <div className="w-[32px] h-[32px] min-w-[32px] rounded-2 bg-purple-500 flex items-center justify-center">
                    <SvgIcon color="#FFFFFF" icon={ArchiveLineIcon} size="medium" />
                </div>

                <div className="flex-grow my-auto max-w-[730px]">
                    {hasEditAccess ? (
                        <TextFieldWithCustomTextStyles
                            value={name}
                            minHeight={32}
                            isSaveOnBlur
                            isSaveOnEnterPress
                            handleSave={saveUpdatedName}
                            textStyle="font-body-bold text-body-bold-l text-black"
                            customTextFieldStyles="px-2 pb-0.5 pt-1.5 rounded-2 shadow-inset-1 shadow-transparent focus:shadow-purple-500 transition-all"
                        />
                    ) : (
                        <p className="font-body-bold text-body-bold-l text-black px-2">{name}</p>
                    )}
                </div>
            </div>

            <div className="flex items-center gap-4">
                <SearchBarWithUrlParamsHandling
                    size="xs"
                    maxWidth={300}
                    placeholder="Search by keyword"
                />

                <div className="relative">
                    <button
                        className="font-body-bold text-body-bold-s text-black underline underline-offset-[3px] border-0 outline-0"
                        onClick={() => setIsSortPopupOpened(true)}
                    >
                        Sort
                    </button>

                    {isSortPopupOpened && (
                        <SortDropdown
                            options={baseEntriesSortOptions}
                            selectedOption={sort}
                            handleOptionSelect={handleJobsSortOptionSelect}
                            dropdownPosition={sortDropdownPosition}
                            onClose={onSearchDropdownClose}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(BaseDetailPageHeader);
