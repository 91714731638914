import React from 'react';

const ItalicIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M9.9974 13.3337H4.66406V12.0003H6.61514L8.02573 4.00033H5.9974V2.66699H11.3307V4.00033H9.37966L7.96906 12.0003H9.9974V13.3337Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ItalicIcon;
