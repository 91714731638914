import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/report-api';
import { HISTORY_TAB } from '../../../constants/assistant';

import { SvgIcon } from '../../../design-system';
import { FlashlightFillIcon, More2FillIcon } from '../../../design-system/Icons';
import InlineEditableField from '../InlineEditableField/InlineEditableField';
import HistoryItemMoreOptionsPopup from '../HistoryItemMoreOptionsPopup/HistoryItemMoreOptionsPopup';
import DeleteHistoryItemConfirmationModal from '../DeleteHistoryItemConfirmationModal/DeleteHistoryItemConfirmationModal';

const HistoryJobCard = ({
    job,
    setJobs,
    setCurrentViewingJobData,
    setIsPanelClosableByClickOutside,
    closePanel,
    responsiveBreakpointsHookResponse,
}) => {
    const { jobId: currentViewingJobId } = useParams();

    const { id, name, process } = job;
    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);
    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);

    const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] = useState(false);
    const [isNameEditing, setIsNameEditing] = useState(false);

    const isCurrentJobIsViewingInPanel = currentViewingJobId === id;

    const { isMobile, isDesktop } = responsiveBreakpointsHookResponse;

    const handleUpdateJobName = async (updatedName) => {
        try {
            setJobs((jobs) =>
                jobs.map((job) => (job.id === id ? { ...job, name: updatedName } : job))
            );
            if (isCurrentJobIsViewingInPanel) {
                setCurrentViewingJobData((jobData) => {
                    if (!jobData) {
                        return jobData;
                    }
                    return { ...jobData, name: updatedName };
                });
            }

            await client.patch(`${API.ROUTES.report.job}${id}/`, { name: updatedName });
        } catch (error) {
            throw error;
        }
    };

    const openMoreOptionsPopup = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsMoreOptionsPopupOpened(true);
        setIsPanelClosableByClickOutside(false);
    };

    const closeMoreOptionsPopup = (e) => {
        e?.stopPropagation();
        setIsMoreOptionsPopupOpened(false);
    };

    const closeDeleteConfirmationModal = () => {
        setIsConfirmDeleteModalOpened(false);
        setIsHovered(false);
        setIsPanelClosableByClickOutside(true);
    };

    const openDeleteConfirmationModal = () => {
        setIsConfirmDeleteModalOpened(true);
        setIsPanelClosableByClickOutside(false);
    };

    const stopEditingName = () => {
        setIsNameEditing(false);
        setIsHovered(false);
        setIsPanelClosableByClickOutside(true);
    };

    const showJobDetail = (e) => {
        e?.stopPropagation();
        if (isNameEditing || isMoreOptionsPopupOpened) {
            return;
        }

        navigate(`/assistant/job/${id}?tab=${HISTORY_TAB.jobs}`);
        if (!isDesktop) {
            closePanel();
        }
    };

    const isMoreOptionsButtonDisplayed =
        !isDesktop || ((isHovered || isMoreOptionsPopupOpened) && !isNameEditing);

    const cardClassName = classNames(
        'rounded-2 flex items-center justify-between transition-colors pl-2',
        {
            'gap-1': isMoreOptionsButtonDisplayed,
            'gap-0': !isMoreOptionsButtonDisplayed,
            'cursor-pointer hover:bg-neutral-50': !isNameEditing,
            'bg-white': !isCurrentJobIsViewingInPanel,
            'bg-neutral-50': isCurrentJobIsViewingInPanel,
        }
    );

    const wrapperClassName = classNames('flex items-center', {
        'w-full': !isMoreOptionsButtonDisplayed,
        'w-[calc(100%-38px)]': isMoreOptionsButtonDisplayed,
    });

    return (
        <>
            <div
                className={cardClassName}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={showJobDetail}
            >
                <div className={wrapperClassName}>
                    <SvgIcon color={process.icon_color} icon={FlashlightFillIcon} size="large" />
                    <InlineEditableField
                        value={name}
                        isEditing={isNameEditing}
                        stopEditing={stopEditingName}
                        onSave={handleUpdateJobName}
                        textStyles="font-body text-body-regular-m text-neutral-500"
                        shouldEditInPopup={isMobile}
                        popupTitle="Rename Job"
                    />
                </div>

                <div className="relative">
                    {isMoreOptionsButtonDisplayed && (
                        <button className="block pr-2" onClick={openMoreOptionsPopup}>
                            <SvgIcon color="#000000" icon={More2FillIcon} size="large" />
                        </button>
                    )}

                    {isMoreOptionsPopupOpened && (
                        <HistoryItemMoreOptionsPopup
                            onClose={closeMoreOptionsPopup}
                            handleRename={() => setIsNameEditing(true)}
                            openDeleteConfirmationModal={openDeleteConfirmationModal}
                            allowPanelCloseByClickOutside={() =>
                                setIsPanelClosableByClickOutside(true)
                            }
                        />
                    )}
                </div>
            </div>

            {isConfirmDeleteModalOpened && (
                <DeleteHistoryItemConfirmationModal
                    itemType="job"
                    itemId={id}
                    itemName={name}
                    setItems={setJobs}
                    onClose={closeDeleteConfirmationModal}
                />
            )}
        </>
    );
};

export default HistoryJobCard;
