import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/operate-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { mutateListDataAfterItemStatusChanging } from '../../../../helpers/swrMutateUtils';
import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';

import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import Alert from '../../../../design-system/Alert/Alert';
import ConfirmDeletionModalPattern from '../../../../design-system/ConfirmDeletionModalPattern/ConfirmDeletionModalPattern';

const CancelExecutionModal = ({ mutate, onClose, executionId, executionName }) => {
    const {
        requestKeysToMutate: { executions: requestKeysToMutateFromContext },
    } = useSwrRequestKeysToMutateFromContext();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleCancelExecution = async () => {
        try {
            setIsLoading(true);

            const { data } = await client.patch(`${API.ROUTES.operate.execution}${executionId}/`, {
                status: 'canceled',
            });

            if (mutate) {
                await mutate();
            }

            mutateListDataAfterItemStatusChanging({
                requestKeysToMutate: requestKeysToMutateFromContext,
                id: executionId,
                status: data.status,
            });

            onClose();
        } catch (error) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
            setIsLoading(false);
        }
    };

    return (
        <>
            <ConfirmDeletionModalPattern
                onClose={onClose}
                entityName={executionName}
                handleDelete={handleCancelExecution}
                isLoading={isLoading}
                buttonsTheme="dark"
                title="Confirm Cancel Execution"
                confirmationTextStart="Are you sure you want to cancel"
                confirmationTextEnd="execution? This will cancel all threads."
                customCancelButtonText="No, Exit"
                customConfirmButtonText="Yes, Cancel"
            />

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default CancelExecutionModal;
