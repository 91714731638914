import { useForm } from 'react-hook-form';

import { useTranslation } from '@mdxeditor/editor';

import '../link-dialog.css';

export function LinkEditForm({ url, title, onSubmit, onCancel }) {
    const { register, handleSubmit, reset: _ } = useForm({ values: { url, title } });

    const t = useTranslation();

    return (
        <form
            onSubmit={(e) => {
                void handleSubmit(onSubmit)(e);
                e.stopPropagation();
                e.preventDefault();
            }}
            onReset={(e) => {
                e.stopPropagation();
                onCancel();
            }}
            className="multiFieldForm linkDialogEditForm"
        >
            <div className="formField">
                <label htmlFor="link-url">{t('createLink.url', 'URL')}</label>
                <input
                    id="link-url"
                    className="textInput"
                    size={40}
                    autoFocus
                    {...register('url')}
                />
            </div>

            <div className="formField">
                <label htmlFor="link-title">{t('createLink.title', 'Title')}</label>
                <input id="link-title" className="textInput" size={40} {...register('title')} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                <button
                    type="submit"
                    title={t('createLink.saveTooltip', 'Set URL')}
                    aria-label={t('createLink.saveTooltip', 'Set URL')}
                    className="primaryButton"
                >
                    {t('dialogControls.save', 'Save')}
                </button>
                <button
                    type="reset"
                    title={t('createLink.cancelTooltip', 'Cancel change')}
                    aria-label={t('createLink.cancelTooltip', 'Cancel change')}
                    className="secondaryButton"
                >
                    {t('dialogControls.cancel', 'Cancel')}
                </button>
            </div>
        </form>
    );
}
