import React, { useMemo, useState } from 'react';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { HISTORY_TAB } from '../../../constants/assistant';

import SearchBar from '../../../design-system/SearchBar/SearchBar';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';
import HistoryGroupContainer from '../HistoryGroupContainer/HistoryGroupContainer';
import HistoryJobCard from '../HistoryJobCard/HistoryJobCard';
import HistoryTabsEmptyView from '../HistoryTabsEmptyView/HistoryTabsEmptyView';

const HistorySidePanelJobsTab = ({
    jobsHistoryHookResponse,
    setCurrentViewingJobData,
    setIsPanelClosableByClickOutside,
    closePanel,
}) => {
    const [searchBarInputValue, setSearchBarInputValue] = useState('');

    const {
        items: jobs,
        setItems: setJobs,
        isLoading: areJobsLoading,
        canLoadMore,
        setPage,
        searchQuery,
        setSearchQuery,
        isFirstRequestCompleted,
    } = jobsHistoryHookResponse;

    const responsiveBreakpointsHookResponse = useResponsiveBreakpoints();

    const handleSearchbarClear = () => {
        setSearchBarInputValue('');
        setSearchQuery('');
    };

    const jobGroups = useMemo(
        () =>
            Object.entries(Object.groupBy(jobs || [], ({ created_at_group }) => created_at_group)),
        [jobs]
    );

    const isEmptyStateDisplayed = isFirstRequestCompleted && !areJobsLoading && !jobs?.length;

    return (
        <div className="flex flex-col gap-5 h-full">
            <SearchBar
                size="xs"
                value={searchBarInputValue}
                onChange={(e) => setSearchBarInputValue(e.target.value)}
                onSubmit={() => setSearchQuery(searchBarInputValue)}
                withClearIcon
                onClear={handleSearchbarClear}
            />

            {isEmptyStateDisplayed && (
                <HistoryTabsEmptyView tab={HISTORY_TAB.jobs} isSearchQuery={!!searchQuery} />
            )}

            <div className="mx-[-20px] lg:mx-[-16px] xl:mx-[-20px] px-5 lg:px-4 xl:px-5 overflow-y-auto flex-grow pb-6">
                <InfiniteScrollList
                    handleFetch={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMore}
                    items={jobGroups}
                    loading={areJobsLoading}
                    gap={20}
                >
                    {jobGroups.map(([group, jobs]) => {
                        return (
                            <HistoryGroupContainer key={'job' + group} group={group}>
                                {jobs.map((job) => (
                                    <HistoryJobCard
                                        key={job.id}
                                        job={job}
                                        setJobs={setJobs}
                                        setCurrentViewingJobData={setCurrentViewingJobData}
                                        setIsPanelClosableByClickOutside={
                                            setIsPanelClosableByClickOutside
                                        }
                                        responsiveBreakpointsHookResponse={
                                            responsiveBreakpointsHookResponse
                                        }
                                        closePanel={closePanel}
                                    />
                                ))}
                            </HistoryGroupContainer>
                        );
                    })}
                </InfiniteScrollList>
            </div>
        </div>
    );
};

export default HistorySidePanelJobsTab;
