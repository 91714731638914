import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import { ArrowGoBackLineIcon, ErrorWarningLineIcon } from '../Icons';
import Alert from '../Alert/Alert';

CreationPageContainer.propTypes = {
    title: PropTypes.string.isRequired,
    backButtonText: PropTypes.string.isRequired,
    handleBackButtonClick: PropTypes.func.isRequired,
    errorAlert: PropTypes.shape({
        message: PropTypes.string.isRequired,
        statusCode: PropTypes.number,
    }),
    setErrorAlert: PropTypes.func,
};

function CreationPageContainer({
    title,
    backButtonText,
    handleBackButtonClick,
    errorAlert = null,
    setErrorAlert,
    children,
}) {
    return (
        <div className="bg-white w-full h-full flex justify-center px-5 sm:px-7 pb-[40px] min-h-[calc(100vh-64px)]">
            <div className="w-full max-w-[800px] py-5">
                <div className="mb-5">
                    <Button
                        type="link"
                        size="sm"
                        text={backButtonText}
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={handleBackButtonClick}
                    />
                </div>
                <h1 className="font-heading-bold text-heading-bold-m text-neutral-500 mb-8">
                    {title}
                </h1>
                {children}
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={5000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
}

export default CreationPageContainer;
