import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/assistant-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { triggerPlaybookSave } from '../../../../helpers/playbookBuilderUtils';

import Alert from '../../../Alert/Alert';
import Modal from '../../../Modal/Modal';
import ModalHeader from '../../../ModalHeader/ModalHeader';
import { Button, SvgIcon } from '../../../index';
import { ErrorWarningLineIcon, TerminalBoxLineIcon } from '../../../Icons';
import { setSyncTimeout } from '../../../../helpers/setSyncTimeout';

const ConfirmPromptBlockDeleteModal = ({
    id,
    name,
    handleDeleteBlock,
    handleBlockPropertyChange,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            await client.delete(`${API.ROUTES.assistant.chatPrompt}${id}/`);

            handleBlockPropertyChange('chat_prompt_id', '');
            setSyncTimeout(triggerPlaybookSave, 150);
            handleDeleteBlock();

            onClose();
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
        }
    };

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title="Delete Prompt" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                <div>
                    <p className="font-body text-body-regular-m text-neutral-500 mb-4">
                        Are you sure you’d like to delete this Prompt block?
                    </p>

                    <div className="px-3 py-2 rounded-[6px] bg-neutral-50 flex items-center gap-1">
                        <SvgIcon icon={TerminalBoxLineIcon} size="medium" color="#754DCF" />
                        <p className="font-body-bold text-body-bold-s text-[#25324B]">{name}</p>
                    </div>
                </div>
                <div className="flex items-center gap-2 justify-between">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="md"
                        text="Yes, Delete"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleDelete}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default ConfirmPromptBlockDeleteModal;
