import React from 'react';

import useUser from '../../../hooks/useUser';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

import Loading from '../../../components/Loading';
import UserSettingsBlock from '../UserSettingsBlock/UserSettingsBlock';
import OAuthConnectionsBlock from '../OAuthConnectionsBlock/OAuthConnectionsBlock';

const AccountOverviewSection = () => {
    const { user } = useUser();

    useDocumentTitle('Account Overview');

    if (!user) {
        return (
            <div className="flex-1 flex items-center justify-center">
                <Loading />
            </div>
        );
    }

    return (
        <>
            <h1 className="font-heading-bold text-heading-bold-l text-black">Account Overview</h1>

            <UserSettingsBlock />
            {user.is_staff && <OAuthConnectionsBlock />}
        </>
    );
};

export default AccountOverviewSection;
