import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../../services/template-api';

import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';
import { mutateTemplatesLibraryDataAfterDeletingGoalTemplate } from '../../../../helpers/templatesUtils';
import ConfirmDeletionModalPattern from '../../../../design-system/ConfirmDeletionModalPattern/ConfirmDeletionModalPattern';

const ConfirmGoalTemplateDeletionModal = ({
    goalTemplateId,
    goalTemplateName,
    setSuccessAlert = () => {},
    requestKeysToMutate,
    navigateToLibraryOnSuccess,
    backLinkHref,
    onClose,
}) => {
    const {
        requestKeysToMutate: { templatesLibrary: templatesLibraryRequestKeysToMutateFromContext },
    } = useSwrRequestKeysToMutateFromContext();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleGoalTemplateDelete = async () => {
        try {
            setIsLoading(true);
            await client.delete(`${API.ROUTES.template.goalTemplate}${goalTemplateId}/`);
            setSuccessAlert({ message: 'Goal Template successfully deleted!' });
            // if requestKeysToMutate are passed as prop than mutate them (if Modal is opened on Templates Library Page)
            // if requestKeysToMutate is undefined, use templatesLibraryRequestKeysToMutateFromContext to mutate data (if Modal is opened on Goal Template Details Page)
            const keysToMutate =
                requestKeysToMutate || templatesLibraryRequestKeysToMutateFromContext;
            mutateTemplatesLibraryDataAfterDeletingGoalTemplate(keysToMutate, goalTemplateId);
            if (navigateToLibraryOnSuccess) {
                navigate(backLinkHref || '/templates/goals');
            }
            onClose();
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    return (
        <ConfirmDeletionModalPattern
            title="Delete Goal Template"
            confirmationTextStart="Are you sure you want to delete"
            entityName={goalTemplateName}
            confirmationTextEnd="goal template? This template will no longer be available for new Process Template Versions."
            handleDelete={handleGoalTemplateDelete}
            isLoading={isLoading}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
            buttonsTheme="dark"
            onClose={onClose}
        />
    );
};

export default ConfirmGoalTemplateDeletionModal;
