import React from 'react';
import CollapsableContainer from '../../../components/CollapsableContainer/CollapsableContainer';
import CompassIcon from '../../../design-system/Icons/CompassIcon';
import NewTextAreaBox from '../../../design-system/NewTextAreaBox/NewTextAreaBox';
import PromptSettingsBox from '../../../components/PromptSettingsBox/PromptSettingsBox';
import { getPromptSettings } from '../../../helpers/getPromptSettings';
import { formatPromptVersionName } from '../../../helpers/formatPromptVersionName';

const PromptSpecificSection = ({ resultData, isExpanded, toggleCollapsed }) => {
    const promptText = resultData && resultData?.prompt_version?.messages?.[0]?.content;
    const promptSettings = resultData && getPromptSettings(resultData?.prompt_version.settings);
    const promptVersion = resultData && {
        id: resultData?.prompt_version?.id,
        name: formatPromptVersionName(resultData?.prompt_version?.name),
    };

    return (
        <>
            <CollapsableContainer
                title="Prompt"
                isExpanded={isExpanded.prompt}
                toggleExpand={() => toggleCollapsed('prompt')}
                badge={{
                    text: `Version ${promptVersion.name}`,
                    color: 'peach',
                    leadingIcon: CompassIcon,
                    leadingIconColor: '#000000',
                }}
            >
                {promptText && (
                    <div className="flex flex-col flex-grow">
                        <NewTextAreaBox
                            name="prompt"
                            value={promptText}
                            onChange={() => {}}
                            label={'Prompt'}
                            placeholder="Write your prompt here"
                            state={'disabled'}
                            withCopyButton
                            darkBg={true}
                        />
                    </div>
                )}
            </CollapsableContainer>
            <CollapsableContainer
                title="Prompt Settings"
                isExpanded={isExpanded.promptSettings}
                toggleExpand={() => toggleCollapsed('promptSettings')}
            >
                <PromptSettingsBox editable={false} promptSettings={promptSettings} />
            </CollapsableContainer>
        </>
    );
};

export default PromptSpecificSection;
