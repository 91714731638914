import React from 'react';
import { Navigate } from 'react-router-dom';
import useUser from 'hooks/useUser';

//route just for not loggedIn users
const RestrictedRoute = ({ component: Component, redirectTo = '/' }) => {
    const useUserResult = useUser();
    const { user } = useUserResult;

    return user ? <Navigate to={redirectTo} /> : Component;
};

export default RestrictedRoute;
