import React, { memo } from 'react';
import classNames from 'classnames';

import Checkbox from '../../../design-system/Checkbox/Checkbox';

export const docItemGridColsStyles =
    'grid grid-cols-[24px_minmax(100px,_1fr)_24px] md:grid-cols-[24px_minmax(282px,_2.5fr)_minmax(132px,_1fr)_24px] lg:grid-cols-[24px_minmax(285px,_1fr)_24px] xl:grid-cols-[24px_minmax(282px,_2.3fr)_minmax(132px,_1fr)_24px] gap-2 sm:gap-3 md:gap-4 px-4 items-center';
export const nestedGridContainerStyles =
    'flex flex-col gap-y-1 sm:grid sm:grid-cols-[minmax(150px,_1.5fr)_minmax(132px,_1fr)] lg:grid-cols-[minmax(150px,_1.7fr)_minmax(132px,_1fr)] xl:grid-cols-[minmax(150px,_1.3fr)_minmax(132px,_1fr)] gap-x-3 md:gap-y-4 sm:items-center';
export const updatedByColStyles = 'hidden md:block lg:hidden xl:block';

const DocsColumnHeader = ({ selectAllItems }) => {
    const containerClassNames = classNames(
        'py-1.5 rounded-2 bg-[#CFD6E54D]',
        docItemGridColsStyles
    );

    const updatedByClassNames = classNames(
        'font-body-bold text-body-bold-xs text-neutral-500 truncate',
        updatedByColStyles
    );

    return (
        <div className={containerClassNames}>
            <Checkbox name="all-docs" isChecked={false} onChange={selectAllItems} />

            <div className={nestedGridContainerStyles}>
                <p className="font-body-bold text-body-bold-xs text-neutral-500 truncate">
                    Doc Name
                </p>
                <p className="font-body-bold text-body-bold-xs text-neutral-500 truncate">
                    Last updated at
                </p>
            </div>

            <p className={updatedByClassNames}>Last updated by</p>
        </div>
    );
};

export default memo(DocsColumnHeader);
