import React from 'react';

import { API } from 'constants';
import client from '../../../../services/assistant-api';

import TextAreaWithEditControls from '../../../../components/TextAreaWithEditControls/TextAreaWithEditControls';

const BotInstructionsBlock = ({ botId, instructionsArray, setBotDetail }) => {
    const content = instructionsArray?.[0]?.content || '';

    const handleSaveInstructions = async (value) => {
        const formattedInstructions = [...(instructionsArray || [{ role: 'system' }])];
        formattedInstructions[0].content = value;

        const { data } = await client.patch(`${API.ROUTES.assistant.customBot}${botId}/`, {
            instructions: formattedInstructions,
        });
        setBotDetail((prevData) => ({
            ...prevData,
            updated_at: data.updated_at,
            default_version: {
                ...data.default_version,
                instructions: data.default_version?.instructions,
            },
        }));
    };

    return (
        <div className="p-5 sm:p-6 lg:p-8">
            <TextAreaWithEditControls
                label="Instructions"
                initialValue={content}
                handleSave={handleSaveInstructions}
            />
        </div>
    );
};

export default BotInstructionsBlock;
