import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CONTENT_TYPE } from '../../../../constants/docs';
import { handleDuplicateItem } from '../../../../helpers/docsUtils';

import { ListOption, ListOptionGroup } from '../../../../design-system';

const DocDetailMoreOptionsPopup = ({
    id,
    setSuccessAlert,
    setErrorAlert,
    requestKeysToMutate,
    setActionModal,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    const handleClose = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        onClose();
    };

    const duplicateDoc = async () => {
        await handleDuplicateItem({
            id,
            location,
            setSuccessAlert,
            setErrorAlert,
            setIsLoading,
            closePopup: handleClose,
            requestKeysToMutate,
            contentType: CONTENT_TYPE.document,
        });
    };

    const openActionModal = (action) => {
        onClose();
        setActionModal({ action });
    };

    return (
        <div className="absolute top-[120%] right-0 z-20 pb-5">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="copyIcon"
                            text="Duplicate"
                            isLoading={isLoading}
                            onClick={duplicateDoc}
                        />
                        <ListOption
                            leadingIconName="folderTransferFillIcon"
                            text="Move to"
                            onClick={() => openActionModal('move')}
                        />
                        <ListOption
                            leadingIconName="inboxArchiveLineIcon"
                            text="Archive"
                            onClick={() => openActionModal('archive')}
                        />
                        <ListOption
                            leadingIconName="userSharedLineIcon"
                            text="Send to"
                            onClick={() => openActionModal('send')}
                        />
                    </>
                }
                handleClose={handleClose}
            />
        </div>
    );
};

export default DocDetailMoreOptionsPopup;
