import { useEffect } from 'react';
import WebSocketInstance from '../services/websocket';

// in order to use this hook add message type to static typesForListeningLoadingState array in WebSocketService at /services/websocket.js
export const useWebSocketListeningLoadingState = ({
    messageType,
    onSuccess,
    onError,
    shouldConnectWebsocket = true, // if WebSocket connection on the page occurs conditionally, use the prop 'shouldConnectWebSocket'
}) => {
    const callback = (data) => {
        const { message } = data;

        if (message.status === 'error') {
            onError(message);
            removeRequestUuid(message.request_uuid);
        }

        if (message.status === 'success') {
            onSuccess(message);
            removeRequestUuid(message.request_uuid);
        }
    };

    useEffect(() => {
        if (shouldConnectWebsocket) {
            WebSocketInstance.connect();
            WebSocketInstance.addCallbackForSpecificType(messageType, callback);
        }
    }, [shouldConnectWebsocket]);

    useEffect(() => {
        return () => {
            if (WebSocketInstance.isOpen()) {
                WebSocketInstance.disconnect();
            }
        };
    }, []);

    const tryReconnectIfWebsocketClosed = async () => {
        // use this func inside try {} catch {} block
        if (!WebSocketInstance.isOpen()) {
            await WebSocketInstance.attempt_reconnect({
                shouldThrowError: true,
            });
        }
    };
    const addRequestUuid = (requestUuid) => {
        WebSocketInstance.setCurrentPageRequestUuids(requestUuid);
    };

    function removeRequestUuid(requestUuid) {
        WebSocketInstance.removeCurrentRequestUuid(requestUuid);
    }

    const clearRequestUuid = () => {
        WebSocketInstance.clearAllCurrentPageRequestUuids();
    };

    return {
        addRequestUuid,
        clearRequestUuid,
        tryReconnectIfWebsocketClosed,
    };
};
