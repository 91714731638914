import React, { useMemo, useState } from 'react';
import { DEFAULT_LIMIT } from '../../../../hooks/useCustomPagination';
import { transformDatasetsDataForTable } from '../../../../helpers/formatDatasetsDataForTable';

import Table from '../../../../design-system/Table/Table';
import DeleteDatasetConfirmationModal from '../DeleteDatasetConfirmationModal/DeleteDatasetConfirmationModal';
import DatasetsMoreOptionsPopup from '../DatasetsMoreOptionsPopup/DatasetsMoreOptionsPopup';
import NewDatasetRecordModal from '../NewDatasetRecordModal/NewDatasetRecordModal';
import UpdateDatasetModal from '../UpdateDatasetModal/UpdateDatasetModal';

const DatasetsTableBlock = ({
    data,
    page,
    total,
    swrKey,
    onPreviousPageClick,
    onNextPageClick,
}) => {
    const tableData = useMemo(
        () => transformDatasetsDataForTable(data, page, total),
        [data, page, total]
    );

    const [datasetActionModal, setDatasetActionModal] = useState({
        action: null,
        data: null,
    });

    const closeActionModal = () => setDatasetActionModal({ action: null, data: null });

    const openLangsmithUrlInTheNewTab = (row) => {
        window.open(row.langsmithUrl, '_blank');
    };

    return (
        <>
            <Table
                data={tableData}
                size="md"
                rowActionButtons={[
                    {
                        action: 'visit',
                        handleAction: openLangsmithUrlInTheNewTab,
                    },
                    {
                        action: 'moreOptions',
                    },
                ]}
                columnsCount={1}
                shift={910}
                includePagination={total > DEFAULT_LIMIT}
                onPaginationPreviousClick={onPreviousPageClick}
                onPaginationNextClick={onNextPageClick}
                moreOptionPopupComponent={DatasetsMoreOptionsPopup}
                setDatasetActionModal={setDatasetActionModal}
            />
            {datasetActionModal.action === 'delete' && (
                <DeleteDatasetConfirmationModal
                    datasetDetail={datasetActionModal.data}
                    swrKey={swrKey}
                    onClose={closeActionModal}
                />
            )}
            {datasetActionModal.action === 'addRecord' && (
                <NewDatasetRecordModal
                    datasetDetail={datasetActionModal.data}
                    onClose={closeActionModal}
                />
            )}
            {datasetActionModal.action === 'update' && (
                <UpdateDatasetModal
                    datasetDetail={datasetActionModal.data}
                    swrKey={swrKey}
                    onClose={closeActionModal}
                />
            )}
        </>
    );
};

export default DatasetsTableBlock;
