import React from 'react';
import { ListOption, ListOptionGroup } from '../../../design-system';

const PlaybookSectionMoreOptionPopup = ({
    isTheOnlySection,
    handleDuplicateSection,
    handleDeleteSection,
    onClose,
}) => {
    return (
        <div className="absolute right-0 top-full z-30">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="copyIcon"
                            text="Duplicate Section"
                            onClick={() => {
                                handleDuplicateSection();
                                onClose();
                            }}
                        />
                        {!isTheOnlySection && (
                            <ListOption
                                leadingIconName="deleteBin4LineIcon"
                                text="Delete Section"
                                iconColor="#E95B69"
                                textColor="#E95B69"
                                onClick={handleDeleteSection}
                            />
                        )}
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default PlaybookSectionMoreOptionPopup;
