import React, { useEffect, useRef } from 'react';

import { jobsLimit } from '../ClientProcessDetailPage';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';

import JobCard from '../../../DashboardPage/JobCard/JobCard';
import PaginationFooter from '../../../LibraryPage/PaginationFooter/PaginationFooter';
import Loading from '../../../../components/Loading';

const JobHistoryTabSection = ({ jobsUseCustomPaginationResponse, jobsPage, setJobsPage }) => {
    const { data: jobs, total, count, loading, key, nextPageKey } = jobsUseCustomPaginationResponse;

    const requestKeysToMutate = useRef([]);

    const start = (jobsPage - 1) * jobsLimit + 1;
    const end = total - start < count ? total : start + count - 1;

    const handleNextPressed = () => {
        setJobsPage((page) => page + 1);
    };

    const handlePreviousPressed = () => {
        if (jobsPage > 1) {
            setJobsPage((page) => page - 1);
        }
    };

    useEffect(() => {
        if (key) {
            requestKeysToMutate.current = addUniqueElementToArray(key, requestKeysToMutate.current);
        }
    }, [key]);

    useEffect(() => {
        if (nextPageKey) {
            requestKeysToMutate.current = addUniqueElementToArray(
                nextPageKey,
                requestKeysToMutate.current
            );
        }
    }, [nextPageKey]);

    return (
        <div className="w-full p-5 sm:p-6 bg-white rounded-2xl flex flex-col gap-5">
            <p className="font-heading-bold text-heading-bold-s text-black">Job History</p>

            {!!total && (
                <div className="flex flex-col gap-3">
                    <ul className="flex flex-col">
                        {jobs.map((job) => (
                            <JobCard
                                key={job.id}
                                job={job}
                                pageName="processDetail"
                                requestKeysToMutate={requestKeysToMutate.current || []}
                            />
                        ))}
                    </ul>

                    <div className="flex flex-col">
                        <PaginationFooter
                            start={start}
                            end={end}
                            total={total}
                            onNextPressed={handleNextPressed}
                            onPreviousPressed={handlePreviousPressed}
                        />
                    </div>
                </div>
            )}

            {!total && !loading && (
                <p className="font-body-bold text-body-bold-s text-neutral-400">No jobs found</p>
            )}

            {!total && loading && (
                <div className="pt-5">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default JobHistoryTabSection;
