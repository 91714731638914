import React, { useEffect, useRef, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/library-api';

import {
    checkIfPlaybookContentFormDataChanged,
    filterOutBlocksWithEmptyProperties,
    formatBlockContent,
    getAreSectionsExpandedInitialState,
    getPlaybookContentInitialState,
    updatePlaybookContentFormDataAfterSaveChanges,
} from '../../../helpers/playbookBuilderUtils';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import PlaybookPageLeftPanel from '../PlaybookPageLeftPanel/PlaybookPageLeftPanel';
import PlaybookPageRightPanel from '../PlaybookPageRightPanel/PlaybookPageRightPanel';
import PlaybookHeader from '../PlaybookHeader/PlaybookHeader';
import PlaybookContent from '../PlaybookContent/PlaybookContent';
import OutdatedAlertModal from '../OutdatedAlertModal/OutdatedAlertModal';
import NavigationGuard from '../../../components/NavigationGuard/NavigationGuard';
import PlaybookBottomActionBar from '../PlaybookBottomActionBar/PlaybookBottomActionBar';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import SharePlaybookBlock from '../SharePlaybookBlock/SharePlaybookBlock';

export const pageColumnsCommonStyles = 'h-full hide-scrollbar pb-[60px] lg:pb-8 xl:pb-[40px]';

const PlaybookBuilderIndex = ({
    playbookDetail,
    setPlaybookDetail,
    playbookContentData,
    playbookContentChangesState,
    openPlaybookEditModal,
    mode,
    height,
    isDesktop,
    changeMode,
}) => {
    const { id } = playbookDetail;

    const [isSaveEnabled, setIsSaveEnabled] = playbookContentChangesState;
    const [isSaveChangesLoading, setIsSaveChangesLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);
    const [isOutdatedAlertModalOpened, setIsOutdatedAlertModalOpened] = useState(false);

    // temporary ID is added to each section (and each block) to ensure every object has a unique identifier. Some sections have a null ID, and the order field cannot be used since it is changeable.
    // if a section is saved in the database, it has an ID, and the temporary ID will be set equal to this ID.
    // if it is a new section with a null ID, a temporary unique ID will be created.
    const [playbookContentFormData, setPlaybookContentFormData] = useState([]);
    // originalPlaybookContentFormData contains the form initial state and is used for tracking changes
    const originalPlaybookContentFormData = useRef([]);

    const areSectionsExpandedState = useState({});
    const [_, setAreSectionsExpanded] = areSectionsExpandedState;

    const shouldRestrictNavigation = isSaveEnabled; // when are unsaved changes

    useEffect(() => {
        const isFormChanged = checkIfPlaybookContentFormDataChanged(
            originalPlaybookContentFormData.current,
            playbookContentFormData
        );

        if (isFormChanged !== isSaveEnabled) {
            setIsSaveEnabled(isFormChanged);
        }
    }, [playbookContentFormData]);

    useEffect(() => {
        const refreshContentFormState = () => {
            const updatedInitialState = getPlaybookContentInitialState(playbookContentData);

            setPlaybookContentFormData(updatedInitialState);
            updateOriginalPlaybookContentFormData(updatedInitialState);
            setAreSectionsExpanded(getAreSectionsExpandedInitialState(updatedInitialState));
        };

        refreshContentFormState();
    }, [playbookContentData]);

    const updateOriginalPlaybookContentFormData = (updatedData) => {
        originalPlaybookContentFormData.current = updatedData;
    };

    const getPlaybookContentRequestBody = ({ overwrite }) => {
        const requestBody = {
            playbook_content: playbookContentFormData.map(({ temporaryId, ...sectionData }) => ({
                ...sectionData,
                block_content: filterOutBlocksWithEmptyProperties(
                    formatBlockContent(sectionData.block_content)
                ),
            })),
        };

        if (overwrite) {
            requestBody.overwrite = true;
        }

        return requestBody;
    };

    const handleSaveChanges = async ({ overwrite = false }) => {
        try {
            setIsSaveChangesLoading(true);
            const requestBody = getPlaybookContentRequestBody({ overwrite });
            const { data } = await client.patch(
                `${API.ROUTES.library.playbook}${id}/`,
                requestBody
            );
            const { playbook_content, status } = data;

            if (status === 'outdated') {
                setIsOutdatedAlertModalOpened(true);
                setIsSaveChangesLoading(false);
                return;
            }

            updatePlaybookContentFormDataAfterSaveChanges(
                playbook_content,
                playbookContentFormData,
                setPlaybookContentFormData,
                updateOriginalPlaybookContentFormData,
                areSectionsExpandedState
            );
            setPlaybookDetail(data);
            setIsSaveChangesLoading(false);
        } catch (e) {
            setIsSaveChangesLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const blockingNavigationFunction = ({
        currentLocation,
        nextLocation,
        isNavigationRestricted,
    }) => {
        // user can change mode without confirming navigation
        if (currentLocation.pathname === nextLocation.pathname) {
            return false;
        }

        return isNavigationRestricted;
    };

    const promptMessage = isSaveEnabled
        ? 'Are you sure you want to leave? You have unsaved edits.'
        : 'Are you sure you want to leave? We’ll keep loading your process in the background.';
    const promptMessageSegment = isSaveEnabled
        ? 'You have unsaved edits.'
        : 'We’ll keep loading your process in the background.';

    return (
        <>
            <div
                className="flex items-start gap-8 pt-5 sm:pt-6 px-4 sm:px-5 flex-grow lg:overflow-hidden"
                style={{ height }}
            >
                <SharePlaybookBlock
                    playbookDetail={playbookDetail}
                    setPlaybookDetail={setPlaybookDetail}
                    playbookContentFormData={playbookContentFormData}
                />

                <div className="flex items-start flex-grow gap-8 lg:gap-[5px] h-full w-full max-w-full lg:w-[calc(100%-232px)] lg:max-w-[calc(100%-232px)]">
                    {isDesktop && (
                        <PlaybookPageLeftPanel
                            mode={mode}
                            playbookDetail={playbookDetail}
                            playbookContentFormData={playbookContentFormData}
                            areSectionsExpandedState={areSectionsExpandedState}
                        />
                    )}

                    <div
                        className={`flex-grow flex flex-col gap-6 lg:overflow-y-auto max-w-full lg:max-w-none ${pageColumnsCommonStyles}`}
                    >
                        {isDesktop && (
                            <PlaybookHeader
                                mode={mode}
                                playbookDetail={playbookDetail}
                                openPlaybookEditModal={openPlaybookEditModal}
                            />
                        )}

                        <PlaybookContent
                            mode={mode}
                            playbookContentFormData={playbookContentFormData}
                            setPlaybookContentFormData={setPlaybookContentFormData}
                            areSectionsExpandedState={areSectionsExpandedState}
                            connectedAgents={playbookDetail.processes || []}
                            playbookContentData={playbookContentData}
                            accessType={playbookDetail.access_type}
                        />
                    </div>
                </div>

                {isDesktop && (
                    <PlaybookPageRightPanel mode={mode} accessType={playbookDetail.access_type} />
                )}
            </div>

            <PlaybookBottomActionBar
                isDesktop={isDesktop}
                mode={mode}
                changeMode={changeMode}
                isPlaybookShared={playbookDetail.is_shared}
                isSaveEnabled={isSaveEnabled}
                handleSaveChanges={handleSaveChanges}
                isSaveChangesLoading={isSaveChangesLoading}
                playbookContentFormData={playbookContentFormData}
                areSectionsExpandedState={areSectionsExpandedState}
            />

            <NavigationGuard
                when={shouldRestrictNavigation}
                promptMessage={promptMessage}
                customNavigationBlockerMessageSegment={promptMessageSegment}
                blockingNavigationFunction={blockingNavigationFunction}
            />

            {isOutdatedAlertModalOpened && (
                <OutdatedAlertModal
                    isSaveChangesLoading={isSaveChangesLoading}
                    handleSaveChanges={handleSaveChanges}
                    onClose={() => setIsOutdatedAlertModalOpened(false)}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default PlaybookBuilderIndex;
