export const ROUTES = {
    library: {
        workflow: '/goal/',
        prompt: '/prompt/',
        task: '/task/',
        // createTask: '/create-task/', // depracated
        // createStep: '/create-step/', // depracated
        search: '/task/?search=',
        promptVersion: '/prompt-version/',
        // duplicateTask: '/duplicate-task/', // depracated, use /task/<id>/duplicate/ instead
        // duplicateGoal: '/duplicate-goal/', // depracated, use /goal/<id>/duplicate/ instead
        // sharedTaskDetails: '/shared-task-details/', // depracated
        // sharedTask: '/shared-task/', // depracated
        sharedTasks: '/shared-tasks/',
        integrationTasks: '/integration-tasks/',
        collection: '/collection/',
        process: '/process/',
        clientProcess: '/client/process/',
        processContext: '/process-context/',
        // createProcess: '/create-process/', // depracated
        playbook: '/playbook/',
        sharedPlaybook: '/shared-playbook/',
        scenario: '/scenario/',
        clientScenario: '/client/scenario/',
        scenarioVersion: '/scenario-version/',
    },
    user: {
        register: '/register/',
        login: '/login/',
        logout: '/logout/',
        userDetails: '/view/',
        apiCredentials: '/api-credentials/',
        verifyEmail: '/register/verify-email/',
        resendEmail: '/register/resend-email/',
        accountConfirmEmail: '/account-confirm-email/',
        passwordReset: '/password/reset/',
        resetPasswordConfirm: '/password/reset/confirm/',
        uploadProfileImage: '/upload-profile-image/',
        verifyInvite: '/verify-invite/',
        uploadImage: '/upload-image/',
        uploadFile: '/upload-file/',
        invite: '/invite/',
    },
    operate: {
        worker: '/worker/',
        run: '/run/',
        result: '/result/',
        // queuePromptRun: '/queue-prompt-run/', // depracated
        // promptHistory: '/prompt-history/', // depracated use /result/ instead with prompt=<id>
        // taskHistory: '/task-history/', // depracated use /result/ instead with task=<id>
        // promptVersionHistory: '/prompt-version-history/', // depracated
        // complete: '/complete/', // depracated use /run/<id>/complete/ instead
        // cancel: '/cancel/', // depracated use /run/<id>/cancel/ instead
        thread: '/thread/',
        // restartThread: '/restart-thread/', // depracated use /thread/<id>/restart/ instead
        // restartWorker: '/restart-worker/', // depracated use /worker/<id>/restart/ instead
        execution: '/execution/',
    },
    organization: {
        // organizationData: '/organization-data/', // depracated
        organization: '/organization/',
        team: '/team/',
        organizationMembership: '/organization-membership/',
        // teamsUserList: '/teams-users-list/', // depracated
        // memberships: '/memberships/', // depracated
        connection: '/connection/',
    },
    apikey: {
        apikey: '/external-api-key/',
    },
    knowledge: {
        base: '/base/',
        context: '/context/',
        document: '/document/',
        folder: '/folder/',
        folderDocument: '/folder-document/',
    },
    payment: {
        createCheckoutSession: '/create-checkout-session/',
    },
    inbox: {
        message: '/message/',
        feedback: '/feedback/',
    },
    report: {
        // template: '/template/', // depracated
        // workReport: '/work-report/', // depracated
        job: '/job/',
        project: '/project/',
    },
    template: {
        goalTemplate: '/goal-template/',
        goalTemplateVersion: '/goal-template-version/',
        // goalDeploy: '/goal/deploy/', // depracated
        processTemplate: '/process-template/',
        processTemplateVersion: '/process-template-version/',
        label: '/label/',
        processDiscover: '/process/discover/',
    },
    execute: {
        dataset: '/dataset/',
        // startUnitTest: '/goal-template-version/',
        testRun: '/testrun/',
    },
    assistant: {
        chatThread: '/chat-thread/',
        chatMessage: '/chat-message/',
        chatPrompt: '/chat-prompt/',
        clientChatPrompt: '/client/chat-prompt/',
        chatMessageVersion: '/chat-message-version/',
        customBot: '/custom-bot/',
    },
};
