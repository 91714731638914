import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import libraryClient from '../../services/library-api';
import templateClient from '../../services/template-api';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import Loading from '../../components/Loading';
import CustomizeGoalPageHeader from './CustomizeGoalPageHeader/CustomizeGoalPageHeader';
import CustomizeGoalSettingsBlock from './CustomizeGoalSettingsBlock/CustomizeGoalSettingsBlock';

const CustomizeGoalPage = () => {
    const { processId, goalTemplateId } = useParams();

    const navigate = useNavigate();

    const [processData, setProcessData] = useState(null);
    const [goalTemplateData, setGoalTemplateData] = useState(null);

    useDocumentTitle(goalTemplateData?.name);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [{ data: processData }, { data: goalTemplateData }] = await Promise.all([
                    libraryClient.get(`${API.ROUTES.library.process}${processId}/`),
                    templateClient.get(
                        `${API.ROUTES.template.goalTemplate}${goalTemplateId}/deploy/`
                    ),
                ]);

                setProcessData(processData);
                setGoalTemplateData(goalTemplateData);
            } catch (e) {
                navigate(`/configure/process/${processId}`);
            }
        };

        fetchData();
    }, []);

    return (
        <div
            className={`fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-neutral-50 flex flex-col overflow-y-auto`}
        >
            {processData && goalTemplateData ? (
                <>
                    <CustomizeGoalPageHeader
                        processData={processData}
                        goalTemplateName={goalTemplateData.name}
                    />
                    <CustomizeGoalSettingsBlock
                        goalTemplateData={goalTemplateData}
                        processId={processId}
                    />
                </>
            ) : (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default CustomizeGoalPage;
