export const parseJobsSearchParams = (searchParams) => {
    const page = Number.parseInt(searchParams.get('page'));
    const sort = searchParams.get('sort');
    const agent = searchParams.get('process__in');
    const playbook = searchParams.get('playbook__in');
    const search = searchParams.get('search');
    const status = searchParams.get('status__in');

    const result = { sort };

    if (page) {
        result.page = page;
    }
    if (search) {
        result.search = search;
    }
    if (agent) {
        const parsedProcesses = agent?.split(',') || [];
        result.agent = parsedProcesses;
    }
    if (playbook) {
        const parsedPlaybooks = playbook?.split(',') || [];
        result.playbook = parsedPlaybooks;
    }
    if (status) {
        const parsedStatus = status?.split(',') || [];
        result.status = parsedStatus;
    }

    return result;
};
