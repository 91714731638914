import React, { useState } from 'react';
import { DateTime } from 'luxon';

import useUser from '../../../../hooks/useUser';
import { Badge, ButtonIcon } from '../../../../design-system';
import { More2FillIcon } from '../../../../design-system/Icons';
import ProcessVersionMoreOptionPopup from '../ProcessVersionMoreOptionPopup/ProcessVersionMoreOptionPopup';

const ProcessVersionHistoryItem = ({ processVersion, isTheOldestVersion }) => {
    const {
        created_at,
        is_default,
        process_template,
        process_template_version: { id, version_name },
    } = processVersion;

    const { user } = useUser();
    const isStaffUser = user.is_staff;

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);

    const formattedTime = DateTime.fromISO(created_at).toFormat('M/d/yy');
    const title = isTheOldestVersion ? 'Process Installed' : 'Process Upgraded';

    return (
        <li className="relative border-b-1 border-neutral-200 py-2 flex items-center justify-between gap-4">
            <div className="flex items-start gap-y-1 gap-x-3 sm:gap-x-5 l:gap-x-3 flex-col-reverse min-[515px]:flex-row min-[515px]:items-center l:flex-col-reverse l:items-start xl:flex-row xl:items-center xl:gap-x-4 2xl:gap-x-5">
                <p className="font-body-bold text-body-bold-m text-neutral-300">
                    {title} @ <span className="font-body text-body-regular-m">{formattedTime}</span>
                </p>
                {is_default && <Badge text="Default" color="blue" />}
            </div>
            <div className="flex items-center gap-3 sm:gap-5 l:gap-x-3 xl:gap-x-4 2xl:gap-x-5">
                <Badge text={version_name} color="neutral" />
                {isStaffUser && (
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={() => setIsMoreOptionsPopupOpened(true)}
                    />
                )}
            </div>

            {isMoreOptionsPopupOpened && (
                <ProcessVersionMoreOptionPopup
                    processTemplateId={process_template.id}
                    processTemplateVersionId={id}
                    onClose={() => setIsMoreOptionsPopupOpened(false)}
                />
            )}
        </li>
    );
};

export default ProcessVersionHistoryItem;
