import React, { useState } from 'react';

import Panel from '../../../../components/Panel';
import EditBaseEntryPanelHeader from '../EditBaseEntryPanelHeader/EditBaseEntryPanelHeader';
import EntryInputsForm from '../EntryInputsForm/EntryInputsForm';
import ConfirmEntryDeletionModal from '../ConfirmEntryDeletionModal/ConfirmEntryDeletionModal';

const EditBaseEntryPanel = ({ entry, schema, requestKeysToMutate, hasEditAccess, onClose }) => {
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    return (
        <Panel
            onClose={onClose}
            showControls={false}
            withOverflowProperty={false}
            closeByClickOutside={true}
        >
            <div className="flex flex-col gap-5 max-w-full pb-[80px] max-h-full overflow-y-auto hide-scrollbar">
                <EditBaseEntryPanelHeader
                    entry={entry}
                    hasEditAccess={hasEditAccess}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={onClose}
                />

                <EntryInputsForm
                    schema={schema}
                    value={entry.default_version?.value}
                    entryId={entry.id}
                    baseId={entry.base}
                    hasEditAccess={hasEditAccess}
                    requestKeysToMutate={requestKeysToMutate}
                    openDeleteModal={() => setIsDeleteModalOpened(true)}
                    onClose={onClose}
                />
            </div>

            {isDeleteModalOpened && (
                <ConfirmEntryDeletionModal
                    entry={entry}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setIsDeleteModalOpened(false)}
                />
            )}
        </Panel>
    );
};

export default EditBaseEntryPanel;
