import React from 'react';
import { Button } from '../../../../design-system';
import classNames from 'classnames';

const BlockHeader = ({
    title,
    description,
    buttonText,
    onButtonClick,
    buttonType = 'neutral',
    buttonLeadingIcon,
    withMarginBottom = false,
    isEditable = true,
}) => {
    const containerStyles = classNames({
        'flex flex-col min-[500px]:flex-row items-start justify-between gap-x-4 gap-y-2':
            isEditable,
        'mb-6 sm:mb-[40px]': withMarginBottom,
    });

    return (
        <div className={containerStyles}>
            <div>
                <p className="font-heading-bold text-heading-bold-m text-black mb-2">{title}</p>
                <p className="font-body text-body-regular-s text-neutral-300">{description}</p>
            </div>
            {isEditable && (
                <div className="min-w-fit">
                    <Button
                        type={buttonType}
                        size="xs"
                        text={buttonText}
                        leadingIcon={buttonLeadingIcon}
                        onClick={onButtonClick}
                    />
                </div>
            )}
        </div>
    );
};

export default BlockHeader;
