import React, { useEffect, useState } from 'react';

import useUser from '../../../hooks/useUser';
import { useViewType } from '../../../hooks/useViewType';
import { checkViewTypeAccess } from '../../../helpers/checkViewTypeAccess';
import { usePreActionNavigationGuard } from '../../../hooks/usePreActionNavigationGuard';

import { VIEW_TYPES } from '../../../constants/viewTypes';
import { navItemIconColor, navItemLabelColor } from '../../../constants/sideBar';
import { ORGANIZATION_PLAN } from '../../../constants/organization';

import SvgIcon from '../../../design-system/SvgIcon/SvgIcon';
import DashboardLineIcon from '../../../design-system/Icons/DashboardLineIcon';
import UserSettingsLineIcon from '../../../design-system/Icons/UserSettingsLineIcon';
import ToolTip from '../../../design-system/ToolTip/ToolTip';

const ViewTypeSwitcher = ({ mobileVersion = false, handleMobMenuClose }) => {
    const { viewType, toggleViewType } = useViewType();
    const { orgPlan, user, userRole } = useUser();

    const shouldBeDisplayed = orgPlan === ORGANIZATION_PLAN.agency && user?.is_staff;
    const [showToolTip, setShowToolTip] = useState(false);

    const {
        handleConfirmNavigation,
        handleCancelNavigation,
        ConfirmModalComponent,
        isConfirmNavigationModalShown,
        isNavigationRestricted,
        setIsConfirmNavigationModalShown,
        promptMessage,
        isPromptViewPageOpened,
    } = usePreActionNavigationGuard({ onConfirmNavigation: toggleViewType });

    useEffect(() => {
        const isUserLoaded = user && orgPlan && userRole;
        if (!isUserLoaded) {
            return;
        }

        const hasViewTypeAccess = checkViewTypeAccess({
            viewType,
            orgPlan,
            userRole,
            is_staff: user.is_staff,
        });

        const shouldToggleViewType = !hasViewTypeAccess;

        if (shouldToggleViewType) {
            toggleViewType();
        }
    }, [viewType, user, orgPlan, userRole]);

    const title = {
        [VIEW_TYPES.admin]: 'Client View',
        [VIEW_TYPES.client]: 'Admin View',
    };

    const icon = {
        [VIEW_TYPES.admin]: DashboardLineIcon,
        [VIEW_TYPES.client]: UserSettingsLineIcon,
    };

    if (!shouldBeDisplayed) {
        return <></>;
    }

    const handleToggleViewTypeClick = () => {
        if (isNavigationRestricted) {
            setIsConfirmNavigationModalShown(true);
        }

        if (!isNavigationRestricted) {
            toggleViewType();
        }
    };

    const confirmNavigationModalText = {
        true: `You have unsaved changes. If you change to ${title[viewType]}, all your changes will be lost.`,
        false: `Are you sure you want to change to ${title[viewType]}? ${promptMessage || ''}`,
    };

    return (
        <>
            {mobileVersion ? (
                <li className="p-2" onClick={handleMobMenuClose}>
                    <div
                        className={`
                            flex items-center gap-[16px] font-body text-body-bold-m cursor-pointer ${navItemLabelColor[viewType]}
                        `}
                        onClick={handleToggleViewTypeClick}
                    >
                        <span className="flex items-center justify-center w-[36px] h-[36px] relative z-[-1]">
                            <SvgIcon
                                color={navItemIconColor[viewType]}
                                size="large"
                                icon={icon[viewType]}
                            />
                        </span>
                        {title[viewType]}
                    </div>
                </li>
            ) : (
                <li className={`list-none p-2`}>
                    <ToolTip
                        text={title[viewType]}
                        position="center-left"
                        shift={'55px'}
                        isShown={showToolTip}
                    >
                        <div
                            className="w-9 h-9 flex justify-center items-center cursor-pointer"
                            onClick={handleToggleViewTypeClick}
                            onMouseEnter={() => setShowToolTip(true)}
                            onMouseLeave={() => setShowToolTip(false)}
                        >
                            <SvgIcon
                                color={navItemIconColor[viewType]}
                                size="large"
                                icon={icon[viewType]}
                            />
                        </div>
                    </ToolTip>
                </li>
            )}
            {isConfirmNavigationModalShown && (
                <ConfirmModalComponent
                    text={confirmNavigationModalText[isPromptViewPageOpened]}
                    confirmButtonText={
                        isPromptViewPageOpened
                            ? `Change to ${title[viewType]}`
                            : `Yes, change to ${title[viewType]}`
                    }
                    cancelButtonText={isPromptViewPageOpened ? 'Stay on Page' : 'No, Stay on Page'}
                    onCancel={handleCancelNavigation}
                    onConfirm={handleConfirmNavigation}
                    buttonsTheme={isPromptViewPageOpened ? 'colored' : 'dark'}
                />
            )}
        </>
    );
};

export default ViewTypeSwitcher;
