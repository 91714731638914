import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { filterOutMatchingObjects } from '../../../../helpers/filterOutMatchingObjects';

import { Button } from '../../../../design-system';
import AddGoalFromTemplateModal from '../../AddGoalFromTemplateModal/AddGoalFromTemplateModal';
import GoalCard from '../GoalCard/GoalCard';
import PlusCircleIcon from '../../../../design-system/Icons/PlusCircleIcon';
import BlockHeader from '../BlockHeader/BlockHeader';

const ActiveGoalsBlock = ({ processDetail, setProcessDetail, isEditable }) => {
    const { id, goals, inactive_goals } = processDetail;
    const activeGoals = useMemo(
        () => filterOutMatchingObjects(goals, inactive_goals),
        [goals, inactive_goals]
    );

    const navigate = useNavigate();
    const location = useLocation();

    const [addTemplateModalOpened, setAddTemplateModalOpened] = useState(false);

    const onNewGoalClick = () => {
        navigate(`/configure/process/${id}/goal/new`, {
            state: {
                processName: processDetail.name,
                ...(location.state || {}),
            },
        });
    };

    return (
        <div className="w-full p-5 sm:p-8 bg-white rounded-2xl">
            <BlockHeader
                title="Active Goals"
                description="Unordered collection of goals available to run in this process"
                buttonText="Add Template"
                onButtonClick={() => setAddTemplateModalOpened(true)}
                withMarginBottom
                isEditable={isEditable}
            />

            {!!activeGoals?.length && (
                <ul className="flex flex-col gap-4 sm:gap-6 mb-4 sm:mb-6">
                    {activeGoals.map((goal, index) => {
                        const isLastCard = index === activeGoals.length - 1;
                        const shouldPopupBePositionedUp = isLastCard && activeGoals.length > 2;
                        return (
                            <GoalCard
                                key={goal.id}
                                goal={goal}
                                processDetail={processDetail}
                                setProcessDetail={setProcessDetail}
                                isEditable={isEditable}
                                shouldPopupBePositionedUp={shouldPopupBePositionedUp}
                            />
                        );
                    })}
                </ul>
            )}

            {!activeGoals?.length && (
                <p className="font-body-bold text-body-bold-s text-[#25324B] mt-[-16px] mb-5">
                    You don’t have any Goals in this Process yet.
                </p>
            )}

            {isEditable && (
                <div className="flex justify-center">
                    <Button
                        type="link"
                        size="xs"
                        text="New Goal"
                        leadingIcon={PlusCircleIcon}
                        onClick={onNewGoalClick}
                    />
                </div>
            )}

            {addTemplateModalOpened && (
                <AddGoalFromTemplateModal
                    processId={id}
                    onClose={() => setAddTemplateModalOpened(false)}
                />
            )}
        </div>
    );
};

export default ActiveGoalsBlock;
