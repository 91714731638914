export const defaultErrorMessage = 'Oops! Something went wrong. Please try again.';
export const accessDeniedErrorMessage = "Oops! You don't have access to perform this action";
// e.response.status

export const emptyFieldErrorMessage = 'Please fill in this field.';

export const invalidInputErrorMessage =
    'Oops! It looks like this input is not valid. Please update and try again.';

export const invalidJsonErrorMessage = 'Invalid JSON format. Please check your syntax.';
