import React from 'react';
import DocsPageHeader from '../DocsPageHeader/DocsPageHeader';
import FolderNavBar from '../FolderNavBar/FolderNavBar';

const FolderSidePanel = () => {
    return (
        <div className="hidden lg:flex flex-col w-[320px] min-w-[320px] h-full max-h-full px-5 py-4 gap-5 bg-white">
            <DocsPageHeader />

            <FolderNavBar />
        </div>
    );
};

export default FolderSidePanel;
