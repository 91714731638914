import React from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from 'design-system/ButtonIcon/ButtonIcon';
import ArrowLeftSLineIcon from 'design-system/Icons/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'design-system/Icons/ArrowRightSLineIcon';

TablePagination.propTypes = {
    data: PropTypes.shape({
        page: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
        totalItems: PropTypes.number.isRequired,
    }),
    onNextClick: PropTypes.func.isRequired,
    onPreviousClick: PropTypes.func.isRequired,
};

function TablePagination(props) {
    const {
        data: { page, limit, totalItems: total },
        onNextClick,
        onPreviousClick,
    } = props;
    const start = (page - 1) * limit + 1;
    const end = page * limit > total ? total : page * limit;

    const previousDisabled = start === 1 ? 'disabled' : 'default';
    const nextDisabled = end === total ? 'disabled' : 'default';

    return (
        <div className="flex items-center gap-[16px]">
            <p className="font-body text-body-regular-s text-black">{`${start}-${end} of ${total}`}</p>
            <div className="flex items-center gap-1">
                <ButtonIcon
                    icon={ArrowLeftSLineIcon}
                    type="ghost"
                    state={previousDisabled}
                    size="xs"
                    onClick={onPreviousClick}
                />
                <ButtonIcon
                    icon={ArrowRightSLineIcon}
                    type="ghost"
                    state={nextDisabled}
                    size="xs"
                    onClick={onNextClick}
                />
            </div>
        </div>
    );
}

export default TablePagination;
