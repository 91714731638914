import React, { useState } from 'react';

import * as API from '../../../../constants/api';
import client from '../../../../services/execute-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { Button } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import Alert from '../../../../design-system/Alert/Alert';
import NewDatasetRecordForm from './NewDatasetRecordForm/NewDatasetRecordForm';
import SuccessBlockWithLangSmithButton from '../../../../design-system/SuccessBlockWithLangSmithButton/SuccessBlockWithLangSmithButton';

const NewDatasetRecordModal = ({ datasetDetail, onClose }) => {
    const { id, inputKeys, outputKeys, organizationId } = datasetDetail;

    const initialInputsState = Object.fromEntries(inputKeys?.map((key) => [key, '']) || []);
    const initialOutputsState = Object.fromEntries(outputKeys?.map((key) => [key, '']) || []);

    const [formData, setFormData] = useState({
        inputs: initialInputsState,
        outputs: initialOutputsState,
    });
    const [langsmithUrl, setLangsmithUrl] = useState(null);

    const [formSubmissionState, setFormSubmissionState] = useState('default'); // success, loading, default
    const [errorAlert, setErrorAlert] = useState(null);

    const atLeastOneInputFieldIsFilled = Object.values(formData.inputs).some(Boolean);
    const atLeastOneOutputFieldIsFilled = Object.values(formData.outputs).some(Boolean);
    const atLeastOneFieldIsFilled = atLeastOneInputFieldIsFilled || atLeastOneOutputFieldIsFilled;

    const createButtonState =
        formSubmissionState === 'loading'
            ? 'loading'
            : atLeastOneFieldIsFilled
            ? 'default'
            : 'disabled';

    const handleCreateDatasetRecord = async () => {
        try {
            setFormSubmissionState('loading');
            const requestBody = { organization: organizationId, ...formData };
            const { data } = await client.post(
                `${API.ROUTES.execute.dataset}${id}/example/`,
                requestBody
            );
            setLangsmithUrl(data.data?.langsmith_url);
            setFormSubmissionState('success');
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
            setFormSubmissionState('default');
        }
    };

    const handleClearForm = () => {
        setFormData({ inputs: initialInputsState, outputs: initialOutputsState });
        setLangsmithUrl(null);
        setFormSubmissionState('default');
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="sm:p-2 flex flex-col gap-8">
                <ModalHeader onClose={onClose} title="New Dataset Record" />

                {formSubmissionState !== 'success' && (
                    <NewDatasetRecordForm
                        datasetDetail={datasetDetail}
                        formData={formData}
                        setFormData={setFormData}
                    />
                )}

                {formSubmissionState === 'success' && (
                    <SuccessBlockWithLangSmithButton
                        message="Recorded to dataset!"
                        langsmithUrl={langsmithUrl}
                    />
                )}

                <div className="flex items-center justify-between">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    {formSubmissionState !== 'success' && (
                        <Button
                            type="secondary"
                            size="sm"
                            text="Create"
                            state={createButtonState}
                            onClick={handleCreateDatasetRecord}
                        />
                    )}
                    {formSubmissionState === 'success' && (
                        <Button
                            type="secondary"
                            size="sm"
                            text="Create New"
                            onClick={handleClearForm}
                        />
                    )}
                </div>
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default NewDatasetRecordModal;
