import React, { useState } from 'react';

import { handleCopy } from '../../../../helpers/handleCopy';
import { formatKbRecordsForCopy } from '../../../../helpers/milestoneUtils';

import Alert from '../../../../design-system/Alert/Alert';
import PaginationBar from '../../../../design-system/PaginationBar/PaginationBar';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import { ButtonIcon } from '../../../../design-system';
import { FileCopyLineIcon, ShareBoxLineIcon } from '../../../../design-system/Icons';

const KnowledgeBaseContentPiece = ({ content }) => {
    const { data, is_filled, label } = content;

    const [page, setPage] = useState(1);
    const [copyAlert, setCopyAlert] = useState(null);

    if (is_filled && !data.records?.length) {
        return (
            <FormFieldWrapper label={label} gap={6}>
                <div className="px-4 py-8 rounded-2 border-1 border-neutral-300 flex justify-center font-body text-body-regular-m text-neutral-300 text-center">
                    No records found
                </div>
            </FormFieldWrapper>
        );
    }

    const { records } = data;

    const limit = 5;
    const recordsTotal = records.length;

    const firstDisplayedRecordIndex = (page - 1) * limit;
    const displayedRecords = records.slice(
        firstDisplayedRecordIndex,
        firstDisplayedRecordIndex + limit
    );

    const handleShareButtonClick = ({ base_id, base_item_id }) => {
        if (base_item_id) {
            window.open(`/base/${base_id}?entry=${base_item_id}`, '_blank');
            return;
        }

        window.open(`/base/${base_id}`, '_blank');
    };

    const handleCopyRecords = () => {
        const recordsToCopy = formatKbRecordsForCopy(records);
        handleCopy(recordsToCopy, setCopyAlert);
    };

    return (
        <div className="flex flex-col gap-1.5">
            <div className="flex items-center gap-2 justify-between">
                <p className="font-body-bold text-body-bold-s text-neutral-500">{label}</p>

                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={FileCopyLineIcon}
                    onClick={handleCopyRecords}
                />
            </div>

            <ul className="flex flex-col gap-2">
                {displayedRecords.map((record, index) => {
                    const { label, title, base_id, base_item_id, object_uuid } = record;
                    return (
                        <div
                            key={index}
                            className="px-3 sm:px-5 py-3 border-1 flex items-center justify-between rounded-2"
                        >
                            <p className="font-body-bold text-body-bold-s text-neutral-500 truncate mb-1">
                                {label || title}
                            </p>

                            {base_id && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    icon={ShareBoxLineIcon}
                                    onClick={() =>
                                        handleShareButtonClick({
                                            base_id,
                                            base_item_id: base_item_id || object_uuid,
                                        })
                                    }
                                />
                            )}
                        </div>
                    );
                })}
            </ul>
            {recordsTotal > limit && (
                <PaginationBar
                    page={page}
                    limit={limit}
                    total={recordsTotal}
                    handlePreviousClick={() => setPage((page) => page - 1)}
                    handleNextClick={() => setPage((page) => page + 1)}
                />
            )}

            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={2500}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </div>
    );
};

export default KnowledgeBaseContentPiece;
