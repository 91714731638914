import { VIEW_TYPES } from './viewTypes';

export const ORGANIZATION_PLAN = {
    plus: 'plus',
    pro: 'pro',
    agency: 'agency',
};

export const USER_ROLE = {
    owner: 'owner',
    admin: 'admin',
    member: 'member',
};

const ALL_USER_ROLES = [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member];

// ACCESS_CONFIG object indicates who has access to the admin and client viewTypes
export const ACCESS_CONFIG = {
    [VIEW_TYPES.admin]: {
        [ORGANIZATION_PLAN.plus]: {
            availableUserRoles: ALL_USER_ROLES,
        },
        [ORGANIZATION_PLAN.pro]: {
            availableUserRoles: ALL_USER_ROLES,
        },
        [ORGANIZATION_PLAN.agency]: { allowedIsStaffValues: [true] },
    },
    [VIEW_TYPES.client]: {
        [ORGANIZATION_PLAN.plus]: { availableUserRoles: [] },
        [ORGANIZATION_PLAN.pro]: { availableUserRoles: [] },
        [ORGANIZATION_PLAN.agency]: { allowedIsStaffValues: [true, false] },
    },
};

export const ORGANIZATION_ID_LS_KEY = 'organization_id';
