import React from 'react';

const CustomBotMessageHeader = ({ customBotVersion }) => {
    const botName = customBotVersion?.custom_bot?.name;

    if (!botName) {
        return <></>;
    }

    return (
        <div className="flex items-center min-h-[25px]">
            <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase">{botName}</p>
        </div>
    );
};

export default CustomBotMessageHeader;
