import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useWindowSize } from '../../hooks/useWindowSize';

DetailContainer.propTypes = {
    label: PropTypes.string.isRequired,
    desktopStartPixel: PropTypes.number,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // can be passed in pixels or in percentage (150 or "20%")
    customStyles: PropTypes.string,
    isTruncated: PropTypes.bool,
    isBreakWord: PropTypes.bool,
    isMobileView: PropTypes.bool,
    labelCustomStyles: PropTypes.string,
};

function DetailContainer({
    label,
    maxWidth,
    desktopStartPixel = 1024,
    children,
    customStyles,
    isTruncated = true,
    isBreakWord = false, // use isBreakWord = true with ids to bleak a long string
    isMobileView = false,
    labelCustomStyles = null,
}) {
    const { width: screenWidth } = useWindowSize();
    const isDesktop = isMobileView ? false : screenWidth >= desktopStartPixel;

    const formattedMaxWidth = typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth;
    const maxWidthStyle = maxWidth && isDesktop ? formattedMaxWidth : '100%';

    const containerClassName = classNames('flex', customStyles, {
        'flex-col gap-1': isDesktop,
        'flex-row items-center gap-1.5': !isDesktop,
    });
    const childrenClassName = classNames('font-body-bold text-body-bold-xs text-neutral-500', {
        truncate: isTruncated,
        'break-all': !isTruncated && isBreakWord,
        'break-word': !isTruncated && !isBreakWord,
    });

    const labelClassNames = classNames(
        'font-body text-body-regular-xs text-neutral-300 whitespace-nowrap',
        labelCustomStyles
    );

    return (
        <div className={containerClassName} style={{ maxWidth: maxWidthStyle }}>
            <p className={labelClassNames}>{label}</p>
            <div className={childrenClassName}>{children}</div>
        </div>
    );
}

export default DetailContainer;
