import React from 'react';
import classNames from 'classnames';

import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import FormFieldWrapper from '../../../design-system/FormFieldWrapper/FormFieldWrapper';
import Radio from '../../../design-system/Radio/Radio';
import Checkbox from '../../../design-system/Checkbox/Checkbox';
import NewRichTextArea from '../../../design-system/NewRichTextArea/NewRichTextArea';

const MessageContentForm = ({
    formData,
    setFormData = () => {},
    editable,
    errorFormFields = {},
    setErrorFormFields = () => {},
    bodyTypeFormFieldsCount,
}) => {
    const handleFreeformChange = (id, value) => {
        setFormData((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, value } : item))
        );
        setErrorFormFields((prevData) => ({ ...prevData, [id]: false }));
    };

    const handleSingleSelectChange = (id, value) => {
        setFormData((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, value } : item))
        );
        if (errorFormFields[id]) {
            setErrorFormFields((prevData) => ({ ...prevData, [id]: false }));
        }
    };

    const handleMultiSelectChange = (id, optionValue) => {
        setFormData((prevData) =>
            prevData.map((item) => {
                if (item.id === id) {
                    const currentValue = Array.isArray(item.value) ? item.value : [item.value];
                    const newValue = currentValue.includes(optionValue)
                        ? currentValue.filter((el) => el !== optionValue)
                        : [...currentValue, optionValue];
                    return { ...item, value: newValue };
                } else {
                    return item;
                }
            })
        );
    };

    return (
        <>
            {formData?.map(({ id, action_type, label, options, value }, index) => {
                const formFieldContainerClassName = classNames({
                    'pb-6': index <= bodyTypeFormFieldsCount - 1,
                    'border-b-1 border-neutral-200': index === bodyTypeFormFieldsCount - 1,
                    'pt-6': index === bodyTypeFormFieldsCount && index > 0,
                    'pb-4': index >= bodyTypeFormFieldsCount && index < formData.length - 1,
                });
                return (
                    <div key={id} className={formFieldContainerClassName}>
                        {action_type === 'body' && (
                            <RichTextArea
                                value={value}
                                minHeight={20}
                                autoExpand
                                previewOnly
                                isBorderHidden
                                openLinksInNewTab
                            />
                        )}
                        {action_type === 'freeform' && (
                            <FormFieldWrapper
                                label={<strong>{label}</strong>}
                                showLabelAsBadge
                                gap={8}
                                withCopyButton
                                labelToCopyAlert={label}
                                value={value}
                                state={
                                    !editable
                                        ? 'disabled'
                                        : errorFormFields[id]
                                        ? 'error'
                                        : 'default'
                                }
                                errorMessage="Please fill in this field"
                            >
                                <NewRichTextArea
                                    value={value}
                                    state={
                                        !editable
                                            ? 'disabled'
                                            : errorFormFields[id]
                                            ? 'error'
                                            : 'default'
                                    }
                                    placeholder="Write here"
                                    setValue={(value) => handleFreeformChange(id, value)}
                                    minHeight={20}
                                    autoExpand
                                />
                            </FormFieldWrapper>
                        )}
                        {action_type === 'single-select' && (
                            <FormFieldWrapper
                                label={<strong>{label}</strong>}
                                showLabelAsBadge
                                state={
                                    !editable
                                        ? 'disabled'
                                        : errorFormFields[id]
                                        ? 'error'
                                        : 'default'
                                }
                                errorMessage="Please select an option"
                                gap={6}
                            >
                                <ul className="flex flex-col gap-1">
                                    {Object.entries(options)?.map(([key, optionValue], index) => (
                                        <div key={index} className="py-1 px-2">
                                            <Radio
                                                isSelected={optionValue === value}
                                                name={optionValue}
                                                onChange={() =>
                                                    handleSingleSelectChange(id, optionValue)
                                                }
                                                label={key}
                                                state={!editable ? 'disabled' : 'default'}
                                            />
                                        </div>
                                    ))}
                                </ul>
                            </FormFieldWrapper>
                        )}
                        {action_type === 'multi-select' && (
                            <FormFieldWrapper
                                label={<strong>{label}</strong>}
                                showLabelAsBadge
                                gap={6}
                            >
                                <ul className="flex flex-col gap-1">
                                    {Object.entries(options)?.map(([key, option], index) => (
                                        <div key={index} className="py-1 px-2">
                                            <Checkbox
                                                isChecked={value.includes(option)}
                                                name={option}
                                                onChange={() => handleMultiSelectChange(id, option)}
                                                label={key}
                                                state={!editable ? 'disabled' : 'default'}
                                            />
                                        </div>
                                    ))}
                                </ul>
                            </FormFieldWrapper>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default MessageContentForm;
