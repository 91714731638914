import React, { useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { threadStatusBadge } from '../../../../constants/threads';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import { ButtonIcon } from '../../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../../design-system/Icons';
import DetailContainer from '../../../../design-system/DetailContainer/DetailContainer';
import ExecutionMoreOptionButton from '../ExecutionMoreOptionButton/ExecutionMoreOptionButton';

const ExecutionDetailsBlock = ({ execution, setActionModal }) => {
    const { id, status, updated_at, created_at } = execution;

    const [areDetailsShown, setAreDetailsShown] = useState(false);

    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 640 });

    const moreDetailsData = [
        { name: 'Status', value: threadStatusBadge[status]?.text },
        {
            name: 'Last Updated',
            value: DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - HH:mm:ss'),
        },
        {
            name: 'Created At',
            value: DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - hh:mm a'),
        },
        {
            name: 'Execution ID',
            value: id,
        },
    ];

    const containerClassName = classNames(
        'sm:hidden flex items-center justify-between',
        areDetailsShown ? 'mb-3' : 'mb-0'
    );

    return (
        <div className="px-5 sm:px-6 py-2 sm:py-3 border-b-1 border-neutral-200">
            <div className={containerClassName}>
                <p className="font-body-bold text-body-bold-s text-black">More details</p>
                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={areDetailsShown ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                    onClick={() => setAreDetailsShown((prevState) => !prevState)}
                />
            </div>
            {(areDetailsShown || !isMobile) && (
                <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col gap-y-3 sm:flex-row gap-x-[40px] sm:gap-y-1.5 sm:flex-wrap">
                        {moreDetailsData?.map((item) => (
                            <DetailContainer
                                key={item.name}
                                label={item.name}
                                isTruncated={false}
                                isMobileView
                                isBreakWord={item.name === 'Execution ID'}
                            >
                                {item.value}
                            </DetailContainer>
                        ))}
                    </div>

                    <div className="hidden sm:block">
                        <ExecutionMoreOptionButton setActionModal={setActionModal} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExecutionDetailsBlock;
