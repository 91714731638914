import { useEffect, useRef } from 'react';

import { useSwrRequestKeysToMutateFromContext } from './useSwrRequestKeysToMutateFromContext';
import { addUniqueElementToArray } from '../helpers/addUniqueElementToArray';
import { mergeTwoArraysRemovingDuplicates } from '../helpers/mergeTwoArraysRemovingDuplicates';

// useSwrPageRequestKeysWithContextSaving hook manages and updates request keys state associated with a specific page (utilizing context to persist keys between page navigations)
// to use this hook add an appropriate pageAccessor in context/SwrRequestKeysToMutateProvider.js
export const useSwrPageRequestKeysManagingWithContextSaving = ({
    pageAccessor,
    key,
    nextPageKey,
}) => {
    // we use ref to save data and reduce redundant rerender
    const requestKeysToMutate = useRef([]);

    const {
        requestKeysToMutate: { [pageAccessor]: requestKeysToMutateFromContext },
        setRequestKeysToMutate,
    } = useSwrRequestKeysToMutateFromContext();

    const setRequestKeysToMutateFromContext = (cb) => {
        setRequestKeysToMutate((prevData) => ({
            ...prevData,
            [pageAccessor]: typeof cb === 'function' ? cb(prevData[pageAccessor]) : cb,
        }));
    };

    useEffect(() => {
        if (key) {
            // add new key to requestKeysToMutate list
            requestKeysToMutate.current = addUniqueElementToArray(key, requestKeysToMutate.current);
        }
    }, [key]);

    useEffect(() => {
        if (nextPageKey) {
            // add auto fetched next page key to requestKeysToMutate list
            requestKeysToMutate.current = addUniqueElementToArray(
                nextPageKey,
                requestKeysToMutate.current
            );
        }
    }, [nextPageKey]);

    useEffect(() => {
        // if there are [pageAccessor] swr keys saved in context, set them into current requestKeysToMutate ref
        // In context, saved swr keys are only stored to persist them between pages, while current swr keys will be stored in requestKeysToMutate ref (to preserve performance and avoid updating the context every time a new key is added)
        // In the context, data will only be updated when the Page is unmounted, so that it can be used subsequently.
        if (!!requestKeysToMutateFromContext?.length) {
            requestKeysToMutate.current = mergeTwoArraysRemovingDuplicates(
                requestKeysToMutate.current,
                requestKeysToMutateFromContext
            );
        }

        // on component umount update saved swr keys in context
        return () => {
            setRequestKeysToMutateFromContext(requestKeysToMutate.current);
        };
    }, []);

    return { requestKeysToMutate: requestKeysToMutate.current || [] };
};
