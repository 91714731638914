let HOST_URL;
let SOCKET_URL;
const baseUrl = '/api/ws/';

if (process.env.NODE_ENV === 'production') {
    const host = window.location.host;
    SOCKET_URL = `wss://${host}${baseUrl}`;
    HOST_URL = '/api/';
} else {
    HOST_URL = 'http://127.0.0.1:8000';
    SOCKET_URL = `ws://127.0.0.1:8000${baseUrl}`;
}

export { HOST_URL, SOCKET_URL };
