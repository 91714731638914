import React from 'react';

const ListCheckIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M5.33333 2.66634H14V3.99967H5.33333V2.66634ZM2 2.33301H4V4.33301H2V2.33301ZM2 6.99967H4V8.99967H2V6.99967ZM2 11.6663H4V13.6663H2V11.6663ZM5.33333 7.33301H14V8.66634H5.33333V7.33301ZM5.33333 11.9997H14V13.333H5.33333V11.9997Z"
                fill={props.color}
            />
            <path
                d="M5.33333 2.66634H14V3.99967H5.33333V2.66634ZM2 2.33301H4V4.33301H2V2.33301ZM2 6.99967H4V8.99967H2V6.99967ZM2 11.6663H4V13.6663H2V11.6663ZM5.33333 7.33301H14V8.66634H5.33333V7.33301ZM5.33333 11.9997H14V13.333H5.33333V11.9997Z"
                fill={props.color}
                fillOpacity="0.2"
            />
        </svg>
    );
};

export default ListCheckIcon;
