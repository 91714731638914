import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTextareaAutoResize } from '../../hooks/useTextareaAutoResize';

import { handleCopy } from '../../helpers/handleCopy';
import { defaultErrorMessage } from '../../constants/errorMessages';

import Alert from '../../design-system/Alert/Alert';
import EditLineIcon from '../../design-system/Icons/EditLineIcon';
import { Button, ButtonIcon } from '../../design-system';
import { ErrorWarningLineIcon, FileCopyLineIcon } from '../../design-system/Icons';

const TextAreaWithEditControls = ({ initialValue, label, handleSave: _handleSave }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [savedValue, setSavedValue] = useState(initialValue);
    const [editedValue, setEditedValue] = useState(initialValue);

    const [isLoading, setIsLoading] = useState(false);

    const [copyAlert, setCopyAlert] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const { textareaRef, textareaContainerRef } = useTextareaAutoResize({
        text: editedValue,
        minHeight: 86,
    });

    useEffect(() => {
        setSavedValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (isEditing && textareaRef.current) {
            textareaRef.current.focus();

            // Set the cursor at the end of the text
            textareaRef.current.setSelectionRange(
                textareaRef.current.value.length,
                textareaRef.current.value.length
            );
        }
    }, [isEditing, textareaRef]);

    const handleCancel = () => {
        setIsEditing(false);
        setEditedValue(savedValue);
    };

    const handleSave = async () => {
        if (editedValue === savedValue) {
            setIsEditing(false);
            return;
        }

        const correctSavedValue = savedValue;

        try {
            setIsLoading(true);

            setSavedValue(editedValue);
            setIsEditing(false);

            await _handleSave(editedValue);

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);

            setSavedValue(correctSavedValue);
            setEditedValue(correctSavedValue);

            setErrorAlert({
                message: defaultErrorMessage,
                statusCode: e.response?.status,
            });
        }
    };

    return (
        <div className="flex flex-col gap-1.5">
            <div className="flex items-center justify-between">
                <p className="font-body-bold text-body-bold-m text-neutral-500">{label}</p>

                <div className="flex items-center gap-1">
                    <div className={isEditing ? 'opacity-0' : 'opacity-100'}>
                        <ButtonIcon
                            type="link"
                            size="xs"
                            state="default"
                            icon={EditLineIcon}
                            onClick={() => setIsEditing(true)}
                        />
                    </div>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        state={!editedValue ? 'disabled' : 'default'}
                        icon={FileCopyLineIcon}
                        onClick={() => handleCopy(editedValue, setCopyAlert)}
                    />
                </div>
            </div>

            <div className="flex flex-col max-w-full" ref={textareaContainerRef}>
                <textarea
                    ref={textareaRef}
                    rows={1}
                    value={editedValue}
                    className="w-full px-3 py-[18px] font-body text-body-regular-m text-neutral-500 disabled:text-neutral-300 rounded-2 shadow-inset-1 shadow-neutral-300 disabled:shadow-neutral-200 focus:outline-0 resize-none bg-transparent disabled:bg-neutral-50 placeholder-neutral-300"
                    disabled={!isEditing}
                    autoFocus={isEditing}
                    onChange={(e) => setEditedValue(e.target.value)}
                />
            </div>

            {isEditing && (
                <div className="flex items-center justify-between gap-2 mt-1.5">
                    <Button type="neutral" size="xs" text="Cancel" onClick={handleCancel} />
                    <Button
                        type="secondary"
                        size="xs"
                        text="Save"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleSave}
                    />
                </div>
            )}

            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={2500}
                    handleClose={() => setCopyAlert(null)}
                />
            )}

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

TextAreaWithEditControls.propTypes = {
    initialValue: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleSave: PropTypes.func.isRequired,
};

export default TextAreaWithEditControls;
