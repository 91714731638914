import { ModelOptions, TemperatureOptions } from 'constants';

// this maps the user's available models to the format expected by the Select component

export const getPromptSettings = (settings) => {
    // if no settings or no model, return default settings
    if (!settings || !settings.model) {
        return {
            model: ModelOptions[0],
            maxTokens: 1000,
            temperature: TemperatureOptions[0],
        };
    } else {
        const mappedModelOption = ModelOptions.find((option) => option.model === settings.model);
        const mappedTemperature = TemperatureOptions.find(
            (option) => option.value === settings.temperature
        );
        return {
            model: mappedModelOption || ModelOptions[0],
            maxTokens: settings.max_tokens || null,
            temperature: mappedTemperature || TemperatureOptions[0],
        };
    }
};
