import React from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import {
    getNewBlockObject,
    getNewPlaybookSectionObject,
    getTargetBlockData,
    removeBlockFromSection,
} from '../../../helpers/playbookBuilderUtils';

import { SvgIcon } from '../../../design-system';
import { AddLineIcon } from '../../../design-system/Icons';

const AddNewSectionZone = ({ setPlaybookContentFormData, areSectionsExpandedState }) => {
    const [areSectionsExpanded, setAreSectionsExpanded] = areSectionsExpandedState;

    const [{ isOver }, drop] = useDrop({
        accept: ['NEW_BLOCK', 'BLOCK'],
        drop: handleDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    function handleDrop(item) {
        const { dndType, blockType, blockTemporaryId } = item;

        if (dndType === 'NEW_BLOCK') {
            addNewSectionWithEmptyBlock({ blockType });
        }

        if (dndType === 'BLOCK') {
            const currentSectionTemporaryId = item.sectionTemporaryId;
            addNewSectionWithBlockFromAnotherSection({
                currentSectionTemporaryId,
                blockTemporaryId,
            });
        }
    }

    function getAndExpandNewSection({ prevData, block_content = [] }) {
        const newSectionOrder = prevData.length + 1;
        const newSection = getNewPlaybookSectionObject(newSectionOrder, block_content);

        setAreSectionsExpanded({ ...areSectionsExpanded, [newSection.temporaryId]: true });

        return newSection;
    }

    function handleAddNewSection({ block_content = [] }) {
        setPlaybookContentFormData((prevData) => {
            const newSection = getAndExpandNewSection({ prevData, block_content });
            return [...prevData, newSection];
        });
    }

    function addNewSectionWithEmptyBlock({ blockType }) {
        const newBlock = getNewBlockObject({ blockType });
        handleAddNewSection({ block_content: [newBlock] });
    }

    function addNewSectionWithBlockFromAnotherSection({
        currentSectionTemporaryId,
        blockTemporaryId,
    }) {
        setPlaybookContentFormData((prevData) => {
            const blockData = getTargetBlockData({
                currentSectionTemporaryId,
                blockTemporaryId,
                sectionsList: prevData,
            });
            if (!blockData) {
                return prevData;
            }

            const updatedSectionsList = prevData.map((section) =>
                section.temporaryId === currentSectionTemporaryId
                    ? removeBlockFromSection(section, blockTemporaryId)
                    : section
            );

            const blockDataWithResetOrder = { ...blockData, order: 1 };
            const newSection = getAndExpandNewSection({
                prevData,
                block_content: [blockDataWithResetOrder],
            });

            return [...updatedSectionsList, newSection];
        });
    }

    const buttonClassName = classNames(
        'w-full flex items-center gap-2.5 rounded-2.5 p-6 border-1 border-dashed font-body text-body-regular-m text-neutral-300 transition-colors',
        {
            'bg-purple-100 border-purple-300': isOver,
            'bg-neutral-50 border-neutral-200': !isOver,
        }
    );

    return (
        <div ref={drop} className="lg:ml-[27px]">
            <button
                type="button"
                className={buttonClassName}
                onClick={() => handleAddNewSection({})}
            >
                <SvgIcon icon={AddLineIcon} color="#5E6470" size="large" />
                Add new section
            </button>
        </div>
    );
};

export default AddNewSectionZone;
