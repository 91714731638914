import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useViewType } from '../../../hooks/useViewType';

import { ListOption, ListOptionGroup } from '../../../design-system';

const ThreadCardMoreOptionsPopup = ({ onClose, threadId, threadStatus, setActionModal }) => {
    const navigate = useNavigate();
    const { toggleViewType } = useViewType();

    const isViewJobDetailButtonDisplayed =
        threadStatus !== 'canceled' && threadStatus !== 'archived';

    const navigateToJobDetailPage = () => {
        toggleViewType();
        navigate(`/job/${threadId}`);
    };

    const openModal = (action) => {
        setActionModal({ action });
        onClose();
    };

    return (
        <div
            className="absolute top-[42px] right-[4px] z-55 pb-4"
            onClick={(e) => e.preventDefault()}
        >
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editIcon"
                            text="Edit Name"
                            onClick={() => openModal('rename')}
                        />
                        <ListOption
                            leadingIconName="skipBackIcon"
                            text="Restart Thread"
                            onClick={() => openModal('restart')}
                        />
                        <ListOption
                            leadingIconName="squareIcon"
                            text="Cancel Thread"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={() => openModal('cancel')}
                        />
                        {isViewJobDetailButtonDisplayed && (
                            <ListOption
                                leadingIconName="playCircleIcon"
                                text="View Job Details"
                                onClick={navigateToJobDetailPage}
                            />
                        )}
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};

export default ThreadCardMoreOptionsPopup;
