import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { Button } from '../../../../design-system';
import { ArrowGoBackLineIcon } from '../../../../design-system/Icons';
import MoreOptionsControls from '../../../../components/MoreOptionsControls/MoreOptionsControls';
import BotMoreOptionPopup from '../../BotMoreOptionPopup/BotMoreOptionPopup';
import ClockIcon from '../../../../design-system/Icons/ClockIcon';

const BotDetailHeader = ({ botDetail, backlinkHref, setActionModal }) => {
    const { name, description, updated_at } = botDetail;

    const navigate = useNavigate();

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy');

    return (
        <div className="px-5 py-4 xs:p-5 sm:p-6 lg:p-8 bg-purple-100 max-w-full flex flex-col gap-4 lg:gap-6">
            <div className="flex items-center gap-2 justify-between">
                <Button
                    type="link"
                    size="sm"
                    text="Back"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(backlinkHref)}
                />

                <MoreOptionsControls
                    popupComponent={BotMoreOptionPopup}
                    setActionModal={setActionModal}
                />
            </div>

            <div className="w-full max-w-[640px] flex flex-col gap-2">
                <h1 className="font-heading-bold text-heading-bold-l text-black">{name}</h1>

                <div className="flex items-center gap-1">
                    <ClockIcon width={14} height={14} color="#754DCF" />
                    <p className="font-body text-body-regular-xs text-neutral-500">
                        Last Updated{' '}
                        <span className="font-body-bold text-body-bold-xs">{formattedTime}</span>
                    </p>
                </div>

                <p className="font-body text-body-regular-m text-neutral-500">{description}</p>
            </div>
        </div>
    );
};

export default BotDetailHeader;
