import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const OrganizationTag = ({ name, size = 'xs', color = 'purple' }) => {
    if (!name) return null;

    const tagClassName = classNames('flex items-center justify-center', {
        'w-5 h-5 min-w-[20px] rounded-1': size === 'xs',
        'w-[26px] h-[26px] min-w-[26px] border-1 border-neutral-50 rounded-2': size === 'sm',
        'bg-purple-500': color === 'purple',
        'bg-neutral-300': color === 'neutral',
    });
    const tagLetterClassName = classNames('text-white uppercase', {
        'font-body text-body-regular-xs': size === 'xs',
        'font-body text-body-bold-m': size === 'sm',
    });
    const labelClassName = classNames('truncate', {
        'font-body-bold text-body-bold-xs': size === 'xs',
        'font-body text-body-regular-m': size === 'sm',
        'text-neutral-500': color !== 'neutral',
        'text-neutral-300': color === 'neutral',
    });
    const containerClassName = classNames('flex items-center', {
        'gap-1': size === 'xs',
        'gap-2': size === 'sm',
    });

    return (
        <div className={containerClassName}>
            <div className={tagClassName}>
                <span className={tagLetterClassName}>{name?.[0]}</span>
            </div>

            <p className={labelClassName}>{name}</p>
        </div>
    );
};

OrganizationTag.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['xs', 'sm']),
    color: PropTypes.oneOf(['purple', 'neutral']),
};

export default OrganizationTag;
