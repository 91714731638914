import { useEffect, useRef } from 'react';
import { addUniqueElementToArray } from '../helpers/addUniqueElementToArray';

export const useCollectRequestKeys = ({ key, nextPageKey }) => {
    const requestKeysToMutate = useRef([]);

    useEffect(() => {
        if (key) {
            requestKeysToMutate.current = addUniqueElementToArray(key, requestKeysToMutate.current);
        }
    }, [key]);

    useEffect(() => {
        if (nextPageKey) {
            requestKeysToMutate.current = addUniqueElementToArray(
                nextPageKey,
                requestKeysToMutate.current
            );
        }
    }, [nextPageKey]);

    return { requestKeysToMutate: requestKeysToMutate.current || [] };
};
