import { useEffect, useState } from 'react';

export const useClientSideFilterBar = ({ options, showClientSideFilterBar }) => {
    const [filteredOptions, setFilteredOptions] = useState(options || []);

    useEffect(() => {
        if (!showClientSideFilterBar) {
            setFilteredOptions(options || []);
        }
    }, [options, showClientSideFilterBar]);

    return { filteredOptions, setFilteredOptions };
};
