import React, { useRef, useState } from 'react';
import useUser from '../../../../hooks/useUser';
import { USER_ROLE } from '../../../../constants/organization';

import { ButtonIcon, SvgIcon } from '../../../../design-system';
import BookmarkFillIcon from '../../../../design-system/Icons/BookmarkFillIcon';
import { More2FillIcon } from '../../../../design-system/Icons';

const CollectionCard = ({
    item,
    selected = false,
    handleSelect,
    withDropdownCardInfo,
    setWithDropdownCardInfo,
}) => {
    const { userRole } = useUser();
    const viewOnlyMode = userRole === USER_ROLE.member;

    const [hover, setHover] = useState(false);
    const buttonRef = useRef(null);
    const showMoreOptions =
        withDropdownCardInfo.showMoreOptions && withDropdownCardInfo.data?.id === item.id;

    const handleShowMore = (e) => {
        e.stopPropagation();

        if (buttonRef.current) {
            const { top, left } = buttonRef.current.getBoundingClientRect();
            setWithDropdownCardInfo({
                top,
                left,
                data: item,
                showMoreOptions: true,
            });
        }
    };

    return (
        <div
            className={`w-full h-[36px] px-2 py-1 rounded-2 flex gap-2 items-center cursor-pointer transition-colors ${
                selected || hover || showMoreOptions ? 'bg-neutral-50' : 'bg-white'
            }`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => handleSelect(item.id)}
        >
            <SvgIcon color={item.icon_color} size="medium" icon={BookmarkFillIcon} />
            <p className="font-body text-body-regular-s text-neutral-500 truncate flex-grow">
                {item.name}
            </p>
            {(hover || showMoreOptions) && !viewOnlyMode && (
                <div ref={buttonRef}>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={handleShowMore}
                    />
                </div>
            )}
        </div>
    );
};

export default CollectionCard;
