export const getAdminProcessOverviewTabs = (isEditable) => {
    if (isEditable) {
        return [
            { name: 'Configure', key: 'configure' },
            { name: 'Form', key: 'form' },
            { name: 'Admin', key: 'admin' },
        ];
    }

    return [
        { name: 'Configure', key: 'configure' },
        { name: 'Form', key: 'form' },
        { name: 'Settings', key: 'settings' },
        { name: 'Admin', key: 'admin' },
    ];
};
