import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Badge, ButtonIcon } from '../index';
import { AddLineIcon } from '../Icons';
import Select from '../Select/Select';
import PaginatedSelect from '../PaginatedSelect/PaginatedSelect';
import SvgIcon from '../SvgIcon/SvgIcon';
import ErrorWarningLineIcon from '../Icons/ErrorWarningLineIcon';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

MultiSelect.propTypes = {
    name: PropTypes.string,
    addedOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    optionsForSelect: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    optionsLoading: PropTypes.bool,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    handleAddNewOption: PropTypes.func,
    handleRemoveOption: PropTypes.func,
    addedOptionsBadgeColor: PropTypes.oneOf([
        'neutral',
        'blue',
        'purple',
        'peach',
        'lime',
        'white',
        'red',
    ]),
    includeClientSideFiltering: PropTypes.bool,
    usePaginatedSelect: PropTypes.bool,
    fetchOptions: PropTypes.func, // required if usePaginatedSelect = True
    canLoadMore: PropTypes.bool, // required if usePaginatedSelect = True
    totalOptionsCount: PropTypes.number, // required if usePaginatedSelect = True
    shouldHideOptionsInfo: PropTypes.bool,
};

function MultiSelect({
    name,
    addedOptions: _addedOptions,
    optionsForSelect,
    optionsLoading = false,
    label,
    isRequired,
    placeholder = 'Select an option and click + to add them',
    state,
    errorMessage,
    tipText,
    handleAddNewOption,
    handleRemoveOption,
    addedOptionsBadgeColor = 'neutral',
    includeClientSideFiltering = false,
    usePaginatedSelect = false,
    fetchOptions,
    canLoadMore = false,
    totalOptionsCount = 0,
    initiallyAddedOptionsInfoIfPaginatedSelect = [], // formatted as options
    shouldHideOptionsInfo = false,
}) {
    const [selectedItem, setSelectedItem] = useState(null);
    const addedOptions = typeof _addedOptions === 'string' ? [] : _addedOptions;

    // If the select is paginated, initially selected options may not be loaded yet, causing them not to be displayed. To solve this issue, the addedOptionsInfoIfPaginatedSelect state is used.
    const [addedOptionsInfoIfPaginatedSelect, setAddedOptionsInfoIfPaginatedSelect] = useState(
        initiallyAddedOptionsInfoIfPaginatedSelect
    );

    // remove already selected options for list
    const filteredOptionsForSelect = useMemo(
        () => optionsForSelect.filter((item) => !addedOptions?.includes(item.id)),
        [addedOptions, optionsForSelect]
    );

    // get added options info to display in the bottom badges (if select is not paginated)
    const addedOptionsInfo = useMemo(() => {
        // create an object for fast access to elements of optionsForSelect by their ids
        const optionsMap = {};
        optionsForSelect.forEach((item) => {
            optionsMap[item.id] = item;
        });

        // use map() to preserve the sequence of elements from the addedOptions array
        return addedOptions?.map((optionId) => optionsMap[optionId]) || [];
    }, [addedOptions, optionsForSelect]);

    const addNewOption = () => {
        if (!selectedItem) {
            return;
        }

        if (!usePaginatedSelect) {
            handleAddNewOption(selectedItem);
        }
        if (usePaginatedSelect) {
            // find selected option info and set to setAddedOptionsInfoIfPaginatedSelect
            const selectedOption = optionsForSelect.find((option) => option.id === selectedItem);
            setAddedOptionsInfoIfPaginatedSelect((prevOptions) => [...prevOptions, selectedOption]);

            handleAddNewOption(selectedItem, selectedOption);
        }
        setSelectedItem(null);
    };

    const removeOption = (id) => {
        if (state === 'disabled') return;
        handleRemoveOption(id);

        if (usePaginatedSelect) {
            // delete removed option from addedOptionsInfoIfPaginatedSelect
            setAddedOptionsInfoIfPaginatedSelect((prevOptions) =>
                prevOptions.filter((option) => option.id !== id)
            );
        }
    };

    const addedOptionsInfoToDisplay = usePaginatedSelect
        ? addedOptionsInfoIfPaginatedSelect
        : addedOptionsInfo;

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
                <div className={`flex gap-2 items-end`}>
                    {usePaginatedSelect ? (
                        <PaginatedSelect
                            size="sm"
                            name={name || 'option'}
                            options={filteredOptionsForSelect}
                            optionsLoading={optionsLoading}
                            value={selectedItem}
                            label={label}
                            state={state}
                            placeholder={placeholder}
                            isRequired={isRequired}
                            onChange={setSelectedItem}
                            fetchOptions={fetchOptions}
                            canLoadMore={canLoadMore}
                            includeClientSideFiltering
                            totalOptionsCount={totalOptionsCount}
                        />
                    ) : (
                        <Select
                            size="sm"
                            name={name || 'option'}
                            options={filteredOptionsForSelect}
                            optionsLoading={optionsLoading}
                            value={selectedItem}
                            label={label}
                            state={state}
                            placeholder={placeholder}
                            isRequired={isRequired}
                            onChange={setSelectedItem}
                            includeClientSideFiltering={includeClientSideFiltering}
                        />
                    )}
                    <ButtonIcon
                        type="ghost"
                        size="md"
                        state={state}
                        icon={AddLineIcon}
                        onClick={addNewOption}
                    />
                </div>

                {state === 'error' && errorMessage && <ErrorMessage message={errorMessage} />}

                {tipText && (state !== 'error' || !errorMessage) && (
                    <div className="flex items-center gap-1">
                        <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                        <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                    </div>
                )}
            </div>

            {!!addedOptionsInfoToDisplay?.length && !shouldHideOptionsInfo && (
                <ul className="flex flex-wrap gap-x-2 gap-y-1">
                    {addedOptionsInfoToDisplay.map((option) => (
                        <li key={option?.id}>
                            <Badge
                                text={
                                    <span className="p-0.5 text-body-regular-xs">
                                        {option?.name}
                                    </span>
                                }
                                color={addedOptionsBadgeColor}
                                removeable
                                id={option?.id}
                                handleRemove={removeOption}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default MultiSelect;
