import React, { useState } from 'react';
import classNames from 'classnames';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { useDraggableReorderableItem } from '../../../hooks/useDraggableReorderableItem';
import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import PlaybookSection from '../PlaybookSection/PlaybookSection';
import DraggableLineIcon from '../../../design-system/Icons/DraggableLineIcon';

const DraggablePlaybookSection = ({
    sectionData,
    setPlaybookContentFormData,
    mode,
    ...restProps
}) => {
    const { isDesktop } = useResponsiveBreakpoints();
    const [isHovered, setIsHovered] = useState(false);

    const { itemRef, dragIconRef, isDragging, handlerId } = useDraggableReorderableItem({
        itemType: 'SECTION',
        item: sectionData,
        setData: setPlaybookContentFormData,
    });

    const isDragAndDropEnabled = isDesktop && mode === PLAYBOOK_BUILDER_MODE.edit;

    const sectionContainerClassName = classNames('flex', {
        'opacity-0': isDragging,
        'opacity-100': !isDragging,
        'lg:pl-[27px]': !isDragAndDropEnabled,
    });
    const iconContainerClassName = classNames('pt-4 cursor-grab transition-opacity', {
        'opacity-100': isHovered,
        'opacity-0': !isHovered,
        block: isDragAndDropEnabled,
        hidden: !isDragAndDropEnabled,
    });

    return (
        <div
            id={sectionData.temporaryId}
            ref={itemRef}
            className={sectionContainerClassName}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                ref={dragIconRef}
                data-handler-id={handlerId}
                draggable={isDragAndDropEnabled}
                className={iconContainerClassName}
            >
                <DraggableLineIcon width={27} height={24} color="#000000" />
            </div>

            <PlaybookSection
                sectionData={sectionData}
                setPlaybookContentFormData={setPlaybookContentFormData}
                mode={mode}
                isDragAndDropEnabled={isDragAndDropEnabled}
                {...restProps}
            />
        </div>
    );
};

export default DraggablePlaybookSection;
