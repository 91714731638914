import React from 'react';
import { Badge } from 'design-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTaskTypeBadge } from '../../helpers/getTaskTypeBadge';
import { TASK_TYPES } from '../../constants/library';

const RecentViewedCard = ({ item, closeModal }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const badge =
        item.type === 'prompt'
            ? getTaskTypeBadge({ taskType: 'prompt', model: item.model })
            : getTaskTypeBadge({ taskType: item.taskType });

    const handleCardClick = () => {
        closeModal();
        if (item.type === TASK_TYPES.prompt) {
            navigate(`/library/prompt/${item.id}`, {
                state: { libraryLocation: location },
            });
        } else if (item.type === 'task') {
            navigate(`/library/task/${item.id}`, {
                state: { libraryLocation: location },
            });
        }
    };

    return (
        <div
            className="w-full bg-white py-[8px] px-[12px] rounded-[6px] hover:cursor-pointer hover:bg-neutral-50 transition"
            onClick={handleCardClick}
        >
            <div className="w-full flex justify-between items-center gap-[8px] mb-[6px]">
                <p className="font-body text-body-bold-s text-neutral-400 truncate">{item.name}</p>
                {badge && (
                    <div className="max-xs:hidden">
                        <Badge
                            text={badge.label}
                            color={badge.color}
                            leadingIcon={badge.icon}
                            leadingIconColor={badge.iconColor}
                        />
                    </div>
                )}
            </div>
            <p className="font-body text-body-bold-xs text-neutral-500">
                Last Run <span className="font-body text-body-regular-xs">{item.last_run_at}</span>
            </p>
            {badge && (
                <div className="xs:hidden mt-[6px]">
                    <Badge
                        text={badge.label}
                        color={badge.color}
                        leadingIcon={badge.icon}
                        leadingIconColor={badge.iconColor}
                    />
                </div>
            )}
        </div>
    );
};

export default RecentViewedCard;
