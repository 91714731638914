import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../design-system';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';

const StartNewProjectSuccessState = ({ scenarioName }) => {
    const navigate = useNavigate();

    const openAllJobsPage = () => {
        navigate(`/jobs`);
    };

    return (
        <div className="px-8 w-full py-[80px] sm:py-[140px]">
            <div className="py-5 max-w-[460px] mx-auto flex flex-col gap-[40px]">
                <div className="flex flex-col items-center gap-3 md:gap-4 pb-[40px]">
                    <div className="w-[80px] h-[80px] min-w-[80px] bg-lime-500 rounded-full flex items-center justify-center">
                        <CheckLineIcon width={32} height={32} color="#FFFFFF" />
                    </div>
                    <p className="font-body text-body-regular-xs text-neutral-300 uppercase">
                        Project successfully started
                    </p>
                    <p className="font-heading-bold text-heading-bold-m text-black text-center">
                        {scenarioName} has been started!
                    </p>
                    <p className="font-body text-body-regular-s text-neutral-400 text-center">
                        Visit All Jobs to see the project agents’ progress.
                    </p>
                    <Button
                        type="link"
                        size="sm"
                        text="View All Jobs"
                        onClick={openAllJobsPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default StartNewProjectSuccessState;
