import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { BASE_TYPE_BADGE_COLOR, BASE_TYPE_LABEL } from '../../../constants/base';

import { Badge, ButtonIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import BaseCardMoreOptionsPopup from '../BaseCardMoreOptionsPopup/BaseCardMoreOptionsPopup';
import RenameBaseModal from '../RenameBaseModal/RenameBaseModal';
import ToolTip from '../../../design-system/ToolTip/ToolTip';

const BaseCard = ({ base: { id, name, type, records }, hasEditAccess, requestKeysToMutate }) => {
    const location = useLocation();

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);
    const [isRenameModalOpened, setIsRenameModalOpened] = useState(false);

    const openMoreOptionsPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsMoreOptionsPopupOpened(true);
    };

    return (
        <li>
            <Link
                to={`/base/${id}`}
                state={{ from: location }}
                className="block p-5 rounded-[6px] border-1 border-neutral-100 bg-white"
            >
                <div className="w-full flex items-center justify-between gap-0.5 mb-4">
                    <div className={hasEditAccess ? 'w-[calc(100%-30px)]' : 'w-full'}>
                        <ToolTip
                            text={name}
                            position="top-left"
                            shift="1px"
                            useInternalHoverStateToShowToolTip
                        >
                            <p className="max-w-full font-body-bold text-body-bold-m text-black truncate">
                                {name}
                            </p>
                        </ToolTip>
                    </div>

                    {hasEditAccess && (
                        <div className="relative">
                            <ButtonIcon
                                type="link"
                                size="xs"
                                icon={More2FillIcon}
                                onClick={openMoreOptionsPopup}
                            />
                            {isMoreOptionsPopupOpened && (
                                <BaseCardMoreOptionsPopup
                                    baseId={id}
                                    openRenameModal={() => setIsRenameModalOpened(true)}
                                    handleClose={() => setIsMoreOptionsPopupOpened(false)}
                                />
                            )}
                        </div>
                    )}
                </div>

                <div className="flex items-center gap-3 justify-between">
                    <div className="flex-1 truncate">
                        <Badge text={BASE_TYPE_LABEL[type]} color={BASE_TYPE_BADGE_COLOR[type]} />
                    </div>

                    <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap">
                        <span className="text-body-bold-xs text-neutral-500">
                            {records?.toLocaleString()}
                        </span>{' '}
                        {`${records === 1 ? 'record' : 'records'}`}
                    </p>
                </div>
            </Link>

            {isRenameModalOpened && (
                <RenameBaseModal
                    id={id}
                    name={name}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setIsRenameModalOpened(false)}
                />
            )}
        </li>
    );
};

export default memo(BaseCard);
