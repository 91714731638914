import React from 'react';

const BookmarkFillIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M3.33329 1.33301H12.6666C13.0348 1.33301 13.3333 1.63149 13.3333 1.99967V14.7619C13.3333 14.9459 13.184 15.0953 13 15.0953C12.9374 15.0953 12.876 15.0775 12.8229 15.0443L7.99996 12.0205L3.17702 15.0443C3.02105 15.1421 2.81533 15.0949 2.71754 14.9389C2.68427 14.8859 2.66663 14.8245 2.66663 14.7619V1.99967C2.66663 1.63149 2.96511 1.33301 3.33329 1.33301Z"
                fill={props.color}
            />
        </svg>
    );
};

export default BookmarkFillIcon;
