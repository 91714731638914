import React, { useState } from 'react';

import SelectDocModalTemplate from '../../SelectDocModalTemplate/SelectDocModalTemplate';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import SelectButton from '../../../design-system/SelectButton/SelectButton';
import { Button, Input } from '../../../design-system';
import { submitButtonText } from '../QuickAddToDocModal';

const QuickAddToDocMobileView = ({
    formData,
    handleInputChange,
    fieldErrorMessages,
    docsOptionsHookResponse,
    handleSubmit,
    mode,
    tipText,
    isLoading,
    onClose,
}) => {
    const [isDocSelectorOpened, setIsDocSelectorOpened] = useState(false);

    const handleDocSelect = (e, doc) => {
        handleInputChange('doc', doc);
        setIsDocSelectorOpened(false);
    };

    const submitButtonState = isLoading
        ? 'loading'
        : (mode === 'add' && formData.doc) || (mode === 'create' && formData.label)
        ? 'default'
        : 'disabled';

    return (
        <>
            {!isDocSelectorOpened && (
                <MobilePopup
                    onClose={onClose}
                    containerCustomPaddings="p-0"
                    bottomButtonsCustomPaddings="px-5 pb-3 pt-1"
                    contentCustomPaddings="pt-2.5"
                    customGap={8}
                    withCloseButton={false}
                    bottomButtonsGroup={
                        <div className="flex flex-col gap-2">
                            <Button
                                type="primary"
                                size="sm"
                                text={submitButtonText[mode]}
                                state={submitButtonState}
                                onClick={handleSubmit}
                            />
                            <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                        </div>
                    }
                >
                    <div className="flex flex-col">
                        <ModalHeader
                            onClose={onClose}
                            title="Quick Add to Doc"
                            withEscapeButton={false}
                            customPaddings="px-5 pb-3"
                            outlined
                        />
                        <div className="p-5">
                            {mode === 'add' && (
                                <SelectButton
                                    size="md"
                                    label="Select Document"
                                    isRequired
                                    selectedItem={formData.doc}
                                    state="default"
                                    placeholder="Select a Document"
                                    isDropdownOpened={false}
                                    toggleDropdownOpened={() => setIsDocSelectorOpened(true)}
                                    tipText={tipText}
                                />
                            )}

                            {mode === 'create' && (
                                <Input
                                    size="md"
                                    name="name"
                                    value={formData.label}
                                    onChange={(e) => handleInputChange('label', e.target.value)}
                                    label="Create New Document"
                                    isRequired
                                    state={fieldErrorMessages.label ? 'error' : 'default'}
                                    errorMessage={fieldErrorMessages.label}
                                    placeholder="Give your new document a name"
                                    tipText={tipText}
                                />
                            )}
                        </div>
                    </div>
                </MobilePopup>
            )}
            {isDocSelectorOpened && (
                <SelectDocModalTemplate
                    docsOptionsHookResponse={docsOptionsHookResponse}
                    withEscapeButton={false}
                    handleDocSelect={handleDocSelect}
                    modalTitle="Select Doc"
                    onClose={() => setIsDocSelectorOpened(false)}
                />
            )}
        </>
    );
};

export default QuickAddToDocMobileView;
