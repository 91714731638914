import React from 'react';
import temperatureOptions from '../../constants/temperatureOptions';

const PromptSettingsTemperatureSelector = ({
    selectedTemperatureId,
    editable = true,
    handleTemperatureChange,
}) => {
    const handleChange = (e) => {
        const id = Number(e.target?.value);
        const selectedOption = temperatureOptions.find((option) => option.id === id);
        if (selectedOption) {
            handleTemperatureChange(selectedOption);
        }
    };

    return (
        <div className="w-full">
            <p className="font-body text-body-bold-s text-neutral-500 mb-[4px]">
                Select a Temperature
            </p>
            <div className="w-full flex gap-[8px] max-sm:flex-wrap lg:max-xl:flex-wrap">
                {temperatureOptions
                    .sort((a, b) => a.id - b.id)
                    .map((item) => (
                        <label
                            key={item.id}
                            className={`w-full xs:max-sm:w-[calc((100%-8px)/2)] lg:max-xl:w-[calc((100%-8px)/2)] p-[12px] shadow-inset-2 transition-all rounded-2 ${
                                selectedTemperatureId === item.id
                                    ? 'shadow-purple-500'
                                    : 'shadow-[#5E647080]'
                            } ${!editable ? 'bg-neutral-50' : 'bg-white cursor-pointer'}`}
                        >
                            <p className="font-body text-body-bold-s text-neutral-500">
                                {item.name}
                            </p>
                            <p className="font-body text-body-regular-s text-black">
                                {item.description}
                            </p>
                            <input
                                type="radio"
                                className="sr-only"
                                name="temperature"
                                value={item.id}
                                checked={selectedTemperatureId === item.id}
                                onChange={editable ? handleChange : null}
                            />
                        </label>
                    ))}
            </div>
        </div>
    );
};

export default PromptSettingsTemperatureSelector;
