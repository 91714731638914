import axios from 'axios';

// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const getAuthToken = () => {
    return localStorage.getItem('authKey');
};

// if environment is production, use the production API
let baseUrl;
if (process.env.NODE_ENV === 'production') {
    // TODO: replace with production API URL
    baseUrl = '/api/user/';
} else {
    baseUrl = 'http://127.0.0.1:8000/api/user/';
}

// create client constant with axios and auth token
const client = axios.create({
    baseURL: baseUrl,
});

client.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
});

export default client;