import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BLOCK_TYPE } from '../../../constants/playbookBuilder';

import CalloutColorPicker from './CalloutColorPicker/CalloutColorPicker';
import DeleteBin4LineIcon from '../../Icons/DeleteBin4LineIcon';
import TextFieldWithCustomTextStyles from '../../TextFieldWithCustomTextStyles/TextFieldWithCustomTextStyles';
import { ButtonIcon } from '../../index';

CalloutBlock.propTypes = {
    blockData: PropTypes.shape({
        order: PropTypes.number,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        temporaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        block_type: PropTypes.oneOf([BLOCK_TYPE.callout]),
        properties: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            callout_color: PropTypes.string,
        }).isRequired,
    }),
    editMode: PropTypes.bool,
    handleDeleteBlock: PropTypes.func.isRequired,
    handleBlockPropertyChange: PropTypes.func.isRequired,
};

function CalloutBlock({
    blockData,
    editMode = false,
    handleDeleteBlock,
    handleBlockPropertyChange,
}) {
    const {
        properties: { title, description, callout_color },
    } = blockData;

    return (
        <div className="flex flex-col-reverse xs:flex-row xs:items-start gap-2">
            <div
                className="relative z-10 border-l-4 p-5 xs:flex-grow rounded-[10px] transition-colors flex flex-col gap-4"
                style={{ borderColor: callout_color }}
            >
                <div
                    className="absolute inset-0 rounded-2 z-[-1] transition-colors"
                    style={{ backgroundColor: callout_color, opacity: 0.2 }}
                ></div>
                <TextFieldWithCustomTextStyles
                    value={title}
                    isDisabled={!editMode}
                    minHeight={24}
                    textStyle="font-body-bold text-body-bold-m text-neutral-500"
                    placeholder="Enter title here"
                    handleChange={(e) => handleBlockPropertyChange('title', e.target.value)}
                />
                <TextFieldWithCustomTextStyles
                    value={description}
                    isDisabled={!editMode}
                    minHeight={64}
                    textStyle="font-body text-body-regular-s text-neutral-500"
                    placeholder="Enter description here"
                    handleChange={(e) => handleBlockPropertyChange('description', e.target.value)}
                />
            </div>

            {editMode && (
                <div className="flex items-center xs:items-start gap-2 justify-between xs:justify-start">
                    <CalloutColorPicker
                        selectedColor={callout_color || '#5E6470'}
                        handleColorChange={(e) =>
                            handleBlockPropertyChange('callout_color', e.target.value)
                        }
                    />

                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={DeleteBin4LineIcon}
                        onClick={handleDeleteBlock}
                    />
                </div>
            )}
        </div>
    );
}

export default memo(CalloutBlock);
