import React from 'react';

const LogoIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 36 36" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1463 35.9994C28.0201 35.9208 36 27.8923 36 18C36 8.1077 28.0201 0.0792081 18.1463 0.000582483V0H18H5.99023C2.68192 0 0 2.68192 0 5.99023V18V30.0098C0 33.3181 2.68192 36 5.99023 36H18H18.1463V35.9994Z"
                fill="#754DCF"
            />
            <path
                d="M21.8544 9.05617C21.8809 8.73336 22.3535 8.73336 22.3799 9.05617C22.5064 10.5994 23.7313 11.8244 25.2746 11.9508C25.5974 11.9773 25.5974 12.4499 25.2746 12.4763C23.7313 12.6028 22.5064 13.8277 22.3799 15.371C22.3535 15.6938 21.8809 15.6938 21.8544 15.371C21.728 13.8277 20.503 12.6028 18.9598 12.4763C18.637 12.4499 18.637 11.9773 18.9598 11.9508C20.503 11.8244 21.728 10.5994 21.8544 9.05617Z"
                fill="#F3F5F8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.236 21.0647C24.7068 20.1937 25.6799 19.568 26.6363 19.8243L27.6372 20.0925C28.5207 20.3292 29.0706 21.2288 28.7271 22.0765C28.0948 23.6374 27.083 25.0785 25.8527 26.2615C23.7062 28.3254 20.6914 29.8076 17.2928 29.8076C13.8809 29.8076 10.8659 28.2913 8.72745 26.221C7.54607 25.0774 6.56281 23.6949 5.92675 22.2036C5.56357 21.352 6.11292 20.4347 7.00712 20.1951L8.03298 19.9202C8.96856 19.6695 9.92283 20.2625 10.3958 21.1077C10.7548 21.7493 11.2421 22.3922 11.8574 22.9879C13.302 24.3864 15.237 25.3076 17.2928 25.3076C19.3618 25.3076 21.297 24.3991 22.7337 23.0177C23.3717 22.4043 23.8732 21.7359 24.236 21.0647Z"
                fill="#E9F1A4"
            />
        </svg>
    );
};

export default LogoIcon;
