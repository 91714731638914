import React from 'react';

import { CHAT_ROLE } from '../../../constants/assistant';
import { useFormState } from '../../../hooks/useFormState';

import { Button } from '../../../design-system';
import TextFieldWithCustomTextStyles from '../../../design-system/TextFieldWithCustomTextStyles/TextFieldWithCustomTextStyles';

const UserMessageEditState = ({
    id,
    content,
    stopEditing,
    isRegenerateDisabled,
    handleRegenerateChatMessage,
}) => {
    const { formData, handleInputChange } = useFormState({ content });

    const handleSubmitMessage = async () => {
        stopEditing();
        await handleRegenerateChatMessage({ id, role: CHAT_ROLE.user, content: formData.content });
    };

    const submitButtonState = isRegenerateDisabled || !formData.content ? 'disabled' : 'default';

    return (
        <div className="flex flex-col gap-4">
            <TextFieldWithCustomTextStyles
                value={formData.content}
                minHeight={21}
                handleChange={(e) => handleInputChange('content', e.target.value)}
                customTextFieldStyles="font-body text-bodu-regular-s text-neutral-500"
            />

            <div className="flex items-center gap-4 ml-auto">
                <Button type="link" size="xs" text="Cancel" onClick={stopEditing} />
                <Button
                    type="primary"
                    size="xs"
                    text="Send"
                    state={submitButtonState}
                    onClick={handleSubmitMessage}
                />
            </div>
        </div>
    );
};

export default UserMessageEditState;
