import React, { useState } from 'react';

import { API } from 'constants';
import client from 'services/organization-api';
import userClient from 'services/user-api';

import { usePatchRequest, usePostRequest } from 'hooks/request';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import Modal from 'design-system/Modal/Modal';
import Input from 'design-system/Input/Input';
import Alert from 'design-system/Alert/Alert';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';
import InviteUserSuccessState from '../InviteUserSuccessState/InviteUserSuccessState';
import { Button, Select } from 'design-system';

const EMAIL_WITH_NO_ACCOUNT_ERROR_MESSAGE =
    'This email does not have an account yet, would you like to invite them instead?';

const EditMembershipModal = ({ onClose, data, mode, handleSave, organizationData }) => {
    const [member, setMember] = useState(() => {
        if (mode === 'edit' && data) {
            return {
                email: data.user.email,
                role: data.org_role,
                organization: organizationData.id,
            };
        }
        return { email: '', organization: organizationData?.id, role: null };
    });

    const [invitationData, setInvitationData] = useState(null);

    const [errorAlert, setErrorAlert] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const membershipCreate = usePostRequest(
        { url: API.ROUTES.organization.organizationMembership },
        client
    );
    const membershipUpdate = usePatchRequest({}, client);
    const inviteUser = usePostRequest({ url: API.ROUTES.user.invite }, userClient);

    const [errorFields, setErrorFields] = useState({
        email: false,
        role: false,
    });

    const isSendInviteButtonDisplayed =
        mode === 'create' &&
        errorFields.email &&
        errorMessage === EMAIL_WITH_NO_ACCOUNT_ERROR_MESSAGE;

    const handleInputChange = (e) => {
        setMember((prevCredentials) => ({
            ...prevCredentials,
            [e.target.name]: e.target.value,
        }));
        if (errorFields[e.target.name]) {
            setErrorFields((prevState) => ({ ...prevState, [e.target.name]: false }));
        }
    };

    const handleRoleSelectChange = (role) => {
        setMember((prevCredentials) => ({
            ...prevCredentials,
            role,
        }));
        if (errorFields.role) {
            setErrorFields((prevState) => ({ ...prevState, role: false }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!member.email || !member.role) {
            setErrorFields({
                email: !member.email,
                role: !member.role,
            });
            setErrorMessage(emptyFieldErrorMessage);
            return;
        }

        setIsLoading(true);
        if (mode === 'edit') {
            const { success, error } = await membershipUpdate.request({
                data: {
                    role: member.role,
                    organization: member.organization,
                },
                url: `${API.ROUTES.organization.organizationMembership}${data.id}/`,
            });
            if (success) {
                await handleSave();
                onClose();
            }
            if (error) {
                setErrorAlert({ message: defaultErrorMessage });
            }
        }

        if (mode === 'create') {
            const { success, error } = await membershipCreate.request({ data: member });
            if (success) {
                await handleSave();
                onClose();
            }
            if (error) {
                if (error.data && error.data.email) {
                    setErrorFields({ email: true });

                    const receivedError = error.data.email[0];
                    if (receivedError === 'This email does not have an account') {
                        setErrorMessage(EMAIL_WITH_NO_ACCOUNT_ERROR_MESSAGE);
                    } else {
                        setErrorMessage(receivedError);
                    }
                } else {
                    setErrorAlert({ message: defaultErrorMessage });
                }
            }
        }

        setIsLoading(false);
    };

    const handleSendInvite = async () => {
        const data = {
            auto: true,
            plan: 'free',
            organization: member.organization,
            email: member.email,
        };

        setIsLoading(true);
        const { response, success, error } = await inviteUser.request({ data });
        if (success) {
            setInvitationData(response);
        }
        if (error) {
            setErrorAlert({
                message:
                    'Oops! Something went wrong and the invite could not be sent, please try again later.',
            });
        }

        setIsLoading(false);
    };

    return (
        <>
            {!invitationData && (
                <Modal size="medium" onClose={onClose}>
                    <div className="flex flex-col gap-4">
                        <h2 className="font-heading-bold text-heading-bold-m text-black">
                            {mode === 'create'
                                ? `Add member to ${organizationData?.name} Organization`
                                : 'Editing member access'}
                        </h2>

                        <Input
                            size="md"
                            name="email"
                            value={member.email}
                            label="Email Address"
                            isRequired
                            placeholder="Add valid email address"
                            onChange={handleInputChange}
                            state={
                                errorFields.email
                                    ? 'error'
                                    : mode === 'edit'
                                    ? 'disabled'
                                    : 'default'
                            }
                            errorMessage={errorMessage}
                        />

                        <Select
                            size="md"
                            name="role"
                            label="Member type"
                            value={member.role}
                            isRequired
                            options={[
                                { id: 'member', name: 'Member' },
                                { id: 'admin', name: 'Admin' },
                            ]}
                            state={errorFields.role ? 'error' : 'default'}
                            errorMessage="Please select a Member type"
                            onChange={handleRoleSelectChange}
                            increaseComponentHeightBy={40}
                            placeholder="Select the member type"
                        />

                        <div className="flex justify-between">
                            <Button type="neutral" text="Cancel" size="sm" onClick={onClose} />

                            {!isSendInviteButtonDisplayed && (
                                <Button
                                    size="sm"
                                    type="primary"
                                    text="Confirm"
                                    state={isLoading ? 'loading' : 'default'}
                                    onClick={handleSubmit}
                                />
                            )}

                            {isSendInviteButtonDisplayed && (
                                <Button
                                    size="sm"
                                    type="secondary"
                                    text="Send Invite"
                                    state={isLoading ? 'loading' : 'default'}
                                    onClick={handleSendInvite}
                                />
                            )}
                        </div>
                    </div>
                    {errorAlert && (
                        <Alert
                            status="critical"
                            message={errorAlert.message}
                            icon={ErrorWarningLineIcon}
                            statusCode={errorAlert.statusCode}
                            autoCloseInMS={3000}
                            handleClose={() => setErrorAlert(null)}
                        />
                    )}
                </Modal>
            )}

            {!!invitationData && <InviteUserSuccessState data={invitationData} onClose={onClose} />}
        </>
    );
};

export default EditMembershipModal;
