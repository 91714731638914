import React from 'react';

const BoldIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M5.33333 7.33366H8.33333C9.2538 7.33366 10 6.58747 10 5.66699C10 4.74652 9.2538 4.00033 8.33333 4.00033H5.33333V7.33366ZM12 10.3337C12 11.9905 10.6569 13.3337 9 13.3337H4V2.66699H8.33333C9.9902 2.66699 11.3333 4.01014 11.3333 5.66699C11.3333 6.46987 11.0179 7.19906 10.5043 7.73752C11.3986 8.25679 12 9.22499 12 10.3337ZM5.33333 8.66699V12.0003H9C9.92047 12.0003 10.6667 11.2541 10.6667 10.3337C10.6667 9.41319 9.92047 8.66699 9 8.66699H5.33333Z"
                fill={props.color}
            />
        </svg>
    );
};

export default BoldIcon;
