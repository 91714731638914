import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Alert from '../../../../design-system/Alert/Alert';
import Button from '../../../../design-system/Button/Button';
import ProcessMoreOptionsPopup from '../ProcessMoreOptionsPopup/ProcessMoreOptionsPopup';
import ConfirmDeleteProcessModal from '../ConfirmDeleteProcessModal/ConfirmDeleteProcessModal';
import { ButtonIcon } from '../../../../design-system';
import { ArrowGoBackLineIcon, More2FillIcon } from '../../../../design-system/Icons';

const ProcessDetailTopNavBar = ({ processDetail, backLinkHref }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const [copyAlert, setCopyAlert] = useState(null);

    return (
        <div className="py-3 px-4 sm:px-6 flex justify-between min-[380px]:items-center gap-2 bg-white border-b-1 border-neutral-200">
            <div className="flex flex-col items-start min-[380px]:flex-row min-[380px]:items-center gap-x-5 gap-y-0.5">
                <Button
                    type="link"
                    size="xs"
                    text="Back"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(backLinkHref, { state: { from: location } })}
                />
                <p className="font-body text-body-regular-s text-neutral-300">
                    Configure / Process Overview
                </p>
            </div>

            <div className="relative transform translate-y-[-4px] min-[380px]:translate-y-[0]">
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={More2FillIcon}
                    onClick={() => setIsMoreOptionsPopupOpened(true)}
                />

                {isMoreOptionsPopupOpened && (
                    <ProcessMoreOptionsPopup
                        processDetail={processDetail}
                        setCopyAlert={setCopyAlert}
                        setIsDeleteModalOpened={setIsDeleteModalOpened}
                        onClose={() => setIsMoreOptionsPopupOpened(false)}
                    />
                )}
            </div>

            {isDeleteModalOpened && (
                <ConfirmDeleteProcessModal
                    processId={processDetail.id}
                    processName={processDetail.name}
                    backLinkHref={backLinkHref}
                    onClose={() => setIsDeleteModalOpened(false)}
                />
            )}

            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </div>
    );
};

export default ProcessDetailTopNavBar;
