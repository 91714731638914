import React from 'react';

import { formatKeyToLabel } from '../../../../helpers/formatKeyToLabel';
import { formatOutputValue } from '../../../../helpers/formatOutputValue';

import { Badge } from '../../../../design-system';
import NewTextAreaBox from '../../../../design-system/NewTextAreaBox/NewTextAreaBox';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';

const TaskOutputsList = ({ outputs, setActionModal }) => {
    const state = 'disabled';

    return (
        <div className="flex flex-col gap-4">
            {outputs.map((output) => {
                const { id, key, type, value, is_required } = output;

                const tags = [{ text: formatKeyToLabel(type || ''), color: 'neutral' }];

                const tagsElements = (
                    <div className="flex items-center gap-2">
                        {tags.map((tag, idx) => (
                            <Badge key={idx} text={tag.text} color={tag.color} />
                        ))}
                    </div>
                );

                const formattedValue = formatOutputValue(value);

                return (
                    <FormFieldWrapper
                        key={id}
                        label={key}
                        state={state}
                        isRequired={is_required}
                        withCopyButton
                        withDeleteButton
                        actionsBarLeftContent={tagsElements}
                        actionsBarCustomGap={4}
                        value={formattedValue}
                        onDeleteButtonClick={() =>
                            setActionModal({ action: 'delete', outputId: id })
                        }
                    >
                        <NewTextAreaBox
                            value={formattedValue}
                            state={state}
                            fullHeight
                            placeholder="n/a"
                            fixedMinHeight={48}
                            initialMinHeight={48}
                            darkBg
                            customPaddings="px-3 py-3.5"
                        />
                    </FormFieldWrapper>
                );
            })}
        </div>
    );
};

export default TaskOutputsList;
