import React from 'react';
import { useParams } from 'react-router-dom';

import { USER_ROLE } from '../../../../constants/organization';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

import Loading from '../../../../components/Loading';
import OrganizationDetailsBlock from '../OrganizationDetailsBlock/OrganizationDetailsBlock';
import ClientOrganizationsBlock from '../ClientOrganizationsBlock/ClientOrganizationsBlock';
import OrganizationConnectionsBlock from '../OrganizationConnectionsBlock/OrganizationConnectionsBlock';
import ApiKeyBlock from '../ApiKeyBlock/ApiKeyBlock';

const ParentOrganizationSettings = ({
    organizationData,
    refetchOrganizationData,
    childOrganizations,
    paginationProps,
    ...organizationsListProps
}) => {
    const { organizationId } = useParams();

    const organizationName = organizationData?.organization?.name;
    useDocumentTitle(organizationName);

    if (!organizationData) {
        return (
            <div className="flex-1 flex items-center justify-center">
                <Loading />
            </div>
        );
    }

    const userRole = organizationData.organization_member_role;
    const orgPlan = organizationData.billing_account?.plan;

    const isOwnerOrAdmin = userRole === USER_ROLE.owner || userRole === USER_ROLE.admin;

    return (
        <>
            <h1 className="font-heading-bold text-heading-bold-l text-black">
                Organization Settings
            </h1>

            <OrganizationDetailsBlock
                organizationData={organizationData}
                refetchOrganizationData={refetchOrganizationData}
                isOwnerOrAdmin={isOwnerOrAdmin}
                orgPlan={orgPlan}
                {...organizationsListProps}
            />

            {!!childOrganizations?.length && (
                <ClientOrganizationsBlock
                    organizationId={organizationId}
                    childOrganizations={childOrganizations}
                    paginationProps={paginationProps}
                />
            )}

            {isOwnerOrAdmin && (
                <OrganizationConnectionsBlock
                    orgId={organizationId}
                    userRole={userRole}
                    orgPlan={orgPlan}
                />
            )}

            {isOwnerOrAdmin && <ApiKeyBlock orgId={organizationId} />}
        </>
    );
};

export default ParentOrganizationSettings;
