export function compareObjectsByActionType(obj1, obj2) {
    // Compare 'action_type' property of the two objects
    if (obj1.action_type === 'body' && obj2.action_type !== 'body') {
        return -1; // If obj1 has 'action_type' as 'body' and obj2 doesn't, obj1 comes before obj2
    } else if (obj1.action_type !== 'body' && obj2.action_type === 'body') {
        return 1; // If obj2 has 'action_type' as 'body' and obj1 doesn't, obj2 comes before obj1
    } else {
        return 0; // If both objects have the same 'action_type' or both have different 'action_type's, maintain their order
    }
}
