import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { defaultErrorMessage } from '../../constants/errorMessages';

import Alert from '../Alert/Alert';
import SvgIcon from '../SvgIcon/SvgIcon';
import JsonEditor from '../JsonEditor/JsonEditor';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import DownloadIcon from '../Icons/DownloadIcon';
import CheckLineIcon from '../Icons/CheckLineIcon';
import ErrorWarningLineIcon from '../Icons/ErrorWarningLineIcon';
import { FileCopyLineIcon } from '../Icons';

JsonTextareaBox.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    setValue: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['view', 'code']).isRequired,
    state: PropTypes.oneOf(['error', 'default', 'disabled']),
    label: PropTypes.string,
    labelToCopyAlert: PropTypes.string,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    withCopyButton: PropTypes.bool,
    withDownloadButton: PropTypes.bool,
    downloadFileName: PropTypes.string,
    containerMinHeight: PropTypes.number,
    parentGap: PropTypes.number,
    autoExpand: PropTypes.bool, // use just for mode = "code"
};

// JsonTextareaBox component should be wrapped up by flex container: <div className="flex flex-col"></div>
function JsonTextareaBox({
    value,
    setValue,
    mode,
    state = 'default',
    label,
    labelToCopyAlert,
    tipText,
    isRequired = false,
    errorMessage,
    withCopyButton,
    withDownloadButton,
    downloadFileName,
    containerMinHeight = 350,
    parentGap = 16, // JsonTextareaBox component is in flex container so parentGap means the value of gap in pixels
    autoExpand = false,
}) {
    const [copyAlert, setCopyAlert] = useState(null);

    const handleCopy = () => {
        const valueToCopy = typeof value === 'string' ? value : JSON.stringify(value, null, 4);

        navigator.clipboard
            .writeText(valueToCopy)
            .then(() => {
                setCopyAlert({
                    message: `${labelToCopyAlert || label} copied to clipboard!`,
                    status: 'positive',
                    icon: CheckLineIcon,
                });
            })
            .catch(() => {
                setCopyAlert({
                    message: defaultErrorMessage,
                    status: 'critical',
                    icon: ErrorWarningLineIcon,
                });
            });
    };

    const handleDownload = () => {
        const element = document.createElement('a');
        const valueInFile = typeof value === 'string' ? value : JSON.stringify(value, null, 4);
        const file = new Blob([valueInFile], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${downloadFileName || 'file'}.txt`;
        element.classList.add('disable-click-outside');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const containerClassName = classNames('flex flex-col gap-1.5 flex-grow', {
        'overflow-hidden': mode === 'code' && !autoExpand,
        'resize-y': mode === 'code' && !autoExpand && state !== 'disabled',
    });

    return (
        <>
            <div className={containerClassName} style={{ minHeight: `${containerMinHeight}px` }}>
                {(label || withDownloadButton || withCopyButton) && (
                    <div className="flex justify-between items-center gap-2">
                        <p className={`font-body text-body-bold-s text-neutral-500`}>
                            {label}
                            {isRequired && (
                                <sup className="text-red-500 font-small leading-1 font-medium ml-0.5">
                                    *
                                </sup>
                            )}
                        </p>
                        <div className="flex items-center">
                            {withDownloadButton && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state="default"
                                    icon={DownloadIcon}
                                    onClick={handleDownload}
                                />
                            )}
                            {withCopyButton && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state={!value ? 'disabled' : 'default'}
                                    icon={FileCopyLineIcon}
                                    onClick={handleCopy}
                                />
                            )}
                        </div>
                    </div>
                )}
                <div className={`flex-grow ${!autoExpand ? 'flex flex-col' : ''}`}>
                    <JsonEditor
                        value={value}
                        setValue={setValue}
                        mode={mode}
                        disabled={state === 'disabled'}
                        autoExpand={autoExpand}
                    />
                </div>
            </div>

            {state === 'error' && errorMessage && (
                <div
                    className="flex gap-x-1"
                    style={{
                        marginTop: `-${parentGap - 6}px`,
                    }}
                >
                    <SvgIcon icon={ErrorWarningLineIcon} color="#E95B69" size="medium" />
                    <p className="text-extraSmall text-red-500 leading-1.25">{errorMessage}</p>
                </div>
            )}

            {tipText && (state !== 'error' || !errorMessage) && (
                <div
                    className="flex items-center gap-1"
                    style={{
                        marginTop: `-${parentGap - 6}px`,
                    }}
                >
                    <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                    <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                </div>
            )}
            {copyAlert?.message && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </>
    );
}

export default JsonTextareaBox;
