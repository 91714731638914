import React from 'react';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const ScenarioMoreOptionsPopup = ({ setIsDeleteModalOpened, onClose }) => {
    const openDeleteModal = () => {
        setIsDeleteModalOpened(true);
        onClose();
    };

    return (
        <div className="absolute right-0 top-full z-10">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete Scenario"
                            textColor="#E95B69"
                            iconColor="#E95B69"
                            onClick={openDeleteModal}
                        />
                    </>
                }
                handleClose={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};

export default ScenarioMoreOptionsPopup;
