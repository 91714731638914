import React from 'react';
import Modal from '../../../design-system/Modal/Modal';
import { Button } from '../../../design-system';
import PropTypes from 'prop-types';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const BlockingNavigationModal = ({
    onConfirm,
    onCancel,
    savePrompt,
    runPrompt,
    changeOrganization,
    edits, // don't pass this prop if BlockingNavigationModal is rendered in OrganizationSwitcher
    target, // target = "organization" if BlockingNavigationModal is shown because user wants to change organization having unsaved change, target = undefined otherwise
    handleClose = () => {},
    closeOrganizationSwitcher,
}) => {
    const handleSavePrompt = async () => {
        onCancel();
        await savePrompt();
    };

    const handleRunPrompt = async () => {
        onCancel();
        await runPrompt();
    };

    const onClose = () => {
        onCancel();
        handleClose();
    };

    const handleChangeOrganization = () => {
        onConfirm();
        changeOrganization();
        handleClose();
    };

    return (
        <Modal onClose={onClose} size="medium">
            <h2 className="font-heading-bold text-heading-bold-m text-neutral-400 mb-6">
                {target === 'organization' &&
                    'You have unsaved changes. If you change the organization now, all your changes will be lost.'}
                {edits === 'prompt' &&
                    'You have unsaved changes. Would you like to save your prompt?'}
                {edits === 'input' &&
                    'You have unsaved changes. Would you like to run your prompt to save them?'}
            </h2>
            {
                <div className="flex items-center justify-center gap-3">
                    {target === 'organization' ? (
                        <>
                            <Button
                                type="neutral"
                                size="sm"
                                text="Change Organization"
                                onClick={handleChangeOrganization}
                            />
                            <Button
                                type="secondary"
                                size="sm"
                                text="Stay on Page"
                                onClick={() => {
                                    onClose();
                                    closeOrganizationSwitcher();
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                type="neutral"
                                size="sm"
                                text="No, Leave Page"
                                onClick={onConfirm}
                            />
                            {edits === 'prompt' && (
                                <Button
                                    type="secondary"
                                    size="sm"
                                    text="Yes, Save Prompt"
                                    onClick={handleSavePrompt}
                                />
                            )}
                            {edits === 'input' && (
                                <Button
                                    type="primary"
                                    size="sm"
                                    text="Yes, Run Prompt"
                                    leadingIcon={FlashlightFillIcon}
                                    onClick={handleRunPrompt}
                                />
                            )}
                        </>
                    )}
                </div>
            }
        </Modal>
    );
};

BlockingNavigationModal.propTypes = {
    edits: PropTypes.oneOf(['prompt', 'input']),
    handleClose: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    savePrompt: PropTypes.func,
    runPrompt: PropTypes.func,
    changeOrganization: PropTypes.func,
    closeOrganizationSwitcher: PropTypes.func,
};

export default BlockingNavigationModal;
