import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import CheckboxIcon from 'design-system/Icons/CheckboxIcon';

Checkbox.propTypes = {
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    isChecked: PropTypes.bool.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    logAs: PropTypes.string,
};

function Checkbox(props) {
    const { state = 'default', isChecked, label, name, onChange, logAs } = props;

    const colors = {
        default: isChecked
            ? 'border-purple-500 bg-purple-500'
            : `border-neutral-300 peer-hover:border-neutral-400 peer-focus:border-purple-500 bg-white`,
        disabled: isChecked ? 'border-neutral-300 bg-neutral-300' : 'border-neutral-300 bg-white',
        error: isChecked ? 'border-red-500 bg-red-400' : 'border-red-500 bg-white',
    };

    const transitionStyles = {
        transitionProperty: 'background-color, border-color, color',
    };
    const checkboxColors = colors[state];

    let className = classNames(
        `w-4.5 h-4.5 flex items-center justify-center peer border-1 rounded-[4px] transition-all after:transition-all`,
        checkboxColors,
        transitionStyles
    );

    const containerClassName = classNames('flex items-center gap-2 max-w-full', {
        'cursor-not-allowed': state === 'disabled',
        'cursor-pointer': state !== 'disabled',
    });

    const labelClassName = classNames('font-inter font-base leading-[125%] truncate', {
        'text-neutral-300': state === 'disabled',
        'text-neutral-500': state !== 'disabled',
    });

    return (
        <label className={containerClassName}>
            <div className="relative h-6 w-6 flex items-center justify-center">
                <input
                    type="checkbox"
                    className="sr-only peer"
                    onChange={onChange}
                    name={name}
                    checked={isChecked}
                    disabled={state === 'disabled'}
                    {...(logAs ? { 'data-dd-action-name': logAs } : {})}
                />
                <div className={className}>
                    {isChecked && <SvgIcon color="#FFFFFF" icon={CheckboxIcon} size="large" />}
                </div>
            </div>

            {label && <div className={labelClassName}>{label}</div>}
        </label>
    );
}

export default Checkbox;
