import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { handleClearSearchBar, handleSearch } from '../../../helpers/handleUpdateSearchParams';
import SearchBar from '../../../design-system/SearchBar/SearchBar';

const PlaybooksFilteringBlock = ({ parsedParams, noPlaybooks }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState(parsedParams.search || '');

    const handleSearchSubmit = () => {
        handleSearch({ searchParams, setSearchParams, searchValue });
    };

    const handleClearSearchQuery = () => {
        handleClearSearchBar({ searchParams, setSearchParams, setSearchValue });
    };

    return (
        <>
            {!noPlaybooks && (
                <div className="hidden sm:flex items-center justify-between gap-5">
                    <p className="font-heading-bold text-heading-bold-m text-black">
                        All Playbooks
                    </p>

                    <div className="w-[calc(100vw*0.5)] lg:w-[400px] max-w-[400px]">
                        <SearchBar
                            size="xs"
                            value={searchValue}
                            withSubmitIcon
                            withClearIcon
                            placeholder="Search ..."
                            onChange={(e) => setSearchValue(e.target.value)}
                            onSubmit={handleSearchSubmit}
                            onClear={handleClearSearchQuery}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default PlaybooksFilteringBlock;
