import React from 'react';
import Loading from '../Loading';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

const InfiniteScrollList = ({
    children,
    loading,
    canLoadMore,
    handleFetch,
    items,
    gap = 8,
    customStyles = '',
    loadingIconColor = 'black',
    showLoader = true,
}) => {
    const { guardRef } = useIntersectionObserver({
        handleFetch,
        options: items,
        optionsLoading: loading,
        canLoadMore,
    });

    return (
        <>
            <div className={`flex flex-col ${customStyles}`} style={{ gap }}>
                {children}
                {loading && showLoader && <Loading withText={false} color={loadingIconColor} />}
            </div>

            <div ref={guardRef}></div>
        </>
    );
};

export default InfiniteScrollList;
