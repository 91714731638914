import React, { useState } from 'react';

import { API } from 'constants';
import operateClient from '../../../services/operate-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import Alert from '../../../design-system/Alert/Alert';
import Modal from '../../../design-system/Modal/Modal';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const ConfirmReloadReportModal = ({ threadId, threadName, setSuccessAlert, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleReloadReport = async () => {
        try {
            setIsLoading(true);
            await operateClient.post(`${API.ROUTES.operate.thread}${threadId}/reload/`);
            setSuccessAlert({ message: 'Thread report successfully reloaded' });
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    return (
        <Modal size="extraSmall" onClose={onClose}>
            <p className="font-heading-bold text-heading-bold-m text-black py-2 mb-4">
                Confirm Reload Report
            </p>
            <p className="mb-8 font-body text-body-regular-m text-neutral-500">
                Are you sure you want to reload the report for{' '}
                <span className="font-body-bold text-body-bold-m">{threadName}</span> thread? This
                will reset and update metrics and milestones on the thread.
            </p>
            <div className="flex items-center justify-between">
                <Button type="ghost" size="sm" text="No, Cancel" onClick={onClose} />
                <Button
                    type="primary"
                    size="sm"
                    text="Yes, Reload"
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleReloadReport}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default ConfirmReloadReportModal;
