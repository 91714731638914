import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../services/library-api';

import { SvgIcon } from '../../../design-system';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import BlockContainerWithHeader from '../../../components/BlockContainerWithHeader/BlockContainerWithHeader';
import RichTextFieldEditingState from '../../../components/RichTextFieldEditingState/RichTextFieldEditingState';

const AboutTab = ({ scenarioDetail, setScenarioDetail }) => {
    const { id, description, is_shared } = scenarioDetail;

    const isDescriptionEditable = !is_shared;
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        if (isDescriptionEditable && !isEditing) {
            setIsEditing(true);
        }
    };

    const editButton = (
        <button onClick={handleEditClick}>
            <SvgIcon color="#1F2125" icon={EditLineIcon} size="large" />
        </button>
    );

    const saveDescription = async (description) => {
        if (!isDescriptionEditable) return;

        const { data } = await client.patch(`${API.ROUTES.library.scenario}${id}/`, {
            description,
        });
        setScenarioDetail((prevData) => ({ ...prevData, description: data.description }));
    };

    return (
        <BlockContainerWithHeader
            title="Scenario Overview"
            containerGap={8}
            topRightContent={isDescriptionEditable && editButton}
        >
            {!isEditing && (
                <RichTextArea
                    value={description || ''}
                    previewOnly
                    autoExpand
                    isBorderHidden
                    minHeight={32}
                />
            )}

            {isEditing && (
                <RichTextFieldEditingState
                    value={description}
                    handleSave={saveDescription}
                    stopEditing={() => setIsEditing(false)}
                />
            )}
        </BlockContainerWithHeader>
    );
};

export default AboutTab;
