import React, { useState } from 'react';

import { capitalizeFirstLetter } from '../../../../../services/strings';
import { Input, Select } from '../../../../../design-system';
import CollapsableContainer from '../../../../../components/CollapsableContainer/CollapsableContainer';

const NewDatasetRecordForm = ({ datasetDetail, formData, setFormData }) => {
    const { name, inputKeys, outputKeys } = datasetDetail;

    const [isExpanded, setIsExpanded] = useState({ inputs: true, outputs: false });

    const toggleExpand = (section) => {
        setIsExpanded((prevData) => ({ ...prevData, [section]: !prevData[section] }));
    };

    const handleChange = (type, key, value) => {
        setFormData((data) => ({ ...data, [type]: { ...data[type], [key]: value } }));
    };

    return (
        <div className="flex flex-col gap-4">
            <Select
                size="sm"
                name="dataset"
                value={name}
                options={[{ id: name, name }]}
                label="Select a Dataset"
                isRequired
                state="disabled"
                onChange={() => {}}
            />

            <CollapsableContainer
                title="Inputs"
                isExpanded={isExpanded.inputs}
                toggleExpand={() => toggleExpand('inputs')}
            >
                {!!inputKeys?.length && (
                    <div className="flex flex-col gap-4">
                        {inputKeys.map((key) => (
                            <Input
                                key={key}
                                size="md"
                                value={formData.inputs[key]}
                                name={key}
                                label={capitalizeFirstLetter(key)}
                                placeholder={`Add {${key}} here`}
                                onChange={(e) => handleChange('inputs', key, e.target.value)}
                            />
                        ))}
                    </div>
                )}
                {!inputKeys?.length && (
                    <p className="font-body text-body-regular-s text-neutral-500 italic mb-[-8px]">
                        None
                    </p>
                )}
            </CollapsableContainer>

            <CollapsableContainer
                title="Outputs"
                isExpanded={isExpanded.outputs}
                toggleExpand={() => toggleExpand('outputs')}
            >
                {!!outputKeys?.length && (
                    <div className="flex flex-col gap-4">
                        {outputKeys.map((key) => (
                            <Input
                                key={key}
                                size="md"
                                value={formData.outputs[key]}
                                name={key}
                                label={capitalizeFirstLetter(key)}
                                placeholder={`Add {${key}} here`}
                                onChange={(e) => handleChange('outputs', key, e.target.value)}
                            />
                        ))}
                    </div>
                )}
                {!outputKeys?.length && (
                    <p className="font-body text-body-regular-s text-neutral-500 italic mb-[-8px]">
                        None
                    </p>
                )}
            </CollapsableContainer>
        </div>
    );
};

export default NewDatasetRecordForm;
