import React from 'react';
import CollapsableContainer from '../../../../components/CollapsableContainer/CollapsableContainer';
import { Select } from '../../../../design-system';

const TriggerSettingsBlock = ({ isExpanded, toggleExpand, selectedGoal }) => {
    if (!selectedGoal) {
        return <></>;
    }

    return (
        <CollapsableContainer
            title="Trigger Settings"
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
        >
            <div className="mb-[-8px]">
                <Select
                    size="xs"
                    name="goal"
                    value={selectedGoal.id}
                    options={[{ id: selectedGoal.id, name: selectedGoal.name }]}
                    state="disabled"
                    label="Choose a Goal to Trigger"
                    isRequired
                    tipText="This is not editable after task creation"
                    onChange={() => {}}
                />
            </div>
        </CollapsableContainer>
    );
};

export default TriggerSettingsBlock;
