import React from 'react';
import { Link } from 'react-router-dom';

import { issueTypes } from '../../../../constants/template';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';

import { ButtonIcon, Input } from '../../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../../design-system/Icons';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import NewRichTextArea from '../../../../design-system/NewRichTextArea/NewRichTextArea';

const IssueDetailsBlock = ({
    templateVersionData,
    isExpanded,
    toggleExpand,
    editable,
    versionChangeableData,
    setVersionChangeableData,
    setChangedVersionFields,
    versionInputsErrorMessages,
    setVersionInputsErrorMessages,
}) => {
    const { type, issue_url, name } = templateVersionData;

    const handleInputChange = (fieldName, value) => {
        setVersionChangeableData((prevData) => ({ ...prevData, [fieldName]: value }));
        setChangedVersionFields((prevFields) => addUniqueElementToArray(fieldName, prevFields));
        setVersionInputsErrorMessages((prevData) => ({ ...prevData, [fieldName]: null }));
    };

    return (
        <div className="px-5 l:px-8 py-2 rounded-2 bg-neutral-50 flex flex-col gap-4">
            <div className="flex items-center justify-between" onClick={toggleExpand}>
                <p className="font-body-bold text-body-bold-m text-neutral-500 uppercase">
                    Issue Details
                </p>
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                />
            </div>

            {isExpanded && (
                <div className="flex flex-col gap-4 mb-3">
                    <Input
                        size="xs"
                        name="name"
                        value={editable ? versionChangeableData.name : name}
                        label="Issue Title"
                        isRequired
                        state={
                            !editable
                                ? 'disabled'
                                : versionInputsErrorMessages.name
                                ? 'error'
                                : 'default'
                        }
                        errorMessage={versionInputsErrorMessages.name}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                    />
                    <FormFieldWrapper label="Issue Type" isRequired>
                        <div className="w-full grid grid-cols-1 min-[500px]:grid-cols-2 min-[800px]:grid-cols-3 gap-x-2 gap-y-1">
                            {issueTypes.map((issue) => (
                                <div
                                    key={issue.id}
                                    className={`w-full p-3 shadow-inset-2 rounded-2 self-stretch ${
                                        type === issue.id
                                            ? 'shadow-purple-500 bg-white'
                                            : 'shadow-neutral-200 bg-transparent'
                                    }`}
                                >
                                    <p className="font-body text-body-bold-s text-neutral-500 mb-0.5">
                                        {issue.name}
                                    </p>
                                    <p className="font-body text-body-regular-s text-black">
                                        {issue.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </FormFieldWrapper>
                    <NewRichTextArea
                        value={versionChangeableData.description || ''}
                        setValue={(value) => handleInputChange('description', value)}
                        label="Issue Description"
                        isRequired
                        state={
                            !editable
                                ? 'disabled'
                                : versionInputsErrorMessages.description
                                ? 'error'
                                : 'default'
                        }
                        errorMessage={versionInputsErrorMessages.description}
                        autoExpand
                        minHeight={120}
                        withCopyButton
                    />
                    <Link
                        to={issue_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-body-bold text-body-bold-xs underline text-purple-500"
                    >
                        Go to GitHub issue
                    </Link>
                </div>
            )}
        </div>
    );
};

export default IssueDetailsBlock;
