import PropTypes from 'prop-types';
import { Button } from 'design-system';

export default function PaginationFooter({ start, end, total, onNextPressed, onPreviousPressed }) {
    const previousDisabled = start === 1 ? 'disabled' : 'default';
    const nextDisabled = end === total ? 'disabled' : 'default';

    function handleNextClick() {
        onNextPressed();
    }
    function handlePrvClick() {
        onPreviousPressed();
    }

    return (
        <div className="flex flex-col content-center items-center gap-4 self-stretch px-3 py-6 ">
            <div className="flex content-center items-center gap-3">
                <Button
                    type="ghost"
                    text="Previous"
                    state={previousDisabled}
                    size="sm"
                    onClick={handlePrvClick}
                />
                <Button
                    type="ghost"
                    text="Next"
                    state={nextDisabled}
                    size="sm"
                    onClick={handleNextClick}
                />
            </div>
            <p className="font-body text-body-regular-s text-gray-700">{`Showing ${start} to ${end} of ${total} results`}</p>
        </div>
    );
}

PaginationFooter.propTypes = {
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onNextPressed: PropTypes.func.isRequired,
    onPreviousPressed: PropTypes.func.isRequired,
};
