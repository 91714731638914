import React, { useState } from 'react';
import { VIEW_TYPES } from '../../../constants/viewTypes';
import {
    itemHoverColor,
    logoIconColor,
    navActiveItemIconColor,
    navItemIconColor,
    sideBarBg,
} from '../../../constants/sideBar';
import SvgIcon from '../../../design-system/SvgIcon/SvgIcon';
import Search2LineIcon from '../../../design-system/Icons/Search2LineIcon';
import MenuLineIcon from '../../../design-system/Icons/MenuLineIcon';
import Logo from '../../Logo/Logo';
import { CloseLineIcon } from '../../../design-system/Icons';

const SideBarMobVersionHeader = ({ viewType, isMenuOpened, setIsMenuOpened }) => {
    const [isHover, setIsHover] = useState(false);

    const handleMenuClose = () => {
        setIsMenuOpened(false);
        setIsHover(false);
    };

    const handleMenuOpen = () => {
        setIsMenuOpened(true);
        setIsHover(false);
    };

    const navStyles = `w-9 h-9 flex justify-center items-center rounded-2 transition-colors duration-200 cursor-pointer ${itemHoverColor[viewType]}`;

    return (
        <div
            className={`sm:hidden fixed top-0 left-0 right-0 z-30 w-full h-15 px-[20px] flex justify-between items-center transition-colors ${
                sideBarBg[viewType]
            } ${viewType === VIEW_TYPES.client && 'border-b-1 border-neutral-200'}`}
        >
            {isMenuOpened ? (
                <>
                    {viewType === VIEW_TYPES.admin ? (
                        <div className={navStyles} onClick={handleMenuClose}>
                            <SvgIcon
                                color={navItemIconColor[viewType]}
                                size="large"
                                icon={Search2LineIcon}
                            />
                        </div>
                    ) : (
                        <div className="w-9 h-9"></div>
                    )}
                </>
            ) : (
                <div
                    className={navStyles}
                    onClick={handleMenuOpen}
                    onMouseEnter={() => {
                        setIsHover(true);
                    }}
                    onMouseLeave={() => {
                        setIsHover(false);
                    }}
                >
                    <SvgIcon
                        color={
                            isHover ? navActiveItemIconColor[viewType] : navItemIconColor[viewType]
                        }
                        size="large"
                        icon={MenuLineIcon}
                    />
                </div>
            )}
            <div>
                <Logo color={logoIconColor[viewType]} />
            </div>
            {isMenuOpened ? (
                <div
                    className={navStyles}
                    onClick={handleMenuClose}
                    onMouseEnter={() => {
                        setIsHover(true);
                    }}
                    onMouseLeave={() => {
                        setIsHover(false);
                    }}
                >
                    <SvgIcon
                        color={
                            isHover ? navActiveItemIconColor[viewType] : navItemIconColor[viewType]
                        }
                        size="large"
                        icon={CloseLineIcon}
                    />
                </div>
            ) : (
                <>
                    {viewType === VIEW_TYPES.admin ? (
                        <div className={navStyles} onClick={handleMenuClose}>
                            <SvgIcon
                                color={navItemIconColor[viewType]}
                                size="large"
                                icon={Search2LineIcon}
                            />
                        </div>
                    ) : (
                        <div className="w-9 h-9"></div>
                    )}
                </>
            )}
        </div>
    );
};

export default SideBarMobVersionHeader;
