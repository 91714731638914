import React from 'react';
import ArrowRightSLineIcon from '../../../design-system/Icons/ArrowRightSLineIcon';

const FolderBreadcrumbItem = ({
    item,
    index,
    folderId,
    isHidden,
    setIsFullLocationDisplayed,
    handleItemClick,
}) => {
    if (isHidden) {
        return (
            <button
                onClick={() => setIsFullLocationDisplayed(true)}
                className="flex items-center gap-1 whitespace-nowrap outline-0 focus:outline-none"
            >
                <p className="font-body-medium text-body-regular-xs text-neutral-300">...</p>
                <ArrowRightSLineIcon width={10} height={10} color="#5E6470" />
            </button>
        );
    }

    const { id, label } = item;

    const isCurrentFolder = id === folderId;
    const labelClassName = isCurrentFolder
        ? 'font-body-medium text-body-regular-s text-neutral-500'
        : 'font-body-medium text-body-regular-xs text-neutral-300';

    return (
        <button
            onClick={() => handleItemClick(id, index)}
            className="flex items-center gap-1 whitespace-nowrap outline-0 focus:outline-none"
        >
            <p className={labelClassName}>{label}</p>
            {!isCurrentFolder && <ArrowRightSLineIcon width={10} height={10} color="#5E6470" />}
        </button>
    );
};

export default FolderBreadcrumbItem;
