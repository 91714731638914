import React from 'react';

const AlignLeftIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                d="M3 4H21V6H3V4ZM3 19H17V21H3V19ZM3 14H21V16H3V14ZM3 9H17V11H3V9Z"
                fill={props.color}
            />
        </svg>
    );
};

export default AlignLeftIcon;
