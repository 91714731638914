import React, { useState } from 'react';

import {
    useCellValues,
    currentListType$,
    iconComponentFor$,
    usePublisher,
    applyListType$,
} from '@mdxeditor/editor';

import ListOrderedIcon from '../../../Icons/ListOrderedIcon';
import ListCheckIcon from '../../../Icons/ListCheckIcon';
import { ListOptionGroup, SvgIcon } from '../../../index';
import { ArrowDownSLineIcon } from '../../../Icons';

const data = [
    { value: 'number', label: 'Ordered List', icon: ListOrderedIcon },
    { value: 'bullet', label: 'Unordered List', icon: ListCheckIcon },
];

const ToolbarLists = ({ isMobile }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentListType] = useCellValues(currentListType$, iconComponentFor$);
    const applyListType = usePublisher(applyListType$);

    const onClose = (e) => {
        e?.stopPropagation();
        setIsPopupOpen(false);
    };

    if (isMobile) {
        return (
            <div className="relative">
                <button
                    className="flex p-[6px] hover:bg-neutral-50"
                    onClick={() => setIsPopupOpen(true)}
                >
                    <SvgIcon color="#1F2125" icon={ListCheckIcon} size="medium" />
                    <SvgIcon color="#1F2125" icon={ArrowDownSLineIcon} size="medium" />
                </button>
                {isPopupOpen && (
                    <div className="absolute -left-1 z-30 top-[110%]">
                        <ListOptionGroup
                            additionalStyles="!p-2"
                            fixedWidth={170}
                            listOptions={
                                <>
                                    {data.map(({ value, label, icon }) => (
                                        <button
                                            key={value}
                                            onClick={() => {
                                                applyListType(
                                                    currentListType === value ? '' : value
                                                );
                                                onClose();
                                            }}
                                            className="flex items-center gap-2 px-2 py-[5px] hover:bg-neutral-50 w-full rounded-2"
                                        >
                                            <SvgIcon color="#1F2125" icon={icon} size="medium" />
                                            <p className="text-body-regular-xs text-neutral-500 pt-[1px]">
                                                {label}
                                            </p>
                                        </button>
                                    ))}
                                </>
                            }
                            handleClose={onClose}
                        />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div>
            {data.map(({ value, icon }) => (
                <button
                    key={value}
                    className="p-[6px] hover:bg-neutral-50 rounded-2"
                    onClick={() => applyListType(currentListType === value ? '' : value)}
                >
                    <SvgIcon color="#1F2125" icon={icon} size="medium" />
                </button>
            ))}
        </div>
    );
};

export default ToolbarLists;
