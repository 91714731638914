import React, { useEffect, useRef, useState } from 'react';
import { mutate } from 'swr';
import { API } from 'constants';
import client from '../../../services/library-api';
import { useClickOutside } from '../../../hooks';
import CollectionsSearchBar from '../CollectionsSearchBar/CollectionsSearchBar';
import { Button, SvgIcon } from '../../../design-system';
import BookmarkFillIcon from '../../../design-system/Icons/BookmarkFillIcon';
import Checkbox from '../../../design-system/Checkbox/Checkbox';
import { AddCircleLineIcon } from '../../../design-system/Icons';
import CollectionActionModal from '../CollectionActionModal/CollectionActionModal';
import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import { areNumericArraysEqual } from '../../../helpers/areNumericArraysEqual';
import Loading from '../../../components/Loading';

const CollectionSelector = ({
    currentTaskCollections,
    allCollections,
    setAllCollections,
    updateCollectionsState,
    taskId,
    onClose,
    swrKey,
    setAlert,
    containerWidth = 360,
    containerMaxHeight = 350,
    position = { top: '30px', left: 0 },
}) => {
    const [initialSelectedCollectionsIds, setInitialSelectedCollectionsIds] = useState([]);
    const [selectedCollectionsIds, setSelectedCollectionsIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const selectorRef = useRef(null);
    const [selectorHeight, setSelectorHeight] = useState(0);
    const [scrollContainerTop, setScrollContainerTop] = useState(0);

    useEffect(() => {
        if (currentTaskCollections) {
            const selectedIdsArray = currentTaskCollections.map((item) => item.id);
            setInitialSelectedCollectionsIds(selectedIdsArray);
            setSelectedCollectionsIds(selectedIdsArray);
        }
    }, []);

    useEffect(() => {
        if (selectorRef.current) {
            const height = selectorRef.current.getBoundingClientRect().height;
            setSelectorHeight(height || 0);
        }
    }, [selectorRef.current]);

    const [filteredCollections, setFilteredCollections] = useState([]);
    const [isCreateCollectionModalOpened, setIsCreateCollectionModalOpened] = useState(false);
    const [successAlert, setSuccessAlert] = useState({ message: '', shown: false });
    const changeTaskCollections = async (e) => {
        try {
            e.stopPropagation();
            if (areNumericArraysEqual(selectedCollectionsIds, initialSelectedCollectionsIds)) {
                onClose(e);
                return;
            }
            if (isLoading) {
                return;
            }
            if (selectorRef.current) {
                setScrollContainerTop(selectorRef.current.scrollTop);
            }
            setIsLoading(true);
            const { data } = await client.patch(`${API.ROUTES.library.task}${taskId}/`, {
                collections: selectedCollectionsIds,
            });
            if (swrKey) {
                mutate(
                    swrKey,
                    (currentData) => {
                        const updatedData = currentData.results.map((item) =>
                            item.id === taskId ? data : item
                        );
                        return { ...currentData, results: updatedData };
                    },
                    {
                        revalidate: true,
                    }
                );
            }
            if (updateCollectionsState) {
                updateCollectionsState(data.collections);
            }
            setIsLoading(false);
            setScrollContainerTop(0);
            onClose(e);
        } catch (error) {
            setAlert({ show: true, statusCode: error.response.status });
            setIsLoading(false);
            setScrollContainerTop(0);
            onClose(e);
        }
    };

    useClickOutside(selectorRef, isCreateCollectionModalOpened ? () => {} : changeTaskCollections);

    const openCreateCollectionModal = (e) => {
        e.stopPropagation();
        setIsCreateCollectionModalOpened(true);
    };

    const onCheckboxChange = (id) => {
        const isChecked = selectedCollectionsIds.includes(id);
        if (isChecked) {
            setSelectedCollectionsIds((prevState) => prevState.filter((item) => item !== id));
        } else {
            setSelectedCollectionsIds((prevState) => [...prevState, id]);
        }
    };

    return (
        <>
            <div
                className={`absolute z-56 w-[360px] rounded-lg border border-neutral-200 bg-white shadow-lg p-4 flex flex-col items-start gap-3 ${
                    isLoading ? 'overflow-hidden' : 'overflow-y-auto'
                }  overflow-x-hidden`}
                ref={selectorRef}
                style={{
                    width: `${containerWidth}px`,
                    maxHeight: `${containerMaxHeight}px`,
                    ...position,
                }}
            >
                {isLoading && (
                    <div
                        className="absolute left-0 right-0 bottom-0 bg-black/20 z-57 flex items-center justify-center"
                        style={{ height: `${selectorHeight}px`, top: `${scrollContainerTop}px` }}
                    >
                        <Loading withText={false} />
                    </div>
                )}
                <CollectionsSearchBar
                    allCollections={allCollections}
                    filterCollections={setFilteredCollections}
                />
                {filteredCollections.length > 0 && (
                    <div className="flex flex-col gap-1 w-full max-w-full">
                        {filteredCollections.map((item) => (
                            <div key={item.id} className="px-2 py-1 w-full flex items-center gap-2">
                                <SvgIcon
                                    color={item.icon_color}
                                    icon={BookmarkFillIcon}
                                    size="medium"
                                />
                                <p className="font-body text-body-regular-s text-neutral-500 truncate w-[calc(100%-56px)]">
                                    {item.name}
                                </p>
                                <Checkbox
                                    isChecked={selectedCollectionsIds.includes(item.id)}
                                    name={item.id}
                                    onChange={() => onCheckboxChange(item.id)}
                                />
                            </div>
                        ))}
                    </div>
                )}
                <div>
                    <Button
                        type="link"
                        size="xs"
                        text="New Collection"
                        leadingIcon={AddCircleLineIcon}
                        onClick={openCreateCollectionModal}
                    />
                </div>
            </div>
            {isCreateCollectionModalOpened && (
                <CollectionActionModal
                    handleClose={() => setIsCreateCollectionModalOpened(false)}
                    action="create"
                    showAlert={(message) => setSuccessAlert({ shown: true, message })}
                    setCollections={setAllCollections}
                />
            )}
            {successAlert.shown && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    handleClose={() => setSuccessAlert({ shown: false, message: '' })}
                />
            )}
        </>
    );
};

export default CollectionSelector;
