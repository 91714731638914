import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';

import SearchBar from '../../../design-system/SearchBar/SearchBar';

const DocsPageSearch = ({ folderId }) => {
    const navigate = useNavigate();

    const { setLocation } = useDocsPageContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = searchParams.get('search') || '';

    const [searchValue, setSearchValue] = useState(searchQuery);

    const handleSubmitSearchQuery = () => {
        if (folderId) {
            navigate(`/docs?page=1&search=${searchValue}`);
            setLocation([]);
        }

        if (!folderId) {
            const urlSearchParams = new URLSearchParams(searchParams);

            urlSearchParams.set('search', searchValue);
            urlSearchParams.set('page', '1');

            setSearchParams(urlSearchParams);
        }
    };

    const handleClearSearchQuery = () => {
        navigate(`/docs?page=1`);
        setSearchValue('');
        setLocation([]);
    };

    useEffect(() => {
        if (folderId) {
            setSearchValue('');
        }
    }, [folderId]);

    return (
        <div className="w-full" style={{ maxWidth: '550px' }}>
            <SearchBar
                size="xs"
                value={searchValue}
                placeholder="Search ..."
                withSubmitIcon
                withClearIcon
                onChange={(e) => setSearchValue(e.target.value)}
                onSubmit={handleSubmitSearchQuery}
                onClear={handleClearSearchQuery}
            />
        </div>
    );
};

export default DocsPageSearch;
