import React from 'react';

import { generalSortOptions } from '../../../constants/sort';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import SortControls from '../../../components/SortControls/SortControls';
import SearchBarWithUrlParamsHandling from '../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';
import { AddCircleLineIcon } from '../../../design-system/Icons';
import { Button } from '../../../design-system';

const BotsPageHeader = ({ setIsCreateBotModalOpen }) => {
    const { isMobile } = useResponsiveBreakpoints();

    const sortDropdownPosition = isMobile ? { top: '120%', right: 0 } : { top: '120%', left: 0 };

    return (
        <>
            <div>
                <h1 className="font-heading-bold text-heading-bold-l text-black">Custom Bots</h1>
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Discover and create custom AI bots that combine instructions and available
                    knowledge about your organization.{' '}
                </p>
            </div>

            <div className="flex flex-col-reverse xs:items-center xs:justify-between gap-x-4 gap-y-2 xs:flex-row">
                <div className="w-full xs:flex-1 xs:max-w-[378px] flex flex-1 items-center gap-3">
                    <SearchBarWithUrlParamsHandling size="xs" placeholder="Search by name" />

                    <SortControls
                        variant="primary"
                        sortOptions={generalSortOptions}
                        buttonContainerClassName="relative"
                        dropdownPosition={sortDropdownPosition}
                    />
                </div>

                <Button
                    type="primary"
                    size="xs"
                    text="New Bot"
                    state="default"
                    leadingIcon={AddCircleLineIcon}
                    onClick={() => setIsCreateBotModalOpen(true)}
                />
            </div>
        </>
    );
};

export default BotsPageHeader;
