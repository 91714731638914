export const updateQueryParameters = ({ resultId, clearFillId = false }) => {
    const searchParams = new URLSearchParams(window.location.search);

    if (resultId === null) {
        searchParams.delete('result');
    } else {
        searchParams.set('result', resultId);
    }

    if (clearFillId) {
        searchParams.delete('fill');
    }

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
};
