import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../design-system/Modal/Modal';
import { Button } from '../../design-system';

const ConfirmNavigationModal = ({
    onConfirm,
    onCancel,
    text = 'Are you sure you want to leave? We’ll keep loading your process in the background.',
    confirmButtonText = 'Yes, Leave Page',
    cancelButtonText = 'No, Stay on Page',
    buttonsTheme = 'dark',
}) => {
    return (
        <Modal onClose={onCancel} size="medium">
            <div className="sm:p-2 flex flex-col gap-6">
                <p className="font-heading-bold text-heading-bold-m text-neutral-400">{text}</p>
                <div className="flex items-center gap-4 justify-center">
                    {buttonsTheme === 'dark' && (
                        <>
                            <Button
                                type="neutral"
                                size="sm"
                                text={cancelButtonText}
                                onClick={onCancel}
                            />
                            <Button
                                type="primary"
                                size="sm"
                                text={confirmButtonText}
                                onClick={onConfirm}
                            />
                        </>
                    )}
                    {buttonsTheme === 'colored' && (
                        <>
                            <Button
                                type="neutral"
                                size="sm"
                                text={confirmButtonText}
                                onClick={onConfirm}
                            />
                            <Button
                                type="secondary"
                                size="sm"
                                text={cancelButtonText}
                                onClick={onCancel}
                            />
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

ConfirmNavigationModal.propType = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    text: PropTypes.string,
    confirmButtonText: PropTypes.string,
    buttonsTheme: PropTypes.oneOf(['dark', 'colored']),
};

export default ConfirmNavigationModal;
