import React from 'react';

const ArrowGoForwardLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M12.1157 4.6667L10.4253 6.35706L11.3681 7.29986L14.668 4.00003L11.3681 0.700195L10.4253 1.64301L12.1157 3.33336H7.33464C4.3891 3.33336 2.0013 5.72118 2.0013 8.66673C2.0013 11.6122 4.3891 14.0001 7.33464 14.0001H13.3346V12.6667H7.33464C5.1255 12.6667 3.33463 10.8759 3.33463 8.66673C3.33463 6.45756 5.1255 4.6667 7.33464 4.6667H12.1157Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ArrowGoForwardLineIcon;
