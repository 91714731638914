import React from 'react';
import useUser from '../../../hooks/useUser';

import { Robot2LineIcon } from '../../../design-system/Icons';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';

const InstructionsBlock = ({
    instructions,
    description = 'Here are the instructions for completing this task.',
}) => {
    const { user } = useUser();
    const title = user?.first_name ? `Hello ${user.first_name}!` : 'Hello there!';

    return (
        <div className="p-5 w-full">
            <div className="flex items-center gap-3 mb-1">
                <Robot2LineIcon width={26} height={26} color="#754DCF" />
                <p className="font-heading-bold text-heading-bold-s text-neutral-500">{title}</p>
            </div>
            <p className="font-heading-regular text-heading-regular-s text-neutral-500 mb-4">
                {description}
            </p>
            <RichTextArea
                value={instructions}
                previewOnly
                isBorderHidden
                autoExpand
                minHeight={38}
            />
        </div>
    );
};

export default InstructionsBlock;
