import React from 'react';

const CheckDoubleLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 32 32" fill="none">
            <path
                d="M15.47 18.3465L17.3527 20.2292L28.64 8.94174L30.5257 10.8274L17.3527 24.0004L8.86732 15.5151L10.7529 13.6295L13.586 16.4625L15.47 18.3465ZM15.4723 14.5763L22.0757 7.97266L23.9561 9.85303L17.3527 16.4565L15.4723 14.5763ZM11.7033 22.1164L9.81926 24.0004L1.33398 15.5151L3.2196 13.6295L5.10362 15.5136L5.10204 15.5151L11.7033 22.1164Z"
                fill={props.color}
            />
        </svg>
    );
};

export default CheckDoubleLineIcon;
