import React, { useState } from 'react';

import { More2FillIcon } from '../../../../design-system/Icons';
import { Badge, ButtonIcon, ListOption, ListOptionGroup } from '../../../../design-system';

const InboxRecordItem = ({ item, openActionModal }) => {
    const { assigned_to_email, assigned_to_id, status, message_id } = item;

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);

    const badgeText = status === 'success' ? 'Approved' : 'Waiting for approval';
    const badgeColor = status === 'success' ? 'lime' : 'peach';

    return (
        <li className="relative py-1.5 border-b-1 border-neutral-200 flex justify-between gap-2 sm:gap-4">
            <div className="w-[calc(100%-36px)] sm:w-[calc(100%-44px)] flex items-center gap-3">
                <p className="font-body text-body-regular-m text-neutral-300 truncate flex-grow">
                    {assigned_to_email}
                </p>
                <Badge text={badgeText} color={badgeColor} />
            </div>
            <ButtonIcon
                type="link"
                size="xs"
                icon={More2FillIcon}
                onClick={() => setIsMoreOptionsPopupOpened(true)}
            />

            {isMoreOptionsPopupOpened && (
                <div className="absolute right-0 top-[90%] z-50">
                    <ListOptionGroup
                        fixedWidth={300}
                        listOptions={
                            <>
                                {status !== 'success' && (
                                    <ListOption
                                        leadingIconName="userSharedLineIcon"
                                        text="Reassign"
                                        onClick={() => {
                                            openActionModal('reassign', message_id, assigned_to_id);
                                            setIsMoreOptionsPopupOpened(false);
                                        }}
                                    />
                                )}
                                <ListOption
                                    leadingIconName="inboxIndicatorIcon"
                                    text="View Message"
                                    onClick={() => {
                                        openActionModal('view', message_id);
                                        setIsMoreOptionsPopupOpened(false);
                                    }}
                                />
                            </>
                        }
                        handleClose={(e) => {
                            e.stopPropagation();
                            setIsMoreOptionsPopupOpened(false);
                        }}
                    />
                </div>
            )}
        </li>
    );
};

export default InboxRecordItem;
