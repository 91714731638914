import React from 'react';
import CollapsableContainer from '../../../../components/CollapsableContainer/CollapsableContainer';
import { Select } from '../../../../design-system';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';

const requestMethodsOptions = [
    { id: 'POST', name: 'POST' },
    { id: 'GET', name: 'GET' },
    { id: 'PUT', name: 'PUT' },
    { id: 'PATCH', name: 'PATCH' },
    { id: 'DELETE', name: 'DELETE' },
    { id: 'HEAD', name: 'HEAD' },
    { id: 'OPTIONS', name: 'OPTIONS' },
];

const requestFormatOptions = [
    { id: 'json_object', name: 'JSON Object' },
    { id: 'raw_text', name: 'Raw Text' },
];

const ApiCallSettingsBlock = ({
    apiTaskChangeableData,
    setApiTaskChangeableData,
    setChangedTaskFields,
    isExpanded,
    toggleExpand,
}) => {
    const { request_method, request_format } = apiTaskChangeableData;

    const handleSelectChange = (value, name) => {
        setApiTaskChangeableData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setChangedTaskFields((prevFields) => addUniqueElementToArray(name, prevFields));
    };

    return (
        <CollapsableContainer
            title="API Call Settings"
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
        >
            <div className="flex flex-col gap-4">
                <Select
                    size="xs"
                    value={request_method}
                    name="request_method"
                    label="Choose a Request Method"
                    isRequired
                    options={requestMethodsOptions}
                    onChange={handleSelectChange}
                />
                <Select
                    size="xs"
                    value={request_format}
                    name="request_format"
                    label="Choose a Request Format"
                    isRequired
                    options={requestFormatOptions}
                    onChange={handleSelectChange}
                    tipText="This will impact the format of the data sent in the request. See provider’s API docs for help."
                />
            </div>
        </CollapsableContainer>
    );
};

export default ApiCallSettingsBlock;
