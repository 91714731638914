import React, { memo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';
import ParentOrganizationNavItem from '../ParentOrganizationNavItem/ParentOrganizationNavItem';

const OrganizationsNavBar = ({
    setIsSidePanelOpened,
    organizationsList,
    organizationsLoading,
    setPage,
    canLoadMoreOrganizations,
}) => {
    const { organizationId: selectedParentOrgId } = useParams();
    const [searchParams] = useSearchParams();
    const selectedChildOrgId = searchParams.get('client');

    return (
        <>
            <p className="text-body-bold-xs font-body-bold text-neutral-300 uppercase">
                Organizations
            </p>

            <div className="flex-1 overflow-y-auto mr-[-16px] pr-4">
                <InfiniteScrollList
                    handleFetch={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMoreOrganizations}
                    items={organizationsList}
                    loading={organizationsLoading}
                    gap={4}
                >
                    {organizationsList.map((organization) => (
                        <ParentOrganizationNavItem
                            key={organization.id}
                            parentOrganization={organization}
                            selectedParentOrgId={selectedParentOrgId}
                            selectedChildOrgId={selectedChildOrgId}
                            setIsSidePanelOpened={setIsSidePanelOpened}
                        />
                    ))}
                </InfiniteScrollList>
            </div>
        </>
    );
};

export default memo(OrganizationsNavBar);
