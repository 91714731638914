import AlignLeftIcon from '../design-system/Icons/AlignLeftIcon';
import ImageAddLineIcon from '../design-system/Icons/ImageAddLineIcon';
import VideoAddLineIcon from '../design-system/Icons/VideoAddLineIcon';
import TextBlockIcon from '../design-system/Icons/TextBlockIcon';
import FileTextLineIcon from '../design-system/Icons/FileTextLineIcon';
import ChatSmile2LineIcon from '../design-system/Icons/ChatSmile2LineIcon';
import { FlashlightFillIcon, FlowChartIcon } from '../design-system/Icons';

export const PLAYBOOK_BUILDER_MODE = {
    edit: 'edit',
    view: 'view',
};

export const PLAYBOOK_ACCESS_TYPE = {
    private: 'private',
    shared: 'shared',
};

export const BLOCK_TYPE = {
    richtext: 'richtext',
    image: 'image',
    video: 'video',
    callout: 'callout',
    document: 'document',
    chat_prompt: 'chat_prompt',
    agent: 'agent',
    project: 'project',
};

export const BLOCK_TYPE_LABEL = {
    [BLOCK_TYPE.richtext]: 'Text',
    [BLOCK_TYPE.image]: 'Image',
    [BLOCK_TYPE.video]: 'Video',
    [BLOCK_TYPE.callout]: 'Callout',
    [BLOCK_TYPE.document]: 'Document',
    [BLOCK_TYPE.chat_prompt]: 'Prompt',
    [BLOCK_TYPE.agent]: 'Agent',
    [BLOCK_TYPE.project]: 'Project',
};

export const BLOCK_TYPE_ICON = {
    [BLOCK_TYPE.richtext]: AlignLeftIcon,
    [BLOCK_TYPE.image]: ImageAddLineIcon,
    [BLOCK_TYPE.video]: VideoAddLineIcon,
    [BLOCK_TYPE.callout]: TextBlockIcon,
    [BLOCK_TYPE.document]: FileTextLineIcon,
    [BLOCK_TYPE.chat_prompt]: ChatSmile2LineIcon,
    [BLOCK_TYPE.agent]: FlashlightFillIcon,
    [BLOCK_TYPE.project]: FlowChartIcon,
};

export const DEFAULT_SECTION_NAME = 'Add a section title';

export const EMPTY_PLAYBOOK_SECTION = {
    id: null,
    order: 1,
    name: '',
    description: '',
    version_id: null,
    block_content: [],
};

export const EMPTY_CALLOUT_BLOCK_PROPERTIES = {
    properties: {
        title: '',
        description: '',
        callout_color: '#5E6470',
    },
};

export const EMPTY_RICHTEXT_BLOCK_PROPERTIES = {
    properties: {
        content: '',
    },
};

export const EMPTY_IMAGE_BLOCK_PROPERTIES = {
    properties: {
        image_url: '',
    },
};

export const EMPTY_VIDEO_BLOCK_PROPERTIES = {
    properties: {
        video_url: '',
    },
};

export const EMPTY_DOCUMENT_BLOCK_PROPERTIES = {
    properties: {
        document_id: '',
    },
};

export const EMPTY_AGENT_BLOCK_PROPERTIES = {
    properties: {
        agent_id: '',
        label: '',
    },
};

export const EMPTY_CHAT_PROMPT_BLOCK_PROPERTIES = {
    properties: {
        chat_prompt_id: '',
    },
};

export const EMPTY_PROJECT_BLOCK_PROPERTIES = {
    properties: {
        scenario_id: '',
        label: '',
    },
};

export const EMPTY_BLOCK_PROPERTIES = {
    [BLOCK_TYPE.richtext]: EMPTY_RICHTEXT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.image]: EMPTY_IMAGE_BLOCK_PROPERTIES,
    [BLOCK_TYPE.video]: EMPTY_VIDEO_BLOCK_PROPERTIES,
    [BLOCK_TYPE.callout]: EMPTY_CALLOUT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.document]: EMPTY_DOCUMENT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.chat_prompt]: EMPTY_CHAT_PROMPT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.agent]: EMPTY_AGENT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.project]: EMPTY_PROJECT_BLOCK_PROPERTIES,
};

export const PRIVATE_ACCESS_BLOCK_OPTIONS = [
    {
        name: 'Content Block',
        options: [BLOCK_TYPE.richtext, BLOCK_TYPE.image, BLOCK_TYPE.video, BLOCK_TYPE.callout],
    },
    {
        name: 'Prebuilt Block',
        options: [
            BLOCK_TYPE.document,
            BLOCK_TYPE.chat_prompt,
            BLOCK_TYPE.agent,
            BLOCK_TYPE.project,
        ],
    },
];

export const SHARED_ACCESS_BLOCK_OPTIONS = [
    {
        name: 'Content Block',
        options: [BLOCK_TYPE.richtext, BLOCK_TYPE.image, BLOCK_TYPE.video, BLOCK_TYPE.callout],
    },
    {
        name: 'Prebuilt Block',
        options: [BLOCK_TYPE.chat_prompt, BLOCK_TYPE.agent, BLOCK_TYPE.project],
    },
];

export const ACCESS_BLOCK_OPTIONS = {
    [PLAYBOOK_ACCESS_TYPE.private]: PRIVATE_ACCESS_BLOCK_OPTIONS,
    [PLAYBOOK_ACCESS_TYPE.shared]: SHARED_ACCESS_BLOCK_OPTIONS,
};
