import React from 'react';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';

import { BLOCK_TYPE_ICON, BLOCK_TYPE_LABEL } from '../../../constants/playbookBuilder';
import { SvgIcon } from '../../../design-system';

const BlockOptionFromSelector = ({ blockType, isDraggable = true, addNewBlock, closeSelector }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'NEW_BLOCK',
        item: { blockType, dndType: 'NEW_BLOCK' },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (monitor?.didDrop()) {
                closeSelector();
            }
        },
    });

    const optionClassName = classNames('transition-opacity', {
        'opacity-100': !isDragging,
        'opacity-30': isDragging,
    });

    const buttonClassName = classNames('flex items-center gap-3 w-full py-1', {
        'cursor-grab': isDraggable,
        'cursor-pointer': !isDraggable,
    });

    const handleOptionSelect = () => {
        if (isDraggable) {
            return;
        }

        addNewBlock(blockType);
        closeSelector();
    };

    return (
        <li ref={isDraggable ? dragRef : null} className={optionClassName}>
            <button type="button" className={buttonClassName} onClick={handleOptionSelect}>
                <SvgIcon icon={BLOCK_TYPE_ICON[blockType]} color="#FFFFFF" size="large" />
                <p className="font-body text-body-regular-s text-white">
                    {BLOCK_TYPE_LABEL[blockType]}
                </p>
            </button>
        </li>
    );
};

export default BlockOptionFromSelector;
