import { mutate } from 'swr';

import { API } from 'constants';
import client from '../services/template-api';

export const mutateTemplatesLibraryDataAfterUpdatingLabels = (
    requestKeysToMutate,
    processTemplateId,
    updatedLabels
) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            const isKeyForProcessTemplatesRequest = key.includes('/process-template/');
            if (isKeyForProcessTemplatesRequest) {
                mutate(
                    key,
                    (currentData) => {
                        if (!currentData) {
                            return;
                        }
                        const updatedResults = currentData.results?.map((processTemplate) =>
                            processTemplate.id === processTemplateId
                                ? { ...processTemplate, labels: updatedLabels }
                                : processTemplate
                        );
                        return { ...currentData, results: updatedResults };
                    },
                    { revalidate: false }
                );
            }
        });
    }
};

export const updateLabelsOfProcessTemplate = async (
    selectedLabelsIds,
    processTemplateId,
    abortController
) => {
    const [controller, setController] = abortController;
    try {
        controller.abort(); // cancel the previous request if it's still active

        const newController = new AbortController();
        setController(newController);

        await client.patch(
            `${API.ROUTES.template.processTemplate}${processTemplateId}/`,
            {
                labels: selectedLabelsIds,
            },
            { signal: newController.signal }
        );
    } catch (e) {
        if (e.message === 'canceled') {
            return;
        }
    }
};

export const mutateTemplatesLibraryDataAfterDeletingLabel = (
    requestKeysToMutate,
    deletedLabelId
) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            const isKeyForProcessTemplatesRequest = key.includes('/process-template/');
            if (isKeyForProcessTemplatesRequest) {
                mutate(
                    key,
                    (currentData) => {
                        if (!currentData) {
                            return;
                        }
                        const updatedResults = currentData.results?.map((processTemplate) => ({
                            ...processTemplate,
                            labels: processTemplate.labels.filter(
                                (label) => label.id !== deletedLabelId
                            ),
                        }));

                        return { ...currentData, results: updatedResults };
                    },
                    { revalidate: false }
                );
            }
        });
    }
};

export const mutateTemplatesLibraryDataAfterEditingLabel = (
    requestKeysToMutate,
    editedLabelId,
    editedLabelData
) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            const isKeyForProcessTemplatesRequest = key.includes('/process-template/');
            if (isKeyForProcessTemplatesRequest) {
                mutate(
                    key,
                    (currentData) => {
                        if (!currentData) {
                            return;
                        }
                        const updatedResults = currentData.results?.map((processTemplate) => ({
                            ...processTemplate,
                            labels: processTemplate.labels.map((label) =>
                                label.id === editedLabelId
                                    ? { ...label, ...editedLabelData }
                                    : label
                            ),
                        }));

                        return { ...currentData, results: updatedResults };
                    },
                    { revalidate: false }
                );
            }
        });
    }
};

export const mutateTemplatesLibraryDataAfterDeletingGoalTemplate = (
    requestKeysToMutate,
    deletedGoalTemplateId
) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            const isKeyForGoalTemplatesRequest = key.includes('/goal-template/');
            if (isKeyForGoalTemplatesRequest) {
                mutate(
                    key,
                    (currentData) => {
                        if (!currentData) {
                            return;
                        }
                        const isDeletedGoalTemplateInResponse = !!currentData.results?.find(
                            (goalTemplate) => goalTemplate.id === deletedGoalTemplateId
                        );

                        const updatedCount = isDeletedGoalTemplateInResponse
                            ? currentData.count - 1
                            : currentData.count;

                        const updatedResults = isDeletedGoalTemplateInResponse
                            ? currentData.results?.filter(
                                  (goalTemplate) => goalTemplate.id !== deletedGoalTemplateId
                              )
                            : currentData.results;

                        return { ...currentData, count: updatedCount, results: updatedResults };
                    },
                    { revalidate: true }
                );
            }
        });
    }
};

export const getLabelsSelectorPosition = ({ screenWidth, openingButtonRef }) => {
    // add "relative" to button container starting from 480px. For less than 480px list container should have position: relative
    let position = { top: '30px', left: 0 };
    let width = 360;

    const windowHeight = window.innerHeight;

    if (openingButtonRef.current) {
        const {
            x: buttonXCoord,
            y: buttonYCoord,
            width: buttonWidth,
        } = openingButtonRef.current.getBoundingClientRect();

        let verticalPosition;
        if (windowHeight - buttonYCoord < 380 && buttonYCoord > 380) {
            verticalPosition = { bottom: '30px' };
        } else {
            verticalPosition = { top: '30px' };
        }

        if (screenWidth < 480) {
            position = { ...verticalPosition, left: '50%', transform: 'translateX(-50%)' };
            width = Math.min(340, 0.8 * screenWidth);
        }

        if (screenWidth >= 480) {
            let availableLeftSpace = 0;
            let availableRightSpace = 0;

            if (screenWidth < 640) {
                availableLeftSpace = buttonXCoord + buttonWidth - 20;
                availableRightSpace = screenWidth - buttonXCoord - 20;
            }
            if (screenWidth >= 640) {
                availableLeftSpace = buttonXCoord + buttonWidth - 20 - 68; // 68 is the sideBar width
                availableRightSpace = screenWidth - 20 - buttonXCoord;
            }

            const difference = availableLeftSpace - availableRightSpace;
            const leftSideHasMoreSpace = difference > 0;

            if (availableRightSpace > 380) {
                width = 360;
                position = { ...verticalPosition, left: 0 };
            }

            if (availableRightSpace <= 380) {
                if (leftSideHasMoreSpace) {
                    if (availableLeftSpace > 380) {
                        width = 360;
                    }
                    if (availableLeftSpace <= 380) {
                        width = Math.min(availableLeftSpace - 16, 350);
                    }
                    position = { ...verticalPosition, right: 0 };
                }

                if (!leftSideHasMoreSpace) {
                    width = Math.min(availableRightSpace - 16, 350);
                    position = { ...verticalPosition, left: 0 };
                }
            }
        }
    }

    return { position, width };
};

export const getLabelMoreOptionPopupRef = ({
    selectorPopupRef,
    selectorPopupPosition,
    labelItemRef,
    screenWidth,
}) => {
    const selectorPopupIsPositionedLeft = selectorPopupPosition.hasOwnProperty('left');
    const selectorPopupIsPositionedTop = selectorPopupPosition.hasOwnProperty('top');
    const desktopPopupWidth = 300;
    const popupHeight = 118;

    let verticalPosition = { top: '100%' };
    let horizontalPosition = { right: '-30px' };
    if (selectorPopupRef.current && labelItemRef.current) {
        const {
            x: selectorXCoord,
            y: selectorYCoord,
            width: selectorWidth,
            right: selectorRightValue,
            bottom: selectorBottomValue,
        } = selectorPopupRef.current.getBoundingClientRect();
        const { y: labelItemYCoord, bottom: labelItemBottomValue } =
            labelItemRef.current.getBoundingClientRect();

        if (selectorPopupIsPositionedTop) {
            verticalPosition = { top: 60 + labelItemYCoord - selectorYCoord };
        }
        if (!selectorPopupIsPositionedTop) {
            verticalPosition = {
                bottom: 26 + selectorBottomValue - labelItemBottomValue - popupHeight,
            };
        }

        if (screenWidth < 480) {
            horizontalPosition = { left: '50%', transform: 'translateX(-50%)' };
        }

        if (screenWidth > 480 && screenWidth < 640) {
            if (selectorPopupIsPositionedLeft) {
                horizontalPosition = { left: -selectorXCoord + 60 };
            }
            if (!selectorPopupIsPositionedLeft) {
                horizontalPosition = { left: -selectorRightValue + 80 };
            }
        }

        if (screenWidth >= 640) {
            horizontalPosition = calculateMoreOptionPopupHorizontalPosition(
                screenWidth,
                selectorRightValue,
                selectorPopupIsPositionedLeft,
                selectorWidth,
                desktopPopupWidth
            );
        }
    }

    return { position: { ...horizontalPosition, ...verticalPosition } };
};

function calculateMoreOptionPopupHorizontalPosition(
    screenWidth,
    selectorRightValue,
    selectorPopupIsPositionedLeft,
    selectorWidth,
    desktopPopupWidth
) {
    const availableRightSpace = screenWidth - selectorRightValue - 20;
    let adjustment;

    // Determine the necessary adjustment based on the available right space
    if (availableRightSpace > 230) {
        adjustment = selectorPopupIsPositionedLeft ? -60 : 90;
    } else if (availableRightSpace > 150) {
        adjustment = selectorPopupIsPositionedLeft ? -140 : 170;
    } else if (availableRightSpace > 100) {
        adjustment = selectorPopupIsPositionedLeft ? -200 : 200;
    } else if (availableRightSpace > 50) {
        adjustment = selectorPopupIsPositionedLeft ? -250 : 250;
    } else {
        adjustment = selectorPopupIsPositionedLeft ? -290 : 300;
    }

    // Apply the adjustment to the correct side based on the position of the SelectorPopup
    return selectorPopupIsPositionedLeft
        ? { left: selectorWidth + adjustment }
        : { right: -desktopPopupWidth + adjustment };
}

// Template Detail Page
export const getGoalTemplateSidePanelMoreOptionPopupTopPosition = ({ buttonRef, panelRef }) => {
    const shift = 32;
    let top = 100;
    if (buttonRef.current && panelRef.current) {
        const { top: buttonTopValue } = buttonRef.current.getBoundingClientRect();
        const { top: panelTopValue } = panelRef.current.getBoundingClientRect();
        top = buttonTopValue - panelTopValue + shift;
    }

    return { topPosition: { top } };
};
