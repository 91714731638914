import React, { memo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DateTime } from 'luxon';
import classNames from 'classnames';

import { More2FillIcon } from '../../../../design-system/Icons';
import { ButtonIcon, SvgIcon } from '../../../../design-system';
import ArchiveLineIcon from '../../../../design-system/Icons/ArchiveLineIcon';
import EditBaseEntryPanel from '../EditBaseEntryPanel/EditBaseEntryPanel';
import EntryMoreOptionsPopup from '../EntryMoreOptionsPopup/EntryMoreOptionsPopup';
import ConfirmEntryDeletionModal from '../ConfirmEntryDeletionModal/ConfirmEntryDeletionModal';

const EntryCard = ({ entry, schema, hasEditAccess, requestKeysToMutate }) => {
    const [searchParams] = useSearchParams();
    const { id, label, updated_at } = entry;

    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const [actionModal, setActionModal] = useState(() => {
        const shouldOpenEditModal = searchParams.get('entry') === id;
        if (shouldOpenEditModal) return { action: 'edit' };
        return { action: null };
    });

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');

    const cardContainerClassName = classNames(
        'px-4 py-3 max-w-full rounded-2 bg-white cursor-pointer',
        'grid grid-rows-[auto_auto] md:grid-rows-1 grid-cols-[minmax(100px,_1fr)_28px] md:grid-cols-[minmax(100px,_1fr)_224px_28px] gap-x-4 gap-y-2 items-start md:items-center'
    );

    const openMoreOptionsPopup = (e) => {
        e?.stopPropagation();
        setIsPopupOpened(true);
    };

    const handleCardClick = () => {
        if (actionModal.action === 'edit') return;

        setActionModal({ action: 'edit' });
    };

    return (
        <>
            <div className={cardContainerClassName} onClick={handleCardClick}>
                <div className="flex items-start gap-4 flex-1">
                    <div className="w-[32px] h-[32px] min-w-[32px] rounded-2 bg-purple-100 flex items-center justify-center">
                        <SvgIcon color="#754DCF" icon={ArchiveLineIcon} size="medium" />
                    </div>
                    <p className="font-body-medium text-body-medium-s text-black my-auto">
                        {label}
                    </p>
                </div>

                <div className="pt-2 md:p-0 border-t-1 border-neutral-200 md:border-none row-start-2 row-end-2 col-span-2 md:row-start-auto md:row-end-auto md:col-span-1">
                    <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap truncate">
                        Last Updated{' '}
                        <span className="font-body-bold text-body-bold-xs text-neutral-500">
                            {formattedTime}
                        </span>
                    </p>
                </div>

                <div className="relative">
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={openMoreOptionsPopup}
                    />

                    {isPopupOpened && (
                        <EntryMoreOptionsPopup
                            hasEditAccess={hasEditAccess}
                            openActionModal={setActionModal}
                            onClose={() => setIsPopupOpened(false)}
                        />
                    )}
                </div>
            </div>

            {actionModal.action === 'edit' && (
                <EditBaseEntryPanel
                    entry={entry}
                    schema={schema}
                    requestKeysToMutate={requestKeysToMutate}
                    hasEditAccess={hasEditAccess}
                    onClose={() => setActionModal({ action: null })}
                />
            )}

            {actionModal.action === 'delete' && (
                <ConfirmEntryDeletionModal
                    entry={entry}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setActionModal({ action: null })}
                />
            )}
        </>
    );
};

export default memo(EntryCard);
