import React from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { Button, ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';
import ThumbsUpIcon from '../../../design-system/Icons/ThumbsUpIcon';
import ThumbsDownIcon from '../../../design-system/Icons/ThumbsDownIcon';
import DiscussLineIcon from '../../../design-system/Icons/DiscussLineIcon';

const FeedbackPanel = ({ feedback, setShareThreadFeedbackModal, onClose }) => {
    const { width: screenWidth } = useWindowSize();

    const panelClassName = classNames('bg-white px-5 pt-5 flex flex-col gap-4', {
        'w-[280px] min-w-[280px] pb-[40px] h-full overflow-y-auto': screenWidth >= 970,
        'w-[calc(100%-32px)] mx-4 mt-4 mb-[90px] sm:mb-8 pb-4 rounded-2': screenWidth < 970,
    });

    return (
        <div className={panelClassName}>
            <div className="flex justify-between items-center">
                <p className="font-body text-body-bold-m text-neutral-500">User Feedback on AI</p>
                <ButtonIcon type="link" size="xs" icon={CloseLineIcon} onClick={onClose} />
            </div>
            {feedback?.map(({ id, rating, value, created_at, created_by_user }) => {
                const formattedTime = DateTime.fromISO(created_at).toFormat('LLL dd yyyy @ h:mma');

                return (
                    <div
                        className="pt-4 border-t-1 border-neutral-200 flex flex-col gap-4"
                        key={id}
                    >
                        <div className="flex items-center gap-2">
                            <ButtonIcon
                                type={rating === 1 ? 'secondary' : 'neutral'}
                                size="xs"
                                icon={ThumbsUpIcon}
                                onClick={() => {}}
                            />
                            <ButtonIcon
                                type={rating === -1 ? 'secondary' : 'neutral'}
                                size="xs"
                                icon={ThumbsDownIcon}
                                onClick={() => {}}
                            />
                        </div>
                        <p className="font-body text-body-bold-s text-neutral-500 relative ml-[12px] before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:bg-black before:absolute before:top-[5px] before:left-[-12px]">
                            {formattedTime}
                        </p>
                        {value && (
                            <p className="font-body text-body-regular-s text-neutral-500 whitespace-pre-wrap">
                                {value}
                            </p>
                        )}
                        <p className="w-full font-body text-body-regular-xs text-neutral-300 truncate">
                            {created_by_user}
                        </p>
                    </div>
                );
            })}
            <div className="flex justify-center">
                <Button
                    type="link"
                    size="sm"
                    leadingIcon={DiscussLineIcon}
                    text="Share AI Feedback"
                    onClick={() => setShareThreadFeedbackModal({ opened: true, state: 'empty' })}
                />
            </div>
        </div>
    );
};

export default FeedbackPanel;
