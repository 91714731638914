import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_LIMIT } from '../../../../hooks/useCustomPagination';

import Loading from '../../../../components/Loading';
import EditLineIcon from '../../../../design-system/Icons/EditLineIcon';
import PaginationBar from '../../../../design-system/PaginationBar/PaginationBar';
import SectionWrapper from '../../SectionWrapper/SectionWrapper';
import { Button, ButtonIcon } from '../../../../design-system';

const ClientOrganizationsBlock = ({ organizationId, childOrganizations, paginationProps }) => {
    const navigate = useNavigate();

    const { page, total, setPage } = paginationProps;
    const [currentPage, setCurrentPage] = useState(1);

    const openClientOrganization = (id) => {
        navigate(`/settings/organization/${organizationId}?client=${id}`);
    };

    const organizationsToDisplay = childOrganizations.slice(
        (currentPage - 1) * DEFAULT_LIMIT,
        currentPage * DEFAULT_LIMIT
    );

    const handleNextClick = () => {
        setCurrentPage((currentPage) => {
            const nextPage = currentPage + 1;
            if (nextPage > page) {
                setPage((page) => page + 1);
            }
            return nextPage;
        });
    };

    const handlePreviousClick = () => {
        if (currentPage === 1) return;
        setCurrentPage((page) => page - 1);
    };

    const hasMoreThanOnePage = total > DEFAULT_LIMIT;

    return (
        <SectionWrapper title="Client Organizations">
            <div className="flex flex-col gap-1 mb-4">
                <ul className="flex flex-col gap-1 max-w-full">
                    {!organizationsToDisplay?.length ? (
                        <div>
                            <Loading withText={false} />
                        </div>
                    ) : (
                        organizationsToDisplay.map((org) => {
                            const { id, name } = org;

                            return (
                                <li
                                    key={id}
                                    className="pl-4 pr-2 flex items-center gap-3 md:gap-4 max-w-full h-[56px] overflow-hidden"
                                >
                                    <div className="w-[36px] h-[36px] min-w-[36px] bg-neutral-200 border-1 border-neutral-50 rounded-2 flex items-center justify-center font-body-bold text-body-bold-xl text-white">
                                        {name?.[0]}
                                    </div>
                                    <p className="font-body text-body-regular-m text-neutral-500 flex-1 truncate">
                                        {name}
                                    </p>

                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        icon={EditLineIcon}
                                        onClick={() => openClientOrganization(id)}
                                    />
                                </li>
                            );
                        })
                    )}
                </ul>

                {hasMoreThanOnePage && (
                    <PaginationBar
                        page={currentPage}
                        total={total}
                        handleNextClick={handleNextClick}
                        handlePreviousClick={handlePreviousClick}
                    />
                )}
            </div>

            <div>
                <Button type="primary" size="xs" state="disabled" text="Add Client" />
            </div>
        </SectionWrapper>
    );
};

export default ClientOrganizationsBlock;
