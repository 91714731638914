import React from 'react';
import IntegrationTestSettingsBlock from '../IntegrationTestSettingsBlock/IntegrationTestSettingsBlock';
import ProcessSettingsBlock from '../ProcessSettingsBlock/ProcessSettingsBlock';
import Button from '../../../../design-system/Button/Button';
import { FlashlightFillIcon } from '../../../../design-system/Icons';

const RunIntegrationTestForm = ({
    formData,
    processTemplateData,
    fieldsErrorMessage,
    setFieldsErrorMessage,
    isFormFilled,
    handleStartRun,
    isExpanded,
    setIsExpanded,
    setSectionFormData,
    isProcessSettingsFormBlockOpenable,
}) => {
    const toggleExpanded = (field) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    return (
        <>
            <IntegrationTestSettingsBlock
                isExpanded={isExpanded.integrationTestSettings}
                toggleExpand={() => toggleExpanded('integrationTestSettings')}
                versions={processTemplateData?.versions}
                formData={formData}
                setBlockFormData={(cb) => setSectionFormData('integrationTestSettings', cb)}
                fieldsErrorMessage={fieldsErrorMessage}
                setFieldsErrorMessage={setFieldsErrorMessage}
            />
            <ProcessSettingsBlock
                isExpanded={isExpanded.processSettings}
                toggleExpand={() => toggleExpanded('processSettings')}
                formData={formData}
                setProcessSettingsFormData={(cb) => setSectionFormData('processSettings', cb)}
                isBlockOpenable={isProcessSettingsFormBlockOpenable}
            />
            <Button
                type="primary"
                size="sm"
                text="Start Run"
                leadingIcon={FlashlightFillIcon}
                state={!isFormFilled ? 'disabled' : 'default'}
                onClick={handleStartRun}
            />
        </>
    );
};

export default RunIntegrationTestForm;
