import React, { memo, useState } from 'react';

import * as API from '../../../constants/api';
import client from '../../../services/library-api';

import { SORT_TYPES } from '../../../constants/sort';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import UnconfirmedAgentBlock from './UnconfirmedAgentBlock/UnconfirmedAgentBlock';
import ConfirmedAgentBlock from './ConfirmedAgentBlock/ConfirmedAgentBlock';

const AgentBlock = ({
    blockData,
    editMode = false,
    handleDeleteBlock,
    handleBlockPropertyChange,
    agentDetails,
    setConnectedAgentsData,
}) => {
    const {
        properties: { agent_id, label },
    } = blockData;

    const [isConfirmedMode, setIsConfirmedMode] = useState(!!agent_id);

    const formatResponseToOptions = (results) =>
        results?.map(({ id, name, description, icon_color }) => ({
            id,
            name,
            description,
            leadingIconColor: icon_color,
        }));

    // making any changes to this request, please make the same changes on the following page: PlaybookBuilderPage/PlaybookContent/PlaybookContent.js. There, the request is created so that when the page loads, the request is executed and the cached data is immediately returned here.
    const agentOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.process,
        searchParams: { ordering: SORT_TYPES.alphabetical },
        formatResponseToOptions,
    });

    function setAgentDetails(agentId, cb) {
        setConnectedAgentsData((prevAgents) => ({
            ...prevAgents,
            [agentId]: cb(prevAgents[agentId]),
        }));
    }

    const handleConfirmAgent = (agent, label) => {
        handleBlockPropertyChange('agent_id', agent.id);
        handleBlockPropertyChange('label', label);
        setAgentDetails(agent.id, () => ({ ...agent, icon_color: agent.leadingIconColor }));
        setIsConfirmedMode(true);
    };

    const formattedConfirmedAgentData = agentDetails
        ? { ...agentDetails, leadingIconColor: agentDetails.icon_color }
        : null;

    return (
        <>
            {isConfirmedMode && (
                <ConfirmedAgentBlock
                    isEditable={editMode}
                    agentId={agent_id}
                    label={label}
                    agentDetails={agentDetails}
                    setAgentDetails={setAgentDetails}
                    handleDeleteBlock={handleDeleteBlock}
                    setIsConfirmedMode={setIsConfirmedMode}
                />
            )}

            {!isConfirmedMode && (
                <UnconfirmedAgentBlock
                    isEditable={editMode}
                    initiallySelectedAgentData={formattedConfirmedAgentData}
                    initialLabel={formattedConfirmedAgentData ? label : null}
                    agentOptionsHookResponse={agentOptionsHookResponse}
                    handleDeleteBlock={handleDeleteBlock}
                    handleConfirmAgent={handleConfirmAgent}
                />
            )}
        </>
    );
};

export default memo(AgentBlock);
