import React, { memo, useMemo } from 'react';

import { CHAT_ROLE } from '../../../constants/assistant';
import { checkIfAllRequiredInputsAreFilled } from '../../../helpers/startNewJobFormUtils';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import MessageWrapper from '../MessageWrapper/MessageWrapper';
import NewJobForm from '../../../components/NewJobForm/NewJobForm';
import Loading from '../../../components/Loading';
import { Button } from '../../../design-system';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const StartNewJobForm = ({ agentDetail, formData, setFormData, handleSubmitForm }) => {
    const allRequiredInputsAreFilled = useMemo(
        () => checkIfAllRequiredInputsAreFilled(formData || []),
        [formData]
    );

    const submitButtonState = allRequiredInputsAreFilled ? 'default' : 'disabled';

    const { isMobile } = useResponsiveBreakpoints();

    return (
        <MessageWrapper role={CHAT_ROLE.assistant}>
            <div className="min-h-[21px] flex">
                <p className="font-body text-body-regular-s text-neutral-500 my-auto">
                    Here are the required inputs to start this job
                </p>
            </div>

            {agentDetail && (
                <div className="md:p-5 flex flex-col pb-8" style={{ gap: isMobile ? 16 : 24 }}>
                    <h2 className="font-heading-bold text-heading-bold-m text-neutral-500">
                        Start New Job
                    </h2>

                    <NewJobForm
                        formData={formData}
                        setFormData={setFormData}
                        containerGap={isMobile ? 16 : 24}
                    />

                    <div
                        className="flex justify-end border-t-1 border-neutral-200"
                        style={{ paddingTop: isMobile ? 16 : 24 }}
                    >
                        <Button
                            type="secondary"
                            size="sm"
                            text="Start Job"
                            trailingIcon={FlashlightFillIcon}
                            state={submitButtonState}
                            onClick={handleSubmitForm}
                        />
                    </div>
                </div>
            )}

            {!agentDetail && (
                <div className="flex justify-center my-3">
                    <Loading withText={false} />
                </div>
            )}
        </MessageWrapper>
    );
};

export default memo(StartNewJobForm);
