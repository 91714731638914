import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TASK_TYPES } from '../../../../constants/library';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { Button, ButtonIcon } from '../../../../design-system';
import {
    ErrorWarningLineIcon,
    FlashlightFillIcon,
    More2FillIcon,
} from '../../../../design-system/Icons';
import TaskOptionsPopup from '../TaskOptionsPopup/TaskOptionsPopup';
import Alert from '../../../../design-system/Alert/Alert';
import DuplicateTaskSuccessModal from '../../../../components/DuplicateTaskSuccessModal/DuplicateTaskSuccessModal';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import DeleteTaskConfirmModal from '../../../../components/DeleteTaskConfirmModal/DeleteTaskConfirmModal';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';

const taskTypesWithSaveChangesButton = [
    TASK_TYPES.retrieval,
    TASK_TYPES.assistant,
    TASK_TYPES.inbox,
    TASK_TYPES.api,
    TASK_TYPES.regex,
    TASK_TYPES.code,
    TASK_TYPES.plot,
];

const TaskPlaygroundActionBar = ({
    task,
    taskType,
    requiredInputs,
    userInputs,
    outputs,
    hasEditAccess,
    handleTestTask,
    saveTaskChanges,
    saveChangesLoading,
    changedTaskFields,
    runTaskState,
}) => {
    const { id: taskId } = useParams();

    const [showTaskOptionsPopup, setShowTaskOptionsPopup] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const [duplicateSuccessModal, setDuplicateSuccessModal] = useState({ opened: false });

    const [copyAlert, setCopyAlert] = useState({ show: false });

    const [deleteTaskConfirmModalOpened, setDeleteTaskConfirmModalOpened] = useState(false);

    const isSaveChangesButtonDisplayed = taskTypesWithSaveChangesButton.includes(taskType);
    return (
        <>
            <div className="fixed bottom-[20px] right-[20px] p-2 flex gap-2.5">
                <div className="sm:relative">
                    <ButtonIcon
                        type="secondary"
                        size="sm"
                        icon={More2FillIcon}
                        onClick={() => setShowTaskOptionsPopup(true)}
                    />
                    {showTaskOptionsPopup && (
                        <TaskOptionsPopup
                            task={task}
                            taskId={taskId}
                            taskType={taskType}
                            requiredInputs={requiredInputs}
                            userInputs={userInputs}
                            outputs={outputs}
                            setErrorAlert={setErrorAlert}
                            setDuplicateSuccessModal={setDuplicateSuccessModal}
                            setCopyAlert={setCopyAlert}
                            showDeleteConfirmModal={() => {
                                setDeleteTaskConfirmModalOpened(true);
                                setShowTaskOptionsPopup(false);
                            }}
                            onClose={() => setShowTaskOptionsPopup(false)}
                        />
                    )}
                </div>
                {isSaveChangesButtonDisplayed && (
                    <Button
                        type="secondary"
                        size="sm"
                        text="Save Task"
                        state={
                            saveChangesLoading
                                ? 'loading'
                                : !!changedTaskFields.length
                                ? 'default'
                                : 'disabled'
                        }
                        onClick={saveTaskChanges}
                    />
                )}
                <Button
                    type="primary"
                    size="sm"
                    text="Test Task"
                    leadingIcon={FlashlightFillIcon}
                    state={runTaskState.isLoading ? 'loading' : 'default'}
                    onClick={() => {
                        if (hasEditAccess) {
                            handleTestTask();
                        }
                    }}
                />
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            {duplicateSuccessModal.opened && (
                <DuplicateTaskSuccessModal
                    handleClose={() => setDuplicateSuccessModal({ opened: false })}
                    id={duplicateSuccessModal.id}
                    taskType={duplicateSuccessModal.taskType}
                    taskName={duplicateSuccessModal.taskName}
                />
            )}
            {copyAlert.show && (
                <Alert
                    status={copyAlert.status}
                    message={
                        copyAlert.status === 'positive'
                            ? `Config copied to clipboard!`
                            : defaultErrorMessage
                    }
                    icon={copyAlert.status === 'positive' ? CheckLineIcon : ErrorWarningLineIcon}
                    handleClose={() => setCopyAlert({ show: false })}
                />
            )}
            {deleteTaskConfirmModalOpened && (
                <DeleteTaskConfirmModal
                    id={taskId}
                    taskType={taskType}
                    onClose={() => setDeleteTaskConfirmModalOpened(false)}
                />
            )}
        </>
    );
};

export default TaskPlaygroundActionBar;
