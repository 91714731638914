export const parsePlaybooksIndexSearchParams = (searchParams) => {
    const page = Number(searchParams.get('page'));
    const search = searchParams.get('search');

    const result = {};

    if (page) {
        result.page = page;
    }
    if (search) {
        result.search = search;
    }

    return result;
};
