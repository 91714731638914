export const getRetrievalSettings = (settings) => {
    if (!settings) {
        return {
            limit: 10,
            max_distance: 0.25,
            max_tokens: 1000,
        };
    } else {
        return {
            limit: settings.limit || 10,
            max_distance: settings.max_distance || 0.25,
            max_tokens: settings.max_tokens || 1000,
        };
    }
};
