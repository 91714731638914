import React from 'react';

const PlayProcessIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 12.9503 21.8674 13.8696 21.6198 14.7405C23.048 15.6208 24 17.1992 24 19C24 21.7614 21.7614 24 19 24C17.1992 24 15.6208 23.048 14.7405 21.6198C13.8696 21.8674 12.9503 22 12 22ZM20 12C20 12.7099 19.9075 13.3982 19.734 14.0535C19.4944 14.0183 19.2493 14 19 14C16.2386 14 14 16.2386 14 19C14 19.2493 14.0183 19.4944 14.0535 19.734C13.3982 19.9075 12.7099 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM10.6219 8.41459L15.5008 11.6672C15.6846 11.7897 15.7343 12.0381 15.6117 12.2219C15.5824 12.2658 15.5447 12.3035 15.5008 12.3328L10.6219 15.5854C10.4381 15.708 10.1897 15.6583 10.0672 15.4745C10.0234 15.4088 10 15.3316 10 15.2526V8.74741C10 8.52649 10.1791 8.34741 10.4 8.34741C10.479 8.34741 10.5562 8.37078 10.6219 8.41459ZM21.6 18.2727H19.15V15L16 19.7273H18.45V23L21.6 18.2727Z"
                fill={props.color}
            />
        </svg>
    );
};

export default PlayProcessIcon;
