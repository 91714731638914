import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';

import { CloseLineIcon } from '../../design-system/Icons';
import { useClickOutside } from '../../hooks';
import { Button, ButtonIcon } from '../../design-system';
import InstructionsBlock from './InstructionsBlock/InstructionsBlock';
import EscapeIcon from '../../design-system/Icons/EscapeIcon';

const InstructionsModal = ({
    instructions,
    description = 'Here are the instructions for completing this task.',
    pageContainerRef,
    viewInstructionButtonRef,
    topValue = 20,
    onClose,
}) => {
    const modalRef = useRef(null);
    const { width: screenWidth } = useWindowSize();

    const [width, setWidth] = useState('596px'); // modal width for desktop view
    const [position, setPosition] = useState(null); // modal position for desktop view

    useClickOutside(modalRef, onClose);

    useEffect(() => {
        // get modal position and width for Desktop
        if (pageContainerRef?.current && viewInstructionButtonRef?.current && screenWidth >= 640) {
            const containerWidth = pageContainerRef.current.offsetWidth;
            const { right: containerRightPartCoord } =
                pageContainerRef.current.getBoundingClientRect();
            const newWidth = screenWidth >= 860 ? containerWidth - 70 : containerWidth - 90;
            setWidth(`${Math.min(newWidth, 596)}px`);

            const { right: buttonRightPartCoord } =
                viewInstructionButtonRef.current.getBoundingClientRect();

            const buttonIsOnContainerRightSide =
                containerRightPartCoord - buttonRightPartCoord < 74;
            if (buttonIsOnContainerRightSide) {
                setPosition({ right: '25px', top: `${topValue}px` });
            } else {
                setPosition({ left: '25px', top: `${topValue}px` });
            }
        }
    }, [pageContainerRef, viewInstructionButtonRef, screenWidth]);

    if (!position && screenWidth >= 640) {
        return <></>;
    }

    return (
        <>
            <div className="max-sm:fixed max-sm:top-0 max-sm:left-0 max-sm:right-0 max-sm:bottom-0 max-sm:bg-black/50 sm:hidden z-32"></div>
            <div
                className="fixed max-sm:left-0 max-sm:right-0 bottom-0 sm:bottom-[unset] sm:absolute rounded-2 border-1 border-neutral-200 shadow-l1 px-1 pt-5 pb-1 z-33 bg-white max-h-[calc(100%-80px)] sm:max-h-[70vh] overflow-y-auto"
                style={screenWidth >= 640 ? { ...position, width } : {}}
                ref={modalRef}
            >
                <div className="hidden sm:flex sm:gap-1 sm:items-center sm:absolute top-[4px] right-[4px]">
                    <button onClick={onClose} className="cursor-pointer">
                        <EscapeIcon width={28} height={18} color="#1F2125" />
                    </button>
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                </div>

                <InstructionsBlock instructions={instructions} description={description} />

                <div className="sm:hidden flex flex-col px-5 mb-5">
                    <Button type="neutral" size="sm" text="Close" onClick={onClose} />
                </div>
            </div>
        </>
    );
};

export default InstructionsModal;
