const temperatureOptions = [
    {
        id: 1,
        value: parseFloat(0.0),
        name: 'No Randomness (0.0)',
        description: 'Best for most tasks, avoids hallucinations',
    },
    {
        id: 2,
        value: parseFloat(0.35),
        name: 'Balanced (0.35)',
        description: 'Best to get some variability in results each time',
    },
    {
        id: 3,
        value: parseFloat(0.7),
        name: 'Creative (0.7)',
        description: 'Best for creative use cases like brainstorming',
    },
    {
        id: 4,
        value: parseFloat(1.0),
        name: 'Very Creative (1.0)',
        description: 'Best if you want to get a little bit crazy',
    },
];

export default temperatureOptions;
