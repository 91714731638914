import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    handleNextPageClick,
    handlePreviousPageClick,
} from '../../../../helpers/handleUpdateSearchParams';
import DatasetsTableBlock from '../DatasetsTableBlock/DatasetsTableBlock';
import Loading from '../../../../components/Loading';
import TestRunsTableBlock from '../TestRunsTableBlock/TestRunsTableBlock';

const PagePaginatedContainer = ({ path, data, total, parsedParams, swrKey }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    if (!data) {
        return (
            <div className="flex justify-center pt-4 self-stretch">
                <Loading />
            </div>
        );
    }

    const DataTable = path === 'datasets' ? DatasetsTableBlock : TestRunsTableBlock;

    return (
        <DataTable
            data={data}
            page={parsedParams.page || 1}
            total={total}
            swrKey={swrKey}
            onPreviousPageClick={() =>
                handlePreviousPageClick({
                    searchParams,
                    setSearchParams,
                    currentPage: parsedParams.page,
                })
            }
            onNextPageClick={() =>
                handleNextPageClick({
                    searchParams,
                    setSearchParams,
                    currentPage: parsedParams.page,
                })
            }
        />
    );
};

export default PagePaginatedContainer;
