import React, { useMemo, useState } from 'react';
import { transformEventNotificationDataForTable } from '../../../../helpers/formatEventsDataForTable';

import { Button } from '../../../../design-system';
import Table from '../../../../design-system/Table/Table';
import NotificationEditorModal from '../NotificationEditorModal/NotificationEditorModal';
import ConfirmDeleteNotificationModal from '../ConfirmDeleteNotificationModal/ConfirmDeleteNotificationModal';

const EventNotificationSettingsBlock = ({ process, setProcess }) => {
    const eventTableData = useMemo(
        () => transformEventNotificationDataForTable(process.events),
        [process.events]
    );

    const [notificationEditorModal, setNotificationEditorModal] = useState({
        opened: false,
        mode: null,
        eventData: null,
    });
    const [deleteNotificationModal, setDeleteNotificationModal] = useState({ opened: false });

    const eventRowActionButtons = [
        {
            action: 'edit',
            handleAction: (row) => {
                const eventToEdit = process.events?.find((event) => event.id === row.id);
                if (eventToEdit) {
                    setNotificationEditorModal({
                        opened: true,
                        mode: 'edit',
                        eventData: eventToEdit,
                    });
                }
            },
        },
        {
            action: 'delete',
            handleAction: (row) => {
                setDeleteNotificationModal({
                    opened: true,
                    eventData: row,
                });
            },
        },
    ];

    return (
        <div className="p-5 sm:p-6 rounded-2xl bg-white">
            <div className="flex flex-col gap-4 items-start w-full">
                <div>
                    <p className="font-heading-bold text-heading-bold-s text-black mb-2">
                        Notification Settings
                    </p>
                    <p className="font-body text-body-regular-s text-neutral-300">
                        Set up notifications for process events
                    </p>
                </div>
                <Table
                    data={eventTableData}
                    columnsCount={1}
                    shift={530}
                    rowActionButtons={eventRowActionButtons}
                />
                <Button
                    type="primary"
                    size="xs"
                    text="Add Notification"
                    onClick={() => setNotificationEditorModal({ opened: true, mode: 'create' })}
                />
            </div>
            {notificationEditorModal.opened && (
                <NotificationEditorModal
                    mode={notificationEditorModal.mode}
                    eventData={notificationEditorModal.eventData}
                    process={process}
                    setProcess={setProcess}
                    onClose={() => setNotificationEditorModal({ opened: false })}
                />
            )}
            {deleteNotificationModal.opened && (
                <ConfirmDeleteNotificationModal
                    processId={process.id}
                    setProcess={setProcess}
                    eventData={deleteNotificationModal.eventData}
                    onClose={() => setDeleteNotificationModal({ opened: false })}
                />
            )}
        </div>
    );
};

export default EventNotificationSettingsBlock;
