import React from 'react';
import useUser from '../hooks/useUser';
import SideBar from '../components/SideBar/SideBar';
import { Navigate } from 'react-router-dom';
import { VIEW_TYPES_REDIRECT_PATH } from '../constants/viewTypes';
import { useViewType } from '../hooks/useViewType';
import { checkRouteAccess } from '../helpers/checkRouteAccess';

//The route is for both logged-in and non-logged-in users. For logged-in users, a page with a sidebar will be displayed.
const PublicRoute = ({ component: Component, availableViewTypes }) => {
    const { user, isValidating } = useUser();

    const { viewType } = useViewType();
    const hasViewTypesAccess = checkRouteAccess(viewType, availableViewTypes);

    const isNotLoggedIn = !isValidating && !user;

    return isNotLoggedIn ? (
        Component
    ) : hasViewTypesAccess ? (
        <div className="flex flex-row h-full w-full max-sm:pt-[60px]">
            <div className="sm:w-17 sm:min-w-17">
                <SideBar />
            </div>
            {Component}
        </div>
    ) : (
        <Navigate to={VIEW_TYPES_REDIRECT_PATH[viewType]} />
    );
};

export default PublicRoute;
