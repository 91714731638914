import React, { useRef } from 'react';
import { Button } from '../index';
import PropTypes from 'prop-types';
import { useClickOutside } from '../../hooks';

MultiActionPopup.propTypes = {
    actionOptions: PropTypes.node.isRequired,
    onClose: PropTypes.func,
};

function MultiActionPopup({ actionOptions, onClose }) {
    const listRef = useRef(null);

    useClickOutside(listRef, onClose);

    return (
        <>
            <div className="max-xs:fixed max-xs:top-0 max-xs:left-0 max-xs:right-0 max-xs:bottom-0 max-xs:bg-black/50 xs:hidden z-20"></div>
            <div
                className="px-5 pt-6 pb-8 sm:p-4 flex flex-col gap-1 bg-white fixed xs:absolute bottom-0 xs:bottom-[calc(100%+4px)] max-xs:left-0 right-0 rounded-2 shadow-l1 border-1 border-neutral-200 w-full xs:w-[297px] z-30"
                ref={listRef}
            >
                <div className="max-xs:mb-8">{actionOptions}</div>

                <div className="xs:hidden flex flex-col">
                    <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                </div>
            </div>
        </>
    );
}

export default MultiActionPopup;
