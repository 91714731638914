import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import { SORT_TYPES } from '../../../constants/sort';
import {
    handleUpdateFoldersState,
    toggleSidePanelFolderExpand,
} from '../../../helpers/docsSidePanelUtils';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';

import Loading from '../../../components/Loading';
import FolderFillIcon from '../../../design-system/Icons/FolderFillIcon';
import ArrowRightSLineIcon from '../../../design-system/Icons/ArrowRightSLineIcon';
import { SvgIcon } from '../../../design-system';
import { ArrowDownSLineIcon } from '../../../design-system/Icons';

const FolderNavItem = ({ folder, currentFolderExpandedState, isTopLevel, prevFoldersLocation }) => {
    const { id, label, folders, is_parent } = folder;
    const navigate = useNavigate();

    const { folderId: folderIdFromParams } = useParams();

    const isExpanded = !!currentFolderExpandedState;
    const Icon = isExpanded ? ArrowDownSLineIcon : ArrowRightSLineIcon;

    const { setExpandedFolders, setLocation, setSidePanelFolders } = useDocsPageContext();
    const toggleFolderExpand = (location) => {
        toggleSidePanelFolderExpand({ location, setExpandedFolders });
    };

    const folderIconColor = isTopLevel ? '#754DCF' : '#C6B8E7';

    const areSubfoldersDisplayed = isExpanded && folders?.length > 0;

    const fetchNextLevelFolders = async () => {
        try {
            const currentFolderLocation = [...prevFoldersLocation?.map(({ id }) => id), id];

            for (const subFolder of folder.folders) {
                if (subFolder.folders) {
                    continue;
                }

                client
                    .get(API.ROUTES.knowledge.folder, {
                        params: {
                            parent: subFolder.id,
                            pagination: false,
                            ordering: SORT_TYPES.alphabeticalByLabel,
                        },
                    })
                    .then(({ data }) => {
                        handleUpdateFoldersState({
                            setFolders: setSidePanelFolders,
                            location: [...(currentFolderLocation ?? []), subFolder.id],
                            cb: (folder) => ({ ...folder, folders: data }),
                        });
                    })
                    .catch((e) => {});
            }
        } catch (e) {}
    };

    useEffect(() => {
        if (isExpanded) {
            fetchNextLevelFolders();
        }
    }, [isExpanded]);

    const handleToggleButtonClick = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        const locationIdsArray = [...prevFoldersLocation.map((loc) => loc.id), id];
        toggleFolderExpand(locationIdsArray);
    };

    const currentFolderLocation = [...prevFoldersLocation, { id, label }];

    const handleFolderClick = (e) => {
        if (!isExpanded) {
            handleToggleButtonClick(e);
        }
        setLocation(currentFolderLocation);
        navigate(`/docs/folder/${id}`);
    };

    const isHighlighted = folderIdFromParams === id;

    const buttonClassName = classNames(
        'p-2 w-full flex items-center rounded-2 hover:bg-neutral-50 transition-colors focus:outline-none',
        { 'bg-white': !isHighlighted, 'bg-neutral-50': isHighlighted }
    );

    return (
        <li className="max-w-full flex flex-col gap-1">
            <button onClick={handleFolderClick} className={buttonClassName}>
                <div className="block" onClick={handleToggleButtonClick}>
                    <SvgIcon icon={Icon} size="medium" color="#000000" />
                </div>
                <FolderFillIcon width={20} height={20} color={folderIconColor} />
                <p className="flex-1 pl-2 truncate font-body text-body-regular-s text-neutral-500 text-start">
                    {label}
                </p>
            </button>

            <div className={!areSubfoldersDisplayed ? 'hidden' : 'block'}>
                <ul className="flex flex-col gap-1 max-w-full pl-4">
                    {folders?.map((subFolder) => {
                        const subFolderExpandedState = currentFolderExpandedState?.[subFolder.id];

                        return (
                            <FolderNavItem
                                key={subFolder.id}
                                folder={subFolder}
                                currentFolderExpandedState={subFolderExpandedState}
                                prevFoldersLocation={currentFolderLocation}
                            />
                        );
                    })}
                </ul>
            </div>

            {isExpanded && !folders && is_parent && (
                <div>
                    <Loading withText={false} size="small" />
                </div>
            )}
        </li>
    );
};

export default FolderNavItem;
