import React from 'react';
import classNames from 'classnames';
import { SvgIcon } from '../../../design-system';
import { CheckboxCircleLineIcon, CloseCircleLineIcon } from '../../../design-system/Icons';

const SettingsStepCard = ({
    step,
    isCurrentStep = false,
    isStepDisabled = false,
    state = 'completed', // state  "completed" or "error"
    setCurrentStepOrder,
    closeMobileStepsPanel,
}) => {
    const { label, title } = step || {};
    const cardContainerClassName = classNames(
        'px-4 py-5 rounded-2 shadow-inset-2 transition-shadow',
        {
            'bg-purple-100 shadow-purple-500': isCurrentStep,
            'bg-neutral-50 shadow-transparent': !isCurrentStep,
            'cursor-pointer': !isStepDisabled,
        }
    );

    const changeCurrentStep = () => {
        if (!isStepDisabled) {
            setCurrentStepOrder(step.order);
            closeMobileStepsPanel();
        }
    };

    return (
        <div className={cardContainerClassName} onClick={changeCurrentStep}>
            <div className="mb-2 flex items-center justify-between gap-3 min-h-[24px]">
                <p className="font-body-bold text-body-bold-m text-black">{label}</p>
                {state === 'completed' && (
                    <SvgIcon color="#A1C70C" icon={CheckboxCircleLineIcon} size="large" />
                )}
                {state === 'error' && (
                    <SvgIcon color="#E95B69" icon={CloseCircleLineIcon} size="large" />
                )}
            </div>
            <p className="font-body text-body-regular-xs text-neutral-500">{title}</p>
        </div>
    );
};

export default SettingsStepCard;
