import React, { useRef, useState } from 'react';

import { useFormState } from '../../../../hooks/useFormState';
import { applyOpacityToHex } from '../../../../helpers/applyOpacityToHex';
import { emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import SelectButton from '../../../SelectButton/SelectButton';
import SelectAgentDropdown from '../../../../pages/PlaybookBuilderPage/SelectAgentDropdown/SelectAgentDropdown';
import GenericBlockEmptyStateWrapper from '../../GenericBlockEmptyStateWrapper/GenericBlockEmptyStateWrapper';
import { Input } from '../../../index';
import { FlashlightFillIcon } from '../../../Icons';

const UnconfirmedAgentBlock = ({
    isEditable,
    initiallySelectedAgentData,
    initialLabel,
    agentOptionsHookResponse,
    handleDeleteBlock,
    handleConfirmAgent,
}) => {
    const { formData, fieldErrorMessages, handleInputChange, setFieldErrorMessages } = useFormState(
        { selectedAgent: initiallySelectedAgentData, label: initialLabel }
    );

    const isInitialLabelAdded = useRef(!!initialLabel);

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const onConfirmAgentClick = () => {
        if (!formData.selectedAgent?.id || !formData.label) {
            setFieldErrorMessages({
                selectedAgent: !formData.selectedAgent?.id ? 'Please select an agent.' : null,
                label: !formData.label ? emptyFieldErrorMessage : null,
            });
            return;
        }

        handleConfirmAgent(formData.selectedAgent, formData.label);
    };

    const handleAgentSelect = (agent) => {
        handleInputChange('selectedAgent', agent);

        if (!isInitialLabelAdded.current && agent) {
            handleInputChange('label', agent.name);
            isInitialLabelAdded.current = true;
        }
    };

    const toggleDropdownOpened = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsDropdownOpened((prevState) => !prevState);
    };

    const formattedSelectedAgent = formData.selectedAgent
        ? {
              ...formData.selectedAgent,
              leadingIcon: FlashlightFillIcon,
          }
        : null;

    const selectState = !isEditable
        ? 'disabled'
        : fieldErrorMessages.selectedAgent
        ? 'error'
        : 'default';
    const inputState = !isEditable ? 'disabled' : fieldErrorMessages.label ? 'error' : 'default';

    const badgeColor = applyOpacityToHex(formData.selectedAgent?.leadingIconColor, 20);

    const isLabelInputDisplayed = formData.selectedAgent || isInitialLabelAdded.current;

    const labelBlock = (
        <>
            {isLabelInputDisplayed && (
                <Input
                    size="xs"
                    name="agent_label"
                    value={formData.label}
                    label="Label"
                    isRequired
                    placeholder="Enter a label"
                    state={inputState}
                    errorMessage={fieldErrorMessages.label}
                    onChange={(e) => handleInputChange('label', e.target.value)}
                />
            )}
        </>
    );

    return (
        <GenericBlockEmptyStateWrapper
            isEditable={isEditable}
            isError={!!fieldErrorMessages.selectedAgent}
            handleDeleteBlock={handleDeleteBlock}
            handleSubmit={onConfirmAgentClick}
            bottomContent={labelBlock}
        >
            <SelectButton
                size="xs"
                state={selectState}
                selectedItem={formattedSelectedAgent}
                label="Agent"
                placeholder="Select an agent"
                errorMessage={fieldErrorMessages.selectedAgent}
                isDropdownOpened={isDropdownOpened}
                toggleDropdownOpened={toggleDropdownOpened}
                showAsBadge
                badgeColor={badgeColor}
            />

            {isDropdownOpened && (
                <SelectAgentDropdown
                    agentOptionsHookResponse={agentOptionsHookResponse}
                    selectedAgentId={formData.selectedAgent?.id}
                    handleAgentSelect={handleAgentSelect}
                    onClose={toggleDropdownOpened}
                />
            )}
        </GenericBlockEmptyStateWrapper>
    );
};

export default UnconfirmedAgentBlock;
