import React, { useState } from 'react';

import { ButtonIcon, SvgIcon } from '../../../index';
import { DeleteBin4LineIcon, TerminalBoxLineIcon } from '../../../Icons';
import EditLineIcon from '../../../Icons/EditLineIcon';
import CreatedPromptDefaultView from '../CreatedPromptDefaultView/CreatedPromptDefaultView';
import CreatedPromptEditingView from '../CreatedPromptEditingView/CreatedPromptEditingView';
import Loading from '../../../../components/Loading';

const CreatedPromptBlock = ({ promptData, isDisabled, setConfirmDeleteModal }) => {
    const [isEditing, setIsEditing] = useState(false);

    const { data, mutate } = promptData;

    const isPromptLoaded = !!data;

    return (
        <div className="flex flex-col max-w-full rounded-2 border-1 border-neutral-200">
            <div className="px-5 py-2.5 flex items-center justify-between gap-3 bg-purple-100 rounded-t-2">
                <div className="flex items-center gap-1">
                    <SvgIcon color="#754DCF" icon={TerminalBoxLineIcon} size="large" />
                    <p className="font-body-bold text-body-bold-s text-neutral-400 uppercase">
                        Prompt
                    </p>
                </div>

                {!isDisabled && isPromptLoaded && (
                    <div className="flex items-center gap-2">
                        {!isEditing && (
                            <ButtonIcon
                                type="neutral"
                                size="xs"
                                icon={EditLineIcon}
                                onClick={() => setIsEditing(true)}
                            />
                        )}
                        <ButtonIcon
                            type="neutral"
                            size="xs"
                            icon={DeleteBin4LineIcon}
                            onClick={() => setConfirmDeleteModal({ name: data?.name })}
                        />
                    </div>
                )}
            </div>

            {isPromptLoaded && (
                <>
                    <div className={isEditing ? 'hidden' : 'block'}>
                        <CreatedPromptDefaultView promptData={data} />
                    </div>

                    {isEditing && (
                        <CreatedPromptEditingView
                            mutate={mutate}
                            promptData={data}
                            isDisabled={isDisabled}
                            setIsEditing={setIsEditing}
                        />
                    )}
                </>
            )}

            {!isPromptLoaded && (
                <div className="py-3">
                    <Loading withText={false} size="small" />
                </div>
            )}
        </div>
    );
};

export default CreatedPromptBlock;
