export const changeItemsOrderUsingDragAndDrop = (dragOrder, hoverOrder, setData) => {
    if (dragOrder === hoverOrder) return;

    if (hoverOrder < dragOrder) {
        setData((prevData) =>
            [...prevData]
                .map((item) => {
                    if (item.order >= hoverOrder && item.order < dragOrder) {
                        return { ...item, order: item.order + 1 };
                    } else if (item.order === dragOrder) {
                        return { ...item, order: hoverOrder };
                    } else return item;
                })
                .sort((a, b) => a.order - b.order)
        );
    }

    if (dragOrder < hoverOrder) {
        setData((prevData) =>
            [...prevData]
                .map((item) => {
                    if (item.order > dragOrder && item.order <= hoverOrder) {
                        return { ...item, order: item.order - 1 };
                    } else if (item.order === dragOrder) {
                        return { ...item, order: hoverOrder };
                    } else {
                        return item;
                    }
                })
                .sort((a, b) => a.order - b.order)
        );
    }
};
