import React from 'react';

import AddNewMultiActionButton from '../AddNewMultiActionButton/AddNewMultiActionButton';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

const DocsPageHeader = () => {
    const { isDesktop, isTablet, isMobile } = useResponsiveBreakpoints();

    if (isDesktop) {
        return (
            <div className="flex flex-col gap-5">
                <div>
                    <h1 className="font-heading-bold text-heading-bold-l text-black">Docs</h1>
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        Create and manage reusable docs for context to use globally.
                    </p>
                </div>

                <AddNewMultiActionButton />
            </div>
        );
    }

    if (isTablet) {
        return <h2 className="font-heading-bold text-heading-bold-l text-black">Docs</h2>;
    }

    if (isMobile) {
        return (
            <>
                <h2 className="font-heading-bold text-heading-bold-m text-black">Docs</h2>
                <div className="fixed bottom-0 left-0 sm:left-[68px] right-0 p-4 bg-white border-t-1 border-neutral-200 z-50 h-[73px]">
                    <AddNewMultiActionButton />
                </div>
            </>
        );
    }
};

export default DocsPageHeader;
