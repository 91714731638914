import React, { useEffect, useRef, useState } from 'react';
import EditableTextArea from 'components/EditableTextArea/EditableTextArea';
import client from '../../services/library-api';
import { API } from 'constants';
import {
    handlePromptLocalStorageSave,
    handleTaskLocalStorageSave,
} from '../../helpers/handleRecentVisitedPagesLocalStorageSave';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import { Badge, SvgIcon } from '../../design-system';
import BookmarkFillIcon from '../../design-system/Icons/BookmarkFillIcon';
import { AddLineIcon, ErrorWarningLineIcon } from '../../design-system/Icons';
import CollectionSelector from '../../pages/LibraryPage/CollectionSelector/CollectionSelector';
import Alert from '../../design-system/Alert/Alert';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setSelectorPositionInPromptViewPageHeader } from '../../helpers/setCollectionSelectorPosition';
import useUser from '../../hooks/useUser';
import { defaultErrorMessage } from '../../constants/errorMessages';

const PromptViewPageHeader = ({
    taskId,
    headerText,
    emojiCode,
    subHeaderText,
    setPrompt,
    setTask,
    editable = true,
    collectionsEditable = false,
    currentTaskCollectionsIds = [],
    allCollections = [],
    setAllCollections,
    areCollectionsLoading = false,
    leftContainerRef,
}) => {
    const { user } = useUser();
    const organizationId = user?.organization?.id;

    const [isCollectionSelectorOpened, setIsCollectionSelectorOpened] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        status: null,
        message: null,
        icon: null,
        statusCode: null,
    });
    const [currentTaskCollections, setCurrentTaskCollections] = useState([]);

    const collectionButtonRef = useRef(null);
    const [collectionSelectorPosition, setCollectionSelectorPosition] = useState({
        top: '30px',
        left: 0,
    });
    const [collectionSelectorWidth, setCollectionSelectorWidth] = useState(360);
    const [collectionSelectorMaxHeight, setCollectionSelectorMaxHeight] = useState(350);
    const { width: screenWidth } = useWindowSize();

    useEffect(() => {
        const collectionsObjects = allCollections.reduce(
            (acc, item) => (currentTaskCollectionsIds.includes(item.id) ? [...acc, item] : acc),
            []
        );
        setCurrentTaskCollections(collectionsObjects);
    }, [currentTaskCollectionsIds, allCollections]);

    const handleTaskSave = async (fieldName, fieldValue) => {
        try {
            const data = {
                [fieldName]: fieldValue,
            };
            const response = await client.patch(`${API.ROUTES.library.task}${taskId}/`, data);
            if (fieldName === 'name') {
                if (setPrompt) {
                    // if taskType === "prompt" update data
                    setPrompt((prevPrompt) => ({
                        ...prevPrompt,
                        task: { ...prevPrompt.task, name: fieldValue },
                    }));
                }
                if (setTask) {
                    // if taskType is "task" (non-prompt) update data
                    setTask((prevTask) => ({
                        ...prevTask,
                        name: fieldValue,
                    }));
                }
                const taskType = response.data.task_type;
                if (taskType === 'prompt') {
                    handlePromptLocalStorageSave(response.data, organizationId);
                } else {
                    handleTaskLocalStorageSave(response.data, organizationId);
                }
            }
        } catch (e) {
            console.log('error', e);
            return true;
        }
    };

    const showCollectionSelector = () => {
        setIsCollectionSelectorOpened(true);
        setSelectorPositionInPromptViewPageHeader({
            setCollectionSelectorWidth,
            setCollectionSelectorPosition,
            setCollectionSelectorMaxHeight,
            collectionButtonRef,
            leftContainerRef,
            screenWidth,
        });
    };

    return (
        <div className="flex flex-col space-y-5 justify-start">
            <div className="flex flex-col space-y-4 grow">
                {editable ? (
                    <>
                        <EditableTextArea
                            initialText={headerText}
                            textStyle="font-body-bold text-body-bold-xl"
                            errorMessage="Oops! Something went wrong while editing Task Name. Please try again."
                            onSave={async (value) => {
                                const isError = await handleTaskSave('name', value);
                                if (isError) {
                                    return true;
                                }
                            }}
                            emojiCode={emojiCode || '2699-fe0f'}
                            onEmojiUpdated={(emojiCode) => {
                                handleTaskSave('icon_text', emojiCode);
                            }}
                        />
                        <EditableTextArea
                            initialText={subHeaderText}
                            textStyle="font-body text-body-regular-m"
                            errorMessage="Oops! Something went wrong while editing Task Description. Please try again."
                            onSave={async (value) => {
                                const isError = await handleTaskSave('description', value);
                                if (isError) {
                                    return true;
                                }
                            }}
                        />
                    </>
                ) : (
                    <>
                        <div className="flex items-center gap-2">
                            <div className="w-[36px] h-[36px] min-w-[36px] min-h-[36px] rounded-2 flex items-center justify-center bg-neutral-100">
                                <Emoji
                                    unified={emojiCode || '2699-fe0f'}
                                    emojiStyle={EmojiStyle.APPLE}
                                    size={22}
                                />
                            </div>
                            <p className="font-body-bold text-body-bold-xl text-black">
                                {headerText}
                            </p>
                        </div>
                        <p className="font-body text-body-regular-m text-neutral-500">
                            {subHeaderText}
                        </p>
                    </>
                )}
                <div
                    className={`flex items-center flex-wrap gap-1 ${
                        screenWidth <= 460 && 'relative'
                    }`}
                >
                    {!!currentTaskCollections.length &&
                        !areCollectionsLoading &&
                        currentTaskCollections.map((item) => (
                            <Badge
                                key={item.id}
                                text={item.name}
                                color="neutral"
                                leadingIcon={BookmarkFillIcon}
                                leadingIconColor={item.icon_color}
                            />
                        ))}
                    {collectionsEditable && (
                        <div className={`${screenWidth > 460 && 'relative'}`}>
                            <button
                                className="flex items-center gap-2 font-body text-body-regular-xs h-[24px] px-2 bg-neutral-100 rounded-2"
                                ref={collectionButtonRef}
                                onClick={showCollectionSelector}
                            >
                                <SvgIcon color="#1F2125" icon={AddLineIcon} size="medium" />
                                Collection
                            </button>

                            {isCollectionSelectorOpened && (
                                <CollectionSelector
                                    taskId={taskId}
                                    currentTaskCollections={currentTaskCollections}
                                    allCollections={allCollections}
                                    setAllCollections={setAllCollections}
                                    updateCollectionsState={setCurrentTaskCollections}
                                    onClose={(e) => {
                                        e.stopPropagation();
                                        setIsCollectionSelectorOpened(false);
                                    }}
                                    setAlert={setAlert}
                                    position={collectionSelectorPosition}
                                    containerWidth={collectionSelectorWidth}
                                    containerMaxHeight={collectionSelectorMaxHeight}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {alert.show && (
                <Alert
                    status={alert.status || 'critical'}
                    statusCode={alert.statusCode}
                    message={alert.message || defaultErrorMessage}
                    icon={alert.icon || ErrorWarningLineIcon}
                    handleClose={() => setAlert({ show: false })}
                />
            )}
        </div>
    );
};

export default PromptViewPageHeader;
