import React from 'react';

import { THREADS_TAB } from '../../../constants/threads';

import Loading from '../../../components/Loading';
import PaginatedContainer from '../../../components/PaginatedContainer/PaginatedContainer';
import ThreadCard from '../ThreadCard/ThreadCard';
import ExecutionCard from '../ExecutionCard/ExecutionCard';

const DesktopPaginatedContainer = ({
    activeTab,
    data,
    page,
    total,
    count,
    loading,
    requestKeysToMutate,
}) => {
    return (
        <PaginatedContainer page={page} count={count} total={total}>
            {!!data?.length && (
                <div className="w-full flex flex-col gap-2">
                    {activeTab === THREADS_TAB.threads &&
                        data.map((thread) => (
                            <ThreadCard
                                key={thread.id}
                                thread={thread}
                                requestKeysToMutate={requestKeysToMutate[THREADS_TAB.threads] || []}
                            />
                        ))}

                    {activeTab === THREADS_TAB.executions &&
                        data.map((item) => <ExecutionCard key={item.id} data={item} />)}
                </div>
            )}
            {!total && loading && (
                <div className="pt-5">
                    <Loading />
                </div>
            )}
        </PaginatedContainer>
    );
};

export default DesktopPaginatedContainer;
