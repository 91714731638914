import { TASK_TYPES } from './library';
import { emptyFieldErrorMessage } from './errorMessages';

export const TASK_TYPES_WITH_MISSED_INPUTS_WARNING = [
    TASK_TYPES.assistant,
    TASK_TYPES.function,
    TASK_TYPES.code,
    TASK_TYPES.plot,
    TASK_TYPES.api,
];

// Constants for Create New Input modal
export const INBOX_ACTION_TYPES_OPTIONS = [
    { id: 'body', name: 'Body' },
    { id: 'freeform', name: 'Freeform' },
    { id: 'single-select', name: 'Single Select' },
    { id: 'multi-select', name: 'Multi Select' },
];

export const WEBHOOK_TYPES_OPTIONS = [
    { id: 'string', name: 'String' },
    { id: 'array', name: 'Array' },
    { id: 'object', name: 'Object' },
    { id: 'number', name: 'Number' },
];

export const API_TYPES_OPTIONS = [
    { id: 'string', name: 'String' },
    { id: 'array', name: 'Array' },
    { id: 'object', name: 'Object' },
    { id: 'number', name: 'Number' },
    { id: 'boolean', name: 'Boolean' },
    { id: 'integer', name: 'Integer' },
];

export const GENERAL_TYPES_OPTIONS = [
    { id: 'string', name: 'String' },
    { id: 'integer', name: 'Integer' },
    { id: 'boolean', name: 'Boolean' },
    { id: 'array', name: 'Array' },
    { id: 'object', name: 'Object' },
];

export const TYPES_OPTIONS = {
    [TASK_TYPES.webhook]: WEBHOOK_TYPES_OPTIONS,
    [TASK_TYPES.api]: API_TYPES_OPTIONS,
    [TASK_TYPES.code]: GENERAL_TYPES_OPTIONS,
    [TASK_TYPES.plot]: GENERAL_TYPES_OPTIONS,
};

export const TASK_TYPES_WITH_KEY_INPUT = [TASK_TYPES.code, TASK_TYPES.plot];
export const TASK_TYPES_WITH_TYPE_INPUT = [
    TASK_TYPES.webhook,
    TASK_TYPES.api,
    TASK_TYPES.code,
    TASK_TYPES.plot,
];
export const TASK_TYPES_WITH_ITEMS_INPUT = [TASK_TYPES.code, TASK_TYPES.plot];
export const TASK_TYPES_WITH_REQUIRED_INPUT = [TASK_TYPES.code, TASK_TYPES.plot];

export const EMPTY_FIELDS_ERROR_MESSAGE = {
    label: emptyFieldErrorMessage,
    actionType: 'Please select an Action Type.',
    options: 'Please add an Option.',
    type: 'Please select a Type.',
    items: 'Please select an Items Type.',
    key: 'Please enter a Key.',
};
