import { mutate } from 'swr';

export const mutateThreadsDataAfterRenamingThread = (
    requestKeysToMutate,
    renamedThreadId,
    newThreadName
) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            mutate(
                key,
                (currentData) => {
                    if (!currentData) {
                        return;
                    }
                    const updatedResults = currentData.results?.map((thread) =>
                        thread.id !== renamedThreadId ? thread : { ...thread, name: newThreadName }
                    );

                    return { ...currentData, results: updatedResults };
                },
                { revalidate: false }
            );
        });
    }
};

export const mutateThreadsDataAfterChangingThreadStatus = (
    requestKeysToMutate,
    threadId,
    status
) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            mutate(
                key,
                (currentData) => {
                    if (!currentData) {
                        return;
                    }
                    const updatedResults = currentData.results?.map((thread) =>
                        thread.id !== threadId ? thread : { ...thread, status }
                    );

                    return { ...currentData, results: updatedResults };
                },
                { revalidate: false }
            );
        });
    }
};
