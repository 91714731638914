import React, { useState } from 'react';
import { ButtonIcon, SvgIcon } from '../../../design-system';
import DiscussLineIcon from '../../../design-system/Icons/DiscussLineIcon';
import { More2FillIcon } from '../../../design-system/Icons';
import ThreadMoreOptionsDropdown from '../ThreadMoreOptionsDropdown/ThreadMoreOptionsDropdown';

const ThreadDetailPageActionButtons = ({
    threadStatus,
    feedbackCount,
    setFeedbackPanelOpened,
    setRestartSidePanel,
    setThreadActionsModal,
}) => {
    const [moreOptionsDropdownOpen, setMoreOptionsDropdownOpen] = useState(false);

    const toggleFeedbackPanelOpened = () => {
        setFeedbackPanelOpened((prevState) => !prevState);
    };

    return (
        <div className="flex items-center gap-2">
            {feedbackCount > 0 && (
                <button
                    className="flex items-center justify-center w-[24px] min-w-[24px] h-[24px] relative"
                    onClick={toggleFeedbackPanelOpened}
                >
                    <SvgIcon color="#1F2125" icon={DiscussLineIcon} size="medium" />
                    <span className="absolute top-[1px] right-[-3px] min-w-[10px] w-fit h-[10px] p-0.5 rounded-full bg-red-500 text-[8px] text-white font-medium font-body inline-flex justify-center items-center">
                        {feedbackCount}
                    </span>
                </button>
            )}

            <div className="relative">
                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={More2FillIcon}
                    onClick={() => setMoreOptionsDropdownOpen(true)}
                />
                {moreOptionsDropdownOpen && (
                    <ThreadMoreOptionsDropdown
                        threadStatus={threadStatus}
                        setRestartSidePanel={setRestartSidePanel}
                        setFeedbackPanelOpened={setFeedbackPanelOpened}
                        {...setThreadActionsModal}
                        onClose={() => setMoreOptionsDropdownOpen(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default ThreadDetailPageActionButtons;
