import React from 'react';
import { useNavigate } from 'react-router-dom';
import { handleContactUs } from '../../../helpers/handleContactUs';

import Modal from '../../../design-system/Modal/Modal';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import RecommendedConnectionsBlock from './RecommendedConnectionsBlock/RecommendedConnectionsBlock';
import CreditsCountBadge from '../../../components/CreditsCountBadge/CreditsCountBadge';
import { Button, ButtonIcon } from '../../../design-system';
import { CloseLineIcon, FlashlightFillIcon } from '../../../design-system/Icons';

const ProcessTemplateDetailsModal = ({
    onClose,
    processTemplateData,
    withContactUsButton = false,
}) => {
    const {
        id,
        name,
        description,
        instructions,
        details: { connected_providers, connected_processes, cost_per_run },
        default_version,
    } = processTemplateData;

    const navigate = useNavigate();

    const handleSetupAndRun = () => {
        navigate(`/discover/agent/${id}/setup/${default_version.id}`);
    };

    return (
        <Modal onClose={onClose} size="large">
            <div className="absolute top-[18px] right-[18px]">
                <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
            </div>
            <div className="sm:p-2 flex flex-col gap-5">
                <div className="pb-5 border-b-1 border-neutral-200">
                    <p className="font-heading-bold text-heading-bold-m text-black mb-2 pr-7">
                        {name}
                    </p>
                    <p className="font-body text-body-regular-s text-neutral-400">{description}</p>
                </div>

                <div className="flex flex-col ml:flex-row ml:justify-between gap-8">
                    {instructions && (
                        <div className="ml:w-[calc(100%-321px)] ml:min-w-[calc(100%-321px)]">
                            <RichTextArea
                                value={instructions}
                                previewOnly
                                autoExpand
                                isBorderHidden
                                minHeight={32}
                            />
                        </div>
                    )}

                    <RecommendedConnectionsBlock
                        connectedProcesses={connected_processes}
                        connectedProviders={connected_providers}
                    />
                </div>

                <div className="py-2 pr-2 pl-5 rounded-2 bg-purple-100 flex justify-between items-center gap-2 sm:gap-4">
                    <CreditsCountBadge cost={cost_per_run} label="run" />
                    {withContactUsButton ? (
                        <Button
                            type="primary"
                            size="md"
                            text="Contact Us"
                            state="default"
                            onClick={handleContactUs}
                        />
                    ) : (
                        <Button
                            type="primary"
                            size="md"
                            text="Setup & Run"
                            trailingIcon={FlashlightFillIcon}
                            state="disabled"
                            onClick={handleSetupAndRun}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ProcessTemplateDetailsModal;
