import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { handleCopy } from '../../../helpers/handleCopy';

import { Button } from '../../../design-system';
import Modal from '../../../design-system/Modal/Modal';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import CopyIcon from '../../../design-system/Icons/CopyIcon';
import HistoryLineIcon from '../../../design-system/Icons/HistoryLineIcon';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import Alert from '../../../design-system/Alert/Alert';

const CompiledPromptModal = ({ promptId, resultId, resultData, onClose }) => {
    const navigate = useNavigate();
    const compiledPromptValue = resultData?.request?.messages?.[0]?.content || '';

    const [alert, setAlert] = useState(null);

    const { height: screenHeight, width: screenWidth } = useWindowSize();

    const padding = screenWidth < 640 ? 20 : 24;
    const gap = screenWidth < 640 ? 20 : 24;

    const compiledPromptMaxHeight = screenHeight * 0.85 - 80 - 2 * padding - 2 * gap;

    return (
        <Modal onClose={onClose} size="large" resetPadding>
            <div className="flex flex-col max-h-[85vh]" style={{ padding, gap }}>
                <ModalHeader onClose={onClose} title="Compiled Prompt" />

                <RichTextArea
                    value={compiledPromptValue}
                    previewOnly
                    autoExpand
                    customPadding="p-2.5"
                    previewMaxHeight={compiledPromptMaxHeight}
                />

                <div className="flex items-center justify-end gap-3">
                    <Button
                        type="neutral"
                        size="xs"
                        text="Copy"
                        leadingIcon={CopyIcon}
                        onClick={() => handleCopy(compiledPromptValue, setAlert)}
                    />
                    <Button
                        type="primary"
                        size="xs"
                        text="View Run"
                        leadingIcon={HistoryLineIcon}
                        onClick={() => navigate(`/library/prompt/${promptId}/run/${resultId}`)}
                    />
                </div>
            </div>

            {alert && (
                <Alert
                    status={alert.status}
                    message={alert.message}
                    icon={alert.icon}
                    autoCloseInMS={2500}
                    handleClose={() => setAlert(null)}
                />
            )}
        </Modal>
    );
};

export default CompiledPromptModal;
