import React from 'react';
import PropTypes from 'prop-types';
import { handleContactUs } from '../../helpers/handleContactUs';
import { Button } from '../index';

ContactUsBanner.propTypes = {
    mainText: PropTypes.string.isRequired,
    subText: PropTypes.string,
};

function ContactUsBanner({ mainText, subText = 'Get in touch to discuss your business needs.' }) {
    return (
        <div className="w-full max-w-[360px] lg:max-w-[400px] rounded-2 bg-purple-500 p-4 flex flex-col gap-4">
            <div>
                <h2 className="font-body-bold text-body-bold-m text-neutral-50 mb-1">{mainText}</h2>
                <p className="font-body text-body-regular-xs text-neutral-50">{subText}</p>
            </div>

            <Button type="primary" size="xs" text="Contact Us" onClick={handleContactUs} />
        </div>
    );
}

export default ContactUsBanner;
