import React from 'react';

import { TASK_TYPES } from '../../../../constants/library';
import { Badge, ButtonIcon } from '../../../../design-system';
import { AddLineIcon } from '../../../../design-system/Icons';
import PaginatedSelect from '../../../../design-system/PaginatedSelect/PaginatedSelect';

const GoalsMultiSelect = ({
    taskType,
    selectedGoal,
    options,
    handleSelectChange,
    handleAddGoalClick,
    isError,
    optionsLoading,
    setPage,
    canLoadMoreOptions,
    totalOptions,
    addedGoalsBadgeInfo,
    handleRemoveGoalBadge,
}) => {
    const selectPlaceholder = {
        [TASK_TYPES.assistant]: 'Select a goal and click + to add it as a router option',
        [TASK_TYPES.agent]: 'Select a goal and click + to add it as a tool option',
    };

    return (
        <div className="flex flex-col gap-4">
            <div className={`flex gap-2 ${isError ? 'items-center' : 'items-end'}`}>
                <PaginatedSelect
                    size="sm"
                    name="selectedGoal"
                    options={options}
                    optionsLoading={optionsLoading}
                    value={selectedGoal}
                    label="Goal Options"
                    isRequired
                    placeholder={selectPlaceholder[taskType]}
                    onChange={(newValue) => handleSelectChange(newValue)}
                    state={isError ? 'error' : 'default'}
                    errorMessage={'Please select a goal.'}
                    fetchOptions={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMoreOptions}
                    includeClientSideFiltering
                    totalOptionsCount={totalOptions}
                />
                <ButtonIcon
                    type="ghost"
                    size="md"
                    icon={AddLineIcon}
                    state={selectedGoal ? 'default' : 'disabled'}
                    onClick={handleAddGoalClick}
                />
            </div>
            {!!addedGoalsBadgeInfo?.length && (
                <ul className="flex flex-wrap gap-x-3 gap-y-2">
                    {addedGoalsBadgeInfo.map((goal) => (
                        <li key={goal.id}>
                            <Badge
                                color="peach"
                                id={goal.id}
                                removeable
                                handleRemove={handleRemoveGoalBadge}
                                text={<span className="text-body-regular-s">{goal.name}</span>}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default GoalsMultiSelect;
