import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/library-api';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useCustomPagination from '../../hooks/useCustomPagination';

import { getPlaybooksRequestQueryParams } from '../../helpers/getPlaybooksRequestQueryParams';
import { parsePlaybooksIndexSearchParams } from '../../helpers/parsePlaybooksIndexSearchParams';

import PlaybooksTitleBlock from './PlaybooksTitleBlock/PlaybooksTitleBlock';
import PlaybookDetailsModal from '../PlaybookBuilderPage/PlaybookDetailsModal/PlaybookDetailsModal';
import Loading from '../../components/Loading';
import PlaybooksPaginatedList from './PlaybooksPaginatedList/PlaybooksPaginatedList';
import PlaybooksFilteringBlock from './PlaybooksFilteringBlock/PlaybooksFilteringBlock';

export const PAGINATION_LIMIT = 12;

const PlaybooksIndexPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = parsePlaybooksIndexSearchParams(searchParams);
    const requestSearchParams = getPlaybooksRequestQueryParams(parsedParams);

    const playbooksDataResponse = useCustomPagination({
        client,
        route: API.ROUTES.library.playbook,
        pageIndex: parsedParams.page - 1 || 0,
        searchParams: requestSearchParams,
        limit: PAGINATION_LIMIT,
        autoFetchNextPage: true,
    });
    const { data: playbooks, total } = playbooksDataResponse;

    const [isCreatePlaybookModalOpened, setIsCreatePlaybookModalOpened] = useState(false);

    useDocumentTitle('Playbooks');

    useEffect(() => {
        if (!parsedParams.page) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.set('page', '1');
            setSearchParams(urlSearchParams, { replace: true });
        }
    }, []);

    const openCreatePlaybookModal = () => setIsCreatePlaybookModalOpened(true);

    const noPlaybooks = playbooks && total === 0 && !parsedParams.search;

    return (
        <div className="page-position bg-neutral-50/70 overflow-y-auto flex flex-col">
            <PlaybooksTitleBlock openCreatePlaybookModal={openCreatePlaybookModal} />
            <div className="px-5 py-4 xs:p-6 lg:p-8 flex flex-col gap-5">
                <PlaybooksFilteringBlock parsedParams={parsedParams} noPlaybooks={noPlaybooks} />

                {playbooks ? (
                    <PlaybooksPaginatedList
                        page={parsedParams.page || 1}
                        playbooksDataResponse={playbooksDataResponse}
                        parsedSearchQuery={parsedParams.search}
                        openCreatePlaybookModal={openCreatePlaybookModal}
                    />
                ) : (
                    <div className="flex items-center justify-center pt-2">
                        <Loading />
                    </div>
                )}
            </div>

            {isCreatePlaybookModalOpened && (
                <PlaybookDetailsModal
                    mode="create"
                    onClose={() => setIsCreatePlaybookModalOpened(false)}
                />
            )}
        </div>
    );
};

export default PlaybooksIndexPage;
