import React from 'react';

import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';

import NewRichTextArea from '../../../../design-system/NewRichTextArea/NewRichTextArea';

const InboxTaskInstructionsBlock = ({ instructions, setInstructions, setChangedTaskFields }) => {
    const { isDesktop } = useResponsiveBreakpoints();

    const handleInstructionsChange = (value) => {
        setInstructions(value);
        setChangedTaskFields((prevData) => addUniqueElementToArray('instructions', prevData));
    };

    return (
        <div className="flex-grow">
            <NewRichTextArea
                value={instructions || ''}
                label="Inbox Instructions"
                tipText="Instructions will be shown to the user in any Inbox Message created by this task."
                setValue={handleInstructionsChange}
                withCopyButton
                autoExpand={!isDesktop}
                minHeight={220}
            />
        </div>
    );
};

export default InboxTaskInstructionsBlock;
