import React from 'react';
import { Badge } from '../../design-system';

const LabelBadge = ({ label, handleRemove = () => {}, removeable = false }) => {
    const badgeText = (
        <div className="flex items-center gap-1">
            <span
                className="w-[8px] h-[8px] min-w-[8px] rounded-2"
                style={{ backgroundColor: label.icon_color }}
            ></span>
            {label.name}
        </div>
    );
    return (
        <Badge
            id={label.id}
            text={badgeText}
            color="neutral"
            removeable={removeable}
            handleRemove={handleRemove}
        />
    );
};

export default LabelBadge;
