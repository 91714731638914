import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/template-api';
import { useFormState } from '../../../../hooks/useFormState';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import Alert from '../../../../design-system/Alert/Alert';
import { Button, Input, TextArea } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const successMessage = {
    true: 'Published version successfully updated!',
    false: 'Version successfully published!',
};

const PublishingForm = ({
    versionData,
    setSuccessAlert,
    setProcessTemplateData,
    setTemplateVersionData,
    onClose,
}) => {
    const { id, version_name, name, release_notes, is_published } = versionData;
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        {
            name,
            release_notes,
        }
    );
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSave = async () => {
        const areEmptyFields = !formData.name || !formData.release_notes;
        if (areEmptyFields) {
            setFieldErrorMessages({
                name: !formData.name ? emptyFieldErrorMessage : null,
                release_notes: !formData.release_notes ? emptyFieldErrorMessage : null,
            });
            return;
        }

        try {
            setIsLoading(true);

            let updatedVersion;
            const apiRoute = `${API.ROUTES.template.processTemplateVersion}${id}/publish/`;
            if (is_published) {
                const { data } = await client.patch(apiRoute, formData);
                updatedVersion = data;
            }
            if (!is_published) {
                const { data } = await client.post(apiRoute, formData);
                updatedVersion = data;
            }
            setSuccessAlert({ message: successMessage[is_published] });
            // update process template state
            if (setProcessTemplateData) {
                setProcessTemplateData((prevData) => ({
                    ...prevData,
                    is_published: true,
                    versions: prevData.versions.map((version) =>
                        version.id === updatedVersion.id
                            ? { ...version, ...updatedVersion }
                            : version
                    ),
                }));
            }
            // update process template version state
            if (setTemplateVersionData) {
                setTemplateVersionData((prevData) => ({ ...prevData, ...updatedVersion }));
            }
            onClose();
            setIsLoading(false);
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
            setIsLoading(false);
        }
    };

    return (
        <>
            <Input
                size="md"
                name="version_name"
                value={version_name}
                label="Release Version"
                isRequired
                state="disabled"
                onChange={() => {}}
            />
            <Input
                size="md"
                name="name"
                value={formData.name}
                label="Release Name"
                isRequired
                state={fieldErrorMessages.name ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
            />
            <TextArea
                name="release_notes"
                value={formData.release_notes}
                label="Release Notes"
                isRequired
                state={fieldErrorMessages.release_notes ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.release_notes}
                placeholder="Add some notes about what has changed in this release."
                onChange={(e) => handleInputChange('release_notes', e.target.value)}
            />
            <div className="flex items-center justify-between gap-2">
                <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                <Button
                    type="primary"
                    size="sm"
                    text={is_published ? 'Save' : 'Publish'}
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleSave}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default PublishingForm;
