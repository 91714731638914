import React from 'react';
import { EmptyIndexPage } from '../EmptyIndex/EmptyIndex';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { usePagination } from '../../../hooks';
// import client from '../../../services/library-api';
// import { API } from 'constants';
// import Loading from '../../../components/Loading';
// import SharedTaskCard from '../SharedTaskCard/SharedTaskCard';
// import { getOrganizationMembership } from '../../../helpers/getOrganizationMembership';
// import useUser from '../../../hooks/useUser';
// import TaskCard from '../TaskCard/TaskCard';

const SharedTaskIndexPage = ({ pageIndex, sortType, libraryType }) => {
    // const navigate = useNavigate();
    // const location = useLocation();
    // const { sortedData, loading } = usePagination({
    //     pageIndex,
    //     sortType,
    //     client,
    //     route: API.ROUTES.library.sharedTasks,
    // });
    // const { user } = useUser();
    // const organizationMembership = getOrganizationMembership(user);

    // if (!sortedData?.length && loading) {
    //     // SortedData will prefetch so display it if available
    //     return (
    //         <div className="flex flex-col p-8 m-8">
    //             <Loading text="" />
    //         </div>
    //     );
    // }

    // const handleViewNav = (id) => {
    //     navigate(`/library/prompt/${id}`, {
    //         state: { libraryLocation: location },
    //     });
    // };

    // if (!sortedData.length && pageIndex === 0) {
    return (
        <>
            <EmptyIndexPage libraryType={libraryType} />
        </>
    );
    // }

    // return (
    //     <ul className="grid grid-cols-1 gap-x-5 gap-y-5 md:grid-cols-2 l:grid-cols-3 xl:gap-x-5 3xl:grid-cols-4">
    //         {sortedData.map((task) => (
    //             <SharedTaskCard
    //                 key={task.id}
    //                 task={task}
    //                 onButtonClick={() => handleViewNav(task.default_prompt.id)}
    //             />
    //         ))}
    //     </ul>
    // );
};

export default SharedTaskIndexPage;
