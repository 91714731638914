import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SvgIcon } from '../index';
import { ErrorWarningLineIcon } from '../Icons';

const ErrorMessage = ({ message, size = 'small', highlightedStart, customStyles }) => {
    const iconSize = {
        small: 'medium',
        medium: 'large',
    }[size];

    const messageClassNames = classNames('font-body text-red-500', {
        'text-body-regular-xs': size === 'small',
        'text-body-regular-m pt-0.5': size === 'medium',
    });

    const highlightedStartClassNames = classNames({
        'text-body-bold-xs': size === 'small',
        'text-body-bold-m': size === 'medium',
    });

    return (
        <div className={`flex gap-1 ${customStyles}`}>
            <SvgIcon color="#E95B69" icon={ErrorWarningLineIcon} size={iconSize} />
            <p className={messageClassNames}>
                {highlightedStart && (
                    <span className={highlightedStartClassNames}>{highlightedStart}: </span>
                )}{' '}
                {message}
            </p>
        </div>
    );
};

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium']),
    highlightedStart: PropTypes.string,
    customStyles: PropTypes.string,
};

export default ErrorMessage;
