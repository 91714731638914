import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { handleClearSearchBar, handleSearch } from '../../helpers/handleUpdateSearchParams';

import SearchBar from '../../design-system/SearchBar/SearchBar';

SearchBarWithUrlParamsHandling.propTypes = {
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    placeholder: PropTypes.string,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    usePagination: PropTypes.bool,
    usePageState: PropTypes.bool,
    resetPageState: PropTypes.func,
};

function SearchBarWithUrlParamsHandling({
    size = 'xs',
    placeholder = 'Search...',
    maxWidth: _maxWidth = 'auto',
    usePagination = true,
    usePageState = false,
    resetPageState = () => {},
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = searchParams.get('search') || '';

    const [searchValue, setSearchValue] = useState(searchQuery);

    const handleSubmitSearchQuery = () => {
        handleSearch({
            searchParams,
            setSearchParams,
            searchValue,
            usePagination,
            usePageState,
            resetPageState,
        });
    };

    const handleClearSearchQuery = () => {
        handleClearSearchBar({
            searchParams,
            setSearchParams,
            setSearchValue,
            usePagination,
            usePageState,
            resetPageState,
        });
    };

    const maxWidth = typeof _maxWidth === 'number' ? `${_maxWidth}px` : _maxWidth;

    return (
        <div className="w-full" style={{ maxWidth }}>
            <SearchBar
                size={size}
                value={searchValue}
                placeholder={placeholder}
                withSubmitIcon
                withClearIcon
                onChange={(e) => setSearchValue(e.target.value)}
                onSubmit={handleSubmitSearchQuery}
                onClear={handleClearSearchQuery}
            />
        </div>
    );
}

export default SearchBarWithUrlParamsHandling;
