import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatVersionsDataForTable } from '../../../../helpers/formatVersionsDataForTable';

import Table from '../../../../design-system/Table/Table';
import Alert from '../../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import ProcessVersionPublishingModal from '../../ProcessVersionPublishingModal/ProcessVersionPublishingModal';

const AvailableVersionsBlock = ({ processTemplateData, setProcessTemplateData }) => {
    const { id, versions } = processTemplateData;
    const navigate = useNavigate();
    const location = useLocation();

    const [publishingModal, setPublishingModal] = useState({ isOpened: false, versionData: null });
    const [successAlert, setSuccessAlert] = useState(null);

    const mergedVersions = useMemo(
        () => versions?.filter((version) => version.status === 'merged') || [],
        [versions]
    );

    const tableData = useMemo(() => formatVersionsDataForTable(mergedVersions), [mergedVersions]);

    const openPublishingModal = (versionTableRow) => {
        const currentVersion = versions?.find((version) => version.id === versionTableRow.id);
        if (currentVersion) {
            setPublishingModal({ isOpened: true, versionData: currentVersion });
        }
    };

    const handleShowProcessTemplateDetails = (version) => {
        navigate(`/templates/process/${id}/branch/${version.id}`, { state: { from: location } });
    };

    const rowActionButtons = [
        {
            action: 'edit',
            handleAction: openPublishingModal,
        },
        {
            action: 'show',
            handleAction: handleShowProcessTemplateDetails,
        },
    ];

    return (
        <div className="px-4 pt-4 md:px-8 pb-5 w-full bg-white flex flex-col gap-5">
            <p className="font-body-bold text-body-bold-l text-neutral-500">Available Versions</p>
            <Table
                data={tableData}
                columnsCount={1}
                shift={600}
                rowActionButtons={rowActionButtons}
            />
            {publishingModal.isOpened && (
                <ProcessVersionPublishingModal
                    versionData={publishingModal.versionData}
                    setProcessTemplateData={setProcessTemplateData}
                    setSuccessAlert={setSuccessAlert}
                    onClose={() => setPublishingModal({ isOpened: false })}
                />
            )}
            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </div>
    );
};

export default AvailableVersionsBlock;
