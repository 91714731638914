import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Button } from '../../../design-system';
import PlaybookSharingModal from '../PlaybookSharingModal/PlaybookSharingModal';

const SharePlaybookBlock = ({ playbookDetail, setPlaybookDetail, playbookContentFormData }) => {
    const [buttonRoot, setButtonRoot] = useState(null);

    const isRootMountedRef = useRef(false);
    const isRootMounted = isRootMountedRef.current;

    const [isSharingModalOpened, setIsSharingModalOpened] = useState(false);

    useEffect(() => {
        if (!isRootMounted) {
            isRootMountedRef.current = true;
        }
    }, [isRootMounted]);

    useEffect(() => {
        if (isRootMounted && !buttonRoot) {
            const buttonRoot = document.querySelector('#share-playbook-button');
            setButtonRoot(buttonRoot);
        }
    }, [isRootMounted]);

    if (!buttonRoot) return <></>;

    return createPortal(
        <>
            <Button
                type="link"
                size="sm"
                text="Share"
                onClick={() => setIsSharingModalOpened(true)}
            />

            {isSharingModalOpened && (
                <PlaybookSharingModal
                    playbookDetail={playbookDetail}
                    setPlaybookDetail={setPlaybookDetail}
                    playbookContentFormData={playbookContentFormData}
                    onClose={() => setIsSharingModalOpened(false)}
                />
            )}
        </>,
        buttonRoot
    );
};

export default SharePlaybookBlock;
