import React from 'react';

import { API } from 'constants';
import client from '../../../../services/assistant-api';
import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';

import { mutateDataAfterPromptEditing } from '../../../../helpers/playbookBuilderUtils';

import PromptForm from '../PromptForm/PromptForm';

const CreatedPromptEditingView = ({ promptData, mutate, isDisabled, setIsEditing }) => {
    const { id, default_version } = promptData;
    const prompt = default_version?.messages?.[0]?.content;

    const {
        requestKeysToMutate: { promptsInPlaybooks: requestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const onCancel = () => {
        setIsEditing(false);
    };

    const handleSavePrompt = async (requestBody) => {
        const { data } = await client.patch(
            `${API.ROUTES.assistant.chatPrompt}${id}/`,
            requestBody
        );
        mutate(data, false);
        mutateDataAfterPromptEditing({ requestKeysToMutate, promptData: data });
        setIsEditing(false);
    };

    return (
        <PromptForm
            mode="edit"
            data={{ ...promptData, prompt }}
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={handleSavePrompt}
        />
    );
};

export default CreatedPromptEditingView;
