import React from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';

import HomeIcon from '../../../design-system/Icons/HomeIcon';
import Loading from '../../../components/Loading';
import FolderNavItem from '../FolderNavItem/FolderNavItem';

const FolderNavBar = () => {
    const navigate = useNavigate();
    const { folderId } = useParams();

    const { sidePanelFolders, expandedFolders, setLocation } = useDocsPageContext();

    const handleHomeClick = () => {
        navigate('/docs');
        setLocation([]);
    };

    const homeButtonClassName = classNames(
        'px-2 py-2.5 flex items-center gap-2 rounded-2 hover:bg-neutral-50 transition-colors',
        { 'bg-white': folderId, 'bg-neutral-50': !folderId }
    );

    return (
        <div className="flex-1 flex flex-col gap-1 overflow-y-auto -mr-5 pr-5">
            <button onClick={handleHomeClick} className={homeButtonClassName}>
                <HomeIcon width={20} height={20} color="#754DCF" />
                <p className="font-body text-body-regular-s text-neutral-500">Docs</p>
            </button>

            {sidePanelFolders && (
                <ul className="flex flex-col gap-1 max-w-full">
                    {sidePanelFolders?.map((folder) => {
                        const currentFolderExpandedState = expandedFolders[folder.id];

                        return (
                            <FolderNavItem
                                key={folder.id}
                                folder={folder}
                                isTopLevel
                                currentFolderExpandedState={currentFolderExpandedState}
                                prevFoldersLocation={[]}
                            />
                        );
                    })}
                </ul>
            )}

            {!sidePanelFolders && (
                <div>
                    <Loading withText={false} />
                </div>
            )}
        </div>
    );
};

export default FolderNavBar;
