import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/template-api';

import { copyGoalTemplateConfig } from '../../../helpers/copyGoalTemplateConfig';
import { ListOption, ListOptionGroup } from '../../../design-system';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const GoalTemplateMoreOptionsPopup = ({
    goalTemplateData,
    openConfirmDeletionModal,
    popupLocation = 'libraryPage',
    setCopyAlert,
    onClose,
}) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleViewDatasets = () => {
        navigate(`/templates/goal/${goalTemplateData.id}/datasets`);
    };

    const handleViewTestRuns = () => {
        navigate(`/templates/goal/${goalTemplateData.id}/tests`);
    };

    const redirectToGitHub = () => {
        window.open(goalTemplateData.github_url, '_blank');
        onClose();
    };

    //copy goal template config to clipboard
    const handleCopyGoalTemplateConfig = async () => {
        // if a goalTemplateData has a current_content info
        if (!!goalTemplateData.current_content) {
            copyGoalTemplateConfig(goalTemplateData, setCopyAlert);
            onClose();
            return;
        }

        // if a goalTemplateData does not have a current_content info fetch goal template data first
        try {
            setIsLoading(true);
            const { data } = await client.get(
                `${API.ROUTES.template.goalTemplate}${goalTemplateData.id}/`
            );
            copyGoalTemplateConfig(data, setCopyAlert);

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setCopyAlert({
                status: 'critical',
                message: defaultErrorMessage,
                icon: ErrorWarningLineIcon,
            });
        }
        onClose();
    };

    const popupPosition = {
        libraryPage: 'absolute top-8 right-1 z-55',
        detailPage: 'absolute right-0 top-full z-10',
    };

    return (
        <div className={popupPosition[popupLocation]}>
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="databaseIcon"
                            text="View Datasets"
                            onClick={handleViewDatasets}
                        />
                        <ListOption
                            leadingIconName="flashlightFillIcon"
                            text="View Test Runs"
                            onClick={handleViewTestRuns}
                        />
                        <ListOption
                            leadingIconName="shareBoxLineIcon"
                            text="View in GitHub"
                            isDisabled={!goalTemplateData.is_live || !goalTemplateData.github_url}
                            onClick={redirectToGitHub}
                        />
                        <ListOption
                            leadingIconName="copyIcon"
                            text="Copy Configuration"
                            isLoading={isLoading}
                            onClick={handleCopyGoalTemplateConfig}
                        />
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete"
                            textColor="#E95B69"
                            iconColor="#E95B69"
                            onClick={openConfirmDeletionModal}
                        />
                    </>
                }
                handleClose={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};

export default GoalTemplateMoreOptionsPopup;
