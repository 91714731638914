import React, { useState } from 'react';
import { API } from 'constants';
import client from '../../../../services/library-api';

import { TASK_TYPES } from '../../../../constants/library';
import {
    EMPTY_FIELDS_ERROR_MESSAGE,
    INBOX_ACTION_TYPES_OPTIONS,
    TASK_TYPES_WITH_ITEMS_INPUT,
    TASK_TYPES_WITH_KEY_INPUT,
    TASK_TYPES_WITH_REQUIRED_INPUT,
    TASK_TYPES_WITH_TYPE_INPUT,
    TYPES_OPTIONS,
} from '../../../../constants/taskPlayground';
import { keyRegex } from '../../../../constants/regex_patterns';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { formatToKey } from '../../../../helpers/formatLabelToKey';
import { formatKeyToLabel } from '../../../../helpers/formatKeyToLabel';
import {
    mergeExistingValuesIntoNewOutputs,
    updateSpecificUserInputs,
} from '../../../../helpers/determineInputs';
import { removeRedundantFieldsFromInput } from '../../../../helpers/taskPlaygroundUtils';

import Modal from '../../../../design-system/Modal/Modal';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import OptionsInput from '../../../../design-system/OptionsInput/OptionsInput';
import { Button, Input, Select, Toggle } from '../../../../design-system';

const CreateNewInputModal = ({
    onClose,
    userInput,
    taskId,
    taskType,
    setUpdatedUserInputs,
    setOutputs,
    messagesArray,
    apiTaskChangeableData,
    taskChangeableData,
}) => {
    const [enteredData, setEnteredData] = useState({
        key: '',
        label: '',
        actionType: null, // for inbox type
        optionLabel: '', // for inbox type
        type: null, // for webhook and api types
        is_required: true,
    });
    const [optionsArr, setOptionsArr] = useState([]);
    const [fieldsErrorMessages, setFieldsErrorMessages] = useState({});

    const [errorAlert, setErrorAlert] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);

    const shouldAddOptions =
        enteredData.actionType === 'single-select' || // for inbox task
        enteredData.actionType === 'multi-select' || // for inbox task
        enteredData.type === 'string'; // for api task

    const handleChange = (e) => {
        setEnteredData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
        if (fieldsErrorMessages[e.target.name]) {
            setFieldsErrorMessages((prevState) => ({ ...prevState, [e.target.name]: null }));
        }
    };

    const handleSelect = (field, option) => {
        setEnteredData((prevData) => ({ ...prevData, [field]: option }));
        if (fieldsErrorMessages[field]) {
            setFieldsErrorMessages((prevState) => ({ ...prevState, [field]: null }));
        }
    };

    const handleAddNewOption = (newOption) => {
        setOptionsArr((prevOptions) => [...prevOptions, newOption]);
        if (fieldsErrorMessages.options) {
            setFieldsErrorMessages((prevState) => ({ ...prevState, options: null }));
        }
    };

    const handleRemoveOptionBadge = (optionToRemove) => {
        setOptionsArr((prevOptions) => prevOptions.filter((option) => option !== optionToRemove));
    };

    const getFormattedOptions = () => {
        return !shouldAddOptions
            ? {}
            : optionsArr.reduce((acc, item) => ({ ...acc, [item]: item }), {});
    };

    const checkIfEnteredDataValid = () => {
        let isEnteredDataValid = true;

        const requiredFields = {
            [TASK_TYPES.inbox]: shouldAddOptions
                ? ['label', 'actionType', 'options']
                : ['label', 'actionType'],
            [TASK_TYPES.webhook]: ['label', 'type'],
            [TASK_TYPES.api]: ['label', 'type'],
            [TASK_TYPES.assistant]: ['label'],
            [TASK_TYPES.code]:
                enteredData.type === 'array'
                    ? ['key', 'label', 'type', 'items']
                    : ['key', 'label', 'type'],
            [TASK_TYPES.plot]:
                enteredData.type === 'array'
                    ? ['key', 'label', 'type', 'items']
                    : ['key', 'label', 'type'],
        };
        const requiredFieldsArr = requiredFields[taskType];

        const areEmptyFields = requiredFieldsArr?.some((field) =>
            field === 'options' ? !optionsArr?.length : !enteredData[field]
        );

        if (areEmptyFields) {
            setFieldsErrorMessages((prevState) => {
                const errorMessages = { ...prevState };
                requiredFieldsArr.forEach((field) => {
                    if (field === 'options') {
                        errorMessages.options = !optionsArr?.length
                            ? EMPTY_FIELDS_ERROR_MESSAGE.options
                            : null;
                        return;
                    }
                    errorMessages[field] = !enteredData[field]
                        ? EMPTY_FIELDS_ERROR_MESSAGE[field]
                        : null;
                });
                return errorMessages;
            });
            isEnteredDataValid = false;
        }

        if (taskType === TASK_TYPES.code || taskType === TASK_TYPES.plot) {
            const isKeyValid = keyRegex.test(enteredData.key);
            if (!isKeyValid) {
                setFieldsErrorMessages((prevState) => ({
                    ...prevState,
                    key: 'Key must only contain lowercase letters, numbers, or underscores.',
                }));
                isEnteredDataValid = false;
            }

            const isKeyUnique = userInput.inputs?.every((input) => input.key !== enteredData.key);
            if (!isKeyUnique) {
                setFieldsErrorMessages((prevState) => ({
                    ...prevState,
                    key: 'Key must be unique.',
                }));
                isEnteredDataValid = false;
            }
        }

        return isEnteredDataValid;
    };

    const addNewInput = async () => {
        const isEnteredDataValid = checkIfEnteredDataValid();
        if (!isEnteredDataValid) {
            return;
        }

        try {
            setIsLoading(true);

            const { key, label, actionType, type, items, is_required } = enteredData;
            const newInputRequestBody = {
                [TASK_TYPES.inbox]: {
                    key: formatToKey(label),
                    type: actionType === 'multi-select' ? 'array' : 'string',
                    label,
                    is_required: true,
                    options: getFormattedOptions(),
                    action_type: actionType,
                },
                [TASK_TYPES.webhook]: {
                    label,
                    type,
                    key: formatToKey(label),
                    is_required: true,
                },
                [TASK_TYPES.assistant]: {
                    label,
                    type: 'string',
                    key: formatToKey(label),
                    is_required: true,
                    value: '',
                },
                [TASK_TYPES.function]: {
                    label,
                    type: 'string',
                    key: formatToKey(label),
                    is_required: true,
                    value: '',
                },
                [TASK_TYPES.api]: {
                    label,
                    type,
                    key: formatToKey(label),
                    is_required: true,
                    value: '',
                    // only add options if it's not empty
                    ...(optionsArr.length > 0 && { options: getFormattedOptions() }),
                },
                [TASK_TYPES.code]: {
                    key,
                    label,
                    type,
                    is_required,
                    ...(type === 'array' && { items }),
                },
                [TASK_TYPES.plot]: {
                    key,
                    label,
                    type,
                    is_required,
                    ...(type === 'array' && { items }),
                },
            };

            const requestBody = {
                [userInput.key]: [
                    ...userInput.inputs.map((item) => {
                        const input = removeRedundantFieldsFromInput(item);
                        return taskType === TASK_TYPES.assistant
                            ? { ...input, value: '' }
                            : { ...input };
                    }),
                    newInputRequestBody[taskType],
                ],
            };

            const { data } = await client.patch(
                `${API.ROUTES.library.task}${taskId}/`,
                requestBody
            );
            const { func_def, inputs, outputs } = data;
            const apiRequest = [
                apiTaskChangeableData?.request_url,
                apiTaskChangeableData?.request_headers,
                apiTaskChangeableData?.request_data,
            ];
            const code = taskChangeableData?.code || '';
            const definition = taskChangeableData?.definition || {};

            setUpdatedUserInputs(
                updateSpecificUserInputs({
                    userInputKey: userInput.key,
                    func_def,
                    inputsFromResponse: inputs,
                    existingInputs: userInput.inputs,
                    taskType,
                    messagesArray,
                    code,
                    apiRequest,
                    definition,
                    shouldCheckMissedInputs: true,
                })
            );
            if (taskType !== TASK_TYPES.code) {
                setOutputs((existingOutputs) =>
                    mergeExistingValuesIntoNewOutputs({
                        existingOutputs,
                        newOutputs: outputs,
                        addValueFromExistingOutputs: true,
                    })
                );
            }

            setIsLoading(false);
            onClose();
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
            setIsLoading(false);
        }
    };

    // used to increase the height of the modal so that the dropdown fits entirely on the screen
    const formMarginBottom =
        typeDropdownOpen &&
        (taskType === TASK_TYPES.webhook ||
            (taskType === TASK_TYPES.api && enteredData.type !== 'string'))
            ? 90
            : typeDropdownOpen &&
              (taskType === TASK_TYPES.code || taskType === TASK_TYPES.plot) &&
              enteredData.type !== 'array'
            ? 64
            : 0;

    const increaseActionTypeSelectHeight =
        !enteredData.actionType ||
        enteredData.actionType === 'body' ||
        enteredData.actionType === 'freeform'
            ? 90
            : 0;

    return (
        <Modal onClose={onClose} size="medium" hideScrollbar>
            <div className="p-2 flex flex-col gap-8">
                <ModalHeader onClose={onClose} title={`Add New ${userInput.label} Input`} />

                <div
                    className="flex flex-col gap-5 transition"
                    style={{
                        marginBottom: `${formMarginBottom}px`,
                        transition: 'margin 0.2s ease-in-out',
                    }}
                >
                    {TASK_TYPES_WITH_KEY_INPUT.includes(taskType) && (
                        <Input
                            size="md"
                            name="key"
                            label="Input Variable Key"
                            value={enteredData.key}
                            isRequired
                            onChange={handleChange}
                            state={fieldsErrorMessages.key ? 'error' : 'default'}
                            errorMessage={fieldsErrorMessages.key}
                            placeholder="Add variable key"
                            tipText="Input key needs to be all lowercase with no spaces and no “-”"
                        />
                    )}

                    <Input
                        size="md"
                        name="label"
                        label={`${userInput.label} Label`}
                        value={enteredData.label}
                        isRequired
                        onChange={handleChange}
                        state={fieldsErrorMessages.label ? 'error' : 'default'}
                        errorMessage={fieldsErrorMessages.label}
                        placeholder="Add Input Label"
                    />

                    {taskType === TASK_TYPES.inbox && (
                        <>
                            <Select
                                size="md"
                                name="actionType"
                                value={enteredData.actionType}
                                options={INBOX_ACTION_TYPES_OPTIONS}
                                label={`${userInput.label} Action Type`}
                                onChange={(option) => handleSelect('actionType', option)}
                                isRequired
                                placeholder="Select an Action Type"
                                state={fieldsErrorMessages.actionType ? 'error' : 'default'}
                                errorMessage={fieldsErrorMessages.actionType}
                                dropdownHeight={180}
                                increaseComponentHeightBy={increaseActionTypeSelectHeight}
                            />

                            {shouldAddOptions && (
                                <OptionsInput
                                    options={optionsArr}
                                    label={`${userInput.label} Options for ${formatKeyToLabel(
                                        enteredData.actionType
                                    )}`}
                                    isRequired
                                    state={fieldsErrorMessages.options ? 'error' : 'default'}
                                    errorMessage={fieldsErrorMessages.options}
                                    handleAddNewOption={handleAddNewOption}
                                    handleRemoveOption={handleRemoveOptionBadge}
                                />
                            )}
                        </>
                    )}

                    {TASK_TYPES_WITH_TYPE_INPUT.includes(taskType) && (
                        <Select
                            size="md"
                            name="type"
                            value={enteredData.type}
                            options={TYPES_OPTIONS[taskType]}
                            label={`${userInput.label} Type`}
                            onChange={(option) => handleSelect('type', option)}
                            isRequired
                            placeholder="Select an option"
                            state={fieldsErrorMessages.type ? 'error' : 'default'}
                            errorMessage={fieldsErrorMessages.type}
                            dropdownHeight={180}
                            useExternalDropdownState
                            dropdownOpen={typeDropdownOpen}
                            setDropdownOpen={setTypeDropdownOpen}
                        />
                    )}
                    {taskType === TASK_TYPES.api && (
                        <>
                            {enteredData.type === 'string' && (
                                <OptionsInput
                                    options={optionsArr}
                                    label="Input Options"
                                    state="default"
                                    handleAddNewOption={handleAddNewOption}
                                    handleRemoveOption={handleRemoveOptionBadge}
                                />
                            )}
                        </>
                    )}
                    {TASK_TYPES_WITH_ITEMS_INPUT.includes(taskType) &&
                        enteredData.type === 'array' && (
                            <Select
                                size="md"
                                name="items"
                                value={enteredData.items}
                                options={TYPES_OPTIONS[taskType]}
                                label={`${userInput.label} Items`}
                                onChange={(option) => handleSelect('items', option)}
                                isRequired
                                placeholder="Select an option"
                                state={fieldsErrorMessages.items ? 'error' : 'default'}
                                errorMessage={fieldsErrorMessages.items}
                                increaseComponentHeightBy={64}
                                dropdownHeight={180}
                            />
                        )}
                    {TASK_TYPES_WITH_REQUIRED_INPUT.includes(taskType) && (
                        <Toggle
                            name="is_required"
                            isSelected={enteredData.is_required}
                            label="Input Variable Required"
                            isRequired
                            size="sm"
                            onChange={(is_required) => handleSelect('is_required', is_required)}
                        />
                    )}
                </div>
                <div className="flex items-center justify-center gap-1 xs:gap-4">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Add New"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={addNewInput}
                    />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default CreateNewInputModal;
