export const collectionColorOptions = [
    '#E95B69',
    '#FF324D',
    '#754DCF',
    '#5920DB',
    '#17B4E5',
    '#1776E5',
    '#F2AA85',
    '#FF9F29',
    '#D9E56E',
    '#A1C70C',
    '#FFD851',
    '#FFB942',
    '#CBD5DC',
    '#738391',
];
