import React from 'react';

const ExpandRightLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24">
            <path
                d="M17.1718 11.0003L12.5149 6.34341L13.9291 4.9292L21.0002 12.0003L13.9291 19.0713L12.5149 17.6571L17.1718 13.0003L7.99995 13.0004L7.99993 11.0004L17.1718 11.0003ZM4 19.0002V5.00021H6V19.0002H4Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ExpandRightLineIcon;
