// Parses search, sort, and page parameters from URL search parameters (can be used on different pages)
// other keys can be added
export const parseSearchParams = (searchParams) => {
    const page = Number.parseInt(searchParams.get('page'));
    const sort = searchParams.get('sort');
    const search = searchParams.get('search');

    const result = { page };

    if (sort) {
        result.sort = sort;
    }

    if (search) {
        result.search = search;
    }

    return result;
};
