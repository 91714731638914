import React, { useRef } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';

import MessageDetailControls from '../MessageDetailControls/MessageDetailControls';
import MessageDetailHeader from '../MessageDetailHeader/MessageDetailHeader';
import MessageContentBlock from '../MessageContentBlock/MessageContentBlock';
import MobileVersionAdditionalControls from '../MobileVersionAdditionalControls/MobileVersionAdditionalControls';
import InstructionsBlock from '../../../components/InstructionsModal/InstructionsBlock/InstructionsBlock';

const ExpandedMessageDetailView = ({
    message,
    currentMessageIndex,
    messagesLength,
    handleNextClick,
    handlePrevClick,
    handleCloseExpandedMessageDetailView,
    setData,
    requestKeysToMutate,
    messageContentFormData,
    setMessageContentFormData,
    showTaskApprovedSnackAlert,
    messageContentBodyTypeFormFieldsCount,
}) => {
    const { width: screenWidth } = useWindowSize();
    const containerRef = useRef(null);

    const showInstructionBlock = message?.instructions && screenWidth >= 900;

    return (
        <>
            {message && (
                <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white overflow-hidden flex flex-col">
                    <div className="fixed bottom-0 right-0 left-0 sm:left-[68px] min-[1160px]:static">
                        <MessageDetailControls
                            message={message}
                            currentMessageIndex={currentMessageIndex}
                            handleNextClick={handleNextClick}
                            handlePrevClick={handlePrevClick}
                            messagesLength={messagesLength}
                            handleFullScreenClick={handleCloseExpandedMessageDetailView}
                            setData={setData}
                            requestKeysToMutate={requestKeysToMutate}
                        />
                    </div>
                    <MobileVersionAdditionalControls
                        message={message}
                        handleCloseExpandedMessageDetailView={handleCloseExpandedMessageDetailView}
                    />
                    <div className="w-full h-[calc(100%-126px)] min-[1160px]:h-[calc(100%-65px)] flex flex-col min-[900px]:flex-row overflow-y-auto">
                        <div className="min-[900px]:flex-grow w-full min-[900px]:w-[61%] l:w-[calc(100%-401px)] h-auto min-[1160px]:h-full">
                            <div
                                className="h-full min-[900px]:overflow-y-auto px-5 sm:px-6 lg:px-[40px] pt-5 pb-[48px]"
                                ref={containerRef}
                            >
                                <MessageDetailHeader
                                    message={message}
                                    containerRef={containerRef}
                                />
                                <MessageContentBlock
                                    message={message}
                                    setData={setData}
                                    requestKeysToMutate={requestKeysToMutate}
                                    formData={messageContentFormData}
                                    setFormData={setMessageContentFormData}
                                    setNextMessageAfterMessageApproval={handleNextClick}
                                    showTaskApprovedSnackAlert={showTaskApprovedSnackAlert}
                                    bodyTypeFormFieldsCount={messageContentBodyTypeFormFieldsCount}
                                />
                            </div>
                        </div>
                        {showInstructionBlock && (
                            <div className="w-[37%] l:w-[400px] h-full border-l-1 border-neutral-200 overflow-y-auto">
                                <InstructionsBlock instructions={message.instructions} />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ExpandedMessageDetailView;
