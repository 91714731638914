import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CONFIGURE_TAB } from '../../../../constants/configure';
import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';

import DeleteScenarioModal from '../../DeleteScenarioModal/DeleteScenarioModal';

const ConfirmDeleteScenarioModal = ({ scenarioId, scenarioName, onClose }) => {
    const navigate = useNavigate();

    const {
        requestKeysToMutate: { scenarios: keysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const onScenarioDelete = () => {
        navigate(`/configure?tab=${CONFIGURE_TAB.scenarios}`, { replace: true });
    };

    return (
        <DeleteScenarioModal
            id={scenarioId}
            name={scenarioName}
            keysToMutate={keysToMutate || []}
            onScenarioDelete={onScenarioDelete}
            onClose={onClose}
        />
    );
};

export default ConfirmDeleteScenarioModal;
