import React, { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
    handleClearSearchBar,
    handleSearch,
    handleSortOptionSelect,
} from '../../helpers/handleUpdateSearchParams';
import { useMediaQuery } from '../../hooks/useMediaQuery';

import { Badge, Button } from '../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../design-system/Icons';
import SearchBar from '../../design-system/SearchBar/SearchBar';
import FilterByProcessDropdown from '../FilterByProcessDropdown/FilterByProcessDropdown';
import PlayCircleFillIcon from '../../design-system/Icons/PlayCircleFillIcon';
import SortDropdown from '../SortDropdown/SortDropdown';

// This component contains the functionality for SearchBar, Filtering by Process, and Sorting.
const SearchSortAndFilterByProcessBar = ({
    parsedParams,
    resetPageState = () => {},
    usePagination = true,
    searchBarPlaceholder = 'Search by keyword',
    loading,
    total,
    label,
    sortOptions,
    maxWidth = 600,
}) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const isBigScreen = useMediaQuery('(min-width: 920px)');

    const [searchValue, setSearchValue] = useState(parsedParams.search || '');
    const [selectedFilterInfo, setSelectedFilterInfo] = useState(
        // processData can be hold in location object when user navigate from some pages (for instance if user come from ProcessStartPage by clicking on "View {n} completed work runs" )
        location?.state?.processData
            ? location.state.processData
            : {
                  // info about selected process in filter dropdown
                  id: parsedParams.process,
                  name: null,
                  icon_color: null,
              }
    );

    const [actionDropdown, setActionDropdown] = useState({ opened: false, action: null }); // action can be "filter" or "sort"

    const closeActionDropdown = (e) => {
        e?.stopPropagation();
        setActionDropdown({ opened: false, action: null });
    };

    const handleSearchQuery = () => {
        handleSearch({
            searchParams,
            setSearchParams,
            searchValue,
            usePagination,
            resetPageState,
            usePageState: true,
        });
    };

    const handleClearSearchQuery = () => {
        handleClearSearchBar({
            searchParams,
            setSearchParams,
            setSearchValue,
            usePagination,
            resetPageState,
            usePageState: true,
        });
    };

    const handleSort = (sortOption) => {
        handleSortOptionSelect({
            searchParams,
            setSearchParams,
            sortOption,
            usePagination,
            usePageState: true,
            resetPageState,
            onSearchDropdownClose: closeActionDropdown,
        });
    };

    const handleFilterOptionSelect = (processId, processInfo) => {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.set('process', processId);
        if (usePagination) {
            updatedSearchParams.set('page', '1');
        }
        setSearchParams(updatedSearchParams);

        setSelectedFilterInfo({
            ...processInfo,
            icon_color: processInfo.badgeLeadingIconColor,
        });

        closeActionDropdown();
        resetPageState();
    };

    const handleFilterOptionRemove = () => {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('process');
        if (usePagination) {
            updatedSearchParams.set('page', '1');
        }
        setSearchParams(updatedSearchParams);

        setSelectedFilterInfo(null);
        closeActionDropdown();
        resetPageState();
    };

    const sortDropdownPosition = {
        top: '130%',
        left: isBigScreen ? '70px' : 'auto',
        right: !isBigScreen ? 0 : 'auto',
    };

    return (
        <div className="flex flex-col gap-2">
            <div
                className="flex items-center gap-2 w-full relative"
                style={{ maxWidth: `${maxWidth}px` }}
            >
                <SearchBar
                    size="xs"
                    value={searchValue}
                    withSubmitIcon
                    withClearIcon
                    placeholder={searchBarPlaceholder}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSubmit={handleSearchQuery}
                    onClear={handleClearSearchQuery}
                />
                <div className="flex items-center gap-2 justify-end sm:relative">
                    <Button
                        type="link"
                        size="xs"
                        text="Filter"
                        trailingIcon={
                            actionDropdown.opened && actionDropdown.action === 'filter'
                                ? ArrowUpSLineIcon
                                : ArrowDownSLineIcon
                        }
                        onClick={() => setActionDropdown({ opened: true, action: 'filter' })}
                    />
                    <Button
                        type="link"
                        size="xs"
                        text="Sort"
                        trailingIcon={
                            actionDropdown.opened && actionDropdown.action === 'sort'
                                ? ArrowUpSLineIcon
                                : ArrowDownSLineIcon
                        }
                        onClick={() => setActionDropdown({ opened: true, action: 'sort' })}
                    />
                    {actionDropdown.opened && actionDropdown.action === 'filter' && (
                        <FilterByProcessDropdown
                            selectedOption={parsedParams.process}
                            handleOptionSelect={handleFilterOptionSelect}
                            handleOptionRemove={handleFilterOptionRemove}
                            onClose={closeActionDropdown}
                        />
                    )}
                    {actionDropdown.opened && actionDropdown.action === 'sort' && (
                        <SortDropdown
                            options={sortOptions}
                            selectedOption={parsedParams.sort}
                            handleOptionSelect={handleSort}
                            onClose={closeActionDropdown}
                            dropdownPosition={sortDropdownPosition}
                        />
                    )}
                </div>
            </div>
            {selectedFilterInfo?.name &&
                !loading &&
                parsedParams.process === selectedFilterInfo.id && (
                    <div className="flex items-center justify-between gap-2">
                        <Badge
                            text={selectedFilterInfo.name}
                            color="neutral"
                            leadingIconColor={selectedFilterInfo.icon_color}
                            leadingIcon={PlayCircleFillIcon}
                            removeable
                            handleRemove={handleFilterOptionRemove}
                            withBorder
                        />
                        <p className="font-body text-body-regular-s text-neutral-300">
                            {total} {total === 1 ? label : `${label}s`}
                        </p>
                    </div>
                )}
        </div>
    );
};

export default SearchSortAndFilterByProcessBar;
