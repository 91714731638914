import React from 'react';
import classNames from 'classnames';

import { SvgIcon } from '../../../design-system';
import UserBadge from '../../../design-system/UserBadge/UserBadge';
import UsersIcon from '../../../design-system/Icons/UsersIcon';

const CardCoverImageBlock = ({ playbook }) => {
    const { cover_image, name, created_by, icon_color, is_shared } = playbook;

    const imageWrapperClassNames = classNames('h-[300px] relative z-10 flex-col', {
        'p-8 flex flex-col justify-between': !cover_image,
        'p-4 flex items-center justify-center': cover_image,
    });

    return (
        <div className={imageWrapperClassNames}>
            <div
                className="absolute inset-0 z-[-1]"
                style={{ backgroundColor: icon_color, opacity: 0.2 }}
            ></div>
            {!!cover_image && (
                <>
                    <img
                        src={cover_image}
                        className="block max-w-full max-h-full object-contain"
                        alt="Playbook Cover Image"
                    />

                    {is_shared && (
                        <div className="absolute top-8 right-8 z-20">
                            <SvgIcon color="#000000" icon={UsersIcon} size="large" />
                        </div>
                    )}
                </>
            )}
            {!cover_image && (
                <>
                    <div className="flex justify-between gap-2">
                        <p className="font-heading-regular font-[600] text-[24px] leading-[26px] text-black line-clamp-[7]">
                            {name}
                        </p>
                        {is_shared && <SvgIcon color="#000000" icon={UsersIcon} size="large" />}
                    </div>
                    <UserBadge name={created_by.name} color="black" />
                </>
            )}
        </div>
    );
};

export default CardCoverImageBlock;
