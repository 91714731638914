import { useEffect } from 'react';
/**
 * This Hook can be used for detecting clicks outside the Opened Menu
 * https://stackoverflow.com/questions/63359138/react-closing-a-dropdown-when-click-outside
 */
export default function useClickOutside(ref, onClickOutside) {
    useEffect(() => {
        /**
         * Invoke Function onClick outside of element
         */
        function handleClickOutside(event) {
            if (
                ref.current &&
                !ref.current.contains(event.target) &&
                !event.target?.classList?.contains('disable-click-outside') // If an element has the className 'disable-click-outside', the onClickOutside function will not be executed when clicking on that specific element. So to block onClickOutside function execution add className 'disable-click-outside'.
            ) {
                onClickOutside(event);
            }
        }
        // Bind
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            // dispose
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ref, onClickOutside]);
}
