import { useEffect, useState } from 'react';
import { DEFAULT_LIMIT } from './useCustomPagination';
import { mergeNewDataIntoCurrent } from '../helpers/mergeNewDataIntoCurrent';

export const useFetchInfinityScrollableSearchAndEditData = ({
    client,
    route,
    searchParams = {},
    limit = DEFAULT_LIMIT,
    startRequest = true, // startRequest prop should be used just if you want to initiate a request after getting some data or any other event (not on page mount)
}) => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(0);

    const [page, setPage] = useState(1);
    const [canLoadMore, setCanLoadMore] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const updatedSearchParams = { ...searchParams };
    if (searchQuery) {
        updatedSearchParams.search = searchQuery;
    }

    const [isFirstRequestCompleted, setIsFirstRequestCompleted] = useState(false); // indicates if the first data is already received

    const [searchParamsId, setSearchParamsId] = useState(0); //the searchParams indicator (when searchParams change, searchParamsId increases by 1, triggering a refetch of options)
    const [controller, setController] = useState(new AbortController());

    const searchParamsQuery =
        Object.keys(updatedSearchParams)
            .map((key) => `${key}=${updatedSearchParams[key]}`)
            .join('&') || '';

    useEffect(() => {
        const fetchData = async () => {
            try {
                controller.abort();

                const newController = new AbortController();
                setController(newController);

                setIsLoading(true);
                const { data: newData } = await client.get(
                    `${route}?limit=${limit}&offset=${(page - 1) * limit}${
                        searchParamsQuery && `&${searchParamsQuery}`
                    }`,
                    { signal: newController.signal }
                );
                setItems((prevData) => mergeNewDataIntoCurrent(prevData || [], newData.results));
                setCanLoadMore(!!newData.next);
                setIsLoading(false);
                if (page === 1) {
                    setTotalItems(newData.count);
                    setIsFirstRequestCompleted(true);
                }
            } catch (error) {
                if (error.message === 'canceled') {
                    return; // the next request is loading
                }
                setIsLoading(false);
            }
        };

        if (startRequest && searchParamsId) {
            fetchData();
        }
    }, [page, startRequest, searchParamsId]);

    useEffect(() => {
        // if searchParams changed reset current options data to fetch new options
        if (startRequest) {
            resetCurrentData();
        }
    }, [searchParamsQuery, startRequest]);

    const resetCurrentData = () => {
        setIsLoading(!!startRequest);
        setPage(1);
        setItems([]);
        setTotalItems(0);
        setSearchParamsId((prevData) => prevData + 1);
        setIsFirstRequestCompleted(false);
    };

    return {
        items,
        setItems,
        isLoading,
        canLoadMore,
        setPage,
        totalItems,
        isFirstRequestCompleted,
        searchQuery,
        setSearchQuery,
    };
};
