import React from 'react';

import { PROJECTS_HISTORY_LIMIT } from '../ClientScenarioDetailPage';
import { useSwrPageRequestKeysManagingWithContextSaving } from '../../../hooks/useSwrPageRequestKeysManagingWithContextSaving';

import Loading from '../../../components/Loading';
import ProjectCard from '../ProjectCard/ProjectCard';
import PaginatedContainer from '../../../components/PaginatedContainer/PaginatedContainer';
import BlockContainerWithHeader from '../../../components/BlockContainerWithHeader/BlockContainerWithHeader';

const ProjectHistoryTab = ({ dataHookResponse, page, setPage }) => {
    const { data: projects, total, count, loading, key, nextPageKey } = dataHookResponse;

    const { requestKeysToMutate } = useSwrPageRequestKeysManagingWithContextSaving({
        key,
        nextPageKey,
        pageAccessor: 'projects',
    });

    const areProjects = !!projects?.length;
    const noProjects = !total && !projects?.length && !loading;

    return (
        <BlockContainerWithHeader title="Project History">
            <PaginatedContainer
                page={page}
                count={count}
                total={total}
                limit={PROJECTS_HISTORY_LIMIT}
                onCustomPreviousPage={() => setPage((page) => page - 1)}
                onCustomNextPage={() => setPage((page) => page + 1)}
            >
                {areProjects && (
                    <div className="flex flex-col">
                        {projects.map((project) => (
                            <ProjectCard
                                key={project.id}
                                project={project}
                                requestKeysToMutate={requestKeysToMutate}
                            />
                        ))}
                    </div>
                )}

                {noProjects && (
                    <p className="font-body-bold text-body-bold-s text-neutral-400">
                        No projects found
                    </p>
                )}

                {loading && (
                    <div className="pt-5">
                        <Loading />
                    </div>
                )}
            </PaginatedContainer>
        </BlockContainerWithHeader>
    );
};

export default ProjectHistoryTab;
