import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

const BlockContainerWithHeader = ({
    title,
    description,
    containerGap = 20,
    topRightContent = null,
    children,
}) => {
    const titleClassNames = classNames(
        'font-heading-bold text-heading-bold-s text-black',
        description && 'mb-2'
    );
    const titleContainerClassName = classNames(
        topRightContent && 'flex items-center justify-between gap-x-5 gap-y-1.5'
    );

    return (
        <div
            className="w-full flex flex-col p-5 sm:p-6 bg-white rounded-2xl"
            style={{ gap: `${containerGap}px` }}
        >
            <div>
                <div className={titleContainerClassName}>
                    <h2 className={titleClassNames}>{title}</h2>

                    {topRightContent}
                </div>
                {description && (
                    <p className="font-body text-body-regular-s text-neutral-400">{description}</p>
                )}
            </div>

            {children}
        </div>
    );
};

BlockContainerWithHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    containerGap: PropTypes.number,
    topRightContent: PropTypes.node,
    children: PropTypes.node.isRequired,
};

export default BlockContainerWithHeader;
