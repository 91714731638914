import React from 'react';
import PropTypes from 'prop-types';

import LoaderIcon from '../../design-system/Icons/LoaderIcon';

const defaultText = "We're cooking up something good...";

const Loading = ({ withText = true, text: _text, size = 'medium', color = 'black' }) => {
    const text = _text || defaultText;

    const sideLength = {
        extraSmall: 16,
        small: 22,
        medium: 30,
    };

    const iconColor =
        {
            black: '#000000',
            white: '#FFFFFF',
        }[color] || '#000000';

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="animate-icon-spin">
                <LoaderIcon width={sideLength[size]} height={sideLength[size]} color={iconColor} />
            </div>

            {withText && <p className="text-center">{text}</p>}
        </div>
    );
};

Loading.propTypes = {
    withText: PropTypes.bool,
    text: PropTypes.string,
    size: PropTypes.oneOf(['extraSmall', 'small', 'medium']),
    color: PropTypes.oneOf(['black', 'white']),
};

export default Loading;
