import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/inbox-api';

import { SORT_TYPES } from '../../constants/sort';
import {
    STATUS_TAB_KEYS,
    TAB_REQUEST_STATUS,
    TOTAL_MESSAGES_REQUEST_SWR_KEY,
} from '../../constants/inbox';

import { useWindowSize } from '../../hooks/useWindowSize';
import useCustomPagination from '../../hooks/useCustomPagination';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import { addUniqueElementToArray } from '../../helpers/addUniqueElementToArray';
import { parseInboxSearchParams } from '../../helpers/parseInboxSearchParams';
import { setSyncTimeout } from '../../helpers/setSyncTimeout';
import { compareObjectsByActionType } from '../../helpers/compareObjectsByActionType';
import { getInboxRequestSearchParams } from '../../helpers/getInboxRequestSearchParams';
import { handleChangeMessageIsReadStatus } from '../../helpers/inboxUtils';
import {
    mergeNewDataAtTheTopOfCurrentData,
    mergeNewDataIntoCurrent,
} from '../../helpers/mergeNewDataIntoCurrent';

import ExpandedMessageDetailView from './ExpandedMessageDetailView/ExpandedMessageDetailView';
import Loading from '../../components/Loading';
import InboxIndexPage from './InboxIndexPage/InboxIndexPage';
import InboxTaskApprovedSnackAlert from './InboxTaskApprovedSnackAlert/InboxTaskApprovedSnackAlert';

const InboxPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedSearchParams = parseInboxSearchParams(searchParams);
    const { width: screenWidth } = useWindowSize();
    const isMobile = screenWidth < 1160;

    const [searchQuery, setSearchQuery] = useState(null);
    const [selectedSortOption, setSelectedSortOption] = useState(SORT_TYPES.created);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);

    const [searchQueryInputValue, setSearchQueryInputValue] = useState('');
    const [shouldSetNewData, setShouldSetNewData] = useState(false); // this state will stay true when searchQuery is changed

    const statusTab = parsedSearchParams.status || STATUS_TAB_KEYS.pending;
    const [isStatusTabIndexChanging, setIsStatusTabIndexChanging] = useState(false);
    const [page, setPage] = useState(1);
    const [areReadMessagesShown, setAreReadMessagesShown] = useState(true);

    const [taskApprovedSnackAlert, setTaskApprovedSnackAlert] = useState({
        isShown: false,
        approvedMessageData: null,
    });

    const requestSearchParams = {
        ordering: selectedSortOption,
        status: STATUS_TAB_KEYS[statusTab],
    };

    const [requestKeysToMutate, setRequestKeysToMutate] = useState([
        TOTAL_MESSAGES_REQUEST_SWR_KEY,
    ]);

    useDocumentTitle('Inbox');

    const { data: resultsForFirstPage } = useCustomPagination({
        // it doesn't create an additional request, it's used to always fetch the latest messages
        pageIndex: 0,
        searchParams: { ordering: SORT_TYPES.created, status: TAB_REQUEST_STATUS[0] },
        client,
        route: API.ROUTES.inbox.message,
    });

    const {
        data: results,
        loading,
        hasNextPage,
        key,
    } = useCustomPagination({
        pageIndex: page - 1,
        searchParams: getInboxRequestSearchParams(
            requestSearchParams,
            searchQuery,
            selectedFilterOptions,
            areReadMessagesShown,
            statusTab
        ),
        client,
        route: API.ROUTES.inbox.message,
    });

    const [data, setData] = useState(null);

    useEffect(() => {
        // if we are not on To Do tab we shouldn't mutate request on approve
        if (statusTab !== STATUS_TAB_KEYS.pending) {
            return;
        }

        if (key) {
            setRequestKeysToMutate((prevKeys) => addUniqueElementToArray(key, prevKeys));
        }
    }, [key]);

    useEffect(() => {
        setData((prevResults) => {
            if (!prevResults && !results) {
                return null;
            } else {
                // when we change tab we also replace current data by new
                if (isStatusTabIndexChanging) {
                    if (results !== null) {
                        setIsStatusTabIndexChanging(false);
                        setShouldSetNewData(false);
                        return results;
                    }
                    setSelectedMessage(null);
                    return prevResults;
                }
                // when user submit search in SearchBar, change filter or sorting, we don't have to merge new Data into current, but we should replace them
                if (shouldSetNewData && results !== null) {
                    setShouldSetNewData(false);
                    return results;
                }
                // when page === 1 and data changed (for example user get new message) merge new data at the top
                if (key.includes('offset=0')) {
                    return mergeNewDataAtTheTopOfCurrentData(prevResults || [], results || []);
                }

                return mergeNewDataIntoCurrent(prevResults || [], results || []); // when page = page + 1
            }
        });
    }, [results]);

    useEffect(() => {
        // if there is no status in search params set "status=pending"
        if (!parsedSearchParams.status) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.set('status', STATUS_TAB_KEYS.pending);
            setSearchParams(urlSearchParams, { replace: true });
        }
    }, [parsedSearchParams.status]);

    useEffect(() => {
        // if it is a start page (tab = To Do, without selected sort, filter and search value) and pagination was used (page > 1) and user get a new mail, set it at the top
        if (
            !searchQuery &&
            !selectedFilterOptions?.length &&
            selectedSortOption === SORT_TYPES.created &&
            statusTab === STATUS_TAB_KEYS.pending &&
            page > 1
        ) {
            setData((prevResults) => {
                if (!prevResults && !results) {
                    return null;
                } else {
                    return mergeNewDataAtTheTopOfCurrentData(
                        prevResults || [],
                        resultsForFirstPage || []
                    );
                }
            });
        }
    }, [resultsForFirstPage]);

    const [selectedMessage, setSelectedMessage] = useState(null); // the message we show on MessageDetailPanel or ExpandedMessageDetailView

    useEffect(() => {
        const selectedMessageInData = data?.find((item) => item.id === selectedMessage?.id);

        // when there is a message_id in search params
        if (parsedSearchParams.message_id && !!data?.length && !selectedMessageInData) {
            //try to find corresponding message (with message_id) in current data
            const messageFromSearchParams = data?.find(
                (item) => item.id === parsedSearchParams.message_id
            );
            // if such message exists set it as selected (show ExpandedMessageDetailView)
            if (messageFromSearchParams) {
                setSelectedMessage(messageFromSearchParams);
                // if such message doesn't exist remove message_id from search params and show normal InboxPage instead of ExpandedMessageDetailView
            } else {
                if (!isMobile) {
                    setSelectedMessage(data[0]);
                }
                if (isMobile) {
                    setSelectedMessage(null);
                }
                const urlSearchParams = new URLSearchParams(searchParams);
                urlSearchParams.delete('message_id');
                setSearchParams(urlSearchParams, { replace: true });
            }
            // if there is no message_id in search params and selectedMessage isn't in current data => set the first element from data as selected
        } else if (!isMobile && !!data?.length && !selectedMessageInData) {
            setSelectedMessage(data[0]);
            // set selected message null if there are no messages in data
        } else if (!selectedMessageInData && data && !data.length) {
            if (selectedMessage) {
                setSelectedMessage(null);
            }
            if (parsedSearchParams.message_id) {
                const urlSearchParams = new URLSearchParams(searchParams);
                urlSearchParams.delete('message_id');
                setSearchParams(urlSearchParams, { replace: true });
            }
        }

        if (selectedMessageInData) {
            const selectedMessageInDataIsReadStatus = selectedMessageInData.is_read;

            if (selectedMessageInDataIsReadStatus !== selectedMessage.is_read) {
                setSelectedMessage(selectedMessageInData);
            }
        }
    }, [data]);

    const selectedMessageIndex =
        data && selectedMessage && data?.findIndex((item) => item.id === selectedMessage.id);

    const [messageContentFormData, setMessageContentFormData] = useState([]); // State used to manage form data in the Message Content Block
    const [messageContentBodyTypeFormFieldsCount, setMessageContentBodyTypeFormFieldsCount] =
        useState(0); // Count of form fields with action_type === "body" (Message Content Block)

    useEffect(() => {
        if (selectedMessage) {
            const content =
                selectedMessage.status === 'completed'
                    ? selectedMessage.final_output
                    : selectedMessage.message_content;

            setMessageContentFormData([...content].sort(compareObjectsByActionType)); // set objects with action_type === 'body' first
            setMessageContentBodyTypeFormFieldsCount(
                content?.filter((item) => item.action_type === 'body')?.length
            );
        }
    }, [selectedMessage?.id]);

    const toggleShownMessagesReadState = () => {
        setShouldSetNewData(true);
        setPage(1);

        setAreReadMessagesShown((prevState) => !prevState);
    };

    const onMessageStatusTabChanged = (tab) => {
        if (tab !== statusTab) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.set('status', tab);
            setSearchParams(urlSearchParams);

            setPage(1);
            setIsStatusTabIndexChanging(true);
            setSearchQueryInputValue('');
            setSearchQuery(null);
            setSelectedFilterOptions([]);
        }
    };

    const handlePrevClick = () => {
        const prevMessage = data[selectedMessageIndex - 1];
        setSelectedMessage(prevMessage);
        if (parsedSearchParams.message_id) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.set('message_id', prevMessage?.id);
            setSearchParams(urlSearchParams);
        }
    };
    const handleNextClick = () => {
        const nextMessage = data[selectedMessageIndex + 1];
        if (nextMessage) {
            setSelectedMessage(nextMessage);
            if (parsedSearchParams.message_id) {
                const urlSearchParams = new URLSearchParams(searchParams);
                urlSearchParams.set('message_id', nextMessage?.id);
                setSearchParams(urlSearchParams);
            }
        }
    };

    const closeExpandedMessageDetailView = () => {
        const urlSearchParams = new URLSearchParams(searchParams);
        urlSearchParams.delete('message_id');
        setSearchParams(urlSearchParams);
    };

    useEffect(() => {
        // make message is_read after 3s after mount
        let stopTimeout;
        if (selectedMessage && !selectedMessage.is_read) {
            stopTimeout = setSyncTimeout(onChangeIsReadStatus, 3000);
        }

        return () => {
            if (stopTimeout) {
                stopTimeout();
            }
        };
    }, [selectedMessage?.id]);

    const onChangeIsReadStatus = async () => {
        try {
            await handleChangeMessageIsReadStatus({
                is_read: true,
                message: selectedMessage,
                setData,
                requestKeysToMutate,
            });
        } catch (e) {}
    };

    const showTaskApprovedSnackAlert = (data) => {
        setTaskApprovedSnackAlert({ isShown: true, approvedMessageData: data });
    };

    const isPageLoaded = !!data;
    const isExpandedView = !!parsedSearchParams.message_id;

    const isSnackAlertDisplayed =
        taskApprovedSnackAlert.isShown && statusTab === STATUS_TAB_KEYS.pending;

    return (
        <>
            {isPageLoaded && (
                <>
                    {!isExpandedView && (
                        <InboxIndexPage
                            data={data}
                            loading={loading}
                            setPage={setPage}
                            areReadMessagesShown={areReadMessagesShown}
                            toggleShownMessagesReadState={toggleShownMessagesReadState}
                            selectedFilterOptions={selectedFilterOptions}
                            setSelectedFilterOptions={setSelectedFilterOptions}
                            handlePrevClick={handlePrevClick}
                            handleNextClick={handleNextClick}
                            setMessageContentFormData={setMessageContentFormData}
                            selectedMessage={selectedMessage}
                            requestKeysToMutate={requestKeysToMutate}
                            messageContentFormData={messageContentFormData}
                            setSearchQueryInputValue={setSearchQueryInputValue}
                            shouldSetNewData={shouldSetNewData}
                            statusTab={statusTab}
                            onMessageStatusTabChanged={onMessageStatusTabChanged}
                            setShouldSetNewData={setShouldSetNewData}
                            hasNextPage={hasNextPage}
                            isStatusTabIndexChanging={isStatusTabIndexChanging}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            searchQueryInputValue={searchQueryInputValue}
                            selectedMessageIndex={selectedMessageIndex}
                            setData={setData}
                            setSelectedMessage={setSelectedMessage}
                            selectedSortOption={selectedSortOption}
                            setSelectedSortOption={setSelectedSortOption}
                            showTaskApprovedSnackAlert={showTaskApprovedSnackAlert}
                            messageContentBodyTypeFormFieldsCount={
                                messageContentBodyTypeFormFieldsCount
                            }
                        />
                    )}

                    {isExpandedView && (
                        <ExpandedMessageDetailView
                            message={selectedMessage}
                            handlePrevClick={handlePrevClick}
                            handleNextClick={handleNextClick}
                            messagesLength={data?.length}
                            handleCloseExpandedMessageDetailView={closeExpandedMessageDetailView}
                            currentMessageIndex={selectedMessageIndex}
                            setData={setData}
                            requestKeysToMutate={requestKeysToMutate}
                            messageContentFormData={messageContentFormData}
                            setMessageContentFormData={setMessageContentFormData}
                            showTaskApprovedSnackAlert={showTaskApprovedSnackAlert}
                            messageContentBodyTypeFormFieldsCount={
                                messageContentBodyTypeFormFieldsCount
                            }
                        />
                    )}

                    {isSnackAlertDisplayed && (
                        <InboxTaskApprovedSnackAlert
                            data={taskApprovedSnackAlert.approvedMessageData || {}}
                            isExpandedView={isExpandedView}
                            onClose={() =>
                                setTaskApprovedSnackAlert({
                                    isShown: false,
                                    approvedMessageData: null,
                                })
                            }
                        />
                    )}
                </>
            )}

            {!isPageLoaded && (
                <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-neutral-50 flex justify-center">
                    <Loading />
                </div>
            )}
        </>
    );
};

export default InboxPage;
