import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useViewType } from '../../../../hooks/useViewType';

import { ListOption, ListOptionGroup } from '../../../../design-system';
import { copyProcessConfig } from '../../../../helpers/copyProcessConfig';

const ProcessMoreOptionsPopup = ({
    processDetail,
    setCopyAlert,
    setIsDeleteModalOpened,
    onClose,
}) => {
    const navigate = useNavigate();
    const { toggleViewType } = useViewType();

    const handleViewAgent = () => {
        toggleViewType();
        navigate(`/agent/${processDetail.id}`);
    };

    const openConfirmDeleteModal = () => {
        setIsDeleteModalOpened(true);
        onClose();
    };

    const handleCopyConfig = () => {
        copyProcessConfig(processDetail, setCopyAlert);
        onClose();
    };

    return (
        <div className="absolute right-0 top-[90%] z-10">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="shareBoxLine"
                            text="View Agent"
                            onClick={handleViewAgent}
                        />
                        <ListOption
                            leadingIconName="fileCopyLineIcon"
                            text="Copy Config"
                            onClick={handleCopyConfig}
                        />
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={openConfirmDeleteModal}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default ProcessMoreOptionsPopup;
