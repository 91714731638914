import React from 'react';

import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import SaveIcon from '../../../design-system/Icons/SaveIcon';
import ModeToggle from '../ModeToggle/ModeToggle';
import { Button } from '../../../design-system';

export const SAVE_PLAYBOOK_BUTTON_ID = 'save-playbook-button';

const DesktopBottomActionBar = ({
    mode,
    changeMode,
    isPlaybookShared,
    isSaveEnabled,
    handleSaveChanges,
    isSaveChangesLoading,
}) => {
    const saveButtonState = isSaveChangesLoading
        ? 'loading'
        : !isSaveEnabled
        ? 'disabled'
        : 'default';

    return (
        <>
            <div className="fixed bottom-7 right-5 p-2 flex items-center gap-2.5">
                {!isPlaybookShared && <ModeToggle mode={mode} changeMode={changeMode} />}

                {mode === PLAYBOOK_BUILDER_MODE.edit && (
                    <Button
                        id={SAVE_PLAYBOOK_BUTTON_ID}
                        type="secondary"
                        size="xs"
                        text="Save"
                        leadingIcon={SaveIcon}
                        state={saveButtonState}
                        onClick={handleSaveChanges}
                    />
                )}
            </div>
        </>
    );
};

export default DesktopBottomActionBar;
