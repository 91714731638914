import React from 'react';
import { ListOption, ListOptionGroup } from '../../../design-system';

const LabelMoreOptionsPopup = ({ onClose, setActionModal, labelData, popupPosition }) => {
    const openEditLabelModal = () => {
        setActionModal({ isOpened: true, mode: 'edit', labelData });
        onClose();
    };

    const openDeleteLabelModal = () => {
        setActionModal({ isOpened: true, mode: 'delete', labelData });
        onClose();
    };

    return (
        <div className="absolute z-50" style={popupPosition}>
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Edit label"
                            onClick={openEditLabelModal}
                        />
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete label"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={openDeleteLabelModal}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default LabelMoreOptionsPopup;
