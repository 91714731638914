import React, { useEffect, useState } from 'react';
import SearchBar from '../../../design-system/SearchBar/SearchBar';

const CollectionsSearchBar = ({ allCollections, filterCollections }) => {
    const [value, setValue] = useState('');

    const filterList = (newValue) => {
        filterCollections(
            allCollections.filter((collection) =>
                collection.name?.toLowerCase()?.includes(newValue.toLowerCase().trim())
            )
        );
    };

    const handleChange = (e) => {
        const newValue = e.target?.value;
        setValue(newValue);
        filterList(newValue);
    };

    useEffect(() => {
        filterList(value);
    }, [allCollections]);

    return <SearchBar size="sm" value={value} onChange={handleChange} onSubmit={() => {}} />;
};

export default CollectionsSearchBar;
