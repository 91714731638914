import { TAB } from '../constants/jobsAndProjects';
import { SORT_TYPES } from '../constants/sort';

export const searchParamsKeys = {
    status: 'status__in',
    agent: 'process__in',
    playbook: 'playbook__in',
};

export const getJobsRequestQueryParams = ({ parsedSearchParams, activeTab }) => {
    const { sort, search, agent, playbook, status } = parsedSearchParams;

    const queryParams = { ordering: sort || SORT_TYPES.created };
    if (search) {
        queryParams.search = search;
    }

    if (!!agent?.length && activeTab === TAB.jobs) {
        queryParams[searchParamsKeys.agent] = agent.join(',');
    }

    if (!!playbook?.length && activeTab === TAB.projects) {
        queryParams[searchParamsKeys.playbook] = playbook.join(',');
    }

    if (!!status?.length) {
        queryParams[searchParamsKeys.status] = status.join(',');
    }
    return queryParams;
};
