import React, { useState } from 'react';

import { useFormState } from '../../../../hooks/useFormState';

import SelectButton from '../../../SelectButton/SelectButton';
import FileTextLineIcon from '../../../Icons/FileTextLineIcon';
import GenericBlockEmptyStateWrapper from '../../GenericBlockEmptyStateWrapper/GenericBlockEmptyStateWrapper';
import SelectDocumentDropdown from '../SelectDocumentDropdown/SelectDocumentDropdown';

const UnconfirmedDocumentBlock = ({
    isEditable,
    docsOptionsHookResponse,
    handleConfirmDocument,
    handleDeleteBlock,
}) => {
    const {
        formData: { selectedDocument },
        fieldErrorMessages: { selectedDocument: errorMessage },
        handleInputChange,
        setFieldErrorMessages,
    } = useFormState({ selectedDocument: null });

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const onConfirmDocumentClick = () => {
        if (!selectedDocument?.id) {
            setFieldErrorMessages({ selectedDocument: 'Please select a document' });
            return;
        }

        handleConfirmDocument(selectedDocument);
    };

    const toggleDropdownOpened = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsDropdownOpened((prevState) => !prevState);
    };

    const selectState = !isEditable ? 'disabled' : errorMessage ? 'error' : 'default';

    const formattedSelectedDocument = selectedDocument
        ? {
              ...selectedDocument,
              leadingIcon: FileTextLineIcon,
              leadingIconColor: '#754DCF',
          }
        : null;

    return (
        <GenericBlockEmptyStateWrapper
            isEditable={isEditable}
            isError={!!errorMessage}
            handleDeleteBlock={handleDeleteBlock}
            handleSubmit={onConfirmDocumentClick}
        >
            <SelectButton
                size="xs"
                state={selectState}
                selectedItem={formattedSelectedDocument}
                label="Connect Document"
                placeholder="Select a document"
                errorMessage={errorMessage}
                isDropdownOpened={isDropdownOpened}
                toggleDropdownOpened={toggleDropdownOpened}
                showAsBadge
                badgeColor="purple"
            />

            {isDropdownOpened && (
                <SelectDocumentDropdown
                    selectedDocumentId={selectedDocument?.id}
                    docsOptionsHookResponse={docsOptionsHookResponse}
                    toggleDropdownOpened={toggleDropdownOpened}
                    handleDocumentSelect={(document) =>
                        handleInputChange('selectedDocument', document)
                    }
                />
            )}
        </GenericBlockEmptyStateWrapper>
    );
};

export default UnconfirmedDocumentBlock;
