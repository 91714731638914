import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '../index';
import { InformationLineIcon } from '../Icons';
import classNames from 'classnames';

Banner.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    color: PropTypes.oneOf(['blue', 'peach', 'red']),
    onBannerClick: PropTypes.func,
};

function Banner({ title, text, color = 'blue', onBannerClick }) {
    const iconColor = {
        red: '#F8CACE',
        blue: '#63D6FA',
        peach: '#F7D289',
    };

    const bannerColorStyles = {
        red: 'bg-red-100/50 border-red-300',
        blue: 'bg-blue-300/20 border-blue-300',
        peach: 'bg-peach-300/20 border-peach-300',
    };

    const bannerStyles = classNames(
        'w-full p-2.5 xs:p-3 sm:p-4 rounded-2 border-1 flex gap-2.5',
        bannerColorStyles[color],
        { 'cursor-pointer': !!onBannerClick }
    );

    return (
        <div className="bg-white rounded-2">
            <div className={bannerStyles} onClick={onBannerClick ? onBannerClick : () => {}}>
                <div className="w-8 h-8 min-w-[32px] rounded-2 bg-white flex items-center justify-center">
                    <SvgIcon color={iconColor[color]} icon={InformationLineIcon} size="large" />
                </div>
                <div>
                    <p className="font-body-bold text-body-bold-s text-neutral-500 mb-1">{title}</p>
                    <p className="font-body text-body-regular-s text-neutral-500">{text}</p>
                </div>
            </div>
        </div>
    );
}

export default Banner;
