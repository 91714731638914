import axios from 'axios';

// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// function to get the auth token from local storage
const getAuthToken = () => {
    return localStorage.getItem('authKey');
};

// if environment is production, use the production API
let baseUrl;
if (process.env.NODE_ENV === 'production') {
    // TODO: figure out how to get environment variable in production
    baseUrl = '/api/operate/';
} else {
    baseUrl = 'http://127.0.0.1:8000/api/operate/';
}

// const baseUrl = process.env.REACT_APP_BACKEND_URL + '/operate/';

const operateClient = axios.create({
    baseURL: baseUrl,
});

// Using request interceptor to set the auth token before each request (so that it loads for every request)
operateClient.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
});

export default operateClient;
