import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import client from '../../../services/user-api';
import useUser from '../../../hooks/useUser';

import Alert from '../../../design-system/Alert/Alert';
import ProviderCard from '../OrganizationDetailPage/ProviderCard/ProviderCard';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import OrgConnectionActionModal from '../OrganizationDetailPage/OrgConnectionActionModal/OrgConnectionActionModal';

const OAuthConnectionsBlock = () => {
    const { user } = useUser();
    const orgId = user?.organization?.id;

    const [searchParams] = useSearchParams();
    const [oauthConnectionData, setOauthConnectionData] = useState(null);

    const [connectionActionModal, setConnectionActionModal] = useState({
        opened: false,
        data: null,
        action: 'view',
        provider: null,
    });
    const [successAlertMessage, setSuccessAlertMessage] = useState(null);

    let baseUrl;
    if (process.env.NODE_ENV === 'production') {
        // TODO: replace with production API URL
        baseUrl = '/api/user/';
    } else {
        baseUrl = 'http://127.0.0.1:8000/api/user/';
    }

    useEffect(() => {
        // Check for existing GitHub credentials
        const checkGithubCredentials = async () => {
            try {
                const { data } = await client.get('api-credentials/?provider=github');
                if (data && data.results.length > 0) {
                    // If credentials exist, set the first item to the Provider Card data
                    setOauthConnectionData(data.results[0]);
                }
            } catch (error) {
                console.error('Error checking GitHub credentials:', error);
            }
        };

        // Handle OAuth callback
        const handleOAuthCallback = async () => {
            const type = searchParams.get('type');
            const status = searchParams.get('status');
            const code = searchParams.get('code');
            if (type === 'github_oauth_callback' && status === 'success' && code) {
                try {
                    // // Handle success (e.g., navigate, update state)
                    const { data } = await client.get(
                        `oauth/callback/?type=${type}&status=${status}&code=${code}`
                    );
                    console.log('OAuth callback response:', data);
                    // Set the OAuth connection data received from the backend
                    setOauthConnectionData(data);
                } catch (error) {
                    console.error('Error handling OAuth callback:', error);
                }
            }
        };

        handleOAuthCallback();
        checkGithubCredentials();
    }, [searchParams]);

    return (
        <SectionWrapper title="OAuth Connections">
            <div className="flex flex-col space-y-5 w-full max-w-[444px]">
                <div className="w-full max-w-[400px]">
                    <ProviderCard
                        provider="github"
                        connectionData={oauthConnectionData}
                        hasCreateAccess={true}
                        comingSoon={false}
                        oauthUrl={`${baseUrl}oauth/redirect/?provider=github`}
                        setConnectionActionModal={setConnectionActionModal}
                    />
                </div>
            </div>

            {connectionActionModal.opened && (
                <OrgConnectionActionModal
                    orgId={orgId}
                    data={connectionActionModal.data}
                    provider={connectionActionModal.provider}
                    action="view"
                    setSuccessAlertMessage={setSuccessAlertMessage}
                    onClose={() => setConnectionActionModal({ opened: false })}
                />
            )}
            {successAlertMessage && (
                <Alert
                    status="positive"
                    icon={CheckLineIcon}
                    message={successAlertMessage}
                    autoCloseInMS={3000}
                    handleClose={() => setSuccessAlertMessage(null)}
                />
            )}
        </SectionWrapper>
    );
};

export default OAuthConnectionsBlock;
