import React from 'react';

import { TASK_TYPES } from '../../../../constants/library';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';
import { checkAndUpdateUserInputsIfUsed } from '../../../../helpers/taskPlaygroundUtils';
import { useTaskPlaygroundMessagesHeight } from '../../../../hooks/useTaskPlaygroundMessagesHeight';

import NewTextAreaBox from '../../../../design-system/NewTextAreaBox/NewTextAreaBox';

const roles = ['system', 'user'];

const AgentMessagesBlock = ({
    messages,
    setChangedTaskFields,
    setUserInputs,
    setChangeableData,
}) => {
    const {
        containerRef,
        systemMessageRef,
        systemMessageHeight,
        scrollInsideFirstMessage,
        isDesktop,
    } = useTaskPlaygroundMessagesHeight({ messages });

    if (!messages) {
        return <></>;
    }

    const [systemMessage, userMessage] = messages;

    const handleMessageChange = (e) => {
        const { value, name: currentRole } = e.target;

        setChangeableData((prevData) => ({
            ...prevData,
            messages: prevData.messages.map((item) =>
                item.role === currentRole ? { role: currentRole, content: value } : item
            ),
        }));
        setChangedTaskFields((prevFields) => addUniqueElementToArray('messages', prevFields));

        const roleMessage = {
            system: systemMessage.content,
            user: userMessage.content,
        };

        const messagesArray = roles.map((role) =>
            role === currentRole ? value : roleMessage[role]
        );
        checkAndUpdateUserInputsIfUsed({
            dataArray: messagesArray,
            setUserInputs,
            taskType: TASK_TYPES.function,
        });
    };

    return (
        <div
            className="flex-grow w-full flex flex-col gap-5 max-lg:min-h-[460px]"
            ref={containerRef}
        >
            <div
                className="w-full flex flex-col max-lg:min-h-[240px]"
                ref={systemMessageRef}
                style={{ height: systemMessageHeight }}
            >
                <div className="flex-grow w-full flex flex-col">
                    <NewTextAreaBox
                        name="system"
                        value={systemMessage.content}
                        onChange={handleMessageChange}
                        label="System Message"
                        placeholder="Write here"
                        state="default"
                        withCopyButton
                        fullHeight={scrollInsideFirstMessage && isDesktop}
                        scrollInsideTextArea={scrollInsideFirstMessage && isDesktop}
                    />
                </div>
            </div>
            <div className="flex-grow w-full flex flex-col min-h-[240px]">
                <NewTextAreaBox
                    name="user"
                    value={userMessage.content}
                    onChange={handleMessageChange}
                    label="User Message"
                    placeholder="Write here"
                    state="default"
                    withCopyButton
                    fullHeight
                    scrollInsideTextArea={isDesktop}
                />
            </div>
        </div>
    );
};

export default AgentMessagesBlock;
