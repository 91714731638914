import React, { useState } from 'react';
import { useFormState } from '../../../../hooks/useFormState';

import { API } from 'constants';
import client from '../../../../services/library-api';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';
import { checkIfKeyIsValid } from '../../../../helpers/checkIfKeyIsValid';

import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import Alert from '../../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import { Button, Input, Select } from '../../../../design-system';

const title = {
    create: 'Add New Process Variable',
    edit: 'Edit Process Variable',
};
const typeSelectOptions = [
    { id: 'string', name: 'String' },
    { id: 'number', name: 'Number' },
    { id: 'array', name: 'Array' },
    { id: 'object', name: 'Object' },
];

const ProcessVariableModal = ({ mode, processId, variableData, outputs, setOutputs, onClose }) => {
    const initialFormState =
        mode === 'edit'
            ? { key: variableData.key, type: variableData.type, label: variableData.label }
            : { key: '', type: null, label: '' };

    const { formData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } =
        useFormState(initialFormState);
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const getRequestBody = () => {
        if (mode === 'create') {
            return { outputs: [...outputs, { ...formData, is_required: false }] };
        }
        if (mode === 'edit') {
            return {
                outputs: outputs.map((output) =>
                    output.id === variableData.id ? { ...output, ...formData } : output
                ),
            };
        }
    };

    const handleSave = async () => {
        const areEmptyFields = !formData.key || !formData.label || !formData.type;
        if (areEmptyFields) {
            setFieldErrorMessages({
                key: !formData.key ? emptyFieldErrorMessage : null,
                label: !formData.label ? emptyFieldErrorMessage : null,
                type: !formData.type ? 'Select a type.' : null,
            });
            return;
        }

        const isKeyValid = checkIfKeyIsValid(formData.key);
        if (!isKeyValid) {
            setFieldErrorMessages({ key: 'Input key needs to be all lowercase with no spaces.' });
            return;
        }

        try {
            setIsLoading(true);
            const requestBody = getRequestBody();
            const { data } = await client.patch(
                `${API.ROUTES.library.process}${processId}/`,
                requestBody
            );
            setOutputs(data.outputs);
            onClose();
        } catch (e) {
            setIsLoading(false);
            const errorMessage = e.response?.data?.error;
            if (errorMessage) {
                setErrorAlert({ message: errorMessage });
            } else {
                setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
            }
        }
    };

    // used to increase the height of the modal so that the dropdown fits entirely on the screen
    const selectMarginBottom = isDropdownOpened ? 82 : 0;

    return (
        <Modal onClose={onClose} size="medium">
            <div className="flex flex-col gap-8">
                <ModalHeader title={title[mode]} size="sm" onClose={onClose} />

                <div
                    className="flex flex-col gap-5"
                    style={{
                        marginBottom: selectMarginBottom,
                        transition: 'margin 0.2s ease-in-out',
                    }}
                >
                    <Input
                        size="md"
                        name="key"
                        value={formData.key}
                        label="Key"
                        isRequired
                        state={fieldErrorMessages.key ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.key}
                        tipText="Input key needs to be all lowercase with no spaces."
                        placeholder="Add variable key"
                        onChange={(e) => handleInputChange('key', e.target.value)}
                    />
                    <Input
                        size="md"
                        name="label"
                        value={formData.label}
                        label="Label"
                        isRequired
                        state={fieldErrorMessages.label ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.label}
                        placeholder="Add variable label"
                        onChange={(e) => handleInputChange('label', e.target.value)}
                    />
                    <Select
                        size="md"
                        name="type"
                        value={formData.type}
                        options={typeSelectOptions}
                        label="Type"
                        isRequired
                        state={fieldErrorMessages.type ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.type}
                        placeholder="Select an option"
                        disableSorting
                        useExternalDropdownState
                        dropdownOpen={isDropdownOpened}
                        setDropdownOpen={setIsDropdownOpened}
                        onChange={(option) => handleInputChange('type', option)}
                    />
                </div>

                <div className="flex items-center justify-between gap-3">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        state={isLoading ? 'loading' : 'default'}
                        text={mode === 'create' ? 'Add New' : 'Save'}
                        onClick={handleSave}
                    />
                </div>
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={CheckLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default ProcessVariableModal;
