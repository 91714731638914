import React, { useMemo } from 'react';

import { DEFAULT_LIMIT } from '../../../../hooks/useCustomPagination';
import { transformTestRunsDataForTable } from '../../../../helpers/formatDatasetsDataForTable';

import Table from '../../../../design-system/Table/Table';
import TestRunMoreOptionsPopup from '../TestRunMoreOptionsPopup/TestRunMoreOptionsPopup';

const TestRunsTableBlock = ({ data, page, total, onPreviousPageClick, onNextPageClick }) => {
    const tableData = useMemo(
        () => transformTestRunsDataForTable(data, page, total),
        [data, page, total]
    );

    const openLangsmithUrlInTheNewTab = (testRun) => {
        window.open(testRun.langsmithUrl, '_blank');
    };

    const rowActionButtons = [
        {
            action: 'visit',
            handleAction: openLangsmithUrlInTheNewTab,
        },
        {
            action: 'moreOptions',
        },
    ];

    return (
        <Table
            data={tableData}
            size="md"
            rowActionButtons={rowActionButtons}
            columnsCount={3}
            shift={450}
            includePagination={total > DEFAULT_LIMIT}
            onPaginationPreviousClick={onPreviousPageClick}
            onPaginationNextClick={onNextPageClick}
            moreOptionPopupComponent={TestRunMoreOptionsPopup}
        />
    );
};

export default TestRunsTableBlock;
