import React, { memo, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatScenarioVersionsForSelectOptions } from '../../../../helpers/scenarioUtils';

import { Button, Select } from '../../../../design-system';
import PlusCircleIcon from '../../../../design-system/Icons/PlusCircleIcon';

const ScenarioVersionSelector = ({
    versions,
    setSelectedVersion,
    isNewVersionButtonEnabled,
    setIsCreateVersionModalOpened,
    isVersionChangeBlocked,
}) => {
    const [searchParams] = useSearchParams();

    const selectedVersionId = searchParams.get('version');

    const versionsOptions = useMemo(
        () => formatScenarioVersionsForSelectOptions(versions),
        [versions]
    );

    const handleVersionSelect = (newId) => {
        if (selectedVersionId === newId) {
            return;
        }

        setSelectedVersion(newId);
    };

    return (
        <div className="flex gap-3 gap-y-2 l:gap-5 flex-col min-[630px]:flex-row min-[630px]:items-center">
            <div className="flex-1 min-[630px]:max-w-[calc(100%-208px)]">
                <Select
                    size="sm"
                    value={selectedVersionId}
                    name="version"
                    label="Scenario Version"
                    options={versionsOptions}
                    onChange={handleVersionSelect}
                    tipText="Select a draft version to make edits"
                    includeClientSideFiltering
                    dropdownHeight={280}
                    disableSorting
                    filterSearchBarPlaceholder="Search..."
                    areOptionsDisabled={isVersionChangeBlocked}
                />
            </div>

            <div>
                <Button
                    size="md"
                    type="primary"
                    text="New Version"
                    leadingIcon={PlusCircleIcon}
                    state={!isNewVersionButtonEnabled ? 'disabled' : 'default'}
                    onClick={() => setIsCreateVersionModalOpened(true)}
                />
            </div>
        </div>
    );
};

export default memo(ScenarioVersionSelector);
