import React from 'react';
import classNames from 'classnames';

const SectionWrapper = ({ title, customStyles, children }) => {
    const titleClassName = classNames(
        'font-body-bold text-body-bold-l text-neutral-500 mb-3',
        customStyles
    );

    return (
        <div className="pt-4 px-5 pb-5 md:pb-8 md:px-8 rounded-2xl w-full bg-white">
            <h2 className={titleClassName}>{title}</h2>

            {children}
        </div>
    );
};

export default SectionWrapper;
