import React, { useState } from 'react';

import { $createHeadingNode } from '@lexical/rich-text';
import { useCellValue, usePublisher } from '@mdxeditor/gurx';
import { $createParagraphNode } from 'lexical';

import { convertSelectionToNode$, currentBlockType$ } from '@mdxeditor/editor';

import { ListOptionGroup, SvgIcon } from '../../../index';
import { ArrowDownSLineIcon } from '../../../Icons';
import TextIcon from '../../../Icons/TextIcon';
import H1Icon from '../../../Icons/H1Icon';
import H2Icon from '../../../Icons/H2Icon';
import H3Icon from '../../../Icons/H3Icon';

const data = [
    { type: 'h1', label: 'Heading 1', icon: H1Icon },
    { type: 'h2', label: 'Heading 2', icon: H2Icon },
    { type: 'h3', label: 'Heading 3', icon: H3Icon },
];

const ToolbarHeadings = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const convertSelectionToNode = usePublisher(convertSelectionToNode$);
    const currentBlockType = useCellValue(currentBlockType$);

    const onClose = (e) => {
        e?.stopPropagation();
        setIsPopupOpen(false);
    };

    return (
        <div className="relative">
            <button
                className="flex p-[6px] hover:bg-neutral-50"
                onClick={() => setIsPopupOpen(true)}
            >
                <SvgIcon color="#1F2125" icon={TextIcon} size="medium" />
                <SvgIcon color="#1F2125" icon={ArrowDownSLineIcon} size="medium" />
            </button>
            {isPopupOpen && (
                <div className="absolute -left-1 z-30 top-[110%]">
                    <ListOptionGroup
                        additionalStyles="!p-2"
                        fixedWidth={170}
                        listOptions={
                            <>
                                {data.map((item) => (
                                    <button
                                        key={item.type}
                                        onClick={() => {
                                            if (currentBlockType === item.type) {
                                                convertSelectionToNode(() =>
                                                    $createParagraphNode()
                                                );
                                            } else {
                                                convertSelectionToNode(() =>
                                                    $createHeadingNode(item.type)
                                                );
                                            }
                                            onClose();
                                        }}
                                        className="flex items-center gap-2 px-2 py-[5px] hover:bg-neutral-50 w-full rounded-2"
                                    >
                                        <SvgIcon color="#1F2125" icon={item.icon} size="medium" />
                                        <p className="text-body-regular-xs text-neutral-500 pt-[1px]">
                                            {item.label}
                                        </p>
                                    </button>
                                ))}
                            </>
                        }
                        handleClose={onClose}
                    />
                </div>
            )}
        </div>
    );
};

export default ToolbarHeadings;
