import React, { useEffect, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import { useFormState } from '../../../../hooks/useFormState';
import { emptyFieldErrorMessage } from '../../../../constants/errorMessages';
import { countMarkdownTextWords } from '../../../../helpers/countMarkdownTextWords';
import { normalizeMarkdown } from '../../../../helpers/normalizeMarkdown';

import { Button } from '../../../../design-system';
import DocDetailHeader from '../DocDetailHeader/DocDetailHeader';
import SaveIcon from '../../../../design-system/Icons/SaveIcon';
import NavigationGuard from '../../../../components/NavigationGuard/NavigationGuard';
import ConfirmNavigationModalFromDocDetail from '../ConfirmNavigationModalFromDocDetail/ConfirmNavigationModalFromDocDetail';
import NewRichTextArea from '../../../../design-system/NewRichTextArea/NewRichTextArea';

const DocDetailMainContent = ({ docDetail, setDocDetail, docsRequestKeysToMutate }) => {
    const { id, default_version } = docDetail;
    const { value = '' } = default_version || {};

    const [wordCount, setWordCount] = useState(default_version?.word_count || 0);

    const [areDocContentChanges, setAreDocContentChanges] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { value: value ?? '' }
    );

    useEffect(() => {
        const areChanges = formData.value !== (value ?? '');

        if (areDocContentChanges !== areChanges) {
            setAreDocContentChanges(areChanges);
        }
    }, [formData.value, value]);

    const handleSave = async () => {
        try {
            if (!formData.value) {
                setFieldErrorMessages({ value: emptyFieldErrorMessage });
                return;
            }

            setIsSaveLoading(true);

            const { data } = await client.patch(`${API.ROUTES.knowledge.document}${id}/`, {
                value: normalizeMarkdown(formData.value),
            });
            setDocDetail((prevData) => ({
                ...prevData,
                default_version: data.default_version,
            }));
            handleInputChange('value', data.default_version?.value);
            setWordCount(data.default_version?.word_count);

            setIsSaveLoading(false);
        } catch (e) {
            setIsSaveLoading(false);
        }
    };

    const handleTextAreaChange = (value) => {
        handleInputChange('value', value);

        const updatedWordCount = countMarkdownTextWords(value);
        if (wordCount !== updatedWordCount) {
            setWordCount(updatedWordCount);
        }
    };

    const shouldRestrictNavigation = areDocContentChanges; // when are unsaved changes

    const saveButtonState = !areDocContentChanges
        ? 'disabled'
        : isSaveLoading
        ? 'loading'
        : 'default';

    return (
        <div className="w-full flex-grow max-w-[800px] mx-auto flex flex-col gap-3 xs:gap-6">
            <DocDetailHeader
                docDetail={docDetail}
                setDocDetail={setDocDetail}
                docsRequestKeysToMutate={docsRequestKeysToMutate}
            />

            <div className="flex-grow flex flex-col gap-2 pb-3 xs:pb-[18px] sm:pb-3.5 lg:pb-7 min-[1190px]:pb-0">
                <NewRichTextArea
                    value={formData.value}
                    setValue={handleTextAreaChange}
                    state={fieldErrorMessages.value ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.value}
                    minHeight={300}
                />

                <div className="flex lg:justify-end">
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        {wordCount} {wordCount === 1 ? 'word' : 'words'}
                    </p>
                </div>
            </div>

            <div className="fixed bottom-0 right-2 xs:bottom-1 sm:bottom-2 sm:right-5 z-10 p-2">
                <Button
                    type="secondary"
                    size="sm"
                    text="Save"
                    leadingIcon={SaveIcon}
                    state={saveButtonState}
                    onClick={handleSave}
                />
            </div>

            <NavigationGuard
                when={shouldRestrictNavigation}
                promptMessage="You have unsaved changes, are you sure you’d like to continue? This will discard your unsaved changes."
                customNavigationBlockerMessageSegment="You have unsaved edits."
                customConfirmNavigationModal={ConfirmNavigationModalFromDocDetail}
            />
        </div>
    );
};

export default DocDetailMainContent;
