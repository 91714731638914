import React, { useEffect, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/template-api';
import { useFormState } from '../../../hooks/useFormState';
import { collectionColorOptions } from '../../../constants/collections';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../constants/errorMessages';

import { Button, Input, TextArea } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import ColorPicker from '../../../design-system/ColorPicker/ColorPicker';
import Modal from '../../../design-system/Modal/Modal';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import FormFieldWrapper from '../../../design-system/FormFieldWrapper/FormFieldWrapper';
import Alert from '../../../design-system/Alert/Alert';
import { mutateTemplatesLibraryDataAfterEditingLabel } from '../../../helpers/templatesUtils';

const modalTitle = {
    create: 'Add New Label',
    edit: 'Edit  Label',
};
const confirmButtonText = {
    create: 'Add Label',
    edit: 'Save Label',
};

const LabelActionModal = ({
    mode = 'create',
    labelData,
    onClose,
    setLabels,
    setSelectedLabels,
    libraryRequestKeysToMutate,
}) => {
    const { formData, setFormData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } =
        useFormState({
            name: '',
            description: '',
            icon_color: mode === 'create' ? collectionColorOptions[0] : null,
        });

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);

    useEffect(() => {
        if (mode === 'edit' && labelData) {
            const { name, description, icon_color } = labelData;
            setFormData({ name, description, icon_color });
        }
    }, [mode, labelData]);

    const createLabel = async () => {
        const { data } = await client.post(API.ROUTES.template.label, formData);
        setLabels((prevState) => [...prevState, data]);
    };

    const updateCurrentStateAfterEditingLabel = (prevState, data) =>
        prevState?.map((label) => {
            if (label.id === data.id) {
                return data;
            }
            return label;
        });

    const editLabel = async () => {
        const { data } = await client.patch(
            `${API.ROUTES.template.label}${labelData.id}/`,
            formData
        );
        // update corresponding label in all labels list
        setLabels((prevState) => updateCurrentStateAfterEditingLabel(prevState, data));
        // update corresponding label in selected labels list
        setSelectedLabels((prevState) => updateCurrentStateAfterEditingLabel(prevState, data));
        mutateTemplatesLibraryDataAfterEditingLabel(
            libraryRequestKeysToMutate,
            labelData.id,
            formData
        );
    };

    const actionHandlers = {
        create: createLabel,
        edit: editLabel,
    };

    const handleAction = async () => {
        const areEmptyFields = !formData.name || !formData.description || !formData.icon_color;
        if (areEmptyFields) {
            setFieldErrorMessages({
                name: !formData.name ? emptyFieldErrorMessage : null,
                description: !formData.description ? emptyFieldErrorMessage : null,
                icon_color: !formData.icon_color ? 'Please select a label color' : null,
            });
            return;
        }

        try {
            setIsLoading(true);
            await actionHandlers[mode]();
            setIsLoading(false);
            onClose();
        } catch (e) {
            setIsLoading(false);
            const nameFieldError = e.response?.data?.name?.[0];
            if (nameFieldError) {
                setFieldErrorMessages((prevMessages) => ({
                    ...prevMessages,
                    name: nameFieldError,
                }));
            } else {
                setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
            }
        }
    };

    return (
        <Modal size="midSize" onClose={onClose} higherZIndex>
            <div className="flex flex-col gap-8 sm:p-2">
                <ModalHeader title={modalTitle[mode]} onClose={onClose} />
                <div className="flex flex-col gap-4">
                    <Input
                        size="xs"
                        name="name"
                        value={formData.name}
                        label="Label Name"
                        isRequired
                        placeholder="Give this label a unique name"
                        state={fieldErrorMessages.name ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.name}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                    />
                    <TextArea
                        name="description"
                        value={formData.description}
                        label="Label Description"
                        isRequired
                        state={fieldErrorMessages.description ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.description}
                        placeholder="Describe what this label holds"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    <FormFieldWrapper
                        label="Label color"
                        isRequired
                        state={fieldErrorMessages.icon_color ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.icon_color}
                        gap={8}
                    >
                        <ColorPicker
                            options={collectionColorOptions}
                            selected={formData.icon_color}
                            handleSelect={(color) => handleInputChange('icon_color', color)}
                        />
                    </FormFieldWrapper>
                </div>

                <div className="flex items-center justify-between">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="sm"
                        text={confirmButtonText[mode]}
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleAction}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default LabelActionModal;
