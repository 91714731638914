import { TASK_TYPES } from '../constants/library';
import { checkIfVariableIsInCode } from './checkIfVariableIsInCode';
import { isValidJson } from './isValidJson';
import { invalidJsonErrorMessage } from '../constants/errorMessages';

export function checkIfInputMissedInMessagesArray(messages = [], inputKey) {
    return messages?.every((message) => !message?.includes(`[${inputKey}]`));
}

export function checkIfInputWithDoubleCurlyBracesMissed(apiRequest = [], inputKey) {
    return apiRequest?.every((item) => !JSON.stringify(item)?.includes(`{{${inputKey}}}`));
}

export function checkIfInputMissedInCode(array = [], inputKey) {
    return array?.every((code) => !checkIfVariableIsInCode(inputKey, code));
}

export const MISSED_INPUTS_CHECKING_FUNC = {
    [TASK_TYPES.assistant]: checkIfInputMissedInMessagesArray,
    [TASK_TYPES.function]: checkIfInputMissedInMessagesArray,
    [TASK_TYPES.code]: checkIfInputMissedInCode,
    [TASK_TYPES.plot]: checkIfInputWithDoubleCurlyBracesMissed,
    [TASK_TYPES.api]: checkIfInputWithDoubleCurlyBracesMissed,
};

export const checkAndUpdateUserInputsIfUsed = ({ dataArray = [], setUserInputs, taskType }) => {
    setUserInputs((prevData) => {
        return prevData.map((item) => ({
            ...item,
            inputs: item.inputs.map((input) => {
                const func =
                    MISSED_INPUTS_CHECKING_FUNC[taskType] || checkIfInputMissedInMessagesArray;
                const isUnused = func(dataArray, input.key);

                return { ...input, isUnused };
            }),
        }));
    });
};

export const removeRedundantFieldsFromInput = (input) => {
    const { state, value, errorMessage, isUnused, ...rest } = input;
    return rest;
};
export const removeRedundantFieldsFromOutput = (output) => {
    const { value, value_history, ...rest } = output;
    return rest;
};

export const getInputInitialValue = (input) => {
    const { action_type, type } = input;
    return !action_type ? (type === 'object' ? {} : type === 'array' ? [] : '') : '';
};

export const formatInputValue = (input) => {
    const { type, action_type, value } = input;

    if (type === 'number') {
        return parseFloat(value);
    }

    if ((type === 'object' || type === 'array') && typeof value === 'string' && !action_type) {
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }

    return value;
};

const checkIfInputFilled = (input) => {
    const { type, action_type, value, is_required } = input;

    if (!is_required) {
        return true;
    }

    if ((type === 'object' || type === 'array') && !action_type) {
        if (typeof value === 'string') {
            const parsedValue = JSON.parse(value);
            const isFilled = !!Object.keys(parsedValue)?.length;
            return isFilled;
        }
        return !!Object.keys(value)?.length;
    }

    return input.value !== '' && input.value !== undefined && input.value !== null;
};

export const areInputsValid = (inputs) => {
    const { areErrorFields, updatedFormData } = inputs?.reduce(
        (acc, item) => {
            const { type, action_type, value } = item;
            const shouldCheckJson =
                (type === 'object' || type === 'array') &&
                typeof value === 'string' &&
                !action_type;

            if (shouldCheckJson) {
                const isJsonValueValid = isValidJson(value);
                if (!isJsonValueValid) {
                    return {
                        areErrorFields: true,
                        updatedFormData: [
                            ...acc.updatedFormData,
                            {
                                ...item,
                                state: 'error',
                                errorMessage: invalidJsonErrorMessage,
                            },
                        ],
                    };
                }
            }

            const isInputFilled = checkIfInputFilled(item);
            if (isInputFilled) {
                return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
            } else {
                return {
                    areErrorFields: true,
                    updatedFormData: [...acc.updatedFormData, item],
                };
            }
        },
        { areErrorFields: false, updatedFormData: [] }
    );

    return { areErrorFields, updatedFormData };
};
