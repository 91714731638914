import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CheckboxCircleLineIcon } from '../../../design-system/Icons';
import { Button } from '../../../design-system';

const SuccessfulSubmitBlock = ({ onClose }) => {
    const { goalTemplateId } = useParams();

    const navigate = useNavigate();

    return (
        <div className="py-[60px] sm:py-[80px] flex flex-col items-center px-3 sm:px-5">
            <div className="w-[80px] h-[80px] flex items-center justify-center bg-lime-300 rounded-full mb-4">
                <CheckboxCircleLineIcon width={32} height={32} color="#1A1A1A" />
            </div>
            <p className="font-body text-body-regular-s text-neutral-300 uppercase mb-1">Success</p>
            <p className="font-heading-bold text-heading-bold-m text-black mb-2 text-center">
                Test Run has been started!
            </p>
            <p className="font-body text-body-regular-m text-black text-center mb-5 max-w-[340px]">
                Start another Test Run for this Template or Go to all Test Runs.
            </p>
            <div className="flex flex-col gap-5 items-center">
                <Button
                    type="secondary"
                    size="md"
                    text="Start another Test Run"
                    onClick={onClose}
                />
                <Button
                    type="link"
                    size="md"
                    text="Go to All Test Runs"
                    onClick={() => navigate(`/templates/goal/${goalTemplateId}/tests`)}
                />
            </div>
        </div>
    );
};

export default SuccessfulSubmitBlock;
