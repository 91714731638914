import React from 'react';
import { PROVIDERS, PROVIDERS_LABEL } from '../../../../constants/organizationConnections';
import MultiSelect from '../../../../design-system/MultiSelect/MultiSelect';

export const providersOptions = Object.keys(PROVIDERS).map((key) => ({
    id: PROVIDERS[key],
    name: PROVIDERS_LABEL[key],
}));

const ConnectionsTabForm = ({
    formStateHookResponse,
    fetchProcessTemplatesOptionsHookResponse,
    connectedProcesses,
}) => {
    const { formData, fieldErrorMessages, handleAddNewOption, handleRemoveOption } =
        formStateHookResponse;

    const {
        options: publishedProcessTemplateOptions,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions,
    } = fetchProcessTemplatesOptionsHookResponse;

    const formattedConnectedProcesses =
        connectedProcesses?.map(({ id, name }) => ({ id, name })) || [];

    return (
        <div className="flex flex-col gap-5">
            <MultiSelect
                name="connected_providers"
                addedOptions={formData.connected_providers}
                optionsForSelect={providersOptions}
                label="3rd Party Tools"
                state={fieldErrorMessages.connected_providers ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.connected_providers}
                handleAddNewOption={(option) => handleAddNewOption('connected_providers', option)}
                handleRemoveOption={(option) => handleRemoveOption('connected_providers', option)}
                includeClientSideFiltering
            />
            <MultiSelect
                name="connected_processes"
                addedOptions={formData.connected_processes}
                optionsForSelect={publishedProcessTemplateOptions}
                optionsLoading={optionsLoading}
                label="Connected Processes"
                state={fieldErrorMessages.connected_processes ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.connected_processes}
                handleAddNewOption={(option) => handleAddNewOption('connected_processes', option)}
                handleRemoveOption={(option) => handleRemoveOption('connected_processes', option)}
                includeClientSideFiltering
                usePaginatedSelect
                canLoadMore={canLoadMoreOptions}
                totalOptionsCount={totalOptions}
                fetchOptions={() => setPage((page) => page + 1)}
                initiallyAddedOptionsInfoIfPaginatedSelect={formattedConnectedProcesses}
            />
        </div>
    );
};

export default ConnectionsTabForm;
