import React from 'react';
import { ListOption, ListOptionGroup } from '../../../design-system';
import { useNavigate } from 'react-router-dom';

const WorkerMoreOptionsDropdown = ({
    onClose,
    processId,
    goalId,
    openRestartSidePanel,
    openCancelConfirmationModal,
    openAddToDatasetModal,
}) => {
    const navigate = useNavigate();

    const handleViewGoal = () => {
        navigate(`/configure/process/${processId}/goal/${goalId}`);
    };

    const handleAction = (cb) => {
        cb();
        onClose();
    };

    return (
        <div className="absolute top-[39px] right-[-9px] z-55">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="sharedWithIcon"
                            text="View Goal"
                            onClick={handleViewGoal}
                        />
                        <ListOption
                            leadingIconName="plusCircleIcon"
                            text="Add to Dataset"
                            onClick={() => handleAction(openAddToDatasetModal)}
                        />
                        <ListOption
                            leadingIconName="skipBackIcon"
                            text="Restart Worker"
                            onClick={() => handleAction(openRestartSidePanel)}
                        />
                        <ListOption
                            leadingIconName="squareIcon"
                            text="Cancel Worker"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={() => handleAction(openCancelConfirmationModal)}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default WorkerMoreOptionsDropdown;
