import React from 'react';
import classNames from 'classnames';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import { ButtonIcon } from '../../design-system';
import { FullscreenLineIcon, More2FillIcon } from '../../design-system/Icons';
import Badge from '../../design-system/Badge/Badge';

const ListViewCard = ({
    title,
    description,
    iconText,
    iconColor = '#F0F5FE',
    showMoreOpened,
    showMoreOptionsList,
    onShowMoreButtonClick,
    showFullScreenButton,
    openPanelOnClick,
    onFullScreenButtonClick,
    metaTagLabel,
    metaTagText,
    badge,
    bottomContent,
    withShowMoreButton = true,
}) => {
    const cardContentWidth = classNames({
        'w-[calc(100%-379px)] sm:max-ql:w-[calc(100%-232px)] lg:max-[1168px]:w-[calc(100%-232px)]':
            showFullScreenButton && withShowMoreButton,
        'w-[calc(100%-339px)] sm:max-ql:w-[calc(100%-192px)] lg:max-[1168px]:w-[calc(100%-192px)]':
            (!showFullScreenButton && withShowMoreButton) ||
            (!withShowMoreButton && showFullScreenButton),
        'w-[calc(100%-299px)] sm:max-ql:w-[calc(100%-152px)] lg:max-[1168px]:w-[calc(100%-152px)]':
            !showFullScreenButton && !withShowMoreButton,
    });

    const cardTagsContainerClassName = `flex gap-3 items-center justify-end sm:max-ql:flex-wrap sm:max-ql:w-[140px] ${
        showFullScreenButton ? ' lg:max-[1168px]:flex-wrap lg:max-[1168px]:w-[140px]' : ''
    }`;

    return (
        <div
            className="w-full px-4 py-3 flex justify-between gap-3 items-center bg-white rounded-2 border-1 border-neutral-100"
            onClick={openPanelOnClick || (() => {})}
        >
            <div className={cardContentWidth}>
                <div className="flex items-center gap-2 mb-0.5">
                    {iconText && (
                        <div
                            className={`w-[30px] min-w-[30px] h-[30px] rounded-2 flex items-center justify-center`}
                            style={{ backgroundColor: iconColor }}
                        >
                            <Emoji unified={iconText} emojiStyle={EmojiStyle.APPLE} size={18} />
                        </div>
                    )}
                    <p className="font-body text-body-bold-s text-black truncate w-[calc(100%-38px)]">
                        {title}
                    </p>
                </div>
                <p className="w-full font-body text-body-regular-xs text-nutral-300 truncate mb-2">
                    {description}
                </p>
                <div className="max-w-full">{bottomContent}</div>
            </div>
            <div className="flex gap-3 items-center">
                <div className={cardTagsContainerClassName}>
                    {badge && (
                        <div className="w-[135px] min-w-[135px] flex justify-end">
                            <Badge
                                leadingIcon={badge.icon}
                                text={badge.label}
                                color={badge.color || 'neutral'}
                                leadingIconColor={badge.iconColor}
                            />
                        </div>
                    )}
                    {metaTagLabel && (
                        <div className="w-[140px] min-w-[140px] flex sm:max-ql:justify-end lg:max-[1168px]:justify-end">
                            <div className="font-body text-body-regular-xs text-neutral-300 truncate">
                                {metaTagLabel}{' '}
                                <span className="text-body-bold-xs text-neutral-500">
                                    {metaTagText}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                {showFullScreenButton && (
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={FullscreenLineIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            onFullScreenButtonClick();
                        }}
                    />
                )}
                {withShowMoreButton && (
                    <div className="relative">
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={More2FillIcon}
                            onClick={(e) => {
                                e.stopPropagation();
                                onShowMoreButtonClick();
                            }}
                        />
                        {showMoreOpened && <>{showMoreOptionsList}</>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ListViewCard;
