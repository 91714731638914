import { useMediaQuery } from './useMediaQuery';

const DEFAULT_BREAKPOINTS = {
    maxMobileWidth: 768,
    minDesktopWidth: 1024,
};

export const useResponsiveBreakpoints = ({
    maxMobileWidth = DEFAULT_BREAKPOINTS.maxMobileWidth,
    minDesktopWidth = DEFAULT_BREAKPOINTS.minDesktopWidth,
} = DEFAULT_BREAKPOINTS) => {
    const isDesktop = useMediaQuery(`(min-width: ${minDesktopWidth}px)`);
    const isTablet = useMediaQuery(`(min-width: ${maxMobileWidth}px)`) && !isDesktop;

    // we consider just Mobile and Desktop
    if (maxMobileWidth === minDesktopWidth) {
        const isMobile = !isDesktop;
        return { isMobile, isDesktop };
    }

    // we consider Mobile, Tablet and Desktop
    const isMobile = !isTablet && !isDesktop;
    return { isMobile, isTablet, isDesktop };
};
