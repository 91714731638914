import React from 'react';
import { TEMPLATE_TYPES } from '../../../../constants/template';

import GoalTemplateMoreOptionsPopup from '../../GoalTemplateMoreOptionsPopup/GoalTemplateMoreOptionsPopup';
import ProcessTemplateMoreOptionsPopup from '../ProcessTemplateMoreOptionsPopup/ProcessTemplateMoreOptionsPopup';

const TemplateMoreOptionPopup = ({
    templateType,
    templateData,
    openConfirmDeletionModal,
    setAlert,
    onClose,
}) => {
    if (templateType === TEMPLATE_TYPES.goal) {
        return (
            <GoalTemplateMoreOptionsPopup
                goalTemplateData={templateData}
                openConfirmDeletionModal={openConfirmDeletionModal}
                setCopyAlert={setAlert}
                popupLocation="detailPage"
                onClose={onClose}
            />
        );
    }

    if (templateType === TEMPLATE_TYPES.process) {
        return (
            <ProcessTemplateMoreOptionsPopup processTemplateData={templateData} onClose={onClose} />
        );
    }

    return <></>;
};

export default TemplateMoreOptionPopup;
