import React, { useState } from 'react';

import DraggableFormField from '../DraggableFormField/DraggableFormField';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import Alert from '../../../../design-system/Alert/Alert';

const NewJobForm = ({
    formData,
    setFormData,
    setFormInputModal,
    setConfirmDeleteModal,
    isEditable,
}) => {
    const [successAlert, setSuccessAlert] = useState(null);

    const handleDuplicateInput = (input) => {
        const { id, order, value, ...inputData } = input;
        // get copied input value
        let copiedValue = value;

        if (Array.isArray(value)) {
            copiedValue = [...value];
        } else if (typeof value === 'object') {
            copiedValue = { ...value };
        }

        setFormData((prevData) => {
            // set duplicated input at the bottom of the list
            const duplicatedInputOrder = prevData.length + 1;
            // Create a temporaryId to use it as a key when mapping over formData (cannot use 'order' because if key={order} drag and drop does not work correctly). This temporaryId will not be sent to the backend!
            const temporaryId = new Date().getTime().toString();
            const duplicatedInput = {
                ...inputData,
                order: duplicatedInputOrder,
                temporaryId,
            };
            if (value) {
                duplicatedInput.value = copiedValue;
            }
            return [...prevData, duplicatedInput];
        });
        setSuccessAlert({ message: 'Input successfully duplicated' });
    };

    const handleDeleteInput = (input) => {
        setConfirmDeleteModal({ isOpened: true, inputData: input });
    };

    const handleEditInput = (input) => {
        setFormInputModal({ isOpened: true, mode: 'edit', inputData: input });
    };

    return (
        <>
            {!!formData?.length && (
                <div className="flex flex-col gap-7">
                    {formData.map((item) => (
                        <DraggableFormField
                            key={item.id || item.temporaryId}
                            input={item}
                            setFormData={setFormData}
                            handleDuplicateInput={handleDuplicateInput}
                            handleDeleteInput={handleDeleteInput}
                            handleEditInput={handleEditInput}
                            isEditable={isEditable}
                        />
                    ))}
                </div>
            )}
            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </>
    );
};

export default NewJobForm;
