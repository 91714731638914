import React, { useState } from 'react';

import * as API from '../../../../constants/api';
import client from '../../../../services/organization-api';

import { useFormState } from '../../../../hooks/useFormState';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Alert from '../../../../design-system/Alert/Alert';
import SectionWrapper from '../../SectionWrapper/SectionWrapper';
import InputWithAutoSaveOnBlur from '../../../../design-system/InputWithAutoSaveOnBlur/InputWithAutoSaveOnBlur';
import ClientAvailableMembersBlock from '../ClientAvailableMembersBlock/ClientAvailableMembersBlock';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const ClientDetailsBlock = ({
    clientOrganizationData,
    isOwnerOrAdmin,
    parentOrganizationName,
    parentOrganizationMembers,
    updateClientData,
}) => {
    const { id, name, available_members } = clientOrganizationData;

    const { formData, handleInputChange } = useFormState({ name });
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSaveName = async () => {
        if (!formData.name) {
            handleInputChange('name', name);
            throw new Error('Organization name cannot be empty');
        }

        try {
            await client.patch(`${API.ROUTES.organization.organization}${id}/`, {
                name: formData.name,
            });
            updateClientData({ clientId: id, name: formData.name });
        } catch (e) {
            handleInputChange('name', name);
            setErrorAlert({ message: defaultErrorMessage });
            throw new Error(e);
        }
    };

    return (
        <SectionWrapper title="Client Details">
            <div className="flex flex-col gap-4">
                <div className="w-full max-w-[444px]">
                    <p className="font-body-bold text-body-bold-s text-neutral-500 mb-2">
                        Client Name
                    </p>

                    <InputWithAutoSaveOnBlur
                        size="xs"
                        name="client_name"
                        value={formData.name}
                        originalValue={name}
                        placeholder="Enter Client Name"
                        isDisabled={!isOwnerOrAdmin}
                        handleSave={handleSaveName}
                        handleInputChange={(e) => handleInputChange('name', e.target.value)}
                    />
                </div>

                <ClientAvailableMembersBlock
                    isOwnerOrAdmin={isOwnerOrAdmin}
                    parentOrganizationMembers={parentOrganizationMembers}
                    parentOrganizationName={parentOrganizationName}
                    availableMembers={available_members}
                    updateClientData={updateClientData}
                />
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </SectionWrapper>
    );
};

export default ClientDetailsBlock;
