import React, { useState } from 'react';
import { copyGoalTemplateConfig } from '../../../../helpers/copyGoalTemplateConfig';
import { ListOption, ListOptionGroup } from '../../../../design-system';
import client from '../../../../services/template-api';
import { templateAPIRoutes } from '../../../../constants/template';

const GoalTemplateSidePanelCardMoreOptionsPopup = ({
    goalTemplateData,
    popupTopPosition,
    setCopyAlert,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    //copy goal template config to clipboard
    const handleCopyGoalTemplateConfig = async () => {
        try {
            setIsLoading(true);
            const { data } = await client.get(`${templateAPIRoutes.goal}${goalTemplateData.id}/`);
            copyGoalTemplateConfig(data, setCopyAlert);

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
        onClose();
    };

    const handleViewGoalTemplate = () => {
        window.open(`/templates/goal/${goalTemplateData.id}`, '_blank');
        onClose();
    };

    return (
        <div className="absolute left-[68%] z-20" style={popupTopPosition}>
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="copyIcon"
                            text="Copy Configuration"
                            isLoading={isLoading}
                            onClick={handleCopyGoalTemplateConfig}
                        />
                        <ListOption
                            leadingIconName="shareBoxLineIcon"
                            text="View Goal Template"
                            onClick={handleViewGoalTemplate}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};
export default GoalTemplateSidePanelCardMoreOptionsPopup;
