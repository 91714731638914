import React from 'react';

import ExtendedPaginatedDropdown from '../../../ExtendedPaginatedDropdown/ExtendedPaginatedDropdown';
import { FlowChartIcon } from '../../../Icons';

const SelectProjectDropdown = ({
    selectedProjectId,
    optionsHookResponse,
    onOptionSelect,
    onClose,
}) => {
    const {
        options: projectOptions,
        optionsLoading,
        totalOptions,
        setPage,
        canLoadMoreOptions,
    } = optionsHookResponse;

    return (
        <ExtendedPaginatedDropdown
            options={projectOptions}
            selectedOptionId={selectedProjectId}
            totalOptions={totalOptions}
            optionsLoading={optionsLoading}
            canLoadMoreOptions={canLoadMoreOptions}
            setOptionsNextPage={setPage}
            onOptionSelect={onOptionSelect}
            onCancel={() => onOptionSelect(null)}
            listLabel="All Projects"
            optionsLeadingIcon={FlowChartIcon}
            optionsLeadingIconColor="#1F2125"
            onClose={onClose}
        />
    );
};
export default SelectProjectDropdown;
