import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../services/template-api';

import ConfirmDeletionModalPattern from '../../../design-system/ConfirmDeletionModalPattern/ConfirmDeletionModalPattern';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { mutateTemplatesLibraryDataAfterDeletingLabel } from '../../../helpers/templatesUtils';

const DeleteLabelModal = ({
    labelData,
    onClose,
    setLabels,
    libraryRequestKeysToMutate,
    setSelectedLabels,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleDelete = async () => {
        const labelIdToDelete = labelData.id;

        try {
            setIsLoading(true);
            await client.delete(`${API.ROUTES.template.label}${labelIdToDelete}/`);
            setLabels((prevLabels) => prevLabels.filter((label) => label.id !== labelIdToDelete));
            setSelectedLabels((prevLabels) =>
                prevLabels?.filter((label) => label.id !== labelIdToDelete)
            );
            mutateTemplatesLibraryDataAfterDeletingLabel(
                libraryRequestKeysToMutate,
                labelIdToDelete
            );
            setIsLoading(false);
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <ConfirmDeletionModalPattern
            title="Delete Label"
            handleDelete={handleDelete}
            onClose={onClose}
            isLoading={isLoading}
            confirmationTextStart="Are you sure you want to delete the"
            confirmationTextEnd="label? This will remove it from all Process Templates."
            entityName={labelData.name}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
        />
    );
};

export default DeleteLabelModal;
