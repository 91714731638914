import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { DateTime } from 'luxon';
import classNames from 'classnames';

import { statusBadge as _statusBadge } from '../../../constants/statuses';

import { More2FillIcon } from '../../../design-system/Icons';
import { Badge, ButtonIcon } from '../../../design-system';
import ProjectCardMoreOptionsPopup from '../ProjectCardMoreOptionsPopup/ProjectCardMoreOptionsPopup';
import RenameProjectModal from '../../JobsAndProjectsPage/RenameProjectModal/RenameProjectModal';
import ConfirmArchiveProjectModal from '../../JobsAndProjectsPage/ConfirmArchiveProjectModal/ConfirmArchiveProjectModal';

const ProjectCard = ({ project, requestKeysToMutate }) => {
    const { id, name, status, updated_at } = project;

    const location = useLocation();

    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const [actionModal, setActionModal] = useState({ action: null });

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');
    const statusBadge = _statusBadge[status];

    const openMoreOptionsPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setIsPopupOpened(true);
    };

    const cardContainerClassName = classNames(
        'px-2 min-[720px]:px-4 py-3.5 max-w-full w-full bg-white border-b-1 border-neutral-200',
        'grid grid-rows-[auto_auto] min-[1024px]:grid-rows-1 grid-cols-[minmax(100px,_1fr)_28px] min-[720px]:grid-cols-[minmax(100px,_1fr)_88px_28px] min-[1024px]:grid-cols-[minmax(100px,_1fr)_96px_234px_28px] gap-x-1.5 gap-y-1.5 min-[720px]:gap-y-2 items-center'
    );

    return (
        <>
            <Link
                key={id}
                to={`/project/${id}`}
                state={{ from: location }}
                className={cardContainerClassName}
            >
                <h3 className="font-body-bold text-body-bold-s text-black truncate">{name}</h3>

                <div className="flex min-[720px]:justify-end row-start-2 row-end-2 min-[720px]:row-start-auto min-[720px]:row-end-auto min-[720px]:col-span-1 pb-0.5 min-[720px]:p-0">
                    <Badge text={statusBadge.text} color={statusBadge.color} />
                </div>

                <div className="flex justify-start min-[1024px]:justify-end row-start-3 row-end-3 col-span-full min-[720px]:row-start-2 min-[720px]:row-end-2 min-[1024px]:row-start-auto min-[1024px]:row-end-auto min-[1024px]:col-span-1">
                    <p className="font-body text-body-regular-xs text-neutral-300 truncate max-w-full">
                        Last Updated{' '}
                        <span className="font-body-bold text-body-bold-xs text-neutral-300">
                            {formattedTime}
                        </span>
                    </p>
                </div>

                <div className="relative">
                    <ButtonIcon
                        icon={More2FillIcon}
                        onClick={openMoreOptionsPopup}
                        type="link"
                        size="xs"
                    />

                    {isPopupOpened && (
                        <ProjectCardMoreOptionsPopup
                            setActionModal={setActionModal}
                            onClose={() => setIsPopupOpened(false)}
                        />
                    )}
                </div>
            </Link>

            {actionModal.action === 'rename' && (
                <RenameProjectModal
                    projectId={id}
                    projectName={name}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setActionModal({ action: null })}
                />
            )}

            {actionModal.action === 'archive' && (
                <ConfirmArchiveProjectModal
                    projectId={id}
                    projectName={name}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setActionModal({ action: null })}
                />
            )}
        </>
    );
};

export default memo(ProjectCard);
