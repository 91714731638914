import React from 'react';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { Tabs } from '../../../../design-system';

const ProcessDetailTopControls = ({ tabs, activeTabIndexState, isProcessEditable }) => {
    const [activeTabIndex, setActiveTabIndex] = activeTabIndexState;

    const { width: screenWidth } = useWindowSize();

    const handleTabChanged = (tab, index) => {
        setActiveTabIndex(index);
    };

    const displayTabsVertical = screenWidth < 390 && !isProcessEditable;

    return (
        <div className={displayTabsVertical ? 'w-full' : `max-w-max`}>
            <Tabs
                tabs={tabs}
                tabIndex={activeTabIndex}
                onTabChanged={handleTabChanged}
                vertical={displayTabsVertical}
            />
        </div>
    );
};

export default ProcessDetailTopControls;
