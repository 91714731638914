import React from 'react';
import BranchAndOrgTestSettingsBlock from '../../TemplatesLibraryPage/BranchAndOrgTestSettingsBlock/BranchAndOrgTestSettingsBlock';

const UnitTestSettingsBlock = ({
    isExpanded,
    toggleExpand,
    versions,
    formData,
    setUnitTestSettingsFormData,
    fieldsErrorMessage,
    setFieldsErrorMessage,
    isOrganizationPreselectLoading,
    orgIdFromPreviousTestRun,
    stopPreselecting,
}) => {
    const { unitTestSettings } = formData;

    return (
        <BranchAndOrgTestSettingsBlock
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
            versions={versions}
            blockFormData={unitTestSettings}
            setBlockFormData={setUnitTestSettingsFormData}
            fieldsErrorMessage={fieldsErrorMessage}
            setFieldsErrorMessage={setFieldsErrorMessage}
            isOrganizationPreselectLoading={isOrganizationPreselectLoading}
            orgIdFromPreviousTestRun={orgIdFromPreviousTestRun}
            stopPreselecting={stopPreselecting}
            blockTitle="UNIT TEST SETTINGS"
            versionSelectLabel="Select Goal Template Branch"
            organizationSelectPlaceholder="Select the organization to run this Unit Test Run in"
        />
    );
};

export default UnitTestSettingsBlock;
