import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/library-api';

import {
    DELIVERY_TYPES,
    deliveryTypeSelectOptions,
    eventListenerSelectOptions,
} from '../../../../constants/events';
import { defaultErrorMessage } from '../../../../constants/errorMessages';
import { urlRegex } from '../../../../constants/regex_patterns';

import Modal from '../../../../design-system/Modal/Modal';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import Tabs from '../../../../design-system/Tabs/Tabs';
import Alert from '../../../../design-system/Alert/Alert';
import { Button, Input, Select } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

// each request has a constant fields and conditional fields based on delivery type
const constantFieldsForRequest = ['name', 'event_listener', 'delivery', 'is_live'];
const conditionalFieldsForRequest = {
    [DELIVERY_TYPES.webhook]: ['webhook_url'],
};

const NotificationEditorModal = ({ mode, eventData, process, setProcess, onClose }) => {
    const [inputsData, setInputsData] = useState(() => {
        if (mode === 'edit') {
            const { id, detail, ...restData } = eventData;
            return { ...restData, ...detail };
        }

        if (mode === 'create') {
            return {
                name: '',
                event_listener: null,
                delivery: null,
                is_live: true,
                webhook_url: '', // for Webhook Delivery Type
            };
        }
    });

    const [errorFields, setErrorFields] = useState({
        name: false,
        event_listener: false,
        delivery: false,
        webhook_url: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({
        show: false,
        message: '',
        statusCode: null,
    });

    const handleInputChange = (e) => {
        const fieldName = e.target.name;
        setInputsData((prevData) => ({ ...prevData, [fieldName]: e.target.value }));

        if (errorFields[fieldName]) {
            setErrorFields((prevFields) => ({ ...prevFields, [fieldName]: false }));
        }
    };

    const handleSelectChange = (value, field) => {
        setInputsData((prevData) => ({ ...prevData, [field]: value }));

        if (errorFields[field]) {
            setErrorFields((prevFields) => ({ ...prevFields, [field]: false }));
        }
    };

    const handleStatusChange = (_, index) => {
        setInputsData((prevData) => ({ ...prevData, is_live: !index }));
    };

    const checkForCorrectEnteredData = () => {
        // there is not "is_live" field in requiredFieldsList because this field is always filled
        const requiredFieldsList = [
            'name',
            'event_listener',
            'delivery',
            ...(conditionalFieldsForRequest[inputsData.delivery] || []),
        ];

        let isWebhookUrlValid = true;
        if (inputsData.delivery === DELIVERY_TYPES.webhook) {
            isWebhookUrlValid = urlRegex.test(inputsData.webhook_url?.trim());
        }

        const areEmptyFields = requiredFieldsList?.some((field) => !inputsData[field]);

        if (areEmptyFields || !isWebhookUrlValid) {
            const emptyFields = requiredFieldsList.reduce(
                (acc, field) => ({
                    ...acc,
                    [field]: !inputsData[field],
                }),
                {}
            );
            emptyFields.webhook_url = !isWebhookUrlValid;
            setErrorFields(emptyFields);
            return false; // data is not correct: means that there are empty fields or url is invalid
        }

        return true; // data is correct: means that there are no empty fields and url is valid
    };

    const getRequestBody = () => {
        const requestBody = constantFieldsForRequest.reduce(
            (acc, field) => ({
                ...acc,
                [field]: inputsData[field],
            }),
            {}
        );

        let detail;
        const conditionalFields = conditionalFieldsForRequest[inputsData.delivery];
        if (conditionalFields) {
            detail = conditionalFields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field]:
                        field === 'webhook_url' ? inputsData[field]?.trim() : inputsData[field],
                }),
                {}
            );
        }

        return detail ? { ...requestBody, detail } : requestBody;
    };

    const handleCreateEvent = async (requestBody) => {
        const { data } = await client.post(
            `${API.ROUTES.library.process}${process.id}/event/`,
            requestBody
        );
        setProcess((prevData) => ({ ...prevData, events: [...prevData.events, data] }));
    };

    const handleEditEvent = async (requestBody) => {
        const { data } = await client.patch(
            `${API.ROUTES.library.process}${process.id}/event/${eventData.id}/`,
            requestBody
        );
        setProcess((prevData) => ({
            ...prevData,
            events: prevData.events?.map((event) => (event.id === eventData.id ? data : event)),
        }));
    };

    const handleAction = {
        create: handleCreateEvent,
        edit: handleEditEvent,
    };

    const handleSaveEvent = async () => {
        const isEnteredDataCorrect = checkForCorrectEnteredData();

        if (!isEnteredDataCorrect) {
            return;
        }

        try {
            setIsLoading(true);
            const requestBody = getRequestBody();
            await handleAction[mode](requestBody);

            setIsLoading(false);
            onClose();
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({
                show: true,
                statusCode: error.response?.status,
                message: defaultErrorMessage,
            });
        }
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="flex flex-col gap-4 mb-6">
                <h2 className="font-heading-bold text-heading-bold-m text-neutral-500">
                    {mode === 'create' && `Add a notification for ${process.name}`}
                    {mode === 'edit' && `Edit ${eventData.name}`}
                </h2>
                <Input
                    size="xs"
                    name="name"
                    value={inputsData.name}
                    label="Name this event"
                    isRequired
                    state={errorFields.name ? 'error' : 'default'}
                    placeholder="Send webhook on thread failure"
                    errorMessage={'Please fill in this field.'}
                    onChange={handleInputChange}
                />
                <Select
                    size="xs"
                    name="event_listener"
                    label="Event trigger"
                    isRequired
                    value={inputsData.event_listener}
                    options={eventListenerSelectOptions}
                    state={errorFields.event_listener ? 'error' : 'default'}
                    errorMessage={'Please select an option.'}
                    onChange={handleSelectChange}
                />
                <Select
                    size="xs"
                    name="delivery"
                    label="Delivery type"
                    isRequired
                    value={inputsData.delivery}
                    options={deliveryTypeSelectOptions}
                    state={errorFields.delivery ? 'error' : 'default'}
                    errorMessage={'Please select an option.'}
                    onChange={handleSelectChange}
                />
                {inputsData.delivery === DELIVERY_TYPES.webhook && (
                    <Input
                        size="xs"
                        name="webhook_url"
                        value={inputsData.webhook_url}
                        label="Webhook URL"
                        isRequired
                        placeholder="https://hook.us1.make.com/..."
                        state={errorFields.webhook_url ? 'error' : 'default'}
                        errorMessage={
                            inputsData.webhook_url
                                ? 'Please check the entered URL as it seems to be invalid.'
                                : 'Please fill in this field.'
                        }
                        tipText="Tip: this should be a unique webhook URL to send data to."
                        onChange={handleInputChange}
                    />
                )}
                <FormFieldWrapper label="Notification status" isRequired>
                    <Tabs
                        tabs={[{ name: 'Active' }, { name: 'Inactive' }]}
                        onTabChanged={handleStatusChange}
                        tabIndex={inputsData.is_live ? 0 : 1}
                    />
                </FormFieldWrapper>
            </div>
            <div className="flex items-center justify-between gap-3">
                <Button type="neutral" size="xs" text="Cancel" onClick={onClose} />
                <Button
                    type="primary"
                    size="xs"
                    text={mode === 'create' ? 'Add Notification' : 'Save Changes'}
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleSaveEvent}
                />
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                />
            )}
        </Modal>
    );
};

export default NotificationEditorModal;
