import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

ImageWithFallback.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    altTextClassName: PropTypes.string,
    onImageErrorChange: PropTypes.func,
};

function ImageWithFallback({
    src,
    alt = 'Image could not be loaded',
    className,
    altTextClassName = 'font-body text-body-regular-m text-neutral-500',
    onImageErrorChange = () => {}, // use onImageErrorChange if additional action should be done after imageError updates
}) {
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        setImageError(false);
    }, [src]);

    useEffect(() => {
        onImageErrorChange(imageError);
    }, [imageError]);

    const handleError = () => {
        setImageError(true);
    };

    return (
        <div>
            {imageError ? (
                <p className={altTextClassName}>{alt}</p>
            ) : (
                <img src={src} alt={alt} onError={handleError} className={className} />
            )}
        </div>
    );
}

export default ImageWithFallback;
