import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../../services/library-api';

import { openLinkInNewTab } from '../../../../helpers/openLinkInNewTab';

import EditLineIcon from '../../../Icons/EditLineIcon';
import RichTextArea from '../../../RichTextArea/RichTextArea';
import GenericBlockLoadingState from '../../GenericBlockLoadingState/GenericBlockLoadingState';
import { Badge, Button, ButtonIcon } from '../../../index';
import { DeleteBin4LineIcon, FlowChartIcon } from '../../../Icons';

const ConfirmedProjectBlock = ({
    isEditable,
    projectId,
    label,
    projectDetails,
    setProjectDetails,
    handleDeleteBlock,
    setIsConfirmedMode,
}) => {
    const { playbookId } = useParams();

    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                const { data } = await client.get(
                    `${API.ROUTES.library.scenario}${projectId}/?shared=true`
                );
                setProjectDetails(projectId, () => data);
            } catch (e) {}
        };

        if (!projectDetails && projectId) {
            fetchProjectDetails();
        }
    }, [projectId, projectDetails]);

    if (!projectDetails) {
        return <GenericBlockLoadingState icon={FlowChartIcon} />;
    }

    const { name, description } = projectDetails;

    const badgeText = (
        <>
            <span className="font-body-bold text-body-bold-xs uppercase">Project:</span> {name}
        </>
    );

    const cardContainerClassName = classNames(
        'flex-1 flex flex-col gap-2.5 border border-neutral-200 rounded-2 p-2.5',
        {
            'max-w-[calc(100%-32px)]': isEditable,
            'max-w-full': !isEditable,
        }
    );

    return (
        <div className="w-full max-w-full flex gap-1 items-start">
            <div className={cardContainerClassName}>
                <div className="max-w-fit">
                    <Badge
                        text={badgeText}
                        color="neutral"
                        leadingIcon={FlowChartIcon}
                        leadingIconColor="#1F2125"
                    />
                </div>

                <div className="flex flex-col-reverse min-[515px]:flex-row min-[515px]:items-center lg:items-start lg:flex-col-reverse xl:flex-row gap-y-2.5 xl:items-center justify-between gap-x-3">
                    <p className="font-body-bold text-body-bold-l text-neutral-500">{label}</p>

                    <div className="min-w-fit">
                        <Button
                            type="neutral"
                            size="xs"
                            text="Start New Project"
                            trailingIcon={FlowChartIcon}
                            onClick={() =>
                                openLinkInNewTab(`/playbook/${playbookId}/start/${projectId}`)
                            }
                        />
                    </div>
                </div>

                {description && (
                    <RichTextArea
                        value={description}
                        previewOnly
                        autoExpand
                        isBorderHidden
                        minHeight={32}
                    />
                )}

                <div>
                    <Button
                        type="link"
                        size="xs"
                        text="View Details"
                        onClick={() => openLinkInNewTab(`/scenario/${projectId}`)}
                    />
                </div>
            </div>

            {isEditable && (
                <div className="flex flex-col gap-2">
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={DeleteBin4LineIcon}
                        onClick={handleDeleteBlock}
                    />

                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={EditLineIcon}
                        onClick={() => setIsConfirmedMode(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default ConfirmedProjectBlock;
