import React, { useRef, useState } from 'react';

import { useFormState } from '../../../../hooks/useFormState';
import { emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import SelectButton from '../../../SelectButton/SelectButton';
import SelectProjectDropdown from '../SelectProjectDropdown/SelectProjectDropdown';
import GenericBlockEmptyStateWrapper from '../../GenericBlockEmptyStateWrapper/GenericBlockEmptyStateWrapper';
import { Input } from '../../../index';
import { FlowChartIcon } from '../../../Icons';

const UnconfirmedProjectBlock = ({
    isEditable,
    initiallySelectedProjectData,
    initialLabel,
    projectsOptionHookResponse,
    handleDeleteBlock,
    handleConfirmProject,
}) => {
    const { formData, fieldErrorMessages, handleInputChange, setFieldErrorMessages } = useFormState(
        { project: initiallySelectedProjectData, label: initialLabel }
    );

    const isInitialLabelAdded = useRef(!!initialLabel);

    const onConfirmProjectClick = () => {
        if (!formData.project?.id || !formData.label) {
            setFieldErrorMessages({
                project: !formData.project?.id ? 'Please select a project.' : null,
                label: !formData.label ? emptyFieldErrorMessage : null,
            });
            return;
        }

        handleConfirmProject(formData);
    };

    const handleProjectSelect = (project) => {
        handleInputChange('project', project);

        if (!isInitialLabelAdded.current && project) {
            handleInputChange('label', project.name);
            isInitialLabelAdded.current = true;
        }
    };

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const toggleDropdownOpened = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsDropdownOpened((prevState) => !prevState);
    };

    const formattedSelectedProject = formData.project
        ? {
              ...formData.project,
              leadingIcon: FlowChartIcon,
          }
        : null;

    const selectState = !isEditable ? 'disabled' : fieldErrorMessages.project ? 'error' : 'default';
    const inputState = !isEditable ? 'disabled' : fieldErrorMessages.label ? 'error' : 'default';

    const isLabelInputDisplayed = formData.project || isInitialLabelAdded.current;

    const labelBlock = (
        <>
            {isLabelInputDisplayed && (
                <Input
                    size="xs"
                    name="project_label"
                    value={formData.label}
                    label="Label"
                    isRequired
                    placeholder="Enter a label"
                    state={inputState}
                    errorMessage={fieldErrorMessages.label}
                    onChange={(e) => handleInputChange('label', e.target.value)}
                />
            )}
        </>
    );

    return (
        <GenericBlockEmptyStateWrapper
            isEditable={isEditable}
            isError={!!fieldErrorMessages.project}
            handleDeleteBlock={handleDeleteBlock}
            handleSubmit={onConfirmProjectClick}
            bottomContent={labelBlock}
        >
            <SelectButton
                size="xs"
                selectedItem={formattedSelectedProject}
                state={selectState}
                label="Project"
                placeholder="Select an project"
                errorMessage={fieldErrorMessages.project}
                isDropdownOpened={isDropdownOpened}
                toggleDropdownOpened={toggleDropdownOpened}
                showAsBadge
                badgeColor="neutral"
            />

            {isDropdownOpened && (
                <SelectProjectDropdown
                    selectedProjectId={formData.project?.id}
                    optionsHookResponse={projectsOptionHookResponse}
                    onOptionSelect={handleProjectSelect}
                    onClose={toggleDropdownOpened}
                />
            )}
        </GenericBlockEmptyStateWrapper>
    );
};

export default UnconfirmedProjectBlock;
