import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '../index';
import Loading from '../../components/Loading';

ActionOption.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.func.isRequired,
    handleAction: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

function ActionOption({ label, description, icon, handleAction, isLoading = false, onClose }) {
    const handleActionClick = async (e) => {
        await handleAction();
        onClose(e);
    };

    return (
        <div
            className="p-2 font-body rounded-2 bg-white hover:bg-neutral-50 cursor-pointer transition-colors relative"
            onClick={handleActionClick}
        >
            <div className="flex items-center gap-2">
                <SvgIcon color={isLoading ? 'transparent' : '#1F2125'} icon={icon} size="large" />
                <p
                    className={`text-body-regular-m ${
                        isLoading ? 'text-transparent' : 'text-neutral-500'
                    }`}
                >
                    {label}
                </p>
            </div>
            <p
                className={`text-body-regular-s ml-8 relative ${
                    isLoading ? 'text-transparent' : 'text-neutral-300'
                }`}
            >
                {description}
            </p>
            {isLoading && (
                <div className="absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%]">
                    <Loading withText={false} />
                </div>
            )}
        </div>
    );
}

export default ActionOption;
