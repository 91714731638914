import React from 'react';

const CheckLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill={props.color}>
            <path
                d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                stroke={props.color}
            />
        </svg>
    );
};

export default CheckLineIcon;
