import React, { useState } from 'react';
import { mutate } from 'swr';

import { API } from 'constants';
import executeClient from '../../../../services/execute-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Modal from '../../../../design-system/Modal/Modal';
import Alert from '../../../../design-system/Alert/Alert';
import { Button } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const DeleteDatasetConfirmationModal = ({ datasetDetail, swrKey, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleDeleteDataset = async () => {
        try {
            setIsLoading(true);
            await executeClient.delete(`${API.ROUTES.execute.dataset}${datasetDetail.id}/`);

            if (swrKey) {
                mutate(
                    swrKey,
                    (currentData) => {
                        const updatedData = currentData.results.filter(
                            (item) => item.id !== datasetDetail.id
                        );
                        return {
                            ...currentData,
                            count: currentData.count - 1,
                            results: updatedData,
                        };
                    },
                    {
                        revalidate: true,
                    }
                );
            }
            setIsLoading(false);
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e?.response?.status });
        }
    };

    return (
        <Modal onClose={onClose} size="extraSmall">
            <p className="font-heading-bold text-heading-bold-m text-black py-2 mb-4">
                Delete Dataset
            </p>
            <p className="font-body text-body-regular-m text-neutral-500 mb-8">
                Are you sure you want to delete{' '}
                <span className="text-body-bold-m">{datasetDetail.name}</span>? This dataset will no
                longer be available for test runs.
            </p>
            <div className="flex items-center justify-between gap-2">
                <Button type="ghost" size="sm" text="No, Cancel" onClick={onClose} />
                <Button
                    type="primary"
                    size="sm"
                    text="Yes, Delete"
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleDeleteDataset}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default DeleteDatasetConfirmationModal;
