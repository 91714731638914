import React, { useState } from 'react';
import { Badge, SvgIcon } from '../../../design-system';
import BookmarkFillIcon from '../../../design-system/Icons/BookmarkFillIcon';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import { useWindowSize } from '../../../hooks/useWindowSize';

const CardCollectionList = ({ collections, libraryView }) => {
    const { width: screenWidth } = useWindowSize();

    const formattedList = [...collections].slice(0, 3);
    const rest = collections.length - 3;

    const [showToolTip, setShowToolTip] = useState({ id: null });

    return (
        <>
            {!!collections.length && (
                <>
                    {(libraryView === 'grid' || screenWidth < 768) && (
                        <div className="flex items-center gap-0.5">
                            {formattedList.map((item) => (
                                <div
                                    key={item.id}
                                    onMouseEnter={() => setShowToolTip({ id: item.id })}
                                    onMouseLeave={() => setShowToolTip({ id: null })}
                                >
                                    <ToolTip
                                        text={item.name}
                                        position="top-right"
                                        isShown={showToolTip.id === item.id}
                                        shift="-3px"
                                    >
                                        <div className="w-[28px] min-w-[28px] h-[28px] flex items-center justify-center">
                                            <SvgIcon
                                                color={item.icon_color}
                                                size="medium"
                                                icon={BookmarkFillIcon}
                                            />
                                        </div>
                                    </ToolTip>
                                </div>
                            ))}
                            {rest > 0 && (
                                <p className="font-body text-body-regular-xs text-neutral-500">
                                    +{rest}
                                </p>
                            )}
                        </div>
                    )}
                    {libraryView === 'list' && screenWidth >= 768 && (
                        <>
                            {formattedList.map((item) => (
                                <Badge
                                    key={item.id}
                                    text={item.name}
                                    color="neutral"
                                    leadingIcon={BookmarkFillIcon}
                                    leadingIconColor={item.icon_color}
                                />
                            ))}
                            {rest > 0 && (
                                <p className="font-body text-body-regular-s text-neutral-300">
                                    +{rest} more
                                </p>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default CardCollectionList;
