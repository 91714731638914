import { defaultSortType } from '../pages/TemplatesLibraryPage/GoalTemplateDatasetsAndTestRunsPage/GoalTemplateDatasetsAndTestRunsPage';

export const getRequestQueryParams = (parsedSearchParams, goalTemplateId, path) => {
    const queryParams = {
        goal_template: goalTemplateId,
        ordering: parsedSearchParams.sort || defaultSortType[path],
    };
    if (parsedSearchParams.search) {
        queryParams.search = parsedSearchParams.search;
    }
    return queryParams;
};
