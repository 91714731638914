import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import * as API from '../../../constants/api';
import client from '../../../services/organization-api';
import { useGetRequest } from '../../../hooks/request';

import ClientSettings from './ClientSettings/ClientSettings';
import ParentOrganizationSettings from './ParentOrganizationSettings/ParentOrganizationSettings';

import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

const OrganizationDetailPage = ({ ...organizationsListProps }) => {
    const { organizationId: parentOrgId } = useParams();

    const [searchParams] = useSearchParams();
    const childOrgId = searchParams.get('client');

    const navigate = useNavigate();

    const controller = useRef(new AbortController());
    const [parentOrganizationData, setParentOrganizationData] = useState(null);

    const getParentOrganizationData = useGetRequest(
        { url: API.ROUTES.organization.organization + parentOrgId + '/' },
        client
    );

    const {
        options: childOrganizations,
        setOptions: setChildOrganizations,
        page: childOrganizationsPage,
        setPage: setChildOrganizationsPage,
        totalOptions: totalChildOrganizations,
        isFirstRequestCompleted: isChildOrganizationsFirstDataFetched,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: `${API.ROUTES.organization.organization}${parentOrgId}/child-organization/`,
        callback: (prevData, newData) => [...prevData, ...newData.results],
        triggerRefetch: [parentOrgId],
    });

    const refetchParentOrganizationData = async () => {
        controller.current?.abort();

        const newController = new AbortController();
        controller.current = newController;

        const { response, success, error } = await getParentOrganizationData.request({
            signal: newController.signal,
        });
        if (success) {
            setParentOrganizationData(response.organization_data);
        }
        if (error) {
            if (error === 'canceled') {
                return;
            }
            navigate('/settings');
        }
    };

    useEffect(() => {
        const fetchParentOrganizationData = async () => {
            setParentOrganizationData(null);

            await refetchParentOrganizationData();
        };

        fetchParentOrganizationData();
    }, [parentOrgId]);

    const parentOrganizationMembers = useMemo(
        () => parentOrganizationData?.organization_members?.map(({ user }) => ({ user })),
        [parentOrganizationData]
    );

    const paginationProps = {
        page: childOrganizationsPage,
        total: totalChildOrganizations,
        setPage: setChildOrganizationsPage,
    };

    return (
        <>
            {!childOrgId && (
                <ParentOrganizationSettings
                    key={`parent-organization-details-${parentOrgId}`}
                    organizationData={parentOrganizationData}
                    refetchOrganizationData={refetchParentOrganizationData}
                    childOrganizations={childOrganizations}
                    paginationProps={paginationProps}
                    {...organizationsListProps}
                />
            )}

            {childOrgId && (
                <ClientSettings
                    key={`client-details-${childOrgId}`}
                    clientOrganizations={childOrganizations}
                    setChildOrganizations={setChildOrganizations}
                    isFirstDataFetched={isChildOrganizationsFirstDataFetched}
                    parentOrganizationMembers={parentOrganizationMembers}
                    parentOrganizationName={parentOrganizationData?.organization?.name}
                    parentOrganizationPlan={parentOrganizationData?.billing_account?.plan}
                    {...organizationsListProps}
                />
            )}
        </>
    );
};

export default OrganizationDetailPage;
