import React from 'react';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { Button } from '../../../design-system';

const InboxTaskApprovedBanner = ({ threadId }) => {
    const { width: screenWidth } = useWindowSize();

    const handleViewJobDetail = () => {
        window.open(`/job/${threadId}`, '_blank');
    };

    return (
        <div className="p-5 rounded-2xl flex flex-col gap-3 bg-neutral-500">
            <div className="flex flex-col gap-1">
                <p className="font-body-bold text-body-bold-s sm:text-body-bold-m text-white">
                    Inbox Task Approved
                </p>
                <p className="font-body text-body-regular-xs sm:text-body-regular-s text-white">
                    This task has been successfully approved. To follow progress of this job, go to
                    the job details page.
                </p>
            </div>
            <div className="flex justify-end">
                <Button
                    type="secondary"
                    size={screenWidth > 640 ? 'sm' : 'xs'}
                    text="View Job Details"
                    onClick={handleViewJobDetail}
                />
            </div>
        </div>
    );
};

export default InboxTaskApprovedBanner;
