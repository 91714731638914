import React, { useState } from 'react';
import classNames from 'classnames';

import { handleCopy } from '../../../helpers/handleCopy';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { ButtonIcon } from '../../../design-system';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import QuickAddToDocModal from '../../../components/QuickAddToDocModal/QuickAddToDocModal';
import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import { FileCopyLineIcon } from '../../../design-system/Icons';

const UserMessageActionButtons = ({ message, setIsEditing, isEditing, isHovered }) => {
    const {
        id,
        default_version: { content },
    } = message;

    const isMessageCreated = !id?.startsWith('temp-');

    const [successAlert, setSuccessAlert] = useState(null);
    const [isAddToDocModalOpened, setIsAddToDocModalOpened] = useState(false);

    const [copyAlert, setCopyAlert] = useState(null);

    const { isDesktop } = useResponsiveBreakpoints();

    const containerClassName = classNames('flex items-center gap-1', {
        'absolute top-0 right-full mr-1': isDesktop,
        'justify-end': !isDesktop,
    });

    const areActionButtonsShown =
        (isDesktop && isHovered && !isEditing) || (!isDesktop && !isEditing);

    const toolTipPosition = isDesktop ? 'top-left' : 'top-right';

    return (
        <>
            {areActionButtonsShown && (
                <div className={containerClassName}>
                    <ToolTip
                        text="Copy Message"
                        position={toolTipPosition}
                        shift="-2px"
                        isShown={!isDesktop && false}
                        useInternalHoverStateToShowToolTip={isDesktop}
                    >
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={FileCopyLineIcon}
                            onClick={() => handleCopy(content, setCopyAlert)}
                        />
                    </ToolTip>

                    <ToolTip
                        text="Quick Add"
                        position={toolTipPosition}
                        shift="-2px"
                        isShown={!isDesktop && false}
                        useInternalHoverStateToShowToolTip={isDesktop}
                    >
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={FileTextLineIcon}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setIsAddToDocModalOpened(true);
                            }}
                        />
                    </ToolTip>

                    {isMessageCreated && (
                        <ToolTip
                            text="Edit Message"
                            position={toolTipPosition}
                            shift="-2px"
                            isShown={!isDesktop && false}
                            useInternalHoverStateToShowToolTip={isDesktop}
                        >
                            <ButtonIcon
                                type="link"
                                size="xs"
                                icon={EditLineIcon}
                                onClick={() => setIsEditing(true)}
                            />
                        </ToolTip>
                    )}
                </div>
            )}

            {isAddToDocModalOpened && (
                <QuickAddToDocModal
                    content={content}
                    setSuccessAlert={setSuccessAlert}
                    onClose={() => setIsAddToDocModalOpened(false)}
                />
            )}

            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </>
    );
};

export default UserMessageActionButtons;
