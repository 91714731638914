import React from 'react';

const H3Icon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M14.6693 5.33366L14.6682 6.66699L12.9982 8.58899C14.0575 8.87926 14.8359 9.84886 14.8359 11.0003C14.8359 12.3811 13.7167 13.5003 12.3359 13.5003C11.1186 13.5003 10.1045 12.6303 9.88154 11.4781L11.1907 11.2238C11.2949 11.7612 11.768 12.167 12.3359 12.167C12.9803 12.167 13.5026 11.6447 13.5026 11.0003C13.5026 10.356 12.9803 9.83366 12.3359 9.83366C12.1453 9.83366 11.9653 9.87939 11.8063 9.96053L10.9354 8.92913L12.9015 6.66699H10.0026V5.33366H14.6693ZM2.66927 2.66699V7.33366H7.33594V2.66699H8.66927V13.3337H7.33594V8.66699H2.66927V13.3337H1.33594V2.66699H2.66927Z"
                fill={props.color}
            />
        </svg>
    );
};

export default H3Icon;
