import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../services/library-api';

import { defaultErrorMessage } from '../../../constants/errorMessages';
import { mutateDataAfterRemovingItem } from '../../../helpers/configureUtils';

import Modal from '../../../design-system/Modal/Modal';
import Alert from '../../../design-system/Alert/Alert';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const DeleteProcessConfirmModal = ({
    onClose,
    processName,
    processId,
    keysToMutate,
    onProcessDelete,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState(null);

    const deleteProcess = async () => {
        try {
            setIsLoading(true);
            await client.delete(`${API.ROUTES.library.process}${processId}/`);

            mutateDataAfterRemovingItem({ requestKeysToMutate: keysToMutate, id: processId });

            if (onProcessDelete) {
                onProcessDelete();
            }

            setIsLoading(false);
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorStatusCode(e.response?.status);
        }
    };

    return (
        <Modal size="extraSmall" onClose={onClose}>
            <div>
                <p className="font-heading-bold text-heading-bold-m text-black py-2 mb-4">
                    Delete Process
                </p>
                <p className="font-body text-body-regular-m text-neutral-500 mb-8">
                    Are you sure you want to delete{' '}
                    <span className="text-body-bold-m">{processName}</span> from your
                    configurations?
                </p>
                <div className="flex items-center gap-2 justify-between">
                    <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="sm"
                        text="Yes, Delete"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={deleteProcess}
                    />
                </div>
            </div>
            {errorStatusCode && (
                <Alert
                    status="critical"
                    icon={ErrorWarningLineIcon}
                    message={defaultErrorMessage}
                    statusCode={errorStatusCode}
                    handleClose={() => setErrorStatusCode(null)}
                />
            )}
        </Modal>
    );
};

export default DeleteProcessConfirmModal;
