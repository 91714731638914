import React, { useState } from 'react';
import LabelsBlock from '../../../../components/LabelsBlock/LabelsBlock';

import { updateLabelsOfProcessTemplate } from '../../../../helpers/templatesUtils';

const ProcessTemplateLabelsBlock = ({ processTemplateId, labels, setTemplateData, editable }) => {
    const abortController = useState(new AbortController()); // control patch requests to update labels

    const setAddedLabels = (cb) => {
        setTemplateData((prevData) => ({ ...prevData, labels: cb(prevData.labels) }));
    };

    const handleSaveUpdatedLabels = async (selectedLabels, selectedLabelsIds) => {
        await updateLabelsOfProcessTemplate(selectedLabelsIds, processTemplateId, abortController);
    };

    const handleDeleteAddedLabel = async (id) => {
        const updatedLabels = labels?.filter((label) => label.id !== id) || [];
        const updatedLabelsIds = updatedLabels.map((label) => label.id);

        setTemplateData((prevData) => ({ ...prevData, labels: updatedLabels }));
        await updateLabelsOfProcessTemplate(updatedLabelsIds, processTemplateId, abortController);
    };

    return (
        <LabelsBlock
            isEditable={editable}
            addedLabels={labels}
            shouldSaveDataOnBlur
            setAddedLabels={setAddedLabels}
            saveUpdatedLabels={handleSaveUpdatedLabels}
            handleDeleteAddedLabel={handleDeleteAddedLabel}
        />
    );
};

export default ProcessTemplateLabelsBlock;
