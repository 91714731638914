import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API } from 'constants';
import client from '../../../services/report-api';
import { STATUS } from '../../../constants/statuses';

import Loading from '../../../components/Loading';
import JobDetailPageHeader from './JobDetailPageHeader/JobDetailPageHeader';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import RenameJobModal from './RenameJobModal/RenameJobModal';
import ConfirmArchiveJobModal from './ConfirmArchiveJobModal/ConfirmArchiveJobModal';
import RestartJobSidePanel from './RestartJobSidePanel/RestartJobSidePanel';
import MilestonesSection from './MilestonesSection/MilestonesSection';

const fetcher = (url) => client.get(url).then((res) => res.data);

const JobDetailPage = () => {
    const { jobId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from ?? '/jobs';

    const [shouldRefresh, setShouldRefresh] = useState(true); // if job.status === 'started' auto polling is enabled

    const getJobDataRequestEndpoint = `${API.ROUTES.report.job}${jobId}/`;
    const {
        data: jobDetail,
        error,
        mutate,
    } = useSWR(getJobDataRequestEndpoint, fetcher, {
        refreshInterval: shouldRefresh ? 1000 : 0,
    });

    const [actionModal, setActionModal] = useState({ isOpened: false, action: null }); // actions are "rename", "archive", "restart"

    // column or row view
    const [view, setView] = useState('column');

    useEffect(() => {
        if (jobDetail) {
            setShouldRefresh(jobDetail.status === STATUS.started);
        }
    }, [jobDetail]);

    if (error) {
        navigate(backLinkHref);
    }

    useDocumentTitle(jobDetail?.name);

    const closeActionModal = () => {
        setActionModal({ isOpened: false, action: null });
    };

    return (
        <div
            className={`page-position bg-white flex flex-col hide-scrollbar ${
                view === 'row' && 'overflow-y-auto'
            }`}
        >
            {jobDetail && (
                <>
                    <JobDetailPageHeader
                        jobDetail={jobDetail}
                        backLinkHref={backLinkHref}
                        setActionModal={setActionModal}
                    />

                    <MilestonesSection
                        milestones={jobDetail.milestones}
                        view={view}
                        setView={setView}
                        tabLabel="Job Tasks"
                    />

                    {actionModal.isOpened && actionModal.action === 'rename' && (
                        <RenameJobModal
                            jobId={jobId}
                            jobName={jobDetail.name}
                            mutate={mutate}
                            onClose={closeActionModal}
                        />
                    )}
                    {actionModal.isOpened && actionModal.action === 'restart' && (
                        <RestartJobSidePanel
                            jobId={jobId}
                            mutate={mutate}
                            onClose={closeActionModal}
                        />
                    )}
                    {actionModal.isOpened && actionModal.action === 'archive' && (
                        <ConfirmArchiveJobModal
                            jobId={jobId}
                            jobName={jobDetail.name}
                            backLinkHref={backLinkHref}
                            onClose={closeActionModal}
                        />
                    )}
                </>
            )}
            {!jobDetail && (
                <div className="flex-grow flex justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default JobDetailPage;
