import React from 'react';

const CheckboxCircleFillLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <g>
                <rect x="3.5" y="3.5" width="17" height="17" rx="8.5" fill={props.color} />
                <rect x="3.5" y="3.5" width="17" height="17" rx="8.5" stroke={props.color} />
                <path
                    d="M7 12.8095L10.0476 15.6667L17 9"
                    stroke="white"
                    strokeWidth="1.90476"
                    strokeLinecap="square"
                />
            </g>
        </svg>
    );
};

export default CheckboxCircleFillLineIcon;
