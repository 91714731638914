import React, { useEffect, useRef, useState } from 'react';

import { TASK_TYPES } from '../../../../constants/library';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';
import { checkAndUpdateUserInputsIfUsed } from '../../../../helpers/taskPlaygroundUtils';

import { Input } from '../../../../design-system';
import JsonTextareaBox from '../../../../design-system/JsonTextareaBox/JsonTextareaBox';

const ApiRequestDetailsBlock = ({
    apiTaskChangeableData,
    setApiTaskChangeableData,
    setChangedTaskFields,
    fieldsErrorMessages,
    setFieldsErrorMessages,
    setUserInputs,
}) => {
    const { request_url, request_headers, request_data } = apiTaskChangeableData;
    const jsonsContainerRef = useRef(null);
    const [jsonsContainerInitialHeight, setJsonsContainerInitialHeight] = useState(0);

    useEffect(() => {
        if (jsonsContainerRef.current) {
            const height = jsonsContainerRef.current.clientHeight;
            setJsonsContainerInitialHeight(height);
        }
    }, [jsonsContainerRef]);

    const jsonTextAreaHeight = jsonsContainerInitialHeight
        ? `${(jsonsContainerInitialHeight - 32) / 2}px`
        : 'calc((100% - 32px) / 2)';

    useEffect(() => {
        // check if {{input.key}} is in api request_url or request_headers or request_data
        const apiRequest = [request_url, request_headers, request_data];

        checkAndUpdateUserInputsIfUsed({
            dataArray: apiRequest,
            setUserInputs,
            taskType: TASK_TYPES.api,
        });
    }, [request_url, request_headers, request_data]);

    const handleInputChange = (name, value) => {
        setApiTaskChangeableData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setChangedTaskFields((prevFields) => addUniqueElementToArray(name, prevFields));
        setFieldsErrorMessages((prevData) => ({ ...prevData, [name]: null }));
    };

    return (
        <div className="flex-grow w-full flex flex-col gap-5 max-lg:min-h-[500px]">
            <Input
                size="xs"
                value={request_url}
                name="request_url"
                label="Request URL"
                isRequired
                state={fieldsErrorMessages.request_url ? 'error' : 'default'}
                errorMessage={fieldsErrorMessages.request_url}
                placeholder="https://"
                tipText="Add mapped input variables by {{input-key}}"
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />
            <div className="flex-grow w-full flex flex-col gap-5" ref={jsonsContainerRef}>
                <div style={{ minHeight: jsonTextAreaHeight }} className="flex flex-col gap-4">
                    <JsonTextareaBox
                        value={request_headers}
                        mode="code"
                        setValue={(value) => handleInputChange('request_headers', value)}
                        label="Request Headers"
                        isRequired
                        state={fieldsErrorMessages.request_headers ? 'error' : 'default'}
                        errorMessage={fieldsErrorMessages.request_headers}
                        parentGap={16}
                        containerMinHeight={200}
                        withCopyButton
                        tipText="Map this connection’s Authorization Key using {{connection.api_key}}"
                    />
                </div>

                <div style={{ minHeight: jsonTextAreaHeight }} className="flex flex-col gap-4">
                    <JsonTextareaBox
                        value={request_data}
                        mode="code"
                        setValue={(value) => handleInputChange('request_data', value)}
                        label="Request Data"
                        isRequired
                        state={fieldsErrorMessages.request_data ? 'error' : 'default'}
                        errorMessage={fieldsErrorMessages.request_data}
                        parentGap={16}
                        containerMinHeight={200}
                        withCopyButton
                        tipText="Add mapped input variables by {{input-key}}"
                    />
                </div>
            </div>
        </div>
    );
};

export default ApiRequestDetailsBlock;
