import React from 'react';
import PropTypes from 'prop-types';

TabbedHeaderControl.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeTab: PropTypes.string.isRequired,
    handleTabSelect: PropTypes.func,
};

function TabbedHeaderControl({ tabs, activeTab, handleTabSelect = () => {} }) {
    return (
        <div className="flex items-center gap-4">
            {tabs.map((tab, index) => (
                <div
                    className={`
                                    capitalize cursor-pointer font-heading-bold text-[14px] font-semibold tracking-[0.7] leading-[100%] transition-colors pt-3 ${
                                        tab === activeTab
                                            ? 'text-purple-500 border-b-2 border-purple-500 pb-[8px]'
                                            : 'text-neutral-500 pb-[10px]'
                                    }
                                `}
                    onClick={() => handleTabSelect(tab)}
                    key={index}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
}

export default TabbedHeaderControl;
