import React from 'react';

import ActionOption from '../../../../design-system/ActionOption/ActionOption';
import FileIcon from '../../../../design-system/Icons/FileIcon';
import MultiActionPopup from '../../../../design-system/MultiActionPopup/MultiActionPopup';
import EyeIcon from '../../../../design-system/Icons/EyeIcon';
import CheckSquareIcon from '../../../../design-system/Icons/CheckSquareIcon';

const ScenarioMultiActionPopup = ({
    isLiveVersionSelected,
    isDefaultVersionSelected,
    changeVersionStatus,
    handleSaveChanges,
    isStatusChangeLoading,
    isSaveChangesLoading,
    onClose,
}) => {
    return (
        <MultiActionPopup
            actionOptions={
                <>
                    {!isLiveVersionSelected && (
                        <ActionOption
                            label="Save Changes"
                            description="This saves your changes to the selected Scenario version"
                            icon={FileIcon}
                            isLoading={isSaveChangesLoading}
                            onClose={onClose}
                            handleAction={handleSaveChanges}
                        />
                    )}
                    {!isLiveVersionSelected && (
                        <ActionOption
                            label="Make Live"
                            description="This will save your changes and turn this Scenario version live"
                            icon={EyeIcon}
                            handleAction={async () =>
                                await changeVersionStatus({ statusType: 'is_live' })
                            }
                            isLoading={isStatusChangeLoading.is_live}
                            onClose={onClose}
                        />
                    )}
                    {isLiveVersionSelected && !isDefaultVersionSelected && (
                        <ActionOption
                            label="Make Default"
                            description="This action will make this Scenario version the default"
                            icon={CheckSquareIcon}
                            handleAction={async () =>
                                await changeVersionStatus({ statusType: 'is_default' })
                            }
                            isLoading={isStatusChangeLoading.is_default}
                            onClose={onClose}
                        />
                    )}
                </>
            }
            onClose={(e) => {
                e?.stopPropagation();
                onClose();
            }}
        />
    );
};

export default ScenarioMultiActionPopup;
