import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/report-api';
import libraryClient from '../../../services/library-api';
import knowledgeClient from '../../../services/knowledge-api';

import { extractDocIds } from '../../../helpers/startNewJobFormUtils';
import { useRecursiveDataFetchWithSWR } from '../../../hooks/useRecursiveDataFetchWithSWR';

import MainPanelContainer from '../MainPanelContainer/MainPanelContainer';
import MainPanelHeader from '../MainPanelHeader/MainPanelHeader';
import AgentSelector from '../AgentSelector/AgentSelector';
import JobPanelMainContent from '../JobPanelMainContent/JobPanelMainContent';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';

const JobPanel = ({
    jobId,
    jobDataHookResponse,
    historyPanelExpandedState,
    setJobsHistoryList,
}) => {
    const [searchParams] = useSearchParams();
    const agentId = searchParams.get('agent');

    const { isJobStarted, jobData, setJobData } = jobDataHookResponse;

    const [newJobName, setNewJobName] = useState('');

    // keeps data in format {docId: docLabel}
    const mappedDocsLabelsState = useState({});
    const [mappedDocsLabels, setMappedDocsLabels] = mappedDocsLabelsState;

    useEffect(() => {
        if (!jobData?.inputs?.length) {
            return;
        }

        const mappedDocs = extractDocIds(jobData.inputs);
        setMappedDocsLabels((prevData) => ({ ...mappedDocs, ...prevData }));
    }, [jobData?.inputs]);

    useEffect(() => {
        const idsToFetch = Object.entries(mappedDocsLabels)
            .filter(([_, label]) => !label)
            .map(([docId]) => docId);

        const fetchDocLabels = async () => {
            if (!idsToFetch.length) return;

            try {
                const data = await Promise.allSettled(
                    idsToFetch.map((id) => {
                        return knowledgeClient.get(`${API.ROUTES.knowledge.document}${id}/`);
                    })
                );

                const fetchedDocsLabels = data.reduce((acc, { status, value }) => {
                    if (status === 'fulfilled') {
                        const { id, label } = value.data;

                        return {
                            ...acc,
                            [id]: label,
                        };
                    }
                    return acc;
                }, {});

                setMappedDocsLabels((prev) => ({
                    ...prev,
                    ...fetchedDocsLabels,
                }));
            } catch (e) {}
        };

        fetchDocLabels();
    }, [mappedDocsLabels]);

    const updateJobName = async (updatedName) => {
        setJobsHistoryList((jobs) =>
            jobs.map((job) => (job.id === jobId ? { ...job, name: updatedName } : job))
        );

        if (!jobId) {
            setNewJobName(updatedName);
        }

        if (jobId) {
            setJobData((jobData) => (jobData ? { ...jobData, name: updatedName } : jobData));
            await client.patch(`${API.ROUTES.report.job}${jobId}/`, {
                name: updatedName,
            });
        }
    };

    const formatAgentDataToOption = ({ id, name, icon_color }) => ({
        id,
        name,
        leadingIcon: PlayCircleFillIcon,
        leadingIconColor: icon_color,
    });

    const formatResponse = (results) => results?.map(formatAgentDataToOption);

    const agentsOptionsHookResponse = useRecursiveDataFetchWithSWR({
        client: libraryClient,
        route: API.ROUTES.library.process,
        searchParams: { shared: true },
        callback: formatResponse,
    });

    useEffect(() => {
        setNewJobName('');
    }, [agentId]);

    const isJobDataLoading = jobId && !jobData;

    const startedJobAgentBadgeInfo =
        isJobStarted && jobData ? formatAgentDataToOption(jobData.process) : null;

    const displayedName = !!jobData ? jobData.name : newJobName;

    return (
        <MainPanelContainer
            isLoading={isJobDataLoading}
            historyPanelExpandedState={historyPanelExpandedState}
        >
            <MainPanelHeader
                name={displayedName}
                handleUpdateName={updateJobName}
                panelEntity="job"
                historyPanelExpandedState={historyPanelExpandedState}
                selector={
                    <AgentSelector
                        agentsOptionsHookResponse={agentsOptionsHookResponse}
                        isJobStarted={isJobStarted}
                        startedJobAgentInfo={startedJobAgentBadgeInfo}
                    />
                }
            />
            <JobPanelMainContent
                agentId={agentId}
                jobDataHookResponse={jobDataHookResponse}
                setJobsHistoryList={setJobsHistoryList}
                newJobName={newJobName}
                mappedDocsLabelsState={mappedDocsLabelsState}
            />
        </MainPanelContainer>
    );
};

export default JobPanel;
