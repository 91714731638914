import React from 'react';

import { usePublisher } from '@mdxeditor/editor';
import { openLinkEditDialog$ } from '../../LinkDialog/linkDialogUtils';

import { SvgIcon } from '../../../index';
import LinkIcon from '../../../Icons/LinkIcon';

const ToolbarLink = () => {
    const openLinkDialog = usePublisher(openLinkEditDialog$);

    return (
        <button className="p-[6px] hover:bg-neutral-50 rounded-2" onClick={openLinkDialog}>
            <SvgIcon color="#1F2125" icon={LinkIcon} size="medium" />
        </button>
    );
};

export default ToolbarLink;
