import React, { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonIcon } from '../../../../design-system';
import { ArrowGoBackLineIcon, More2FillIcon } from '../../../../design-system/Icons';
import ScenarioMoreOptionsPopup from '../ScenarioMoreOptionsPopup/ScenarioMoreOptionsPopup';
import ConfirmDeleteScenarioModal from '../ConfirmDeleteScenarioModal/ConfirmDeleteScenarioModal';

const PageHeader = ({ scenarioId, scenarioName, backLinkHref }) => {
    const navigate = useNavigate();

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [isMoreOptionsPopupShown, setIsMoreOptionsPopupShown] = useState(false);

    return (
        <div className="px-4 md:px-6 pt-3.5 md:pt-5 pb-1.5 md:pb-[10px] bg-white border-b-1 border-neutral-200 flex items-center justify-between gap-x-2 gap-y-0">
            <div className="flex items-center gap-5">
                <Button
                    type="link"
                    size="xs"
                    text="Back"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(backLinkHref)}
                />
                <p className="font-body text-body-regular-s text-neutral-300 whitespace-nowrap">
                    Configure / Scenario
                </p>
            </div>
            <div className="relative">
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={More2FillIcon}
                    onClick={() => setIsMoreOptionsPopupShown(true)}
                />
                {isMoreOptionsPopupShown && (
                    <ScenarioMoreOptionsPopup
                        setIsDeleteModalOpened={setIsDeleteModalOpened}
                        onClose={() => setIsMoreOptionsPopupShown(false)}
                    />
                )}
            </div>

            {isDeleteModalOpened && (
                <ConfirmDeleteScenarioModal
                    scenarioId={scenarioId}
                    scenarioName={scenarioName}
                    onClose={() => setIsDeleteModalOpened(false)}
                />
            )}
        </div>
    );
};

export default memo(PageHeader);
