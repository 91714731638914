import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { Button, ButtonIcon } from '../../../design-system';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';

const PlaybookBuilderNavigation = ({ playbookId, isPlaybookShared = false }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { isDesktop } = useResponsiveBreakpoints();

    const openPlaybookBuilder = (mode) => {
        navigate(`/playbook/${playbookId}?mode=${mode}`, { state: { from: location } });
    };

    const viewButtonLabel = isPlaybookShared ? 'View Shared Playbook' : 'View Playbook';
    const withEditButton = isDesktop && !isPlaybookShared;

    return (
        <div className="flex items-center justify-end gap-2">
            {withEditButton && (
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={EditLineIcon}
                    onClick={() => openPlaybookBuilder(PLAYBOOK_BUILDER_MODE.edit)}
                />
            )}
            <Button
                type="neutral"
                size="xs"
                text={viewButtonLabel}
                onClick={() => openPlaybookBuilder(PLAYBOOK_BUILDER_MODE.view)}
            />
        </div>
    );
};

export default PlaybookBuilderNavigation;
