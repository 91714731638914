import React, { useEffect, useState } from 'react';

import { Button, ListOption, ListOptionGroup } from '../../../design-system';
import { AddCircleLineIcon } from '../../../design-system/Icons';
import Loading from '../../../components/Loading';
import CollectionActionModal from '../CollectionActionModal/CollectionActionModal';
import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import CollectionCard from './CollectionCard/CollectionCard';
import CollectionsSearchBar from '../CollectionsSearchBar/CollectionsSearchBar';
import { useSearchParams } from 'react-router-dom';
import { parseLibrarySearchParams } from '../../../services/library-utils';
import { LIBRARY_FILTER_COLLECTION_QUERY_PARAM_NAME } from '../../../constants/library';
import { useFetchCollections } from '../../../hooks/useFetchCollections';
import { USER_ROLE } from '../../../constants/organization';
import useUser from '../../../hooks/useUser';

const CollectionsPanel = ({ collections, setCollections, setAllCollectionsAreLoaded }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = parseLibrarySearchParams(searchParams);

    const { userRole } = useUser();
    const viewOnlyMode = userRole === USER_ROLE.member;

    const [filteredCollections, setFilteredCollections] = useState([]);
    const [selectedCollectionId, setSelectedCollectionId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [collectionActionModal, setCollectionActionModal] = useState({
        action: null,
        opened: false,
        data: null,
    });
    const [successAlert, setSuccessAlert] = useState({ message: '', shown: false });
    const [clickedMoreOptionsCardInfo, setClickedMoreOptionsCardInfo] = useState({
        top: null,
        left: null,
        data: null,
        showMoreOptions: false,
    }); // we have to hold this state here (not in CollectionCard because of positioning and overflow property on panel)

    useFetchCollections(setCollections, setIsLoading, setAllCollectionsAreLoaded);

    useEffect(() => {
        if (selectedCollectionId !== parsedParams.collection) {
            setSelectedCollectionId(parsedParams.collection);
        }
    }, [parsedParams.collection]);

    const createCollection = () => {
        setCollectionActionModal({ opened: true, action: 'create', data: null });
    };

    const handleCollectionSelect = (id) => {
        if (selectedCollectionId === id) {
            setSelectedCollectionId(null);
            setSearchParams({
                ...parsedParams,
                [LIBRARY_FILTER_COLLECTION_QUERY_PARAM_NAME]: null,
            });
            return;
        }
        setSelectedCollectionId(id);
        setSearchParams({
            ...parsedParams,
            [LIBRARY_FILTER_COLLECTION_QUERY_PARAM_NAME]: id,
            page: 1,
        });
    };

    const handleDropdownOptionSelect = (action) => {
        setCollectionActionModal({ opened: true, action, data: clickedMoreOptionsCardInfo.data });
        setClickedMoreOptionsCardInfo({ showMoreOptions: false });
    };

    return (
        <>
            <div className="w-[260px] min-w-[260px] h-full px-4 py-6 bg-white max-lg:hidden flex flex-col gap-3 overflow-y-auto pb-[100px]">
                <p className="font-body text-body-bold-xs uppercase text-neutral-300">
                    Collections
                </p>

                {!isLoading && collections?.length === 0 && (
                    <div className="py-[80px] text-black text-center">
                        <p className="font-heading-bold text-heading-bold-s mb-1">
                            You don’t have any Collections yet.
                        </p>
                        {!viewOnlyMode && (
                            <>
                                <p className="font-body text-body-regular-s mb-4">
                                    Start organizing your prompts by creating a new collection.
                                </p>
                                <div className="flex justify-center">
                                    <Button
                                        type="neutral"
                                        size="xs"
                                        text="Create Collection"
                                        leadingIcon={AddCircleLineIcon}
                                        onClick={createCollection}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}
                {collections?.length > 0 && (
                    <>
                        <CollectionsSearchBar
                            filterCollections={setFilteredCollections}
                            allCollections={collections}
                        />
                        <div className="flex flex-col gap-1">
                            <div
                                className={`w-full h-[36px] px-2 py-1 rounded-2 flex items-center hover-bg-neutral-50 cursor-pointer transition-colors font-body text-body-regular-s text-neutral-500 ${
                                    !selectedCollectionId && 'bg-neutral-50'
                                }`}
                                onClick={() => handleCollectionSelect(null)}
                            >
                                All Prompts
                            </div>
                            {filteredCollections.map((item) => (
                                <CollectionCard
                                    key={item.id}
                                    item={item}
                                    selected={item.id === selectedCollectionId}
                                    handleSelect={handleCollectionSelect}
                                    withDropdownCardInfo={clickedMoreOptionsCardInfo}
                                    setWithDropdownCardInfo={setClickedMoreOptionsCardInfo}
                                />
                            ))}
                        </div>
                    </>
                )}
                {isLoading && (
                    <div className="flex justify-center pt-2 pb-1">
                        <Loading withText={false} />
                    </div>
                )}
                {collections?.length > 0 && !viewOnlyMode && (
                    <div className="pt-1">
                        <Button
                            type="link"
                            size="xs"
                            text="New Collection"
                            leadingIcon={AddCircleLineIcon}
                            onClick={createCollection}
                        />
                    </div>
                )}
                {collectionActionModal.opened && (
                    <CollectionActionModal
                        handleClose={() => {
                            setCollectionActionModal({ action: null, opened: false, data: null });
                        }}
                        action={collectionActionModal.action}
                        data={collectionActionModal.data}
                        showAlert={(message) => setSuccessAlert({ shown: true, message })}
                        setCollections={setCollections}
                    />
                )}
                {successAlert.shown && (
                    <Alert
                        status="positive"
                        message={successAlert.message}
                        icon={CheckLineIcon}
                        handleClose={() => setSuccessAlert({ shown: false, message: '' })}
                    />
                )}
            </div>
            {clickedMoreOptionsCardInfo.showMoreOptions && (
                <div
                    className="absolute z-55"
                    style={{
                        top: `${clickedMoreOptionsCardInfo.top + 42}px`,
                        left: `${clickedMoreOptionsCardInfo.left - 92}px`,
                    }}
                >
                    <ListOptionGroup
                        listOptions={
                            <>
                                <ListOption
                                    leadingIconName="editIcon"
                                    text="Edit"
                                    onClick={() => handleDropdownOptionSelect('edit')}
                                />
                                <ListOption
                                    leadingIconName="deleteBin4LineIcon"
                                    text="Delete"
                                    iconColor="#E95B69"
                                    textColor="#E95B69"
                                    onClick={() => handleDropdownOptionSelect('delete')}
                                />
                            </>
                        }
                        handleClose={() => {
                            setClickedMoreOptionsCardInfo({ showMoreOptions: false });
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default CollectionsPanel;
