import React from 'react';

const UnlockIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M12.6667 7.3335H3.33333C2.59695 7.3335 2 7.93045 2 8.66683V13.3335C2 14.0699 2.59695 14.6668 3.33333 14.6668H12.6667C13.403 14.6668 14 14.0699 14 13.3335V8.66683C14 7.93045 13.403 7.3335 12.6667 7.3335Z"
                fill="none"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.66602 7.33344V4.66677C4.66519 3.84013 4.97154 3.04268 5.5256 2.42921C6.07966 1.81575 6.8419 1.43004 7.66435 1.34696C8.4868 1.26389 9.31078 1.48937 9.97633 1.97964C10.6419 2.46992 11.1015 3.19 11.266 4.0001"
                stroke={props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default UnlockIcon;
