import React from 'react';

import { handleOpenStartNewJobModal } from '../../../helpers/handleOpenStartNewJobModal';

import BlockHeader from '../BlockHeader/BlockHeader';
import JobCard from '../JobCard/JobCard';
import Loading from '../../../components/Loading';
import EmptyView from '../../../design-system/EmptyView/EmptyView';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const RecentJobsBlock = ({ jobs }) => {
    const isViewButtonDisplayed = !!jobs?.length;

    return (
        <div className="p-5 sm:p-8 bg-neutral-50 flex-grow">
            {jobs && (
                <div className="bg-white rounded-2 min-h-full p-4 sm:p-6 flex flex-col gap-5">
                    <BlockHeader
                        title="Recent Jobs"
                        description="Most recent jobs that you’ve run"
                        viewAllNavPath="/jobs"
                        isViewButtonDisplayed={isViewButtonDisplayed}
                    />
                    {!!jobs.length && (
                        <div className="flex flex-col max-[815px]:gap-1">
                            {jobs.map((job) => (
                                <JobCard key={job.id} job={job} pageName="dashboard" />
                            ))}
                        </div>
                    )}
                    {!jobs.length && (
                        <EmptyView
                            icon={FlashlightFillIcon}
                            mainText="You don’t have any Jobs yet."
                            subtext="Try creating one by clicking “New Job”."
                            buttonText="New Job"
                            handleButtonClick={handleOpenStartNewJobModal}
                            buttonLeadingIcon={FlashlightFillIcon}
                            buttonHelperText="⌘J"
                        />
                    )}
                </div>
            )}
            {!jobs && <Loading withText={false} />}
        </div>
    );
};

export default RecentJobsBlock;
