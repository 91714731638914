import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const ProcessTemplateMoreOptionsPopup = ({ processTemplateData, onClose }) => {
    const navigate = useNavigate();

    const redirectToGitHub = () => {
        window.open(processTemplateData.github_url, '_blank');
    };

    const handlePublishSettings = () => {
        navigate(`/templates/process/${processTemplateData.id}/publish`);
    };

    return (
        <div className="absolute right-0 top-full z-10">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="publishedIcon"
                            text="Publish Settings"
                            onClick={handlePublishSettings}
                        />
                        <ListOption
                            leadingIconName="shareBoxLineIcon"
                            text="View in GitHub"
                            isDisabled={!processTemplateData.is_live}
                            onClick={redirectToGitHub}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default ProcessTemplateMoreOptionsPopup;
