import React from 'react';

import { CHAT_TYPE } from '../../../constants/assistant';

import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';
import AiModelsDropdownOption from '../AiModelsDropdownOption/AiModelsDropdownOption';
import { ListOptionGroup } from '../../../design-system';

const AiModelsDropdown = ({
    onClose,
    selectedOption,
    modelOptions,
    diviBotOption,
    customBotOptionsHookResponse,
    handleOptionSelect,
    isMobile,
}) => {
    const {
        options: customBotOptions,
        optionsLoading,
        setPage,
        canLoadMoreOptions,
        isFirstRequestCompleted,
    } = customBotOptionsHookResponse;

    const handleOptionClick = (option) => {
        handleOptionSelect(option);
        onClose();
    };

    let listOptions = [];

    if (diviBotOption) {
        const isSelected = selectedOption?.chatType === CHAT_TYPE.divibot;

        listOptions.push(
            <AiModelsDropdownOption
                key="diviBot-option"
                option={diviBotOption}
                isSelected={isSelected}
                handleOptionClick={handleOptionClick}
            />
        );
    }

    const noCustomBots =
        isFirstRequestCompleted &&
        !customBotOptions?.length &&
        !optionsLoading &&
        !canLoadMoreOptions;

    if (!noCustomBots) {
        const customBotsList = (
            <InfiniteScrollList
                key="custom-bots-list"
                handleFetch={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                items={customBotOptions}
                loading={optionsLoading}
                customStyles="w-full"
                gap={4}
            >
                {customBotOptions?.map((option) => {
                    const isSelected =
                        selectedOption.chatType === CHAT_TYPE.custom &&
                        option.id === selectedOption?.id;

                    return (
                        <AiModelsDropdownOption
                            key={option.id}
                            option={option}
                            isSelected={isSelected}
                            handleOptionClick={handleOptionClick}
                        />
                    );
                })}
            </InfiniteScrollList>
        );

        listOptions.push(customBotsList);
    }

    const allCustomBotsLoaded = isFirstRequestCompleted && !canLoadMoreOptions && !optionsLoading;
    if (allCustomBotsLoaded && !!modelOptions?.length) {
        const modelOptionsMarkup = modelOptions.map((option) => {
            const isSelected =
                selectedOption.chatType === CHAT_TYPE.generic &&
                option.model === selectedOption?.model;

            return (
                <AiModelsDropdownOption
                    key={option.name + option.model}
                    option={option}
                    isSelected={isSelected}
                    handleOptionClick={handleOptionClick}
                />
            );
        });

        listOptions.push(...modelOptionsMarkup);
    }

    if (!modelOptions?.length) {
        listOptions = (
            <p className="font-body-bold text-body-bold-s text-neutral-400 px-3 text-center">
                Please add your LLM API key
            </p>
        );
    }

    if (isMobile) {
        return (
            <MobilePopup onClose={onClose} closeButtonText="Cancel" title="Choose a Model">
                <div className="flex flex-col gap-1">{listOptions}</div>
            </MobilePopup>
        );
    }

    return (
        <div className="absolute right-0 top-[110%] z-20">
            <ListOptionGroup
                listOptions={listOptions}
                fixedWidth={260}
                maxHeight={380}
                handleClose={onClose}
            />
        </div>
    );
};

export default AiModelsDropdown;
