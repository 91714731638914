import React from 'react';
import ConfirmationModal from '../../../design-system/ConfirmationModal/ConfirmationModal';

const OutdatedAlertModal = ({ onClose, isSaveChangesLoading, handleSaveChanges }) => {
    const handleOverwrite = async () => {
        await handleSaveChanges({ overwrite: true });
        onClose();
    };

    return (
        <ConfirmationModal
            onClose={onClose}
            text="You are about to overwrite others’ recent edits. Are you sure?"
            confirmButtonText="Yes, Overwrite"
            cancelButtonText="No, Go Back"
            confirmButtonState={isSaveChangesLoading ? 'loading' : 'default'}
            handleConfirm={handleOverwrite}
        />
    );
};

export default OutdatedAlertModal;
