import React from 'react';

const ArrowRightUpLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ArrowRightUpLineIcon;
