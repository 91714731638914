export const checkRouteAccess = (viewType, availableViewTypes = []) => {
    return availableViewTypes.some((availableViewType) => viewType === availableViewType);
};

export const checkSpecialPermission = (
    orgPlan,
    userRole,
    allowedPlansAndRoles,
    needsSpecialPermission
) => {
    return needsSpecialPermission && orgPlan && userRole
        ? !!allowedPlansAndRoles?.find(
              (item) => item.plan === orgPlan && item.roles?.includes(userRole)
          )
        : true;
};
