import React, { memo } from 'react';
import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import { ButtonIcon } from '../../../design-system';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';

const PlaybookHeader = ({ playbookDetail, mode, openPlaybookEditModal, topControls = null }) => {
    const { name, description, icon_color } = playbookDetail;

    return (
        <div className="relative lg:rounded-2.5 z-10 px-4 xs:px-6 py-5 sm:p-8 lg:p-[40px] lg:ml-[27px]">
            <div
                className="absolute inset-0 lg:rounded-2.5 z-[-1] transition-colors"
                style={{ backgroundColor: icon_color, opacity: 0.2 }}
            ></div>

            {topControls}

            {mode === PLAYBOOK_BUILDER_MODE.edit && (
                <div className="absolute top-3 right-3 z-20">
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={EditLineIcon}
                        onClick={openPlaybookEditModal}
                    />
                </div>
            )}

            <div className="flex flex-col gap-6">
                <h1 className="font-body font-medium text-[32px] text-black leading-tight">
                    {name}
                </h1>
                {description && (
                    <p className="font-body text-body-regular-m text-black">{description}</p>
                )}
            </div>
        </div>
    );
};

export default memo(PlaybookHeader);
