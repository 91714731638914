import React, { useState } from 'react';
import classNames from 'classnames';

import InlineEditableField from '../InlineEditableField/InlineEditableField';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import { ButtonIcon } from '../../../design-system';

const EditableNameBlock = ({ name, handleUpdateName, defaultName }) => {
    const [isEditing, setIsEditing] = useState(false);

    const containerClassName = classNames(
        'flex items-center justify-center max-w-[calc(100%-50px)] md:max-w-[calc(100%-430px)] xl:max-w-[calc(100%-500px)] overflow-hidden',
        {
            'min-w-[240px]': isEditing,
        }
    );

    return (
        <div className={containerClassName}>
            <InlineEditableField
                value={name || defaultName}
                isEditing={isEditing}
                stopEditing={() => setIsEditing(false)}
                onSave={handleUpdateName}
                textStyles="font-body text-body-regular-s text-neutral-500"
            />
            {!isEditing && (
                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={EditLineIcon}
                    onClick={() => setIsEditing(true)}
                />
            )}
        </div>
    );
};

export default EditableNameBlock;
