import { handleCopy } from './handleCopy';

export const copyProcessConfig = (process, setCopyAlert = () => {}) => {
    const config = {
        process_id: process.id,
        version_id: process.default_version?.id,
        form:
            process.inputs?.reduce((inputs, input) => {
                inputs[input.key] = '';
                return inputs;
            }, {}) || {},
        process:
            process.outputs?.reduce((outputs, output) => {
                outputs[output.key] = '';
                return outputs;
            }, {}) || {},
    };
    // Format the JSON with a 4-space indent
    const prettyJson = JSON.stringify(config, null, 4);

    handleCopy(prettyJson, setCopyAlert, 'Process config copied to clipboard!');
};
