import React from 'react';

import { Button } from '../../../design-system';
import Loading from '../../../components/Loading';

const PageMobilePaginatedContainer = ({
    data,
    setData,
    dataHookResponse,
    setPage,
    requestKeysToMutate,
    cardComponent: CardComponent,
}) => {
    const { total, loading, hasNextPage: canLoadMore } = dataHookResponse;

    return (
        <div className="w-full flex flex-col gap-5">
            {!!data.length && (
                <ul className="w-full flex flex-col gap-2">
                    {data.map((item) => (
                        <li key={item.id}>
                            <CardComponent
                                data={item}
                                setData={setData}
                                requestKeysToMutate={requestKeysToMutate}
                            />
                        </li>
                    ))}
                </ul>
            )}
            {canLoadMore && total !== data?.length && (
                <Button
                    type="link"
                    size="xs"
                    text="Load More"
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                />
            )}
            {loading && (
                <div className="pt-3">
                    <Loading withText={!data.length} />
                </div>
            )}
        </div>
    );
};

export default PageMobilePaginatedContainer;
