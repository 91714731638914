import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from './useWindowSize';

export const useTextareaAutoResize = ({ text, minHeight = 0, maxHeight = 1000000 }) => {
    const textareaRef = useRef(null);
    const textareaContainerRef = useRef(null);

    const [isScrollable, setIsScrollable] = useState(false);

    const { width: screenWidth, height: screenHeight } = useWindowSize();

    useEffect(() => {
        if (textareaRef.current && textareaContainerRef.current) {
            textareaRef.current.style.height = 'auto';
            const scrollHeight = textareaRef.current.scrollHeight;

            const heightWithoutScroll = Math.max(scrollHeight, minHeight);
            const finalHeight = Math.min(heightWithoutScroll, maxHeight);

            textareaRef.current.style.height = finalHeight + 'px';
            textareaContainerRef.current.style.height = finalHeight + 'px';

            const scrollIsAdded = finalHeight < heightWithoutScroll;
            if (scrollIsAdded !== isScrollable) {
                setIsScrollable(scrollIsAdded);
            }
        }
    }, [textareaRef, textareaContainerRef, text, screenWidth, screenHeight]);

    return { textareaRef, textareaContainerRef, isScrollable };
};
