import React from 'react';
import { useNavigate } from 'react-router-dom';

import PlaybookHeader from '../PlaybookHeader/PlaybookHeader';
import { Button } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';

const PlaybookHeaderMobileView = ({
    mode,
    backLinkHref,
    playbookDetail,
    openPlaybookEditModal,
}) => {
    const navigate = useNavigate();

    const topControls = (
        <div className="flex items-center gap-5 mb-5">
            <Button
                type="link"
                size="xs"
                text="Back"
                leadingIcon={ArrowGoBackLineIcon}
                onClick={() => navigate(backLinkHref)}
            />
            <p className="font-body text-body-regular-s text-neutral-300">Playbook /</p>
        </div>
    );

    return (
        <PlaybookHeader
            playbookDetail={playbookDetail}
            mode={mode}
            topControls={topControls}
            openPlaybookEditModal={openPlaybookEditModal}
        />
    );
};

export default PlaybookHeaderMobileView;
