import React from 'react';

import { KNOWLEDGE_SOURCE_TYPE } from '../../../../constants/knowledge';

import KnowledgeSource from '../KnowledgeSource/KnowledgeSource';

const KnowledgeSection = ({ botId, bases, documents, setBotDetail }) => {
    return (
        <div className="p-5 sm:p-6 lg:p-8 flex flex-col gap-5 sm:gap-6">
            <div>
                <h2 className="font-heading-bold text-heading-bold-m text-black mb-2">Knowledge</h2>
                <p className="font-body text-body-regular-m text-neutral-300">
                    Connect this bot to available knowledge sources.
                </p>
            </div>

            <KnowledgeSource
                type={KNOWLEDGE_SOURCE_TYPE.bases}
                sources={bases}
                botId={botId}
                setBotDetail={setBotDetail}
            />

            <KnowledgeSource
                type={KNOWLEDGE_SOURCE_TYPE.documents}
                sources={documents}
                botId={botId}
                setBotDetail={setBotDetail}
            />
        </div>
    );
};

export default KnowledgeSection;
