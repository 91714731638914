import React from 'react';
import { Navigate } from 'react-router-dom';
import useUser from 'hooks/useUser';
import SideBar from 'components/SideBar/SideBar';
import { VIEW_TYPES_REDIRECT_PATH } from '../constants/viewTypes';
import { useViewType } from '../hooks/useViewType';
import { checkRouteAccess, checkSpecialPermission } from '../helpers/checkRouteAccess';

//route just for loggedIn users
const PrivateRoute = ({
    component: Component,
    redirectTo = '/login',
    availableViewTypes,
    availableForStaffOnly = false,
    needsSpecialPermission = false, //based on userRole and orgPlan
    allowedPlansAndRoles = [], // this should be an array structured [{plan: "agency", roles: ["owner", "admin"]}]
}) => {
    const { user, isValidating, orgPlan, userRole } = useUser();
    const isNotLoggedIn = !isValidating && !user;

    const { viewType } = useViewType();
    const hasViewTypesAccess = checkRouteAccess(viewType, availableViewTypes);

    const hasStaffAccess = availableForStaffOnly && user ? !!user?.is_staff : true;

    //based on userRole and orgPlan
    const hasSpecialPermission = checkSpecialPermission(
        orgPlan,
        userRole,
        allowedPlansAndRoles,
        needsSpecialPermission
    );

    if (isNotLoggedIn) {
        return <Navigate to={redirectTo} />;
    }

    if (!hasViewTypesAccess || !hasSpecialPermission || !hasStaffAccess) {
        return <Navigate to={VIEW_TYPES_REDIRECT_PATH[viewType]} />;
    }

    return (
        <div className="flex flex-row h-full w-full max-sm:pt-[60px]">
            <div className="sm:w-17 sm:min-w-17">
                <SideBar />
            </div>
            {Component}
        </div>
    );
};

export default PrivateRoute;
