import React from 'react';

const FileTextIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 32 32" fill="none">
            <path
                d="M18.6654 2.66699H7.9987C7.29145 2.66699 6.61318 2.94794 6.11308 3.44804C5.61298 3.94814 5.33203 4.62641 5.33203 5.33366V26.667C5.33203 27.3742 5.61298 28.0525 6.11308 28.5526C6.61318 29.0527 7.29145 29.3337 7.9987 29.3337H23.9987C24.7059 29.3337 25.3842 29.0527 25.8843 28.5526C26.3844 28.0525 26.6654 27.3742 26.6654 26.667V10.667L18.6654 2.66699Z"
                stroke={props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.3346 22.667H10.668"
                stroke={props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.3346 17.333H10.668"
                stroke={props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3346 12H12.0013H10.668"
                stroke={props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.668 2.66699V10.667H26.668"
                stroke={props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default FileTextIcon;
