import React from 'react';
import { commands } from '@uiw/react-md-editor';

import { ButtonIcon, SvgIcon } from '../index';
import TextIcon from '../Icons/TextIcon';
import ArrowDownSLineIcon from '../Icons/ArrowDownSLineIcon';
import BoldIcon from '../Icons/BoldIcon';
import ItalicIcon from '../Icons/ItalicIcon';
import StrikethroughIcon from '../Icons/StrikethroughIcon';
import LinkIcon from '../Icons/LinkIcon';
import SeparatorIcon from '../Icons/SeparatorIcon';
import CodeViewIcon from '../Icons/CodeViewIcon';
import UnderlineIcon from '../Icons/UnderlineIcon';
import H1Icon from '../Icons/H1Icon';
import H2Icon from '../Icons/H2Icon';
import H3Icon from '../Icons/H3Icon';
import EyeIcon from '../Icons/EyeIcon';
import EyeOffIcon from '../Icons/EyeOffIcon';
import ListOrderedIcon from '../Icons/ListOrderedIcon';
import ListCheckIcon from '../Icons/ListCheckIcon';
import { DeleteBin4LineIcon } from '../Icons';

const underlineCommand = {
    name: 'underline',
    keyCommand: 'underline',
    buttonProps: { 'aria-label': 'Underline selected text' },
    icon: <SvgIcon color="currentColor" size="medium" icon={UnderlineIcon} />,
    execute: (state, api) => {
        let modifyText = ` <ins>${state.selectedText}</ins> `;
        if (!state.selectedText) {
            modifyText = ` <ins></ins> `;
        }
        api.replaceSelection(modifyText);
    },
};

const titleCommandsArray = [
    {
        value: 'title1',
        label: 'Heading 1',
        icon: H1Icon,
    },
    {
        value: 'title2',
        label: 'Heading 2',
        icon: H2Icon,
    },
    {
        value: 'title3',
        label: 'Heading 3',
        icon: H3Icon,
    },
];

const textStylesCommandsArray = [
    {
        value: 'bold',
        label: 'Bold',
        icon: BoldIcon,
    },
    {
        value: 'italic',
        label: 'Italic',
        icon: ItalicIcon,
    },
    {
        value: 'underline',
        command: underlineCommand,
        label: 'Underline',
        icon: UnderlineIcon,
    },
    {
        value: 'strikethrough',
        label: 'Strikethrough',
        icon: StrikethroughIcon,
    },
];

const listCommandsArray = [
    {
        value: 'orderedListCommand',
        icon: ListOrderedIcon,
        label: 'Ordered List',
    },
    {
        value: 'unorderedListCommand',
        icon: ListCheckIcon,
        label: 'Unordered List',
    },
];

const insertCommandsArray = [
    {
        value: 'link',
        icon: LinkIcon,
        label: 'Link',
    },
    {
        value: 'hr',
        icon: SeparatorIcon,
        label: 'Horizontal Line',
    },
    {
        value: 'codeBlock',
        icon: CodeViewIcon,
        label: 'Code Block',
    },
];

const desktopCommandOptions = [
    ...textStylesCommandsArray,
    {
        value: 'divider',
    },
    ...listCommandsArray,
    ...insertCommandsArray,
    {
        value: 'divider',
    },
];

const createCommandsGroup = ({ commandsArray, groupName, buttonLabel, icon: Icon }) => {
    return commands.group(
        commandsArray.map((item) => ({
            ...(item.command ? item.command : commands[item.value]),
            icon: (
                <div className="flex items-end gap-2 font-body text-body-regular-xs text-neutral-500">
                    <SvgIcon color="currentColor" size="medium" icon={item.icon} />
                    {item.label}
                </div>
            ),
        })),
        {
            name: groupName,
            groupName: groupName,
            buttonProps: { 'aria-label': buttonLabel },
            icon: (
                <div className="flex items-center w-[32px]">
                    <Icon width={16} height={16} color="currentColor" />
                    <ArrowDownSLineIcon width={16} height={16} color="currentColor" />
                </div>
            ),
        }
    );
};

const titleCommandsGroup = createCommandsGroup({
    commandsArray: titleCommandsArray,
    groupName: 'title',
    buttonLabel: 'Insert title',
    icon: TextIcon,
});

const textStylesCommandsGroup = createCommandsGroup({
    commandsArray: textStylesCommandsArray,
    groupName: 'text',
    buttonLabel: 'Text Styles',
    icon: BoldIcon,
});

const listCommandsGroup = createCommandsGroup({
    commandsArray: listCommandsArray,
    groupName: 'list',
    buttonLabel: 'List Commands',
    icon: ListOrderedIcon,
});

const insertCommandsGroup = createCommandsGroup({
    commandsArray: insertCommandsArray,
    groupName: 'insertCommands',
    buttonLabel: 'Insert Commands',
    icon: LinkIcon,
});

const toolbarCommandsDesktopVersion = [
    titleCommandsGroup,
    ...desktopCommandOptions.map((item) =>
        item.command
            ? item.command
            : item.icon
            ? {
                  ...commands[item.value],
                  icon: <SvgIcon color="currentColor" size="medium" icon={item.icon} />,
              }
            : commands[item.value]
    ),
];

const toolbarCommandsMobileVersion = [
    titleCommandsGroup,
    textStylesCommandsGroup,
    listCommandsGroup,
    insertCommandsGroup,
];

export const getPreviewModeExtraCommand = (mode, setMode) => {
    return {
        name: 'preview',
        keyCommand: 'preview',
        buttonProps: { 'aria-label': 'Change preview mode' },
        icon: (
            <>
                {mode === 'edit' ? (
                    <EyeIcon width={16} height={16} color="currentColor" />
                ) : (
                    <EyeOffIcon width={16} height={16} color="currentColor" />
                )}
            </>
        ),
        execute: () => {
            // toggle preview mode
            setMode((prevMode) => (prevMode === 'preview' ? 'edit' : 'preview'));
        },
    };
};

export const getDeleteExtraCommand = (handleDelete) => {
    return {
        name: 'delete',
        keyCommand: 'delete',
        render: () => {
            return (
                <div className="flex items-center mt-[-3px]">
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        state="default"
                        icon={DeleteBin4LineIcon}
                        onClick={handleDelete}
                    />
                </div>
            );
        },
    };
};

export const getToolbarCommands = (isExtendedToolBar, isMobile = false, mode, setMode) => {
    const toolbarCommands = isMobile ? toolbarCommandsMobileVersion : toolbarCommandsDesktopVersion;

    if (!isExtendedToolBar) {
        return toolbarCommands;
    }

    if (isExtendedToolBar) {
        return [...toolbarCommands, getPreviewModeExtraCommand(mode, setMode)];
    }
};
