import React, { useState } from 'react';

import { sortOptions } from '../../../constants/threads';
import { handleSortOptionSelect } from '../../../helpers/handleUpdateSearchParams';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useSearchParams } from 'react-router-dom';

import { Button } from '../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../design-system/Icons';
import SortDropdown from '../../../components/SortDropdown/SortDropdown';
import SearchBarWithUrlParamsHandling from '../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';

const ExecutionTopControls = ({ isMobile, resetPageState, parsedParams }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [isSortDropdownOpened, setIsSortDropdownOpened] = useState(false);

    const isBigScreen = useMediaQuery('(min-width: 768px)');

    const handleSort = (sortOption) => {
        handleSortOptionSelect({
            searchParams,
            setSearchParams,
            sortOption,
            usePagination: !isMobile,
            usePageState: true,
            resetPageState,
            onSearchDropdownClose: () => setIsSortDropdownOpened(false),
        });
    };

    const sortDropdownPosition = {
        top: '130%',
        left: isBigScreen ? 0 : 'auto',
        right: !isBigScreen ? 0 : 'auto',
    };
    return (
        <div className="flex items-center gap-2">
            <SearchBarWithUrlParamsHandling
                size="xs"
                maxWidth={300}
                placeholder="Search by execution name"
                usePagination={!isMobile}
                resetPageState={resetPageState}
                usePageState={true}
            />
            <div className="relative">
                <Button
                    type="link"
                    size="xs"
                    text="Sort"
                    trailingIcon={isSortDropdownOpened ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                    onClick={() => setIsSortDropdownOpened(true)}
                />
                {isSortDropdownOpened && (
                    <SortDropdown
                        options={sortOptions}
                        selectedOption={parsedParams.sort}
                        handleOptionSelect={handleSort}
                        dropdownPosition={sortDropdownPosition}
                        onClose={(e) => {
                            e?.stopPropagation();
                            e?.preventDefault();
                            setIsSortDropdownOpened(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default ExecutionTopControls;
