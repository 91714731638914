import React, { useState } from 'react';
import { TASK_TYPES } from '../../../constants/library';
import GoalsMultiSelect from '../../LibraryPage/TaskPlaygroundPage/GoalsMultiSelect/GoalsMultiSelect';

const AssistantAndAgentTasksBlock = ({
    data,
    setData,
    goalOptions,
    goalOptionsLoading,
    setGoalPage,
    totalGoalOptions,
    canLoadMoreGoalOptions,
    errorFields,
    setErrorFields,
}) => {
    const filteredGoalOptions = goalOptions.filter((goal) => !data.goals?.includes(goal.id));
    const taskType = data.type;

    const [addedGoalsBadgeInfo, setAddedGoalsBadgeInfo] = useState(
        goalOptions?.filter((item) => data.goals.includes(item.id)) || []
    );

    const handleSelectChange = (selectedValue) => {
        setData((prevData) => ({ ...prevData, selectedGoal: selectedValue }));
    };

    const handleAddGoalClick = () => {
        const selectedGoalObj = goalOptions.find((item) => item.id === data.selectedGoal);
        if (selectedGoalObj) {
            setData((prevData) => ({
                ...prevData,
                goals: [...prevData.goals, selectedGoalObj.id],
                selectedGoal: '',
            }));
            setAddedGoalsBadgeInfo((prevData) => [...prevData, selectedGoalObj]);
        }
        if (errorFields.goals) {
            setErrorFields((prevData) => ({ ...prevData, goals: false }));
        }
    };

    const handleGoalBadgeRemove = (id) => {
        setData((prevData) => ({
            ...prevData,
            goals: [...prevData.goals?.filter((goalId) => goalId !== id)],
        }));
        setAddedGoalsBadgeInfo((prevData) => prevData.filter((item) => item.id !== id));
    };

    const selectPlaceholder = {
        [TASK_TYPES.assistant]: 'Select a goal and click + to add it as a router option',
        [TASK_TYPES.function]: 'Select a goal and click + to add it as a tool option',
    };

    return (
        <GoalsMultiSelect
            taskType={taskType}
            options={filteredGoalOptions}
            selectedGoal={data.selectedGoal}
            addedGoalsBadgeInfo={addedGoalsBadgeInfo}
            optionsLoading={goalOptionsLoading}
            handleAddGoalClick={handleAddGoalClick}
            handleSelectChange={handleSelectChange}
            setPage={setGoalPage}
            canLoadMoreOptions={canLoadMoreGoalOptions}
            handleRemoveGoalBadge={handleGoalBadgeRemove}
            isError={errorFields.goals}
            totalOptions={totalGoalOptions}
            selectPlaceholder={selectPlaceholder[taskType]}
        />
    );
};

export default AssistantAndAgentTasksBlock;
