import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '../index';
import Checkbox from '../Checkbox/Checkbox';

const CheckboxListItem = ({ item, icon: Icon, isChecked, handleChange }) => {
    const handleItemClick = (event, item) => {
        event?.stopPropagation();
        event?.preventDefault();

        handleChange(item);
    };

    return (
        <div
            className="px-3 py-2 w-full max-w-full flex items-center gap-2 truncate rounded-[6px] hover:bg-neutral-50 cursor-pointer min-h-[37px] bg-white transition-all"
            onClick={(event) => handleItemClick(event, item)}
        >
            <Checkbox isChecked={isChecked} name="item" onChange={() => {}} />
            <div className="flex items-center gap-1 overflow-hidden">
                <SvgIcon color="#754DCF" size="medium" icon={Icon} />
                <p className="flex-1 font-body-bold text-body-bold-s text-[#25324B] truncate">
                    {item.label}
                </p>
            </div>
        </div>
    );
};

CheckboxListItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
    isChecked: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    icon: PropTypes.func.isRequired,
};

export default CheckboxListItem;
