import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { parseInboxSearchParams } from '../../../helpers/parseInboxSearchParams';
import { STATUS_TAB_KEYS } from '../../../constants/inbox';

import MessageContentBlock from '../MessageContentBlock/MessageContentBlock';
import MessageDetailControls from '../MessageDetailControls/MessageDetailControls';
import MessageDetailHeader from '../MessageDetailHeader/MessageDetailHeader';
import InboxTaskApprovedBanner from '../InboxTaskApprovedBanner/InboxTaskApprovedBanner';

const MessageDetailPanel = ({
    message,
    currentMessageIndex,
    handlePrevClick,
    handleNextClick,
    messagesLength,
    setData,
    requestKeysToMutate,
    messageContentFormData,
    setMessageContentFormData,
    showTaskApprovedSnackAlert,
    messageContentBodyTypeFormFieldsCount,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedSearchParams = parseInboxSearchParams(searchParams);

    const containerRef = useRef(null);

    const isMessageCompleted = message.status === STATUS_TAB_KEYS.completed;
    const isApprovedBannerDisplayed = isMessageCompleted && message.thread;

    const openExpandedMessageDetailView = () => {
        setSearchParams({ ...parsedSearchParams, message_id: message.id });
    };

    return (
        <>
            {message && (
                <div className="h-full flex flex-col overflow-y-auto pb-[48px]">
                    <MessageDetailControls
                        message={message}
                        currentMessageIndex={currentMessageIndex}
                        handlePrevClick={handlePrevClick}
                        handleNextClick={handleNextClick}
                        messagesLength={messagesLength}
                        handleFullScreenClick={openExpandedMessageDetailView}
                        setData={setData}
                        requestKeysToMutate={requestKeysToMutate}
                    />
                    <div className="px-5 pt-5 pb-[48px] flex flex-col gap-6" ref={containerRef}>
                        {isApprovedBannerDisplayed && (
                            <InboxTaskApprovedBanner threadId={message.thread} />
                        )}
                        <div>
                            <MessageDetailHeader message={message} containerRef={containerRef} />
                            <MessageContentBlock
                                message={message}
                                setData={setData}
                                requestKeysToMutate={requestKeysToMutate}
                                formData={messageContentFormData}
                                setFormData={setMessageContentFormData}
                                bodyTypeFormFieldsCount={messageContentBodyTypeFormFieldsCount}
                                setNextMessageAfterMessageApproval={handleNextClick}
                                showTaskApprovedSnackAlert={showTaskApprovedSnackAlert}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MessageDetailPanel;
