import React from 'react';
import { useFormState } from '../../../../hooks/useFormState';

import { checkIfKeyIsValid } from '../../../../helpers/checkIfKeyIsValid';
import { emptyFieldErrorMessage } from '../../../../constants/errorMessages';
import { allInputDataTypeOptions } from '../../../../constants/formVariables';
import {
    getAllowedActionTypes,
    getInputActionTypeOptions,
} from '../../../../helpers/formVariablesUtils';

import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import Toggle from '../../../../design-system/Toggle/Toggle';
import OptionsInput from '../../../../design-system/OptionsInput/OptionsInput';
import { Button, Input, Select, TextArea } from '../../../../design-system';

const modalTitle = {
    create: 'Add New Form Input',
    edit: ' Edit Form  Input',
};
const requiredFields = ['key', 'label', 'type', 'action'];

const FormInputModal = ({ inputData, mode, inputs, setInputs, onClose }) => {
    const initialFormState =
        mode === 'edit'
            ? {
                  key: inputData.key,
                  label: inputData.label,
                  description: inputData.description,
                  type: inputData.type,
                  action: inputData.action,
                  options: inputData.options ? Object.keys(inputData.options) : null,
                  is_required: inputData.is_required,
              }
            : {
                  key: '',
                  label: '',
                  description: '',
                  type: null,
                  action: null,
                  options: null,
                  is_required: true,
              };

    const {
        formData,
        fieldErrorMessages,
        setFieldErrorMessages,
        handleInputChange,
        handleAddNewOption,
        handleRemoveOption,
    } = useFormState(initialFormState);

    const checkIfKeyIsUnique = (key) => {
        const keyIsAlreadyInUse = inputs?.some((input) => input.key === key);

        return !keyIsAlreadyInUse;
    };

    const handleCreateInput = () => {
        setInputs((prevInputs) => {
            const { key, label, type, action, is_required, description, options } = formData;
            // Create a temporaryId to use it as a key when mapping over formData (cannot use 'order' because if key={order} drag and drop does not work correctly). This temporaryId will not be sent to the backend!
            const temporaryId = new Date().getTime().toString();
            // add all required fields
            const createdInput = {
                key,
                label,
                type,
                action,
                is_required,
                order: prevInputs.length + 1,
                temporaryId,
            };
            // add description if it is filled
            if (description) {
                createdInput.description = description;
            }
            // add options if there are any
            if (options) {
                createdInput.options = options.reduce(
                    (acc, option) => ({ ...acc, [option]: option }),
                    {}
                );
            }
            return [...prevInputs, createdInput];
        });
    };

    const handleEditInput = () => {
        setInputs((prevInputs) => {
            const { key, label, type, action, is_required, description, options } = formData;
            // update all required fields
            const updatedInput = {
                ...inputData,
                key,
                label,
                type,
                action,
                is_required,
            };
            // add description if it is filled
            if (description) {
                updatedInput.description = description;
            }
            // add options if there are any
            if (options) {
                updatedInput.options = options.reduce(
                    (acc, option) => ({ ...acc, [option]: option }),
                    {}
                );
            }
            return prevInputs.map((input) =>
                input.order === inputData.order ? updatedInput : input
            );
        });
    };

    const handleAction = {
        create: handleCreateInput,
        edit: handleEditInput,
    };

    const handleSave = () => {
        const areEmptyRequiredFields = requiredFields.some((field) => !formData[field]);
        if (areEmptyRequiredFields) {
            setFieldErrorMessages({
                key: !formData.key ? emptyFieldErrorMessage : null,
                label: !formData.label ? emptyFieldErrorMessage : null,
                type: !formData.type ? 'Please select a type.' : null,
                action: !formData.action ? 'Please select an action.' : null,
            });
            return;
        }

        const isKeyValid = checkIfKeyIsValid(formData.key);
        if (!isKeyValid) {
            setFieldErrorMessages({ key: 'Input key needs to be all lowercase with no spaces.' });
            return;
        }

        if (mode === 'create') {
            // Check if the key is unique only in the "create" mode.
            // This is because if a user duplicates an input and intends to update something in the input where the "Duplicate" button was clicked but user wants to update key in duplicated input (not in the current), but the key has not been updated yet in the duplicated input, the key will not be unique.
            // Consequently, the user will not be able to save updates for such input without first updating the key in duplicated input.
            const isKeyUnique = checkIfKeyIsUnique(formData.key);
            if (!isKeyUnique) {
                setFieldErrorMessages({
                    key: 'Input key needs to be unique.',
                });
                return;
            }
        }

        handleAction[mode]();
        onClose();
    };

    const handleDataTypeChange = (type) => {
        handleInputChange('type', type);

        // if selected type is not allowed for selected action type, set action type null
        const allowedActionTypes = getAllowedActionTypes(type);
        if (formData.action && !allowedActionTypes.includes(formData.action)) {
            handleInputChange('action', null);
        }
    };

    return (
        <Modal onClose={onClose} size="medium">
            <div className="sm:p-2 flex flex-col gap-6 sm:gap-8">
                <ModalHeader title={modalTitle[mode]} onClose={onClose} />
                <div className="flex flex-col gap-4 sm:gap-5">
                    <Input
                        size="md"
                        name="key"
                        value={formData.key}
                        label="Key"
                        isRequired
                        state={fieldErrorMessages.key ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.key}
                        tipText="Input key needs to be all lowercase with no spaces."
                        placeholder="Add variable key"
                        onChange={(e) => handleInputChange('key', e.target.value)}
                    />
                    <Input
                        size="md"
                        name="label"
                        value={formData.label}
                        label="Label"
                        isRequired
                        state={fieldErrorMessages.label ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.label}
                        placeholder="Add variable label"
                        onChange={(e) => handleInputChange('label', e.target.value)}
                    />
                    <TextArea
                        name="description"
                        value={formData.description}
                        label="Description"
                        state={fieldErrorMessages.description ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.description}
                        placeholder="Add variable description"
                        onChange={(e) => handleInputChange('description', e.target.value)}
                        height="56px"
                    />
                    <Select
                        size="md"
                        name="type"
                        value={formData.type}
                        options={allInputDataTypeOptions}
                        label="Type"
                        isRequired
                        state={fieldErrorMessages.type ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.type}
                        placeholder="Select an option"
                        dropdownHeight={220}
                        includeClientSideFiltering
                        disableSorting
                        onChange={handleDataTypeChange}
                    />
                    <Select
                        size="md"
                        name="action"
                        value={formData.action}
                        options={getInputActionTypeOptions(formData.type)}
                        label="Action"
                        isRequired
                        state={fieldErrorMessages.action ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.action}
                        placeholder="Select an option"
                        dropdownHeight={220}
                        includeClientSideFiltering
                        disableSorting
                        onChange={(value) => handleInputChange('action', value)}
                    />
                    <OptionsInput
                        options={formData.options || []}
                        label="Options"
                        state={fieldErrorMessages.action ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.action}
                        placeholder="Add your input and click + to add them"
                        handleAddNewOption={(option) => handleAddNewOption('options', option)}
                        handleRemoveOption={(option) => handleRemoveOption('options', option)}
                        optionsBadgeColor="neutral"
                        optionsBadgeFontStyle="text-body-regular-xs"
                        containerGap={16}
                    />
                    <Toggle
                        size="sm"
                        name="is_required"
                        isSelected={formData.is_required}
                        label="Required"
                        isRequired
                        onChange={(value) => handleInputChange('is_required', value)}
                    />
                </div>
                <div className="flex items-center gap-3 justify-between">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text={`${mode === 'create' ? 'Add New' : 'Save'}`}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default FormInputModal;
