import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { VIEW_TYPES } from '../../../constants/viewTypes';
import { useViewType } from '../../../hooks/useViewType';

import { Button } from '../../../design-system';
import { AddCircleLineIcon } from '../../../design-system/Icons';
import SearchBarWithUrlParamsHandling from '../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';

const BasesPageHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { viewType } = useViewType();

    return (
        <>
            <div>
                <p className="font-heading-bold text-heading-bold-l">Knowledge Base</p>
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Store business context in your organization’s DiviUp Knowledge Graph.
                </p>
            </div>

            <div className="flex flex-col-reverse xs:items-center xs:justify-between gap-x-4 gap-y-2 xs:flex-row">
                <div className="w-full xs:max-w-[300px] xs:flex-1">
                    <SearchBarWithUrlParamsHandling size="xs" placeholder="Search..." />
                </div>

                {viewType === VIEW_TYPES.admin && (
                    <Button
                        type="primary"
                        size="xs"
                        text="New Base"
                        state="disabled"
                        leadingIcon={AddCircleLineIcon}
                        onClick={() => navigate('/base/new', { state: { from: location } })}
                    />
                )}
            </div>
        </>
    );
};

export default BasesPageHeader;
