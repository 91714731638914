import { useEffect, useState } from 'react';

import WebSocketInstance from '../services/websocket';

export const useWebSocketMessageStream = ({
    onStreamStart,
    onMessageStream,
    onStreamEnd,
    onStreamError,
}) => {
    // requestUuid is used for websocket messages
    const [generationId, setGenerationId] = useState(null);

    const clearGenIdAfterAction = (func, ...args) => {
        func(...args);
        setGenerationId(null);
    };

    useEffect(() => {
        WebSocketInstance.connect();
        WebSocketInstance.addCallbacks(
            onStreamStart,
            onMessageStream,
            (...args) => clearGenIdAfterAction(onStreamEnd, ...args),
            (...args) => clearGenIdAfterAction(onStreamError, ...args)
        );

        return () => {
            if (WebSocketInstance.isOpen()) {
                WebSocketInstance.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        WebSocketInstance.setCurrentPageGenId(generationId);
    }, [generationId]);

    const tryReconnectIfWebsocketClosed = async () => {
        // use this func inside try {} catch {} block
        if (!WebSocketInstance.isOpen()) {
            await WebSocketInstance.attempt_reconnect({
                shouldThrowError: true,
            });
        }
    };

    const stopStreaming = () => {
        const abortedGenerationId = generationId;
        setGenerationId(null);

        return abortedGenerationId;
    };

    return { setGenerationId, tryReconnectIfWebsocketClosed, stopStreaming };
};
