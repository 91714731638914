export function getLinkedDocumentsTagMarkup(docId, label) {
    let labelMarkup = ``;

    if (label) {
        labelMarkup = `<p class="font-body-medium text-body-medium-xs text-purple-500">{${label}}</p>`;
    }

    if (!label) {
        labelMarkup = `<div class="animate-spin-slow ml-1">${loaderIcon}</div>`;
    }

    return `<div class="inline-block transform translate-y-[3px]"><div class="flex items-center gap-2 py-1 pl-2 pr-1 rounded-2 bg-purple-100"><div class="flex items-center gap-1">${tagLeadingIcon} ${labelMarkup}</div> <a target="_blank" href="/doc/${docId}">${linkArrowIcon}</a></div></div>`;
}

const tagLeadingIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 7.66646V5.3335L9.99787 1.3335H2.66814C2.29913 1.3335 2 1.63704 2 1.9947V14.0056C2 14.3708 2.29663 14.6668 2.66227 14.6668H6.99963C6.69783 14.265 6.45515 13.8163 6.28451 13.3335H3.33333V2.66683H9.33333V6.00016H12.6667V6.95134C13.1494 7.12198 13.5982 7.36466 14 7.66646ZM8.50388 7.3335H5.33333V8.66683H6.99963C7.40488 8.12732 7.91672 7.67245 8.50388 7.3335ZM6.28451 10.0002H5.33333V11.3335H6.01093C6.04152 10.8689 6.13557 10.4215 6.28451 10.0002ZM7.33333 4.66683H5.33333V6.00016H7.33333V4.66683Z"
            fill="#754DCF"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.7602 9.3335H9.17379V10.7554H11.3328L8.66602 13.4222L9.67147 14.4277L12.3383 11.7609V13.9199H13.7602V9.3335Z"
            fill="#754DCF"
        />
    </svg>`;

const linkArrowIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.6688 6.27614L4.93109 12.0139L3.98828 11.0711L9.72601 5.33333H4.66883V4H12.0021V11.3333H10.6688V6.27614Z"
        fill="#5E6470"
    />
  </svg>`;

const loaderIcon = `<svg width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.5 15V18.3333"
        stroke="#754DCF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M14.0352 13.5333L16.3935 15.8917"
        stroke="#754DCF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M4.60938 15.8917L6.96771 13.5333"
        stroke="#754DCF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M15.5 10H18.8333"
        stroke="#754DCF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M2.16797 10H5.5013"
        stroke="#754DCF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M14.0352 6.46661L16.3935 4.10828"
        stroke="#754DCF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M4.60938 4.10828L6.96771 6.46661"
        stroke="#754DCF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M10.5 1.66663V4.99996"
        stroke="#754DCF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>`;
