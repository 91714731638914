import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { handleDuplicateSelectedItems } from '../../../helpers/docsUtils';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import SuccessAlert from '../../../design-system/SuccessAlert/SuccessAlert';
import ArchiveLineIcon from '../../../design-system/Icons/ArchiveLineIcon';
import FileCopy2FillIcon from '../../../design-system/Icons/FileCopy2FillIcon';
import FolderTransferFillIcon from '../../../design-system/Icons/FolderTransferFillIcon';
import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';

const SelectedItemsActionBar = ({
    selectedItems,
    selectedItemsCount,
    currentRequestKeyToMutate,
    requestKeysToMutate,
    setSelectedItemsActionModal,
    clearAllSelectedItems,
}) => {
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false);

    const [errorAlert, setErrorAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    const { location: parentLocation, setSidePanelFolders } = useDocsPageContext();

    const duplicateSelectedItems = async () => {
        await handleDuplicateSelectedItems({
            selectedItems,
            setErrorAlert,
            setSuccessAlert,
            setIsLoading,
            currentRequestKeyToMutate,
            requestKeysToMutate,
            parentLocation,
            setSidePanelFolders,
            skipUpdatingSidePanel: searchParams.get('search'),
            clearAllSelectedItems,
        });
    };

    return (
        <div className="fixed bottom-0 left-0 sm:left-[68px] right-0 p-4 bg-white border-t-1 border-neutral-200 z-52 h-[73px] md:static md:p-0 md:border-none md:bg-transparent md:h-auto md:w-full md:max-w-full">
            <div className="h-full md:h-auto w-full max-w-full px-4 py-1 rounded-2 flex items-center justify-between gap-2 bg-neutral-200/30">
                <p className="font-body-bold text-body-bold-xs text-neutral-500">
                    {selectedItemsCount} item{selectedItems !== 1 && 's'} selected
                </p>

                <div className="flex gap-3 xs:gap-4">
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={FolderTransferFillIcon}
                        onClick={() => setSelectedItemsActionModal({ action: 'move' })}
                    />
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={FileCopy2FillIcon}
                        state={isLoading ? 'loading' : 'default'}
                        onClick={duplicateSelectedItems}
                        showSpinnerOnLoading
                    />
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={ArchiveLineIcon}
                        onClick={() => setSelectedItemsActionModal({ action: 'multiple-archive' })}
                    />
                    <div className="pl-3 xs:pl-4 border-l-1 border-neutral-200">
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={CloseLineIcon}
                            onClick={clearAllSelectedItems}
                        />
                    </div>
                </div>

                <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
                <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            </div>
        </div>
    );
};

export default SelectedItemsActionBar;
