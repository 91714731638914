import React from 'react';

const ClockIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 14 14" fill="none">
            <g>
                <path
                    d="M6.99935 12.8337C10.221 12.8337 12.8327 10.222 12.8327 7.00033C12.8327 3.77866 10.221 1.16699 6.99935 1.16699C3.77769 1.16699 1.16602 3.77866 1.16602 7.00033C1.16602 10.222 3.77769 12.8337 6.99935 12.8337Z"
                    stroke={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7 3.5V7L9.33333 8.16667"
                    stroke={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default ClockIcon;
