import React, { useEffect, useState } from 'react';

import { ListOptionGroup } from '../../../design-system';
import SelectOption from '../../../design-system/SelectOption/SelectOption';
import Loading from '../../../components/Loading';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import ClientSideFilteredSearchBar from '../../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';

const AgentDropdown = ({
    onClose,
    options,
    selectedOptionId,
    areOptionsLoading,
    handleOptionSelect,
    totalOptionsCount,
    startingOptionCountForFilterBar = 6,
    isMobile,
}) => {
    const [filteredOptions, setFilteredOptions] = useState(options || []);

    const showClientSideFilterBar = totalOptionsCount >= startingOptionCountForFilterBar;

    useEffect(() => {
        if (!showClientSideFilterBar) {
            setFilteredOptions(options || []);
        }
    }, [options, showClientSideFilterBar]);

    const handleOptionClick = (id) => {
        handleOptionSelect(id);
        onClose();
    };

    let listOptions = null;

    if (!options?.length && areOptionsLoading) {
        listOptions = (
            <div className="w-full flex justify-center">
                <Loading withText={false} size={isMobile ? 'medium' : 'small'} />
            </div>
        );
    }

    if (!!options?.length) {
        listOptions = (
            <div className="flex flex-col gap-2 w-full">
                {filteredOptions.map((option) => {
                    const { id } = option;
                    const isSelected = id === selectedOptionId;

                    return (
                        <div
                            key={id}
                            onClick={() => handleOptionClick(id)}
                            className={`w-full font-body-medium text-body-medium-xs`}
                        >
                            <SelectOption
                                option={option}
                                isSelected={isSelected}
                                withCheckIconForSelected
                                leadingIconSize="medium"
                            />
                        </div>
                    );
                })}

                {areOptionsLoading && (
                    <div className="w-full flex justify-center">
                        <Loading withText={false} size="small" />
                    </div>
                )}
            </div>
        );
    }

    if (isMobile) {
        return (
            <MobilePopup onClose={onClose} closeButtonText="Cancel" title="Choose a Model">
                {showClientSideFilterBar && (
                    <div className="w-full px-[1px] mb-3">
                        <ClientSideFilteredSearchBar
                            list={options || []}
                            setFilteredList={setFilteredOptions}
                            size="xs"
                            keyToFilter={['name']}
                        />
                    </div>
                )}

                <div className="flex flex-col gap-1">{listOptions}</div>
            </MobilePopup>
        );
    }

    return (
        <div className="absolute right-0 top-[110%] z-20">
            <ListOptionGroup
                listOptions={listOptions}
                fixedWidth={300}
                maxHeight={380}
                handleClose={onClose}
                includeClientSideFiltering={showClientSideFilterBar}
                list={options}
                setFilteredList={setFilteredOptions}
                keyToFilter={['name']}
            />
        </div>
    );
};

export default AgentDropdown;
