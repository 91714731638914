import React from 'react';

const ToolTipArrowIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 12 7">
            <path
                d="M4.4 1.13333L0 7L12 7L7.6 1.13333C6.8 0.0666671 5.2 0.0666677 4.4 1.13333Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ToolTipArrowIcon;
