import React from 'react';

import { ListOption, ListOptionGroup } from '../../../design-system';

const ProjectCardMoreOptionsPopup = ({ setActionModal, onClose }) => {
    const openActionModal = (action) => {
        setActionModal({ action });
        onClose();
    };

    return (
        <div
            className="absolute top-[110%] right-[4px] z-55 pb-4"
            onClick={(e) => e.preventDefault()}
        >
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editIcon"
                            text="Rename"
                            onClick={() => openActionModal('rename')}
                        />
                        <ListOption
                            leadingIconName="inboxArchiveLineIcon"
                            text="Archive"
                            onClick={() => openActionModal('archive')}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};

export default ProjectCardMoreOptionsPopup;
