import React from 'react';
import { DateTime } from 'luxon';

import { sectionContainerStyles } from '../PlaybookDetailsPanel/PlaybookDetailsPanel';
import DetailContainer from '../../../design-system/DetailContainer/DetailContainer';
import UserBadge from '../../../design-system/UserBadge/UserBadge';

const CreationAndUpdateLog = ({ playbook }) => {
    const { created_at, created_by, last_updated_by } = playbook;

    const formattedCreatedAt = DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - hh:mm a');
    const formattedLastUpdated = DateTime.fromISO(last_updated_by.updated_at).toFormat(
        'MM/dd/yyyy - hh:mm a'
    );

    return (
        <div className={`flex flex-col gap-4 ${sectionContainerStyles}`}>
            <DetailContainer label="Created At" desktopStartPixel={0} maxWidth="100%">
                {formattedCreatedAt}
            </DetailContainer>

            <DetailContainer label="Created by" desktopStartPixel={0} maxWidth="100%">
                <UserBadge name={created_by.name} color="purple" />
            </DetailContainer>

            <DetailContainer label="Last Updated" desktopStartPixel={0} maxWidth="100%">
                {formattedLastUpdated}
            </DetailContainer>

            <DetailContainer label="Last Updated by" desktopStartPixel={0} maxWidth="100%">
                <UserBadge name={last_updated_by.name} color="purple" />
            </DetailContainer>
        </div>
    );
};

export default CreationAndUpdateLog;
