import { DateTime } from 'luxon';

const getFormattedTime = (last_run_at) => DateTime.fromISO(last_run_at).toRelative();

export const handlePromptLocalStorageSave = (data, organizationId) => {
    if (data) {
        const savedData = JSON.parse(localStorage.getItem('recent-viewed-data') || '[]');

        let newTask = {};

        if (data.default_prompt_version) {
            const model = data.default_prompt_version.settings.model;

            newTask = {
                type: 'prompt',
                id: data.id,
                taskId: data.task.id,
                name: data.task.name,
                organizationId: organizationId,
                model,
                last_run_at: getFormattedTime(data.last_run_at) || 'No runs yet',
            };
        } else {
            const model = data.default_prompt.default_prompt_version.settings.model;

            newTask = {
                type: 'prompt',
                id: data.default_prompt?.id,
                taskId: data.id,
                name: data.name,
                organizationId: organizationId,
                model,
                last_run_at: getFormattedTime(data.last_run_at) || 'No runs yet',
            };
        }

        const sameOrgSavedItems = savedData.filter(
            (item) =>
                item.organizationId === organizationId &&
                (item.type !== 'prompt' || item.id !== newTask.id)
        ); // items saved in local storage with the same organizationId as 'newTask', excluding 'newTask' itself.

        const anotherOrgSavedItems = savedData.filter(
            (item) => item.organizationId !== organizationId
        ); // items saved in local storage with different organizationId as 'newTask'

        if (sameOrgSavedItems?.length >= 5) {
            // if there are more than 4 items with the same organizationId remove the first redundant items to ensure only the last 4 items remain.
            sameOrgSavedItems.splice(0, sameOrgSavedItems.length - 4);
        }

        const newDataToSave = [...anotherOrgSavedItems, ...sameOrgSavedItems, newTask];
        localStorage.setItem('recent-viewed-data', JSON.stringify(newDataToSave));
    }
};

export const handleTaskLocalStorageSave = (data, organizationId) => {
    if (data) {
        try {
            const savedData = JSON.parse(localStorage.getItem('recent-viewed-data') || '[]');

            const newTask = {
                type: 'task',
                id: data.id,
                taskId: data.id,
                taskType: data.task_type,
                name: data.name,
                organizationId: organizationId,
                last_run_at: getFormattedTime(data.last_run_at) || 'No runs yet',
            };

            const sameOrgSavedItems = savedData.filter(
                (item) =>
                    item.organizationId === organizationId &&
                    (item.type !== 'task' || item.id !== newTask.id)
            ); // items saved in local storage with the same organizationId as 'newTask', excluding 'newTask' itself.

            const anotherOrgSavedItems = savedData.filter(
                (item) => item.organizationId !== organizationId
            ); // items saved in local storage with different organizationId as 'newTask'

            if (sameOrgSavedItems?.length >= 5) {
                // if there are more than 4 items with the same organizationId remove the first redundant items to ensure only the last 4 items remain.
                sameOrgSavedItems.splice(0, sameOrgSavedItems.length - 4);
            }

            const newDataToSave = [...anotherOrgSavedItems, ...sameOrgSavedItems, newTask];
            localStorage.setItem('recent-viewed-data', JSON.stringify(newDataToSave));
        } catch (e) {
            console.log('error with parse', e);
        }
    }
};
