import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../constants/organization';
import {
    DEFAULT_VIEW_LOCAL_STORAGE_KEY,
    LIBRARY_SORT_TYPES,
    LIBRARY_VIEW_TYPES,
} from 'constants/library';
import { parseLibrarySearchParams } from 'services/library-utils';
import LibraryTopControls from './LibraryTopControls/LibraryTopControls';
import PaginatedContainer from './PaginatedContainer/PaginatedContainer';
import CollectionsPanel from './CollectionsPanel/CollectionsPanel';

const LibraryPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const tabIndex = path.includes('shared') ? 1 : 0;
    const libraryType = path.includes('shared')
        ? LIBRARY_VIEW_TYPES.shared
        : LIBRARY_VIEW_TYPES.personal;

    const savedViewSetting = localStorage.getItem(DEFAULT_VIEW_LOCAL_STORAGE_KEY);
    const { user, orgPlan, userRole } = useUser();

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = parseLibrarySearchParams(searchParams);
    const [collections, setCollections] = useState([]);
    const [allCollectionsAreLoaded, setAllCollectionsAreLoaded] = useState(false);
    // list or grid view
    const [libraryView, setLibraryView] = useState('list');

    useDocumentTitle('Library');

    useEffect(() => {
        let pageNumber = Number.isNaN(parsedParams.page) ? 1 : parsedParams.page;
        let sort = Object.values(LIBRARY_SORT_TYPES).includes(parsedParams.sort)
            ? parsedParams.sort
            : LIBRARY_SORT_TYPES.alphabetical;

        if (Number.isNaN(parsedParams.page) || parsedParams.sort !== sort) {
            setSearchParams({
                ...parsedParams,
                page: pageNumber,
                sort: sort,
            }); // Ensure to use parsedParams
        }
    }, [parsedParams, savedViewSetting, setSearchParams]);

    const onTabChanged = (tab, index) => {
        const type = index === 0 ? LIBRARY_VIEW_TYPES.personal : LIBRARY_VIEW_TYPES.shared;
        navigate(
            type === LIBRARY_VIEW_TYPES.personal
                ? `/library?page=1&sort=${LIBRARY_SORT_TYPES.alphabetical}`
                : `/library/shared?page=1&sort=${LIBRARY_SORT_TYPES.alphabetical}`
        );
    };

    useEffect(() => {
        // if user has a task_type in a search query and change organization to non agency remove that filter from search query
        const hasTaskTypeAccess =
            orgPlan === ORGANIZATION_PLAN.agency && userRole !== USER_ROLE.member;

        if (user && !hasTaskTypeAccess && parsedParams.task_type) {
            const { task_type, ...rest } = searchParams;
            setSearchParams({ ...rest });
        }
    }, [user, orgPlan, userRole]);

    useEffect(() => {
        // if user changes organization and there is a collection in search params, remove it if current organization doesn't include a collection with the same ID
        if (allCollectionsAreLoaded && parsedParams.collection) {
            const selectedCollectionInList = collections?.find(
                (item) => item.id === parsedParams.collection
            );
            if (!selectedCollectionInList) {
                const { collection, ...rest } = searchParams;
                setSearchParams({ ...rest });
            }
        }
    }, [allCollectionsAreLoaded]);

    function handleNextPressed() {
        setSearchParams({ ...parsedParams, page: parsedParams.page + 1 }); // Ensure to use pageNumber not index
    }

    function handlePreviousPressed() {
        if (parsedParams.page > 0) {
            setSearchParams({ ...parsedParams, page: parsedParams.page - 1 }); // Ensure to use pageNumber not index
        }
    }

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-neutral-50 overflow-hidden flex">
            {libraryType === LIBRARY_VIEW_TYPES.personal && (
                <CollectionsPanel
                    collections={collections}
                    setCollections={setCollections}
                    setAllCollectionsAreLoaded={setAllCollectionsAreLoaded}
                />
            )}
            <div className="flex flex-col flex-grow overflow-auto p-4 xs:p-8">
                <div className="flex flex-col items-start gap-5 self-stretch bg-neutral-50">
                    <div className="flex items-start flex-col">
                        <p className="font-heading-bold text-heading-bold-l">Library</p>
                        <p className="font-body text-body-regular-s text-neutral-300 pt-1">
                            The home for your saved and shared AI prompts.
                        </p>
                    </div>
                    <LibraryTopControls
                        onTabChanged={onTabChanged}
                        tabIndex={tabIndex}
                        libraryView={libraryView}
                        setLibraryView={setLibraryView}
                    />
                </div>
                <PaginatedContainer
                    libraryType={libraryType}
                    pageIndex={parsedParams.page - 1 || 0}
                    parsedSearchParams={parsedParams}
                    handleNextPressed={handleNextPressed}
                    handlePreviousPressed={handlePreviousPressed}
                    filteredCollectionId={parsedParams.collection}
                    libraryView={libraryView}
                    collections={collections}
                    setCollections={setCollections}
                />
            </div>
        </div>
    );
};

export default LibraryPage;
