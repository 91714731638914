import React from 'react';

const SeparatorIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M1.33594 7.33301H2.66927V8.66634H1.33594V7.33301ZM4.0026 7.33301H12.0026V8.66634H4.0026V7.33301ZM13.3359 7.33301H14.6693V8.66634H13.3359V7.33301Z"
                fill={props.color}
            />
        </svg>
    );
};

export default SeparatorIcon;
