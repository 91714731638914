import React from 'react';
import PropTypes from 'prop-types';

const Switcher = ({ tabs, tabIndex, onTabChanged }) => {
    const tabSelectedClassName = {
        true: 'text-purple-500 shadow-purple-500 border-purple-500',
        false: 'text-neutral-300 shadow-transparent border-transparent',
    };

    return (
        <div className="w-full flex items-end gap-[18px]">
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`px-0.5 pt-2 pb-1.5 border-b-1 shadow-bottom-1 font-heading-bold text-[14px] text-center cursor-pointer transition-colors capitalize ${
                        tabSelectedClassName[index === tabIndex]
                    }`}
                    onClick={() => onTabChanged(tab, index)}
                >
                    {tab.name}
                </div>
            ))}
        </div>
    );
};

Switcher.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            shortcut: PropTypes.string,
        })
    ).isRequired,
    tabIndex: PropTypes.number,
    onTabChanged: PropTypes.func.isRequired,
};

export default Switcher;
