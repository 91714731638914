import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import useSWR from 'swr';
import client from '../services/report-api';

import { HISTORY_TAB } from '../constants/assistant';

const fetcher = (url) => client.get(url).then((res) => res.data);

export const useFetchJobData = ({ jobId }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const agentId = searchParams.get('agent');

    const [jobData, setJobData] = useState(null);
    const [isJobStarted, setIsJobStarted] = useState(!!jobId);

    const [shouldRefresh, setShouldRefresh] = useState(!!jobId); // status === 'started'

    const { data, error } = useSWR(jobId ? `${API.ROUTES.report.job}${jobId}/` : null, fetcher, {
        refreshInterval: shouldRefresh ? 1000 : 0,
    });

    useEffect(() => {
        if (data) {
            setShouldRefresh(data.status === 'started');
            setJobData((prevData) => {
                if (prevData?.id === data.id) {
                    return { ...data, name: prevData.name };
                }
                return data;
            });

            if (!isJobStarted) {
                setIsJobStarted(true);
            }
        }
    }, [data]);

    useEffect(() => {
        if (!jobId) {
            resetJobData();
        }

        if (jobId && data?.id !== jobId && jobData?.id !== jobId) {
            setJobData(null);
            setIsJobStarted(true);
        }
    }, [jobId]);

    useEffect(() => {
        if (agentId) {
            resetJobData();
        }
    }, [agentId]);

    if (error) {
        navigate(`/assistant?tab=${HISTORY_TAB.jobs}`);
    }

    const resetJobData = () => {
        setJobData(null);
        setIsJobStarted(false);
    };

    return {
        jobData,
        setJobData,
        isJobStarted,
        setIsJobStarted,
    };
};
