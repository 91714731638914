import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../../services/strings';

import { Button } from '../../../design-system';
import MenuLineIcon from '../../../design-system/Icons/MenuLineIcon';
import InstructionsModal from '../../../components/InstructionsModal/InstructionsModal';

const MobileBottomControls = ({ pageAction, openMobileStepsPanel, instructions }) => {
    const [isInstructionModalOpened, setIsInstructionModalOpened] = useState(false);

    return (
        <div className="fixed bottom-0 left-0 sm:left-[68px] right-0 p-4 sm:px-6 border-t-1 border-neutral-200 bg-white h-[72px] lg:hidden flex justify-between items-center">
            <Button
                type="link"
                size="sm"
                text={`${capitalizeFirstLetter(pageAction)} Steps`}
                leadingIcon={MenuLineIcon}
                onClick={openMobileStepsPanel}
            />
            {instructions && (
                <div className="relative block sm:hidden">
                    <Button
                        type="ghost"
                        size="xs"
                        text="View Instructions"
                        onClick={() => setIsInstructionModalOpened(true)}
                    />
                    {isInstructionModalOpened && (
                        <InstructionsModal
                            instructions={instructions}
                            description="Here are the instructions for this step."
                            onClose={(e) => {
                                e?.stopPropagation();
                                setIsInstructionModalOpened(false);
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default MobileBottomControls;
