import React, { memo } from 'react';

import { CHAT_ROLE } from '../../../constants/assistant';

import AssistantMessage from '../AssistantMessage/AssistantMessage';
import UserMessage from '../UserMessage/UserMessage';

const ChatMessage = ({
    message,
    setChatMessages,
    isRegenerateDisabled,
    handleRegenerateChatMessage,
}) => {
    const { role } = message;

    if (role === CHAT_ROLE.user) {
        return (
            <UserMessage
                message={message}
                isRegenerateDisabled={isRegenerateDisabled}
                handleRegenerateChatMessage={handleRegenerateChatMessage}
            />
        );
    }

    if (role === CHAT_ROLE.assistant) {
        return (
            <AssistantMessage
                message={message}
                setChatMessages={setChatMessages}
                isRegenerateDisabled={isRegenerateDisabled}
                handleRegenerateChatMessage={handleRegenerateChatMessage}
            />
        );
    }

    return <></>;
};

export default memo(ChatMessage);
