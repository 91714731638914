import React from 'react';
import { DateTime } from 'luxon';

import { SvgIcon } from '../../../design-system';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';

const ProcessCard = ({ process, iconColor, isMobile }) => {
    const formattedTime = DateTime.fromISO(process.last_run_at).toRelative();

    return (
        <div className="px-2 sm:px-3 py-2 rounded-2 flex flex-col xs:flex-row xs:items-center xs:justify-between gap-x-3.5 sm:gap-x-5 gap-y-1.5 max-w-full bg-white hover:bg-neutral-50 transition-colors">
            <div className="flex items-center gap-2 flex-grow truncate">
                <SvgIcon color={iconColor} icon={PlayCircleFillIcon} />
                <p className="font-body-bold text-body-bold-s text-[#25324B] truncate">
                    {process.name}
                </p>
            </div>
            {!isMobile && (
                <p className="font-body text-body-regular-xs text-neutral-500 min-w-fit">
                    {formattedTime ? (
                        <>
                            <span className="font-body-bold text-body-bold-xs mr-0.5">
                                Last Run
                            </span>{' '}
                            {formattedTime}
                        </>
                    ) : (
                        'No runs yet'
                    )}
                </p>
            )}
        </div>
    );
};

export default ProcessCard;
