import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../design-system/Button/Button';
import { ArrowGoBackLineIcon } from '../../design-system/Icons';

const DetailPageTopNavBlock = ({
    backButtonText = 'Back',
    backLinkHref,
    breadcrumbs,
    rightAlignedButton = null,
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="py-3 px-4 sm:px-6 bg-white border-b-1 border-neutral-200 flex items-center justify-between gap-x-5 gap-y-1 flex-wrap">
            <div className="flex items-center gap-x-5 gap-y-0.5">
                <div className="min-w-fit">
                    <Button
                        type="link"
                        size="xs"
                        text={backButtonText}
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate(backLinkHref, { state: { from: location } })}
                    />
                </div>

                <p className="font-body text-body-regular-s text-neutral-300">{breadcrumbs}</p>
            </div>
            {rightAlignedButton && (
                <div className="min-w-max">
                    <Button
                        size="xs"
                        type={rightAlignedButton.type || 'primary'}
                        {...rightAlignedButton}
                    />
                </div>
            )}
        </div>
    );
};

DetailPageTopNavBlock.propTypes = {
    backButtonText: PropTypes.string,
    backLinkHref: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.string.isRequired,
    rightAlignedButton: PropTypes.shape({
        type: PropTypes.oneOf(['primary', 'secondary', 'neutral', 'ghost', 'link', 'critical'])
            .isRequired,
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        state: PropTypes.oneOf(['disabled', 'loading', 'default']),
        leadingIcon: PropTypes.func,
        trailingIcon: PropTypes.func,
        helperIcon: PropTypes.func,
    }),
};

export default DetailPageTopNavBlock;
