import React from 'react';

import { Input, TextArea } from '../../../../design-system';
import LabelsBlock from '../../../../components/LabelsBlock/LabelsBlock';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';

const OverviewTabForm = ({ formStateHookResponse }) => {
    const { formData, setFormData, addChangedField, fieldErrorMessages, handleInputChange } =
        formStateHookResponse;

    const setAddedLabels = (cb) => {
        setFormData((prevData) => ({ ...prevData, labels: cb(prevData.labels) }));
        addChangedField('labels');
    };

    const handleDeleteAddedLabel = (id) => {
        setAddedLabels((prevLabels) => prevLabels.filter((label) => label.id !== id));
        addChangedField('labels');
    };

    return (
        <div className="flex flex-col gap-5">
            <Input
                size="sm"
                name="name"
                value={formData.name}
                label="Process Name"
                isRequired
                state={fieldErrorMessages.name ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
            />
            <TextArea
                name="description"
                value={formData.description}
                label="Process Description"
                isRequired
                state={fieldErrorMessages.description ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.description}
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />
            <FormFieldWrapper
                label="Process labels"
                state={fieldErrorMessages.labels ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.labels}
                gap={8}
            >
                <LabelsBlock
                    addedLabels={formData.labels}
                    setAddedLabels={setAddedLabels}
                    handleDeleteAddedLabel={handleDeleteAddedLabel}
                />
            </FormFieldWrapper>
        </div>
    );
};

export default OverviewTabForm;
