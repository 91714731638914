import React from 'react';
import CheckDoubleLineIcon from '../../../design-system/Icons/CheckDoubleLineIcon';

const EmptyView = ({ type }) => {
    return (
        <div className="py-[80px]">
            <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full mb-4 mx-auto">
                {type === 'todo' && <CheckDoubleLineIcon width={32} height={32} color="#5E6470" />}
            </div>
            <div className="flex flex-col items-center gap-1">
                <p className="font-heading-bold text-heading-bold-m text-center">
                    {type === 'todo' && 'Hooooray!'}
                </p>
                <p className="font-body text-body-regular-m max-w-[440px] text-center">
                    {type === 'todo' && 'Great job keeping your inbox empty.'}
                </p>
            </div>
        </div>
    );
};

export default EmptyView;
