import React from 'react';
import FileTextLineIcon from '../../../Icons/FileTextLineIcon';
import ExtendedPaginatedDropdown from '../../../ExtendedPaginatedDropdown/ExtendedPaginatedDropdown';

const SelectDocumentDropdown = ({
    docsOptionsHookResponse,
    selectedDocumentId,
    handleDocumentSelect,
    toggleDropdownOpened,
}) => {
    const {
        options: docOptions,
        optionsLoading,
        totalOptions,
        setPage,
        canLoadMoreOptions,
    } = docsOptionsHookResponse;

    return (
        <ExtendedPaginatedDropdown
            options={docOptions}
            selectedOptionId={selectedDocumentId}
            totalOptions={totalOptions}
            optionsLoading={optionsLoading}
            canLoadMoreOptions={canLoadMoreOptions}
            setOptionsNextPage={setPage}
            onOptionSelect={handleDocumentSelect}
            onCancel={() => handleDocumentSelect(null)}
            listLabel="All Documents"
            optionsLeadingIcon={FileTextLineIcon}
            optionsLeadingIconColor="#754DCF"
            onClose={toggleDropdownOpened}
        />
    );
};

export default SelectDocumentDropdown;
