import React from 'react';
import classNames from 'classnames';

import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import EyeIcon from '../../../design-system/Icons/EyeIcon';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import { SvgIcon } from '../../../design-system';

const ModeToggle = ({ mode, changeMode }) => {
    const mainButtonStyles =
        'flex items-center justify-center w-9 h-9 min-w-[36px] rounded-2 outline-0 focus:outline-none';

    const colors = { true: 'bg-neutral-500', false: 'bg-neutral-50' };

    const editModeButtonClassName = classNames(
        mainButtonStyles,
        colors[mode === PLAYBOOK_BUILDER_MODE.edit]
    );
    const viewModeButtonClassName = classNames(
        mainButtonStyles,
        colors[mode === PLAYBOOK_BUILDER_MODE.view]
    );

    const editIconColor = mode === PLAYBOOK_BUILDER_MODE.edit ? '#FFFFFF' : '#1F2125';
    const viewIconColor = mode === PLAYBOOK_BUILDER_MODE.view ? '#FFFFFF' : '#1F2125';

    return (
        <div className="flex items-center gap-0.5">
            <button
                onClick={() => changeMode(PLAYBOOK_BUILDER_MODE.edit)}
                className={editModeButtonClassName}
            >
                <SvgIcon color={editIconColor} icon={EditLineIcon} size="medium" />
            </button>

            <button
                onClick={() => changeMode(PLAYBOOK_BUILDER_MODE.view)}
                className={viewModeButtonClassName}
            >
                <SvgIcon color={viewIconColor} icon={EyeIcon} size="medium" />
            </button>
        </div>
    );
};

export default ModeToggle;
