import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { threadStatusBadge } from '../../../constants/threads';

import { Badge, Button, ButtonIcon } from '../../../design-system';
import {
    ArrowDownSLineIcon,
    ArrowGoBackLineIcon,
    ArrowUpSLineIcon,
    FlowChartIcon,
} from '../../../design-system/Icons';
import ThreadEditModal from '../ThreadEditModal/ThreadEditModal';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import ThreadDetailPageActionButtons from '../ThreadDetailPageActionButtons/ThreadDetailPageActionButtons';

const ThreadDetailPageHeader = ({
    thread,
    setThread,
    setRestartSidePanel,
    setCancelConfirmationModal,
    setFeedbackPanelOpened,
    setIsReloadReportModalOpened,
    setIsConfirmStatusChangeModalOpened,
    feedbackCount,
    setPageHeaderHeight,
}) => {
    const { id, name, process, status, auto, updated_at, created_at, metrics } = thread;
    const navigate = useNavigate();
    const location = useLocation();
    const backlinkHref = location.state?.from ?? '/threads';

    const { width: screenWidth } = useWindowSize();
    const headerContainerRef = useRef(null);

    const [moreDetailsShown, setMoreDetailsShown] = useState(false);
    const [threadEditModal, setThreadEditModal] = useState({ open: false });

    useEffect(() => {
        if (headerContainerRef.current && headerContainerRef.current?.offsetHeight) {
            setPageHeaderHeight(headerContainerRef.current.offsetHeight);
        }
    }, [headerContainerRef, screenWidth, moreDetailsShown]);

    const moreDetailsData = [
        { name: 'Type', value: auto ? 'Automatic' : 'Manual' },
        { name: 'Status', value: threadStatusBadge[status]?.text },
        {
            name: 'Last Updated',
            value: DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - HH:mm:ss'),
        },
        {
            name: 'Created At',
            value: DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - hh:mm a'),
        },
        {
            name: 'Thread ID',
            value: id,
        },
        // add details only if status is success
        ...(status === 'success'
            ? [
                  {
                      name: 'Token Cost',
                      value: `$${metrics?.token_cost?.total_cost?.toFixed(2)}`,
                  },
              ]
            : []),
    ];

    const toggleMoreDetailsShown = () => {
        setMoreDetailsShown((prevState) => !prevState);
    };

    const handleProcessConfigurationClick = () => {
        navigate(`/configure/process/${process.id}`);
    };

    const setThreadActionsModal = {
        setCancelConfirmationModal: setCancelConfirmationModal,
        setThreadEditModal: setThreadEditModal,
        setIsReloadReportModalOpened: setIsReloadReportModalOpened,
        setIsConfirmStatusChangeModalOpened: setIsConfirmStatusChangeModalOpened,
    };

    return (
        <div className={`w-full bg-white`} ref={headerContainerRef}>
            <div className="py-3 px-6 border-b-1 border-neutral-200 flex items-start sm:items-center justify-between gap-2">
                <div className="max-w-[calc(100%-36px)] sm:max-w-[calc(100%-200px)] lg:max-w-[calc(100%-250px)]">
                    <div className="flex flex-col items-start mb-0.5 sm:mb-0 sm:flex-row sm:items-center gap-x-5">
                        <Button
                            type="link"
                            size="xs"
                            text="Back"
                            leadingIcon={ArrowGoBackLineIcon}
                            onClick={() => navigate(backlinkHref)}
                        />
                        <p className="font-body text-body-regular-s text-neutral-300">Threads /</p>
                    </div>
                    <div className="flex flex-col items-start sm:flex-row sm:items-center gap-x-2 flex-wrap">
                        <p className="font-body-bold text-body-bold-s sm:text-body-bold-l">
                            {name}
                        </p>
                        <div className="max-w-full overflow-hidden">
                            <Badge
                                text={process.name}
                                color="neutral"
                                leadingIcon={PlayCircleFillIcon}
                                leadingIconColor={process.icon_color}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <div className="fixed bottom-0 left-0 right-0 px-5 pt-3 pb-6 flex flex-col bg-white z-10 sm:static sm:p-0">
                        <Button
                            type="secondary"
                            size="xs"
                            leadingIcon={FlowChartIcon}
                            text="Process Configuration"
                            onClick={handleProcessConfigurationClick}
                        />
                    </div>
                    <div className="sm:hidden">
                        <ThreadDetailPageActionButtons
                            threadStatus={status}
                            feedbackCount={feedbackCount}
                            setFeedbackPanelOpened={setFeedbackPanelOpened}
                            setRestartSidePanel={setRestartSidePanel}
                            setThreadActionsModal={setThreadActionsModal}
                        />
                    </div>
                </div>
            </div>
            <div className="px-6 py-2 sm:py-3 border-b-1 border-neutral-200">
                <div
                    className={`sm:hidden flex items-center justify-between ${
                        moreDetailsShown ? 'mb-3' : 'mb-0'
                    }`}
                >
                    <p className="font-body-bold text-body-bold-s text-black">More details</p>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={moreDetailsShown ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                        onClick={toggleMoreDetailsShown}
                    />
                </div>
                {(moreDetailsShown || screenWidth >= 640) && (
                    <div className="sm:flex sm:justify-between sm:items-center sm:gap-4">
                        <ul
                            className={`flex flex-col gap-y-3 sm:flex-row gap-x-[40px] sm:gap-y-1.5 sm:flex-wrap`}
                        >
                            {moreDetailsData?.map((item) => (
                                <li
                                    key={item.name}
                                    className="font-body text-body-regular-xs text-neutral-300"
                                >
                                    {item.name}{' '}
                                    <span className="font-body text-body-bold-xs text-neutral-500">
                                        {item.value}
                                    </span>
                                </li>
                            ))}
                        </ul>
                        <div className="hidden sm:block">
                            <ThreadDetailPageActionButtons
                                threadStatus={status}
                                feedbackCount={feedbackCount}
                                setFeedbackPanelOpened={setFeedbackPanelOpened}
                                setRestartSidePanel={setRestartSidePanel}
                                setThreadActionsModal={setThreadActionsModal}
                            />
                        </div>
                    </div>
                )}
            </div>
            {threadEditModal.open && (
                <ThreadEditModal
                    threadId={id}
                    name={name}
                    setThread={setThread}
                    onClose={() => setThreadEditModal({ open: false })}
                />
            )}
        </div>
    );
};

export default ThreadDetailPageHeader;
