import React from 'react';

const ThumbsUpIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <g>
                <path
                    d="M4.66671 14.6668H2.66671C2.31309 14.6668 1.97395 14.5264 1.7239 14.2763C1.47385 14.0263 1.33337 13.6871 1.33337 13.3335V8.66683C1.33337 8.31321 1.47385 7.97407 1.7239 7.72402C1.97395 7.47397 2.31309 7.3335 2.66671 7.3335H4.66671M9.33337 6.00016V3.3335C9.33337 2.80306 9.12266 2.29436 8.74759 1.91928C8.37252 1.54421 7.86381 1.3335 7.33337 1.3335L4.66671 7.3335V14.6668H12.1867C12.5083 14.6705 12.8203 14.5578 13.0653 14.3495C13.3103 14.1412 13.4718 13.8514 13.52 13.5335L14.44 7.5335C14.469 7.3424 14.4562 7.14728 14.4023 6.96166C14.3484 6.77604 14.2548 6.60436 14.1279 6.45851C14.0011 6.31265 13.8441 6.19612 13.6678 6.11697C13.4914 6.03783 13.3 5.99797 13.1067 6.00016H9.33337Z"
                    stroke={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default ThumbsUpIcon;
