export const indicatorColors = {
    pending: '#CFD6E5',
    success: '#D9E56E',
    started: '#F2AA85',
    failure: '#F8CACE',
};

export const statusLabels = {
    pending: 'Ready',
    started: 'In Progress',
    success: 'Completed',
    failure: 'Failed',
    cancelled: 'Cancelled',
    archived: 'Archived',
};

export const statusOptions = [
    {
        id: 'pending',
        name: statusLabels['pending'],
    },
    {
        id: 'started',
        name: statusLabels['started'],
    },
    {
        id: 'success',
        name: statusLabels['success'],
    },
    {
        id: 'failure',
        name: statusLabels['failure'],
    },
    {
        id: 'cancelled',
        name: statusLabels['cancelled'],
    },
    {
        id: 'archived',
        name: statusLabels['archived'],
    },
];
