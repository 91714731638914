import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../Input/Input';
import Loading from '../../components/Loading';
import CheckLineIcon from '../Icons/CheckLineIcon';
import { SvgIcon } from '../index';

const InputWithAutoSaveOnBlur = ({
    size = 'xs',
    name,
    label,
    isRequired = false,
    isDisabled = false,
    value,
    originalValue,
    placeholder = 'Not yet Entered',
    handleInputChange,
    handleSave,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const handleBlur = async () => {
        if (value === originalValue) return;

        try {
            setIsLoading(true);
            setIsSaved(false);

            await handleSave(value);

            setIsLoading(false);
            setIsSaved(true);
            setTimeout(() => setIsSaved(false), 1500);
        } catch (e) {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-[90%] relative">
            <div className="w-full">
                <Input
                    size={size}
                    name={name}
                    label={label}
                    isRequired={isRequired}
                    value={value}
                    state={isDisabled ? 'disabled' : 'default'}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                />
            </div>
            {isLoading && (
                <div className="absolute bottom-[6px] left-[calc(100%+5px)]">
                    <Loading withText={false} />
                </div>
            )}
            {isSaved && (
                <div className="absolute bottom-[8px] left-[calc(100%+5px)]">
                    <SvgIcon color="#79AC78" icon={CheckLineIcon} size="large" />
                </div>
            )}
        </div>
    );
};

InputWithAutoSaveOnBlur.propTypes = {
    size: PropTypes.oneOf(['md', 'sm', 'xs']).isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    value: PropTypes.string.isRequired,
    originalValue: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    handleInputChange: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
};

export default InputWithAutoSaveOnBlur;
