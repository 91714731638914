import React, { useRef, useState } from 'react';
import CardCollectionList from '../CardCollectionList/CardCollectionList';
import CollectionSelector from '../CollectionSelector/CollectionSelector';
import { ButtonIcon, SvgIcon } from '../../../design-system';
import { AddCircleLineIcon, AddLineIcon } from '../../../design-system/Icons';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { setSelectorPositionInLibrary } from '../../../helpers/setCollectionSelectorPosition';
import useUser from '../../../hooks/useUser';
import { USER_ROLE } from '../../../constants/organization';

const CardCollectionBlock = ({
    collections,
    allCollections,
    libraryView,
    taskId,
    setCollections,
    swrKey,
    setAlert,
    isCollectionSelectorOpened,
    setIsCollectionSelectorOpened,
}) => {
    const { width: screenWidth } = useWindowSize();
    const buttonRef = useRef(null);

    const { userRole } = useUser();
    const viewOnlyMode = userRole === USER_ROLE.member;

    const [collectionSelectorPosition, setCollectionSelectorPosition] = useState({
        top: '30px',
        left: 0,
    });
    const [collectionSelectorWidth, setCollectionSelectorWidth] = useState(360);

    const openCollectionSelector = (e) => {
        e.stopPropagation();
        setSelectorPositionInLibrary({
            screenWidth,
            libraryView,
            buttonRef,
            setCollectionSelectorPosition,
            setCollectionSelectorWidth,
        });
        setIsCollectionSelectorOpened(true);
    };

    return (
        <div
            className={`flex items-center ${
                libraryView === 'list' ? 'flex-wrap gap-1' : 'gap-0.5'
            }`}
        >
            <CardCollectionList collections={collections} libraryView={libraryView} />
            <div className="relative">
                {(libraryView === 'grid' || screenWidth < 768) && !viewOnlyMode && (
                    <div ref={buttonRef}>
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={AddCircleLineIcon}
                            onClick={openCollectionSelector}
                        />
                    </div>
                )}
                {libraryView === 'list' && screenWidth >= 768 && !viewOnlyMode && (
                    <button
                        className="flex items-center gap-2 font-body text-body-regular-xs h-[24px] px-2 bg-neutral-100 rounded-2"
                        onClick={openCollectionSelector}
                        ref={buttonRef}
                    >
                        <SvgIcon color="#1F2125" icon={AddLineIcon} size="medium" />
                        Collection
                    </button>
                )}
                {isCollectionSelectorOpened && (
                    <CollectionSelector
                        taskId={taskId}
                        currentTaskCollections={collections}
                        allCollections={allCollections}
                        setAllCollections={setCollections}
                        onClose={(e) => {
                            e.stopPropagation();
                            setIsCollectionSelectorOpened(false);
                        }}
                        swrKey={swrKey}
                        setAlert={setAlert}
                        position={collectionSelectorPosition}
                        containerWidth={collectionSelectorWidth}
                    />
                )}
            </div>
        </div>
    );
};

export default CardCollectionBlock;
