import React, { useMemo, useRef } from 'react';

import {
    checkIsFormDataValid,
    checkIfAllRequiredFieldsFilled,
} from '../../../helpers/settingsFormUtils';
import { addUniqueElementToArray } from '../../../helpers/addUniqueElementToArray';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { SHARING_STATE } from '../../../constants/settingsForm';

import SettingsForm from '../../CustomizeGoalPage/SettingsForm/SettingsForm';
import SettingsStepHeader from '../SettingsStepHeader/SettingsStepHeader';
import ErrorBanner from '../../../design-system/ErrorBanner/ErrorBanner';
import { Button } from '../../../design-system';

const SettingsStepForm = ({
    step,
    stepFormData,
    setStepFormData,
    currentStepErrorData,
    setStepsErrorData,
    setCompletedStepOrders,
    isLastStep = false,
    setCurrentStepOrder,
    submitProcessSetup,
    isLoading,
    pageAction,
}) => {
    const { order } = step || {};
    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 640 });

    const pageContainerRef = useRef(null);

    const goToTheNextStep = () => {
        const isFormDataValid = checkIsFormDataValid({
            settingsFormData: stepFormData,
            setSettingsFormData: setStepFormData,
            skipCheckingSharedSettings: true,
        });
        if (!isFormDataValid) {
            return;
        }

        // mark the current step as a completed
        setCompletedStepOrders((prevData) => addUniqueElementToArray(order, prevData));
        // open the next step
        setCurrentStepOrder((prevOrder) => prevOrder + 1);

        // remove Error Icon if current step has an error
        if (currentStepErrorData) {
            setStepsErrorData((prevState) => ({
                ...prevState,
                [order]: { isError: false, errorMessage: null },
            }));
        }
    };

    const allRequiredFieldsAreFilled = useMemo(
        () => checkIfAllRequiredFieldsFilled({ formData: stepFormData, skipSharedFields: true }),
        [stepFormData]
    );

    const lastStepButtonText = {
        setup: 'Build It!',
        upgrade: 'Upgrade!',
    };

    return (
        <div
            className="bg-white rounded-2xl p-7 sm:p-8 flex flex-col gap-[40px]"
            ref={pageContainerRef}
        >
            <SettingsStepHeader step={step} pageContainerRef={pageContainerRef} />

            {currentStepErrorData?.errorMessage && (
                <ErrorBanner errorMessage={currentStepErrorData.errorMessage} />
            )}

            <SettingsForm
                formData={stepFormData}
                setFormData={setStepFormData}
                containerGap={40}
                sharingState={order === 0 ? SHARING_STATE.unavailable : SHARING_STATE.disabled}
            />

            <div className="flex justify-end">
                <Button
                    type="secondary"
                    size={!isMobile ? 'md' : 'sm'}
                    text={isLastStep ? lastStepButtonText[pageAction] : 'Continue'}
                    state={
                        isLoading ? 'loading' : allRequiredFieldsAreFilled ? 'default' : 'disabled'
                    }
                    onClick={isLastStep ? submitProcessSetup : goToTheNextStep}
                />
            </div>
        </div>
    );
};

export default SettingsStepForm;
