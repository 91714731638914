import React, { useRef, useState } from 'react';

import { useWindowSize } from '../../../hooks/useWindowSize';
import {
    getLabelsSelectorPosition,
    mutateTemplatesLibraryDataAfterUpdatingLabels,
    updateLabelsOfProcessTemplate,
} from '../../../helpers/templatesUtils';

import { Badge } from '../../../design-system';
import PlusCircleIcon from '../../../design-system/Icons/PlusCircleIcon';
import LabelSelectorPopup from '../../../components/LabelSelectorPopup/LabelSelectorPopup';
import LabelBadge from '../../../components/LabelBadge/LabelBadge';

const ProcessTemplateCardLabelsBlock = ({
    processTemplateId,
    addedLabelsData,
    fetchAllLabelsHookResponse,
    requestKeysToMutate,
    labelSelector,
    setLabelSelector,
}) => {
    const abortController = useState(new AbortController()); // control patch requests to update labels
    const { width: screenWidth } = useWindowSize();
    const buttonRef = useRef(null);

    const formattedList = [...addedLabelsData].slice(0, 2);
    const rest = addedLabelsData.length - 2;

    const saveUpdatedLabels = async (selectedLabels, selectedLabelsIds) => {
        mutateTemplatesLibraryDataAfterUpdatingLabels(
            requestKeysToMutate,
            processTemplateId,
            selectedLabels
        );
        await updateLabelsOfProcessTemplate(selectedLabelsIds, processTemplateId, abortController);
    };

    const openSelector = (e) => {
        e?.stopPropagation();
        const { position, width } = getLabelsSelectorPosition({
            screenWidth,
            openingButtonRef: buttonRef,
        });
        setLabelSelector({ isOpened: true, position, width });
    };

    return (
        <>
            {!!addedLabelsData.length && (
                <>
                    {formattedList.map((label) => (
                        <LabelBadge key={label.id} label={label} />
                    ))}
                    {rest > 0 && (
                        <p className="font-body text-body-regular-s text-neutral-300">
                            +{rest} more
                        </p>
                    )}
                </>
            )}
            <div className="xs:relative">
                <button type="button" onClick={openSelector} ref={buttonRef}>
                    <PlusCircleIcon width={14} height={14} color="#5E6470" />
                </button>
                {labelSelector.isOpened && (
                    <LabelSelectorPopup
                        fetchAllLabelsHookResponse={fetchAllLabelsHookResponse}
                        currentlyAddedLabels={addedLabelsData}
                        shouldSaveDataOnBlur
                        saveUpdatedLabels={saveUpdatedLabels}
                        libraryRequestKeysToMutate={requestKeysToMutate}
                        selectorWidth={labelSelector.width}
                        selectorPosition={labelSelector.position}
                        onClose={() => setLabelSelector({ isOpened: false })}
                    />
                )}
            </div>
        </>
    );
};

export default ProcessTemplateCardLabelsBlock;
