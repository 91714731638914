export const formatStringToSnakeCase = (string) => {
    let formattedText = string
        ? string
              .trim()
              .toLowerCase()
              .replace(/[^\p{L}0-9]+/gu, '_')
              .replace(/_+$/, '')
        : '';
    return formattedText;
};
