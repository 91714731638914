import React, { useRef } from 'react';
import { useClickOutside } from '../../../hooks';

import { ACCESS_BLOCK_OPTIONS } from '../../../constants/playbookBuilder';

import BlockOptionFromSelector from '../BlockOptionFromSelector/BlockOptionFromSelector';

const BlockOptionsSelector = ({
    onClose,
    position = 'top-full left-0',
    addNewBlock = () => {},
    accessType,
    isDraggableSelector = false,
}) => {
    const selectorRef = useRef(null);

    const handleClose = (e) => {
        e?.stopPropagation();
        onClose();
    };

    const dropdownData = ACCESS_BLOCK_OPTIONS[accessType];

    useClickOutside(selectorRef, handleClose);

    return (
        <div
            className={`w-[237px] rounded-2.5 p-5 flex flex-col gap-5 bg-neutral-400 absolute z-20 ${position}`}
            ref={selectorRef}
        >
            {dropdownData.map((group, index) => (
                <div key={index}>
                    <p className="font-body text-body-regular-xs text-neutral-200 uppercase mb-3">
                        {group.name}
                    </p>
                    <ul className="flex flex-col gap-2">
                        {group.options.map((blockType) => (
                            <BlockOptionFromSelector
                                key={blockType}
                                blockType={blockType}
                                isDraggable={isDraggableSelector}
                                addNewBlock={addNewBlock}
                                closeSelector={onClose}
                            />
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default BlockOptionsSelector;
