import React from 'react';
import PropTypes from 'prop-types';

import CheckLineIcon from '../Icons/CheckLineIcon';
import { Button } from '../index';
import { ShareBoxLineIcon } from '../Icons';

SuccessBlockWithLangSmithButton.propTypes = {
    message: PropTypes.string.isRequired,
    langsmithUrl: PropTypes.string.isRequired,
};

function SuccessBlockWithLangSmithButton({ message, langsmithUrl }) {
    const handleViewInLangSmith = () => {
        window.open(langsmithUrl, '_blank');
    };

    return (
        <div className="py-3 sm:py-5 flex flex-col gap-4 items-center">
            <div className="w-[40px] h-[40px] flex items-center justify-center bg-lime-500 rounded-full">
                <CheckLineIcon width={32} height={32} color="#FFFFFF" />
            </div>
            <p className="font-heading-bold text-heading-bold-m text-black">{message}</p>
            <Button
                type="link"
                size="sm"
                text="View in LangSmith"
                trailingIcon={ShareBoxLineIcon}
                onClick={handleViewInLangSmith}
            />
        </div>
    );
}

export default SuccessBlockWithLangSmithButton;
