export const arePrimitiveArraysEqualUnordered = (array1, array2) => {
    // If the arrays have different lengths, they cannot be equal
    if (array1.length !== array2.length) {
        return false;
    }

    const countElements = (array) => {
        const count = {};
        array.forEach((item) => (count[item] = (count[item] || 0) + 1));
        return count;
    };

    // Count the occurrences of each element in both arrays
    const count1 = countElements(array1);
    const count2 = countElements(array2);

    // Compare the element counts from both arrays
    for (const key in count1) {
        if (count1[key] !== count2[key]) {
            return false;
        }
    }

    // If all element counts are the same, the arrays are equal
    return true;
};
