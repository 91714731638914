import React, { useState } from 'react';
import Modal from 'design-system/Modal/Modal';
import PropTypes from 'prop-types';
import { Button, Input } from 'design-system';

ActionModal.propTypes = {
    type: PropTypes.oneOf(['add', 'edit', 'delete']),
};

function ActionModal({
    onModalClose,
    handleModalAction,
    type = 'add',
    value = '',
    isLoading = false,
}) {
    const [inputValue, setInputValue] = useState(value || '');

    const handleInputChange = (e) => {
        setInputValue(e.target?.value);
    };

    return (
        <Modal onClose={onModalClose} size="small">
            <h2 className="font-body text-body-bold-m text-black mb-[16px]">
                {type === 'add' && 'Add New Variable'}
                {type === 'edit' && 'Rename Variable'}
                {type === 'delete' && 'Delete Variable'}
            </h2>
            {type !== 'delete' && (
                <Input
                    size="xs"
                    label={type === 'add' && 'Variable Name'}
                    isRequired={type === 'add'}
                    value={inputValue}
                    name="variable"
                    onChange={handleInputChange}
                />
            )}
            {type === 'delete' && (
                <p className="font-body text-body-regular-m text-black">
                    This will delete <span className="text-body-bold-m">{inputValue}</span> from
                    your task. Are you sure?
                </p>
            )}
            <div className="flex items-center justify-between mt-[32px]">
                <Button type="neutral" size="sm" text="Cancel" onClick={onModalClose} />
                <Button
                    type="secondary"
                    size="sm"
                    state={isLoading ? 'loading' : 'default'}
                    text={
                        type === 'add' ? 'Add Variable' : type === 'edit' ? 'Rename' : 'Yes, Delete'
                    }
                    onClick={() => handleModalAction(inputValue)}
                />
            </div>
        </Modal>
    );
}

export default ActionModal;
