import React from 'react';

const AddToLibraryIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.0285 2.59961H19.9714V6.02818H23.4V7.97104H19.9714V11.3996H18.0285V7.97104H14.6V6.02818H18.0285V2.59961ZM15 6.42818H15V7.57094H18.4286V10.9995H19.5714V10.9996H18.4285V7.57104H15V6.42818ZM23 7.57104H19.5714V7.57094H23V7.57104ZM18.4285 2.99961H18.4286V6.42808H18.4285V2.99961ZM6 5.99951C4.89543 5.99951 4 6.89494 4 7.99951V17.9995C4 19.1041 4.89543 19.9995 6 19.9995H18C19.1046 19.9995 20 19.1041 20 17.9995V12.9995H22V17.9995C22 20.2086 20.2091 21.9995 18 21.9995H6C3.79086 21.9995 2 20.2086 2 17.9995V7.99951C2 5.79037 3.79086 3.99951 6 3.99951H13V5.99951H6Z"
                fill={props.color}
            />
        </svg>
    );
};

export default AddToLibraryIcon;
