import React, { useState } from 'react';

import { urlRegex } from '../../../../constants/regex_patterns';
import { emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import { Input } from '../../../index';
import GenericBlockEmptyStateWrapper from '../../GenericBlockEmptyStateWrapper/GenericBlockEmptyStateWrapper';

const EditVideoUrlBlock = ({
    videoUrl,
    handleDeleteBlock,
    handleSaveVideoUrl,
    inputErrorMessage,
    isEditable,
    setInputErrorMessage,
}) => {
    const [inputtedVideoUrl, setInputtedVideoUrl] = useState(videoUrl);

    const handleInputChange = (e) => {
        setInputtedVideoUrl(e.target.value);

        if (inputErrorMessage) {
            setInputErrorMessage(null);
        }
    };

    const handleSave = () => {
        const isInputEmpty = !inputtedVideoUrl?.trim();
        if (isInputEmpty) {
            setInputErrorMessage(emptyFieldErrorMessage);
            return;
        }

        const isInputtedUrlInvalid = !urlRegex.test(inputtedVideoUrl?.trim());
        if (isInputtedUrlInvalid) {
            setInputErrorMessage('Please check the entered URL as it seems to be invalid.');
            return;
        }

        handleSaveVideoUrl(inputtedVideoUrl);
    };

    const inputState = !isEditable ? 'disabled' : inputErrorMessage ? 'error' : 'default';

    return (
        <GenericBlockEmptyStateWrapper
            isEditable={isEditable}
            isError={!!inputErrorMessage}
            handleDeleteBlock={handleDeleteBlock}
            handleSubmit={handleSave}
        >
            <Input
                size="xs"
                name="video_url"
                value={inputtedVideoUrl}
                label="Enter video URL"
                state={inputState}
                errorMessage={inputErrorMessage}
                placeholder="https://"
                onChange={handleInputChange}
            />
        </GenericBlockEmptyStateWrapper>
    );
};

export default EditVideoUrlBlock;
