import React, { useRef } from 'react';
import classNames from 'classnames';

import { useClickOutside } from '../../../hooks';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

const HistorySidePanelWrapper = ({
    historyPanelExpandedState,
    isPanelClosableByClickOutside,
    children,
}) => {
    const panelRef = useRef(null);
    const [isExpanded, setIsExpanded] = historyPanelExpandedState;

    const { isDesktop } = useResponsiveBreakpoints();

    const closePanel = () => setIsExpanded(false);
    useClickOutside(panelRef, isPanelClosableByClickOutside ? closePanel : () => {});

    const backdropClassName = classNames('fixed inset-y-0 z-[61] transition-colors delay-[50ms]', {
        'left-0 sm:left-[68px] right-0 bg-transparent md:bg-black/50': isExpanded,
        'left-0 right-[unset] bg-transparent md:bg-black/0': !isExpanded,
    });

    const sidePanelNotDesktopClassName = classNames(
        'fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 md:right-[unset] z-[62] overflow-x-hidden bg-white md:transition-all',
        {
            'md:w-[360px] visible': isExpanded,
            'w-0 invisible': !isExpanded,
        }
    );

    const sidePanelDesktopClassName = classNames(
        'overflow-x-hidden transition-all flex-shrink-0 bg-white',
        {
            'w-[300px] xl:w-[360px] visible': isExpanded,
            'w-0 invisible': !isExpanded,
        }
    );

    if (!isDesktop) {
        return (
            <div className={backdropClassName}>
                <div className={sidePanelNotDesktopClassName} ref={panelRef}>
                    {children}
                </div>
            </div>
        );
    }

    return <div className={sidePanelDesktopClassName}>{children}</div>;
};

export default HistorySidePanelWrapper;
