export const handleUpdateFoldersState = ({
    setFolders,
    location,
    cb,
    callbackForRootFolders,
    shouldSort = false,
}) => {
    if (!location) return;

    if (location.length === 0 && callbackForRootFolders) {
        setFolders((prevFolders) => callbackForRootFolders(prevFolders));
        return;
    }

    setFolders((prevFolders) => {
        const recursiveUpdateState = (state, location, index) => {
            const currentFolderId = location[index];

            if (!state) {
                return null;
            }

            if (index === location.length - 1) {
                const updatedState = state?.map((folder) => {
                    if (folder.id === currentFolderId) {
                        return cb(folder);
                    }

                    return folder;
                });

                if (shouldSort) {
                    return updatedState?.sort((a, b) => a.label.localeCompare(b.label));
                }
                return updatedState;
            }

            return state?.map((folder) => {
                if (folder.id === currentFolderId) {
                    return {
                        ...folder,
                        folders: recursiveUpdateState(folder.folders, location, index + 1),
                    };
                }

                return folder;
            });
        };

        return recursiveUpdateState(prevFolders, location, 0);
    });
};

export const handleExpandSidePanelFolder = ({ setExpandedFolders, location }) => {
    if (!location) return;

    if (location?.length === 0) return;

    setExpandedFolders((prevExpandedFolders) => {
        const recursiveUpdateState = (state, location, index) => {
            const currentFolderId = location[index];

            if (index === location.length - 1) {
                const isExpanded = state[currentFolderId];
                if (!isExpanded) {
                    return { ...state, [currentFolderId]: true };
                }
                return state;
            }

            return {
                ...state,
                [currentFolderId]: {
                    ...recursiveUpdateState(state[currentFolderId] ?? {}, location, index + 1),
                },
            };
        };

        return recursiveUpdateState(prevExpandedFolders, location, 0);
    });
};

export const toggleSidePanelFolderExpand = ({ setExpandedFolders, location }) => {
    if (!location) return;

    setExpandedFolders((prevExpandedFolders) => {
        const recursiveUpdateState = (state, location, index) => {
            const currentFolderId = location[index];

            if (index === location.length - 1) {
                const isExpanded = state[currentFolderId];
                if (isExpanded) {
                    return { ...state, [currentFolderId]: false };
                } else {
                    return { ...state, [currentFolderId]: true };
                }
            }

            return {
                ...state,
                [currentFolderId]: {
                    ...recursiveUpdateState(state[currentFolderId] ?? {}, location, index + 1),
                },
            };
        };

        return recursiveUpdateState(prevExpandedFolders, location, 0);
    });
};

export const updateSidePanelStateAfterRemovingFolders = ({
    removedFoldersIds,
    parentLocation,
    setSidePanelFolders,
}) => {
    if (!removedFoldersIds?.length) return;

    const removeFolderFromState = (folder) => ({
        ...folder,
        folders: folder.folders?.filter((f) => !removedFoldersIds?.includes(f.id)),
    });

    const removeRootFolder = (folders) =>
        folders?.filter((f) => !removedFoldersIds?.includes(f.id));

    handleUpdateFoldersState({
        setFolders: setSidePanelFolders,
        location: parentLocation?.map(({ id }) => id),
        cb: removeFolderFromState,
        callbackForRootFolders: removeRootFolder,
    });
};

export const updateSidePanelStateAfterFoldersInsert = ({
    insertedFolders = [],
    parentLocation,
    setSidePanelFolders,
}) => {
    if (!insertedFolders?.length) return;

    const insertFolders = (folder) => {
        if (!folder.folders) return folder;

        return {
            ...folder,
            folders: [...(folder.folders || []), ...insertedFolders].sort((a, b) =>
                a.label.localeCompare(b.label)
            ),
        };
    };

    const insertRootFolders = (folders) => {
        const updatedFolders = [...(folders || []), ...insertedFolders].sort((a, b) =>
            a.label.localeCompare(b.label)
        );
        return updatedFolders;
    };

    handleUpdateFoldersState({
        setFolders: setSidePanelFolders,
        location: parentLocation?.map(({ id }) => id),
        cb: insertFolders,
        callbackForRootFolders: insertRootFolders,
    });
};
