import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/report-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { mutateDataAfterDeletingItem } from '../../../helpers/swrMutateUtils';
import { mergeTwoArraysRemovingDuplicates } from '../../../helpers/mergeTwoArraysRemovingDuplicates';
import { useSwrRequestKeysToMutateFromContext } from '../../../hooks/useSwrRequestKeysToMutateFromContext';

import ConfirmDeletionModalPattern from '../../../design-system/ConfirmDeletionModalPattern/ConfirmDeletionModalPattern';

const ConfirmArchiveProjectModal = ({
    projectName,
    projectId,
    setProjects,
    backLinkHref,
    requestKeysToMutate,
    onClose,
}) => {
    const navigate = useNavigate();

    const {
        requestKeysToMutate: { projects: requestKeysToMutateFromContext },
    } = useSwrRequestKeysToMutateFromContext();

    const [errorAlert, setErrorAlert] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleArchive = async () => {
        try {
            setIsLoading(true);

            await client.delete(`${API.ROUTES.report.project}${projectId}/`);

            mutateDataAfterDeletingItem({
                requestKeysToMutate: mergeTwoArraysRemovingDuplicates(
                    requestKeysToMutateFromContext,
                    requestKeysToMutate || []
                ),
                id: projectId,
            });
            if (setProjects) {
                setProjects((prevJobs) => prevJobs?.filter((job) => job.id !== projectId) || []);
            }

            if (backLinkHref) {
                navigate(backLinkHref, { replace: true });
            }
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    return (
        <ConfirmDeletionModalPattern
            onClose={onClose}
            handleDelete={handleArchive}
            title="Confirm Archive Project"
            confirmationTextStart="Are you sure you want to archive"
            entityName={projectName}
            confirmationTextEnd="project? This project and its connected jobs will be canceled and will no longer be accessible."
            isLoading={isLoading}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
            buttonsTheme="dark"
            customConfirmButtonText="Yes, Archive"
        />
    );
};

export default ConfirmArchiveProjectModal;
