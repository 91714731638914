import React from 'react';

import Input from '../../../../design-system/Input/Input';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';

const RetrievalSettingsBox = ({
    retrievalSettings,
    setRetrievalSettings,
    editable = true,
    setChangedRetrievalFields,
    retrievalSettingsErrorFields,
    setRetrievalSettingsErrorFields,
}) => {
    const handleMaxDistanceChange = (e) => {
        const newValue = e.target.value;
        setRetrievalSettings((prevSettings) => ({
            ...prevSettings,
            [e.target.name]: newValue,
        }));
        setChangedRetrievalFields((prevFields) =>
            addUniqueElementToArray('max_distance', prevFields)
        );
        if (retrievalSettingsErrorFields.max_distance) {
            setRetrievalSettingsErrorFields((prevState) => ({ ...prevState, max_distance: false }));
        }
    };

    const handleNumberInputChange = (e) => {
        const newValue = e.target.value;
        setRetrievalSettings((prevSettings) => ({
            ...prevSettings,
            [e.target.name]: parseInt(newValue),
        }));
        setChangedRetrievalFields((prevFields) =>
            addUniqueElementToArray(e.target.name, prevFields)
        );
    };

    return (
        <div className="w-full flex flex-col gap-[24px] rounded-2">
            <div className="w-full flex flex-col gap-1">
                <Input
                    label="Set a limit on retrieved items"
                    type="number"
                    value={retrievalSettings.limit}
                    size="xs"
                    name="limit"
                    state={editable ? 'default' : 'disabled'}
                    isRequired
                    placeholder="10"
                    onChange={handleNumberInputChange}
                />
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Default value is 10
                </p>
            </div>

            <div className="w-full flex flex-col gap-1">
                <Input
                    size="xs"
                    name="max_distance"
                    label="Set a Max Retrieval Distance"
                    value={retrievalSettings.max_distance}
                    state={
                        !editable
                            ? 'disabled'
                            : retrievalSettingsErrorFields.max_distance
                            ? 'error'
                            : 'default'
                    }
                    isRequired
                    type="number"
                    step="0.1"
                    placeholder="0.25"
                    min="0"
                    max="2"
                    errorMessage="Current value is outside of the allowed values. Please fix and submit again."
                    onChange={handleMaxDistanceChange}
                />
                {!retrievalSettingsErrorFields.max_distance && (
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        Default value is 0.25 and must be a value from 0 to 2.
                    </p>
                )}
            </div>

            <div className="w-full flex flex-col gap-1">
                <Input
                    label="Set a Max Response Length (tokens)"
                    type="number"
                    value={retrievalSettings.max_tokens}
                    size="xs"
                    name="max_tokens"
                    state={editable ? 'default' : 'disabled'}
                    isRequired
                    placeholder="1000"
                    onChange={handleNumberInputChange}
                />
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Default value is 1000
                </p>
            </div>
        </div>
    );
};

export default RetrievalSettingsBox;
