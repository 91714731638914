import React from 'react';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const ExecutionMoreOptionPopup = ({ onClose, setActionModal }) => {
    const openActionModal = (action) => {
        setActionModal(action);
        onClose();
    };

    return (
        <div className="absolute top-[34px] right-[4px] z-55">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editIcon"
                            text="Edit Name"
                            onClick={() => openActionModal('rename')}
                        />
                        <ListOption
                            leadingIconName="refreshCcwIcon"
                            text="Restart Execution"
                            onClick={() => openActionModal('restart')}
                        />
                        <ListOption
                            leadingIconName="squareIcon"
                            text="Cancel Execution"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={() => openActionModal('cancel')}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default ExecutionMoreOptionPopup;
