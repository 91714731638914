import React, { useState } from 'react';

import * as API from '../../../../constants/api';
import client from '../../../../services/library-api';

import {
    mergeExistingValuesIntoNewOutputs,
    updateSpecificUserInputs,
} from '../../../../helpers/determineInputs';
import { removeRedundantFieldsFromInput } from '../../../../helpers/taskPlaygroundUtils';
import { TASK_TYPES } from '../../../../constants/library';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Modal from '../../../../design-system/Modal/Modal';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import { Button } from '../../../../design-system';

const DeleteTaskInputConfirmModal = ({
    taskId,
    taskType,
    inputIdToDelete,
    userInput,
    setUpdatedUserInputs,
    setOutputs,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const inputToDelete = userInput.inputs?.find((item) => item.id === inputIdToDelete);

    const handleTaskInputDelete = async () => {
        try {
            setIsLoading(true);

            const requestBody = {
                [userInput.key]: [
                    ...userInput.inputs.reduce((acc, item) => {
                        if (item.id === inputIdToDelete) {
                            return acc;
                        } else {
                            const input = removeRedundantFieldsFromInput(item);
                            return [
                                ...acc,
                                taskType === TASK_TYPES.assistant
                                    ? { ...input, value: '' }
                                    : { ...input },
                            ];
                        }
                    }, []),
                ],
            };
            const { data } = await client.patch(
                `${API.ROUTES.library.task}${taskId}/`,
                requestBody
            );
            const { func_def, inputs, outputs } = data;
            setUpdatedUserInputs(
                updateSpecificUserInputs({
                    userInputKey: userInput.key,
                    func_def,
                    inputsFromResponse: inputs,
                    existingInputs: userInput.inputs,
                })
            );
            if (taskType !== TASK_TYPES.code) {
                setOutputs((existingOutputs) =>
                    mergeExistingValuesIntoNewOutputs({
                        existingOutputs,
                        newOutputs: outputs,
                        addValueFromExistingOutputs: true,
                    })
                );
            }

            setIsLoading(false);
            onClose();
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
        }
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="p-2">
                <h2 className="text-neutral-400 font-heading-bold text-heading-bold-m mb-[40px] text-center">
                    Are you sure you want to delete {inputToDelete?.label}?
                </h2>
                <div className="flex gap-8 justify-center">
                    <Button type="link" size="sm" text="No, Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Yes, Delete"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleTaskInputDelete}
                    />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default DeleteTaskInputConfirmModal;
