import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../services/library-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { mutateDataAfterRemovingItem } from '../../../helpers/configureUtils';

import Alert from '../../../design-system/Alert/Alert';
import Modal from '../../../design-system/Modal/Modal';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const DeleteScenarioModal = ({ id, name, keysToMutate, onScenarioDelete, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            await client.delete(`${API.ROUTES.library.scenario}${id}/`);
            mutateDataAfterRemovingItem({ requestKeysToMutate: keysToMutate, id });
            if (onScenarioDelete) {
                onScenarioDelete();
            }

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <Modal onClose={onClose} size="medium" resetPadding>
            <ModalHeader onClose={onClose} title="Delete Scenario?" outlined />
            <div className="px-5 sm:px-6 md:px-8 py-5">
                <p className="font-body text-body-regular-m text-neutral-500 mb-6">
                    You are about to delete{' '}
                    <span className="font-body-bold text-body-bold-m">{name}</span>, are you sure
                    you’d like to continue?This will remove the Scenario from its Playbook.
                </p>

                <div className="flex items-center gap-3 justify-between">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="md"
                        state={isLoading ? 'loading' : 'default'}
                        text="Yes, Delete"
                        onClick={handleDelete}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default DeleteScenarioModal;
