import React from 'react';

import ProcessCard from '../ProcessCard/ProcessCard';
import BlockHeader from '../BlockHeader/BlockHeader';
import Loading from '../../../components/Loading';
import ContactUsBanner from '../../../design-system/ContactUsBanner/ContactUsBanner';
import { Button } from '../../../design-system';

const YourTopProcessesBlock = ({ processes, loading, loadNextProcessesPage, hasNextPage }) => {
    return (
        <div className="flex flex-col gap-5">
            {!!processes.length && (
                <>
                    <BlockHeader
                        title="Your Top Agents"
                        description="Explore the frequently utilized agents, click on ”view all” to access the complete list."
                        viewAllNavPath="/agents"
                    />

                    <div className="grid grid-cols-1 min-[600px]:grid-cols-2 sm:grid-cols-1 gap-x-3 gap-y-4 min-[704px]:grid-cols-2 lg:grid-cols-3 l:gap-x-4 l:gap-y-5 min-[1400px]:grid-cols-4">
                        {processes.map((process) => (
                            <ProcessCard key={process.id} process={process} />
                        ))}
                    </div>

                    {hasNextPage && !loading && (
                        <Button
                            type="link"
                            size="sm"
                            text="View More"
                            onClick={loadNextProcessesPage}
                        />
                    )}
                </>
            )}

            {!processes.length && (
                <div className="w-full flex justify-center bg-purple-100 rounded-2xl py-[40px] sm:py-[48px] px-6 sm:px-[40px]">
                    <ContactUsBanner mainText="Set up your first Agent!" />
                </div>
            )}

            {loading && <Loading withText={false} />}
        </div>
    );
};

export default YourTopProcessesBlock;
