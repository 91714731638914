import React, { useState } from 'react';

import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Alert from '../../../../design-system/Alert/Alert';
import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const InviteUserSuccessState = ({ data: { invite_url, email }, onClose }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(invite_url)
            .then(() => {
                setIsCopied(true);
            })
            .catch(() => {
                setErrorAlert({ message: defaultErrorMessage });
            });
    };

    const buttonText = isCopied ? 'Invite link copied!' : 'Click here to copy invite link';

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title="Invite Sent!" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-4 font-body text-body-regular-m text-neutral-500">
                <p>
                    <span className="font-body-bold text-body-bold-m">{email}</span> will receive an
                    invite email shortly from hello@diviup.ai with a link to create an account and
                    accept your invitation!
                </p>

                <button
                    className="block w-full px-3 py-3 rounded-2 bg-purple-100 font-body-bold text-body-bold-s text-neutral-500 text-center underline underline-offset-[3px]"
                    onClick={handleCopy}
                >
                    {buttonText}
                </button>

                <p>Or you can click above to copy the link and send it directly. </p>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default InviteUserSuccessState;
