import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { CONFIGURE_TAB } from '../../../constants/configure';

import Loading from '../../../components/Loading';
import ConfigureEmptyView from '../ConfigureEmptyView/ConfigureEmptyView';
import PaginatedContainer from '../../../components/PaginatedContainer/PaginatedContainer';
import ProcessCard from '../ProcessCard/ProcessCard';
import ScenarioCard from '../ScenarioCard/ScenarioCard';

const ConfigurePaginatedContainer = ({
    activeTab,
    configureDataHookResponse,
    keysToMutate,
    handleNewScenarioClick,
}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const page = Number.parseInt(searchParams.get('page')) || 1;

    const { total, data, loading, count } = configureDataHookResponse;

    if (!total && loading) {
        return (
            <div className="absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] flex items-center">
                <Loading />
            </div>
        );
    }

    const emptyStateShown = !total && !data?.length && !loading;

    if (emptyStateShown) {
        const handleCreate = () => {
            if (activeTab === CONFIGURE_TAB.scenarios) {
                handleNewScenarioClick();
            }

            if (activeTab === CONFIGURE_TAB.processes) {
                navigate('/configure/process/new', {
                    state: { configureSearchParams: location.search },
                });
            }
        };

        const type = activeTab === CONFIGURE_TAB.scenarios ? 'scenario' : 'process';
        return <ConfigureEmptyView type={type} handleClick={handleCreate} />;
    }

    const hasData = !!data?.length;

    return (
        <PaginatedContainer page={page} count={count} total={total}>
            {hasData && (
                <ul className="flex flex-col gap-2">
                    {activeTab === CONFIGURE_TAB.processes && (
                        <>
                            {data.map((item) => (
                                <ProcessCard
                                    key={item.id}
                                    process={item}
                                    keysToMutate={keysToMutate[CONFIGURE_TAB.processes]}
                                />
                            ))}
                        </>
                    )}
                    {activeTab === CONFIGURE_TAB.scenarios && (
                        <>
                            {data.map((item) => (
                                <ScenarioCard
                                    key={item.id}
                                    scenario={item}
                                    keysToMutate={keysToMutate[CONFIGURE_TAB.scenarios]}
                                />
                            ))}
                        </>
                    )}
                </ul>
            )}
        </PaginatedContainer>
    );
};

export default ConfigurePaginatedContainer;
