import React from 'react';

const EditBoxLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                d="M16.7574 2.99716L14.7574 4.99716H5V18.9972H19V9.2398L21 7.2398V19.9972C21 20.5495 20.5523 20.9972 20 20.9972H4C3.44772 20.9972 3 20.5495 3 19.9972V3.99716C3 3.44487 3.44772 2.99716 4 2.99716H16.7574ZM20.4853 2.09766L21.8995 3.51188L12.7071 12.7043L11.2954 12.7067L11.2929 11.2901L20.4853 2.09766Z"
                fill={props.color}
            />
        </svg>
    );
};

export default EditBoxLineIcon;
