import React, { useState } from 'react';

import client from '../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { CONTENT_TYPE, ROUTE } from '../../../constants/docs';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import {
    mutateCashedDataAfterAction,
    showErrorAlertIfRequestError,
} from '../../../helpers/docsUtils';
import { updateSidePanelStateAfterRemovingFolders } from '../../../helpers/docsSidePanelUtils';

import { Button } from '../../../design-system';
import Modal from '../../../design-system/Modal/Modal';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';

const ArchiveMultipleItemsModal = ({
    items,
    requestKeysToMutate,
    currentRequestKeyToMutate,
    setSuccessArchiveAlert,
    clearAllSelectedItems,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const { location: parentLocation, setSidePanelFolders } = useDocsPageContext() || {};

    const { isMobile } = useResponsiveBreakpoints();

    const archiveMultipleItems = async () => {
        if (!items) return;

        try {
            setIsLoading(true);

            const selectedItemsArray = Object.values(items) || [];

            const data = await Promise.allSettled(
                selectedItemsArray?.map(({ id, content_type }) => {
                    const route = ROUTE[content_type];
                    return client.delete(route + id + '/');
                })
            );

            showErrorAlertIfRequestError({ data, setErrorAlert });

            await mutateCashedDataAfterAction({
                requestKeysToMutate,
                currentRequestKeyToMutate,
                parentLocation,
            });

            const archivedItems = data.reduce((acc, { status }, index) => {
                const { id, label, content_type } = selectedItemsArray[index];

                if (status === 'fulfilled') {
                    acc.push({ id, label, content_type });
                }
                return acc;
            }, []);

            const removedFoldersIds = archivedItems
                .filter(({ content_type }) => content_type === CONTENT_TYPE.folder)
                .map(({ id }) => id);

            updateSidePanelStateAfterRemovingFolders({
                removedFoldersIds,
                parentLocation,
                setSidePanelFolders,
            });

            setSuccessArchiveAlert({ parentFolderLocation: parentLocation, archivedItems });

            clearAllSelectedItems();
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const archiveButtonState = isLoading ? 'loading' : 'default';

    const content = (
        <p className="font-body text-body-regular-m text-neutral-500">
            Are you sure you want to archive the selected items?
        </p>
    );

    const buttonSize = isMobile ? 'md' : 'sm';
    const controls = (
        <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between gap-2">
            <Button size={buttonSize} type="neutral" text="Cancel" onClick={onClose} />
            <Button
                size={buttonSize}
                type="secondary"
                text="Yes, Archive"
                state={archiveButtonState}
                onClick={archiveMultipleItems}
            />

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                bottomButtonsGroup={controls}
                containerCustomPaddings="!pt-0 pb-5"
                contentCustomPaddings="!py-0"
                bottomButtonsCustomPaddings="px-5"
                withCloseButton={false}
                customGap={32}
            >
                <div className="flex flex-col gap-5">
                    <ModalHeader
                        title="Archive Items"
                        outlined
                        customPaddings="px-5 py-3"
                        withEscapeButton={false}
                        onClose={onClose}
                    />
                    <div className="px-5">{content}</div>
                </div>
            </MobilePopup>
        );
    }

    return (
        <Modal size="semiMedium" onClose={onClose}>
            <div className="flex flex-col gap-6">
                <ModalHeader title="Archive Items" onClose={onClose} />
                {content}
                {controls}
            </div>
        </Modal>
    );
};

export default ArchiveMultipleItemsModal;
