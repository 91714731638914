export const BASE_TYPE = {
    playbook_section: 'playbook-section',
    agent_history: 'agent-history',
    agent_knowledge: 'agent-knowledge',
};

export const BASE_TYPE_LABEL = {
    [BASE_TYPE.playbook_section]: 'Playbook Sections',
    [BASE_TYPE.agent_history]: 'Agent History',
    [BASE_TYPE.agent_knowledge]: 'Agent Knowledge',
};

export const BASE_TYPE_BADGE_COLOR = {
    [BASE_TYPE.playbook_section]: 'blue',
    [BASE_TYPE.agent_history]: 'lime',
    [BASE_TYPE.agent_knowledge]: 'purple',
};

export const knowledgeBasePropertyFormatMapping = {
    'Single line text': 'text',
    'Multi line text': 'text',
    Checkbox: 'boolean',
    Integer: 'int',
    // Decimal: 'number',
};

export const PROPERTY_DATA_FORMAT = {
    singleLineText: 'text',
    multiLineText: 'Multi line text',
    checkbox: 'boolean',
    integer: 'int',
    // decimal: 'Decimal',
    // url: 'URL',
    // email: 'Email',
};


export const KB_MILESTONE_DATA_FORMATS = {
    singleLineText: 'label',
    multiLineText: 'content'
};
