import React, { useEffect, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import { useSwrRequestKeysToMutateFromContext } from '../../../hooks/useSwrRequestKeysToMutateFromContext';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import { mergeTwoArraysRemovingDuplicates } from '../../../helpers/mergeTwoArraysRemovingDuplicates';

import { Button, ButtonIcon } from '../../../design-system';
import ChatSmile2LineIcon from '../../../design-system/Icons/ChatSmile2LineIcon';
import SelectPromptPopup from '../SelectPromptPopup/SelectPromptPopup';

const SelectPromptBlock = ({
    textareaHeight,
    handleAppendPromptToMessage,
    setCloseExpandedModeOnOutsideClick,
    isMobile = false,
}) => {
    const [isSelectPopupOpened, setIsSelectPopupOpened] = useState(false);

    const formatResponseToOptions = (results) =>
        results?.map(({ id, name, chat_prompts }) => ({
            id,
            name,
            chat_prompts,
        }));

    const { requestKeysToMutate, ...promptsHookResponse } =
        useFetchOptionsForPaginatedSelectWithSWR({
            client,
            route: API.ROUTES.assistant.clientChatPrompt,
            searchParams: { shared: true, ordering: 'name' },
            formatResponseToOptions,
        });

    const {
        requestKeysToMutate: { promptsInPlaybooks: promptsRequestKeysToMutateFromContext },
        setRequestKeysToMutate,
    } = useSwrRequestKeysToMutateFromContext();

    useEffect(() => {
        // on component umount update saved swr keys in context
        return () => {
            const keys = mergeTwoArraysRemovingDuplicates(
                requestKeysToMutate,
                promptsRequestKeysToMutateFromContext
            );
            setRequestKeysToMutate((prevData) => ({ ...prevData, promptsInPlaybooks: keys }));
        };
    }, []);

    const handleOpenPopup = () => {
        setIsSelectPopupOpened(true);
        setCloseExpandedModeOnOutsideClick(false);
    };

    const handleClosePopup = (e) => {
        e?.stopPropagation();
        setIsSelectPopupOpened(false);
        setCloseExpandedModeOnOutsideClick(true);
    };

    return (
        <>
            {isMobile && (
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={ChatSmile2LineIcon}
                    onClick={handleOpenPopup}
                />
            )}
            {!isMobile && (
                <Button
                    type="link"
                    size="sm"
                    text="Add Prompt"
                    leadingIcon={ChatSmile2LineIcon}
                    onClick={handleOpenPopup}
                />
            )}

            {isSelectPopupOpened && (
                <SelectPromptPopup
                    textareaHeight={textareaHeight}
                    promptsHookResponse={promptsHookResponse}
                    handleAppendPromptToMessage={handleAppendPromptToMessage}
                    onClose={handleClosePopup}
                />
            )}
        </>
    );
};

export default SelectPromptBlock;
