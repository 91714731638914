import React from 'react';
import { Robot2FillIcon } from '../Icons';
import LoaderFillIcon from '../Icons/LoaderFillIcon';

const SubmissionLoader = ({
    text = "We're building it!",
    bottomText = 'Loading awesomeness...',
}) => {
    return (
        <div className="py-[40px] l:py-[80px] flex flex-col items-center justify-center">
            <div className="w-20 h-20 flex justify-center items-center bg-purple-100 rounded-full mb-4">
                <Robot2FillIcon width={32} height={32} color="#754DCF" />
            </div>
            <p className="font-heading-bold text-heading-bold-m text-black mb-1 text-center">
                {text}
            </p>
            <p className="font-body text-body-regular-m text-black mb-4 text-center">
                {bottomText}
            </p>
            <div className="animate-icon-spin">
                <LoaderFillIcon width={32} height={32} color="#754DCF" />
            </div>
        </div>
    );
};

export default SubmissionLoader;
