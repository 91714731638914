import axios from 'axios';

// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// function to get the auth token from local storage
const getAuthToken = () => {
    return localStorage.getItem('authKey');
};

let baseUrl;
if (process.env.NODE_ENV === 'production') {
    // TODO: figure out how to get environemnt variable in production
    baseUrl = '/api/inbox/';
} else {
    baseUrl = 'http://127.0.0.1:8000/api/inbox/';
}

// const baseUrl = process.env.REACT_APP_BACKEND_URL + '/library/';

// create client constant with axios and auth token
const client = axios.create({
    baseURL: baseUrl,
});

// Using request interceptor to set the auth token before each request (so that it loads for every request)
client.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
});

export default client;
