import React from 'react';
import classNames from 'classnames';

import Loading from '../../../components/Loading';

const MainPanelContainer = ({ historyPanelExpandedState, isLoading = false, children }) => {
    const [isHistorySidePanelExpanded] = historyPanelExpandedState;

    const panelContainerClassName = classNames('flex-1 bg-white transition-all', {
        'max-w-full lg:max-w-[calc(100%-300px)] xl:max-w-[calc(100%-360px)]':
            isHistorySidePanelExpanded,
        'max-w-full': !isHistorySidePanelExpanded,
    });
    const contentClassName = classNames('bg-white flex flex-col h-full max-h-full');

    return (
        <div className={panelContainerClassName}>
            {!isLoading && <div className={contentClassName}>{children}</div>}

            {isLoading && (
                <div className="h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default MainPanelContainer;
