import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import organizationClient from '../../../services/organization-api';
import { CONTENT_TYPE } from '../../../constants/docs';
import { ORGANIZATION_ID_LS_KEY } from '../../../constants/organization';

import Alert from '../../../design-system/Alert/Alert';
import Loading from '../../../components/Loading';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';

const SuccessSentToAlert = ({ data, onClose }) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    if (!data) return <></>;

    const { contentType, membershipId, organizationName, folderId } = data;

    const changeTheDefaultOrganization = async () => {
        if (!membershipId) {
            onClose();
            return;
        }

        try {
            setIsLoading(true);
            const { data } = await organizationClient.patch(
                `${API.ROUTES.organization.organizationMembership}${membershipId}/`,
                { is_default: true }
            );

            const newOrgId = data.organization;
            localStorage.setItem(ORGANIZATION_ID_LS_KEY, newOrgId);
            setIsLoading(false);

            const newRoute =
                contentType === CONTENT_TYPE.folder && folderId
                    ? `/docs/folder/${folderId}`
                    : '/docs';
            navigate(newRoute);
            window.location.reload();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({
                message:
                    'Oops! Something went wrong while changing organization. Please try again.',
                statusCode: e?.response?.status,
            });
        }
    };

    const itemTypeLabel = {
        [CONTENT_TYPE.folder]: 'Folder',
        [CONTENT_TYPE.document]: 'Doc',
    }[contentType];
    const alertMessage = `${itemTypeLabel} sent to ${organizationName}`;

    const buttonClassName = classNames('font-[inherit] text-[inherit] underline', {
        'opacity-0': isLoading,
        'opacity-100': !isLoading,
    });

    const description = (
        <div className="relative">
            <button
                type="button"
                className={buttonClassName}
                disabled={isLoading}
                onClick={changeTheDefaultOrganization}
            >
                Visit
            </button>

            {isLoading && (
                <div className="absolute top-1/2 transform translate-y-[-50%] left-1">
                    <Loading withText={false} size="extraSmall" />
                </div>
            )}
        </div>
    );

    return (
        <>
            <Alert
                status="positive"
                message={alertMessage}
                description={description}
                icon={CheckLineIcon}
                position="top-center"
                autoCloseDisabled={isLoading}
                autoCloseInMS={6000}
                handleClose={onClose}
            />
            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default SuccessSentToAlert;
