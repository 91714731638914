import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/library-api';

import ConfigurePageHeader, { tabs } from './ConfigurePageHeader/ConfigurePageHeader';
import { CONFIGURE_TAB } from '../../constants/configure';
import { SORT_TYPES } from '../../constants/sort';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import useCustomPagination from '../../hooks/useCustomPagination';
import { useDefaultSearchParams } from '../../hooks/useDefaultSearchParams';
import { useSaveInContextRequestKeysWithMultipleAccessors } from '../../hooks/useSaveInContextRequestKeysWithMultipleAccessors';

import ConfigurePaginatedContainer from './ConfigurePaginatedContainer/ConfigurePaginatedContainer';
import CreateScenarioModal from './CreateScenarioModal/CreateScenarioModal';

const route = {
    [CONFIGURE_TAB.processes]: API.ROUTES.library.process,
    [CONFIGURE_TAB.scenarios]: API.ROUTES.library.scenario,
};
const DEFAULT_TAB = CONFIGURE_TAB.processes;
const DEFAULT_TAB_ROUTE = route[DEFAULT_TAB];

const ConfigurePage = () => {
    const [searchParams] = useSearchParams();
    const page = Number.parseInt(searchParams.get('page'));

    const activeTab = searchParams.get('tab');
    const activeTabIndex = Math.max(tabs.indexOf(activeTab), 0);

    const [isCreateScenarioModalOpened, setIsCreateScenarioModalOpened] = useState(false);

    useDocumentTitle('Configure');

    const requestSearchParams = { ordering: SORT_TYPES.created };
    if (activeTab === CONFIGURE_TAB.processes) {
        requestSearchParams.shared = true;
    }

    const configureDataHookResponse = useCustomPagination({
        pageIndex: page - 1 || 0,
        client: client,
        searchParams: requestSearchParams,
        route: route[activeTab] || DEFAULT_TAB_ROUTE,
    });
    const { key, nextPageKey } = configureDataHookResponse;

    const requestKeys = useSaveInContextRequestKeysWithMultipleAccessors({
        key,
        nextPageKey,
        pageAccessor: activeTab || DEFAULT_TAB,
        allPageAccessors: tabs,
    });

    useDefaultSearchParams({
        checkPage: true,
        checkSort: false,
        checkTab: true,
        defaultTab: DEFAULT_TAB,
        availableTabs: tabs,
    });

    const handleNewScenarioClick = useCallback(
        () => setIsCreateScenarioModalOpened(true),
        [setIsCreateScenarioModalOpened]
    );

    return (
        <div className="page-position bg-neutral-50 overflow-y-auto p-4 xs:p-5 sm:p-8 flex flex-col gap-5">
            <ConfigurePageHeader
                activeTabIndex={activeTabIndex}
                handleNewScenarioClick={handleNewScenarioClick}
            />

            <ConfigurePaginatedContainer
                activeTab={activeTab}
                configureDataHookResponse={configureDataHookResponse}
                keysToMutate={requestKeys}
                handleNewScenarioClick={handleNewScenarioClick}
            />

            {isCreateScenarioModalOpened && (
                <CreateScenarioModal
                    requestKeysToMutate={requestKeys[CONFIGURE_TAB.scenarios] || []}
                    onClose={() => setIsCreateScenarioModalOpened(false)}
                />
            )}
        </div>
    );
};

export default ConfigurePage;
