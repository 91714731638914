import React from 'react';

import Modal from '../../../../design-system/Modal/Modal';
import { SvgIcon } from '../../../../design-system';
import EscapeIcon from '../../../../design-system/Icons/EscapeIcon';
import JsonTextareaBox from '../../../../design-system/JsonTextareaBox/JsonTextareaBox';

const AdditionalResultDataModal = ({ data, resultId, onClose }) => {
    return (
        <Modal onClose={onClose} size="medium">
            <div className="flex items-center justify-between gap-3 mb-4">
                <p className="font-heading-bold text-heading-bold-m text-neutral-500">
                    View Result Data
                </p>
                <button onClick={onClose}>
                    <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle" />
                </button>
            </div>

            <JsonTextareaBox
                value={data || {}}
                mode="view"
                label={
                    <>
                        Result ID <span className="text-body-regular-s">{resultId}</span>
                    </>
                }
                labelToCopyAlert="Result Data"
                withCopyButton
                withDownloadButton
                downloadFileName={`result_${resultId}`}
                containerMinHeight={30}
            />
        </Modal>
    );
};

export default AdditionalResultDataModal;
