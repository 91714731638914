import React from 'react';

import Loading from '../../../components/Loading';
import PaginatedContainer from '../../../components/PaginatedContainer/PaginatedContainer';

const PageDesktopPaginatedContainer = ({
    page,
    dataHookResponse,
    requestKeysToMutate,
    setData,
    cardComponent: CardComponent,
}) => {
    const { data, total, count, loading } = dataHookResponse;

    return (
        <PaginatedContainer page={page} count={count} total={total}>
            {!!data?.length && (
                <ul className="w-full flex flex-col gap-2">
                    {data.map((item) => (
                        <li key={item.id}>
                            <CardComponent
                                data={item}
                                requestKeysToMutate={requestKeysToMutate}
                                setData={setData}
                            />
                        </li>
                    ))}
                </ul>
            )}

            {!total && loading && (
                <div className="pt-5">
                    <Loading />
                </div>
            )}
        </PaginatedContainer>
    );
};

export default PageDesktopPaginatedContainer;
