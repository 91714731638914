import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../../services/organization-api';

import { USER_ROLE } from '../../../../constants/organization';
import { mutateRequestKeysData } from '../../../../helpers/swrMutateUtils';

import Loading from '../../../../components/Loading';
import ApiKeyBlock from '../ApiKeyBlock/ApiKeyBlock';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import ClientDetailsBlock from '../ClientDetailsBlock/ClientDetailsBlock';
import OrganizationConnectionsBlock from '../OrganizationConnectionsBlock/OrganizationConnectionsBlock';

const ClientSettings = ({
    clientOrganizations,
    setChildOrganizations,
    isFirstDataFetched,
    parentOrganizationName,
    parentOrganizationPlan,
    parentOrganizationMembers,
    setOrganizationsList,
    organizationsRequestKeysToMutate,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const childOrgId = searchParams.get('client');

    const clientInFetchedList = useMemo(
        () => findCurrentClientOrganizationInList(),
        [clientOrganizations, childOrgId, isFirstDataFetched]
    );

    const [currentClientOrganization, setCurrentClientOrganization] = useState(clientInFetchedList);

    function findCurrentClientOrganizationInList() {
        if (!childOrgId || !isFirstDataFetched) return null;

        return clientOrganizations?.find((org) => org.id === childOrgId) || null;
    }

    useEffect(() => {
        if (!currentClientOrganization && clientInFetchedList) {
            setCurrentClientOrganization(clientInFetchedList);
        }
    }, [clientInFetchedList]);

    useEffect(() => {
        const fetchOrganization = async () => {
            try {
                const { data } = await client.get(
                    `${API.ROUTES.organization.organization}${childOrgId}/`
                );

                const { organization_members, organization, organization_member_role } =
                    data.organization_data;
                const available_members = organization_members?.map((member) => ({
                    user: member.user,
                    'organization-membership': { id: member.id, role: member.role },
                }));
                const updatedData = {
                    ...organization,
                    organization_member_role,
                    available_members,
                };

                setCurrentClientOrganization(updatedData);
            } catch (e) {
                const urlSearchParams = new URLSearchParams(searchParams);
                urlSearchParams.delete('client');
                setSearchParams(urlSearchParams);
            }
        };

        if (!clientInFetchedList && isFirstDataFetched) {
            fetchOrganization();
        }
    }, [clientInFetchedList, isFirstDataFetched]);

    const updateClientData = ({ clientId, membership, email, name }) => {
        if (name) {
            const updateChildOrganizationName = (parentOrganizations) =>
                parentOrganizations.map((parentOrg) => ({
                    ...parentOrg,
                    child_organizations: parentOrg.child_organizations?.map((childOrganization) =>
                        childOrganization.id === clientId
                            ? { ...childOrganization, name }
                            : childOrganization
                    ),
                }));

            // Update client name in the sidebar
            setOrganizationsList(updateChildOrganizationName);

            mutateRequestKeysData({
                requestKeysToMutate: organizationsRequestKeysToMutate,
                revalidate: false,
                cb: (currentData) => {
                    const updatedResults = updateChildOrganizationName(currentData.results);

                    return { ...currentData, results: updatedResults };
                },
            });

            setChildOrganizations((childOrganizations) =>
                childOrganizations.map((childOrganization) =>
                    childOrganization.id === clientId
                        ? { ...childOrganization, name }
                        : childOrganization
                )
            );

            setCurrentClientOrganization((currentClientOrganization) => ({
                ...currentClientOrganization,
                name,
            }));
        }

        if (email) {
            const updateChildOrganizationMembership = (currentClientOrganization) => {
                const available_members = currentClientOrganization.available_members.map(
                    (member) =>
                        member.user.email === email
                            ? { ...member, 'organization-membership': membership }
                            : member
                );
                return {
                    ...currentClientOrganization,
                    available_members,
                };
            };

            setChildOrganizations((childOrganizations) =>
                childOrganizations.map((childOrganization) => {
                    if (childOrganization.id !== clientId) return childOrganization;

                    return updateChildOrganizationMembership(childOrganization);
                })
            );

            setCurrentClientOrganization(updateChildOrganizationMembership);
        }
    };

    useDocumentTitle(currentClientOrganization?.name);

    if (!currentClientOrganization) {
        return (
            <div className="flex items-center justify-center h-full">
                <Loading />
            </div>
        );
    }

    const userRole = currentClientOrganization?.organization_member_role;
    const isOwnerOrAdmin = userRole === USER_ROLE.owner || userRole === USER_ROLE.admin;

    return (
        <>
            <h1 className="font-heading-bold text-heading-bold-l text-black">Client Settings</h1>

            <ClientDetailsBlock
                clientOrganizationData={currentClientOrganization}
                isOwnerOrAdmin={isOwnerOrAdmin}
                parentOrganizationName={parentOrganizationName}
                parentOrganizationMembers={parentOrganizationMembers}
                updateClientData={updateClientData}
            />

            {isOwnerOrAdmin && (
                <OrganizationConnectionsBlock
                    orgId={childOrgId}
                    userRole={userRole}
                    orgPlan={parentOrganizationPlan}
                />
            )}

            {isOwnerOrAdmin && <ApiKeyBlock orgId={childOrgId} />}
        </>
    );
};

export default ClientSettings;
