import React, { useState } from 'react';
import { mutate } from 'swr';

import { API } from 'constants';
import executeClient from '../../../../services/execute-api';

import { useFormState } from '../../../../hooks/useFormState';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import Alert from '../../../../design-system/Alert/Alert';
import UpdateDatasetForm from './UpdateDatasetForm/UpdateDatasetForm';
import SuccessBlockWithLangSmithButton from '../../../../design-system/SuccessBlockWithLangSmithButton/SuccessBlockWithLangSmithButton';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const UpdateDatasetModal = ({ datasetDetail, swrKey, onClose }) => {
    const { id, name, description } = datasetDetail;

    const {
        formData: inputsData,
        setFieldErrorMessages,
        ...formHandlers
    } = useFormState({
        name,
        description,
        dataset_file: null,
    });
    const [langsmithUrl, setLangsmithUrl] = useState(null);

    const [formSubmissionState, setFormSubmissionState] = useState('default'); // success, loading, default
    const [errorAlert, setErrorAlert] = useState(null);

    const getRequestBody = () => {
        const formData = new FormData();
        Object.entries(inputsData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        return formData;
    };

    const updateTableDataAfterUpdate = (updatedDataset) => {
        if (swrKey) {
            mutate(
                swrKey,
                (currentData) => {
                    const updatedData = currentData.results.map((item) =>
                        item.id === updatedDataset.id ? { ...updatedDataset } : item
                    );
                    return {
                        ...currentData,
                        results: updatedData,
                    };
                },
                {
                    revalidate: false,
                }
            );
        }
    };

    const handleUpdateDataset = async () => {
        const areEmptyFields = Object.values(inputsData)?.some((value) => !value);
        if (areEmptyFields) {
            setFieldErrorMessages({
                name: !inputsData.name ? defaultErrorMessage : null,
                description: !inputsData.description ? defaultErrorMessage : null,
                dataset_file: !inputsData.dataset_file ? 'Please upload a dataset CSV file' : null,
            });
            return;
        }

        try {
            setFormSubmissionState('loading');
            setLangsmithUrl(null);
            const requestBody = getRequestBody();

            const { data } = await executeClient.patch(
                `${API.ROUTES.execute.dataset}${id}/`,
                requestBody
            );
            setLangsmithUrl(data?.langsmith_url);
            updateTableDataAfterUpdate(data);

            setFormSubmissionState('success');
        } catch (error) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
            setFormSubmissionState('default');
        }
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="sm:p-2 flex flex-col gap-8">
                <ModalHeader onClose={onClose} title="Update Dataset" />

                {formSubmissionState !== 'success' && (
                    <UpdateDatasetForm
                        datasetDetail={datasetDetail}
                        inputsData={inputsData}
                        formHandlers={formHandlers}
                        handleUpdateDataset={handleUpdateDataset}
                        formSubmissionState={formSubmissionState}
                        onClose={onClose}
                    />
                )}

                {formSubmissionState === 'success' && (
                    <SuccessBlockWithLangSmithButton
                        message="Dataset updated!"
                        langsmithUrl={langsmithUrl}
                    />
                )}
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default UpdateDatasetModal;
