import React from 'react';

const PublishedIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.7813 21.993 11.5642 21.9791 11.3489C21.4486 12.1361 20.7364 12.7907 19.9025 13.2525C19.4212 16.3137 17.2039 18.7985 14.29 19.6674C15.1302 17.8855 15.6866 15.9434 15.901 13.8996C15.2034 13.7704 14.5482 13.5206 13.9577 13.1726C13.7857 15.5469 13.0957 17.7775 12 19.752C10.8778 17.7297 10.1811 15.4388 10.0307 13H13.6822C12.9121 12.488 12.2671 11.8028 11.8027 11H10.0307C10.1811 8.56122 10.8778 6.27025 12 4.24799C12.0448 4.32868 12.0889 4.4098 12.1323 4.49134C12.8053 3.55924 13.7395 2.82817 14.8276 2.40537C13.931 2.14155 12.982 2 12 2C6.47715 2 2 6.47715 2 12ZM8.02731 13C8.15732 15.3742 8.74743 17.6259 9.71002 19.6674C6.71639 18.7747 4.458 16.1765 4.06189 13H8.02731ZM8.02731 11H4.06189C4.458 7.8235 6.71639 5.22533 9.71002 4.33256C8.74743 6.37407 8.15732 8.62577 8.02731 11Z"
                fill={props.color}
            />
            <path
                d="M19.0017 6.70415L14.7059 11L14 10.2941L18.2959 5.99827H14.5095V5H20V10.4905H19.0017V6.70415Z"
                fill="#D9E66E"
            />
            <path
                fillRule="evenodd"
                d="M14.2576 4.75H20.2481V10.7405H18.7498V7.3077L14.704 11.3536L13.6445 10.2941L17.6904 6.24827H14.2576V4.75ZM14.7576 5.25V5.74827H18.8975L14.3516 10.2941L14.704 10.6464L19.2498 6.1006V10.2405H19.7481V5.25H14.7576Z"
                fill="#D9E66E"
            />
        </svg>
    );
};

export default PublishedIcon;
