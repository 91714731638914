const modelOptions = [
    { id: 1, model: 'gpt-3.5-turbo', name: 'GPT-3.5', provider: 'openai', type: 'chat', token_limit: 4097, allow_assistant: false },
    { id: 2, model: 'gpt-3.5-turbo-16k', name: 'GPT-3.5 16k', provider: 'openai', type: 'chat', token_limit: 16385, allow_assistant: false },
    { id: 3, model: 'gpt-3.5-turbo-instruct', name: 'GPT-3.5 Instruct', provider: 'openai', type: 'llm', token_limit: 4097, allow_assistant: false },
    { id: 4, model: 'gpt-4', name: 'GPT-4', provider: 'openai', type: 'chat', token_limit: 8192, allow_assistant: false },
    { id: 5, model: 'gpt-4-32k', name: 'GPT-4 32k', provider: 'openai', type: 'chat', token_limit: 32768, allow_assistant: false },
    { id: 6, model: 'claude-instant-1.2', name: 'Claude Instant 1.2', provider: 'anthropic', type: 'chat', token_limit: 100000, allow_assistant: false },
    { id: 7, model: 'claude-2.0', name: 'Claude 2', provider: 'anthropic', type: 'chat', token_limit: 100000, allow_assistant: false },
    { id: 8, model: 'gpt-4-1106-preview', name: 'GPT-4 Turbo 1106 Preview', provider: 'openai', type: 'chat', token_limit: 128000, allow_assistant: false },
    { id: 9, model: 'claude-3-opus-20240229', name: 'Claude 3 Opus', provider: 'anthropic', type: 'chat', token_limit: 200000, allow_assistant: true },
    { id: 10, model: 'claude-3-sonnet-20240229', name: 'Claude 3 Sonnet', provider: 'anthropic', type: 'chat', token_limit: 200000, allow_assistant: false },
    { id: 11, model: 'gpt-4-turbo-preview', name: 'GPT-4 Turbo Preview', provider: 'openai', type: 'chat', token_limit: 128000, allow_assistant: false },
    { id: 12, model: 'gemini-pro', name: 'Gemini Pro', provider: 'google', type: 'chat', token_limit: 32000, allow_assistant: true },
    { id: 13, model: 'gpt-4o', name: 'GPT-4o', provider: 'openai', type: 'chat', token_limit: 128000, allow_assistant: true },
    { id: 14, model: 'gpt-4-turbo', name: 'GPT-4 Turbo', provider: 'openai', type: 'chat', token_limit: 128000, allow_assistant: false },
    { id: 15, model: 'claude-3-haiku-20240307', name: 'Claude 3 Haiku', provider: 'anthropic', type: 'chat', token_limit: 200000, allow_assistant: true },
    { id: 16, model: 'claude-3-5-sonnet-20240620', name: 'Claude 3.5 Sonnet', provider: 'anthropic', type: 'chat', token_limit: 200000, allow_assistant: true },
    { id: 17, model: 'llama-3.1-sonar-small-128k-online', name: 'Perplexity Small Online', provider: 'perplexity', type: 'chat', token_limit: 127072, allow_assistant: false },
    { id: 18, model: 'llama-3.1-sonar-large-128k-online', name: 'Perplexity Online', provider: 'perplexity', type: 'chat', token_limit: 127072, allow_assistant: true },
    // { id: 19, model: 'llama-3.1-sonar-huge-128k-online', name: 'Perplexity Huge Online', provider: 'perplexity', type: 'chat', token_limit: 127072, allow_assistant: false },
    { id: 20, model: 'gpt-4o-mini', name: 'GPT-4o mini', provider: 'openai', type: 'chat', token_limit: 128000, allow_assistant: false },
    { id: 21, model: 'o1-preview', name: 'GPT-o1 Preview', provider: 'openai', type: 'chat', token_limit: 128000, allow_assistant: false },
    { id: 22, model: 'o1-mini', name: 'GPT-o1 mini', provider: 'openai', type: 'chat', token_limit: 128000, allow_assistant: false },
];

export default modelOptions;