import React from 'react';

import { insertCodeBlock$, usePublisher } from '@mdxeditor/editor';

import { SvgIcon } from '../../../index';
import CodeViewIcon from '../../../Icons/CodeViewIcon';

const ToolbarCodeBlock = () => {
    const insertCodeBlock = usePublisher(insertCodeBlock$);

    return (
        <button className="p-[6px] hover:bg-neutral-50 rounded-2" onClick={insertCodeBlock}>
            <SvgIcon color="#1F2125" icon={CodeViewIcon} size="medium" />
        </button>
    );
};

export default ToolbarCodeBlock;
