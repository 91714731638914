import { useEffect, useState } from 'react';
import { mergeNewDataIntoCurrent } from '../helpers/mergeNewDataIntoCurrent';
import useCustomPagination, { DEFAULT_LIMIT } from './useCustomPagination';

export const useRecursiveDataFetchWithSWR = ({
    client,
    route,
    searchParams = {},
    callback = (results) => results || [],
    limit = DEFAULT_LIMIT,
}) => {
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);

    const [loading, setLoading] = useState(true);
    const [isFirstRequestCompleted, setIsFirstRequestCompleted] = useState(false); // indicates if the first data is already received

    const { data, hasNextPage, total } = useCustomPagination({
        client,
        route,
        pageIndex: page - 1,
        searchParams,
        limit,
    });

    useEffect(() => {
        setResults((prevResults) => {
            if (!prevResults && !data) {
                return [];
            } else {
                const newData = callback(data);
                return mergeNewDataIntoCurrent(prevResults || [], newData || []);
            }
        });

        if (page === 1 && results) {
            setIsFirstRequestCompleted(true);
            setTotalResults(total);
        }

        if (hasNextPage) {
            setPage((page) => page + 1);
        }

        if (hasNextPage === false) {
            setLoading(false);
        }
    }, [data]);

    const isDataFetchCompleted = Boolean(results && !loading);
    return { results, loading, isFirstRequestCompleted, isDataFetchCompleted, totalResults };
};
