import React, { useState } from 'react';
import CollapsableContainer from '../../../../components/CollapsableContainer/CollapsableContainer';
import { Button } from '../../../../design-system';
import { AddLineIcon } from '../../../../design-system/Icons';
import CreateNewInputModal from '../CreateNewInputModal/CreateNewInputModal';
import TaskInputsContainer from '../../../../components/TaskInputsContainer/TaskInputsContainer';
import DeleteTaskInputConfirmModal from '../DeleteTaskInputConfirmModal/DeleteTaskInputConfirmModal';

const UserInputBlock = ({
    userInput,
    isExpanded,
    toggleCollapsed,
    taskId,
    taskType,
    setUserInputs,
    setOutputs,
    hasEditAccess,
    messagesArray,
    chainCount,
    protectedMode,
    setProtectedMode,
    apiTaskChangeableData,
    taskChangeableData,
}) => {
    const { key, label, type, inputs } = userInput;
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState({
        opened: false,
        inputIdToDelete: null,
    });

    const buttonState = {
        string: inputs?.length > 0 ? 'disabled' : 'default',
        array: 'default',
    };

    const setUpdatedUserInputs = (inputs) => {
        setUserInputs((prevState) =>
            prevState.map((item) => (item.key === userInput.key ? { ...item, inputs } : item))
        );
    };

    const updateInputs = (cb) => {
        setUserInputs((prevState) =>
            prevState.map((item) =>
                item.key === userInput.key ? { ...item, inputs: cb(item.inputs) } : item
            )
        );
    };

    const handleDeleteIconClick = (id) => {
        setDeleteConfirmModal({ opened: true, inputIdToDelete: id });
    };

    return (
        <>
            <CollapsableContainer
                title={label}
                isExpanded={isExpanded[key]}
                toggleExpand={() => toggleCollapsed(key)}
                taskId={taskId}
                showProtectedModeBadge={chainCount > 0}
                protectedMode={protectedMode}
                setProtectedMode={setProtectedMode}
            >
                <div className="flex flex-col gap-4">
                    {!!inputs?.length && (
                        <TaskInputsContainer
                            inputsData={inputs}
                            setInputsData={updateInputs}
                            protectedMode={protectedMode}
                            taskType={taskType}
                            withCopyButton
                            withDeleteButton
                            onDeleteButtonClick={handleDeleteIconClick}
                            hasEditAccess={hasEditAccess}
                        />
                    )}
                    {hasEditAccess && (
                        <div>
                            <Button
                                type="ghost"
                                size="xs"
                                text={`Add New ${label} Input`}
                                leadingIcon={AddLineIcon}
                                state={protectedMode ? 'disabled' : buttonState[type]}
                                onClick={() => setIsCreateModalOpened(true)}
                            />
                        </div>
                    )}
                </div>
            </CollapsableContainer>
            {isCreateModalOpened && (
                <CreateNewInputModal
                    taskId={taskId}
                    taskType={taskType}
                    userInput={userInput}
                    setUpdatedUserInputs={setUpdatedUserInputs}
                    setOutputs={setOutputs}
                    onClose={() => setIsCreateModalOpened(false)}
                    messagesArray={messagesArray}
                    apiTaskChangeableData={apiTaskChangeableData}
                    taskChangeableData={taskChangeableData}
                />
            )}
            {deleteConfirmModal.opened && (
                <DeleteTaskInputConfirmModal
                    taskId={taskId}
                    taskType={taskType}
                    inputIdToDelete={deleteConfirmModal.inputIdToDelete}
                    userInput={userInput}
                    setUpdatedUserInputs={setUpdatedUserInputs}
                    setOutputs={setOutputs}
                    onClose={() => setDeleteConfirmModal({ opened: false, inputIdToDelete: null })}
                />
            )}
        </>
    );
};

export default UserInputBlock;
