import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import { capitalizeStr } from '../../../../helpers/formatOrganizationDataForTable';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import ProgressStatusBar from '../ProgressStatusBar/ProgressStatusBar';

import { ButtonIcon } from '../../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../../design-system/Icons';
import DetailContainer from '../../../../design-system/DetailContainer/DetailContainer';
import JobOrProjectMainDetail from '../JobOrProjectMainDetail/JobOrProjectMainDetail';

const DetailBar = ({ detailData, type, isExpanded, setIsExpanded }) => {
    const { id, process, playbook, progress, created_by, status, created_at, updated_at } =
        detailData;

    const progressStatusRef = useRef(null);
    const { width: screenWidth } = useWindowSize();

    const [progressStatusWidth, setProgressStatusWidth] = useState(300);

    useEffect(() => {
        if (progressStatusRef.current) {
            setProgressStatusWidth(progressStatusRef.current.clientWidth);
        }
    }, [progressStatusRef, screenWidth]);

    const toggleExpand = (e) => {
        e?.stopPropagation();
        setIsExpanded((prevValue) => !prevValue);
    };

    const expandButtonIcon = isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    const moreDetailsData = [
        {
            label: 'Started At',
            value: DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - hh:mm a'),
        },
        {
            label: 'Updated At',
            value: DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a'),
        },
        {
            label: `${capitalizeStr(type)} ID`,
            value: id,
            containsId: true,
        },
    ];

    return (
        <div
            className="bg-white/90 rounded-2 py-2.5 px-3 min-[375px]:px-4 sm:px-5 cursor-pointer flex flex-col gap-6 lg:gap-3"
            onClick={toggleExpand}
        >
            <div className="flex items-center justify-between gap-2 sm:gap-3">
                <div className="w-[calc(100%-36px)] sm:w-[calc(100%-40px)] lg:flex items-center justify-between gap-4">
                    <div
                        className="hidden lg:flex items-center gap-6"
                        style={{ width: `calc(100% - ${progressStatusWidth + 16}px)` }}
                    >
                        <JobOrProjectMainDetail
                            type={type}
                            process={process}
                            playbook={playbook}
                            created_by={created_by}
                        />
                    </div>
                    <ProgressStatusBar
                        status={status}
                        progress={progress}
                        isProgressBarShown={type === 'project'}
                        ref={progressStatusRef}
                    />
                </div>
                <ButtonIcon icon={expandButtonIcon} type="link" size="xs" onClick={toggleExpand} />
            </div>
            {isExpanded && (
                <div className="flex flex-col lg:flex-row lg:items-center gap-x-6 gap-y-4 flex-wrap">
                    {screenWidth < 1024 && (
                        <JobOrProjectMainDetail
                            type={type}
                            process={process}
                            playbook={playbook}
                            created_by={created_by}
                        />
                    )}
                    {moreDetailsData.map(({ label, value, containsId }) => (
                        <DetailContainer
                            key={label}
                            label={label}
                            isTruncated={!containsId}
                            isBreakWord={containsId}
                        >
                            {value}
                        </DetailContainer>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DetailBar;
