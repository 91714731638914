import React from 'react';
import { AddLineIcon } from '../../../design-system/Icons';
import { Button } from '../../../design-system';
import PlayCircleIcon from '../../../design-system/Icons/PlayCircleIcon';

const EmptyView = ({ parsedSearchQuery, openCreatePlaybookModal }) => {
    if (parsedSearchQuery) {
        return (
            <p className="font-body text-body-bold-s text-neutral-300 max-w-[600px]">
                No matching results found your search for {parsedSearchQuery}. Please try a
                different keyword.
            </p>
        );
    }

    return (
        <section
            id="empty-index"
            className="flex flex-col py-[80px] items-center gap-4 self-stretch"
        >
            <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full">
                <PlayCircleIcon width={32} height={32} color="#000000" />
            </div>
            <div className="flex flex-col items-center gap-2 px-3">
                <p className="font-heading-bold text-heading-bold-m text-center">
                    You don’t have any Playbooks yet.
                </p>
                <p className="font-body text-bold-m text-center">
                    Try creating one by clicking "New Playbook".
                </p>
            </div>
            <Button
                onClick={openCreatePlaybookModal}
                leadingIcon={AddLineIcon}
                text="New Playbook"
                type="primary"
                size="sm"
            />
        </section>
    );
};

export default EmptyView;
