import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useClickOutside } from '../../hooks';
import { areNumericArraysEqual } from '../../helpers/areNumericArraysEqual';

import LabelSelectorItem from './LabelSelectorItem/LabelSelectorItem';
import Loading from '../Loading';
import ClientSideFilteredSearchBar from '../ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import LabelActionModal from './LabelActionModal/LabelActionModal';
import DeleteLabelModal from './DeleteLabelModal/DeleteLabelModal';
import { Button } from '../../design-system';
import { AddCircleLineIcon } from '../../design-system/Icons';
import LabelMoreOptionsPopup from './LabelMoreOptionsPopup/LabelMoreOptionsPopup';

const LabelSelectorPopup = ({
    fetchAllLabelsHookResponse,
    currentlyAddedLabels,
    setCurrentlyAddedLabels = () => {}, // this state is used to update added Labels state in parent component
    shouldSaveDataOnBlur = false, // means if request to backend should be done
    saveUpdatedLabels, // request to backend to save updated labels
    libraryRequestKeysToMutate, // keys to mutate data after label deleting
    selectorWidth = 360,
    selectorPosition = { top: '30px', left: 0 },
    onClose,
}) => {
    const selectorRef = useRef(null);
    const { labels, setLabels, areLabelsLoading, totalLabels } = fetchAllLabelsHookResponse;

    const [selectedLabels, setSelectedLabels] = useState(currentlyAddedLabels || []);
    const selectedLabelsIds = useMemo(
        () => selectedLabels.map((label) => label.id),
        [selectedLabels]
    );

    const [filteredLabels, setFilteredLabels] = useState(labels);
    const showClientSideFilterBar = Math.max(totalLabels, labels?.length) > 5;

    const [actionModal, setActionModal] = useState({ isOpened: false, mode: null });
    const [labelMoreOptionPopup, setLabelMoreOptionPopup] = useState({
        isOpened: false,
        labelData: null,
        position: {},
    });

    useEffect(() => {
        if (!showClientSideFilterBar) {
            setFilteredLabels(labels);
        }
    }, [labels, showClientSideFilterBar]);

    const updateLabelsList = (e) => {
        const currentlyAddedLabelsIds = currentlyAddedLabels.map((label) => label.id);

        e?.stopPropagation();
        setCurrentlyAddedLabels(() => selectedLabels);
        if (areNumericArraysEqual(currentlyAddedLabelsIds, selectedLabelsIds)) {
            onClose(e);
            return;
        }
        if (shouldSaveDataOnBlur) {
            saveUpdatedLabels(selectedLabels, selectedLabelsIds);
        }
        onClose(e);
    };

    const handleClickOutside = (e) => {
        if (!actionModal.isOpened && !labelMoreOptionPopup.isOpened) {
            updateLabelsList(e);
        }
    };

    useClickOutside(selectorRef, handleClickOutside);

    const onCheckboxChange = (label) => {
        const id = label.id;
        const isChecked = selectedLabelsIds.includes(id);
        if (isChecked) {
            setSelectedLabels((prevState) => prevState.filter((label) => label.id !== id));
        } else {
            setSelectedLabels((prevState) => [...prevState, label]);
        }
    };

    return (
        <>
            <div
                className={`absolute z-49 rounded-lg border border-neutral-200 bg-white shadow-lg p-4 flex flex-col gap-3 max-h-[290px] sm:max-h-[350px] overflow-y-auto overflow-x-hidden`}
                style={{ width: `${selectorWidth}px`, ...selectorPosition }}
                ref={selectorRef}
            >
                {showClientSideFilterBar && (
                    <ClientSideFilteredSearchBar
                        size="xs"
                        list={labels || []}
                        setFilteredList={setFilteredLabels}
                    />
                )}
                {!!filteredLabels.length && (
                    <ul className="flex flex-col gap-1 w-full">
                        {filteredLabels.map((label) => (
                            <LabelSelectorItem
                                key={label.id}
                                label={label}
                                isChecked={selectedLabelsIds.includes(label.id)}
                                onCheckboxChange={onCheckboxChange}
                                labelMoreOptionPopup={labelMoreOptionPopup}
                                setLabelMoreOptionPopup={setLabelMoreOptionPopup}
                                selectorPopupPosition={selectorPosition}
                                selectorPopupRef={selectorRef}
                            />
                        ))}
                    </ul>
                )}

                {areLabelsLoading && (
                    <div className="w-full flex justify-center">
                        <Loading withText={false} />
                    </div>
                )}

                {(!areLabelsLoading || !!labels?.length) && (
                    <div>
                        <Button
                            type="link"
                            size="xs"
                            text="New Label"
                            leadingIcon={AddCircleLineIcon}
                            onClick={() => setActionModal({ isOpened: true, mode: 'create' })}
                        />
                    </div>
                )}

                {actionModal.isOpened &&
                    (actionModal.mode === 'delete' ? (
                        <DeleteLabelModal
                            labelData={actionModal.labelData}
                            setLabels={setLabels}
                            setSelectedLabels={setSelectedLabels}
                            libraryRequestKeysToMutate={libraryRequestKeysToMutate}
                            onClose={() => setActionModal({ isOpened: false })}
                        />
                    ) : (
                        <LabelActionModal
                            mode={actionModal.mode}
                            labelData={actionModal.labelData}
                            setLabels={setLabels}
                            setSelectedLabels={setSelectedLabels}
                            libraryRequestKeysToMutate={libraryRequestKeysToMutate}
                            onClose={() => setActionModal({ isOpened: false })}
                        />
                    ))}
            </div>

            {labelMoreOptionPopup.isOpened && (
                <LabelMoreOptionsPopup
                    labelData={labelMoreOptionPopup.labelData}
                    setActionModal={setActionModal}
                    popupPosition={labelMoreOptionPopup.position}
                    onClose={() => setLabelMoreOptionPopup({ isOpened: false })}
                />
            )}
        </>
    );
};

export default LabelSelectorPopup;
