import React from 'react';

import {
    ACTION_TYPE_INITIAL_VALUE,
    SETTING_ACTION_TYPE,
    SHARING_STATE,
} from '../../../../constants/settingsForm';
import { invalidJsonErrorMessage } from '../../../../constants/errorMessages';

import { isValidJson } from '../../../../helpers/isValidJson';
import { checkIsRequiredFieldFilled } from '../../../../helpers/settingsFormUtils';

import ToolTip from '../../../../design-system/ToolTip/ToolTip';
import LockIcon from '../../../../design-system/Icons/LockIcon';
import UnlockIcon from '../../../../design-system/Icons/UnlockIcon';
import { SvgIcon } from '../../../../design-system';

const SettingItemSharingBlock = ({ setting, setFormData, sharingState }) => {
    const { name, is_shared, action, value, original_value } = setting;

    const isSharingStatusChangeDisabled = sharingState === SHARING_STATE.disabled;

    const handleFormItemChange = (cb) => {
        setFormData((prevData) =>
            prevData.map((item) => (item.name === name ? { ...cb(item) } : item))
        );
    };

    const handleShareSetting = () => {
        if (isSharingStatusChangeDisabled) {
            return;
        }

        const isRequiredFieldFilled = checkIsRequiredFieldFilled(setting);
        if (!isRequiredFieldFilled) {
            handleFormItemChange((setting) => ({
                ...setting,
                state: 'error',
                errorMessage: 'Please provide a default value to unlock this setting.',
            }));
            return;
        }

        if (action === SETTING_ACTION_TYPE.json_input) {
            const isJsonValid = typeof value === 'object' || isValidJson(value);
            if (!isJsonValid) {
                handleFormItemChange((setting) => ({
                    ...setting,
                    state: 'error',
                    errorMessage: invalidJsonErrorMessage,
                }));
                return;
            }
        }

        handleFormItemChange((setting) => ({ ...setting, is_shared: true, state: 'disabled' }));
    };

    const handleStopShareSetting = () => {
        if (isSharingStatusChangeDisabled) {
            return;
        }

        const isSettingSharingSaved = !!original_value;

        handleFormItemChange((setting) => {
            const { placeholder, original_value, ...rest } = setting;
            return {
                ...rest,
                value: isSettingSharingSaved ? ACTION_TYPE_INITIAL_VALUE[action] : value,
                is_shared: false,
                state: 'default',
                errorMessage: null,
            };
        });
    };

    const lockedStatusTooltip = {
        [SHARING_STATE.enabled]: 'Click to create client setting',
        [SHARING_STATE.disabled]: 'This setting is not shared',
    }[sharingState];

    const unlockedStatusTooltip = {
        [SHARING_STATE.enabled]: 'Click to remove client setting',
        [SHARING_STATE.disabled]: 'This setting is shared',
    }[sharingState];

    return (
        <>
            {!is_shared && (
                <ToolTip
                    text={lockedStatusTooltip}
                    position="top-right"
                    shift="0px"
                    useInternalHoverStateToShowToolTip
                >
                    <button
                        onClick={handleShareSetting}
                        disabled={isSharingStatusChangeDisabled}
                        className="flex items-center gap-1 whitespace-nowrap font-body-medium text-body-medium-xs text-neutral-500 focus:outline-none"
                    >
                        <SvgIcon color="#000000" icon={LockIcon} size="medium" /> Locked
                    </button>
                </ToolTip>
            )}

            {is_shared && (
                <ToolTip
                    text={unlockedStatusTooltip}
                    position="top-right"
                    shift="0px"
                    useInternalHoverStateToShowToolTip
                >
                    <button
                        onClick={handleStopShareSetting}
                        disabled={isSharingStatusChangeDisabled}
                        className="flex items-center gap-1 whitespace-nowrap font-body-medium text-body-medium-xs text-neutral-500 focus:outline-none"
                    >
                        <SvgIcon color="#000000" icon={UnlockIcon} size="medium" /> Unlocked
                    </button>
                </ToolTip>
            )}
        </>
    );
};

export default SettingItemSharingBlock;
