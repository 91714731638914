import React from 'react';

const CloseIcon = (props) => (
    <svg width={props.width} height={props.height} viewBox="0 0 16 16">
        <path
            fill={props.color}
            d="M8.00047 7.05767L11.3003 3.75781L12.2431 4.70062L8.94327 8.00047L12.2431 11.3003L11.3003 12.2431L8.00047 8.94327L4.70062 12.2431L3.75781 11.3003L7.05767 8.00047L3.75781 4.70062L4.70062 3.75781L8.00047 7.05767Z"
        />
    </svg>
);

export default CloseIcon;
