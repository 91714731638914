import React, { useState } from 'react';

import { ButtonIcon, SvgIcon } from '../../../index';
import { ArrowDownSLineIcon, ArrowUpSLineIcon, DeleteBin4LineIcon } from '../../../Icons';
import FileTextLineIcon from '../../../Icons/FileTextLineIcon';
import EditLineIcon from '../../../Icons/EditLineIcon';
import RichTextArea from '../../../RichTextArea/RichTextArea';
import Loading from '../../../../components/Loading';
import EditDocumentModal from '../EditDocumentModal/EditDocumentModal';

const ConfirmedDocumentBlock = ({
    documentId,
    documentData,
    isEditable,
    handleDeleteBlock,
    setLinkedDocsData,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEditModalOpened, setIsEditModalOpened] = useState(false);

    const { label, content } = documentData || {};

    const toggleExpandButtonIcon = isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    return (
        <div className="w-full max-w-full rounded-2 border-2 border-purple-300 flex flex-col overflow-hidden">
            <div className="px-5 py-2.5 flex items-center gap-3 justify-between bg-purple-100">
                <div className="flex items-center gap-1">
                    <SvgIcon color="#754DCF" icon={FileTextLineIcon} size="large" />
                    <p className="font-body text-body-bold-xs text-neutral-400 uppercase">
                        Document
                    </p>
                </div>

                {isEditable && (
                    <div className="flex items-center gap-2">
                        <ButtonIcon
                            type="neutral"
                            size="xs"
                            icon={EditLineIcon}
                            onClick={() => setIsEditModalOpened(true)}
                        />

                        <ButtonIcon
                            type="neutral"
                            size="xs"
                            icon={DeleteBin4LineIcon}
                            onClick={handleDeleteBlock}
                        />
                    </div>
                )}
            </div>

            <div className="p-4 bg-white flex flex-col gap-3">
                <div className="flex items-center gap-4 justify-between">
                    {label && (
                        <>
                            <p className="font-body-bold text-body-bold-xl text-neutral-500">
                                {label}
                            </p>
                            <ButtonIcon
                                size="xs"
                                type="link"
                                icon={toggleExpandButtonIcon}
                                onClick={() => setIsExpanded((prev) => !prev)}
                            />
                        </>
                    )}
                    {!label && (
                        <div className="flex-1">
                            <Loading withText={false} size="small" />
                        </div>
                    )}
                </div>

                {isExpanded && (
                    <div className="pb-4">
                        <RichTextArea
                            value={content}
                            previewOnly
                            minHeight={20}
                            autoExpand
                            isBorderHidden
                        />
                    </div>
                )}
            </div>

            {isEditModalOpened && (
                <EditDocumentModal
                    id={documentId}
                    content={content}
                    setLinkedDocsData={setLinkedDocsData}
                    onClose={() => setIsEditModalOpened(false)}
                />
            )}
        </div>
    );
};

export default ConfirmedDocumentBlock;
