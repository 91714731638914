import React, { useEffect, useState } from 'react';
import ListOption from 'design-system/ListOption/ListOption';
import ListOptionGroup from 'design-system/ListOptionGroup/ListOptionGroup';
import PropTypes from 'prop-types';
import { iconNameMap } from 'design-system/Icons/GenericIcon';

Dropdown.propTypes = {
    initiallySelectedIndex: PropTypes.number,
    showDropdown: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            badgeInfo: PropTypes.shape({
                label: PropTypes.string,
                color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
                icon: PropTypes.func,
                iconColor: PropTypes.string,
            }),
            leadingIconName: PropTypes.oneOf(Object.keys(iconNameMap)),
            leadingSelectIcon: PropTypes.bool,
            hover: PropTypes.bool,
            trailingBadge: PropTypes.bool,
            logAs: PropTypes.string,
        })
    ),
    isDropdownControlled: PropTypes.bool,
    selectedOptionIndex: PropTypes.number,
    onItemSelect: PropTypes.func,
    allowDeselect: PropTypes.bool,
    title: PropTypes.string,
    addLeadingSelectIcon: PropTypes.bool,
    showOptionsAsBadge: PropTypes.bool,
    includeClientSideFiltering: PropTypes.bool,
};

export default function Dropdown({
    title,
    showDropdown,
    items,
    onItemSelect,
    isDropdownControlled = false,
    initiallySelectedIndex, // if you use uncontrolled Dropdown use this state
    selectedOptionIndex, // if you use controlled Dropdown use this state
    allowDeselect,
    addLeadingSelectIcon,
    showOptionsAsBadge = false,
    includeClientSideFiltering = false,
}) {
    const [selectedIndex, setSelectedIndex] = useState(
        Number.isNaN(initiallySelectedIndex) ? null : initiallySelectedIndex
    );

    const [filteredOptions, setFilteredOptions] = useState(items);

    useEffect(() => {
        setFilteredOptions(items);
    }, [items]);

    const showClass =
        'origin-top-right transition ease-out duration-300 transform scale-100 opacity-100';
    const hideClass =
        'origin-top-right transition ease-in duration-200 transform scale-95 opacity-0 pointer-events-none';
    const dropdownClass = showDropdown ? showClass : hideClass;

    function handleItemSelected(index) {
        const newIndex = allowDeselect && selectedIndex === index ? null : index;
        setSelectedIndex(newIndex);
        onItemSelect(index, newIndex === index);
    }

    const dropdownItems = filteredOptions.map(({ leadingSelectIcon, ...props }, index) => (
        <ListOption
            key={index}
            {...props}
            leadingSelectIcon={leadingSelectIcon || addLeadingSelectIcon} // leadingSelectIcon can be determined in each item or in Dropdown prop
            index={index}
            selected={
                isDropdownControlled ? index === selectedOptionIndex : index === selectedIndex
            }
            onClick={handleItemSelected}
            showTextAsBadge={showOptionsAsBadge}
        />
    ));

    return (
        <div
            className={`${dropdownClass} absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
        >
            <ListOptionGroup
                groupTitle={title}
                listOptions={dropdownItems}
                includeClientSideFiltering={includeClientSideFiltering}
                list={items}
                setFilteredList={setFilteredOptions}
                keyToFilter={showOptionsAsBadge ? ['badgeInfo', 'label'] : ['text']}
            >
                {dropdownItems}
            </ListOptionGroup>
        </div>
    );
}
