export const runType = {
    web_app: 'promptfolio',
    make: 'integration::make',
    zapier: 'integration::zapier',
    chatgpt: 'integration::chatgpt',
    test: 'test',
};

export const runTypeBadgeColor = {
    web_app: 'blue',
    make: 'peach',
    zapier: 'peach',
    chatgpt: 'lime',
    test: 'purple',
};
