export const formatPlotText = (text) => {
    if (typeof text !== 'string') return '';
    return text?.replace(/([\w\s,]{13,}?)\s?\b/g, '$1<br>');
};

export const formatPlotData = ({ data }) => {
    return Array.isArray(data)
        ? data.map((item) => {
              if (!item.name) return item;
              return { ...item, name: formatPlotText(item.name) };
          })
        : [];
};

export const getLayoutSizes = ({ layout }) => {
    const layoutWidth = layout?.width || (layout?.height ? layout?.height * 1.6 : 720);
    const layoutHeight = layout?.height || (layout?.width ? layout?.width / 1.6 : 450);

    return { layoutWidth, layoutHeight };
};

export const formatPlotLayout = ({ layout, width, height }) => {
    const smallView = width < 560;
    const mediumView = !smallView && width < 700;

    const commonFont = { family: 'Inter, Arial' };

    const margin = smallView
        ? { t: 40, b: 60, l: 50, r: 4 }
        : mediumView
        ? { t: 80, b: 60, l: 60, r: 30 }
        : { t: 80, b: 100, l: 80, r: 80 };

    const formattedLayout = {
        ...layout,
        font: { family: 'Inter, Arial', size: smallView ? 11 : 12 },
        title: {
            ...(layout?.title || {}),
            font: {
                ...commonFont,
                size: smallView ? 12 : 16,
                weight: 600,
            },
        },
        legend: {
            ...(layout?.legend || {}),
            title: {
                ...layout?.legend?.title,
                text: formatPlotText(layout?.legend?.title?.text || ''),
                font: { ...commonFont, size: 12, weight: 600 },
            },
            font: { ...commonFont, size: smallView ? 10 : 12 },
        },
        width,
        height,
        margin,
    };

    return formattedLayout;
};
