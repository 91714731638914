export const DATA_TYPES = {
    string: {
        value: 'string',
        name: 'String',
    },
    array: {
        value: 'array',
        name: 'Array',
    },
    object: {
        value: 'object',
        name: 'Object',
    },
    number: {
        value: 'number',
        name: 'Number',
    },
    boolean: {
        value: 'boolean',
        name: 'Boolean',
    },
    integer: {
        value: 'integer',
        name: 'Integer',
    },
};

export const ACTION_TYPES = {
    SINGLE_INPUT: {
        value: 'single-input',
        name: 'Single Input',
        allowed_data_types: ['string', 'number', 'integer'],
    },
    JSON_INPUT: {
        value: 'json-input',
        name: 'JSON Input',
        allowed_data_types: ['object'],
    },
    TEXTAREA_INPUT: {
        value: 'textarea-input',
        name: 'Textarea Input',
        allowed_data_types: ['string'],
    },
    RICHTEXT_INPUT: {
        value: 'richtext-input',
        name: 'Rich Text Input',
        allowed_data_types: ['string'],
    },
    MULTI_INPUT: {
        value: 'multi-input',
        name: 'Multi Input',
        allowed_data_types: ['object', 'array'],
    },
    SINGLE_SELECT: {
        value: 'single-select',
        name: 'Single Select',
        allowed_data_types: ['string', 'number', 'integer', 'object', 'array', 'boolean'],
    },
    MULTI_SELECT: {
        value: 'multi-select',
        name: 'Multi Select',
        allowed_data_types: ['object', 'array'],
    },
    SINGLE_DROPDOWN: {
        value: 'single-dropdown',
        name: 'Single Dropdown',
        allowed_data_types: ['string', 'number', 'integer', 'object', 'array', 'boolean'],
    },
    MULTI_DROPDOWN: {
        value: 'multi-dropdown',
        name: 'Multi Dropdown',
        allowed_data_types: ['object', 'array'],
    },
    TOGGLE: {
        value: 'toggle',
        name: 'Toggle',
        allowed_data_types: ['boolean'],
    },
};

export const allInputDataTypeOptions = Object.values(DATA_TYPES).map((type) => ({
    id: type.value,
    name: type.name,
}));
