import { THREADS_SORT_TYPES } from '../constants/threads';

export const getThreadsRequestQueryParams = ({ parsedSearchParams }) => {
    const queryParams = { ordering: parsedSearchParams.sort || THREADS_SORT_TYPES.created };
    if (parsedSearchParams.search) {
        queryParams.search = parsedSearchParams.search;
    }
    if (parsedSearchParams.process) {
        queryParams.process = parsedSearchParams.process;
    }
    return queryParams;
};
