import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/library-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Modal from '../../../../design-system/Modal/Modal';
import Alert from '../../../../design-system/Alert/Alert';
import { Button } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const ConfirmDeleteNotificationModal = ({ processId, setProcess, eventData, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({
        show: false,
        message: '',
        statusCode: null,
    });

    const handleDeleteEvent = async () => {
        try {
            setIsLoading(true);

            await client.delete(`${API.ROUTES.library.process}${processId}/event/${eventData.id}/`);
            setProcess((prevData) => ({
                ...prevData,
                events: prevData.events?.filter((event) => event.id !== eventData.id),
            }));

            setIsLoading(false);
            onClose();
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({
                show: true,
                statusCode: error.response?.status,
                message: defaultErrorMessage,
            });
        }
    };

    return (
        <Modal size="extraSmall" onClose={onClose}>
            <h2 className="font-heading-bold text-heading-bold-m text-black py-2 mb-4">
                Delete Event Notification
            </h2>
            <p className="font-body text-body-regular-m text-neutral-500 mb-8">
                Are you sure you want to delete{' '}
                <span className="text-body-bold-m">{eventData.name}</span>? This event will no
                longer be used to trigger notifications for this process.
            </p>
            <div className="flex items-center justify-between gap-2">
                <Button type="ghost" size="sm" text="No, Cancel" onClick={onClose} />
                <Button
                    type="primary"
                    size="sm"
                    text="Yes, Delete"
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleDeleteEvent}
                />
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                />
            )}
        </Modal>
    );
};

export default ConfirmDeleteNotificationModal;
