import useSWR, { mutate } from 'swr';
import apiClient from 'services/user-api';
import { API } from 'constants';
import { datadogRum } from '@datadog/browser-rum';

export const userDetailCacheKey = '/view/';

const getAuthToken = () => localStorage.getItem('authKey');

const initialRequestOptions = {
    headers: { 'Content-Type': 'application/json' },
};
export default function useUser() {
    // TODO: Replace this with the api client in ../../services/user-API.ROUTES.js
    let authToken = getAuthToken();
    const options = {
        ...initialRequestOptions,
        headers: {
            ...initialRequestOptions.headers,
            Authorization: `Token ${authToken}`,
        },
    };
    const shouldFetch = authToken && authToken !== 'null'; // Localstorage stores strings
    const fetcher = (url) => apiClient.get(url, options).then((res) => res.data);
    const url = shouldFetch ? API.ROUTES.user.userDetails : null;
    const useResult = useSWR(url, fetcher, {
        onSuccess: ({ email, pk }) => {
            datadogRum.setUser({ id: pk, email });
        },
    });
    const { data, error, isLoading, isValidating } = useResult;

    const mutateUser = (...args) => mutate(userDetailCacheKey, ...args);

    // console.log('useUser', { data, error, isLoading, isValidating });

    return {
        user: data,
        orgPlan: data?.organization?.plan,
        userRole: data?.organization?.role,
        isLoading,
        isValidating,
        isError: error,
        mutateUser, // Bound to the '/user/view' key regardless of shouldFetch value
    };
}
