import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { threadStatusBadge } from '../../../constants/threads';

import { Badge, ButtonIcon, SvgIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import DiscussLineIcon from '../../../design-system/Icons/DiscussLineIcon';
import ThreadCardMoreOptionsPopup from '../ThreadCardMoreOptionsPopup/ThreadCardMoreOptionsPopup';
import ThreadEditModal from '../../ThreadDetailPage/ThreadEditModal/ThreadEditModal';
import RestartSidePanel from '../../ThreadDetailPage/RestartSidePanel/RestartSidePanel';
import CancelConfirmationModal from '../../ThreadDetailPage/CancelConfirmationModal/CancelConfirmationModal';

const ThreadCard = ({ thread, setThreads = null, requestKeysToMutate }) => {
    const { id, name, process, status, updated_at, feedback_count } = thread;
    const location = useLocation();

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');
    const statusBadge = threadStatusBadge[status];

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);
    const [actionModal, setActionModal] = useState({ action: null }); // actions are "rename", "cancel", "restart"

    const openMoreOptionsPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setIsMoreOptionsPopupOpened(true);
    };

    const closeActionModal = () => setActionModal({ action: null });

    const cardContainerClassName = classNames(
        'px-4 py-3 max-w-full w-full rounded-2 bg-white',
        'grid grid-rows-[auto_auto] min-[910px]:grid-rows-1 grid-cols-[minmax(100px,_1fr)_28px] min-[690px]:grid-cols-[minmax(100px,_1fr)_124px_28px] min-[910px]:grid-cols-[minmax(100px,_1fr)_124px_222px_28px] min-[1400px]:grid-cols-[minmax(100px,_1fr)_136px_222px_28px] gap-x-4 min-[1400px]:gap-x-8 gap-y-2 items-start min-[690px]:items-center'
    );

    const setThread = (cb) => {
        if (setThreads) {
            setThreads((prevThreads) => {
                return prevThreads.map((thread) => {
                    if (thread.id === id) {
                        return typeof cb === 'function' ? cb(thread) : cb;
                    }
                    return thread;
                });
            });
        }
    };

    const onCancel = () => {
        setThread((prevData) => ({ ...prevData, status: 'canceled' }));
    };

    return (
        <>
            <Link
                key={id}
                to={`/thread/${id}`}
                state={{ from: location }}
                className={cardContainerClassName}
            >
                <div className="grid grid-cols-1 min-[1100px]:grid-cols-[minmax(100px,_1.2fr)_minmax(100px,_1fr)] gap-x-4 min-[1400px]:gap-x-8 gap-y-1 items-center">
                    <p className="font-body-bold text-body-bold-s text-black truncate max-w-full row-start-2 row-end-2 min-[1100px]:row-start-1 min-[1100px]:row-end-1">
                        {name}
                    </p>
                    <div className="max-w-full row-start-1 row-end-1 flex items-center">
                        <Badge
                            text={process?.name}
                            color="neutral"
                            leadingIcon={PlayCircleFillIcon}
                            leadingIconColor={process?.icon_color}
                        />
                    </div>
                </div>

                <div className="flex flex-row-reverse justify-end items-center min-[690px]:flex-row min-[690px]:justify-end gap-4 min-[1400px]:gap-x-8 w-full row-start-2 row-end-2 col-span-2 min-[690px]:row-start-auto min-[690px]:row-end-auto min-[690px]:col-span-1">
                    {feedback_count > 0 && (
                        <div className="flex items-center justify-center w-[24px] min-w-[24px] h-[24px] relative">
                            <SvgIcon color="#1F2125" icon={DiscussLineIcon} size="medium" />
                            <span className="absolute top-[1px] right-[-3px] min-w-[10px] w-fit h-[10px] p-0.5 rounded-full bg-red-500 text-[8px] text-white font-medium font-body inline-flex justify-center items-center">
                                {feedback_count}
                            </span>
                        </div>
                    )}
                    <div className="w-[82px] min-w-[82px] flex min-[690px]:justify-end">
                        <Badge text={statusBadge.text} color={statusBadge.color} />
                    </div>
                </div>

                <div className="pt-2 min-[910px]:p-0 border-t-1 border-neutral-200 min-[910px]:border-none row-start-3 row-end-3 col-span-full min-[690px]:row-start-2 min-[690px]:row-end-2 min-[910px]:row-start-auto min-[910px]:row-end-auto min-[910px]:col-span-1">
                    <p className="font-body text-body-regular-xs text-neutral-300 truncate max-w-full">
                        Last Updated{' '}
                        <span className="font-body-bold text-body-bold-xs text-neutral-500">
                            {formattedTime}
                        </span>
                    </p>
                </div>

                <div className="relative">
                    <ButtonIcon
                        icon={More2FillIcon}
                        onClick={openMoreOptionsPopup}
                        type="link"
                        size="xs"
                    />

                    {isMoreOptionsPopupOpened && (
                        <ThreadCardMoreOptionsPopup
                            threadId={id}
                            threadStatus={status}
                            setActionModal={setActionModal}
                            onClose={() => setIsMoreOptionsPopupOpened(false)}
                        />
                    )}
                </div>
            </Link>

            {actionModal.action === 'rename' && (
                <ThreadEditModal
                    name={name}
                    threadId={id}
                    setThread={setThread}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={closeActionModal}
                />
            )}

            {actionModal.action === 'restart' && (
                <RestartSidePanel
                    restartTarget="thread"
                    threadId={id}
                    setThread={setThread}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={closeActionModal}
                />
            )}

            {actionModal.action === 'cancel' && (
                <CancelConfirmationModal
                    threadId={id}
                    threadName={name}
                    cancelTarget="thread"
                    onCancel={onCancel}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={closeActionModal}
                />
            )}
        </>
    );
};

export default memo(ThreadCard);
