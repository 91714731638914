import React from 'react';

const FileTextLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M14 5.33337V13.9955C14 14.3668 13.7035 14.6667 13.3377 14.6667H2.66227C2.29663 14.6667 2 14.3707 2 14.0055V1.99457C2 1.63691 2.29913 1.33337 2.66814 1.33337H9.99787L14 5.33337ZM12.6667 6.00004H9.33333V2.66671H3.33333V13.3334H12.6667V6.00004ZM5.33333 4.66671H7.33333V6.00004H5.33333V4.66671ZM5.33333 7.33337H10.6667V8.66671H5.33333V7.33337ZM5.33333 10H10.6667V11.3334H5.33333V10Z"
                fill={props.color}
            />
        </svg>
    );
};

export default FileTextLineIcon;
