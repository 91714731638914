import React, { useCallback, useMemo, useRef, useState } from 'react';

import * as API from '../../../constants/api';
import client from '../../../services/knowledge-api';

import { SORT_TYPES } from '../../../constants/sort';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';
// import { useClickOutside } from '../../../hooks';

import DocMappingItem from '../DocMappingItem/DocMappingItem';
import FormFieldWrapper from '../../../design-system/FormFieldWrapper/FormFieldWrapper';
import AddVariableModal from '../AddVariableModal/AddVariableModal';
import NewRichTextArea from '../../../design-system/NewRichTextArea/NewRichTextArea';

export const LINKED_DOC_REGEX = /\${document\.([a-f0-9\-]+)}/g;

const RichTextWithLinkedDocs = ({
    input,
    setFormData,
    isFormDisabled,
    setCloseParentOnClickOutside,
}) => {
    const {
        key,
        label,
        description,
        is_required,
        value,
        state: _state,
        errorMessage,
        docs_mapping,
    } = input;

    const state = isFormDisabled ? 'disabled' : _state;

    const ref = useRef(null);

    const [isAddVariableModalOpened, setIsAddVariableModalOpened] = useState(false);
    // const textAreaCursorPosition = useRef(null);

    const variablesMappingKeys = Object.keys(docs_mapping);

    const missedVariableKeys = useMemo(() => {
        return variablesMappingKeys.reduce(
            (acc, key) => ({
                ...acc,
                [key]: !value?.includes(`[${key}]`),
            }),
            {}
        );
    }, [value]);

    // const resetCursorPosition = () => {
    //     textAreaCursorPosition.current = null;
    // };

    // useClickOutside(ref, isAddVariableModalOpened ? () => {} : resetCursorPosition);

    const formatResponseToOptions = (results) =>
        results?.map(({ id, label }) => ({
            id,
            name: label,
        }));

    const docsOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.knowledge.document,
        searchParams: { ordering: SORT_TYPES.alphabeticalByLabel },
        formatResponseToOptions,
    });

    const handleInputDataChange = (cb) => {
        setFormData((prevData) => prevData.map((item) => (item.key === key ? cb(item) : item)));
    };

    const handleRichTextChange = (value) => {
        handleInputDataChange((item) => ({ ...item, value, state: 'default', errorMessage: null }));
    };

    const handleMappingChange = useCallback(
        (cb) => {
            handleInputDataChange((item) => ({
                ...item,
                docs_mapping: cb(item.docs_mapping || {}),
                state: 'default',
                errorMessage: null,
            }));
        },
        [setFormData]
    );

    const insertKeyAtSpecificPosition = (key) => {
        // const cursorPosition = textAreaCursorPosition.current;

        // if (cursorPosition !== null && value) {
        //     return value.slice(0, cursorPosition) + `[${key}]` + value.slice(cursorPosition);
        // }
        return (value || '') + (value ? ' ' : '') + `[${key}]`;
    };

    const handleAddVariable = (key) => {
        const updatedValue = insertKeyAtSpecificPosition(key);
        handleRichTextChange(updatedValue);

        handleMappingChange((prevMapping) => ({ ...prevMapping, [key]: null }));
    };

    const handleOpenAddVariableModal = () => {
        setIsAddVariableModalOpened(true);

        if (setCloseParentOnClickOutside) {
            setCloseParentOnClickOutside(false);
        }
    };

    const handleCloseAddVariableModal = () => {
        setIsAddVariableModalOpened(false);

        if (setCloseParentOnClickOutside) {
            setCloseParentOnClickOutside(true);
        }
    };

    // const onTextAreaBlur = (event) => {
    //     textAreaCursorPosition.current = event.target.selectionStart || 0;
    // };

    return (
        <div className="flex flex-col gap-1">
            <div ref={ref}>
                <FormFieldWrapper
                    label={label}
                    isRequired={is_required}
                    state={state}
                    errorMessage={errorMessage}
                    tipText={description}
                    withAddFileButton
                    onAddFileButtonClick={handleOpenAddVariableModal}
                    gap={0}
                    bottomSpace={4}
                    labelMarginBottom={4}
                >
                    <NewRichTextArea
                        name={key}
                        value={value || ''}
                        placeholder={`Enter ${label} or add document`}
                        state={state}
                        setValue={handleRichTextChange}
                        autoExpand
                        minHeight={170}
                        darkBorderColor
                        // onBlur={onTextAreaBlur}
                    />
                </FormFieldWrapper>
            </div>

            {!!variablesMappingKeys.length && (
                <ul className="flex flex-col gap-2 py-2">
                    {variablesMappingKeys.map((key) => (
                        <DocMappingItem
                            key={key}
                            variableKey={key}
                            mappedDocId={docs_mapping[key]}
                            handleMappingChange={handleMappingChange}
                            isVariableMissedInValue={missedVariableKeys[key]}
                            docsOptionsHookResponse={docsOptionsHookResponse}
                        />
                    ))}
                </ul>
            )}

            {isAddVariableModalOpened && (
                <AddVariableModal
                    addVariable={handleAddVariable}
                    existedVariablesKey={variablesMappingKeys}
                    onClose={handleCloseAddVariableModal}
                />
            )}
        </div>
    );
};

export default RichTextWithLinkedDocs;
