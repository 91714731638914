import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { useViewType } from '../../../../hooks/useViewType';
import { applyOpacityToHex } from '../../../../helpers/applyOpacityToHex';

import client from '../../../../services/library-api';
import { SCENARIO_ROUTE } from '../ScenarioDetailPage';

import FlowChartIcon from '../../../../design-system/Icons/FlowChartIcon';
// import FlowIcon from '../../../../design-system/Icons/FlowIcon';
import PlayProcessIcon from '../../../../design-system/Icons/PlayProcessIcon';
import EditableTextArea from '../../../../components/EditableTextArea/EditableTextArea';
import ArrowRightUpLineIcon from '../../../../design-system/Icons/ArrowRightUpLineIcon';
import { Badge, SvgIcon } from '../../../../design-system';

const ScenarioDetailsHeader = ({ scenarioData, isEditable = true, setScenarioData }) => {
    const { id, name, description, created_at, playbook } = scenarioData;
    const formattedTime = DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - hh:mm a');

    const navigate = useNavigate();
    const { toggleViewType } = useViewType();

    const handleScenarioEditsSave = async (name, value) => {
        try {
            await client.patch(`${SCENARIO_ROUTE}${id}/`, { [name]: value });
            setScenarioData((prevData) => ({ ...prevData, [name]: value }));
        } catch (e) {
            return true;
        }
    };

    const navigateToPlaybook = () => {
        toggleViewType();
        navigate(`/playbook/${playbook.id}?mode=view`);
    };

    return (
        <div className="flex flex-col gap-4 pb-5 border-b-1 border-neutral-200">
            {isEditable ? (
                <EditableTextArea
                    initialText={name}
                    textStyle="font-body-bold text-body-bold-l text-black"
                    onSave={(value) => handleScenarioEditsSave('name', value)}
                    icon={FlowChartIcon}
                />
            ) : (
                <div className="flex flex-row items-center space-x-2 w-full">
                    <div className="w-8 h-8 min-w-[32px] bg-neutral-100 rounded-2 flex justify-center items-center">
                        <SvgIcon color="#1F2125" size="medium" icon={FlowChartIcon} />
                    </div>
                    <p className="font-body-bold text-body-bold-l text-black">{name}</p>
                </div>
            )}

            <div className="flex items-center flex-wrap gap-x-5 gap-y-2">
                <div>
                    <p className="font-body-bold text-body-bold-xs text-neutral-500 mb-1">
                        Created At
                    </p>
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        {formattedTime}
                    </p>
                </div>

                <div className="max-w-full overflow-hidden">
                    <p className="font-body-bold text-body-bold-xs text-neutral-500 mb-1">
                        Playbook
                    </p>
                    <Badge
                        text={playbook.name}
                        leadingIcon={PlayProcessIcon}
                        leadingIconColor="#000000"
                        color={applyOpacityToHex(playbook.icon_color, 20)}
                        trailingIcon={ArrowRightUpLineIcon}
                        trailingIconColor="#5E6470"
                        onBadgeClick={navigateToPlaybook}
                    />
                </div>
            </div>

            {isEditable ? (
                <EditableTextArea
                    initialText={description}
                    textStyle="font-body text-body-regular-m text-neutral-500"
                    onSave={(value) => handleScenarioEditsSave('description', value)}
                />
            ) : (
                <p className="font-body text-body-regular-m text-neutral-500">{description}</p>
            )}
        </div>
    );
};

export default memo(ScenarioDetailsHeader);
