import React from 'react';

const StopFillIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                d="M6 5.5H18C18.2762 5.5 18.5 5.72386 18.5 6V18C18.5 18.2762 18.2762 18.5 18 18.5H6C5.72386 18.5 5.5 18.2762 5.5 18V6C5.5 5.72386 5.72386 5.5 6 5.5Z"
                fill={props.color}
                stroke={props.color}
            />
        </svg>
    );
};

export default StopFillIcon;
