import React, { useState } from 'react';
import { API } from 'constants';
import client from '../../../../services/library-api';
import { TASK_TYPES } from '../../../../constants/library';
import { urlRegex } from '../../../../constants/regex_patterns';
import EditableTextArea from '../../../../components/EditableTextArea/EditableTextArea';

const ComingSoonBlock = ({ taskType, taskId, text, webhookUrl, setWebhookUrl }) => {
    const [webhookUrlErrorMessage, setWebhookUrlErrorMessage] = useState(null);

    const handleWebhookUrlChange = async (newValue) => {
        try {
            const isWebhookUrlValid = urlRegex.test(newValue?.trim());
            if (!isWebhookUrlValid) {
                setWebhookUrlErrorMessage(
                    'Please check the entered URL as it seems to be invalid.'
                );
                return true;
            }

            await client.patch(`${API.ROUTES.library.task}${taskId}/`, {
                webhook_url: newValue?.trim(),
            });
            setWebhookUrl(newValue);
        } catch (e) {
            setWebhookUrlErrorMessage(
                'Oops! Something went wrong while changing Webhook URL. Please try again.'
            );
            return true;
        }
    };

    return (
        <div className="w-full h-full flex flex-col gap-[6px] lg:pb-5">
            <p className="font-body text-body-bold-m text-neutral-500 uppercase">{taskType} Task</p>
            {taskType === TASK_TYPES.webhook && (
                <div className="pb-1.5">
                    <EditableTextArea
                        initialText={webhookUrl}
                        onSave={handleWebhookUrlChange}
                        textStyle="font-body text-body-regular-m text-neutral-500"
                        errorMessage={webhookUrlErrorMessage}
                    />
                </div>
            )}
            <div className="w-full min-h-[200px] max-h-[515px] flex-grow border-1 border-neutral-300 rounded-2 px-3 py-[18px] flex items-center justify-center">
                <p className="font-body text-body-bold-m text-neutral-300">{text}</p>
            </div>
        </div>
    );
};

export default ComingSoonBlock;
