export const formatOutputValue = (value) => {
    if (value === null) {
        return 'None';
    } else if (value === undefined) {
        return '';
    } else if (typeof value === 'object') {
        // Convert the dictionary (object) to a formatted JSON string
        return JSON.stringify(value, null, 2); // 2 spaces for indentation
    } else {
        return value;
    }
};
