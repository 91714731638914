// usePreActionNavigationGuard handles navigation blocking by showing a confirmation modal before executing actions like organization change or view type switch
import { useLocation } from 'react-router-dom';
import { useNavigationRestrictionContext } from './useNavigationRestrictionContext';
import { useState } from 'react';
import ConfirmNavigationModalFromDocDetail from '../pages/DocsPage/DocDetailPage/ConfirmNavigationModalFromDocDetail/ConfirmNavigationModalFromDocDetail';
import ConfirmNavigationModal from '../components/ConfirmNavigationModal/ConfirmNavigationModal';

export const usePreActionNavigationGuard = ({
    onConfirmNavigation = () => {},
    onCancelNavigation = () => {},
}) => {
    const location = useLocation();
    const isPromptViewPageOpened = location.pathname?.includes('/library/prompt/');
    const isDocDetailOpened = location.pathname?.startsWith('/doc/');

    const { isNavigationRestricted, setIsNavigationRestricted, promptMessage, setPromptMessage } =
        useNavigationRestrictionContext();
    const [isConfirmNavigationModalShown, setIsConfirmNavigationModalShown] = useState(false);

    const handleConfirmNavigation = () => {
        setIsNavigationRestricted(false);
        setPromptMessage(null);
        onConfirmNavigation();
        setIsConfirmNavigationModalShown(false);
    };

    const handleCancelNavigation = (e) => {
        e?.stopPropagation();
        setIsConfirmNavigationModalShown(false);
        onCancelNavigation();
    };

    const ConfirmModalComponent = isDocDetailOpened
        ? ConfirmNavigationModalFromDocDetail
        : ConfirmNavigationModal;

    return {
        handleConfirmNavigation,
        handleCancelNavigation,
        ConfirmModalComponent,
        isConfirmNavigationModalShown,
        isNavigationRestricted,
        setIsConfirmNavigationModalShown,
        promptMessage,
        isPromptViewPageOpened,
    };
};
