import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import * as Popover from '@radix-ui/react-popover';
import * as Tooltip from '@radix-ui/react-tooltip';

import { useCellValues, usePublisher } from '@mdxeditor/gurx';
import {
    activeEditor$,
    editorRootElementRef$,
    iconComponentFor$,
    removeLink$,
    switchFromPreviewToLinkEdit$,
    updateLink$,
    linkAutocompleteSuggestions$,
    onClickLinkCallback$,
    useTranslation,
} from '@mdxeditor/editor';

import './link-dialog.css';

import { LinkEditForm } from './LinkEditForm/LinkEditForm';
import {
    cancelLinkEdit$,
    closeLinkDialog$,
    linkDialogState$,
    onWindowChange$,
} from './linkDialogUtils';
import { useClickOutside } from '../../../hooks';

export const LinkDialog = () => {
    const [
        editorRootElementRef,
        activeEditor,
        iconComponentFor,
        linkDialogState,
        linkAutocompleteSuggestions,
        onClickLinkCallback,
    ] = useCellValues(
        editorRootElementRef$,
        activeEditor$,
        iconComponentFor$,
        linkDialogState$,
        linkAutocompleteSuggestions$,
        onClickLinkCallback$
    );
    const publishWindowChange = usePublisher(onWindowChange$);
    const updateLink = usePublisher(updateLink$);
    const cancelLinkEdit = usePublisher(cancelLinkEdit$);
    const closeLinkDialog = usePublisher(closeLinkDialog$);

    const switchFromPreviewToLinkEdit = usePublisher(switchFromPreviewToLinkEdit$);
    const removeLink = usePublisher(removeLink$);

    const contentRef = useRef(null);

    useEffect(() => {
        const update = () => {
            activeEditor?.getEditorState().read(() => {
                publishWindowChange(true);
            });
        };

        window.addEventListener('resize', update);
        window.addEventListener('scroll', update);

        return () => {
            window.removeEventListener('resize', update);
            window.removeEventListener('scroll', update);
        };
    }, [activeEditor, publishWindowChange]);

    useClickOutside(contentRef, (e) => {
        if (e.target.parentElement.nodeName === 'A') return;

        e.stopPropagation();
        e.preventDefault();
        closeLinkDialog();
    });

    const [copyUrlTooltipOpen, setCopyUrlTooltipOpen] = useState(false);

    const t = useTranslation();

    const theRect = linkDialogState.rectangle;

    const urlIsExternal =
        linkDialogState.type === 'preview' && linkDialogState.url.startsWith('http');

    return (
        <Popover.Root open={linkDialogState.type !== 'inactive'}>
            <Popover.Anchor
                data-visible={linkDialogState.type === 'edit'}
                className="linkDialogAnchor"
                style={{
                    top: `${theRect?.top ?? 0}px`,
                    left: `${theRect?.left ?? 0}px`,
                    width: `${theRect?.width ?? 0}px`,
                    height: `${theRect?.height ?? 0}px`,
                }}
            />

            <Popover.Portal container={editorRootElementRef?.current}>
                <div className="" ref={contentRef}>
                    <Popover.Content
                        className="linkDialogPopoverContent"
                        sideOffset={5}
                        onOpenAutoFocus={(e) => {
                            e.preventDefault();
                        }}
                        key={linkDialogState.linkNodeKey}
                    >
                        {linkDialogState.type === 'edit' && (
                            <LinkEditForm
                                url={linkDialogState.url}
                                title={linkDialogState.title}
                                onSubmit={updateLink}
                                onCancel={cancelLinkEdit.bind(null)}
                                linkAutocompleteSuggestions={linkAutocompleteSuggestions}
                            />
                        )}

                        {linkDialogState.type === 'preview' && (
                            <>
                                <a
                                    className="linkDialogPreviewAnchor"
                                    href={linkDialogState.url}
                                    {...(urlIsExternal
                                        ? { target: '_blank', rel: 'noreferrer' }
                                        : {})}
                                    onClick={(e) => {
                                        if (onClickLinkCallback !== null) {
                                            e.preventDefault();
                                            onClickLinkCallback(linkDialogState.url);
                                        }
                                    }}
                                    title={
                                        urlIsExternal
                                            ? t('linkPreview.open', `Open {{url}} in new window`, {
                                                  url: linkDialogState.url,
                                              })
                                            : linkDialogState.url
                                    }
                                >
                                    <span>{linkDialogState.url}</span>
                                    {urlIsExternal && iconComponentFor('open_in_new')}
                                </a>

                                <ActionButton
                                    onClick={() => {
                                        switchFromPreviewToLinkEdit();
                                    }}
                                    title={t('linkPreview.edit', 'Edit link URL')}
                                    aria-label={t('linkPreview.edit', 'Edit link URL')}
                                >
                                    {iconComponentFor('edit')}
                                </ActionButton>
                                <Tooltip.Provider>
                                    <Tooltip.Root open={copyUrlTooltipOpen}>
                                        <Tooltip.Trigger asChild>
                                            <ActionButton
                                                title={t(
                                                    'linkPreview.copyToClipboard',
                                                    'Copy to clipboard'
                                                )}
                                                aria-label={t(
                                                    'linkPreview.copyToClipboard',
                                                    'Copy to clipboard'
                                                )}
                                                onClick={() => {
                                                    void window.navigator.clipboard
                                                        .writeText(linkDialogState.url)
                                                        .then(() => {
                                                            setCopyUrlTooltipOpen(true);
                                                            setTimeout(() => {
                                                                setCopyUrlTooltipOpen(false);
                                                            }, 1000);
                                                        });
                                                }}
                                            >
                                                {copyUrlTooltipOpen
                                                    ? iconComponentFor('check')
                                                    : iconComponentFor('content_copy')}
                                            </ActionButton>
                                        </Tooltip.Trigger>
                                        <Tooltip.Portal container={editorRootElementRef?.current}>
                                            <Tooltip.Content
                                                className="tooltipContent"
                                                sideOffset={5}
                                            >
                                                {t('linkPreview.copied', 'Copied!')}
                                                <Tooltip.Arrow />
                                            </Tooltip.Content>
                                        </Tooltip.Portal>
                                    </Tooltip.Root>
                                </Tooltip.Provider>

                                <ActionButton
                                    title={t('linkPreview.remove', 'Remove link')}
                                    aria-label={t('linkPreview.remove', 'Remove link')}
                                    onClick={() => {
                                        removeLink();
                                    }}
                                >
                                    {iconComponentFor('link_off')}
                                </ActionButton>
                            </>
                        )}
                        <Popover.Arrow className="popoverArrow" />
                    </Popover.Content>
                </div>
            </Popover.Portal>
        </Popover.Root>
    );
};

const ActionButton = forwardRef(({ className, ...props }, ref) => {
    return <button className={classNames('actionButton', className)} ref={ref} {...props} />;
});
