import React from 'react';

const GitPullRequest = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 15 14" fill="none">
            <path
                d="M11.166 12.25C12.1325 12.25 12.916 11.4665 12.916 10.5C12.916 9.5335 12.1325 8.75 11.166 8.75C10.1995 8.75 9.41602 9.5335 9.41602 10.5C9.41602 11.4665 10.1995 12.25 11.166 12.25Z"
                stroke={props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.16602 5.25V12.25"
                stroke={props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.25 3.5H10C10.3094 3.5 10.6062 3.62292 10.825 3.84171C11.0438 4.0605 11.1667 4.35725 11.1667 4.66667V8.75"
                stroke={props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.16602 5.25C5.13251 5.25 5.91602 4.4665 5.91602 3.5C5.91602 2.5335 5.13251 1.75 4.16602 1.75C3.19952 1.75 2.41602 2.5335 2.41602 3.5C2.41602 4.4665 3.19952 5.25 4.16602 5.25Z"
                stroke={props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default GitPullRequest;
