import React from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';

import ListOption from '../../design-system/ListOption/ListOption';
import ListOptionGroup from '../../design-system/ListOptionGroup/ListOptionGroup';

const SortDropdown = ({
    options,
    selectedOption,
    handleOptionSelect,
    onClose,
    dropdownPosition,
    dropdownWidth,
}) => {
    const { width: screenWidth } = useWindowSize();

    const listOptions = options.map(({ text, value }, index) => (
        <ListOption
            key={value}
            text={text}
            index={index}
            leadingSelectIcon={true}
            selected={value === selectedOption}
            onClick={() => handleOptionSelect(value)}
        />
    ));

    const defaultDropdownWidth = screenWidth > 850 ? 355 : 320;

    const defaultPosition = {
        top: '120%',
        left: screenWidth >= 1050 ? 0 : 'auto',
        right: screenWidth < 1050 ? 0 : 'auto',
    };

    return (
        <div className="absolute z-20" style={{ ...(dropdownPosition || defaultPosition) }}>
            <ListOptionGroup
                listOptions={listOptions}
                fixedWidth={dropdownWidth || defaultDropdownWidth}
                handleClose={onClose}
            />
        </div>
    );
};

export default SortDropdown;
