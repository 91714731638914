import React from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import useCustomPagination from '../../../../hooks/useCustomPagination';

import { parseSearchParams } from '../../../../helpers/parseSearchSortPageSearchParams';
import { getGeneralRequestQueryParams } from '../../../../helpers/getGeneralRequestQueryParams';
import { useCollectRequestKeys } from '../../../../hooks/useCollectRequestKeys';

import Loading from '../../../../components/Loading';
import EntriesList from '../EntriesList/EntriesList';

export const containerPaddings = 'px-5 sm:px-6 pt-4 pb-8';

const BaseEntriesIndex = ({ baseId, isBaseDetailLoaded, hasEditAccess, schema }) => {
    const [searchParams] = useSearchParams();
    const parsedParams = parseSearchParams(searchParams);

    const page = parsedParams.page || 1;

    const baseEntriesHookResponse = useCustomPagination({
        client,
        route: `${API.ROUTES.knowledge.base}${baseId}/entry/`,
        pageIndex: page - 1,
        searchParams: getGeneralRequestQueryParams(parsedParams),
        autoFetchNextPage: true,
    });
    const { data, total, loading, key, nextPageKey } = baseEntriesHookResponse;

    const { requestKeysToMutate } = useCollectRequestKeys({ key, nextPageKey });

    if (!isBaseDetailLoaded) {
        return <></>;
    }

    if (!total && loading) {
        return (
            <div className="pt-5">
                <Loading />
            </div>
        );
    }

    const emptyStateShown = !total && !data?.length && !loading;

    const emptyMessageContainerClassName = classNames(
        'font-body text-body-bold-s text-neutral-300 px-1 max-w-[600px]',
        containerPaddings
    );

    if (emptyStateShown && parsedParams.search) {
        return (
            <p className={emptyMessageContainerClassName}>
                No entries found. Please adjust your search and try again!
            </p>
        );
    }

    if (emptyStateShown && !parsedParams.search) {
        return <p className={emptyMessageContainerClassName}>No entries found.</p>;
    }

    return (
        <EntriesList
            page={page}
            baseEntriesHookResponse={baseEntriesHookResponse}
            schema={schema}
            requestKeysToMutate={requestKeysToMutate}
            hasEditAccess={hasEditAccess}
        />
    );
};

export default BaseEntriesIndex;
