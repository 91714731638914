import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { statusBadge as jobsStatusBadge } from '../../../constants/statuses';

import { More2FillIcon } from '../../../design-system/Icons';
import { Badge, ButtonIcon } from '../../../design-system';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import JobCardMoreOptionsPopup from '../../JobsAndProjectsPage/JobCardMoreOptionsPopup/JobCardMoreOptionsPopup';
import RenameJobModal from '../../JobsAndProjectsPage/JobDetailPage/RenameJobModal/RenameJobModal';
import RestartJobSidePanel from '../../JobsAndProjectsPage/JobDetailPage/RestartJobSidePanel/RestartJobSidePanel';
import ConfirmArchiveJobModal from '../../JobsAndProjectsPage/JobDetailPage/ConfirmArchiveJobModal/ConfirmArchiveJobModal';

const JobCard = ({ job, pageName = 'workRuns', requestKeysToMutate = [], setJobs }) => {
    const { id, name, process, status, updated_at } = job;
    const location = useLocation();

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);
    const [actionModal, setActionModal] = useState({ action: null }); // actions are "rename", "archive", "restart"

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');
    const statusBadge = jobsStatusBadge[status];

    const withMoreOptionsPopup = pageName !== 'dashboard';
    const withProcessBadge = pageName !== 'processDetail';

    const { width: screenWidth } = useWindowSize();

    const openMoreOptionsPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setIsMoreOptionsPopupOpened(true);
    };

    const closeActionModal = () => setActionModal({ action: null });

    const linkStyles = {
        workRuns: 'py-3 rounded-2',
        dashboard: 'py-3.5 border-b-1 border-neutral-200',
        processDetail: 'py-3.5 border-b-1 border-neutral-200',
    };

    const lastUpdatedContainerStyles = {
        workRuns: 'pt-2 border-t-1',
        dashboard: 'pt-1',
        processDetail: 'pt-1',
    };

    const nameWidth = {
        workRuns: 'lg:w-[calc((100%-20px)*0.55)] xl:w-[calc((100%-32px)*0.55)]',
        dashboard: 'lg:w-[calc((100%-20px)*0.7)] xl:w-[calc((100%-32px)*0.7)]',
        processDetail: 'w-full',
    };

    const processBadgeWidth = {
        workRuns: 'lg:w-[calc((100%-20px)*0.45)] xl:w-[calc((100%-32px)*0.45)]',
        dashboard: 'lg:w-[calc((100%-20px)*0.3)] xl:w-[calc((100%-32px)*0.3)]',
    };

    const cardStyles = classNames(
        'flex flex-col min-[815px]:flex-row min-[815px]:items-center gap-x-5 gap-y-2 xl:gap-x-8 justify-between',
        {
            'w-full': !withMoreOptionsPopup,
            'w-full min-[815px]:w-[calc(100%-29px)]': withMoreOptionsPopup,
        }
    );

    const contentWrapperStyles = classNames(
        'w-full xl:w-[calc(100%-114px)] flex justify-between',
        withProcessBadge ? 'items-start' : 'items-center',
        {
            'min-[550px]:w-[calc(100%-102px)]': !withMoreOptionsPopup,
            'min-[550px]:w-[calc(100%-132px)] min-[815px]:w-[calc(100%-102px)]':
                withMoreOptionsPopup,
        }
    );

    const nameAndProcessBadgeWrapperStyles = classNames(
        'flex flex-col-reverse lg:flex-row lg:items-center gap-y-1 gap-x-5 xl:gap-x-8',
        {
            'w-full max-w-full': !withMoreOptionsPopup,
            'w-[calc(100%-32px)] min-[550px]:w-full': withMoreOptionsPopup,
        }
    );

    const nameStyles = classNames(
        'font-body-bold text-body-bold-s text-black truncate w-full',
        nameWidth[pageName]
    );

    const lastUpdatedWrapperStyles = classNames(
        'font-body text-body-regular-xs text-neutral-300 truncate w-full max-w-full min-[815px]:w-[222px] min-w-[222px] border-neutral-200 min-[815px]:pt-0 min-[815px]:border-t-0',
        lastUpdatedContainerStyles[pageName]
    );

    const moreOptionsBlock = (
        <div className="relative">
            <ButtonIcon icon={More2FillIcon} onClick={openMoreOptionsPopup} type="link" size="xs" />

            {isMoreOptionsPopupOpened && (
                <JobCardMoreOptionsPopup
                    setActionModal={setActionModal}
                    onClose={() => setIsMoreOptionsPopupOpened(false)}
                />
            )}
        </div>
    );

    return (
        <>
            <Link
                key={id}
                to={`/job/${id}`}
                state={{ from: location }}
                className={`w-full flex items-center min-[815px]:justify-between bg-white px-4 ${linkStyles[pageName]}`}
            >
                <div className={cardStyles}>
                    <div className="flex flex-col min-[550px]:flex-row min-[550px]:items-center gap-x-4 gap-y-2 xl:gap-x-8 justify-between w-full min-[815px]:w-[calc(100%-242px)] xl:w-[calc(100%-254px)]">
                        <div className={contentWrapperStyles}>
                            <div className={nameAndProcessBadgeWrapperStyles}>
                                <p className={nameStyles}>{name}</p>
                                {withProcessBadge && (
                                    <div className={`w-full ${processBadgeWidth[pageName]}`}>
                                        <Badge
                                            text={process.name}
                                            color="neutral"
                                            leadingIcon={PlayCircleFillIcon}
                                            leadingIconColor={process.icon_color}
                                        />
                                    </div>
                                )}
                            </div>
                            {withMoreOptionsPopup && screenWidth < 550 && moreOptionsBlock}
                        </div>

                        <div className="flex items-center gap-1">
                            <div className="w-[82px] min-w-[82px] flex min-[550px]:justify-end">
                                <Badge text={statusBadge.text} color={statusBadge.color} />
                            </div>
                            {withMoreOptionsPopup &&
                                screenWidth >= 550 &&
                                screenWidth < 815 &&
                                moreOptionsBlock}
                        </div>
                    </div>
                    <p className={lastUpdatedWrapperStyles}>
                        Last Updated{' '}
                        <span className="font-body-bold text-body-bold-xs text-neutral-500">
                            {formattedTime}
                        </span>
                    </p>
                </div>
                {withMoreOptionsPopup && screenWidth >= 815 && moreOptionsBlock}
            </Link>

            {actionModal.action === 'rename' && (
                <RenameJobModal
                    jobId={id}
                    jobName={name}
                    requestKeysToMutate={requestKeysToMutate || []}
                    onClose={closeActionModal}
                />
            )}
            {actionModal.action === 'restart' && (
                <RestartJobSidePanel
                    jobId={id}
                    requestKeysToMutate={requestKeysToMutate || []}
                    onClose={closeActionModal}
                />
            )}
            {actionModal.action === 'archive' && (
                <ConfirmArchiveJobModal
                    jobId={id}
                    jobName={name}
                    setJobs={setJobs}
                    requestKeysToMutate={requestKeysToMutate || []}
                    onClose={closeActionModal}
                />
            )}
        </>
    );
};

export default memo(JobCard);
