import React, { useState } from 'react';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { mutateDataAfterDeletingItem } from '../../../helpers/swrMutateUtils';
import { updateSidePanelStateAfterRemovingFolders } from '../../../helpers/docsSidePanelUtils';

import client from '../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import {
    ARCHIVED_FOLDER_OR_DOC_DATA_LS_KEY,
    CONTENT_TYPE,
    CONTENT_TYPE_ICON,
    CONTENT_TYPE_LABEL,
    ROUTE,
} from '../../../constants/docs';

import Modal from '../../../design-system/Modal/Modal';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import FileTextIcon from '../../../design-system/Icons/FileTextIcon';
import { Button, SvgIcon } from '../../../design-system';

const ArchiveItemModal = ({
    data,
    contentType = CONTENT_TYPE.document,
    requestKeysToMutate = [],
    setSuccessArchiveAlert,
    navigateToDocsIndex,
    onArchiveSuccess,
    onClose,
}) => {
    const { id, label } = data;

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const { location, setSidePanelFolders } = useDocsPageContext() || {};

    const { isMobile } = useResponsiveBreakpoints();

    const handleArchive = async () => {
        try {
            setIsLoading(true);

            const route = ROUTE[contentType];
            await client.delete(route + id + '/');

            mutateDataAfterDeletingItem({ requestKeysToMutate, id });

            const archivedItemData = { id, label, content_type: contentType };
            const parentFolderLocation = location || null;

            const successAlertData = { archivedItems: [archivedItemData], parentFolderLocation };

            if (setSuccessArchiveAlert) {
                setSuccessArchiveAlert(successAlertData);
            }

            if (contentType === CONTENT_TYPE.folder && setSidePanelFolders) {
                updateSidePanelStateAfterRemovingFolders({
                    removedFoldersIds: [id],
                    parentLocation: location,
                    setSidePanelFolders,
                });
            }

            if (onArchiveSuccess) {
                onArchiveSuccess(id);
            }

            if (navigateToDocsIndex) {
                localStorage.setItem(
                    ARCHIVED_FOLDER_OR_DOC_DATA_LS_KEY,
                    JSON.stringify(successAlertData)
                );
                navigateToDocsIndex();
            }

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    const archiveButtonState = isLoading ? 'loading' : 'default';

    const itemLabel = CONTENT_TYPE_LABEL[contentType];

    const Icon = CONTENT_TYPE_ICON[contentType] || FileTextIcon;

    const content = (
        <div className="flex flex-col gap-4">
            <p className="font-body text-body-regular-m text-neutral-500">
                Are you sure you’d like to archive this {itemLabel}?
            </p>

            <div className="px-2 py-3 rounded-2 bg-neutral-50 flex items-start gap-1 min-h-[16px]">
                <SvgIcon color="#754DCF" icon={Icon} size="medium" />
                <p className="font-body-bold text-body-bold-s text-[#25324B] py-auto">{label}</p>
            </div>

            <p className="font-body text-body-regular-m text-neutral-500">
                This {itemLabel} will be recoverable, if needed.
            </p>
        </div>
    );

    const controls = (
        <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between gap-2">
            <Button size="md" type="neutral" text="Cancel" onClick={onClose} />
            <Button
                size="md"
                type="secondary"
                text="Yes, Archive"
                state={archiveButtonState}
                onClick={handleArchive}
            />
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                bottomButtonsGroup={controls}
                containerCustomPaddings="!pt-0 pb-5"
                contentCustomPaddings="!py-0"
                bottomButtonsCustomPaddings="px-5"
                withCloseButton={false}
                customGap={32}
            >
                <div className="flex flex-col gap-5">
                    <ModalHeader
                        title={`Archive ${itemLabel}`}
                        outlined
                        customPaddings="px-5 py-3"
                        withEscapeButton={false}
                        onClose={onClose}
                    />
                    <div className="px-5">{content}</div>
                </div>

                <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            </MobilePopup>
        );
    }

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title={`Archive ${itemLabel}`} outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                {content}

                {controls}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default ArchiveItemModal;
