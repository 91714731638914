import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';
import operateClient from 'services/operate-api';
import { TASK_TYPES } from '../../../constants/library';
import useUser from '../../../hooks/useUser';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useFetchCollections } from '../../../hooks/useFetchCollections';

import {
    determineRequiredInputs,
    determineUserInputs,
    fillNewValuesIntoExistingRequiredInputs,
    fillNewValuesIntoExistingUserInputs,
    mergeExistingValuesIntoNewOutputs,
} from '../../../helpers/determineInputs';
import { getRetrievalSettings } from '../../../helpers/getRetrievalSettings';
import { getPromptSettings } from '../../../helpers/getPromptSettings';
import { isValidJson } from '../../../helpers/isValidJson';
import { handleTaskLocalStorageSave } from '../../../helpers/handleRecentVisitedPagesLocalStorageSave';
import {
    areInputsValid,
    formatInputValue,
    removeRedundantFieldsFromInput,
} from '../../../helpers/taskPlaygroundUtils';
import { normalizeMarkdown } from '../../../helpers/normalizeMarkdown';

import { capitalizeFirstLetter } from '../../../services/strings';
import {
    defaultErrorMessage,
    emptyFieldErrorMessage,
    invalidJsonErrorMessage,
} from '../../../constants/errorMessages';
import { urlRegex } from '../../../constants/regex_patterns';

import Loading from '../../../components/Loading';
import RequiredInputsBlock from './RequiredInputsBlock/RequiredInputsBlock';
import UserInputBlock from './UserInputBlock/UserInputBlock';
import OutputBlock from './OutputBlock/OutputBlock';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';
import { Button } from '../../../design-system';
import PromptViewPageHeader from '../../../components/PromptViewPageHeader/PromptViewPageHeader';
import ShareTaskModal from '../ShareTaskModal/ShareTaskModal';
import Alert from 'design-system/Alert/Alert';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';
import CheckLineIcon from 'design-system/Icons/CheckLineIcon';
import RetrievalSettingsBox from './RetrievalSettingsBox/RetrievalSettingsBox';
import CollapsableContainer from '../../../components/CollapsableContainer/CollapsableContainer';
import TaskPlaygroundActionBar from './TaskPlaygroundActionBar/TaskPlaygroundActionBar';
import ApiCallSettingsBlock from './ApiCallSettingsBlock/ApiCallSettingsBlock';
import TriggerSettingsBlock from './TriggerSettingsBlock/TriggerSettingsBlock';
import RegexSettingsBlock from './RegexSettingsBlock/RegexSettingsBlock';
import AssistantAndAgentSettingsBlock from './AssistantAndAgentSettingsBlock/AssistantAndAgentSettingsBlock';
import TaskPlaygroundLeftPanel from './TaskPlaygroundLeftPanel/TaskPlaygroundLeftPanel';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';

const TaskPlaygroundPage = () => {
    const { id: taskId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const fillId = searchParams.get('fill') || null;

    const { user } = useUser();

    const [task, setTask] = useState(null);
    const [requiredInputs, setRequiredInputs] = useState([]);
    const [userInputs, setUserInputs] = useState([]); // will stay [] if taskType === TASK_TYPES.trigger
    const [outputs, setOutputs] = useState([]);
    const [outputRating, setOutputRating] = useState(null);
    const [resultId, setResultId] = useState(null);
    const [additionalResultData, setAdditionalResultData] = useState(null); // Additional Result Data from /execute request

    const [retrievalSettings, setRetrievalSettings] = useState(null);
    const [retrievalSettingsErrorFields, setRetrievalSettingsErrorFields] = useState({
        max_distance: false,
    });
    const [retrievalResponseFormat, setRetrievalResponseFormat] = useState(null);

    const [changedTaskFields, setChangedTaskFields] = useState([]);
    const [saveChangesLoading, setSaveChangesLoading] = useState(false);
    const [changedTaskFieldsErrorMessages, setChangedTaskFieldsErrorMessages] = useState({});

    const [assistantAndAgentTasksChangeableData, setAssistantAndAgentTasksChangeableData] =
        useState({
            settings: null,
            messages: null,
            goals: null,
        });

    const [apiTaskChangeableData, setApiTaskChangeableData] = useState({
        request_url: null,
        request_data: null,
        request_format: null,
        request_method: null,
        request_headers: null,
    });
    const [apiTaskInputFieldsErrorMessages, setApiTaskInputFieldsErrorMessages] = useState({
        request_url: null,
        request_data: null,
        request_headers: null,
    });

    const [inboxTaskInstructions, setInboxTaskInstructions] = useState('');
    const [regexPattern, setRegexPattern] = useState(''); // used for regex task type

    const [taskChangeableData, setTaskChangeableData] = useState({}); // can be used to any task type

    const [collections, setCollections] = useState([]);
    const [areCollectionsLoading, setAreCollectionsLoading] = useState(false);

    const [isShareTaskModalOpened, setIsShareTaskModalOpened] = useState(false);
    const [isExpanded, setIsExpanded] = useState({
        apiCallSettings: true,
        retrievalSettings: true,
        assistantSettings: true,
        agentSettings: true,
        triggerSettings: true,
        regexSettings: true,
        outputs: true,
    });
    const leftContainerRef = useRef();

    const [errorAlert, setErrorAlert] = useState(null);

    const [webhookUrl, setWebhookUrl] = useState(null);
    const hasEditAccess = (task && task.access_level !== 'viewer') || false; // is admin or owner
    const taskType = task && task.task_type;

    const [protectedMode, setProtectedMode] = useState(false);
    const chainCount = task && task.chain_count;

    const [runTaskState, setRunTaskState] = useState({
        isLoading: false,
        errorMessage: null,
        successMessage: null,
        statusCode: null,
    });

    useFetchCollections(setCollections, setAreCollectionsLoading);
    useDocumentTitle(task && task?.name);

    useEffect(() => {
        const fetchTaskData = async () => {
            try {
                // fetch taskData and if there is a fillId than fetch resultData as well
                let taskData;
                let resultData;

                if (fillId) {
                    [{ data: taskData }, { data: resultData }] = await Promise.all([
                        client.get(`${API.ROUTES.library.task}${taskId}/`),
                        operateClient.get(`${API.ROUTES.operate.result}${fillId}/`),
                    ]);
                } else {
                    const response = await client.get(`${API.ROUTES.library.task}${taskId}/`);
                    taskData = response.data;
                }

                setTask(taskData);

                const {
                    func_def,
                    inputs,
                    outputs,
                    task_type: taskType,
                    default_prompt,
                    chain_count,
                } = taskData;

                if (taskType === TASK_TYPES.prompt) {
                    navigate(`/library/prompt/${default_prompt?.id}`);
                    return;
                }
                setProtectedMode(!!chain_count);

                if (func_def) {
                    // set requiredInputs (if there is a fillId than fill inputs values from resultData, otherwise, set value: "")
                    let requiredInputs = determineRequiredInputs(
                        func_def,
                        inputs || [],
                        setIsExpanded
                    );
                    if (fillId) {
                        requiredInputs = fillNewValuesIntoExistingRequiredInputs(
                            requiredInputs,
                            resultData.inputs
                        );
                    }
                    setRequiredInputs(requiredInputs);

                    if (
                        taskType !== TASK_TYPES.trigger &&
                        taskType !== TASK_TYPES.retrieval &&
                        taskType !== TASK_TYPES.record
                    ) {
                        // set userInputs (if there is a fillId than fill inputs values from resultData, otherwise, set value: "")
                        let userInputs = determineUserInputs(
                            func_def,
                            inputs || [],
                            setIsExpanded,
                            taskType,
                            default_prompt
                        );
                        if (fillId) {
                            userInputs = fillNewValuesIntoExistingUserInputs(
                                userInputs,
                                resultData.inputs
                            );
                        }
                        setUserInputs(userInputs);
                    }
                    if (taskType === TASK_TYPES.inbox) {
                        setInboxTaskInstructions(func_def.func_data?.instructions);
                    }
                    if (taskType === TASK_TYPES.webhook) {
                        setWebhookUrl(func_def.func_data?.webhook_url);
                    }
                    if (taskType === TASK_TYPES.retrieval) {
                        setRetrievalResponseFormat(func_def.func_data?.response_format);
                        setRetrievalSettings(getRetrievalSettings(func_def.func_data));
                    }
                    if (taskType === TASK_TYPES.assistant || taskType === TASK_TYPES.function) {
                        if (default_prompt) {
                            const { settings, messages } = default_prompt.default_prompt_version;
                            setAssistantAndAgentTasksChangeableData({
                                settings: getPromptSettings(settings),
                                messages: messages,
                                goals: func_def.func_data.goals.map(({ id, name, process }) => ({
                                    id,
                                    name: `${name} ${process ? `- ${process}` : ''}`,
                                })),
                            });
                        }
                    }
                    if (taskType === TASK_TYPES.api) {
                        const { connection, required_inputs, user_inputs, ...requestDetails } =
                            func_def.func_data;
                        setApiTaskChangeableData({ ...requestDetails });
                    }
                    if (taskType === TASK_TYPES.regex) {
                        const regexPattern = func_def.func_data.regex_pattern;
                        setRegexPattern(regexPattern);
                    }
                    if (taskType === TASK_TYPES.code) {
                        const code = func_def.func_data.code || '';
                        setTaskChangeableData({ code });
                    }
                    if (taskType === TASK_TYPES.plot) {
                        const definition = func_def.func_data.definition;
                        setTaskChangeableData({ definition });
                    }
                }

                setOutputs(outputs || []);
            } catch (e) {
                const backLinkHref = location.state?.libraryLocation ?? '/library';
                navigate(backLinkHref);
            }
        };

        fetchTaskData();
    }, []);

    useEffect(() => {
        // save task data in LocalStorage for UniversalSearchModal
        if (task && user) {
            handleTaskLocalStorageSave(task, user?.organization?.id);
        }
    }, [task, user]);

    const toggleCollapsed = (field) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const getSaveRetrievalChangesRequestBody = () => {
        return {
            retrieval_settings: changedTaskFields.reduce(
                (acc, field) =>
                    field === 'response_format'
                        ? { ...acc, [field]: retrievalResponseFormat }
                        : field === 'max_distance'
                        ? { ...acc, [field]: parseFloat(retrievalSettings.max_distance) }
                        : { ...acc, [field]: retrievalSettings[field] },
                {}
            ),
        };
    };

    const getSaveAssistantOrAgentChangesRequestBody = () => {
        const requestBody = {};
        const key = taskType === TASK_TYPES.function ? 'function_settings' : 'assistant_settings';

        changedTaskFields.map((field) => {
            if (field === 'goals') {
                requestBody.goals = assistantAndAgentTasksChangeableData.goals?.map(
                    (goal) => goal.id
                );
            }
            if (field === 'messages') {
                requestBody[key] = {
                    ...(requestBody[key] || {}),
                    messages: assistantAndAgentTasksChangeableData.messages,
                };
            }
            if (field === 'settings') {
                const { settings } = assistantAndAgentTasksChangeableData;

                requestBody[key] = {
                    ...(requestBody[key] || {}),
                    settings: {
                        provider: settings.model.provider,
                        temperature: settings.temperature.value || 0.0,
                        max_tokens: settings.maxTokens || 1000,
                        type: settings.model.type,
                        model: settings.model.model,
                    },
                };
            }
        });
        return requestBody;
    };

    const getSaveInboxChangesRequestBody = () => ({
        instructions: normalizeMarkdown(inboxTaskInstructions || ''),
    });

    const getSaveApiTaskChangesRequestBody = () => {
        return {
            api_settings: changedTaskFields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field]:
                        field === 'request_data' || field === 'request_headers'
                            ? JSON.parse(apiTaskChangeableData[field])
                            : apiTaskChangeableData[field],
                }),
                {}
            ),
        };
    };

    const getCodeTaskChangesRequestBody = () => ({
        code: taskChangeableData.code,
    });

    const getPlotTaskChangesRequestBody = () => {
        const definition =
            typeof taskChangeableData.definition === 'object'
                ? taskChangeableData.definition
                : JSON.parse(taskChangeableData.definition);
        return { definition };
    };

    const getSaveRegexChangesRequestBody = () => ({
        regex_pattern: regexPattern,
    });

    const isSaveTaskChangesRequestBodyError = () => {
        if (!changedTaskFields.length) {
            return true; // there is no data to save, so we don't have to make a request
        }

        // don't allow the user to save the task if there are no Goals selected
        if (
            (taskType === TASK_TYPES.assistant || taskType === TASK_TYPES.function) &&
            changedTaskFields.includes('goals') &&
            !assistantAndAgentTasksChangeableData.goals?.length
        ) {
            setChangedTaskFieldsErrorMessages({ goals: true });
            return true; // it means that there is an error, so we don't have to make a request
        }

        // for retrieval task check if max_distance is between 0 and 2
        if (
            taskType === TASK_TYPES.retrieval &&
            changedTaskFields.includes('max_distance') &&
            (retrievalSettings.max_distance <= 0 || retrievalSettings.max_distance >= 2)
        ) {
            setRetrievalSettingsErrorFields((prevState) => ({ ...prevState, max_distance: true }));
            return true; // it means that there is an error, so we don't have to make a request
        }

        // for api task check if request_data and request_headers are the valid json and request_url is valid URL
        if (taskType === TASK_TYPES.api) {
            const isHeadersDataValid =
                typeof apiTaskChangeableData.request_headers === 'string'
                    ? isValidJson(apiTaskChangeableData.request_headers)
                    : true;
            const isRequestDataValid =
                typeof apiTaskChangeableData.request_data === 'string'
                    ? isValidJson(apiTaskChangeableData.request_data)
                    : true;

            let isRequestUrlValid = true;
            if (changedTaskFields.includes('request_url')) {
                isRequestUrlValid = urlRegex.test(apiTaskChangeableData.request_url?.trim());
            }

            if (!isHeadersDataValid || !isRequestDataValid || !isRequestUrlValid) {
                setApiTaskInputFieldsErrorMessages((prevData) => ({
                    ...prevData,
                    request_headers: !isHeadersDataValid ? invalidJsonErrorMessage : null,
                    request_data: !isRequestDataValid ? invalidJsonErrorMessage : null,
                    request_url: !isRequestUrlValid ? invalidJsonErrorMessage : null,
                }));
                return true; // it means that there is an error, so we don't have to make a request
            }
        }

        // don't allow the user to save the task if Regex Pattern is empty
        if (taskType === TASK_TYPES.regex && !regexPattern) {
            setChangedTaskFieldsErrorMessages({ regex_pattern: emptyFieldErrorMessage });
            return true; // it means that there is an error, so we don't have to make a request
        }

        if (taskType === TASK_TYPES.code && !taskChangeableData.code) {
            setChangedTaskFieldsErrorMessages({ code: emptyFieldErrorMessage });
            return true; // it means that there is an error, so we don't have to make a request
        }

        if (taskType === TASK_TYPES.plot) {
            const { definition } = taskChangeableData;

            if (!definition) {
                setChangedTaskFieldsErrorMessages({ definition: emptyFieldErrorMessage });
                return true; // it means that there is an error, so we don't have to make a request
            }

            const isJsonValid = typeof definition === 'string' ? isValidJson(definition) : true;
            if (!isJsonValid) {
                setChangedTaskFieldsErrorMessages({ definition: invalidJsonErrorMessage });
                return true; // it means that there is an error, so we don't have to make a request
            }
        }

        return false; // it means there are no errors
    };

    const getSaveTaskChangesRequestBody = () =>
        taskType === TASK_TYPES.retrieval
            ? getSaveRetrievalChangesRequestBody()
            : taskType === TASK_TYPES.inbox
            ? getSaveInboxChangesRequestBody()
            : taskType === TASK_TYPES.api
            ? getSaveApiTaskChangesRequestBody()
            : taskType === TASK_TYPES.regex
            ? getSaveRegexChangesRequestBody()
            : taskType === TASK_TYPES.assistant || taskType === TASK_TYPES.function
            ? getSaveAssistantOrAgentChangesRequestBody()
            : taskType === TASK_TYPES.code
            ? getCodeTaskChangesRequestBody()
            : taskType === TASK_TYPES.plot
            ? getPlotTaskChangesRequestBody()
            : {};

    const saveTaskChanges = async () => {
        try {
            const isDataToSaveError = isSaveTaskChangesRequestBodyError();
            if (isDataToSaveError) {
                return;
            }

            setSaveChangesLoading(true);
            const requestBody = getSaveTaskChangesRequestBody();

            const { data } = await client.patch(
                `${API.ROUTES.library.task}${taskId}/`,
                requestBody
            );

            if (taskType === TASK_TYPES.inbox) {
                const updatedInstructions = data?.func_def?.func_data?.instructions;
                setInboxTaskInstructions(updatedInstructions || '');
            }
            if (taskType === TASK_TYPES.code) {
                const updatedCode = data?.func_def?.func_data?.code;
                setTaskChangeableData({ code: updatedCode || '' });
            }
            setChangedTaskFields([]);
            setSaveChangesLoading(false);
        } catch (error) {
            setSaveChangesLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
        }
    };

    const checkIfInputsAreFilledAndValid = () => {
        try {
            let taskInputsValid = true;
            userInputs?.forEach((userInput) => {
                const setUserInput = (inputs) => {
                    setUserInputs((prevData) =>
                        prevData.map((item) =>
                            item.key === userInput.key ? { ...item, inputs } : item
                        )
                    );
                };

                const { areErrorFields, updatedFormData } = areInputsValid(userInput.inputs);
                if (areErrorFields) {
                    setUserInput(updatedFormData);
                    taskInputsValid = false;
                }
            });

            const { areErrorFields, updatedFormData } = areInputsValid(requiredInputs);
            if (areErrorFields) {
                setRequiredInputs(updatedFormData);
                taskInputsValid = false;
            }

            return taskInputsValid;
        } catch (e) {
            return false;
        }
    };

    const clearOutputsValues = () => {
        setOutputs((existingOutputs) => existingOutputs.map(({ value, ...output }) => output));
    };

    const clearPrevRunData = () => {
        setOutputRating(null);
        setAdditionalResultData(null);
        setResultId(null);
        clearOutputsValues();
    };

    const executeTaskTesting = () => {
        setSearchParams({});

        const taskInputsValid = checkIfInputsAreFilledAndValid();

        if (taskInputsValid) {
            const userInputItems = userInputs.map((userInput) => userInput.inputs).flat();
            const inputs = [...requiredInputs, ...userInputItems].map((input) => {
                const formattedInput = removeRedundantFieldsFromInput(input);

                const updatedValue = formatInputValue(input);
                return { ...formattedInput, value: updatedValue };
            });

            clearPrevRunData();

            const data = {
                origin: 'test',
                sync: true,
                inputs: inputs,
                task_id: taskId,
            };
            if (taskType === TASK_TYPES.trigger) {
                data.auto = false;
            }
            operateClient
                .post(API.ROUTES.operate.result, data)
                .then((response) => {
                    const newOutputs = response.data.outputs;
                    if (taskType === TASK_TYPES.code) {
                        setOutputs((existingOutputs) =>
                            mergeExistingValuesIntoNewOutputs({ existingOutputs, newOutputs })
                        );
                        setIsExpanded((prevData) => ({ ...prevData, outputs: true }));
                    } else {
                        setOutputs(newOutputs);
                    }

                    const resultId = response.data.id;
                    setResultId(resultId);
                    setAdditionalResultData(response.data?.data);
                    setOutputRating(0);

                    const status = response.data.status;
                    if (status === 'failure') {
                        const errorMessage = response.data?.data?.error || defaultErrorMessage;
                        setRunTaskState({ isLoading: false, errorMessage, successMessage: null });
                    } else {
                        const successMessage = `${capitalizeFirstLetter(
                            taskType
                        )} task executed successfully`;
                        setRunTaskState({ isLoading: false, errorMessage: null, successMessage });
                    }
                })
                .catch((error) => {
                    setRunTaskState({
                        isLoading: false,
                        errorMessage: error.message,
                        statusCode: error.response.status,
                    });
                    if (error.response.status === 400) {
                        if (
                            error.response.data?.error &&
                            (error.response.data.error.includes('credentials for anthropic') ||
                                error.response.data.error.includes('credentials for openai'))
                        ) {
                            setRunTaskState({
                                isLoading: false,
                                errorMessage: `Add a valid API key to be able to run this task.`,
                            });
                        }
                        if (
                            error.response.data?.error &&
                            error.response.data.error.includes(
                                'Total token exceeds model token limit'
                            )
                        ) {
                            setRunTaskState({
                                isLoading: false,
                                errorMessage:
                                    'Oops! Your request is over the token limit for this model. Please try again with less tokens or a different model.',
                            });
                        }
                    }
                });
        } else {
            setRunTaskState({
                isLoading: false,
                errorMessage: 'Please fill in all required inputs',
            });
        }
    };

    const handleTestTask = () => {
        setRunTaskState({ isLoading: true, errorMessage: null, statusCode: null });

        // save Changes if there are any
        if (!!changedTaskFields.length) {
            const isDataToSaveError = isSaveTaskChangesRequestBodyError();
            if (isDataToSaveError) {
                setRunTaskState({ isLoading: false, errorMessage: null, statusCode: null });
                return;
            }

            const requestBody = getSaveTaskChangesRequestBody();
            client
                .patch(`${API.ROUTES.library.task}${taskId}/`, requestBody)
                .then(() => {
                    setChangedTaskFields([]);
                    executeTaskTesting();
                })
                .catch(() =>
                    setRunTaskState({
                        isLoading: false,
                        errorMessage: 'You have unsaved task edits. Please try again.',
                    })
                );
        } else {
            executeTaskTesting();
        }
    };

    const systemMessage = assistantAndAgentTasksChangeableData.messages?.[0]?.content;
    const userMessage = assistantAndAgentTasksChangeableData.messages?.[1]?.content;

    const messagesArray = {
        [TASK_TYPES.assistant]: [userMessage],
        [TASK_TYPES.function]: [systemMessage, userMessage],
    };

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white overflow-auto lg:overflow-hidden flex flex-col lg:flex-row">
            {task ? (
                <>
                    <div
                        className="h-auto lg:h-full bg-white relative w-full lg:w-1/2"
                        ref={leftContainerRef}
                    >
                        <div className="h-full flex flex-col overflow-y-auto gap-5 py-5 px-6">
                            <div className="flex items-center justify-between">
                                <Button
                                    type="link"
                                    size="sm"
                                    text="Back to Library"
                                    onClick={() =>
                                        navigate(location.state?.libraryLocation ?? '/library')
                                    }
                                    leadingIcon={ArrowGoBackLineIcon}
                                />
                                <Button
                                    type="link"
                                    size="sm"
                                    text="Share"
                                    onClick={() => setIsShareTaskModalOpened(true)}
                                />
                            </div>
                            <div className="lg:pt-5 lg:px-4 flex flex-col gap-6 flex-grow pb-3 lg:pb-[40px]">
                                <PromptViewPageHeader
                                    taskId={taskId}
                                    setTask={setTask}
                                    headerText={task.name}
                                    emojiCode={task.icon_text}
                                    subHeaderText={task.description}
                                    currentTaskCollectionsIds={task?.collections}
                                    allCollections={collections}
                                    setAllCollections={setCollections}
                                    areCollectionsLoading={areCollectionsLoading}
                                    collectionsEditable={hasEditAccess}
                                    leftContainerRef={leftContainerRef}
                                />
                                <TaskPlaygroundLeftPanel
                                    taskType={taskType}
                                    taskId={taskId}
                                    webhookUrl={webhookUrl}
                                    setWebhookUrl={setWebhookUrl}
                                    setUserInputs={setUserInputs}
                                    taskChangeableData={taskChangeableData}
                                    fieldsErrorMessages={changedTaskFieldsErrorMessages}
                                    setFieldErrorMessages={setChangedTaskFieldsErrorMessages}
                                    setTaskChangeableData={setTaskChangeableData}
                                    inboxTaskInstructions={inboxTaskInstructions}
                                    setInboxTaskInstructions={setInboxTaskInstructions}
                                    setChangedTaskFields={setChangedTaskFields}
                                    retrievalResponseFormat={retrievalResponseFormat}
                                    setRetrievalResponseFormat={setRetrievalResponseFormat}
                                    assistantAndAgentTasksChangeableData={
                                        assistantAndAgentTasksChangeableData
                                    }
                                    setAssistantAndAgentTasksChangeableData={
                                        setAssistantAndAgentTasksChangeableData
                                    }
                                    apiTaskChangeableData={apiTaskChangeableData}
                                    setApiTaskChangeableData={setApiTaskChangeableData}
                                    apiTaskInputFieldsErrorMessages={
                                        apiTaskInputFieldsErrorMessages
                                    }
                                    setApiTaskInputFieldsErrorMessages={
                                        setApiTaskInputFieldsErrorMessages
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="h-auto lg:h-full bg-neutral-50 max-lg:flex-grow relative w-full lg:w-1/2">
                        <div className="h-full w-full flex flex-col gap-3 pt-5 px-5 sm:px-8 overflow-y-auto pb-[80px]">
                            {taskType === TASK_TYPES.retrieval && retrievalSettings && (
                                <CollapsableContainer
                                    title="Retrieval Settings"
                                    isExpanded={isExpanded.retrievalSettings}
                                    toggleExpand={() => toggleCollapsed('retrievalSettings')}
                                >
                                    <RetrievalSettingsBox
                                        retrievalSettings={retrievalSettings}
                                        setRetrievalSettings={setRetrievalSettings}
                                        setChangedRetrievalFields={setChangedTaskFields}
                                        retrievalSettingsErrorFields={retrievalSettingsErrorFields}
                                        setRetrievalSettingsErrorFields={
                                            setRetrievalSettingsErrorFields
                                        }
                                    />
                                </CollapsableContainer>
                            )}
                            {taskType === TASK_TYPES.api && (
                                <ApiCallSettingsBlock
                                    isExpanded={isExpanded.apiCallSettings}
                                    toggleExpand={() => toggleCollapsed('apiCallSettings')}
                                    apiTaskChangeableData={apiTaskChangeableData}
                                    setApiTaskChangeableData={setApiTaskChangeableData}
                                    setChangedTaskFields={setChangedTaskFields}
                                />
                            )}
                            {(taskType === TASK_TYPES.assistant ||
                                taskType === TASK_TYPES.function) && (
                                <AssistantAndAgentSettingsBlock
                                    taskType={taskType}
                                    changeableData={assistantAndAgentTasksChangeableData}
                                    setChangeableData={setAssistantAndAgentTasksChangeableData}
                                    isExpanded={isExpanded}
                                    setIsExpanded={setIsExpanded}
                                    setChangedTaskFields={setChangedTaskFields}
                                    emptyGoalsError={changedTaskFieldsErrorMessages.goals}
                                    setEmptyGoalsError={setChangedTaskFieldsErrorMessages}
                                />
                            )}
                            {taskType === TASK_TYPES.trigger && (
                                <TriggerSettingsBlock
                                    isExpanded={isExpanded.triggerSettings}
                                    toggleExpand={() => toggleCollapsed('triggerSettings')}
                                    selectedGoal={task.func_def?.func_data?.goal}
                                />
                            )}
                            {taskType === TASK_TYPES.regex && (
                                <RegexSettingsBlock
                                    isExpanded={isExpanded.regexSettings}
                                    toggleExpand={() => toggleCollapsed('regexSettings')}
                                    regexPattern={regexPattern}
                                    setRegexPattern={setRegexPattern}
                                    setChangedTaskFields={setChangedTaskFields}
                                    fieldsErrorMessages={changedTaskFieldsErrorMessages}
                                    setFieldErrorMessages={setChangedTaskFieldsErrorMessages}
                                />
                            )}
                            <RequiredInputsBlock
                                requiredInputs={requiredInputs}
                                setRequiredInputs={setRequiredInputs}
                                hasEditAccess={hasEditAccess}
                                taskType={taskType}
                                taskId={taskId}
                                isExpanded={isExpanded.requiredInputs}
                                toggleCollapsed={() => toggleCollapsed('requiredInputs')}
                            />
                            {!!userInputs?.length &&
                                userInputs.map((userInput) => (
                                    <UserInputBlock
                                        key={userInput.key}
                                        taskId={taskId}
                                        taskType={taskType}
                                        userInput={userInput}
                                        setUserInputs={setUserInputs}
                                        setOutputs={setOutputs}
                                        isExpanded={isExpanded}
                                        toggleCollapsed={toggleCollapsed}
                                        hasEditAccess={hasEditAccess}
                                        messagesArray={messagesArray[taskType] || []}
                                        apiTaskChangeableData={apiTaskChangeableData}
                                        chainCount={chainCount}
                                        protectedMode={protectedMode}
                                        setProtectedMode={setProtectedMode}
                                        taskChangeableData={taskChangeableData}
                                    />
                                ))}
                            <OutputBlock
                                outputs={outputs}
                                setOutputs={setOutputs}
                                taskType={taskType}
                                resultId={resultId}
                                additionalResultData={additionalResultData}
                                outputRating={outputRating}
                                setOutputRating={setOutputRating}
                                hasEditAccess={hasEditAccess}
                                taskId={taskId}
                                isExpanded={isExpanded}
                                toggleCollapsed={toggleCollapsed}
                            />
                        </div>
                    </div>
                    <TaskPlaygroundActionBar
                        task={task}
                        taskType={taskType}
                        requiredInputs={requiredInputs}
                        userInputs={userInputs}
                        outputs={outputs}
                        handleTestTask={handleTestTask}
                        saveTaskChanges={saveTaskChanges}
                        saveChangesLoading={saveChangesLoading}
                        changedTaskFields={changedTaskFields}
                        hasEditAccess={hasEditAccess}
                        runTaskState={runTaskState}
                    />
                    {isShareTaskModalOpened && (
                        <ShareTaskModal onClose={() => setIsShareTaskModalOpened(false)} />
                    )}
                    {runTaskState.errorMessage && (
                        <Alert
                            status="critical"
                            message={runTaskState.errorMessage}
                            icon={ErrorWarningLineIcon}
                            handleClose={() =>
                                setRunTaskState({
                                    isLoading: false,
                                    errorMessage: null,
                                    statusCode: null,
                                })
                            }
                            statusCode={runTaskState.statusCode}
                        />
                    )}
                    {runTaskState.successMessage && (
                        <Alert
                            status="positive"
                            message={runTaskState.successMessage}
                            icon={CheckLineIcon}
                            handleClose={() =>
                                setRunTaskState({
                                    isLoading: false,
                                    errorMessage: null,
                                    successMessage: null,
                                    statusCode: null,
                                })
                            }
                        />
                    )}
                    <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
                </>
            ) : (
                <div className="w-full h-full flex-grow flex justify-center items-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default TaskPlaygroundPage;
