import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { DateTime } from 'luxon';

import BoxIcon from '../../../design-system/Icons/BoxIcon';
import ClockIcon from '../../../design-system/Icons/ClockIcon';
import BotActionModal from '../BotActionModal/BotActionModal';
import DeleteBotModal from '../DeleteBotModal/DeleteBotModal';
import ArchiveLineIcon from '../../../design-system/Icons/ArchiveLineIcon';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import BotMoreOptionPopup from '../BotMoreOptionPopup/BotMoreOptionPopup';
import MoreOptionsControls from '../../../components/MoreOptionsControls/MoreOptionsControls';

const BotCard = ({ bot, requestKeysToMutate }) => {
    const { id, name, description, updated_at, documents, bases } = bot;

    const location = useLocation();

    const [actionModal, setActionModal] = useState({ action: null });

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy');

    return (
        <li className="max-w-full self-stretch min-h-[150px]">
            <Link
                to={`/bot/${id}`}
                state={{ from: location }}
                className="relative flex flex-col gap-4 justify-between p-5 rounded-[6px] border-1 border-neutral-100 bg-white min-h-full"
            >
                <div className="w-full flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                        <div className="h-8 min-w-[32px] w-8 bg-purple-100 rounded-2 flex items-center justify-center">
                            <BoxIcon width={16} height={16} color="#754DCF" />
                        </div>
                        <p className="max-w-full font-body-bold text-body-bold-m text-black line-clamp-2 pr-2">
                            {name}
                        </p>
                    </div>

                    <p className="line-clamp-2 font-body text-body-regular-xs text-neutral-300">
                        {description}
                    </p>
                </div>

                <MoreOptionsControls
                    buttonSize="xs"
                    containerStyles="absolute top-1.5 right-1"
                    popupComponent={BotMoreOptionPopup}
                    setActionModal={setActionModal}
                />

                <div className="flex justify-between items-center flex-wrap gap-2">
                    <div className="flex gap-1 items-center">
                        <ClockIcon width={14} height={14} color="#754DCF" />

                        <p className="font-body text-body-regular-xs text-neutral-500 whitespace-nowrap ">
                            Last Updated{' '}
                            <span className="font-body-bold text-body-bold-xs">
                                {formattedTime}
                            </span>
                        </p>
                    </div>

                    <div className="flex items-center gap-x-2 gap-y-1 flex-wrap">
                        <div className="flex gap-1 items-center">
                            <ArchiveLineIcon width={14} height={14} color="#754DCF" />
                            <p className="text-neutral-500 text-body-regular-xs">
                                {bases?.length ?? 0}
                            </p>
                        </div>
                        <div className="flex gap-1 items-center">
                            <FileTextLineIcon width={14} height={14} color="#754DCF" />
                            <p className="text-neutral-500 text-body-regular-xs">
                                {documents?.length ?? 0}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>

            {actionModal.action === 'edit' && (
                <BotActionModal
                    id={id}
                    mode="edit"
                    botData={{ name, description }}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setActionModal({ action: null })}
                />
            )}

            {actionModal.action === 'delete' && (
                <DeleteBotModal
                    id={id}
                    botName={name}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setActionModal({ action: null })}
                />
            )}
        </li>
    );
};

export default memo(BotCard);
