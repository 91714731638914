import React, { useRef } from 'react';
import { useClickOutside } from '../../../hooks';
import { useWindowSize } from '../../../hooks/useWindowSize';

import SettingsStepCard from '../SettingsStepCard/SettingsStepCard';
import { Button } from '../../../design-system';

const StepsPanel = ({
    settings,
    stepsErrorData,
    currentStepOrder,
    setCurrentStepOrder,
    completedStepOrders,
    submissionStatus,
    isMobileStepsPanelOpened,
    onClose,
    pageAction,
}) => {
    // an order of last completed step (step with the highest order)
    const maxCompletedStepOrder = Math.max(...completedStepOrders, -1);

    const panelRef = useRef(null);

    const { width: screenWidth } = useWindowSize();
    const isMobile = screenWidth < 1024;

    const handleClickOutside = () => {
        if (isMobile) {
            onClose();
        }
    };

    useClickOutside(panelRef, handleClickOutside);

    const mobileStyles =
        'fixed left-0 sm:left-[68px] right-0 bottom-0 rounded-t-2 border-1 border-neutral-200 shadow-l1 p-6 z-[35] bg-white max-h-[calc(100%-80px)] overflow-y-auto';
    const desktopStyles =
        'w-[33%] l:w-[30%] xl:w-[25%] h-full bg-white overflow-y-auto px-4 pt-4 pb-8 hide-scrollbar';

    // on mobile version StepsPanel is shown as a bottom popup
    // on desktop version StepsPanel is always shown as a left side panel
    if (isMobile && !isMobileStepsPanelOpened) {
        return <></>;
    }

    return (
        <>
            <div className="fixed top-0 left-0 right-0 bottom-0 bg-black/50 lg:hidden z-[34]"></div>
            <div className={isMobile ? mobileStyles : desktopStyles} ref={panelRef}>
                <p className="font-body text-body-bold-xs text-neutral-300 uppercase mb-2">
                    {pageAction} steps
                </p>
                {!!settings?.length && (
                    <ul className="flex flex-col gap-2">
                        {settings.map((step) => {
                            const isStepError = !!stepsErrorData[step.order]?.isError;
                            const isStepCompleted = completedStepOrders.includes(step.order);

                            const stepState = isStepError
                                ? 'error'
                                : isStepCompleted
                                ? 'completed'
                                : 'default';

                            // Enabled steps are all completed and the next one is the first incomplete step and just if form is displayed (not loader or success view)
                            const isStepDisabled =
                                step.order > maxCompletedStepOrder + 1 ||
                                submissionStatus !== 'default';

                            return (
                                <li key={step.order}>
                                    <SettingsStepCard
                                        step={step}
                                        state={stepState}
                                        isCurrentStep={step.order === currentStepOrder}
                                        isStepDisabled={isStepDisabled}
                                        setCurrentStepOrder={setCurrentStepOrder}
                                        closeMobileStepsPanel={onClose}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                )}

                <div className="lg:hidden flex flex-col mt-4">
                    <Button type="neutral" size="sm" text="Close" onClick={onClose} />
                </div>
            </div>
        </>
    );
};

export default StepsPanel;
