import React from 'react';

const LockIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M12.6667 7.33337H3.33333C2.59695 7.33337 2 7.93033 2 8.66671V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V8.66671C14 7.93033 13.403 7.33337 12.6667 7.33337Z"
                fill="none"
                stroke={props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.66406 7.33337V4.66671C4.66406 3.78265 5.01525 2.93481 5.64037 2.30968C6.26549 1.68456 7.11334 1.33337 7.9974 1.33337C8.88145 1.33337 9.7293 1.68456 10.3544 2.30968C10.9795 2.93481 11.3307 3.78265 11.3307 4.66671V7.33337"
                stroke={props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LockIcon;
