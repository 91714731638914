import React from 'react';
import { ListOption, ListOptionGroup } from '../../../design-system';

const BaseCardMoreOptionsPopup = ({ handleClose, openRenameModal }) => {
    const handleRenameBaseClick = () => {
        openRenameModal();
        handleClose();
    };

    return (
        <div
            className="absolute top-[110%] right-3"
            onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
            }}
        >
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Rename Base"
                            onClick={handleRenameBaseClick}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleClose();
                }}
            />
        </div>
    );
};

export default BaseCardMoreOptionsPopup;
