import React from 'react';

import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';

const ChatInputExpandedMode = ({
    message,
    handleMessageChange,
    placeholder,
    onClose,
    bottomButtonsGroup,
    closeExpandedModeOnOutsideClick,
}) => {
    return (
        <MobilePopup
            onClose={onClose}
            withCloseButton={false}
            bottomButtonsGroup={bottomButtonsGroup}
            containerCustomPaddings="px-1 pb-4"
            minHeight="calc(100vh * 0.92)"
            customGap={16}
            closeOnClickOutside={closeExpandedModeOnOutsideClick}
        >
            <div className="px-3 flex-1 h-full min-h-full flex flex-col">
                <textarea
                    value={message}
                    placeholder={placeholder}
                    onChange={handleMessageChange}
                    className="bg-transparent flex-grow h-full min-h-full max-w-full w-full focus:outline-0 resize-none placeholder-neutral-300 font-body text-body-regular-s text-neutral-500"
                />
            </div>
        </MobilePopup>
    );
};

export default ChatInputExpandedMode;
