import { mutateRequestKeysData } from './swrMutateUtils';

export const mutateDataAfterRenamingBase = ({ requestKeysToMutate, id, updatedName }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: false,
        cb: (currentData) => {
            const updatedResults = currentData.results.map((base) =>
                base.id === id ? { ...base, name: updatedName } : base
            );

            return { ...currentData, results: updatedResults };
        },
    });
};
