import React from 'react';
import classNames from 'classnames';

import { statusBadge as _statusBadge } from '../../../../constants/statuses';

import { Badge, ButtonIcon, SvgIcon } from '../../../../design-system';
import { FlashlightFillIcon, ShareBoxLineIcon } from '../../../../design-system/Icons';
import PlayCircleFillIcon from '../../../../design-system/Icons/PlayCircleFillIcon';

const AgentContentPiece = ({ content }) => {
    const { label, data } = content;
    const { name, process, status, thread_id } = data || {};

    const statusBadge = _statusBadge[status];

    const cardContainerClassName = classNames(
        'px-3 sm:px-4 py-3 rounded-2 bg-white',
        'grid grid-rows-[auto_auto] min-[560px]:grid-rows-1 grid-cols-[40px_minmax(100px,_1fr)_28px] min-[560px]:grid-cols-[40px_minmax(100px,_1fr)_86px_28px] gap-x-1 gap-y-1 items-start min-[560px]:items-center'
    );

    return (
        <div className="w-full max-w-full">
            <p className="font-body-bold text-body-bold-s text-neutral-500 mb-2">{label}</p>

            <div className="w-full max-w-full rounded-2 p-2 sm:p-3 bg-neutral-50">
                <div className={cardContainerClassName}>
                    <div className="w-[40px] h-[40px] rounded-2 bg-purple-100 flex items-center justify-center">
                        <SvgIcon color="#754DCF" icon={FlashlightFillIcon} size="medium" />
                    </div>

                    <div className="pl-1">
                        <p className="font-body-bold text-body-bold-xs text-black mb-1 truncate">
                            {name}
                        </p>
                        <div>
                            <Badge
                                text={process?.name}
                                color="neutral"
                                leadingIcon={PlayCircleFillIcon}
                                leadingIconColor="#1F2125"
                            />
                        </div>
                    </div>

                    <div className="min-[560px]:justify-self-end min-[560px]:row-start-auto min-[560px]:row-end-auto row-start-2 row-end-2 col-start-2 min-[560px]:col-start-auto col-span-1 overflow-hidden max-w-full">
                        <Badge text={statusBadge?.text} color={statusBadge?.color} />
                    </div>

                    <ButtonIcon
                        size="xs"
                        type="link"
                        icon={ShareBoxLineIcon}
                        onClick={() => window.open(`/job/${thread_id}`, '_blank')}
                    />
                </div>
            </div>
        </div>
    );
};

export default AgentContentPiece;
