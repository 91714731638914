import React, { memo } from 'react';

import { sortOptions, THREADS_TAB } from '../../../constants/threads';

import SearchSortAndFilterByProcessBar from '../../../components/SearchSortAndFilterByProcessBar/SearchSortAndFilterByProcessBar';
import ExecutionTopControls from '../ExecutionTopControls/ExecutionTopControls';

const ThreadsTopControls = ({
    parsedParams,
    activeTab,
    resetPageState,
    isMobile,
    loading,
    total,
}) => {
    return (
        <>
            {activeTab === THREADS_TAB.threads && (
                <SearchSortAndFilterByProcessBar
                    parsedParams={parsedParams}
                    sortOptions={sortOptions}
                    resetPageState={resetPageState}
                    usePagination={!isMobile}
                    searchBarPlaceholder="Search by thread name"
                    loading={loading}
                    total={total}
                    label="Thread"
                    maxWidth={455}
                />
            )}

            {activeTab === THREADS_TAB.executions && (
                <ExecutionTopControls
                    parsedParams={parsedParams}
                    isMobile={isMobile}
                    resetPageState={resetPageState}
                />
            )}
        </>
    );
};

export default memo(ThreadsTopControls);
