import React, { useEffect, useMemo, useState } from 'react';
import useUser from '../../hooks/useUser';
import useCustomPagination from '../../hooks/useCustomPagination';
import { mergeNewDataIntoCurrent } from '../../helpers/mergeNewDataIntoCurrent';
import { SORT_TYPES } from '../../constants/sort';

import { API } from 'constants';
import client from '../../services/library-api';
import reportClient from '../../services/report-api';

import YourTopProcessesBlock from './YourTopProcessesBlock/YourTopProcessesBlock';
import RecentJobsBlock from './RecentJobsBlock/RecentJobsBlock';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Loading from '../../components/Loading';
import ClientPageTitleBlock from '../../components/ClientPageTitleBlock/ClientPageTitleBlock';

const DashboardPage = () => {
    const { user } = useUser();

    const [processesList, setProcessesList] = useState(null);
    const [processRequestPage, setProcessRequestPage] = useState(1);

    const {
        data: processes,
        loading: processesLoading,
        hasNextPage,
    } = useCustomPagination({
        client,
        route: API.ROUTES.library.process,
        pageIndex: processRequestPage - 1,
        searchParams: { shared: true, ordering: SORT_TYPES.lastRun },
        limit: 4,
        autoFetchNextPage: true,
    });

    const { data: jobs } = useCustomPagination({
        client: reportClient,
        route: API.ROUTES.report.job,
        pageIndex: 0,
        searchParams: { ordering: SORT_TYPES.created },
        limit: 10,
    });

    useEffect(() => {
        setProcessesList((prevProcesses) => {
            if (!prevProcesses && !processes) {
                return null;
            } else {
                return mergeNewDataIntoCurrent(prevProcesses || [], processes || []);
            }
        });
    }, [processes]);

    useDocumentTitle('Dashboard');

    const loadNextProcessesPage = () => {
        setProcessRequestPage((page) => page + 1);
    };

    const pageTitle = useMemo(
        () => (user?.first_name ? `Welcome, ${user.first_name}!` : 'Hello there!'),
        [user]
    );

    const isPageDisplayed = user && processesList;
    return (
        <div className="page-position bg-neutral-50 overflow-y-auto flex flex-col">
            {isPageDisplayed && (
                <>
                    <div className="py-4 px-5 sm:py-5 sm:px-8 bg-white flex flex-col gap-5">
                        <ClientPageTitleBlock title={pageTitle} />
                        <YourTopProcessesBlock
                            processes={processesList}
                            hasNextPage={hasNextPage}
                            loading={processesLoading}
                            loadNextProcessesPage={loadNextProcessesPage}
                        />
                    </div>
                    <RecentJobsBlock jobs={jobs} />
                </>
            )}
            {!isPageDisplayed && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default DashboardPage;
