import React from 'react';

const DraggableLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 27 24">
            <path
                d="M9.5625 7C10.4945 7 11.25 6.32843 11.25 5.5C11.25 4.67157 10.4945 4 9.5625 4C8.63052 4 7.875 4.67157 7.875 5.5C7.875 6.32843 8.63052 7 9.5625 7ZM9.5625 13.5C10.4945 13.5 11.25 12.8284 11.25 12C11.25 11.1716 10.4945 10.5 9.5625 10.5C8.63052 10.5 7.875 11.1716 7.875 12C7.875 12.8284 8.63052 13.5 9.5625 13.5ZM11.25 18.5C11.25 19.3284 10.4945 20 9.5625 20C8.63052 20 7.875 19.3284 7.875 18.5C7.875 17.6716 8.63052 17 9.5625 17C10.4945 17 11.25 17.6716 11.25 18.5ZM17.4375 7C18.3695 7 19.125 6.32843 19.125 5.5C19.125 4.67157 18.3695 4 17.4375 4C16.5056 4 15.75 4.67157 15.75 5.5C15.75 6.32843 16.5056 7 17.4375 7ZM19.125 12C19.125 12.8284 18.3695 13.5 17.4375 13.5C16.5056 13.5 15.75 12.8284 15.75 12C15.75 11.1716 16.5056 10.5 17.4375 10.5C18.3695 10.5 19.125 11.1716 19.125 12ZM17.4375 20C18.3695 20 19.125 19.3284 19.125 18.5C19.125 17.6716 18.3695 17 17.4375 17C16.5056 17 15.75 17.6716 15.75 18.5C15.75 19.3284 16.5056 20 17.4375 20Z"
                fill={props.color}
            />
        </svg>
    );
};

export default DraggableLineIcon;
