import React from 'react';
import ProcessSettingsBlock from '../ProcessSettingsBlock/ProcessSettingsBlock';
import EventNotificationSettingsBlock from '../EventNotificationSettingsBlock/EventNotificationSettingsBlock';

const AdminTabSection = ({ processDetail, setProcessDetail }) => {
    return (
        <div className="flex flex-col gap-5">
            <ProcessSettingsBlock
                processDetail={processDetail}
                setProcessDetail={setProcessDetail}
            />

            <EventNotificationSettingsBlock process={processDetail} setProcess={setProcessDetail} />
        </div>
    );
};

export default AdminTabSection;
