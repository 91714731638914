import axios from 'axios';
axios.defaults.withCredentials = true;

const getAuthToken = () => {
    return localStorage.getItem('authKey');
};

let baseUrl;
if (process.env.NODE_ENV === 'production') {
    baseUrl = '/api/organization/';
} else {
    baseUrl = 'http://127.0.0.1:8000/api/organization/';
}

const organizationClient = axios.create({
    baseURL: baseUrl,
});

// Using request interceptor to set the auth token before each request (so that it loads for every request)
organizationClient.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
});

export default organizationClient;
