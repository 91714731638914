import React from 'react';

const H1Icon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M8.66927 13.3337H7.33594V8.66699H2.66927V13.3337H1.33594V2.66699H2.66927V7.33366H7.33594V2.66699H8.66927V13.3337ZM14.0029 5.33366V13.3337H12.6696L12.6693 6.80299L11.3359 7.16033V5.78033L13.0029 5.33366H14.0029Z"
                fill={props.color}
            />
        </svg>
    );
};

export default H1Icon;
