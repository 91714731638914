import React, { useState } from 'react';

import * as API from '../../../../constants/api';
import client from '../../../../services/organization-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { useFormState } from '../../../../hooks/useFormState';
import { mutateListDataAfterRenamingItem } from '../../../../helpers/swrMutateUtils';

import Alert from '../../../../design-system/Alert/Alert';
import SectionWrapper from '../../SectionWrapper/SectionWrapper';
import InputWithAutoSaveOnBlur from '../../../../design-system/InputWithAutoSaveOnBlur/InputWithAutoSaveOnBlur';
import OrganizationMembersBlock from '../OrganizationMembersBlock/OrganizationMembersBlock';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const OrganizationDetailsBlock = ({
    organizationData,
    refetchOrganizationData,
    isOwnerOrAdmin,
    orgPlan,
    setOrganizationsList,
    organizationsRequestKeysToMutate,
}) => {
    const organizationName = organizationData?.organization?.name;

    const { formData, handleInputChange } = useFormState({
        name: organizationName,
    });
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSaveName = async () => {
        if (!formData.name) {
            handleInputChange('name', organizationName);
            throw new Error('Organization name cannot be empty');
        }

        try {
            const orgId = organizationData.organization?.id;
            await client.patch(`${API.ROUTES.organization.organization}${orgId}/`, {
                name: formData.name,
            });
            setOrganizationsList((organizations) =>
                organizations.map((organization) =>
                    organization.id === orgId
                        ? { ...organization, name: formData.name }
                        : organization
                )
            );
            mutateListDataAfterRenamingItem({
                requestKeysToMutate: organizationsRequestKeysToMutate,
                id: orgId,
                updatedName: formData.name,
            });

            refetchOrganizationData();
        } catch (e) {
            handleInputChange('name', organizationName);
            setErrorAlert({ message: defaultErrorMessage });
            throw new Error(e);
        }
    };

    return (
        <SectionWrapper title="Organization Details">
            <div className="flex flex-col gap-4">
                <div className="w-full max-w-[444px]">
                    <p className="font-body-bold text-body-bold-s text-neutral-500 mb-2">
                        Organization Name
                    </p>

                    <InputWithAutoSaveOnBlur
                        size="xs"
                        name="organization_name"
                        value={formData.name}
                        originalValue={organizationName}
                        placeholder="Enter Organization Name"
                        isDisabled={!isOwnerOrAdmin}
                        handleSave={handleSaveName}
                        handleInputChange={(e) => handleInputChange('name', e.target.value)}
                    />
                </div>

                <OrganizationMembersBlock
                    organizationData={organizationData}
                    refetchOrganizationData={refetchOrganizationData}
                    isOwnerOrAdmin={isOwnerOrAdmin}
                    orgPlan={orgPlan}
                />
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </SectionWrapper>
    );
};

export default OrganizationDetailsBlock;
