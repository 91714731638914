import React, { createContext, useEffect, useState } from 'react';
import { VIEW_TYPE_LS_KEY, VIEW_TYPES, VIEW_TYPES_ARRAY } from '../constants/viewTypes';

export const ViewTypeContext = createContext();

export const ViewTypeProvider = ({ children }) => {
    const [viewType, setViewType] = useState(() => {
        const result = localStorage.getItem(VIEW_TYPE_LS_KEY);
        return VIEW_TYPES_ARRAY.includes(result) ? result : VIEW_TYPES.admin;
    });

    useEffect(() => {
        // This useEffect hook sets up a listener for changes in the 'localStorage' (specifically when viewType is changed)
        // If a change is detected in the storage key in any tab except the current one, it will update the 'viewType' state where the same application is open.
        const handleStorageChange = async (e) => {
            if (e.key === VIEW_TYPE_LS_KEY) {
                const newValue = e.newValue;
                setViewType(VIEW_TYPES_ARRAY.includes(newValue) ? newValue : VIEW_TYPES.admin);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const toggleViewType = () => {
        const newType = viewType === VIEW_TYPES.admin ? VIEW_TYPES.client : VIEW_TYPES.admin;
        setViewType(newType);
        localStorage.setItem(VIEW_TYPE_LS_KEY, newType);
    };

    return (
        <ViewTypeContext.Provider value={{ viewType, toggleViewType }}>
            {children}
        </ViewTypeContext.Provider>
    );
};
