import React, { useMemo } from 'react';

import { CHAT_ROLE, CHAT_TYPE } from '../../../constants/assistant';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { SvgIcon } from '../../../design-system';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import RefreshCwIcon from '../../../design-system/Icons/RefreshCwIcon';
import MessageWrapper from '../MessageWrapper/MessageWrapper';
import DiviBotMessageHeader from '../DiviBotMessageHeader/DiviBotMessageHeader';
import DiviBotMessageSourcesBlock from '../DiviBotMessageSourcesBlock/DiviBotMessageSourcesBlock';
import AssistantMessageActionButtons from '../AssistantMessageActionButtons/AssistantMessageActionButtons';
import CustomBotMessageHeader from '../CustomBotMessageHeader/CustomBotMessageHeader';

const CHAT_TYPES_WITH_SOURCE_BLOCK = [CHAT_TYPE.divibot, CHAT_TYPE.custom];
// possible statuses: "failure", "pending", "success"
const AssistantMessage = ({
    message,
    setChatMessages,
    isRegenerateDisabled,
    handleRegenerateChatMessage,
}) => {
    const {
        id,
        default_version: {
            content,
            status,
            tools,
            type: chatType,
            custom_bot_version,
            streamErrorMessage,
        },
    } = message;

    const isActionButtonsDisplayed = status === 'success';
    const isClickableErrorBanner = status === 'failure';

    const toolsArray = useMemo(() => Object.entries(tools || {}), [tools]);

    const isSourceBlockDisplayed =
        CHAT_TYPES_WITH_SOURCE_BLOCK.includes(chatType) && status === 'success';

    const onRegenerateMessageClick = () => {
        if (handleRegenerateChatMessage) {
            handleRegenerateChatMessage({ id, role: CHAT_ROLE.assistant });
        }
    };

    return (
        <MessageWrapper role={CHAT_ROLE.assistant} blackRobotIcon={chatType !== CHAT_TYPE.divibot}>
            {chatType === CHAT_TYPE.divibot && <DiviBotMessageHeader toolsArray={toolsArray} />}
            {chatType === CHAT_TYPE.custom && (
                <CustomBotMessageHeader customBotVersion={custom_bot_version} />
            )}

            <RichTextArea value={content} previewOnly autoExpand isBorderHidden />

            {isSourceBlockDisplayed && <DiviBotMessageSourcesBlock toolsArray={toolsArray} />}

            {isActionButtonsDisplayed && (
                <AssistantMessageActionButtons
                    message={message}
                    setChatMessages={setChatMessages}
                    isRegenerateDisabled={isRegenerateDisabled}
                    onRegenerateMessageClick={onRegenerateMessageClick}
                />
            )}

            {isClickableErrorBanner && (
                <div className="flex gap-2 cursor-pointer" onClick={onRegenerateMessageClick}>
                    <SvgIcon color="#E95B69" icon={RefreshCwIcon} size="medium" />
                    <p className="font-body text-body-regular-s text-red-500">
                        {streamErrorMessage || defaultErrorMessage}
                    </p>
                </div>
            )}
        </MessageWrapper>
    );
};

export default AssistantMessage;
