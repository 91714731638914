export const setSelectorPositionInPromptViewPageHeader = ({
    screenWidth,
    collectionButtonRef,
    leftContainerRef,
    setCollectionSelectorPosition,
    setCollectionSelectorWidth,
    setCollectionSelectorMaxHeight,
}) => {
    if (collectionButtonRef.current) {
        const top = '30px';
        const { x: buttonXCoord, y: buttonYCoord } =
            collectionButtonRef.current.getBoundingClientRect();
        const leftContainerWidth =
            (leftContainerRef?.current && leftContainerRef.current?.clientWidth) || 340;

        if (screenWidth <= 460) {
            setCollectionSelectorPosition({
                top: 'calc(100% + 5px)',
                left: '50%',
                transform: 'translateX(-50%)',
            });
            setCollectionSelectorWidth(screenWidth < 400 ? screenWidth - 50 : screenWidth - 90);
        }

        if (screenWidth > 460) {
            let availableLeftSpace = 0;
            let availableRightSpace = 0;

            if (screenWidth > 460 && screenWidth < 640) {
                availableLeftSpace = buttonXCoord + 97;
                availableRightSpace = screenWidth - buttonXCoord - 8;
            } else if (screenWidth >= 640 && screenWidth < 1024) {
                availableLeftSpace = buttonXCoord + 28;
                availableRightSpace = screenWidth - buttonXCoord;
            } else if (screenWidth >= 1024) {
                availableLeftSpace = buttonXCoord + 28;
                availableRightSpace = leftContainerWidth + 68 - buttonXCoord;
            }

            const difference = availableLeftSpace - availableRightSpace;
            const leftSideHasMoreSpace = difference > 0;
            if (availableLeftSpace > 370) {
                setCollectionSelectorWidth(360);
                if (availableRightSpace < 370) {
                    setCollectionSelectorPosition({ top, right: 0 });
                } else {
                    setCollectionSelectorPosition({ top, left: 0 });
                }
            }

            if (availableLeftSpace <= 370) {
                if (availableRightSpace > 370) {
                    setCollectionSelectorWidth(360);
                    setCollectionSelectorPosition({ top, left: 0 });
                } else {
                    if (leftSideHasMoreSpace) {
                        setCollectionSelectorWidth(availableLeftSpace - 10);
                        setCollectionSelectorPosition({ top, right: 0 });
                    } else {
                        setCollectionSelectorWidth(availableRightSpace - 10);
                        setCollectionSelectorPosition({ top, left: 0 });
                    }
                }
            }
        }

        if (screenWidth < 1024 && leftContainerRef?.current) {
            const containerHeight = leftContainerRef.current?.clientHeight;
            const maxHeight =
                screenWidth < 640
                    ? containerHeight - buttonYCoord - 14
                    : containerHeight - buttonYCoord - 74;
            setCollectionSelectorMaxHeight(Math.min(350, maxHeight));
        } else {
            setCollectionSelectorMaxHeight(350);
        }
    }
};

export const setSelectorPositionInLibrary = ({
    buttonRef,
    libraryView,
    screenWidth,
    setCollectionSelectorPosition,
    setCollectionSelectorWidth,
}) => {
    if (buttonRef.current) {
        const { y: buttonYCoord, x: buttonXCoord } = buttonRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (libraryView === 'list' && screenWidth >= 768) {
            if (windowHeight - buttonYCoord < 380) {
                setCollectionSelectorPosition({ left: 0, bottom: '30px' });
            } else {
                setCollectionSelectorPosition({ left: 0, top: '30px' });
            }
        }

        if (libraryView === 'grid' || screenWidth < 768) {
            const collectionSelectorWidth =
                screenWidth < 640 && buttonXCoord < 350
                    ? buttonXCoord
                    : screenWidth >= 640 && screenWidth < 1024 && buttonXCoord - 40 < 350
                    ? buttonXCoord - 50
                    : screenWidth >= 1024 && buttonXCoord - 300 < 350
                    ? buttonXCoord - 310
                    : 350;

            setCollectionSelectorWidth(collectionSelectorWidth);

            if (windowHeight - buttonYCoord < 380) {
                setCollectionSelectorPosition({ right: 0, bottom: '30px' });
            } else {
                setCollectionSelectorPosition({ right: 0, top: '30px' });
            }
        }
    }
};
