import React from 'react';

const FlowIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                d="M16.8537 10.2929L18.6162 8.5304L19.8966 7.25007H18.0859H13.7502V6.75008H18.0859H19.8966L18.6162 5.46975L16.8537 3.7072L17.2073 3.35361L20.8537 7.00007L17.2073 10.6465L16.8537 10.2929ZM5.91436 16.7501H10.2502V17.2501H5.91436H4.1037L5.38403 18.5304L7.14659 20.293L6.79304 20.6465L3.1466 17.0001L6.79304 13.3536L7.14659 13.7072L5.38403 15.4697L4.1037 16.7501H5.91436ZM10.7502 7.00007C10.7502 9.07113 9.07123 10.7501 7.00015 10.7501C4.92908 10.7501 3.25015 9.07114 3.25015 7.00007C3.25015 4.929 4.92908 3.25007 7.00015 3.25007C9.07123 3.25007 10.7502 4.92901 10.7502 7.00007ZM13.7502 14.0001C13.7502 13.862 13.8621 13.7501 14.0002 13.7501H20.0002C20.1382 13.7501 20.2502 13.862 20.2502 14.0001V20.0001C20.2502 20.1381 20.1382 20.2501 20.0002 20.2501H14.0002C13.8621 20.2501 13.7502 20.1381 13.7502 20.0001V14.0001Z"
                stroke={props.color}
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default FlowIcon;
