import useSWR from 'swr';
import { SWR_CONFIG } from '../constants/swrConfig';

export const DEFAULT_LIMIT = 12;

export function getKey({ pageIndex, searchParams, route, limit = DEFAULT_LIMIT }) {
    const offset = pageIndex === 0 ? 0 : limit * pageIndex;
    const additionalQuery =
        Object.keys(searchParams)
            .map((key) => `${key}=${searchParams[key]}`)
            .join('&') || '';

    return `${route}?limit=${limit}&offset=${offset}${additionalQuery && `&${additionalQuery}`}`;
}

export default function useCustomPagination({
    pageIndex,
    searchParams = {},
    client,
    route,
    limit = DEFAULT_LIMIT,
    autoFetchNextPage = false,
}) {
    const fetcher = (url) => client.get(url).then((res) => res.data);

    const key = getKey({ pageIndex, searchParams, route, limit });
    const { data: response, error, isLoading, isValidating } = useSWR(key, fetcher, SWR_CONFIG);

    const hasNextPage = Boolean(response?.next);
    const shouldMakeRequestForNextPage = hasNextPage && autoFetchNextPage;
    const nextPageKey = getKey({
        pageIndex: pageIndex + 1,
        searchParams,
        route,
        limit,
    });
    useSWR(shouldMakeRequestForNextPage ? nextPageKey : null, fetcher);

    // If the next page request is being executed, return nextPageKey; otherwise, return null.
    const nextPageKeyResultValue = shouldMakeRequestForNextPage ? nextPageKey : null;

    if (!response || !response.results) {
        return {
            data: null,
            count: 0,
            loading: true,
            key,
            nextPageKey: nextPageKeyResultValue,
            error,
        };
    }
    const data = response.results;
    const total = response.count;
    const count = data.length;

    return {
        data,
        total,
        count,
        loading: false,
        key,
        hasNextPage,
        nextPageKey: nextPageKeyResultValue,
    };
}
