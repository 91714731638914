import React from 'react';
import Modal from '../../../design-system/Modal/Modal';
import PublishingModalHeader from './PublishingModalHeader/PublishingModalHeader';
import UnpublishedBlock from './UnpublishedBlock/UnpublishedBlock';
import PublishingForm from './PublishingForm/PublishingForm';

const ProcessVersionPublishingModal = ({
    versionData,
    setProcessTemplateData,
    setTemplateVersionData,
    setSuccessAlert,
    onClose,
}) => {
    const { is_published, version_name } = versionData;

    const publishingFormProps = {
        setProcessTemplateData,
        setTemplateVersionData,
        setSuccessAlert,
        onClose,
    };

    return (
        <Modal onClose={onClose} size="medium" resetPadding>
            <PublishingModalHeader onClose={onClose} />
            <div className="p-4 xs:p-8 flex flex-col gap-6">
                <p className="font-heading-bold text-heading-bold-m text-neutral-500">
                    Publish Version {version_name}
                </p>
                {!is_published && (
                    <UnpublishedBlock versionData={versionData} {...publishingFormProps} />
                )}
                {is_published && (
                    <>
                        <p className="font-body text-body-regular-m text-neutral-400">
                            This Process Template Version is published!
                        </p>
                        <PublishingForm versionData={versionData} {...publishingFormProps} />
                    </>
                )}
            </div>
        </Modal>
    );
};

export default ProcessVersionPublishingModal;
