import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';
import DeleteProcessConfirmModal from '../../DeleteProcessConfirmModal/DeleteProcessConfirmModal';

const ConfirmDeleteProcessModal = ({ processId, processName, backLinkHref, onClose }) => {
    const navigate = useNavigate();

    const {
        requestKeysToMutate: { processes: keysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const onProcessDelete = () => {
        navigate(backLinkHref, { replace: true });
    };

    return (
        <DeleteProcessConfirmModal
            processId={processId}
            processName={processName}
            keysToMutate={keysToMutate}
            onProcessDelete={onProcessDelete}
            onClose={onClose}
        />
    );
};

export default ConfirmDeleteProcessModal;
