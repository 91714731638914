import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Badge, Button, ButtonIcon } from '../../../../design-system';
import { More2FillIcon } from '../../../../design-system/Icons';
import GoalMoreOptionsPopup from '../GoalMoreOptionsPopup/GoalMoreOptionsPopup';

const GoalCard = ({
    goal,
    processDetail,
    setProcessDetail,
    isEditable,
    shouldPopupBePositionedUp,
}) => {
    const { id, name, description, is_first_goal: isFirstGoal } = goal;

    const navigate = useNavigate();
    const location = useLocation();
    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);

    const goalDetailPageRoute = `/configure/process/${processDetail.id}/goal/${id}`;

    const handleGoalView = () => {
        navigate(goalDetailPageRoute, {
            state: { processName: processDetail.name, ...(location.state || {}) },
        });
    };

    return (
        <Link
            to={goalDetailPageRoute}
            state={{ processName: processDetail.name, ...(location.state || {}) }}
        >
            <div className="w-full p-4 rounded-2 bg-purple-100 relative">
                <div className="w-full flex items-center justify-between gap-2 mb-2">
                    <div className="flex items-start gap-1 xs:gap-2 truncate">
                        <p className="font-body-bold text-body-bold-m text-neutral-500 truncate">
                            {name}
                        </p>
                        {isFirstGoal && <Badge text="First Goal" color="neutral" />}
                    </div>
                    {isEditable && (
                        <div className="flex items-center gap-2 sm:relative">
                            <ButtonIcon
                                type="link"
                                size="xs"
                                icon={More2FillIcon}
                                onClick={(e) => {
                                    e?.stopPropagation();
                                    e?.preventDefault();
                                    setIsMoreOptionsPopupOpened(true);
                                }}
                            />
                            {isMoreOptionsPopupOpened && (
                                <GoalMoreOptionsPopup
                                    goal={goal}
                                    processDetail={processDetail}
                                    setProcessDetail={setProcessDetail}
                                    isPositionedUp={shouldPopupBePositionedUp}
                                    onClose={() => {
                                        setIsMoreOptionsPopupOpened(false);
                                    }}
                                />
                            )}
                        </div>
                    )}
                    {!isEditable && (
                        <div>
                            <Button type="primary" size="xs" text="View" onClick={handleGoalView} />
                        </div>
                    )}
                </div>
                <p className="font-body text-body-regular-xs text-neutral-500 line-clamp-2">
                    {description}
                </p>
            </div>
        </Link>
    );
};

export default GoalCard;
