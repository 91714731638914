import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/template-api';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../constants/errorMessages';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useFormState } from '../../hooks/useFormState';
import { formatStringToSnakeCase } from '../../helpers/formatStringToSnakeCase';

import CreationPageContainer from '../../design-system/CreationPageContainer/CreationPageContainer';
import FormFieldWrapper from '../../design-system/FormFieldWrapper/FormFieldWrapper';
import LabelsBlock from '../../components/LabelsBlock/LabelsBlock';
import LayersIcon from '../../design-system/Icons/LayersIcon';
import { Button, Input, TextArea } from '../../design-system';

const CreateNewProcessTemplatePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.templatesLibraryLocation || '/templates/processes';

    const { formData, setFormData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } =
        useFormState({
            name: '',
            description: '',
            labels: [],
        });

    const [fileName, setFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    useDocumentTitle('Create Process Template');

    const handleNameChange = (e) => {
        const value = e.target.value;

        handleInputChange(e.target.name, value);
        setFileName(formatStringToSnakeCase(value));
    };

    const setAddedLabels = (cb) => {
        setFormData((prevData) => ({ ...prevData, labels: cb(prevData.labels) }));
    };

    const handleDeleteAddedLabel = (id) => {
        setAddedLabels((prevLabels) => prevLabels.filter((label) => label.id !== id));
    };

    const handleCreateTemplate = async () => {
        const areEmptyFields = !formData.name || !formData.description;
        if (areEmptyFields) {
            setFieldErrorMessages({
                name: !formData.name ? emptyFieldErrorMessage : null,
                description: !formData.description ? emptyFieldErrorMessage : null,
            });
            return;
        }

        try {
            setIsLoading(true);
            const requestBody = {
                ...formData,
                file_name: fileName,
                labels: formData.labels?.map((label) => label.id) || [],
            };
            const { data } = await client.post(API.ROUTES.template.processTemplate, requestBody);
            navigate(`/templates/process/${data.id}`);
        } catch (error) {
            if (
                error.response?.status === 400 &&
                error.response?.data?.error?.includes(
                    'duplicate key value violates unique constraint'
                )
            ) {
                setErrorAlert({ message: 'Please pick a unique template name and try again.' });
            } else {
                setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
            }
            setIsLoading(false);
        }
    };

    return (
        <CreationPageContainer
            title="Create New Process Template"
            backButtonText="Back to Library"
            handleBackButtonClick={() => navigate(backLinkHref)}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
        >
            <div className="flex flex-col gap-5 mb-8">
                <Input
                    size="md"
                    name="name"
                    value={formData.name}
                    label="Name"
                    isRequired
                    placeholder="Give this process template a helpful name"
                    onChange={handleNameChange}
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                    tipText={`File name: ${fileName}`}
                />
                <TextArea
                    name="description"
                    value={formData.description}
                    label="Description"
                    isRequired
                    state={fieldErrorMessages.description ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.description}
                    placeholder="Describe in a sentence or two what this process does"
                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
                <FormFieldWrapper
                    label="Add labels"
                    state={fieldErrorMessages.labels ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.labels}
                    gap={8}
                >
                    <LabelsBlock
                        addedLabels={formData.labels}
                        setAddedLabels={setAddedLabels}
                        handleDeleteAddedLabel={handleDeleteAddedLabel}
                    />
                </FormFieldWrapper>
            </div>
            <Button
                type="primary"
                size="sm"
                text="Create Template"
                state={isLoading ? 'loading' : 'default'}
                trailingIcon={LayersIcon}
                onClick={handleCreateTemplate}
            />
        </CreationPageContainer>
    );
};

export default CreateNewProcessTemplatePage;
