import { DateTime } from 'luxon';
import { formatPromptVersionName } from './formatPromptVersionName';
import CompassIcon from '../design-system/Icons/CompassIcon';
import OutputRatingControls from '../components/OutputRatingControls/OutputRatingControls';
import { Settings6LineIcon } from '../design-system/Icons';
import temperatureOptions from '../constants/temperatureOptions';
import { Badge } from '../design-system';
import CheckboxCircleLineIcon from '../design-system/Icons/CheckboxCircleLineIcon';
import { DEFAULT_LIMIT } from '../hooks/useCustomPagination';
import { formatOutputValue } from './formatOutputValue';
import { threadStatusBadge } from '../constants/threads';
import { runType, runTypeBadgeColor } from '../constants/task';

export const transformPromptRunDataForTable = (data, page) => {
    const result = {
        columns: [
            {
                header: 'Run At',
                accessor: 'date',
                type: 'text',
                fixedWidth: 214,
            },
            {
                header: 'Run Type',
                accessor: 'runType',
                type: 'badge',
                fixedWidth: 160,
            },
            {
                header: 'Prompt Version',
                accessor: 'promptVersion',
                type: 'badge',
                badgeColor: 'peach',
                badgeIcon: CompassIcon,
                badgeIconColor: '#000000',
                fixedWidth: 165,
            },
            {
                header: 'Inputs',
                accessor: 'inputs',
                type: 'text',
            },
            {
                header: 'Output',
                accessor: 'output',
                type: 'text',
            },
            {
                header: 'Rating',
                accessor: 'rating',
                type: 'any',
                fixedWidth: 100,
            },
        ],
        rows: [],
        pagination: {
            page: page,
            limit: DEFAULT_LIMIT,
            totalItems: data.count,
        },
    };

    data.results.map((item) => {
        const formattedDate = DateTime.fromISO(item.updated_at).toFormat('MM/dd/yyyy, hh:mm a');
        const row = {
            id: item.id,
            date: formattedDate,
            rating: <OutputRatingControls outputRating={item.rating} resultId={item.id} />,
        };

        const inputs = item.inputs.map((input) => input.value).join(', ');
        row.inputs = inputs;

        const output = item.outputs.map((output) => output.value).join(', ');
        row.output = output;

        row.runType = runType[item.origin] || 'other';

        row.badgeColor = runTypeBadgeColor[item.origin] || 'neutral';

        const promptVersion = formatPromptVersionName(item.prompt_version_name);

        row.promptVersion = `Version ${promptVersion}`;

        result.rows.push(row);
    });

    return result;
};

export const transformTaskRunDataForTable = (data, page) => {
    const result = {
        columns: [
            {
                header: 'Run At',
                accessor: 'date',
                type: 'text',
                fixedWidth: 214,
            },
            {
                header: 'Run Type',
                accessor: 'runType',
                type: 'badge',
                fixedWidth: 160,
            },
            {
                header: 'Inputs',
                accessor: 'inputs',
                type: 'text',
            },
            {
                header: 'Output',
                accessor: 'output',
                type: 'text',
            },
            {
                header: 'Run Status',
                accessor: 'runStatus',
                type: 'badge',
                fixedWidth: 150,
            },
            {
                header: 'Rating',
                accessor: 'rating',
                type: 'any',
                fixedWidth: 100,
            },
        ],
        rows: [],
        pagination: {
            page: page,
            limit: DEFAULT_LIMIT,
            totalItems: data.count,
        },
    };

    data.results.map((item) => {
        const formattedDate = DateTime.fromISO(item.updated_at).toFormat('MM/dd/yyyy, hh:mm a');
        const inputs = item.inputs.map((input) => input.value).join(', ');
        const output = item.outputs
            .filter((output) => output.value)
            .map((output) => formatOutputValue(output.value))
            .join(', ');

        const row = {
            id: item.id,
            date: formattedDate,
            inputs,
            output,
            runType: runType[item.origin] || 'other',
            runStatus: threadStatusBadge[item.status]?.text,
            badgesColorsConfigObj: {
                runType: runTypeBadgeColor[item.origin] || 'neutral',
                runStatus: threadStatusBadge[item.status]?.color || 'blue',
            },
            rating: <OutputRatingControls outputRating={item.rating} resultId={item.id} />,
        };

        result.rows.push(row);
    });

    return result;
};

export const transformVersionDataForTable = (data, page) => {
    const result = {
        columns: [
            {
                header: 'Version',
                accessor: 'version',
                type: 'any',
                fixedWidth: 230,
            },
            {
                header: 'Created at',
                accessor: 'date',
                type: 'text',
                fixedWidth: 210,
            },
            {
                header: 'Model',
                accessor: 'model',
                type: 'badge',
                badgeColor: 'purple',
                badgeIcon: Settings6LineIcon,
                badgeIconColor: '#000000',
                fixedWidth: 175,
            },
            {
                header: 'Temperature',
                accessor: 'temperature',
                type: 'text',
                fixedWidth: 205,
            },
            {
                header: 'Token Limit',
                accessor: 'tokenLimit',
                type: 'text',
                fixedWidth: 150,
            },
            {
                header: 'Prompt',
                accessor: 'prompt',
                type: 'text',
            },
        ],
        rows: [],
        pagination: {
            page: page,
            limit: 12,
            totalItems: data.count,
        },
    };

    data.results.map((item) => {
        const createdAtDate = DateTime.fromISO(item.created_at).toFormat('MM/dd/yyyy, hh:mm a');
        const promptVersionName = `Version ${formatPromptVersionName(item.name)}`;
        const version = (
            <div className="flex items-center gap-2.5">
                {promptVersionName}{' '}
                {item.is_default && (
                    <Badge text="current" color="lime" leadingIcon={CheckboxCircleLineIcon} />
                )}
            </div>
        );

        const { settings } = item;

        const tokenLimit = `${settings.max_tokens} tokens`;
        const temperature = temperatureOptions.find(
            (option) => option.value === settings.temperature
        )?.name;

        const row = {
            id: item.id,
            version,
            date: createdAtDate,
            model: settings.model,
            temperature,
            tokenLimit,
            prompt: item.messages[0]?.content,
        };

        result.rows.push(row);
    });

    return result;
};
