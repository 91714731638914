import React from 'react';
import CollapsableContainer from '../../../../components/CollapsableContainer/CollapsableContainer';
import SettingsForm from '../../../CustomizeGoalPage/SettingsForm/SettingsForm';
import Loading from '../../../../components/Loading';

const ProcessSettingsBlock = ({
    isExpanded,
    toggleExpand,
    isBlockOpenable,
    formData,
    setProcessSettingsFormData,
}) => {
    const { settings } = formData.processSettings;

    const setSettingsFormData = (cb) => {
        setProcessSettingsFormData((prevData) => ({
            ...prevData,
            settings: cb(prevData.settings),
        }));
    };

    return (
        <CollapsableContainer
            title="PROCESS SETTINGS"
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
            isDisabled={!isBlockOpenable}
        >
            <>
                {settings && (
                    <>
                        {!!settings.length && (
                            <SettingsForm
                                formData={settings}
                                setFormData={setSettingsFormData}
                                containerGap={16}
                            />
                        )}
                        {!settings.length && (
                            <p className="font-body-bold text-body-bold-s text-neutral-400">
                                No settings found.
                            </p>
                        )}
                    </>
                )}
                {!settings && <Loading withText={false} />}
            </>
        </CollapsableContainer>
    );
};

export default ProcessSettingsBlock;
