import React, { useState } from 'react';

import { ButtonIcon } from '../../../../design-system';
import { More2FillIcon } from '../../../../design-system/Icons';
import ExecutionMoreOptionPopup from '../ExecutionMoreOptionPopup/ExecutionMoreOptionPopup';

const ExecutionMoreOptionButton = ({ setActionModal }) => {
    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);

    return (
        <div className="relative">
            <ButtonIcon
                type="link"
                size="xs"
                icon={More2FillIcon}
                onClick={() => setIsMoreOptionsPopupOpened(true)}
            />

            {isMoreOptionsPopupOpened && (
                <ExecutionMoreOptionPopup
                    setActionModal={setActionModal}
                    onClose={() => {
                        setIsMoreOptionsPopupOpened(false);
                    }}
                />
            )}
        </div>
    );
};

export default ExecutionMoreOptionButton;
