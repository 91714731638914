import React, { useState } from 'react';
import { Button } from '../../../../design-system';
import Modal from '../../../../design-system/Modal/Modal';

const CancelIssueConfirmationModal = ({ onClose, handleStatusChange, issueType }) => {
    const [isLoading, setIsLoading] = useState(false);

    const cancelIssue = async () => {
        setIsLoading(true);

        await handleStatusChange({ status: 'canceled' });

        setIsLoading(false);
        onClose();
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="p-2">
                <h2 className="text-neutral-400 font-heading-bold text-heading-bold-m mb-[40px] text-center">
                    {issueType === 'init'
                        ? 'Confirm Cancelation? This will delete the draft Template from the library.'
                        : 'Confirm Cancelation? This will cancel the Template Version and delete the branch.'}
                </h2>
                <div className="flex gap-8 justify-center">
                    <Button type="link" size="sm" text="No, Leave it" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Yes, Confirm"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={cancelIssue}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default CancelIssueConfirmationModal;
