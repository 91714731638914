import React, { useMemo, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/library-api';
import orgClient from '../../../services/organization-api';

import useUser from '../../../hooks/useUser';
import { useFormState } from '../../../hooks/useFormState';
import { capitalizeFirstLetter } from '../../../services/strings';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import { Button } from '../../../design-system';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const DEFAULT_PLACEHOLDER = 'Share with client organizations...';
const EMPTY_OPTIONS_PLACEHOLDER = 'No client organizations found to share this playbook with';

const SharingModalSharedBlock = ({ playbookDetail, setPlaybookDetail }) => {
    const { user } = useUser();
    const currentOrganizationId = user?.organization?.id;

    const { id: playbookId, is_shared, shared_with, organization } = playbookDetail;

    const { formData, handleInputChange } = useFormState({ selectedOrg: null });

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const {
        options: clientOrgs,
        optionsLoading,
        canLoadMoreOptions,
        totalOptions,
        setPage,
        error,
        isFirstRequestCompleted,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client: orgClient,
        route:
            API.ROUTES.organization.organization + currentOrganizationId + '/child-organization/',
        formatResponseToOptions: (results) => results?.map(({ id, name }) => ({ id, name })),
    });

    const filteredClientOrgs = useMemo(() => {
        const sharedWithIds = shared_with?.map(({ organization: { id } }) => id) || [];
        return clientOrgs.filter((org) => !sharedWithIds.includes(org.id));
    }, [clientOrgs, shared_with]);

    const addClientToSharedWithList = async () => {
        const { selectedOrg } = formData;
        if (!selectedOrg) return;

        try {
            setIsLoading(true);
            const requestBody = {
                playbook: playbookId,
                role: 'member',
                organization: selectedOrg.id,
            };
            const { data } = await client.post(API.ROUTES.library.sharedPlaybook, requestBody);
            const newSharedWithOrg = { organization: selectedOrg, role: data.role };
            setPlaybookDetail((prevData) => ({
                ...prevData,
                shared_with: [...(prevData.shared_with || []), newSharedWithOrg],
            }));

            handleInputChange('selectedOrg', null);
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        } finally {
            setIsLoading(false);
        }
    };

    const currentOrgIsClientOrg = error?.response?.status === 404;
    const noClientOrgsToAdd =
        currentOrgIsClientOrg || (isFirstRequestCompleted && !filteredClientOrgs?.length);

    const isViewOnly = noClientOrgsToAdd || is_shared;

    const dropdownHeight = totalOptions > 5 ? 180 : 148;
    const neededHeight = totalOptions > 5 ? 84 : totalOptions < 3 && !!shared_with?.length ? 0 : 48;
    const increaseComponentHeightBy = Math.max(neededHeight - 28 * (shared_with?.length || 0), 0);

    const placeholder =
        noClientOrgsToAdd && !is_shared ? EMPTY_OPTIONS_PLACEHOLDER : DEFAULT_PLACEHOLDER;

    const ownerOrg = { name: organization?.name, role: 'owner' };

    return (
        <>
            <div className="flex items-start gap-2">
                <PaginatedSelect
                    size="xs"
                    name="client-org"
                    options={filteredClientOrgs}
                    value={formData.selectedOrg?.id}
                    optionsLoading={optionsLoading}
                    placeholder={placeholder}
                    state={isViewOnly ? 'disabled' : 'default'}
                    onChange={(_, org) => handleInputChange('selectedOrg', org)}
                    fetchOptions={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMoreOptions}
                    includeClientSideFiltering
                    totalOptionsCount={totalOptions}
                    dropdownHeight={dropdownHeight}
                    increaseComponentHeightBy={increaseComponentHeightBy}
                />
                <Button
                    type="secondary"
                    size="sm"
                    text="Share"
                    state={isViewOnly ? 'disabled' : isLoading ? 'loading' : 'default'}
                    onClick={addClientToSharedWithList}
                />
            </div>

            <div>
                <p className="font-body text-body-regular-xs text-neutral-300 uppercase mb-3">
                    Shared With
                </p>

                <ul className="flex flex-col gap-2">
                    <SharedWithCard {...ownerOrg} />

                    {shared_with?.map(({ organization: { id, name }, role }) => (
                        <SharedWithCard key={id} name={name} role={role} />
                    ))}
                </ul>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default SharingModalSharedBlock;

function SharedWithCard({ name, role }) {
    const roleLabel = role === 'member' ? 'View Only' : capitalizeFirstLetter(role);

    return (
        <li className="max-w-full flex items-center justify-between gap-3 py-1 border-b-1 border-neutral-200">
            <p className="flex-1 font-body-bold text-body-bold-s text-neutral-500 truncate">
                {name}
            </p>
            <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap">
                {roleLabel}
            </p>
        </li>
    );
}
