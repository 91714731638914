import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { API } from 'constants';
import apiClient from 'services/unauthorized-user-api';

import { Button } from 'design-system';
import Loading from 'components/Loading';

export default function VerifyEmail() {
    const { email } = useParams();
    const [formData, setFormData] = useState({ email: email || '' });
    const [requestState, setRequestState] = useState({
        loading: false,
        success: false,
        error: null,
    });

    const handleSubmit = async () => {
        setRequestState({
            loading: true,
            success: false,
            error: null,
        });

        const data = {
            email: formData.email,
        };

        const response = await apiClient.post(API.ROUTES.user.resendEmail, data);
        setRequestState({
            loading: false,
            success: response.status === 200,
            error: null,
        });
    };

    let content;
    if (requestState.loading) {
        content = <Loading text={`Sending you another verification email to: ${formData.email}`} />;
    } else if (requestState.success) {
        content = (
            <h1 className="font-heading-bold text-heading-bold-m text-center mb-[4px]">
                An email has been sent to your account for verification.
            </h1>
        );
    } else {
        content = (
            <>
                <h1 className="font-heading-bold text-heading-bold-m text-center mb-[4px]">
                    Verify Account
                </h1>
                <p className="font-body text-body-regular-m text-center mb-[32px]">
                    Click the button below to verify your account
                </p>
                <div className="flex justify-center">
                    <Button type="primary" text="Verify Account" size="sm" onClick={handleSubmit} />
                </div>
            </>
        );
    }

    return <div className="VerifyEmail py-[80px]">{content}</div>;
}
