import React, { useState } from 'react';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import FileTextIcon from '../../../design-system/Icons/FileTextIcon';
import AddNewMultiActionButton from '../AddNewMultiActionButton/AddNewMultiActionButton';

const DocsEmptyView = ({ isSearchQuery }) => {
    const [errorAlert, setErrorAlert] = useState(null);

    if (isSearchQuery) {
        return (
            <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[600px]">
                No documents found. Please adjust your search and try again!
            </p>
        );
    }

    return (
        <>
            <div className="py-[80px] mx-auto flex-grow px-4">
                <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full mb-4 mx-auto">
                    <FileTextIcon width={32} height={32} color="#5E6470" />
                </div>
                <div className="flex flex-col items-center gap-1 text-black mb-4">
                    <p className="font-heading-bold text-heading-bold-m text-center max-w-[480px]">
                        You don’t have any Docs or Folders yet.
                    </p>
                    <p className="font-body text-body-regular-m max-w-[400px] text-center">
                        Try creating one by clicking “Add new”
                    </p>
                </div>

                <div className="mx-auto max-w-fit">
                    <AddNewMultiActionButton />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default DocsEmptyView;
