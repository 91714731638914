import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { API } from 'constants';
import apiClient from 'services/unauthorized-user-api';
import { VIEW_TYPE_LS_KEY, VIEW_TYPES } from '../../constants/viewTypes';

import { Button, Form } from 'design-system';
import Loading from 'components/Loading';
import useUser from 'hooks/useUser';
import Input from 'design-system/Input/Input';

const EMAIL_NOT_VERIFIED = 'E-mail is not verified.';
const BAD_CREDENTIALS = 'Unable to log in with provided credentials.';

const expectedErrors = [EMAIL_NOT_VERIFIED, BAD_CREDENTIALS];

export default function Login() {
    const { user, mutateUser } = useUser();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [requestState, setRequestState] = useState({
        attempts: 0,
        loading: false,
        error: null,
        success: false,
        emailNeedsVerification: false,
        badCredentials: false,
    });
    const [authKey, setAuthKey] = useState(null);
    const handleLogin = async (e) => {
        e.preventDefault();

        setRequestState({
            attemps: 1,
            loading: true,
            error: null,
            success: false,
            emailNeedsVerification: false,
            badCredentials: false,
        });
        const data = {
            email: formData.email,
            password: formData.password,
        };
        let response;
        try {
            response = await apiClient.post(API.ROUTES.user.login, data);
        } catch (error) {
            if (error.response.status !== 400) {
                throw error;
            }
            if (
                !error.response.data.non_field_errors ||
                !error.response.data.non_field_errors.length
            ) {
                throw error;
            }
            const errorMessage = error.response.data.non_field_errors[0];
            if (!expectedErrors.includes(errorMessage)) {
                throw error;
            }
            const emailNeedsVerification = errorMessage === EMAIL_NOT_VERIFIED;
            const badCredentials = errorMessage === BAD_CREDENTIALS;

            setRequestState({
                attemps: requestState.attempts + 1,
                loading: false,
                error: error.response.data,
                success: false,
                emailNeedsVerification,
                badCredentials,
            });
            return;
        }
        if (response.status !== 200) {
            setRequestState({
                attemps: requestState.attempts + 1,
                loading: false,
                error: response.data,
                success: false,
                emailNeedsVerification: false,
                badCredentials: false,
            });
        }
        const { key: authKey } = response.data;
        setAuthKey(authKey);
        localStorage.setItem('authKey', authKey);
        const currentViewType = localStorage.getItem(VIEW_TYPE_LS_KEY);
        if (!currentViewType) {
            localStorage.setItem(VIEW_TYPE_LS_KEY, VIEW_TYPES.admin);
        }
        setRequestState({
            ...requestState,
            loading: false,
            error: null,
            success: true,
            emailNeedsVerification: false,
            badCredentials: false,
        });
        mutateUser(null, { revalidate: true }); // invalidate before going to profile
    };

    const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    let formContent;

    if (requestState.loading) {
        formContent = <Loading />;
    } else if (requestState.emailNeedsVerification) {
        formContent = <Navigate to={`/email/verify/${formData.email}`} />;
    } else if (user || requestState.success) {
        formContent = <Navigate to="/library" />;
    } else {
        formContent = (
            <div className="flex flex-col gap-[16px]">
                <Input
                    size="xs"
                    label="Email"
                    value={formData.email}
                    name="email"
                    isRequired={true}
                    type="email"
                    onChange={handleInputChange}
                />
                <Input
                    size="xs"
                    label="Password"
                    value={formData.password}
                    name="password"
                    isRequired={true}
                    type="password"
                    onChange={handleInputChange}
                />
                <div className="mx-auto">
                    <Button type="primary" text="Login" size="sm" isSubmit={true} />
                </div>
                {requestState.badCredentials && (
                    <p className="text-red-500">Invalid email or password.</p>
                )}
            </div>
        );
    }

    return (
        <div className="Login mx-auto py-[80px] w-[300px] flex flex-col gap-[32px]">
            <Form title="Log in to DiviUp" onSubmit={handleLogin}>
                {formContent}
            </Form>
            {!requestState.loading && (
                <div className="flex gap-[16px] justify-center">
                    <Button
                        type="link"
                        size="sm"
                        text="Forgot Password"
                        onClick={() => {
                            navigate('/password-reset');
                        }}
                    />
                    {/* <Button
            type="link"
            size="sm"
            text="Create Account"
            onClick={() => {
              navigate("/register");
            }}
          /> */}
                </div>
            )}
        </div>
    );
}
