import React from 'react';

import { API } from 'constants';
import client from '../../../../services/assistant-api';

import { setSyncTimeout } from '../../../../helpers/setSyncTimeout';
import { triggerPlaybookSave } from '../../../../helpers/playbookBuilderUtils';

import { ButtonIcon, SvgIcon } from '../../../index';
import { DeleteBin4LineIcon, TerminalBoxLineIcon } from '../../../Icons';
import PromptForm from '../PromptForm/PromptForm';

const NotCreatedPromptBlock = ({ isDisabled, handleDeleteBlock, handleBlockPropertyChange }) => {
    const handleCreatePrompt = async (requestBody) => {
        const { data } = await client.post(API.ROUTES.assistant.chatPrompt, requestBody);

        handleBlockPropertyChange('chat_prompt_id', data.id);
        setSyncTimeout(triggerPlaybookSave, 0);
    };

    return (
        <div className="flex flex-col max-w-full rounded-2 border-1 border-neutral-200 overflow-hidden">
            <div className="px-5 py-2.5 flex items-center justify-between gap-3 bg-purple-100">
                <div className="flex items-center gap-1">
                    <SvgIcon color="#754DCF" icon={TerminalBoxLineIcon} size="large" />
                    <p className="font-body-bold text-body-bold-s text-neutral-400 uppercase">
                        Prompt
                    </p>
                </div>

                {!isDisabled && (
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={DeleteBin4LineIcon}
                        onClick={handleDeleteBlock}
                    />
                )}
            </div>

            <PromptForm
                mode="create"
                onCancel={handleDeleteBlock}
                onSubmit={handleCreatePrompt}
                isDisabled={isDisabled}
            />
        </div>
    );
};

export default NotCreatedPromptBlock;
