import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import useUser from '../../../hooks/useUser';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { TASK_TYPES } from '../../../constants/library';
import { USER_ROLE } from '../../../constants/organization';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { getTaskTypeBadge } from '../../../helpers/getTaskTypeBadge';

import TaskPanel from 'pages/LibraryPage/TaskPanel/TaskPanel';
import TaskCardMoreOptions from '../TaskCardMoreOptions/TaskCardMoreOptions';
import DuplicateTaskSuccessModal from '../../../components/DuplicateTaskSuccessModal/DuplicateTaskSuccessModal';
import Alert from '../../../design-system/Alert/Alert';
import DeleteTaskConfirmModal from '../../../components/DeleteTaskConfirmModal/DeleteTaskConfirmModal';
import ShareTaskModal from '../ShareTaskModal/ShareTaskModal';
import ListViewCard from '../../../components/ListViewCard/ListViewCard';
import CardCollectionBlock from '../CardCollectionBlock/CardCollectionBlock';
import { Card } from 'design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

export default function TaskCard(props) {
    const {
        task,
        swrKey,
        libraryView,
        collections,
        setCollections,
        navigateToTaskPageByCardClick = false,
    } = props;
    const { width: screenWidth } = useWindowSize();
    const navigate = useNavigate();
    const location = useLocation();

    const { userRole } = useUser();
    const viewOnlyMode = userRole === USER_ROLE.member;

    const taskType = task.task_type;
    const [isPromptPanelShown, setIsPromptPanelShown] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState({
        share: false,
        delete: false,
    });
    const [duplicateSuccessModal, setDuplicateSuccessModal] = useState({ opened: false });
    const [showMoreOptionsOpened, setShowMoreOptionsOpened] = useState(false);
    const [isCollectionSelectorOpened, setIsCollectionSelectorOpened] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        status: null,
        message: null,
        icon: null,
        statusCode: null,
    });

    const formattedTime = DateTime.fromISO(task.last_run_at).toRelative();

    const model = task.default_prompt?.default_prompt_version?.settings?.model;
    const cardBadge = getTaskTypeBadge({ taskType: taskType, model });

    const openModal = (mode) => {
        setShowMoreOptionsOpened(false);
        setIsModalOpened((prevState) => ({ ...prevState, [mode]: true }));
    };

    const closeModal = () => {
        setIsModalOpened({ share: false, duplicate: false, delete: false });
    };

    const openPanel = (e) => {
        if (!isCollectionSelectorOpened && !showMoreOptionsOpened) {
            e.stopPropagation();
            setIsPromptPanelShown(true);
        }
    };

    const handleViewNav = () => {
        if (taskType === TASK_TYPES.prompt) {
            navigate(`/library/prompt/${task.default_prompt?.id}`, {
                state: { libraryLocation: location },
            });
        } else {
            navigate(`/library/task/${task.id}`, {
                state: { libraryLocation: location },
            });
        }
    };

    const handleNavByCardClick = (e) => {
        if (!isCollectionSelectorOpened && !showMoreOptionsOpened) {
            e.stopPropagation();
            handleViewNav();
        }
    };

    const collectionsBlock = (
        <CardCollectionBlock
            swrKey={swrKey}
            taskId={task.id}
            collections={task.collections}
            allCollections={collections}
            isCollectionSelectorOpened={isCollectionSelectorOpened}
            setIsCollectionSelectorOpened={setIsCollectionSelectorOpened}
            setCollections={setCollections}
            setAlert={setAlert}
            libraryView={libraryView}
        />
    );

    const showMoreOptionsList = (
        <TaskCardMoreOptions
            task={task}
            swrKey={swrKey}
            setAlert={setAlert}
            openActionModal={openModal}
            setDuplicateSuccessModal={setDuplicateSuccessModal}
            setShowMoreOptionsOpened={setShowMoreOptionsOpened}
        />
    );

    return (
        <>
            <li key={task.id} className="cursor-pointer">
                {(libraryView === 'grid' || screenWidth < 768) && (
                    <Card
                        title={task.name}
                        description={task.description}
                        iconText={task.icon_text}
                        iconColor={task.icon_color}
                        openOnClick={handleViewNav}
                        metaTagLabel="Last Run:"
                        metaTagText={formattedTime || 'No runs yet'}
                        showFullScreenButton={!navigateToTaskPageByCardClick}
                        openPanelOnClick={
                            navigateToTaskPageByCardClick ? handleNavByCardClick : openPanel
                        }
                        withShowMoreButton={!viewOnlyMode}
                        showMoreOptionsList={showMoreOptionsList}
                        onShowMoreButtonClick={() => setShowMoreOptionsOpened(true)}
                        showMoreOpened={showMoreOptionsOpened}
                        bottomRightContent={collectionsBlock}
                    />
                )}
                {libraryView === 'list' && screenWidth >= 768 && (
                    <ListViewCard
                        title={task.name}
                        description={task.description}
                        iconText={task.icon_text}
                        iconColor={task.icon_color}
                        showFullScreenButton={!navigateToTaskPageByCardClick}
                        onFullScreenButtonClick={handleViewNav}
                        metaTagLabel="Last Run:"
                        metaTagText={formattedTime || 'No runs yet'}
                        openPanelOnClick={
                            navigateToTaskPageByCardClick ? handleNavByCardClick : openPanel
                        }
                        withShowMoreButton={!viewOnlyMode}
                        showMoreOptionsList={showMoreOptionsList}
                        onShowMoreButtonClick={() => setShowMoreOptionsOpened(true)}
                        showMoreOpened={showMoreOptionsOpened}
                        badge={cardBadge}
                        bottomContent={collectionsBlock}
                    />
                )}
            </li>
            {isPromptPanelShown && (
                <TaskPanel
                    data={task}
                    id={task.id}
                    handlePanelClose={() => {
                        setIsPromptPanelShown(false);
                    }}
                />
            )}
            {duplicateSuccessModal.opened && (
                <DuplicateTaskSuccessModal
                    handleClose={() => setDuplicateSuccessModal({ opened: false })}
                    id={duplicateSuccessModal.id}
                    taskType={duplicateSuccessModal.taskType}
                    taskName={duplicateSuccessModal.taskName}
                />
            )}
            {isModalOpened.delete && (
                <DeleteTaskConfirmModal
                    id={task.id}
                    swrKey={swrKey}
                    onClose={closeModal}
                    navigateToLibrary={false}
                    taskType={taskType}
                />
            )}
            {isModalOpened.share && <ShareTaskModal onClose={closeModal} />}
            {alert.show && (
                <Alert
                    status={alert.status || 'critical'}
                    message={alert.message || defaultErrorMessage}
                    statusCode={alert.statusCode}
                    icon={alert.icon || ErrorWarningLineIcon}
                    handleClose={() => setAlert({ show: false, statusCode: null })}
                />
            )}
        </>
    );
}
