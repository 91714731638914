import React from 'react';

import { SHARING_STATE } from '../../../constants/settingsForm';

import SettingFormField from './SettingFormField/SettingFormField';

const SettingsForm = ({
    formData,
    setFormData,
    containerGap = 28,
    shouldShowHiddenSettings = true,
    isFormDisabled = false,
    sharingState = SHARING_STATE.unavailable, // "enabled" | "disabled" | "unavailable"
}) => {
    // if sharingState is "unavailable" - sharing status buttons are hidden
    return (
        <>
            {!!formData?.length && (
                <div className="flex flex-col" style={{ gap: `${containerGap}px` }}>
                    {formData.map((setting) => (
                        <SettingFormField
                            key={setting.name}
                            setting={setting}
                            setFormData={setFormData}
                            isFormDisabled={isFormDisabled}
                            sharingState={sharingState}
                            shouldShowHiddenSettings={shouldShowHiddenSettings}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default SettingsForm;
