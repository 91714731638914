import React from 'react';

import ErrorMessage from '../../../design-system/ErrorMessage/ErrorMessage';
import ChatInputExpandedMode from '../ChatInputExpandedMode/ChatInputExpandedMode';

const ChatInputWrapper = ({
    children,
    message,
    handleMessageChange,
    placeholder,
    chatInputErrorMessage,
    isExpandedMode,
    setIsExpandedMode,
    bottomButtonsGroup,
    isMobile,
    closeExpandedModeOnOutsideClick,
}) => {
    return (
        <div className="w-full max-w-[1010px] px-4 xs:px-5 pb-5 mx-auto">
            <div className="flex flex-col">
                {chatInputErrorMessage && (
                    <ErrorMessage message={chatInputErrorMessage} customStyles="mb-[5px]" />
                )}

                {children}
            </div>

            {isExpandedMode && isMobile && (
                <ChatInputExpandedMode
                    message={message}
                    handleMessageChange={handleMessageChange}
                    placeholder={placeholder}
                    bottomButtonsGroup={bottomButtonsGroup}
                    closeExpandedModeOnOutsideClick={closeExpandedModeOnOutsideClick}
                    onClose={() => setIsExpandedMode(false)}
                />
            )}
        </div>
    );
};

export default ChatInputWrapper;
