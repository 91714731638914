import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { VIEW_TYPES } from '../../../constants/viewTypes';
import { useViewType } from '../../../hooks/useViewType';

import PlusCircleIcon from '../../../design-system/Icons/PlusCircleIcon';
import EmptyView from '../../../design-system/EmptyView/EmptyView';
import ArchiveLineIcon from '../../../design-system/Icons/ArchiveLineIcon';

const BasesEmptyView = ({ isSearchQuery }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { viewType } = useViewType();

    if (isSearchQuery) {
        return (
            <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[600px]">
                No bases found. Please adjust your search and try again!
            </p>
        );
    }

    const subtext = {
        [VIEW_TYPES.admin]: 'Try creating one by clicking “New Base”.',
        [VIEW_TYPES.client]: 'Contact your account manager to set up your Knowledge Bases.',
    }[viewType];

    return (
        <EmptyView
            icon={ArchiveLineIcon}
            mainText="You don't have any Knowledge Bases yet."
            subtext={subtext}
            withButton={viewType === VIEW_TYPES.admin}
            buttonText="New Base"
            buttonState="disabled"
            buttonLeadingIcon={PlusCircleIcon}
            handleButtonClick={() => navigate('/base/new', { state: { from: location } })}
            mainTextAdditionalStyles="max-w-[490px]"
        />
    );
};

export default BasesEmptyView;
