import { API } from 'constants';

export const versionStatusLabel = {
    open: 'Open',
    merged: 'Merged',
    canceled: 'Canceled',
    deleted: 'Deleted',
    ready_for_review: 'Ready for Review',
    changes_requested: 'Changes Requested',
};

export const versionStatusBadgeColor = {
    open: 'peach',
    merged: 'lime',
    canceled: 'neutral',
    deleted: 'red',
    ready_for_review: 'blue',
    changes_requested: 'purple',
};

export const issueTypes = [
    {
        id: 'init',
        name: 'Initial File [major]',
        description: 'Sets up the initial template file and configuration',
    },
    {
        id: 'feature',
        name: 'Feature [major]',
        description: 'Changes like adding features or adding abstraction',
    },
    {
        id: 'integration',
        name: 'Integration [major]',
        description: 'Changes to inputs, outputs or settings which are breaking changes',
    },
    {
        id: 'improvement',
        name: 'Improvement [minor]',
        description: 'Changes to quality, number or type of steps',
    },
    {
        id: 'hotfix',
        name: 'Hot Fix [patch]',
        description: 'Small changes made to prompts, configs, etc',
    },
    {
        id: 'bugfix',
        name: 'Bug Fix [patch]',
        description: 'Small fixes that are not breaking',
    },
];

export const TEMPLATES_LIBRARY_TABS = {
    goals: 'goals',
    processes: 'processes',
};

export const TEMPLATE_TYPES = {
    goal: 'goal',
    process: 'process',
};

export const successStatusChangeMessages = {
    ready_for_review: 'This issue has been successfully submitted',
    canceled: 'This issue has been successfully canceled',
};

export const templateAPIRoutes = {
    [TEMPLATE_TYPES.goal]: API.ROUTES.template.goalTemplate,
    [TEMPLATE_TYPES.process]: API.ROUTES.template.processTemplate,
};

export const versionAPIRoutes = {
    [TEMPLATE_TYPES.goal]: API.ROUTES.template.goalTemplateVersion,
    [TEMPLATE_TYPES.process]: API.ROUTES.template.processTemplateVersion,
};

export const defaultBackLinkHref = {
    [TEMPLATE_TYPES.goal]: '/templates/goals',
    [TEMPLATE_TYPES.process]: '/templates/processes',
};
