import React, { useMemo, useRef, useState } from 'react';

import client from '../../../../services/template-api';
import { templateAPIRoutes } from '../../../../constants/template';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import InfiniteScrollList from '../../../../components/InfiniteScrollList/InfiniteScrollList';
import GoalTemplateSidePanelCard from '../GoalTemplateSidePanelCard/GoalTemplateSidePanelCard';
import GoalTemplateSidePanelCardMoreOptionsPopup from '../GoalTemplateSidePanelCardMoreOptionsPopup/GoalTemplateSidePanelCardMoreOptionsPopup';
import Alert from '../../../../design-system/Alert/Alert';
import SearchBar from '../../../../design-system/SearchBar/SearchBar';

const GoalTemplatesSidePanel = ({ processTemplateVersionGoals }) => {
    const panelRef = useRef(null);
    const [moreOptionPopup, setMoreOptionPopup] = useState({
        isOpened: false,
        goalTemplateData: null,
        topPosition: {},
    });
    const [copyAlert, setCopyAlert] = useState(null);

    const [searchQuery, setSearchQuery] = useState('');
    const [searchBarInputValue, setSearchBarInputValue] = useState('');

    const requestSearchParams = useMemo(
        () => (searchQuery ? { search: searchQuery, is_live: true } : { is_live: true }),
        [searchQuery]
    );

    const {
        options: goalTemplates,
        optionsLoading: areGoalTemplatesLoading,
        canLoadMoreOptions: canLoadMore,
        setPage,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: templateAPIRoutes.goal,
        searchParams: requestSearchParams,
        formatResponseToOptions: (results) => results || [],
    });

    const handleSearchbarClear = () => {
        setSearchBarInputValue('');
        setSearchQuery('');
    };

    return (
        <div className="relative h-full" ref={panelRef}>
            <div className="w-[320px] min-w-[320px] bg-white px-4 py-6 border-r-1 border-neutral-200 h-full overflow-y-auto hide-scrollbar">
                <p className="font-heading-bold text-heading-bold-m text-black pb-4 border-b-1 border-neutral-200">
                    Goal Templates
                </p>
                <div className="py-4">
                    <div className="mb-4 pt-2">
                        <SearchBar
                            size="xs"
                            value={searchBarInputValue}
                            onChange={(e) => setSearchBarInputValue(e.target.value)}
                            onSubmit={() => setSearchQuery(searchBarInputValue)}
                            withClearIcon
                            onClear={handleSearchbarClear}
                        />
                    </div>
                    <p className="font-body text-body-bold-xs text-neutral-300 uppercase mb-2">
                        All goal templates
                    </p>
                    <InfiniteScrollList
                        handleFetch={() => setPage((page) => page + 1)}
                        canLoadMore={canLoadMore}
                        items={goalTemplates}
                        loading={areGoalTemplatesLoading}
                    >
                        {goalTemplates?.map((goalTemplate) => {
                            const isUsedInProcessTemplate = !!processTemplateVersionGoals?.find(
                                (goal) => goal.template_id === goalTemplate.id
                            );
                            return (
                                <GoalTemplateSidePanelCard
                                    key={goalTemplate.id}
                                    goalTemplate={goalTemplate}
                                    isUsedInProcessTemplate={isUsedInProcessTemplate}
                                    panelRef={panelRef}
                                    setMoreOptionPopup={setMoreOptionPopup}
                                />
                            );
                        })}
                    </InfiniteScrollList>

                    {!goalTemplates?.length && !areGoalTemplatesLoading && searchQuery && (
                        <p className="font-body-bold text-body-bold-s text-[#25324B] px-3 py-2 text-center">
                            No matching results found
                        </p>
                    )}

                    {!goalTemplates?.length && !areGoalTemplatesLoading && !searchQuery && (
                        <p className="font-body-bold text-body-bold-s text-[#25324B] px-3 py-2 text-center">
                            No goal templates found
                        </p>
                    )}
                </div>
            </div>
            {moreOptionPopup.isOpened && (
                <GoalTemplateSidePanelCardMoreOptionsPopup
                    goalTemplateData={moreOptionPopup.goalTemplateData}
                    popupTopPosition={moreOptionPopup.topPosition}
                    setCopyAlert={setCopyAlert}
                    onClose={() => setMoreOptionPopup({ isOpened: false })}
                />
            )}
            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </div>
    );
};

export default GoalTemplatesSidePanel;
