import React, { useState } from 'react';
import { SIMPLIFIED_TASK_TYPES } from '../../../constants/library';
import { DateTime } from 'luxon';
import { capitalizeFirstLetter } from '../../../services/strings';
import { useParams } from 'react-router-dom';
import { Button } from '../../../design-system';
import { runType } from '../../../constants/task';
import { threadStatusBadge } from '../../../constants/threads';
import AdditionalResultDataModal from '../../LibraryPage/TaskPlaygroundPage/AdditionalResultDataModal/AdditionalResultDataModal';

const ViewHistoricalRunHeader = ({ resultData, taskData, simplifiedTaskType }) => {
    const { resultId } = useParams();
    const [additionalResultDataModalOpened, setAdditionalResultDataModalOpened] = useState(false);

    const formattedDate =
        resultData && DateTime.fromISO(resultData?.updated_at).toFormat('MM/dd/yyyy, hh:mm a');

    return (
        <>
            {simplifiedTaskType === SIMPLIFIED_TASK_TYPES.prompt && (
                <div className="flex flex-col gap-1">
                    <p className="font-body text-body-regular-xl text-black">
                        <span className="text-body-bold-xl">Prompt Run: </span>
                        {taskData?.task?.name} at {formattedDate}
                    </p>
                    <p className="font-body text-body-regular-xs text-neutral-300 py-1 flex gap-1.5">
                        ID
                        <span className="break-words">{resultId}</span>
                    </p>
                </div>
            )}

            {simplifiedTaskType === SIMPLIFIED_TASK_TYPES.nonPrompt && (
                <div>
                    <div className="flex gap-y-4 gap-x-2 min-[500px]:items-center justify-between flex-col min-[500px]:flex-row">
                        <p className="font-body text-body-regular-xl text-black flex-grow">
                            <span className="text-body-bold-xl">
                                {capitalizeFirstLetter(taskData?.task_type)} Task Run:
                            </span>
                            {taskData?.name} at {formattedDate}
                        </p>
                        <div className="whitespace-nowrap">
                            <Button
                                type="link"
                                size="xs"
                                text="View Data"
                                onClick={() => setAdditionalResultDataModalOpened(true)}
                            />
                        </div>
                    </div>
                    <div className="py-3 flex flex-wrap gap-x-[40px] gap-y-3">
                        <p className="font-body text-body-regular-xs text-neutral-300">
                            Type{' '}
                            <span className="text-body-bold-xs text-neutral-500">
                                {runType[resultData.origin]}
                            </span>
                        </p>
                        <p className="font-body text-body-regular-xs text-neutral-300">
                            Status{' '}
                            <span className="text-body-bold-xs text-neutral-500">
                                {threadStatusBadge[resultData.status]?.text}
                            </span>
                        </p>
                        <p className="font-body text-body-regular-xs text-neutral-300">
                            Result ID{' '}
                            <span className="text-body-bold-xs text-neutral-500">{resultId}</span>
                        </p>
                    </div>
                </div>
            )}
            {additionalResultDataModalOpened && (
                <AdditionalResultDataModal
                    resultId={resultId}
                    data={resultData.data}
                    onClose={() => setAdditionalResultDataModalOpened(false)}
                />
            )}
        </>
    );
};

export default ViewHistoricalRunHeader;
