import React from 'react';

const TextIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M8.66927 4.00033V14.0003H7.33594V4.00033H3.33594V2.66699H12.6693V4.00033H8.66927Z"
                fill={props.color}
            />
        </svg>
    );
};

export default TextIcon;
