export const updateContextState = (contextList, promptText) => {
    return contextList && contextList.length
        ? contextList.map((context) => {
              const result = {
                  id: context.id,
                  key: context.key,
                  label: context.label,
                  value: context.value,
                  state: 'disabled',
                  isRequired: true,
              };
              if (promptText !== undefined && promptText !== null) {
                  result.isMissedInPrompt = !promptText?.includes(`[[${context.key}]]`);
              }
              return result;
          })
        : [];
};
