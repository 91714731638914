import React, { useState } from 'react';
import Modal from '../../../design-system/Modal/Modal';
import { Button } from '../../../design-system';
import Alert from '../../../design-system/Alert/Alert';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const MessageCancelationConfirmModal = ({
    onClose,
    onCancelMessage,
    messageSubject,
    onMultiActionOptionsPanelClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false });

    const handleReassign = async () => {
        try {
            setIsLoading(true);

            await onCancelMessage();

            setIsLoading(false);
            onMultiActionOptionsPanelClose();
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({ show: true, statusCode: error.response.status });
        }
    };
    return (
        <Modal onClose={onClose} size="extraSmall">
            <p className="font-heading-bold text-heading-bold-m text-black py-2 mb-4">
                Confirm cancelation
            </p>
            <p className="font-body text-body-regular-m text-neutral-500 mb-8">
                Are you sure you want to cancel{' '}
                <span className="text-body-bold-m">{messageSubject}</span> from your inbox? This
                will cause the task to fail.
            </p>
            <div className="flex items-center gap-2 justify-between">
                <Button type="ghost" size="sm" text="No, Exit" onClick={onClose} />
                <Button
                    type="primary"
                    size="sm"
                    text="Yes, Cancel"
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleReassign}
                />
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </Modal>
    );
};

export default MessageCancelationConfirmModal;
