import React, { memo } from 'react';
import classNames from 'classnames';

import Switcher from '../../../../design-system/Switcher/Switcher';
import ViewSwitcher from '../ViewSwitcher/ViewSwitcher';
import MilestonesBoard from '../MilestonesBoard/MilestonesBoard';

const MilestonesSection = ({ milestones, view, setView, tabLabel }) => {
    const containerClassName = classNames(
        'pt-2.5 px-4 xs:px-5 sm:px-8 pb-4 lg:pb-7 lg:px-[40px] xl:px-[60px] flex flex-col flex-1 bg-neutral-50/70',
        view === 'column' && 'overflow-hidden'
    );

    return (
        <div className={containerClassName}>
            <div className="relative flex flex-col h-full max-h-full flex-grow">
                <div className="px-3 sm:px-4 border-b-1 border-neutral-200 mb-5 flex flex-col">
                    <div className="flex items-center justify-between">
                        <Switcher
                            tabs={[{ name: tabLabel }]}
                            tabIndex={0}
                            onTabChanged={() => {}}
                        />
                        <ViewSwitcher view={view} setView={setView} />
                    </div>
                </div>

                <MilestonesBoard milestones={milestones || []} view={view} entityName={tabLabel} />
            </div>
        </div>
    );
};

export default memo(MilestonesSection);
