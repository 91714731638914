import React from 'react';

const LinkIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M12.2403 10.357L11.2974 9.41423L12.2403 8.47143C13.542 7.1697 13.542 5.05914 12.2403 3.7574C10.9385 2.45565 8.82793 2.45565 7.52619 3.7574L6.58338 4.70021L5.64057 3.7574L6.58338 2.81459C8.40586 0.992142 11.3606 0.992142 13.1831 2.81459C15.0055 4.63704 15.0055 7.59183 13.1831 9.41423L12.2403 10.357ZM10.3546 12.2427L9.41179 13.1855C7.5894 15.008 4.63459 15.008 2.81215 13.1855C0.989701 11.363 0.989701 8.4083 2.81215 6.58582L3.75495 5.64302L4.69776 6.58582L3.75495 7.52863C2.45321 8.83036 2.45321 10.941 3.75495 12.2427C5.0567 13.5444 7.16726 13.5444 8.469 12.2427L9.41179 11.2999L10.3546 12.2427ZM9.88319 5.17161L10.826 6.11442L6.11197 10.8285L5.16917 9.88563L9.88319 5.17161Z"
                fill={props.color}
            />
        </svg>
    );
};

export default LinkIcon;
