import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';

const CustomizeGoalPageHeader = ({ processData, goalTemplateName }) => {
    const navigate = useNavigate();

    return (
        <div className="w-full px-6 py-3 bg-white border-b-1 border-neutral-200">
            <div className="flex flex-col gap-x-3 items-start xs:items-center xs:flex-row mb-1 xs:mb-0">
                <Button
                    type="link"
                    size="xs"
                    text="Back to Process"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(`/configure/process/${processData.id}`)}
                />
                <p className="font-body text-body-regular-s text-neutral-300">
                    Configure / {processData.name} / Add Goal
                </p>
            </div>
            <p className="font-body-bold text-body-bold-l text-black">{goalTemplateName}</p>
        </div>
    );
};

export default CustomizeGoalPageHeader;
