import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { BLOCK_TYPE } from '../../../constants/playbookBuilder';

import EditVideoUrlBlock from './EditVideoUrlBlock/EditVideoUrlBlock';
import EditLineIcon from '../../Icons/EditLineIcon';
import { ButtonIcon } from '../../index';
import { DeleteBin4LineIcon } from '../../Icons';
import Loading from '../../../components/Loading';

VideoBlock.propTypes = {
    blockData: PropTypes.shape({
        order: PropTypes.number,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        temporaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        block_type: PropTypes.oneOf([BLOCK_TYPE.video]),
        properties: PropTypes.shape({
            video_url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        }).isRequired,
    }),
    editMode: PropTypes.bool,
    handleDeleteBlock: PropTypes.func.isRequired,
    handleBlockPropertyChange: PropTypes.func.isRequired,
};

function VideoBlock({ blockData, editMode = false, handleDeleteBlock, handleBlockPropertyChange }) {
    const {
        properties: { video_url },
    } = blockData;

    const [isEditingVideoUrl, setIsEditingVideoUrl] = useState(!video_url);
    const [inputtedVideoUrlErrorMessage, setInputtedVideoUrlErrorMessage] = useState(null);

    const setVideoUrlInBlockData = (value) => {
        handleBlockPropertyChange('video_url', value);
    };

    const handleSaveVideoUrl = (url) => {
        let validUrl = url;

        const isLoomVideo = url?.includes('www.loom.com/');
        if (isLoomVideo) {
            validUrl = url.replace('share', 'embed');
        }

        setVideoUrlInBlockData(validUrl);
        setIsEditingVideoUrl(false);
    };

    const handleEditVideoUrl = () => {
        setIsEditingVideoUrl(true);
    };

    const handleVideoPlaybackError = () => {
        setIsEditingVideoUrl(true);
        setInputtedVideoUrlErrorMessage(
            'This URL does not seem to be a valid video link. Please enter a correct video URL.'
        );
    };

    const isLoomVideo = video_url?.includes('www.loom.com/');

    return (
        <>
            {isEditingVideoUrl && (
                <EditVideoUrlBlock
                    videoUrl={video_url}
                    handleDeleteBlock={handleDeleteBlock}
                    handleSaveVideoUrl={handleSaveVideoUrl}
                    inputErrorMessage={inputtedVideoUrlErrorMessage}
                    isEditable={editMode}
                    setInputErrorMessage={setInputtedVideoUrlErrorMessage}
                />
            )}

            {!isEditingVideoUrl && video_url && (
                <div className="relative w-full pt-[56%]">
                    {!isLoomVideo && (
                        <ReactPlayer
                            url={video_url}
                            controls
                            width="100%"
                            height="100%"
                            style={{ position: 'absolute', top: 0, left: 0, zIndex: 10 }}
                            onError={handleVideoPlaybackError}
                        />
                    )}

                    {isLoomVideo && (
                        <iframe
                            src={video_url}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 10,
                            }}
                        />
                    )}

                    <div className="absolute top-0 left-0 z-[1] w-full h-full flex items-center justify-center">
                        <Loading withText={false} />
                    </div>

                    {editMode && (
                        <div className="absolute top-2 right-2 flex items-center gap-2 z-20">
                            <ButtonIcon
                                type="neutral"
                                size="xs"
                                icon={EditLineIcon}
                                onClick={handleEditVideoUrl}
                            />

                            <ButtonIcon
                                type="neutral"
                                size="xs"
                                icon={DeleteBin4LineIcon}
                                onClick={handleDeleteBlock}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default memo(VideoBlock);
