import { useState } from 'react';
import { addUniqueElementToArray } from '../helpers/addUniqueElementToArray';

export const useFormState = (initialState, shouldTrackChangedFields = false) => {
    const [formData, setFormData] = useState(initialState);
    const [fieldErrorMessages, setFieldErrorMessages] = useState({});
    const [changedFields, setChangedFields] = useState([]);

    const updateFieldErrorMessagesState = (field) => {
        if (fieldErrorMessages[field]) {
            setFieldErrorMessages((prevMessages) => ({ ...prevMessages, [field]: null }));
        }
    };

    const trackChangedFields = (field) => {
        if (shouldTrackChangedFields) {
            setChangedFields((prevFields) => addUniqueElementToArray(field, prevFields));
        }
    };

    const handleInputChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));

        updateFieldErrorMessagesState(field);
        trackChangedFields(field);
    };

    const handleAddNewOption = (field, newOption) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: [...(prevData[field] || []), newOption],
        }));

        updateFieldErrorMessagesState(field);
        trackChangedFields(field);
    };

    const handleRemoveOption = (field, optionToRemove) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: prevData[field]?.filter((option) => option !== optionToRemove) || [],
        }));

        updateFieldErrorMessagesState(field);
        trackChangedFields(field);
    };

    // This function replaces the current form state with a new one. It should be used when the form is changed (means form fields are changed, not its values)
    const replaceFormState = (newFormState) => {
        setFormData(newFormState);
        setFieldErrorMessages({});
    };

    const resetChangedFields = () => {
        setChangedFields([]);
    };

    const addChangedField = (field) => {
        setChangedFields((prevFields) => addUniqueElementToArray(field, prevFields));
    };

    return {
        formData,
        setFormData,
        fieldErrorMessages,
        setFieldErrorMessages,
        handleInputChange,
        handleAddNewOption,
        handleRemoveOption,
        replaceFormState,
        changedFields,
        resetChangedFields,
        addChangedField,
    };
};
