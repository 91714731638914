import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FlashlightFillIcon } from '../../../design-system/Icons';
import { Button } from '../../../design-system';

const ConnectedAgentsBlock = ({ agents }) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (!agents?.length) {
        return <></>;
    }

    return (
        <div>
            <p className="font-heading-regular font-semibold text-[16px] text-black mb-5">
                Connected Agents
            </p>

            <ul className="flex flex-col">
                {agents.map(({ id, name }) => (
                    <li
                        key={id}
                        className="p-2 flex flex-col min-[550px]:flex-row min-[550px]:items-center min-[550px]:justify-between gap-x-4 gap-y-2 border-b-1 border-neutral-200"
                    >
                        <p className="font-body text-body-regular-s text-neutral-500">{name}</p>
                        <div className="min-w-fit flex items-center gap-2.5">
                            <Button
                                type="neutral"
                                size="xs"
                                text="View Agent"
                                onClick={() =>
                                    navigate(`/agent/${id}`, {
                                        state: { from: location },
                                    })
                                }
                            />
                            <Button
                                type="link"
                                size="xs"
                                text="New Job"
                                leadingIcon={FlashlightFillIcon}
                                onClick={() =>
                                    navigate(`/agent/${id}/start`, {
                                        state: { from: location },
                                    })
                                }
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ConnectedAgentsBlock;
