import React, { useState } from 'react';

import { ACCESS_TYPE } from '../../constants/accessType';

import { WsMultipleLoadingsProvider } from '../../contexts/websoketListeningMultipleLoadingsContext';

import ClientSettingsBlock from './ClientSettingsBlock/ClientSettingsBlock';
import BlockContainerWithHeader from '../BlockContainerWithHeader/BlockContainerWithHeader';
import ProcessOverviewSettingsTabSection from './ProcessOverviewSettingsTabSection/ProcessOverviewSettingsTabSection';

const EMPTY_CONTEXT_MESSAGE = 'No client settings found. Unlock settings above to edit here.';
const SHARED_AGENT_EMPTY_CONTEXT_MESSAGE =
    'No client settings found. Unlock settings in the parent organization to edit here.';

const AgentSettingsTabSection = ({
    processDetail,
    setProcessDetail,
    shouldShowHiddenSettings = false,
}) => {
    const { is_shared, access_type, default_version } = processDetail;

    const [contextChangesCount, setContextChangesCount] = useState(0);

    const triggerContextFormStateRefresh = () => {
        setContextChangesCount((prev) => prev + 1);
    };

    if (!is_shared) {
        return (
            <>
                <WsMultipleLoadingsProvider messageType="update_process">
                    <ProcessOverviewSettingsTabSection
                        processDetails={processDetail}
                        setProcessDetails={setProcessDetail}
                        shouldShowHiddenSettings={shouldShowHiddenSettings}
                        allowSharing={access_type === ACCESS_TYPE.shared}
                        triggerContextFormStateRefresh={triggerContextFormStateRefresh}
                    />
                </WsMultipleLoadingsProvider>

                {access_type === ACCESS_TYPE.shared && (
                    <ClientSettingsBlock
                        context={default_version.context?.context}
                        contextId={default_version.context?.id}
                        shouldShowHiddenSettings={shouldShowHiddenSettings}
                        emptyContextMessage={EMPTY_CONTEXT_MESSAGE}
                        refreshFormDataDependencyArray={[contextChangesCount]}
                    />
                )}
            </>
        );
    }

    if (is_shared) {
        return (
            <>
                <BlockContainerWithHeader
                    title="Agent Settings"
                    description="Go to your parent organization to view and edit the agent settings."
                />

                <ClientSettingsBlock
                    context={default_version.context?.context}
                    contextId={default_version.context?.id}
                    shouldShowHiddenSettings={shouldShowHiddenSettings}
                    emptyContextMessage={SHARED_AGENT_EMPTY_CONTEXT_MESSAGE}
                    refreshFormDataDependencyArray={[contextChangesCount]}
                />
            </>
        );
    }
};

export default AgentSettingsTabSection;
