// copy goal template config to clipboard
import { defaultErrorMessage } from '../constants/errorMessages';
import { ErrorWarningLineIcon } from '../design-system/Icons';
import CheckLineIcon from '../design-system/Icons/CheckLineIcon';

export const copyGoalTemplateConfig = (goalTemplate, setCopyAlert = () => {}) => {
    const goalTemplateConfig = {
        // get goal template name in lowercase and replace all non-alphanumeric characters with underscore
        name: goalTemplate.name.toLowerCase().replace(/[^a-z0-9]+/g, '_'),
        template_id: goalTemplate.id,
        // find the version of the goal template whose is_default == True
        version_id: goalTemplate.current_content.version_id,
        settings: goalTemplate.current_content.settings.reduce((settings, setting) => {
            settings[setting.name] = setting.value || '';
            return settings;
        }, {}),
        // add inputs with their default values
        inputs: goalTemplate.current_content.inputs.reduce((inputs, input) => {
            inputs[input.name] = input.value || '';
            return inputs;
        }, {}),

        outputs: goalTemplate.current_content.outputs.reduce((outputs, output) => {
            outputs[output.name] = '';
            return outputs;
        }, {}),

        milestones: [],
    };
    // Format the JSON with a 4-space indent
    const prettyJson = JSON.stringify(goalTemplateConfig, null, 4);

    navigator.clipboard
        .writeText(prettyJson)
        .then(() => {
            setCopyAlert({
                status: 'positive',
                message: 'Copied to Clipboard!',
                icon: CheckLineIcon,
            });
        })
        .catch(() => {
            setCopyAlert({
                status: 'critical',
                message: defaultErrorMessage,
                icon: ErrorWarningLineIcon,
            });
        });
};
