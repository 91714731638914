import React from 'react';
import classNames from 'classnames';
import { CHAT_ROLE } from '../../../constants/assistant';

import { SvgIcon } from '../../../design-system';
import { Robot2FillIcon } from '../../../design-system/Icons';

const MessageWrapper = ({ role, blackRobotIcon = false, noBgColor = false, children }) => {
    const userMsgWrapperClassNames = classNames(
        'relative rounded-2 max-w-[800px] w-full md:w-[80%] ml-auto',
        {
            'p-4 bg-neutral-100': !noBgColor,
        }
    );

    if (role === CHAT_ROLE.user) {
        return <div className={userMsgWrapperClassNames}>{children}</div>;
    }

    const robotIconColor = blackRobotIcon ? '#1F2125' : '#754DCF';

    if (role === CHAT_ROLE.assistant) {
        return (
            <div className="flex items-start py-4 sm:pl-4 gap-4">
                <SvgIcon color={robotIconColor} icon={Robot2FillIcon} size="large" />
                <div className="flex-1 flex flex-col gap-4 max-w-[calc(100%-40px)]">{children}</div>
            </div>
        );
    }

    return <></>;
};

export default MessageWrapper;
