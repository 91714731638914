import { mutateRequestKeysData } from './swrMutateUtils';

export const mutateDataAfterRemovingItem = ({ requestKeysToMutate, id }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: true,
        cb: (currentData) => {
            const updatedResults = currentData.results.filter((item) => item.id !== id);

            return { ...currentData, results: updatedResults, count: currentData.count - 1 };
        },
    });
};
