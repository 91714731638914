import React, { useState } from 'react';
import { ButtonIcon, SvgIcon } from '../../../../design-system';
import { DeleteBin4LineIcon, FileCopyLineIcon } from '../../../../design-system/Icons';
import EditLineIcon from '../../../../design-system/Icons/EditLineIcon';
import { handleCopy } from '../../../../helpers/handleCopy';
import Alert from '../../../../design-system/Alert/Alert';

const ProcessVariableCard = ({ output, setVariableModal, setConfirmDeleteModal, isEditable }) => {
    const { label, key, type } = output;

    const processMapping = '${process.' + key + '}';

    const [alert, setAlert] = useState(null);

    return (
        <div className="px-4 py-3 rounded-2 bg-neutral-50 flex gap-3 items-center justify-between">
            <div
                className={`flex flex-col gap-x-3 gap-y-2 w-[calc(100%-40px)] ${
                    isEditable
                        ? 'min-[412px]:w-[calc(100%-112px)] min-[540px]:flex-row min-[540px]:items-center'
                        : 'xs:flex-row xs:items-center'
                } `}
            >
                <div
                    className={`truncate ${
                        isEditable ? 'min-[540px]:w-[calc(100%-126px)]' : 'xs:w-[calc(100%-126px)]'
                    }`}
                >
                    <p className="font-body-bold text-body-bold-s text-black mb-[3px] truncate">
                        {label}
                    </p>
                    <p className="font-body text-body-regular-xs text-neutral-500 truncate">
                        {processMapping}
                    </p>
                </div>
                <p
                    className={`font-body text-body-regular-xs text-neutral-300 whitespace-nowrap truncate ${
                        isEditable ? 'min-[540px]:w-[114px]' : 'xs:w-[114px]'
                    }`}
                >
                    Data type{' '}
                    <span className="font-body text-body-bold-xs text-neutral-500">{type}</span>
                </p>
            </div>

            <div className="flex flex-col min-[412px]:flex-row min-[412px]:items-center gap-x-2">
                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={FileCopyLineIcon}
                    onClick={() => handleCopy(processMapping, setAlert)}
                />
                {isEditable && (
                    <>
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={EditLineIcon}
                            onClick={() =>
                                setVariableModal({
                                    isOpened: true,
                                    mode: 'edit',
                                    variableData: output,
                                })
                            }
                        />
                        <button
                            className="w-[28px] h-[28px] min-w-[28px] flex items-center justify-center"
                            onClick={() =>
                                setConfirmDeleteModal({ isOpened: true, variableData: output })
                            }
                        >
                            <SvgIcon icon={DeleteBin4LineIcon} color="#E95B69" size="medium" />
                        </button>
                    </>
                )}
            </div>
            {alert && (
                <Alert
                    status={alert.status}
                    message={alert.message}
                    icon={alert.icon}
                    autoCloseInMS={3000}
                    handleClose={() => setAlert(null)}
                />
            )}
        </div>
    );
};

export default ProcessVariableCard;
