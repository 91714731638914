import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TEMPLATE_TYPES } from '../../../../constants/template';
import { formatVersionDataForSelectOption } from '../../../../helpers/formatVersionDataForSelectOption';
import { useWindowSize } from '../../../../hooks/useWindowSize';

import { Button, Select } from '../../../../design-system';
import FileIcon from '../../../../design-system/Icons/FileIcon';
import gitBranchIcon from '../../../../design-system/Icons/GitBranchIcon';
import GitCommitIcon from '../../../../design-system/Icons/GitCommitIcon';

const BranchSelectionBlock = ({
    templateType,
    templateId,
    versions,
    isLive,
    selectedVersionId,
    setTemplateVersionData,
    backLinkHref,
    isEditAccess,
    handleMergeMain,
    isMergeMainLoading,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const buttonContainerRef = useRef(null);

    const isMainBranch = !selectedVersionId;
    const isMergeWithMainButtonDisplayed = isEditAccess && templateType === TEMPLATE_TYPES.process; // status == "open" or "changes_requested" or "ready_for_review" on Process Template Version Detail Page

    const isActionButtonDisplayed = isMainBranch || isMergeWithMainButtonDisplayed;
    const [actionButtonWidth, setActionButtonWidth] = useState(0);

    const { width: screenWidth } = useWindowSize();

    const handleBranchSelect = (selectedId) => {
        // if user select the same branch
        if (selectedVersionId === selectedId) {
            return;
        }
        setTemplateVersionData(null);
        // if selected branch is main navigate to Template Main detail page, if not - to Template Version detail page
        const route =
            selectedId === 'main'
                ? `/templates/${templateType}/${templateId}`
                : `/templates/${templateType}/${templateId}/branch/${selectedId}`;

        navigate(route, {
            state: { from: backLinkHref },
        });
    };

    const branchSelectOptions = useMemo(
        () => [
            {
                id: 'main',
                name: 'main (not editable)',
                leadingIcon: FileIcon,
                leadingIconColor: '#1F2125',
            },
            ...versions.map(formatVersionDataForSelectOption),
        ],
        [versions]
    );

    useEffect(() => {
        setActionButtonWidth(buttonContainerRef.current?.offsetWidth || 0);
    }, [buttonContainerRef.current]);

    const calculateSelectMaxWidth = (screenWidth, actionButtonWidth) => {
        if (screenWidth >= 630) {
            return `calc(100% - ${actionButtonWidth + 12}px)`;
        }
        if (screenWidth >= 1124) {
            return `calc(100% - ${actionButtonWidth + 20}px)`;
        }
        return '100%';
    };

    const selectMaxWidth = isActionButtonDisplayed
        ? calculateSelectMaxWidth(screenWidth, actionButtonWidth)
        : '100%';

    return (
        <div className="flex gap-3 l:gap-5 flex-col min-[630px]:flex-row min-[630px]:items-center">
            <div className={`flex-grow`} style={{ maxWidth: selectMaxWidth }}>
                <Select
                    size="sm"
                    value={selectedVersionId || 'main'}
                    name="version"
                    label="Goal Template Branch Name"
                    options={branchSelectOptions}
                    onChange={handleBranchSelect}
                    tipText={'Select an open branch to make edits'}
                    includeClientSideFiltering
                    dropdownHeight={280}
                    disableSorting
                    filterSearchBarPlaceholder="Search branches"
                />
            </div>

            {isActionButtonDisplayed && (
                <div className="min-w-max" ref={buttonContainerRef}>
                    {isMainBranch && (
                        <Button
                            type="primary"
                            size="md"
                            text="New Branch"
                            leadingIcon={gitBranchIcon}
                            state={isLive ? 'default' : 'disabled'}
                            onClick={() =>
                                navigate(`/templates/${templateType}/${templateId}/branch/new`, {
                                    state: { from: location },
                                })
                            }
                        />
                    )}

                    {isMergeWithMainButtonDisplayed && (
                        <Button
                            type="ghost"
                            size="md"
                            text="Merge Main"
                            leadingIcon={GitCommitIcon}
                            state={isMergeMainLoading ? 'loading' : 'default'}
                            onClick={handleMergeMain}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default BranchSelectionBlock;
