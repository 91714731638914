import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'design-system/Modal/Modal';
import { SvgIcon } from 'design-system';
import EscapeIcon from 'design-system/Icons/EscapeIcon';
import RecentViewedCard from 'components/RecentViewedCard/RecentViewedCard';
import SearchBar from 'components/SearchBar/SearchBar';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';

const UniversalSearchModal = (props) => {
    const { handleClose } = props;
    const { user } = useUser();

    const [recentData, setRecentData] = useState([]);
    const filteredRecentData = useMemo(() => {
        const organizationId = user?.organization?.id;
        return organizationId
            ? recentData.filter((item) => item.organizationId === organizationId)
            : [];
    }, [recentData, user]);

    const navigate = useNavigate();

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('recent-viewed-data') || '[]');
        setRecentData(data);
    }, []);

    return (
        <Modal onClose={handleClose}>
            <div className="flex flex-col gap-[16px]">
                <div className="flex justify-between items-center gap-2">
                    <h2 className="font-heading-bold text-heading-bold-m text-black">
                        Quick Search
                    </h2>
                    <div onClick={handleClose} className="cursor-pointer">
                        <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle"></SvgIcon>
                    </div>
                </div>
                <SearchBar
                    handleSearch={(value) => {
                        handleClose();
                        navigate(`/search?query=${value}&page=1`);
                    }}
                    isModal
                />
                {!!filteredRecentData.length && (
                    <>
                        <h3 className="font-body text-body-bold-s text-neutral-300 uppercase">
                            Your most recent
                        </h3>
                        <ul className="flex flex-col">
                            {filteredRecentData
                                .slice(0, 5)
                                .reverse()
                                .map((item, index) => (
                                    <li key={index}>
                                        <RecentViewedCard item={item} closeModal={handleClose} />
                                    </li>
                                ))}
                        </ul>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default UniversalSearchModal;
