import { ACCESS_CONFIG } from '../constants/organization';

export const checkViewTypeAccess = ({ viewType, orgPlan, userRole, is_staff }) => {
    // retrieve access rules for the given view type and organization plan
    const currentOrgPlanAccessRules = ACCESS_CONFIG[viewType][orgPlan];

    if (!currentOrgPlanAccessRules) {
        return false;
    }

    const {
        availableUserRoles: userRolePermissionRequirements,
        allowedIsStaffValues: isStaffPermissionRequirements,
    } = currentOrgPlanAccessRules;

    // determine access based on user roles
    const needsUserRoleAccess = !!userRolePermissionRequirements;
    const hasUserRoleAccess = needsUserRoleAccess
        ? userRolePermissionRequirements?.includes(userRole)
        : true;

    // determine access based on is_staff status
    const needsIsStaffAccess = !!isStaffPermissionRequirements;
    const hasIsStaffAccess = needsIsStaffAccess
        ? isStaffPermissionRequirements?.includes(is_staff)
        : true;

    const hasAccess = hasUserRoleAccess && hasIsStaffAccess;
    return hasAccess;
};
