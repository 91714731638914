import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import * as API from '../../constants/api';
import organizationClient from '../../services/organization-api';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import SettingsSidePanel from './SettingsSidePanel/SettingsSidePanel';
import AccountOverviewSection from './AccountOverviewSection/AccountOverviewSection';
import OrganizationDetailPage from './OrganizationDetailPage/OrganizationDetailPage';
import { ButtonIcon } from '../../design-system';
import { ExpandRightLineIcon } from '../../design-system/Icons';

const SettingsAndOrganizationsPage = () => {
    const { organizationId } = useParams();

    const [isSidePanelOpened, setIsSidePanelOpened] = useState(false);

    const formatResponseToOptions = (results) =>
        results?.map(({ id, name, child_organizations }) => ({ id, name, child_organizations }));

    const {
        options: organizationsList,
        optionsLoading: organizationsLoading,
        setPage,
        canLoadMoreOptions: canLoadMoreOrganizations,
        setOptions: setOrganizationsList,
        requestKeysToMutate: organizationsRequestKeysToMutate,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client: organizationClient,
        route: API.ROUTES.organization.organization,
        formatResponseToOptions,
    });

    return (
        <div className="page-position flex">
            <SettingsSidePanel
                isSidePanelOpened={isSidePanelOpened}
                setIsSidePanelOpened={setIsSidePanelOpened}
                organizationsList={organizationsList}
                organizationsLoading={organizationsLoading}
                setPage={setPage}
                canLoadMoreOrganizations={canLoadMoreOrganizations}
            />

            <div className="px-5 pb-8 pt-[60px] lg:pt-4 flex flex-col gap-5 w-full overflow-y-auto">
                <div className="lg:hidden fixed top-[60px] sm:top-0 left-0 sm:left-[68px] right-0 bg-white z-50 px-5 h-[44px] flex items-center border-b-1 border-neutral-200">
                    <ButtonIcon
                        type="link"
                        size="sm"
                        icon={ExpandRightLineIcon}
                        onClick={() => setIsSidePanelOpened(true)}
                    />
                </div>

                {!organizationId && <AccountOverviewSection />}

                {organizationId && (
                    <OrganizationDetailPage
                        setOrganizationsList={setOrganizationsList}
                        organizationsRequestKeysToMutate={organizationsRequestKeysToMutate}
                    />
                )}
            </div>
        </div>
    );
};

export default SettingsAndOrganizationsPage;
