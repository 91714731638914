import { defaultErrorMessage } from '../constants/errorMessages';

import CheckLineIcon from '../design-system/Icons/CheckLineIcon';
import { ErrorWarningLineIcon } from '../design-system/Icons';

export const handleCopy = (data, setAlert = () => {}, successMessage = 'Copied to clipboard') => {
    const valueToCopy = typeof data === 'string' ? data : JSON.stringify(data, null, 4);

    navigator.clipboard
        .writeText(valueToCopy)
        .then(() => {
            setAlert({ status: 'positive', message: successMessage, icon: CheckLineIcon });
        })
        .catch(() => {
            setAlert({
                status: 'critical',
                message: defaultErrorMessage,
                icon: ErrorWarningLineIcon,
            });
        });
};
