import { useEffect } from 'react';
import client from '../services/library-api';
import { API } from 'constants';

export const useFetchCollections = (
    setCollections,
    setIsLoading = () => {},
    setAllCollectionsAreLoaded = () => {}
) => {
    useEffect(() => {
        const fetchNextCollections = async (page) => {
            const { data } = await client.get(
                `${API.ROUTES.library.collection}?limit=12&offset=${(page - 1) * 12}`
            );
            setCollections((prevData) => [...prevData, ...data.results]);
            if (data.next) {
                await fetchNextCollections(page + 1);
            }
        };

        const fetchCollections = async () => {
            try {
                setIsLoading(true);
                const { data } = await client.get(API.ROUTES.library.collection);
                setCollections(data.results);
                if (data.next) {
                    await fetchNextCollections(2);
                }
            } catch (e) {
            } finally {
                setIsLoading(false);
                setAllCollectionsAreLoaded(true);
            }
        };

        fetchCollections();
    }, []);

    return {};
};
