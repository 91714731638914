import React from 'react';

import useDocumentTitle from '../../hooks/useDocumentTitle';

import { DocsPageProvider } from '../../contexts/docsPageContext';

import FolderAndDocumentIndex from './FolderAndDocumentIndex/FolderAndDocumentIndex';
import FolderSidePanel from './FolderSidePanel/FolderSidePanel';

const DocsPage = () => {
    useDocumentTitle('Docs');

    return (
        <div className="page-position flex">
            <FolderSidePanel />

            <FolderAndDocumentIndex />
        </div>
    );
};

const WrappedDocsPage = () => (
    <DocsPageProvider>
        <DocsPage />
    </DocsPageProvider>
);

export default WrappedDocsPage;
