import { useEffect, useRef, useState } from 'react';

export const useIntersectionObserver = ({
    handleFetch,
    options,
    optionsLoading,
    canLoadMore,
    isSelectOpen = false, // use this prop if useIntersectionObserver is called in Select
}) => {
    const observer = useRef(null);
    const guardRef = useRef(null);

    // We need to use this loading state instead of optionsLoading to avoid flickering when includeClientSideFiltering=true, the user enters a query for which there are no results, but requests go one after another searching for the entered query.
    // Use this loading state just when the fist page of data is already loaded (when the first page is loading use optionsLoading state)
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);

    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    };

    const onIntersectionObserver = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (!optionsLoading) {
                    handleFetch();
                }
            }
        });
    };

    useEffect(() => {
        observer.current = new IntersectionObserver(onIntersectionObserver, observerOptions);

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [options, optionsLoading]);

    useEffect(() => {
        if (!canLoadMore) {
            observer.current?.disconnect();
        }
    }, [canLoadMore]);

    useEffect(() => {
        if (guardRef.current && observer.current && canLoadMore) {
            observer.current.observe(guardRef.current);
        }
    }, [guardRef.current, observer, optionsLoading, options, canLoadMore, isSelectOpen]);

    useEffect(() => {
        // remove loader when !canLoadMore (there are no more data to fetch)
        if (!canLoadMore && !!options.length) {
            setIsNextPageLoading(false);
        }
    }, [canLoadMore, options]);

    useEffect(() => {
        if (optionsLoading && canLoadMore && !isNextPageLoading) {
            setIsNextPageLoading(true);
        }
    }, [optionsLoading, isNextPageLoading]);

    return { guardRef, isNextPageLoading };
};
