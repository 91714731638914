import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonIcon from 'design-system/ButtonIcon/ButtonIcon';
import ExpandRightLineIcon from 'design-system/Icons/ExpandRightLineIcon';
import FullscreenLineIcon from 'design-system/Icons/FullscreenLineIcon';

Panel.propTypes = {
    onClose: PropTypes.func,
    onNavigationButtonClick: PropTypes.func,
    showControls: PropTypes.bool,
    closeByClickOutside: PropTypes.bool,
    resetPadding: PropTypes.bool,
    withOverflowProperty: PropTypes.bool,
};

const panelRoot = document.getElementById('panel-root');

function Panel({
    children,
    onClose,
    onNavigationButtonClick,
    showControls = true,
    closeByClickOutside = true,
    resetPadding = false,
    withOverflowProperty = true,
}) {
    const panelRef = useRef(null);

    useEffect(() => {
        document.body.classList.add('no-scroll');

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const panelClassName = classNames(
        'fixed top-0 bottom-0 right-0 bg-white z-32 transition-all duration-300 w-full ml:w-1/2 ml:min-w-[800px] flex flex-col',
        { 'px-4 pt-4 pb-8 sm:px-8': !resetPadding, 'overflow-y-auto': withOverflowProperty }
    );

    const onBackDropClick = (e) => {
        if (e.target === e.currentTarget && closeByClickOutside) {
            onClose();
        }
    };

    return createPortal(
        <div
            className="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-31"
            onMouseDown={onBackDropClick}
        >
            <div className={panelClassName} ref={panelRef}>
                {showControls && (
                    <div className="flex mb-[12px]">
                        <ButtonIcon
                            size="sm"
                            type="link"
                            icon={ExpandRightLineIcon}
                            onClick={onClose}
                        />
                        <ButtonIcon
                            size="sm"
                            type="link"
                            icon={FullscreenLineIcon}
                            onClick={onNavigationButtonClick || (() => {})}
                        />
                    </div>
                )}
                {children}
            </div>
        </div>,
        panelRoot
    );
}

export default Panel;
