import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { useClickOutside } from '../../../hooks';
import { useEscapeKeyListener } from '../../../hooks/useEscapeKeyListener';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { getAvailableModalMaxHeight } from '../../../helpers/getAvailableModalMaxHeight';

import SearchBar from '../../../design-system/SearchBar/SearchBar';
import PromptCard from '../PromptCard/PromptCard';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';

import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';

const SelectPromptPopup = ({
    promptsHookResponse,
    textareaHeight,
    handleAppendPromptToMessage,
    onClose,
}) => {
    const popupRef = useRef(null);
    const [popupMinHeight, setPopupMinHeight] = useState(0);

    useClickOutside(popupRef, onClose);
    useEscapeKeyListener(onClose);

    const { isMobile } = useResponsiveBreakpoints();

    const { options: playbooks, optionsLoading, setPage, canLoadMoreOptions } = promptsHookResponse;

    const [searchQuery, setSearchQuery] = useState('');
    const loweredSearchQuery = searchQuery.toLowerCase();

    // playbooks with chat_prompts that match search
    const playbooksWithFilteredPrompts = useMemo(filterPrompts, [playbooks, loweredSearchQuery]);

    // hide playbooks with no chat_prompts
    const playbooksToDisplay = playbooksWithFilteredPrompts?.filter(
        (playbook) => !!playbook.chat_prompts?.length
    );

    function filterPrompts() {
        if (!loweredSearchQuery) {
            return playbooks;
        }

        return playbooks?.map((playbook) => ({
            ...playbook,
            chat_prompts: playbook.chat_prompts?.filter((prompt) =>
                prompt.name?.toLowerCase()?.includes(loweredSearchQuery)
            ),
        }));
    }

    const maxHeight = getAvailableModalMaxHeight(textareaHeight);

    useEffect(() => {
        if (popupRef.current && !isMobile) {
            const popupHeight = popupRef.current.offsetHeight;
            if (popupHeight > popupMinHeight) {
                setPopupMinHeight(Math.min(popupHeight, maxHeight));
            }
        }
    }, [popupRef.current, playbooksWithFilteredPrompts]);

    const promptsListClassName = classNames('flex-1 pt-4 mt-2 px-5', {
        'md:pb-3': !!playbooksToDisplay?.length,
        'min-h-[70px]': optionsLoading,
        'overflow-y-auto': isMobile || maxHeight >= 300,
    });

    const handlePromptClick = useCallback((prompt) => {
        handleAppendPromptToMessage(prompt);
        onClose();
    }, []);

    const topFixedContent = (
        <div className="flex flex-col">
            <ModalHeader
                onClose={onClose}
                title="Select prompt"
                outlined
                titleStyles="font-heading-bold text-heading-bold-s text-neutral-500"
                customPaddings="py-3 px-5"
            />

            <div className="pt-5 px-5 flex items-center gap-3 xs:gap-5">
                <SearchBar
                    size="md"
                    value={searchQuery}
                    state={!playbooks ? 'disabled' : 'default'}
                    placeholder="Search..."
                    onChange={(e) => setSearchQuery(e.target.value)}
                    withClearIcon
                    showClearIconIfValueIsNotEmpty
                    onClear={() => setSearchQuery('')}
                    onSubmit={() => {}}
                    autoFocus
                />
                <button
                    type="button"
                    className="font-body text-body-regular-m text-neutral-500 underline"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </div>
        </div>
    );

    const promptsList = (
        <div className={promptsListClassName}>
            <InfiniteScrollList
                handleFetch={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                items={playbooks}
                loading={optionsLoading}
                gap={0}
            >
                <div className="flex flex-col gap-4">
                    {playbooksToDisplay?.map((playbook) => (
                        <div key={playbook.id}>
                            <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase mb-4">
                                {playbook.name}
                            </p>
                            <div className="flex flex-col">
                                {playbook.chat_prompts?.map((prompt) => (
                                    <PromptCard
                                        key={prompt.id}
                                        prompt={prompt}
                                        isMobile={isMobile}
                                        handlePromptClick={handlePromptClick}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </InfiniteScrollList>
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                closeButtonText="Cancel"
                minHeight="86vh"
                maxHeight="86vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="pt-0"
                bottomButtonsCustomPaddings="px-5 pt-3"
                topFixedContent={topFixedContent}
                customGap={8}
            >
                <div className="flex flex-col bg-white">{promptsList}</div>
            </MobilePopup>
        );
    }

    return (
        <div className="absolute bottom-[calc(100%+6px)] left-0 right-0">
            <div
                className="flex flex-col bg-white rounded-2 border-1 border-neutral-200 shadow-l1 overflow-y-auto"
                style={{ maxHeight: `${maxHeight}px`, minHeight: `${popupMinHeight}px` }}
                ref={popupRef}
            >
                {topFixedContent}
                {promptsList}
            </div>
        </div>
    );
};

export default SelectPromptPopup;
