import React, { useRef, useState } from 'react';
import { useFetchLabels } from '../../hooks/useFetchLabels';
import { useWindowSize } from '../../hooks/useWindowSize';
import { getLabelsSelectorPosition } from '../../helpers/templatesUtils';

import { Badge, SvgIcon } from '../../design-system';
import { AddLineIcon } from '../../design-system/Icons';
import LabelSelectorPopup from '../LabelSelectorPopup/LabelSelectorPopup';
import LabelBadge from '../LabelBadge/LabelBadge';

const LabelsBlock = ({
    addedLabels,
    setAddedLabels = () => {},
    handleDeleteAddedLabel,
    shouldSaveDataOnBlur = false,
    saveUpdatedLabels = () => {},
    isEditable = true,
}) => {
    const fetchAllLabelsHookResponse = useFetchLabels();
    const buttonRef = useRef(null);

    const { width: screenWidth } = useWindowSize();
    const [labelSelector, setLabelSelector] = useState({
        isOpened: false,
        width: 360,
        position: {},
    });

    const openSelector = () => {
        const { position, width } = getLabelsSelectorPosition({
            screenWidth,
            openingButtonRef: buttonRef,
        });
        setLabelSelector({ isOpened: true, position, width });
    };

    return (
        <div className="flex items-center gap-2 flex-wrap relative">
            {addedLabels?.map((label) => (
                <LabelBadge
                    key={label.id}
                    label={label}
                    removeable={isEditable}
                    handleRemove={handleDeleteAddedLabel}
                />
            ))}
            {isEditable && (
                <div className="xs:relative">
                    <button
                        type="button"
                        className="flex items-center gap-1 px-2 py-1 font-body text-body-regular-xs text-neutral-500 rounded-2 bg-neutral-100 select-none"
                        onClick={openSelector}
                        ref={buttonRef}
                    >
                        <SvgIcon color="#1F2125" size="medium" icon={AddLineIcon} />
                        Label
                    </button>
                    {labelSelector.isOpened && (
                        <LabelSelectorPopup
                            fetchAllLabelsHookResponse={fetchAllLabelsHookResponse}
                            currentlyAddedLabels={addedLabels}
                            setCurrentlyAddedLabels={setAddedLabels}
                            shouldSaveDataOnBlur={shouldSaveDataOnBlur}
                            saveUpdatedLabels={saveUpdatedLabels}
                            selectorWidth={labelSelector.width}
                            selectorPosition={labelSelector.position}
                            onClose={() => setLabelSelector({ isOpened: false })}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default LabelsBlock;
