import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Plot from 'react-plotly.js';

import { formatPlotData, formatPlotLayout, getLayoutSizes } from '../../../helpers/plotUtils';

import Modal from '../../Modal/Modal';
import { ButtonIcon } from '../../index';
import { CloseLineIcon } from '../../Icons';

// assuming that the max width of the modal is 95% (90% from 1650px) of the screen and max height is 95% of the screen
const FullScreenPlotModal = ({ value, onClose }) => {
    let parsedValue = value;
    if (typeof value === 'string') {
        try {
            parsedValue = JSON.parse(value);
        } catch (e) {}
    }

    function countPlotSizes() {
        if (typeof parsedValue !== 'object' || parsedValue === null) {
            return { width: null, height: null };
        }

        const { layoutWidth, layoutHeight } = getLayoutSizes({ layout: parsedValue.layout });

        let width;

        const screenWidth = window.innerWidth;
        width = screenWidth >= 1650 ? screenWidth * 0.9 - 16 : screenWidth * 0.95 - 16;

        let height = (layoutHeight / layoutWidth) * width;

        const screenHeight = window.innerHeight;
        const availableHeight = screenHeight * 0.95 - 24;

        if (height > availableHeight) {
            height = availableHeight;
            width = (layoutWidth / layoutHeight) * height;
        }

        return { width, height };
    }

    if (typeof parsedValue === 'object' && parsedValue !== null) {
        const { data, layout } = parsedValue;
        const { width, height } = countPlotSizes();

        const formattedData = formatPlotData({ data });

        const formattedLayout = formatPlotLayout({ layout, width, height });

        const backgroundColor = layout?.paper_bgcolor || '#fff';

        return (
            <Modal size="fullScreen" maxHeight="95%" resetPadding onClose={onClose}>
                <div className="relative">
                    <div className="absolute top-2 right-2 z-30">
                        <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                    </div>

                    <div className="p-2 overflow-hidden" style={{ backgroundColor }}>
                        <Plot
                            data={formattedData}
                            layout={formattedLayout}
                            config={{ displayModeBar: false }}
                            onError={(err) => console.log('Error in PlotView', err)}
                        />
                    </div>
                </div>
            </Modal>
        );
    }

    return <></>;
};

FullScreenPlotModal.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onClose: PropTypes.func.isRequired,
};

export default memo(FullScreenPlotModal);
