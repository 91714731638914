import React from 'react';

const PlayCircleFillIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M8.0026 14.6663C4.3207 14.6663 1.33594 11.6815 1.33594 7.99967C1.33594 4.31777 4.3207 1.33301 8.0026 1.33301C11.6845 1.33301 14.6693 4.31777 14.6693 7.99967C14.6693 11.6815 11.6845 14.6663 8.0026 14.6663ZM7.08387 5.6094C7.04007 5.58019 6.9886 5.56461 6.93594 5.56461C6.78867 5.56461 6.66927 5.684 6.66927 5.83128V10.1681C6.66927 10.2207 6.68487 10.2722 6.71407 10.316C6.79574 10.4385 6.96134 10.4717 7.08387 10.3899L10.3365 8.22154C10.3657 8.20201 10.3909 8.17687 10.4104 8.14761C10.4921 8.02507 10.459 7.85947 10.3365 7.77781L7.08387 5.6094Z"
                fill={props.color}
            />
        </svg>
    );
};

export default PlayCircleFillIcon;
