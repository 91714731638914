import { DateTime } from 'luxon';

export const formatVersionsDataForTable = (data) => {
    const result = {
        columns: [
            {
                header: 'VERSION',
                accessor: 'version',
                type: 'text',
                fixedWidth: 110,
            },
            {
                header: 'NAME',
                accessor: 'name',
                type: 'text',
            },
            {
                header: 'UPDATED AT',
                accessor: 'updatedAt',
                type: 'text',
                fixedWidth: 250,
            },
            {
                header: 'STATUS',
                accessor: 'status',
                type: 'badge',
                fixedWidth: 132,
            },
        ],
        rows: [],
    };

    data.map((version) => {
        const formattedTime = DateTime.fromISO(version.updated_at).toFormat(
            'MM/dd/yyyy - hh:mm:ss a'
        );

        result.rows.push({
            id: version.id,
            version: version.version_name,
            name: version.name,
            updatedAt: formattedTime,
            status: version.is_published ? 'Published' : 'Unpublished',
            badgeColor: version.is_published ? 'lime' : 'peach',
        });
    });

    return result;
};
