import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/assistant-api';
import reportClient from '../../services/report-api';

import useUser from '../../hooks/useUser';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useFetchChatThreadData } from '../../hooks/useFetchChatThreadData';
import { useFetchJobData } from '../../hooks/useFetchJobData';
import { useFetchInfinityScrollableSearchAndEditData } from '../../hooks/useFetchInfinityScrollableSearchAndEditData';

import HistorySidePanel from './HistorySidePanel/HistorySidePanel';
import ChatThreadPanel from './ChatThreadPanel/ChatThreadPanel';
import JobPanel from './JobPanel/JobPanel';

const AssistantPage = () => {
    const { chatThreadId, jobId } = useParams();

    const [searchParams] = useSearchParams();
    const isJobPanelOpened = searchParams.get('agent') || jobId;

    const { user } = useUser();

    const isDesktop = window.innerWidth >= 1024;
    const historyPanelExpandedState = useState(isDesktop);

    const chatThreadsHistoryHookResponse = useFetchInfinityScrollableSearchAndEditData({
        client,
        route: API.ROUTES.assistant.chatThread,
    });

    const jobsHistoryHookResponse = useFetchInfinityScrollableSearchAndEditData({
        client: reportClient,
        route: API.ROUTES.report.job,
        searchParams: { created_by: user?.id },
        startRequest: !!user,
    });

    const chatThreadDataHookResponse = useFetchChatThreadData({ chatThreadId });

    const jobDataHookResponse = useFetchJobData({ jobId });

    useDocumentTitle('Assistant');

    // remove html {scrollbar-gutter: stable} to display scrollbar correctly
    useEffect(() => {
        const htmlElement = document.documentElement;
        const originalScrollbarGutter = htmlElement.style.scrollbarGutter;
        htmlElement.style.scrollbarGutter = 'auto';

        return () => {
            htmlElement.style.scrollbarGutter = originalScrollbarGutter;
        };
    }, []);

    return (
        <div className="page-position bg-white flex overflow-hidden">
            <HistorySidePanel
                historyPanelExpandedState={historyPanelExpandedState}
                chatThreadsHistoryHookResponse={chatThreadsHistoryHookResponse}
                setCurrentViewingChatThreadData={chatThreadDataHookResponse.setChatThreadData}
                setCurrentViewingJobData={jobDataHookResponse.setJobData}
                jobsHistoryHookResponse={jobsHistoryHookResponse}
            />
            {!isJobPanelOpened && (
                <ChatThreadPanel
                    chatThreadId={chatThreadId}
                    chatThreadDataHookResponse={chatThreadDataHookResponse}
                    historyPanelExpandedState={historyPanelExpandedState}
                    setChatThreadsHistoryList={chatThreadsHistoryHookResponse.setItems}
                />
            )}
            {isJobPanelOpened && (
                <JobPanel
                    jobId={jobId}
                    jobDataHookResponse={jobDataHookResponse}
                    historyPanelExpandedState={historyPanelExpandedState}
                    setJobsHistoryList={jobsHistoryHookResponse.setItems}
                />
            )}
        </div>
    );
};

export default AssistantPage;
