export const areNumericArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false;
    }

    const sortedArray1 = [...array1].sort((a, b) => a - b);
    const sortedArray2 = [...array2].sort((a, b) => a - b);

    return sortedArray1.every((value, index) => value === sortedArray2[index]);
};
