import React, { memo } from 'react';
import { DateTime } from 'luxon';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import { mutateListDataAfterUpdatingItemField } from '../../../../helpers/swrMutateUtils';

import { ButtonIcon, SvgIcon } from '../../../../design-system';
import { CloseLineIcon } from '../../../../design-system/Icons';
import ArchiveLineIcon from '../../../../design-system/Icons/ArchiveLineIcon';
import TextFieldWithCustomTextStyles, {
    emptyFieldError,
} from '../../../../design-system/TextFieldWithCustomTextStyles/TextFieldWithCustomTextStyles';
import UserBadge from '../../../../design-system/UserBadge/UserBadge';
import DetailContainer from '../../../../design-system/DetailContainer/DetailContainer';

const EditBaseEntryPanelHeader = ({ entry, hasEditAccess, requestKeysToMutate, onClose }) => {
    const { id, label, base, created_by, created_at, updated_at } = entry;

    const entryData = [
        {
            label: 'Created By',
            value: <UserBadge name={created_by.first_name || created_by.email} />,
        },
        {
            label: 'Created At',
            value: DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - hh:mm a'),
        },
        {
            label: 'Updated At',
            value: DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a'),
        },
    ];

    const saveUpdatedLabel = async (label) => {
        if (!label) {
            throw new Error(emptyFieldError);
        }

        mutateListDataAfterUpdatingItemField({
            requestKeysToMutate,
            id,
            fieldName: 'label',
            fieldValue: label,
        });
        await client.patch(`${API.ROUTES.knowledge.base}${base}/entry/${id}/`, { label });
    };

    return (
        <>
            <div className="flex items-center justify-between gap-3">
                <p className="font-body-bold text-body-bold-l text-black">Edit Entry</p>
                <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
            </div>

            <div className="flex gap-1">
                <div className="w-[32px] h-[32px] min-w-[32px] rounded-2 bg-purple-100 flex items-center justify-center">
                    <SvgIcon color="#754DCF" icon={ArchiveLineIcon} size="medium" />
                </div>

                <div className="flex-grow my-auto">
                    {hasEditAccess ? (
                        <TextFieldWithCustomTextStyles
                            value={label}
                            minHeight={32}
                            isSaveOnBlur
                            isSaveOnEnterPress
                            handleSave={saveUpdatedLabel}
                            textStyle="font-heading-bold text-heading-bold-s text-black"
                            customTextFieldStyles="px-2 pb-0.5 pt-1.5 rounded-2 shadow-inset-1 shadow-transparent focus:shadow-purple-500 transition-all"
                        />
                    ) : (
                        <p className="font-heading-bold text-heading-bold-s text-black px-2">
                            {label}
                        </p>
                    )}
                </div>
            </div>

            <div className="py-2.5 flex flex-col gap-4 border-b-1 border-neutral-200">
                {entryData.map(({ label, value }) => (
                    <DetailContainer
                        key={label}
                        label={label}
                        isMobileView
                        labelCustomStyles="w-[94px] min-w-[94px] md:w-[124px] md:min-w-[124px]"
                    >
                        {value}
                    </DetailContainer>
                ))}
            </div>
        </>
    );
};

export default memo(EditBaseEntryPanelHeader);
