import * as API from './api';

import ArchiveLineIcon from '../design-system/Icons/ArchiveLineIcon';
import FileTextLineIcon from '../design-system/Icons/FileTextLineIcon';

export const KNOWLEDGE_SOURCE_TYPE = {
    bases: 'bases',
    documents: 'documents',
};

export const KNOWLEDGE_SOURCE_TYPE_ICON = {
    [KNOWLEDGE_SOURCE_TYPE.bases]: ArchiveLineIcon,
    [KNOWLEDGE_SOURCE_TYPE.documents]: FileTextLineIcon,
};

export const SOURCE_ROUTES_BY_TYPE = {
    [KNOWLEDGE_SOURCE_TYPE.bases]: API.ROUTES.knowledge.base,
    [KNOWLEDGE_SOURCE_TYPE.documents]: API.ROUTES.knowledge.document,
};
