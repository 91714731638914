import React, { useState } from 'react';
import Panel from '../../../../components/Panel';
import MilestonePanelHeader from '../MilestonePanelHeader/MilestonePanelHeader';
import MilestonePanelContent from '../MilestonePanelContent/MilestonePanelContent';

const MilestoneSidePanel = ({ milestone, bucketStatus, onClose }) => {
    // when a modal is opened above the panel, this state is used to control the closing of the panel
    const [shouldClosePanelOnOutsideClick, setShouldClosePanelOnOutsideClick] = useState(true);

    return (
        <Panel
            onClose={onClose}
            showControls={false}
            closeByClickOutside={shouldClosePanelOnOutsideClick}
        >
            <MilestonePanelHeader
                milestone={milestone}
                bucketStatus={bucketStatus}
                onClose={onClose}
            />
            {!!milestone.content?.length && (
                <MilestonePanelContent
                    milestoneId={milestone.id}
                    contentArray={milestone.content}
                    setShouldClosePanelOnOutsideClick={setShouldClosePanelOnOutsideClick}
                />
            )}
        </Panel>
    );
};

export default MilestoneSidePanel;
