import React, { memo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { CONFIGURE_TAB } from '../../../constants/configure';
import { capitalizeFirstLetter } from '../../../services/strings';
import { handleChangeTab } from '../../../helpers/handleUpdateSearchParams';

import { Button, Tabs } from '../../../design-system';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import FlowChartIcon from '../../../design-system/Icons/FlowChartIcon';
// import FlowIcon from '../../../design-system/Icons/FlowIcon';

export const tabs = [CONFIGURE_TAB.processes, CONFIGURE_TAB.scenarios];
const formattedTabs = tabs.map((tab) => ({ name: capitalizeFirstLetter(tab), key: tab }));

const ConfigurePageHeader = ({ activeTabIndex, handleNewScenarioClick }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = tabs[activeTabIndex];

    const createNewProcess = () => {
        navigate('/configure/process/new', {
            state: { configureSearchParams: location.search },
        });
    };

    return (
        <>
            <div className="flex flex-col xs:flex-row justify-between items-start gap-4">
                <div>
                    <p className="font-heading-bold text-heading-bold-l">Configure</p>
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        Collection of pre-designed goals with instructions configured to guide
                        interactions with AI.
                    </p>
                </div>
                {activeTab === CONFIGURE_TAB.processes && (
                    <Button
                        type="secondary"
                        size="sm"
                        text="New Process"
                        leadingIcon={PlayCircleFillIcon}
                        onClick={createNewProcess}
                    />
                )}

                {activeTab === CONFIGURE_TAB.scenarios && (
                    <Button
                        type="secondary"
                        size="sm"
                        text="New Scenario"
                        leadingIcon={FlowChartIcon}
                        onClick={handleNewScenarioClick}
                    />
                )}
            </div>

            <div className="max-w-fit">
                <Tabs
                    tabs={formattedTabs}
                    tabIndex={activeTabIndex}
                    onTabChanged={(tab) => handleChangeTab({ tab, searchParams, setSearchParams })}
                />
            </div>
        </>
    );
};

export default memo(ConfigurePageHeader);
