import React, { useRef, useState } from 'react';
import { Button } from '../../../design-system';
import InstructionsModal from '../../../components/InstructionsModal/InstructionsModal';

const SettingsStepHeader = ({ step, pageContainerRef }) => {
    const { title, description, instructions } = step || {};

    const viewInstructionButtonRef = useRef(null);
    const [isInstructionModalOpened, setIsInstructionModalOpened] = useState(false);

    return (
        <div className="flex flex-col gap-2 pb-3 border-b-1 lg:border-none lg:pb-0 border-neutral-200">
            <div className="flex items-end justify-between gap-x-4 gap-y-3 flex-wrap">
                <p className="font-heading-bold text-heading-bold-s sm:text-heading-bold-m text-black">
                    {title}
                </p>
                {instructions && (
                    <div className="relative hidden sm:block" ref={viewInstructionButtonRef}>
                        <Button
                            type="ghost"
                            size="xs"
                            text="View Instructions"
                            onClick={() => setIsInstructionModalOpened(true)}
                        />
                        {isInstructionModalOpened && (
                            <InstructionsModal
                                instructions={instructions}
                                description="Here are the instructions for this step."
                                pageContainerRef={pageContainerRef}
                                viewInstructionButtonRef={viewInstructionButtonRef}
                                topValue={42}
                                onClose={(e) => {
                                    e?.stopPropagation();
                                    setIsInstructionModalOpened(false);
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            {description && (
                <p className="font-body text-body-regular-xs sm:text-body-regular-m text-neutral-400">
                    {description}
                </p>
            )}
        </div>
    );
};

export default SettingsStepHeader;
