import React from 'react';

import { TASK_TYPES } from '../../../../constants/library';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';

import CodeBlock from '../CodeBlock/CodeBlock';
import ComingSoonBlock from '../ComingSoonBlock/ComingSoonBlock';
import AgentMessagesBlock from '../AgentMessagesBlock/AgentMessagesBlock';
import ApiRequestDetailsBlock from '../ApiRequestDetailsBlock/ApiRequestDetailsBlock';
import AssistantMessagesBlock from '../AssistantMessagesBlock/AssistantMessagesBlock';
import InboxTaskInstructionsBlock from '../InboxTaskInstructionsBlock/InboxTaskInstructionsBlock';
import RetrievalResponseFormatBlock from '../RetrievalResponseFormatBlock/RetrievalResponseFormatBlock';
import PlotDefinitionBlock from '../PlotDefinitionBlock/PlotDefinitionBlock';

const TaskPlaygroundLeftPanel = ({
    taskType,
    taskId,
    webhookUrl,
    setWebhookUrl,
    taskChangeableData,
    setTaskChangeableData,
    fieldsErrorMessages,
    setFieldErrorMessages,
    inboxTaskInstructions,
    setInboxTaskInstructions,
    setChangedTaskFields,
    retrievalResponseFormat,
    setRetrievalResponseFormat,
    assistantAndAgentTasksChangeableData,
    setAssistantAndAgentTasksChangeableData,
    setUserInputs,
    apiTaskChangeableData,
    setApiTaskChangeableData,
    apiTaskInputFieldsErrorMessages,
    setApiTaskInputFieldsErrorMessages,
}) => {
    if (taskType === TASK_TYPES.inbox) {
        return (
            <InboxTaskInstructionsBlock
                instructions={inboxTaskInstructions}
                setInstructions={setInboxTaskInstructions}
                setChangedTaskFields={setChangedTaskFields}
            />
        );
    }

    if (taskType === TASK_TYPES.retrieval) {
        return (
            <RetrievalResponseFormatBlock
                retrievalResponseFormat={retrievalResponseFormat}
                setRetrievalResponseFormat={setRetrievalResponseFormat}
                setChangedRetrievalFields={setChangedTaskFields}
            />
        );
    }

    if (taskType === TASK_TYPES.assistant) {
        return (
            <AssistantMessagesBlock
                assistantTaskChangeableData={assistantAndAgentTasksChangeableData}
                setAssistantTaskChangeableData={setAssistantAndAgentTasksChangeableData}
                setChangedTaskFields={setChangedTaskFields}
                setUserInputs={setUserInputs}
            />
        );
    }

    if (taskType === TASK_TYPES.function) {
        return (
            <AgentMessagesBlock
                messages={assistantAndAgentTasksChangeableData?.messages}
                setChangeableData={setAssistantAndAgentTasksChangeableData}
                setChangedTaskFields={setChangedTaskFields}
                setUserInputs={setUserInputs}
            />
        );
    }

    if (taskType === TASK_TYPES.api) {
        return (
            <ApiRequestDetailsBlock
                apiTaskChangeableData={apiTaskChangeableData}
                setApiTaskChangeableData={setApiTaskChangeableData}
                setChangedTaskFields={setChangedTaskFields}
                fieldsErrorMessages={apiTaskInputFieldsErrorMessages}
                setFieldsErrorMessages={setApiTaskInputFieldsErrorMessages}
                setUserInputs={setUserInputs}
            />
        );
    }

    const handleInputChange = (field, value) => {
        setTaskChangeableData((prevData) => ({ ...prevData, [field]: value }));
        setChangedTaskFields((prevData) => addUniqueElementToArray(field, prevData));

        setFieldErrorMessages((errorFields) => {
            if (errorFields[field]) {
                return { ...errorFields, [field]: null };
            }

            return errorFields;
        });
    };

    if (taskType === TASK_TYPES.code) {
        return (
            <CodeBlock
                code={taskChangeableData?.code || ''}
                handleInputChange={handleInputChange}
                fieldsErrorMessages={fieldsErrorMessages}
                setUserInputs={setUserInputs}
            />
        );
    }

    if (taskType === TASK_TYPES.plot) {
        return (
            <PlotDefinitionBlock
                definition={taskChangeableData?.definition}
                handleInputChange={handleInputChange}
                fieldsErrorMessages={fieldsErrorMessages}
                setUserInputs={setUserInputs}
            />
        );
    }

    return (
        <ComingSoonBlock
            taskId={taskId}
            taskType={taskType}
            text="Coming soon!"
            webhookUrl={webhookUrl}
            setWebhookUrl={setWebhookUrl}
        />
    );
};

export default TaskPlaygroundLeftPanel;
