import { mutate } from 'swr';

export const mutateJobsIndexPageDataAfterArchivingJob = (requestKeysToMutate, archivedJobId) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            mutate(
                key,
                (currentData) => {
                    if (!currentData) {
                        return;
                    }
                    const isArchivedJobInList = currentData.results?.some(
                        (job) => job.id === archivedJobId
                    );
                    if (!isArchivedJobInList) {
                        return currentData;
                    }

                    const updatedResults = currentData.results?.filter(
                        (job) => job.id !== archivedJobId
                    );

                    return {
                        ...currentData,
                        results: updatedResults,
                        count: currentData.count - 1,
                    };
                },
                { revalidate: true }
            );
        });
    }
};

export const mutateJobsIndexPageDataAfterRenamingJob = (
    requestKeysToMutate,
    renamedJobId,
    newJobName
) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            mutate(
                key,
                (currentData) => {
                    if (!currentData) {
                        return;
                    }
                    const updatedResults = currentData.results?.map((job) =>
                        job.id !== renamedJobId ? job : { ...job, name: newJobName }
                    );

                    return { ...currentData, results: updatedResults };
                },
                { revalidate: false }
            );
        });
    }
};

export const mutateJobsDataAfterRestartingJob = (requestKeysToMutate, jobId, status) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            mutate(
                key,
                (currentData) => {
                    if (!currentData) {
                        return;
                    }
                    const updatedResults = currentData.results?.map((job) =>
                        job.id !== jobId ? job : { ...job, status }
                    );

                    return { ...currentData, results: updatedResults };
                },
                { revalidate: false }
            );
        });
    }
};
