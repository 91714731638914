import React from 'react';

const ImageAddLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                d="M21 15V18H24V20H21V23H19V20H16V18H19V15H21ZM21.0082 3C21.556 3 22 3.44495 22 3.9934V13H20V5H4V18.999L14 9L17 12V14.829L14 11.8284L6.827 19H14V21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082ZM8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ImageAddLineIcon;
