import { useCallback, useEffect, useRef } from 'react';

import { useCellValue, usePublisher } from '@mdxeditor/gurx';
import { $createParagraphNode, $getNodeByKey } from 'lexical';
import { activeEditor$, editorInFocus$, useCodeBlockEditorContext } from '@mdxeditor/editor';

export function useCodeMirrorRef(nodeKey, editorType, language, focusEmitter) {
    const activeEditor = useCellValue(activeEditor$);
    const setEditorInFocus = usePublisher(editorInFocus$);
    const codeMirrorRef = useRef(null);
    const { lexicalNode } = useCodeBlockEditorContext();

    // these flags escape the editor with arrows.
    // they are set to true when the cursor is at the top or bottom of the editor, and then the user presses the arrow.
    const atBottom = useRef(false);
    const atTop = useRef(false);

    const onFocusHandler = useCallback(() => {
        setEditorInFocus({
            editorType,
            rootNode: lexicalNode,
        });
    }, [editorType, lexicalNode, setEditorInFocus]);

    const onKeyDownHandler = useCallback(
        (e) => {
            if (e.key === 'ArrowDown') {
                const state = codeMirrorRef.current?.getCodemirror()?.state;
                if (state) {
                    const docLength = state.doc.length;
                    const selectionEnd = state.selection.ranges[0].to;

                    if (docLength === selectionEnd) {
                        // escaping once
                        if (!atBottom.current) {
                            atBottom.current = true;
                        } else {
                            // escaping twice
                            activeEditor?.update(() => {
                                const node = $getNodeByKey(nodeKey);
                                const nextSibling = node.getNextSibling();
                                if (nextSibling) {
                                    codeMirrorRef.current?.getCodemirror()?.contentDOM.blur();
                                    node.selectNext();
                                } else {
                                    node.insertAfter($createParagraphNode());
                                }
                            });
                            atBottom.current = false;
                        }
                    }
                }
            } else if (e.key === 'ArrowUp') {
                const state = codeMirrorRef.current?.getCodemirror()?.state;
                if (state) {
                    const selectionStart = state.selection.ranges[0].from;

                    if (selectionStart === 0) {
                        // escaping once
                        if (!atTop.current) {
                            atTop.current = true;
                        } else {
                            // escaping twice
                            activeEditor?.update(() => {
                                const node = $getNodeByKey(nodeKey);
                                const previousSibling = node.getPreviousSibling();
                                if (previousSibling) {
                                    codeMirrorRef.current?.getCodemirror()?.contentDOM.blur();
                                    node.selectPrevious();
                                } else {
                                }
                            });
                            atTop.current = false;
                        }
                    }
                }
            } else if (e.key === 'Enter') {
                e.stopPropagation();
            } else if (e.key === 'Backspace' || e.key === 'Delete') {
                const state = codeMirrorRef.current?.getCodemirror()?.state;
                const docLength = state?.doc.length;
                if (docLength === 0) {
                    activeEditor?.update(() => {
                        const node = $getNodeByKey(nodeKey);
                        node.remove();
                    });
                }
            }
        },
        [activeEditor, nodeKey]
    );

    useEffect(() => {
        const codeMirror = codeMirrorRef.current;
        setTimeout(() => {
            codeMirror?.getCodemirror()?.contentDOM.addEventListener('focus', onFocusHandler);
            codeMirror?.getCodemirror()?.contentDOM.addEventListener('keydown', onKeyDownHandler);
        }, 300);

        return () => {
            codeMirror?.getCodemirror()?.contentDOM.removeEventListener('focus', onFocusHandler);
            codeMirror
                ?.getCodemirror()
                ?.contentDOM.removeEventListener('keydown', onKeyDownHandler);
        };
    }, [codeMirrorRef, onFocusHandler, onKeyDownHandler, language]);

    useEffect(() => {
        focusEmitter.subscribe(() => {
            codeMirrorRef.current?.getCodemirror()?.focus();
            onFocusHandler();
        });
    }, [focusEmitter, codeMirrorRef, nodeKey, onFocusHandler]);

    return codeMirrorRef;
}
