import React from 'react';

import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import { Button } from '../../../../design-system';

const ConfirmNavigationModalFromDocDetail = ({
    onConfirm,
    onCancel,
    text = 'You have unsaved changes, are you sure you’d like to continue? This will discard your unsaved changes.',
    confirmButtonText = 'Yes, Discard',
    cancelButtonText = 'Cancel',
}) => {
    return (
        <Modal onClose={onCancel} size="medium" resetPadding>
            <ModalHeader onClose={onCancel} title="Unsaved Changes" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                <p className="font-body text-body-regular-m text-neutral-500">{text}</p>

                <div className="flex items-center justify-between gap-4">
                    <Button size="md" type="neutral" text={cancelButtonText} onClick={onCancel} />
                    <Button
                        size="md"
                        type="secondary"
                        text={confirmButtonText}
                        onClick={onConfirm}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmNavigationModalFromDocDetail;
