import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import assistantClient from '../../../services/assistant-api';
import reportClient from '../../../services/report-api';

import { defaultErrorMessage } from '../../../constants/errorMessages';
import { capitalizeFirstLetter } from '../../../services/strings';

import ConfirmDeletionModalPattern from '../../../design-system/ConfirmDeletionModalPattern/ConfirmDeletionModalPattern';
import { HISTORY_TAB } from '../../../constants/assistant';

const DeleteHistoryItemConfirmationModal = ({
    onClose,
    itemType = 'chat',
    itemId,
    itemName,
    setItems,
}) => {
    const { chatThreadId: currentViewingChatThreadId, jobId: currentViewingJobId } = useParams();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('tab') || HISTORY_TAB.chats;

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleDelete = async () => {
        try {
            setIsLoading(true);

            const client = {
                chat: assistantClient,
                job: reportClient,
            };
            const route = {
                chat: `${API.ROUTES.assistant.chatThread}${itemId}/`,
                job: `${API.ROUTES.report.job}${itemId}/`,
            };

            await client[itemType].delete(route[itemType]);
            setItems((items) => items.filter((item) => item.id !== itemId));

            const isDeletedItemOpenedInPanel =
                currentViewingChatThreadId === itemId || currentViewingJobId === itemId;
            if (isDeletedItemOpenedInPanel) {
                navigate(`/assistant?tab=${activeTab}`);
            }

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    return (
        <ConfirmDeletionModalPattern
            onClose={onClose}
            title={`Confirm Archive ${capitalizeFirstLetter(itemType)}`}
            confirmationTextStart={`Are you sure you want to archive`}
            confirmationTextEnd={`${itemType}?`}
            entityName={itemName}
            handleDelete={handleDelete}
            isLoading={isLoading}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
            buttonsTheme="dark"
            customConfirmButtonText="Yes, Archive"
        />
    );
};

export default DeleteHistoryItemConfirmationModal;
