import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../design-system/Select/Select';
import Checkbox from '../../design-system/Checkbox/Checkbox';
import { Input, TextArea } from '../../design-system';

ProcessForm.propTypes = {
    formData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            options: PropTypes.object,
            type: PropTypes.oneOf(['string', 'integer', 'array', 'object']).isRequired,
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            is_required: PropTypes.bool.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
                .isRequired,
            state: PropTypes.oneOf(['default', 'error', 'disabled']).isRequired,
        })
    ).isRequired,
    setFormData: PropTypes.func,
};

function ProcessForm({ formData, setFormData }) {
    const handleInputChange = (id, event) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.id === id && !item.disabled
                    ? { ...item, value: event.target.value, state: 'default' }
                    : item
            )
        );
    };

    const handleSelectChange = (id, value) => {
        setFormData((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, state: 'default', value } : item))
        );
    };
    const handleCheckboxChange = (id, option) => {
        setFormData((prevData) =>
            prevData.map((item) => {
                if (item.id === id) {
                    const newValue = item.value?.includes(option)
                        ? item.value?.filter((el) => el !== option)
                        : [...(item.value || []), option];
                    return { ...item, value: newValue, state: 'default' };
                } else {
                    return item;
                }
            })
        );
    };
    const getOptionsForSelect = (object) => {
        return Object.values(object)?.map((item) => ({ name: item, id: item }));
    };

    return (
        <>
            {!!formData?.length && (
                <div className="flex flex-col gap-5">
                    {formData.map(
                        ({ options, id, type, key, label, is_required, value, state }) => (
                            <React.Fragment key={id}>
                                {options && (
                                    <>
                                        {(type === 'string' || type === 'integer') && (
                                            <Select
                                                name={key}
                                                label={label}
                                                isRequired={is_required}
                                                size="md"
                                                value={value}
                                                options={getOptionsForSelect(options)}
                                                onChange={(value) => handleSelectChange(id, value)}
                                                state={state === 'error' ? 'error' : 'default'}
                                                errorMessage="Please select an option"
                                            />
                                        )}
                                        {(type === 'array' || type === 'object') && (
                                            <div>
                                                <p className="font-body text-body-bold-s text-neutral-500 mb-1.5">
                                                    {label}
                                                    {is_required && (
                                                        <sup className="text-red-500 font-small leading-1 font-medium ml-0.5">
                                                            *
                                                        </sup>
                                                    )}
                                                </p>
                                                <ul className="flex flex-col gap-1">
                                                    {Object.values(options)?.map(
                                                        (option, index) => (
                                                            <li key={index} className="py-1 px-2">
                                                                <Checkbox
                                                                    isChecked={value?.includes(
                                                                        option
                                                                    )}
                                                                    name={option}
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            id,
                                                                            option
                                                                        )
                                                                    }
                                                                    label={option}
                                                                />
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </>
                                )}
                                {!options && (
                                    <>
                                        {type === 'string' && (
                                            <TextArea
                                                name={key}
                                                label={label}
                                                isRequired={is_required}
                                                value={value}
                                                placeholder={`Add ${label} here`}
                                                height="76px"
                                                onChange={(event) => handleInputChange(id, event)}
                                                state={state || 'default'}
                                                errorMessage="Please fill in this field"
                                            />
                                        )}
                                        {type === 'integer' && (
                                            <Input
                                                name={key}
                                                label={label}
                                                isRequired={is_required}
                                                size="md"
                                                value={value}
                                                placeholder={`Add ${label} here`}
                                                type="number"
                                                onChange={(event) => handleInputChange(id, event)}
                                                state={state || 'default'}
                                                errorMessage="Please fill in this field"
                                            />
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        )
                    )}
                </div>
            )}
        </>
    );
}

export default ProcessForm;
