import React from 'react';

const LayersIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <g>
                <path
                    d="M1.33398 11.3334L8.00065 14.6667L14.6673 11.3334"
                    stroke={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.33398 11.3334L8.00065 14.6667L14.6673 11.3334"
                    stroke={props.color}
                    strokeOpacity="0.2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.33398 8L8.00065 11.3333L14.6673 8"
                    stroke={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.33398 8L8.00065 11.3333L14.6673 8"
                    stroke={props.color}
                    strokeOpacity="0.2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.00065 1.33337L1.33398 4.66671L8.00065 8.00004L14.6673 4.66671L8.00065 1.33337Z"
                    stroke={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default LayersIcon;
