import React, { useState } from 'react';
import { defaultErrorMessage } from '../../constants/errorMessages';

import { Badge, ButtonIcon, Input } from '../index';
import { AddLineIcon, ErrorWarningLineIcon } from '../Icons';
import Alert from '../Alert/Alert';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon/SvgIcon';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

OptionsInput.propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    options: PropTypes.arrayOf(PropTypes.string),
    errorMessage: PropTypes.string,
    handleAddNewOption: PropTypes.func,
    handleRemoveOption: PropTypes.func,
    tipText: PropTypes.string,
    placeholder: PropTypes.string,
    containerGap: PropTypes.number,
    optionsBadgeColor: PropTypes.oneOf([
        'neutral',
        'blue',
        'purple',
        'peach',
        'lime',
        'white',
        'red',
    ]),
    optionsBadgeFontStyle: PropTypes.string,
};

function OptionsInput({
    label,
    isRequired = false,
    state = 'default',
    errorMessage = 'Please add an Option',
    options = [],
    handleAddNewOption,
    handleRemoveOption,
    tipText,
    placeholder = 'Add your option values and click + to add them',
    containerGap = 20,
    optionsBadgeColor = 'peach',
    optionsBadgeFontStyle = 'text-body-regular-m',
}) {
    const [inputValue, setInputValue] = useState('');

    const [errorAlert, setErrorAlert] = useState({
        isShown: false,
        message: '',
    });

    const addNewOption = () => {
        const newOption = inputValue.trim();
        if (!newOption) {
            return;
        }
        const alreadyExist = options.some((option) => newOption === option);
        if (alreadyExist) {
            setErrorAlert({
                isShown: true,
                message: 'Such Option already exists',
            });
            return;
        }
        handleAddNewOption(newOption);
        setInputValue('');
    };

    return (
        <div className="flex flex-col" style={{ gap: `${containerGap}px` }}>
            <div className="flex flex-col gap-1">
                <div className="flex gap-2 items-end">
                    <Input
                        size="sm"
                        name="optionLabel"
                        value={inputValue}
                        label={label}
                        state={state}
                        placeholder={placeholder}
                        isRequired={isRequired}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <ButtonIcon
                        type="ghost"
                        size="md"
                        state={state}
                        icon={AddLineIcon}
                        onClick={addNewOption}
                    />
                </div>

                {state === 'error' && errorMessage && <ErrorMessage message={errorMessage} />}

                {tipText && (state !== 'error' || !errorMessage) && (
                    <div className="flex items-center gap-1">
                        <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                        <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                    </div>
                )}
            </div>

            {!!options.length && (
                <div className="flex flex-wrap gap-x-2 gap-y-1">
                    {options.map((option) => (
                        <div key={option}>
                            <Badge
                                text={
                                    <span className={`p-0.5 ${optionsBadgeFontStyle}`}>
                                        {option}
                                    </span>
                                }
                                color={optionsBadgeColor}
                                removeable
                                id={option}
                                handleRemove={handleRemoveOption}
                            />
                        </div>
                    ))}
                </div>
            )}

            {errorAlert.isShown && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={2000}
                    handleClose={() => setErrorAlert({ isShown: false })}
                />
            )}
        </div>
    );
}

export default OptionsInput;
