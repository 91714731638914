import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/user-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import useUser from '../../../hooks/useUser';

import Alert from '../../../design-system/Alert/Alert';
import Input from '../../../design-system/Input/Input';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import InputWithAutoSaveOnBlur from '../../../design-system/InputWithAutoSaveOnBlur/InputWithAutoSaveOnBlur';
import UserProfilePictureBlock from './UserProfilePictureBlock/UserProfilePictureBlock';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const UserSettingsBlock = () => {
    const navigate = useNavigate();

    const { user, mutateUser } = useUser();

    const [userData, setUserData] = useState({
        first_name: user.first_name,
        last_name: user.last_name,
        profile_image: user.settings?.profile_image,
    });
    const [errorAlert, setErrorAlert] = useState({ show: false, message: '', statusCode: null });

    const handleInputChange = (e) => {
        setUserData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

    const handleSave = async (name) => {
        try {
            const requestBody = { [name]: userData[name] };
            const { data } = await client.patch(`${API.ROUTES.user.userDetails}`, requestBody);

            await mutateUser((user) => ({ ...user, [name]: data[name] }), { revalidate: true });
        } catch (e) {
            setErrorAlert({
                show: true,
                message: defaultErrorMessage,
                statusCode: e.response.status,
            });
            setUserData((prevData) => ({ ...prevData, [name]: user[name] }));
            throw new Error(e);
        }
    };

    return (
        <SectionWrapper title="Account Details" customStyles="mb-3">
            <div className="flex flex-col gap-y-5 min-[520px]:flex-row">
                <div className="flex flex-col gap-5 w-full max-w-[444px]">
                    <InputWithAutoSaveOnBlur
                        size="xs"
                        name="first_name"
                        label="First Name"
                        value={userData.first_name}
                        originalValue={user.first_name}
                        placeholder="Not yet Entered"
                        handleInputChange={handleInputChange}
                        handleSave={() => handleSave('first_name')}
                    />

                    <InputWithAutoSaveOnBlur
                        size="xs"
                        name="last_name"
                        label="Last name"
                        value={userData.last_name}
                        originalValue={user.last_name}
                        placeholder="Not yet Entered"
                        handleInputChange={handleInputChange}
                        handleSave={() => handleSave('last_name')}
                    />

                    <div className="w-[90%]">
                        <Input
                            label="Email Address"
                            value={user.email}
                            state="disabled"
                            size="xs"
                        />
                    </div>
                    <div className="w-full max-w-[400px]">
                        <Button
                            type="primary"
                            text="Reset password"
                            size="sm"
                            onClick={() => navigate('/password-reset')}
                        />
                    </div>
                </div>

                <div className="pr-2 md:pr-0">
                    <UserProfilePictureBlock
                        user={user}
                        userData={userData}
                        setUserData={setUserData}
                    />
                </div>
            </div>

            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                    autoCloseInMS={3000}
                />
            )}
        </SectionWrapper>
    );
};

export default UserSettingsBlock;
