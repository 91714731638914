import React, { useEffect, useState } from 'react';

import { useFormState } from '../../../../hooks/useFormState';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Alert from '../../../Alert/Alert';
import TextArea from '../../../TextArea/TextArea';
import { Button, Input } from '../../../index';
import { ErrorWarningLineIcon } from '../../../Icons';

const PromptForm = ({ mode = 'create', data, isDisabled, onCancel, onSubmit }) => {
    const { name, description, prompt } = data || {};

    const { formData, handleInputChange } = useFormState(
        mode === 'create'
            ? { name: '', description: '', prompt: '' }
            : { name, description, prompt }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useEffect(() => {
        if (mode === 'edit') {
            const areChanges =
                name !== formData.name ||
                description !== formData.description ||
                prompt !== formData.prompt;
            if (areChanges !== hasUnsavedChanges) {
                setHasUnsavedChanges(areChanges);
            }
        }
    }, [formData]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const { name, description, prompt } = formData;
            const requestBody = {
                name,
                description,
                messages: [
                    {
                        role: 'user',
                        content: prompt,
                    },
                ],
            };

            await onSubmit(requestBody);
            if (mode === 'edit') {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.statusCode });
        }
    };

    const formState = isDisabled ? 'disabled' : 'default';

    const allFieldsAreFilled = formData.name && formData.description && formData.prompt;
    const submitButtonState = isLoading
        ? 'loading'
        : allFieldsAreFilled && !isDisabled && (mode === 'create' || hasUnsavedChanges)
        ? 'default'
        : 'disabled';

    const submitButtonText = {
        create: 'Create Prompt',
        edit: 'Save Prompt',
    };

    return (
        <>
            <div className="flex flex-col gap-2 px-5 py-4 bg-neutral-50/50">
                <Input
                    size="xs"
                    name="name"
                    value={formData.name}
                    label="Prompt Name"
                    isRequired
                    state={formState}
                    placeholder="Enter a prompt name"
                    onChange={(e) => handleInputChange('name', e.target.value)}
                />

                <TextArea
                    name="description"
                    value={formData.description}
                    label="Prompt Description"
                    isRequired
                    state={formState}
                    placeholder="Enter your prompt description"
                    height="108px"
                    onChange={(e) => handleInputChange('description', e.target.value)}
                />

                <TextArea
                    name="prompt"
                    value={formData.prompt}
                    label="Prompt"
                    isRequired
                    state={formState}
                    placeholder="Enter your prompt here"
                    height="258px"
                    onChange={(e) => handleInputChange('prompt', e.target.value)}
                />
            </div>
            <div className="px-4 py-2.5 flex items-center gap-2 justify-between border-t-1 border-neutral-200">
                <Button
                    type="neutral"
                    size="sm"
                    text="Cancel"
                    state={isDisabled ? 'disabled' : 'default'}
                    onClick={onCancel}
                />
                <Button
                    type="primary"
                    size="sm"
                    text={submitButtonText[mode]}
                    state={submitButtonState}
                    onClick={handleSubmit}
                />
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default PromptForm;
