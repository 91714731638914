import React, { useState } from 'react';
import { API } from 'constants';
import client from '../../../../services/library-api';

import { TASK_TYPES } from '../../../../constants/library';
import CollapsableContainer from '../../../../components/CollapsableContainer/CollapsableContainer';
import TaskInputsContainer from '../../../../components/TaskInputsContainer/TaskInputsContainer';
import Alert from '../../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import { updateRequiredInputs } from '../../../../helpers/determineInputs';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

const RequiredInputsBlock = ({
    requiredInputs,
    setRequiredInputs,
    hasEditAccess,
    taskType,
    taskId,
    isExpanded,
    toggleCollapsed,
}) => {
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const handleRefresh = async () => {
        try {
            setRefreshLoading(true);
            const { data } = await client.patch(`${API.ROUTES.library.task}${taskId}/`, {
                reload_inputs: true,
            });
            const { func_def, inputs } = data;
            setRequiredInputs(updateRequiredInputs(func_def, inputs || [], requiredInputs));
            setRefreshLoading(false);
        } catch (e) {
            setErrorAlert({ show: true, statusCode: e.response.status });
            setRefreshLoading(false);
        }
    };

    return (
        <CollapsableContainer
            title="Required Inputs"
            isExpanded={isExpanded}
            toggleExpand={toggleCollapsed}
            withRefreshIcon={taskType === TASK_TYPES.record}
            handleRefresh={taskType === TASK_TYPES.record ? handleRefresh : () => {}}
            refreshLoading={refreshLoading}
        >
            {!!requiredInputs?.length ? (
                <TaskInputsContainer
                    inputsData={requiredInputs}
                    setInputsData={setRequiredInputs}
                    withCopyButton
                    hasEditAccess={hasEditAccess}
                />
            ) : (
                <p className="font-body text-body-regular-s text-neutral-500 italic mb-[-8px]">
                    None
                </p>
            )}
            {errorAlert.show && (
                <Alert
                    status="critical"
                    icon={ErrorWarningLineIcon}
                    message={defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </CollapsableContainer>
    );
};

export default RequiredInputsBlock;
