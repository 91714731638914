import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import templateClient from '../../services/template-api';
import libraryClient from '../../services/library-api';

import { capitalizeFirstLetter } from '../../services/strings';
import { useWindowSize } from '../../hooks/useWindowSize';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import Loading from '../../components/Loading';
import PageHeader from './PageHeader/PageHeader';
import ProcessSetupOrUpgradeIndex from './ProcessSetupOrUpgradeIndex/ProcessSetupOrUpgradeIndex';

const ProcessSetupOrUpgradePage = () => {
    const { versionId, processId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    // if pageAction == "upgrade" ProcessUpgradePage is renders, else ProcessSetupPage
    const pageAction = location.pathname.includes('upgrade') ? 'upgrade' : 'setup';

    const [templateVersionData, setTemplateVersionData] = useState(null);
    const [processData, setProcessData] = useState(null);

    const { width: screenWidth } = useWindowSize();

    const [pageHeaderHeight, setPageHeaderHeight] = useState(0);
    const pageContentHeight =
        screenWidth >= 640
            ? `calc(100% - ${pageHeaderHeight}px)`
            : `calc(100% - ${pageHeaderHeight + 60}px)`; // height of the page without header

    const defaultBackLinkHref = {
        setup: '/discover',
        upgrade: `/agent/${processId}`,
    };
    const backLinkHref = location.state?.from ?? defaultBackLinkHref[pageAction];

    useEffect(() => {
        const fetchDataForSetup = async () => {
            try {
                const { data } = await templateClient.get(
                    `${API.ROUTES.template.processTemplateVersion}${versionId}/deploy/`
                );
                setTemplateVersionData(data);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        const fetchDataForUpgrade = async () => {
            try {
                const [{ data: processData }, { data: versionData }] = await Promise.all([
                    libraryClient.get(`${API.ROUTES.library.clientProcess}${processId}/`),
                    templateClient.get(
                        `${API.ROUTES.template.processTemplateVersion}${versionId}/deploy/?process=${processId}`
                    ),
                ]);
                setProcessData(processData);
                setTemplateVersionData(versionData);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        if (pageAction === 'setup') {
            fetchDataForSetup();
        }
        if (pageAction === 'upgrade') {
            fetchDataForUpgrade();
        }
    }, []);

    useDocumentTitle(`Process ${capitalizeFirstLetter(pageAction)}`);

    const pageTitle = {
        setup: templateVersionData?.process_template_name,
        upgrade: `Upgrading ${processData?.name} to Version ${templateVersionData?.version_name}`,
    };

    return (
        <div className="page-position flex flex-col">
            {templateVersionData && (
                <>
                    <PageHeader
                        pageAction={pageAction}
                        pageTitle={pageTitle}
                        backLinkHref={backLinkHref}
                        setPageHeaderHeight={setPageHeaderHeight}
                    />
                    <div
                        className="flex-grow flex"
                        style={{
                            height: pageContentHeight,
                        }}
                    >
                        <ProcessSetupOrUpgradeIndex
                            pageAction={pageAction}
                            settings={templateVersionData.settings}
                            processTemplateName={templateVersionData.process_template_name}
                        />
                    </div>
                </>
            )}
            {!templateVersionData && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ProcessSetupOrUpgradePage;
