import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TEMPLATE_TYPES } from '../../../../constants/template';
import { Button, ButtonIcon } from '../../../../design-system';
import { ArrowGoBackLineIcon, More2FillIcon } from '../../../../design-system/Icons';
import TemplateMoreOptionPopup from '../TemplateMoreOptionPopup/TemplateMoreOptionPopup';
import Alert from '../../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';

const PageHeader = ({
    templateType,
    templateData,
    backLinkHref,
    setHeaderHeight,
    setConfirmTemplateDeletionModalOpened,
}) => {
    const navigate = useNavigate();
    const headerRef = useRef(null);

    const [moreOptionsPopupShown, setMoreOptionsPopupShown] = useState(false);
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.clientHeight);
        }
    }, [headerRef]);

    return (
        <div
            className="px-6 pt-5 pb-[10px] bg-white border-b-1 border-neutral-200 flex items-center justify-between gap-x-2 gap-y-0"
            ref={headerRef}
        >
            <div className="flex items-center gap-5">
                <Button
                    type="link"
                    size="xs"
                    text="Back"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(backLinkHref)}
                />
                <p className="font-body text-body-regular-s text-neutral-300 whitespace-nowrap">
                    Templates / {templateType === TEMPLATE_TYPES.goal ? 'Goals' : 'Processes'}
                </p>
            </div>
            <div className="relative">
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={More2FillIcon}
                    onClick={() => setMoreOptionsPopupShown(true)}
                />
                {moreOptionsPopupShown && (
                    <TemplateMoreOptionPopup
                        templateType={templateType}
                        templateData={templateData}
                        setAlert={setAlert}
                        openConfirmDeletionModal={() => {
                            setMoreOptionsPopupShown(false);
                            setConfirmTemplateDeletionModalOpened(true);
                        }}
                        onClose={() => setMoreOptionsPopupShown(false)}
                    />
                )}
            </div>
            {alert && (
                <Alert
                    status={alert.status || 'positive'}
                    message={alert.message}
                    icon={alert.icon || CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setAlert(null)}
                />
            )}
        </div>
    );
};

export default PageHeader;
