import { useLayoutEffect, useState } from 'react';
import { mergeNewDataIntoCurrent } from '../helpers/mergeNewDataIntoCurrent';

export const useCollectListDataForMobileViews = ({
    data,
    shouldSetNewData,
    setShouldSetNewData,
}) => {
    const [collectedData, setCollectedData] = useState([]);

    useLayoutEffect(() => {
        setCollectedData((prevResults) => {
            if (data === null && !shouldSetNewData) {
                return prevResults;
            }

            // clear collected Data when user submit search in SearchBar, or change sorting / filters and new data is loading
            if (shouldSetNewData && data === null) {
                return [];
            }

            // when user submit search in Search Bar, or change sorting / filters, we don't have to merge new Data into current, but we should replace them
            if (shouldSetNewData && data !== null) {
                setShouldSetNewData(false);
                return data;
            }

            return mergeNewDataIntoCurrent(prevResults || [], data || []); // when user click show more button
        });
    }, [data]);

    return [collectedData, setCollectedData];
};
