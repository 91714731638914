import React from 'react';
import { useEffect } from 'react'; // Updated import
import { CheckboxCircleLineIcon } from '../../design-system/Icons'; // Updated icon import
import { Button } from '../../design-system';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import SideBar from '../../components/SideBar/SideBar';

const UpgradeSuccessPage = () => { // Updated component name
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location
    const { user, isValidating } = useUser();

    const shouldRedirect = !isValidating && !user;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');

        if (status === 'error') {
            navigate('/profile')
        }
    }, [location]);

    if (shouldRedirect) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <SideBar />
            <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 px-5 py-6 sm:py-[28px] sm:px-0">
                <div className="py-[80px] flex flex-col items-center">
                    <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-lime-500 rounded-full mb-4"> 
                        <CheckboxCircleLineIcon width={32} height={32} color="#5E6470" /> 
                    </div>
                    <p className="font-body text-regular-s text-success-500 uppercase text-center mb-1"> 
                        SUCCESS
                    </p>
                    <p className="font-heading-bold text-heading-bold-m text-black text-center min-[350px]:w-[85%] max-w-[400px] mb-2">
                        You upgraded to a Pro account!
                    </p>
                    <p className="font-body text-regular-m text-black text-center min-[350px]:w-[85%] max-w-[340px] mb-6">
                        Set up your organization and invite members to enjoy our Team features 
                    </p>
                    <Button
                        type="secondary"
                        size="md"
                        text="Set up your Organization"
                        onClick={() => navigate('/organization')}
                    />
                </div>
            </div>
        </>
    );
};

export default UpgradeSuccessPage; // Updated component export
