import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useViewType } from '../../../hooks/useViewType';

import { ListOption, ListOptionGroup } from '../../../design-system';

const ThreadMoreOptionsDropdown = ({
    onClose,
    threadStatus,
    setThreadEditModal,
    setRestartSidePanel,
    setCancelConfirmationModal,
    setIsConfirmStatusChangeModalOpened,
    setFeedbackPanelOpened,
    setIsReloadReportModalOpened,
}) => {
    const navigate = useNavigate();
    const { toggleViewType } = useViewType();

    const { threadId } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const withArchivedWorkers = Boolean(searchParams.get('archived'));

    const handleEditName = () => {
        setThreadEditModal({ open: true });
        onClose();
    };

    const handleRestart = () => {
        setRestartSidePanel({ restartTarget: 'thread', opened: true });
        onClose();
    };

    const handleCancelThread = () => {
        setCancelConfirmationModal({ opened: true, cancelTarget: 'thread' });
        onClose();
    };

    const handleReloadReport = () => {
        setIsReloadReportModalOpened(true);
        onClose();
    };

    const handleMarkAsComplete = () => {
        setIsConfirmStatusChangeModalOpened(true);
        onClose();
    };

    const showArchivedWorkers = () => {
        setFeedbackPanelOpened(false);
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.append('archived', 'True');
        setSearchParams(updatedSearchParams);
        onClose();
    };

    const hideArchivedWorkers = () => {
        setFeedbackPanelOpened(false);
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('archived');
        setSearchParams(updatedSearchParams);
        onClose();
    };

    const navigateToJobDetailPage = () => {
        toggleViewType();
        navigate(`/job/${threadId}`);
    };

    const isViewJobDetailButtonDisplayed =
        threadStatus !== 'canceled' && threadStatus !== 'archived';

    const isThreadCompleted = threadStatus === 'success';

    return (
        <div className="absolute top-[34px] right-[4px] z-55">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editIcon"
                            text="Edit Name"
                            onClick={handleEditName}
                        />
                        {withArchivedWorkers ? (
                            <ListOption
                                leadingIconName="eyeOffIcon"
                                text="Hide Archived Workers"
                                onClick={hideArchivedWorkers}
                            />
                        ) : (
                            <ListOption
                                leadingIconName="eyeIcon"
                                text="Show Archived Workers"
                                onClick={showArchivedWorkers}
                            />
                        )}
                        <ListOption
                            leadingIconName="refreshCcwIcon"
                            text="Reload Report"
                            onClick={handleReloadReport}
                        />
                        <ListOption
                            leadingIconName="skipBackIcon"
                            text="Restart Thread"
                            onClick={handleRestart}
                        />
                        <ListOption
                            leadingIconName="squareIcon"
                            text="Cancel Thread"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={handleCancelThread}
                        />
                        {isViewJobDetailButtonDisplayed && (
                            <ListOption
                                leadingIconName="playCircleIcon"
                                text="View Job Details"
                                onClick={navigateToJobDetailPage}
                            />
                        )}
                        {!isThreadCompleted && (
                            <ListOption
                                leadingIconName="checkboxLineIcon"
                                text="Mark as Complete"
                                onClick={handleMarkAsComplete}
                            />
                        )}
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default ThreadMoreOptionsDropdown;
