import React from 'react';

import MessageDetailPanel from '../MessageDetailPanel/MessageDetailPanel';
import Loading from '../../../components/Loading';
import EmptyView from '../EmptyView/EmptyView';
import InboxMessageIndexPanel from '../InboxMessageIndexPanel/InboxMessageIndexPanel';
import InboxSidebar from '../InboxSidebar/InboxSidebar';

const InboxIndexPage = ({
    data,
    setData,
    loading,
    selectedMessage,
    selectedMessageIndex,
    setSelectedMessage,
    areReadMessagesShown,
    toggleShownMessagesReadState,
    selectedFilterOptions,
    setSelectedFilterOptions,
    selectedSortOption,
    setSelectedSortOption,
    searchQuery,
    setSearchQuery,
    searchQueryInputValue,
    setSearchQueryInputValue,
    messageContentFormData,
    setMessageContentFormData,
    messageContentBodyTypeFormFieldsCount,
    onMessageStatusTabChanged,
    shouldSetNewData,
    setShouldSetNewData,
    requestKeysToMutate,
    setPage,
    hasNextPage,
    statusTab,
    isStatusTabIndexChanging,
    handlePrevClick,
    handleNextClick,
    showTaskApprovedSnackAlert,
}) => {
    const resetCurrentData = () => {
        setShouldSetNewData(true);
        setPage(1);
    };

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-neutral-50 overflow-auto min-[700px]:overflow-hidden flex flex-col min-[700px]:flex-row">
            <InboxSidebar
                selectedStatusTab={statusTab}
                onTabChanged={onMessageStatusTabChanged}
                areReadMessagesShown={areReadMessagesShown}
                toggleShownMessagesReadState={toggleShownMessagesReadState}
                selectedFilterOptions={selectedFilterOptions}
                setSelectedFilterOptions={setSelectedFilterOptions}
                resetCurrentData={resetCurrentData}
            />
            <div className="w-full min-[700px]:w-[calc(100%-230px)] min-[800px]:w-[calc(100%-260px)] flex flex-col min-[1160px]:flex-row relative">
                <InboxMessageIndexPanel
                    data={data}
                    setData={setData}
                    selectedSortOption={selectedSortOption}
                    setSelectedSortOption={setSelectedSortOption}
                    searchQueryInputValue={searchQueryInputValue}
                    setSearchQueryInputValue={setSearchQueryInputValue}
                    setSearchQuery={setSearchQuery}
                    selectedFilterOptions={selectedFilterOptions}
                    setPage={setPage}
                    shouldSetNewData={shouldSetNewData}
                    setShouldSetNewData={setShouldSetNewData}
                    selectedMessage={selectedMessage}
                    setSelectedMessage={setSelectedMessage}
                    isStatusTabIndexChanging={isStatusTabIndexChanging}
                    loading={loading}
                    hasNextPage={hasNextPage}
                    requestKeysToMutate={requestKeysToMutate}
                    setNextMessageAfterMessageApproval={handleNextClick}
                    resetCurrentData={resetCurrentData}
                />
                {selectedMessage && !!data?.length && (
                    <div className="hidden min-[1160px]:block h-auto lg:h-full bg-white w-full min-[1160px]:w-[56%] min-[1380px]:w-[calc(100%-500px)]">
                        <MessageDetailPanel
                            message={selectedMessage}
                            handlePrevClick={handlePrevClick}
                            handleNextClick={handleNextClick}
                            currentMessageIndex={selectedMessageIndex}
                            messagesLength={data?.length}
                            setData={setData}
                            requestKeysToMutate={requestKeysToMutate}
                            messageContentFormData={messageContentFormData}
                            setMessageContentFormData={setMessageContentFormData}
                            showTaskApprovedSnackAlert={showTaskApprovedSnackAlert}
                            messageContentBodyTypeFormFieldsCount={
                                messageContentBodyTypeFormFieldsCount
                            }
                        />
                    </div>
                )}

                {data?.length === 0 && !searchQuery && !selectedFilterOptions?.length && (
                    <>
                        {loading && (
                            <div className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                                <Loading />
                            </div>
                        )}
                        {!loading && (
                            <div className="absolute top-[120px] left-1/2 transform translate-x-[-50%]">
                                <EmptyView type="todo" />
                            </div>
                        )}
                    </>
                )}

                {isStatusTabIndexChanging && (
                    <div className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                        <Loading />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InboxIndexPage;
