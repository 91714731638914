import React from 'react';

const CompassIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <g>
                <path
                    d="M8.0026 14.6666C11.6845 14.6666 14.6693 11.6818 14.6693 7.99992C14.6693 4.31802 11.6845 1.33325 8.0026 1.33325C4.32071 1.33325 1.33594 4.31802 1.33594 7.99992C1.33594 11.6818 4.32071 14.6666 8.0026 14.6666Z"
                    fill="transparent"
                    stroke={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.8252 5.17334L9.41187 9.41334L5.17188 10.8267L6.58521 6.58667L10.8252 5.17334Z"
                    stroke={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3265_15673">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CompassIcon;
