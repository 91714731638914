import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'design-system/Button/Button';
import Dropdown from 'design-system/Dropdown/Dropdown';
import { iconNameMap } from 'design-system/Icons/GenericIcon';
import { useClickOutside } from 'hooks';

Button.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'neutral', 'ghost', 'link']).isRequired,
    size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']).isRequired,
    state: PropTypes.oneOf(['disabled', 'loading', 'default']),
    text: PropTypes.string.isRequired,
    leadingIcon: PropTypes.func,
    trailingIcon: PropTypes.element,
    helperIcon: PropTypes.element,
    onButtonPressed: PropTypes.func,
    onItemPressed: PropTypes.func,
    isSubmit: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            leadingIconName: PropTypes.oneOf(Object.keys(iconNameMap)),
            leadingSelectIcon: PropTypes.bool,
            hover: PropTypes.bool,
            trailingBadge: PropTypes.bool,
        })
    ),
};

const DropdownButton = ({ items, onButtonPressed, onItemPressed, ...otherProps }) => {
    const {
        type,
        size,
        text,
        onClick,
        leadingIcon,
        trailingIcon,
        helperIcon,
        state = 'default',
        isSubmit = false,
    } = otherProps;

    const buttonProps = {
        type,
        size,
        text,
        onClick,
        leadingIcon,
        trailingIcon,
        helperIcon,
        state,
        isSubmit,
    };
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef('dropdownMenu');
    useClickOutside(dropdownRef, () => {
        setShowDropdown(false);
    });

    const toggleDropdown = () => {
        setShowDropdown((show) => {
            onButtonPressed({ dropdownShown: !show });
            return !show;
        });
    };

    function onItemSelect(index, selected) {
        onItemPressed({ index, selected });
    }

    return (
        <div className="relative inline-block w-[361]" ref={dropdownRef}>
            <Button
                {...buttonProps}
                id="options-menu"
                onClick={toggleDropdown}
                text="Open Dropdown"
                type="secondary"
                size="lg"
            />
            <Dropdown showDropdown={showDropdown} onItemSelect={onItemSelect} items={items} />
        </div>
    );
};

export default DropdownButton;
