import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom';
import PageContainer from '../components/PageContainer/PageContainer';
import * as Pages from '../pages';
import PrivateRoute from './PrivateRoute';
import RestrictedRoute from './RestrictedRoute';
import PublicRoute from './PublicRoute';
import { VIEW_TYPES } from '../constants/viewTypes';
import { ORGANIZATION_PLAN, USER_ROLE } from '../constants/organization';

export default function router() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<PageContainer />}>
                <Route
                    index
                    element={
                        <PrivateRoute
                            component={<Pages.LibraryPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/email/confirm/:confirmationId"
                    element={
                        <RestrictedRoute component={<Pages.ConfirmEmail />} redirectTo="/library" />
                    }
                />
                <Route
                    path="/email/verify/"
                    element={
                        <RestrictedRoute component={<Pages.VerifyEmail />} redirectTo="/library" />
                    }
                />
                <Route
                    path="/email/verify/:email"
                    element={
                        <RestrictedRoute component={<Pages.VerifyEmail />} redirectTo="/library" />
                    }
                />
                <Route
                    path="/library"
                    element={
                        <PrivateRoute
                            component={<Pages.LibraryPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/library/shared"
                    element={
                        <PrivateRoute
                            component={<Pages.LibraryPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/library/prompt/:id"
                    element={
                        <PrivateRoute
                            component={<Pages.PromptViewPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/library/prompt/:id/view"
                    element={
                        <PrivateRoute
                            component={<Pages.ViewOnlyPromptPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/library/prompt/:id/history"
                    element={
                        <PrivateRoute
                            component={<Pages.TaskHistoryIndexPage />} // // history page for both prompt and non-prompt tasks
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/library/prompt/:promptId/run/:resultId"
                    element={
                        <PrivateRoute
                            component={<Pages.ViewHistoricalRunPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/library/prompt/:promptId/version/:versionId"
                    element={
                        <PrivateRoute
                            component={<Pages.VersionHistoryDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/library/task/:id"
                    element={
                        <PrivateRoute
                            component={<Pages.TaskPlaygroundPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/library/task/:id/history"
                    element={
                        <PrivateRoute
                            component={<Pages.TaskHistoryIndexPage />} // history page for both prompt and non-prompt tasks
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/library/task/:taskId/run/:resultId"
                    element={
                        <PrivateRoute
                            component={<Pages.ViewHistoricalRunPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/library/prompt/new"
                    element={
                        <PrivateRoute
                            component={<Pages.CreateNewPromptTaskPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/library/task/new"
                    element={
                        <PrivateRoute
                            component={<Pages.CreateNewTaskPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/login"
                    element={<RestrictedRoute component={<Pages.Login />} redirectTo="/library" />}
                />
                <Route
                    path="/logout"
                    element={
                        <PrivateRoute
                            component={<Pages.Logout />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <PrivateRoute
                            component={<Pages.SettingsAndOrganizationsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/settings/organization/:organizationId"
                    element={
                        <PrivateRoute
                            component={<Pages.SettingsAndOrganizationsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/register"
                    element={
                        <RestrictedRoute component={<Pages.Register />} redirectTo="/library" />
                    }
                />
                <Route
                    path="/password-reset"
                    element={
                        <PublicRoute
                            component={<Pages.ResetPassword />}
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/password-reset/confirm/:id/:confirmationId"
                    element={
                        <PublicRoute
                            component={<Pages.ResetPasswordConfirmation />}
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/search"
                    element={
                        <PrivateRoute
                            component={<Pages.SearchPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                        />
                    }
                />
                <Route
                    path="/bases"
                    element={
                        <PrivateRoute
                            component={<Pages.BasesPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/base/:baseId"
                    element={
                        <PrivateRoute
                            component={<Pages.BaseDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member],
                                },
                            ]}
                        />
                    }
                />
                {/*<Route*/}
                {/*    path="/base/new"*/}
                {/*    element={*/}
                {/*        <PrivateRoute*/}
                {/*            component={<Pages.BaseBuilderPage />}*/}
                {/*            redirectTo="/login"*/}
                {/*            availableViewTypes={[VIEW_TYPES.admin]}*/}
                {/*            needsSpecialPermission*/}
                {/*            allowedPlansAndRoles={[*/}
                {/*                {*/}
                {/*                    plan: ORGANIZATION_PLAN.agency,*/}
                {/*                    roles: [USER_ROLE.owner, USER_ROLE.admin],*/}
                {/*                },*/}
                {/*            ]}*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                <Route
                    path="/upgrade"
                    element={
                        <PrivateRoute
                            component={<Pages.UpgradeSuccessPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/inbox"
                    element={
                        <PrivateRoute
                            component={<Pages.InboxPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/configure"
                    element={
                        <PrivateRoute
                            component={<Pages.ConfigurePage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/configure/process/new"
                    element={
                        <PrivateRoute
                            component={<Pages.CreateNewProcessPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/configure/process/:processId"
                    element={
                        <PrivateRoute
                            component={<Pages.ProcessDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/configure/process/:processId/goal/new"
                    element={
                        <PrivateRoute
                            component={<Pages.GoalDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/configure/process/:processId/goal/:goalId"
                    element={
                        <PrivateRoute
                            component={<Pages.GoalDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/configure/process/:processId/goal/template/:goalTemplateId"
                    element={
                        <PrivateRoute
                            component={<Pages.CustomizeGoalPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/configure/scenario/:scenarioId"
                    element={
                        <PrivateRoute
                            component={<Pages.ScenarioDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/agent/:processId"
                    element={
                        <PrivateRoute
                            component={<Pages.ClientProcessDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/agent/:processId/start"
                    element={
                        <PrivateRoute
                            component={<Pages.StartNewJobPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/agent/:processId/upgrade/:versionId" // latestVersionId
                    element={
                        <PrivateRoute
                            component={<Pages.ProcessSetupOrUpgradePage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/scenario/:scenarioId"
                    element={
                        <PrivateRoute
                            component={<Pages.ClientScenarioDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/playbooks"
                    element={
                        <PrivateRoute
                            component={<Pages.PlaybooksIndexPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/playbook/:playbookId"
                    element={
                        <PrivateRoute
                            component={<Pages.PlaybookBuilderPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/playbook/:playbookId/start/:scenarioId"
                    element={
                        <PrivateRoute
                            component={<Pages.StartNewProjectPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/jobs"
                    element={
                        <PrivateRoute
                            component={<Pages.JobsAndProjectsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/job/:jobId"
                    element={
                        <PrivateRoute
                            component={<Pages.JobDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/projects"
                    element={
                        <PrivateRoute
                            component={<Pages.JobsAndProjectsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/project/:projectId"
                    element={
                        <PrivateRoute
                            component={<Pages.ProjectDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                {/*<Route*/}
                {/*    path="/discover"*/}
                {/*    element={*/}
                {/*        <PrivateRoute*/}
                {/*            component={<Pages.DiscoverPage />}*/}
                {/*            redirectTo="/login"*/}
                {/*            availableViewTypes={[VIEW_TYPES.client]}*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}
                <Route
                    path="/discover/agent/:processTemplateId/setup/:versionId"
                    element={
                        <PrivateRoute
                            component={<Pages.ProcessSetupOrUpgradePage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/threads"
                    element={
                        <PrivateRoute
                            component={<Pages.ThreadsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/thread/:threadId"
                    element={
                        <PrivateRoute
                            component={<Pages.ThreadDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/execution/:executionId"
                    element={
                        <PrivateRoute
                            component={<Pages.ExecutionDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/templates/goals"
                    element={
                        <PrivateRoute
                            component={<Pages.TemplatesLibraryPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/processes"
                    element={
                        <PrivateRoute
                            component={<Pages.TemplatesLibraryPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/goal/new"
                    element={
                        <PrivateRoute
                            component={<Pages.CreateNewGoalTemplatePage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/process/new"
                    element={
                        <PrivateRoute
                            component={<Pages.CreateNewProcessTemplatePage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/goal/:goalTemplateId"
                    element={
                        <PrivateRoute
                            component={<Pages.TemplateDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/goal/:goalTemplateId/branch/:versionId"
                    element={
                        <PrivateRoute
                            component={<Pages.TemplateDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/goal/:goalTemplateId/branch/new"
                    element={
                        <PrivateRoute
                            component={<Pages.CreateNewTemplateBranchPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/process/:processTemplateId"
                    element={
                        <PrivateRoute
                            component={<Pages.TemplateDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/process/:processTemplateId/branch/:versionId"
                    element={
                        <PrivateRoute
                            component={<Pages.TemplateDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/process/:processTemplateId/branch/new"
                    element={
                        <PrivateRoute
                            component={<Pages.CreateNewTemplateBranchPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/process/:processTemplateId/publish"
                    element={
                        <PrivateRoute
                            component={<Pages.PublishProcessTemplatePage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/process/:processTemplateId/test"
                    element={
                        <PrivateRoute
                            component={<Pages.RunIntegrationTestPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/goal/:goalTemplateId/datasets"
                    element={
                        <PrivateRoute
                            component={<Pages.GoalTemplateDatasetsAndTestRunsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/goal/:goalTemplateId/tests"
                    element={
                        <PrivateRoute
                            component={<Pages.GoalTemplateDatasetsAndTestRunsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/goal/:goalTemplateId/dataset/new"
                    element={
                        <PrivateRoute
                            component={<Pages.CreateNewDatasetPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/templates/goal/:goalTemplateId/test"
                    element={
                        <PrivateRoute
                            component={<Pages.CreateUnitTestPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin, VIEW_TYPES.client]}
                            availableForStaffOnly
                        />
                    }
                />
                <Route
                    path="/dashboard"
                    element={
                        <PrivateRoute
                            component={<Pages.DashboardPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/assistant"
                    element={
                        <PrivateRoute
                            component={<Pages.AssistantPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/assistant/chat/:chatThreadId"
                    element={
                        <PrivateRoute
                            component={<Pages.AssistantPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/assistant/job/:jobId"
                    element={
                        <PrivateRoute
                            component={<Pages.AssistantPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/docs"
                    element={
                        <PrivateRoute
                            component={<Pages.DocsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/docs/folder/:folderId"
                    element={
                        <PrivateRoute
                            component={<Pages.DocsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/doc/:docId"
                    element={
                        <PrivateRoute
                            component={<Pages.DocDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.client]}
                        />
                    }
                />
                <Route
                    path="/bots"
                    element={
                        <PrivateRoute
                            component={<Pages.BotsPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route
                    path="/bot/:botId"
                    element={
                        <PrivateRoute
                            component={<Pages.BotDetailPage />}
                            redirectTo="/login"
                            availableViewTypes={[VIEW_TYPES.admin]}
                            needsSpecialPermission
                            allowedPlansAndRoles={[
                                {
                                    plan: ORGANIZATION_PLAN.agency,
                                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                                },
                            ]}
                        />
                    }
                />
                <Route path="*" element={<Pages.NotFoundPage />} />
            </Route>
        )
    );
    return <RouterProvider router={router} />;
}
