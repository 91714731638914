import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useFetchOptionsForPaginatedSelect } from '../../hooks/useFetchOptionsForPaginatedSelect';

import client from '../../services/library-api';
import knowledgeClient from '../../services/knowledge-api';
import organizationClient from '../../services/organization-api';
import { API } from 'constants';

import { TASK_TYPES } from '../../constants/library';
import { urlRegex } from '../../constants/regex_patterns';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../constants/errorMessages';

import Button from '../../design-system/Button/Button';
import {
    ArrowGoBackLineIcon,
    ErrorWarningLineIcon,
    TerminalBoxLineIcon,
} from '../../design-system/Icons';
import { Input, Select } from '../../design-system';
import Alert from '../../design-system/Alert/Alert';
import PaginatedSelect from '../../design-system/PaginatedSelect/PaginatedSelect';
import AssistantAndAgentTasksBlock from './AssistantAndAgentTasksBlock/AssistantAndAgentTasksBlock';

const taskTypeOptions = [
    { id: TASK_TYPES.inbox, name: 'Inbox Task' },
    { id: TASK_TYPES.trigger, name: 'Trigger Static Goal Task' },
    { id: TASK_TYPES.webhook, name: 'Send Webhook Task' },
    { id: TASK_TYPES.retrieval, name: 'Retrieve from Single Knowledge Base' },
    { id: TASK_TYPES.assistant, name: 'Single Goal Assistant Task' },
    // { id: TASK_TYPES.record, name: 'Record to Knowledge Base Task' },
    { id: TASK_TYPES.api, name: 'Generic API Call Task' },
    { id: TASK_TYPES.regex, name: 'Find All Regex Task' },
    { id: TASK_TYPES.function, name: 'Single Function Call Task' },
    { id: TASK_TYPES.code, name: 'Run Python Task' },
    { id: TASK_TYPES.plot, name: 'Create Single Plot Task' },
];

const requiredFieldsForCreateRequest = {
    [TASK_TYPES.inbox]: ['name', 'type'],
    [TASK_TYPES.trigger]: ['name', 'type', 'goal'],
    [TASK_TYPES.webhook]: ['name', 'type', 'webhook_url'],
    [TASK_TYPES.retrieval]: ['name', 'type', 'base', 'subtype'],
    [TASK_TYPES.assistant]: ['name', 'type', 'goals', 'subtype'],
    // [TASK_TYPES.record]: ['name', 'type', 'base', 'subtype'],
    [TASK_TYPES.api]: ['name', 'type', 'connection', 'subtype'],
    [TASK_TYPES.regex]: ['name', 'type', 'regex_pattern', 'subtype'],
    [TASK_TYPES.function]: ['name', 'type', 'goals', 'subtype'],
    [TASK_TYPES.code]: ['name', 'type', 'subtype'],
    [TASK_TYPES.plot]: ['name', 'type', 'subtype'],
};

const taskSubtypes = {
    [TASK_TYPES.retrieval]: 'concat-records',
    [TASK_TYPES.assistant]: 'single-tool-assistant',
    // [TASK_TYPES.record]: 'single-knowledgebase-entry',
    [TASK_TYPES.api]: 'string-response',
    [TASK_TYPES.regex]: 'find-all-string',
    [TASK_TYPES.function]: 'single-tool',
    [TASK_TYPES.code]: 'python',
    [TASK_TYPES.plot]: 'single-plot',
};

const CreateNewTaskPage = () => {
    const { user } = useUser();
    const orgId = user && user.organization.id;

    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '/library';

    const [data, setData] = useState({
        name: '',
        type: '',
        goal: '', // used for trigger task
        webhook_url: '',
        base: '',
        selectedGoal: '', // this is used for saving current selected goal in assistant and function (agent) task types
        goals: [], // used for assistant and function (agent) tasks
        connection: null,
        regex_pattern: '', // used for regexp task
        code: '', // used for code task
    });
    const [errorFields, setErrorFields] = useState({
        name: false,
        type: false,
        goal: false,
        webhook_url: false,
        base: false,
        goals: false,
        connection: false,
        regex_pattern: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({
        show: false,
        message: '',
        statusCode: null,
    });

    useDocumentTitle('Create Task');

    const setGoalOptions = (prevData, data) => {
        const newData =
            // add the process to the name if it's not null
            data.results?.map((item) => ({
                id: item.id,
                name: `${item.name}` + (item.process ? ` - ${item.process}` : ''),
            })) || [];
        return [...prevData, ...newData];
    };

    const {
        options: goalOptions,
        optionsLoading: goalOptionsLoading,
        canLoadMoreOptions: canLoadMoreGoalOptions,
        setPage: setGoalPage,
        totalOptions: totalGoalOptions,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: API.ROUTES.library.workflow,
        searchParams: { ordering: 'name', is_deleted: false },
        callback: setGoalOptions,
    });

    const setBasesOptions = (prevData, data) => {
        const newData = data.results?.map((item) => ({ id: item.id, name: item.name })) || [];
        return [...prevData, ...newData];
    };

    const {
        options: basesOptions,
        optionsLoading: basesOptionsLoading,
        canLoadMoreOptions: canLoadMoreBasesOptions,
        setPage: setBasesPage,
        totalOptions: totalBasesOptions,
    } = useFetchOptionsForPaginatedSelect({
        client: knowledgeClient,
        route: API.ROUTES.knowledge.base,
        searchParams: { type: 'knowledge-base' },
        callback: setBasesOptions,
    });

    const setConnectionsOptions = (prevData, data) => {
        const newData = data.results?.map((item) => ({ id: item.id, name: item.name })) || [];
        return [...prevData, ...newData];
    };

    const {
        options: connectionsOptions,
        optionsLoading: connectionsOptionsLoading,
        canLoadMoreOptions: canLoadMoreConnectionsOptions,
        setPage: setConnectionsPage,
        totalOptions: totalConnectionsOptions,
    } = useFetchOptionsForPaginatedSelect({
        client: organizationClient,
        route: API.ROUTES.organization.connection,
        searchParams: { organization: orgId },
        callback: setConnectionsOptions,
        startRequest: !!orgId,
    });

    const handleInputChange = (e) => {
        setData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
        if (errorFields[e.target.name]) {
            setErrorFields((prevData) => ({ ...prevData, [e.target.name]: false }));
        }
    };

    const handleSelectChange = (selectedValue, fieldName) => {
        setData((prevData) => ({ ...prevData, [fieldName]: selectedValue }));
        if (errorFields[fieldName]) {
            setErrorFields((prevData) => ({ ...prevData, [fieldName]: false }));
        }
    };

    const handleTaskCreate = async () => {
        const fieldsLabelList = requiredFieldsForCreateRequest[data.type] || ['name', 'type'];

        let isWebhookUrlValid = true;
        if (data.type === TASK_TYPES.webhook) {
            isWebhookUrlValid = urlRegex.test(data.webhook_url?.trim());
        }

        const areEmptyFields = fieldsLabelList.some((field) =>
            field === 'subtype' ? false : field === 'goals' ? !data[field]?.length : !data[field]
        );
        if (areEmptyFields || !isWebhookUrlValid) {
            const emptyFields = fieldsLabelList.reduce(
                (acc, field) => ({
                    ...acc,
                    [field]: field === 'goals' ? !data[field]?.length : !data[field],
                }),
                {}
            );
            emptyFields.webhook_url = !isWebhookUrlValid;
            setErrorFields(emptyFields);
            return;
        }

        try {
            setIsLoading(true);
            const requestBody = fieldsLabelList.reduce(
                (acc, field) => ({
                    ...acc,
                    [field]:
                        field === 'webhook_url'
                            ? data[field]?.trim()
                            : field === 'subtype'
                            ? taskSubtypes[data.type]
                            : data[field],
                }),
                {}
            );
            if (data.type === TASK_TYPES.code) {
                requestBody.code = '';
            }

            const { data: response } = await client.post(API.ROUTES.library.task, requestBody);
            setIsLoading(false);
            navigate(`/library/task/${response.id}`);
        } catch (error) {
            setErrorAlert({
                show: true,
                statusCode: error.response.status,
                message: defaultErrorMessage,
            });
            setIsLoading(false);
        }
    };

    const handleBackPressed = () => {
        navigate(libraryLocation);
    };

    return (
        <div className="bg-white w-full sm:w-[calc(100%-68px)] flex justify-center h-full px-5 pb-[40px] min-h-[calc(100vh-64px)]">
            <div className="w-full max-w-[800px] py-5">
                <div className="mb-5">
                    <Button
                        type="link"
                        size="sm"
                        text="Back to Library"
                        onClick={handleBackPressed}
                        leadingIcon={ArrowGoBackLineIcon}
                    />
                </div>
                <div className="flex flex-col gap-8">
                    <h1 className="font-heading-bold text-heading-bold-m text-neutral-500">
                        Create New Task
                    </h1>
                    <div className="flex flex-col gap-5">
                        <Select
                            size="md"
                            name="type"
                            value={data.type}
                            options={taskTypeOptions}
                            label="Task Type"
                            isRequired
                            placeholder="Select an option"
                            state={errorFields.type ? 'error' : 'default'}
                            errorMessage="Please select an option"
                            onChange={(newValue) => handleSelectChange(newValue, 'type')}
                            dropdownHeight={280}
                        />
                        <Input
                            size="md"
                            label="Task Name"
                            name="name"
                            value={data.name}
                            state={errorFields.name ? 'error' : 'default'}
                            isRequired
                            placeholder="Brand Guideline Audit Review"
                            errorMessage={emptyFieldErrorMessage}
                            onChange={handleInputChange}
                        />
                        {data.type === TASK_TYPES.trigger && (
                            <PaginatedSelect
                                size="sm"
                                name="goal"
                                options={goalOptions}
                                optionsLoading={goalOptionsLoading}
                                value={data.goal}
                                label="Task Goal"
                                isRequired
                                placeholder="Select a Task Goal"
                                onChange={(newValue) => handleSelectChange(newValue, 'goal')}
                                state={errorFields.goal ? 'error' : 'default'}
                                errorMessage={'Please select an option'}
                                fetchOptions={() => setGoalPage((page) => page + 1)}
                                canLoadMore={canLoadMoreGoalOptions}
                                includeClientSideFiltering
                                totalOptionsCount={totalGoalOptions}
                            />
                        )}
                        {data.type === TASK_TYPES.webhook && (
                            <Input
                                size="md"
                                label="Task Webhook URL"
                                name="webhook_url"
                                value={data.webhook_url}
                                state={errorFields.webhook_url ? 'error' : 'default'}
                                isRequired
                                placeholder="https://hook.us1.make.com/xxxx"
                                errorMessage={
                                    data.webhook_url
                                        ? 'Please check the entered URL as it seems to be invalid.'
                                        : emptyFieldErrorMessage
                                }
                                onChange={handleInputChange}
                            />
                        )}
                        {(data.type === TASK_TYPES.retrieval ||
                            data.type === TASK_TYPES.record) && (
                            <PaginatedSelect
                                size="sm"
                                name="base"
                                options={basesOptions}
                                optionsLoading={basesOptionsLoading}
                                value={data.base}
                                label="Task Base"
                                isRequired
                                placeholder="Select a Task Base"
                                onChange={(newValue) => handleSelectChange(newValue, 'base')}
                                state={errorFields.base ? 'error' : 'default'}
                                errorMessage={'Please select an option'}
                                fetchOptions={() => setBasesPage((page) => page + 1)}
                                canLoadMore={canLoadMoreBasesOptions}
                                includeClientSideFiltering
                                totalOptionsCount={totalBasesOptions}
                            />
                        )}
                        {(data.type === TASK_TYPES.assistant ||
                            data.type === TASK_TYPES.function) && (
                            <AssistantAndAgentTasksBlock
                                data={data}
                                setData={setData}
                                goalOptions={goalOptions}
                                canLoadMoreGoalOptions={canLoadMoreGoalOptions}
                                goalOptionsLoading={goalOptionsLoading}
                                totalGoalOptions={totalGoalOptions}
                                setGoalPage={setGoalPage}
                                errorFields={errorFields}
                                setErrorFields={setErrorFields}
                            />
                        )}
                        {data.type === TASK_TYPES.api && (
                            <PaginatedSelect
                                size="sm"
                                name="connection"
                                options={connectionsOptions}
                                optionsLoading={!orgId ? true : connectionsOptionsLoading}
                                value={data.connection}
                                label="API Provider"
                                isRequired
                                placeholder="Select an API Provider"
                                onChange={(newValue) => handleSelectChange(newValue, 'connection')}
                                state={errorFields.connection ? 'error' : 'default'}
                                errorMessage={'Please select an option'}
                                fetchOptions={() => setConnectionsPage((page) => page + 1)}
                                canLoadMore={canLoadMoreConnectionsOptions}
                                includeClientSideFiltering
                                totalOptionsCount={totalConnectionsOptions}
                            />
                        )}
                        {data.type === TASK_TYPES.regex && (
                            <Input
                                size="md"
                                label="Regex Pattern"
                                name="regex_pattern"
                                value={data.regex_pattern}
                                state={errorFields.regex_pattern ? 'error' : 'default'}
                                isRequired
                                placeholder="Write the regex pattern to search with i.e. <tag>(.*?)<\/tag>"
                                errorMessage={emptyFieldErrorMessage}
                                onChange={handleInputChange}
                            />
                        )}
                    </div>
                    <div>
                        <Button
                            type="primary"
                            size="sm"
                            text="Create Task"
                            state={isLoading ? 'loading' : 'default'}
                            trailingIcon={TerminalBoxLineIcon}
                            onClick={handleTaskCreate}
                        />
                    </div>
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                />
            )}
        </div>
    );
};

export default CreateNewTaskPage;
