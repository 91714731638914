import React from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { SvgIcon } from '../../../design-system';
import { ArrowDownSLineIcon } from '../../../design-system/Icons';
import ArrowRightSLineIcon from '../../../design-system/Icons/ArrowRightSLineIcon';

const ParentOrganizationNavItem = ({
    parentOrganization,
    selectedParentOrgId,
    selectedChildOrgId,
    setIsSidePanelOpened,
}) => {
    const navigate = useNavigate();
    const { id: parentOrgId, name, child_organizations } = parentOrganization;

    const handleSelectOrganization = (childOrgId) => {
        setIsSidePanelOpened(false);

        if (childOrgId) {
            navigate(`/settings/organization/${parentOrgId}?client=${childOrgId}`);
            return;
        }
        navigate(`/settings/organization/${parentOrgId}`);
    };

    const isParentOrgExpanded = selectedParentOrgId === parentOrgId;
    const isParentOrgSelected = selectedParentOrgId === parentOrgId && !selectedChildOrgId;
    const parentOrgButtonClassName = classNames(
        'p-2 flex gap-2 items-center w-full rounded-2 transition-colors',
        {
            'bg-neutral-100': isParentOrgSelected,
            'bg-white': !isParentOrgSelected,
        }
    );

    const buttonIcon = isParentOrgExpanded ? ArrowDownSLineIcon : ArrowRightSLineIcon;
    const areChildOrgsDisplayed = isParentOrgExpanded && !!child_organizations?.length;

    return (
        <div>
            <button className={parentOrgButtonClassName} onClick={() => handleSelectOrganization()}>
                <SvgIcon color="#000000" icon={buttonIcon} size="medium" />
                <p className="font-body text-body-regular-m text-neutral-500 truncate">{name}</p>
            </button>

            {areChildOrgsDisplayed && (
                <div className="pl-4">
                    {child_organizations.map((childOrganization) => {
                        const { id: childOrgId, name } = childOrganization;

                        const isChildSelected = selectedChildOrgId === childOrgId;
                        const childClassName = classNames(
                            'p-2 flex gap-2 items-center w-full rounded-2 transition-colors',
                            {
                                'bg-neutral-100': isChildSelected,
                                'bg-white': !isChildSelected,
                            }
                        );

                        return (
                            <button
                                key={childOrgId}
                                className={childClassName}
                                onClick={() => handleSelectOrganization(childOrgId)}
                            >
                                <p className="font-body text-body-regular-s text-neutral-500 truncate">
                                    {name}
                                </p>
                            </button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ParentOrganizationNavItem;
