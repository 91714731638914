import { Outlet } from 'react-router-dom';
import React from 'react';

export default function PageContainer() {
    return (
        <div className="px-0 py-0 sm:p-8 bg-neutral-50 w-full h-full min-h-screen">
            <Outlet />
        </div>
    );
}
