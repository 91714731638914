import React from 'react';
import Tag from '../../../design-system/Tag/Tag';
import LayersIcon from '../../../design-system/Icons/LayersIcon';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const PlaybookInfoBadges = ({ sections, processes }) => {
    const sectionsCount = sections?.length || 0;
    const sectionsLabel =
        sectionsCount === 0
            ? 'No sections'
            : `${sectionsCount} ${sectionsCount === 1 ? 'Section' : 'Sections'}`;

    // const agentsCount = processes?.length || 0;
    const agentsCount = processes || 0;
    const agentsLabel =
        agentsCount === 0
            ? 'No agents'
            : `${agentsCount} ${agentsCount === 1 ? 'Agent' : 'Agents'}`;

    return (
        <div className="flex items-center gap-1">
            <Tag text={sectionsLabel} type="ghost" color="neutral" leadingIcon={LayersIcon} />
            <Tag text={agentsLabel} type="ghost" color="neutral" leadingIcon={FlashlightFillIcon} />
        </div>
    );
};

export default PlaybookInfoBadges;
