import React from 'react';

const UnderlineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M5.33073 2V8C5.33073 9.47273 6.52464 10.6667 7.9974 10.6667C9.47013 10.6667 10.6641 9.47273 10.6641 8V2H11.9974V8C11.9974 10.2091 10.2065 12 7.9974 12C5.78826 12 3.9974 10.2091 3.9974 8V2H5.33073ZM2.66406 13.3333H13.3307V14.6667H2.66406V13.3333Z"
                fill={props.color}
            />
        </svg>
    );
};

export default UnderlineIcon;
