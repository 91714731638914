import React, { memo, useState } from 'react';
import classNames from 'classnames';

import { indicatorColors } from '../../../../constants/runStatus';
import { threadStatusBadge } from '../../../../constants/threads';

import { More2FillIcon } from '../../../../design-system/Icons';
import { Badge, ButtonIcon } from '../../../../design-system';
import DetailContainer from '../../../../design-system/DetailContainer/DetailContainer';
import PlayCircleFillIcon from '../../../../design-system/Icons/PlayCircleFillIcon';
import ThreadMoreOptionsPopup from '../ThreadMoreOptionsPopup/ThreadMoreOptionsPopup';
import RestartSidePanel from '../../../ThreadDetailPage/RestartSidePanel/RestartSidePanel';
import CancelConfirmationModal from '../../../ThreadDetailPage/CancelConfirmationModal/CancelConfirmationModal';

const ThreadCard = ({ thread, mutate }) => {
    const { id, name, status, process } = thread;
    const statusBadge = threadStatusBadge[status];

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);
    const [actionModal, setActionModal] = useState({ action: null });

    const onCancel = () => {
        mutate(
            (currentData) => {
                if (!currentData) {
                    return;
                }

                const updatedThreads = currentData.threads?.map((thread) =>
                    thread.id === id ? { ...thread, status: 'canceled' } : thread
                );
                return { ...currentData, threads: updatedThreads };
            },
            { revalidate: true }
        );
    };

    const cardContainerClassName = classNames(
        'flex-1 bg-white px-4 py-3 rounded-2',
        'grid grid-rows-[auto_auto] lg:grid-rows-1 grid-cols-[minmax(100px,_1fr)_28px] lg:grid-cols-[minmax(100px,_1fr)_190px_110px_28px] xl:grid-cols-[minmax(100px,_1fr)_230px_110px_28px] 2xl:grid-cols-[minmax(100px,_1fr)_290px_110px_28px] gap-x-4 xl:gap-x-6 gap-y-2.5 items-start lg:items-center'
    );

    return (
        <li className="max-w-full w-full flex">
            <div className="flex flex-col gap-0.5 items-center px-2.5 pt-2.5">
                <div
                    className="w-[7px] h-[7px] min-w-[7px] rounded-full"
                    style={{ backgroundColor: indicatorColors[status] || '#CFD6E5' }}
                ></div>
                <div className="flex-grow w-[1px] bg-neutral-200"></div>
            </div>

            <div className={cardContainerClassName}>
                <div>
                    <p className="font-body-bold text-body-bold-s text-black mb-1">{name}</p>
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        Thread ID <span className="text-neutral-500">{id}</span>
                    </p>
                </div>

                <DetailContainer
                    label="Process"
                    customStyles="row-start-2 row-end-2 col-span-full lg:row-start-auto lg:row-end-auto lg:col-span-1"
                >
                    <Badge
                        text={process?.name}
                        color="neutral"
                        leadingIcon={PlayCircleFillIcon}
                        leadingIconColor={process?.icon_color}
                    />
                </DetailContainer>

                <DetailContainer
                    label="Status"
                    customStyles="row-start-3 row-end-3 col-span-full lg:row-start-auto lg:row-end-auto lg:col-span-1"
                >
                    <Badge text={statusBadge.text} color={statusBadge.color} />
                </DetailContainer>

                <div className="relative">
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={() => setIsMoreOptionsPopupOpened(true)}
                    />

                    {isMoreOptionsPopupOpened && (
                        <ThreadMoreOptionsPopup
                            threadId={id}
                            setActionModal={setActionModal}
                            onClose={() => setIsMoreOptionsPopupOpened(false)}
                        />
                    )}
                </div>
            </div>

            {actionModal.action === 'restart' && (
                <RestartSidePanel
                    threadId={id}
                    restartTarget="thread"
                    mutate={mutate}
                    onClose={() => setActionModal({ action: null })}
                />
            )}

            {actionModal.action === 'cancel' && (
                <CancelConfirmationModal
                    cancelTarget="thread"
                    threadId={id}
                    threadName={name}
                    onCancel={onCancel}
                    onClose={() => setActionModal({ action: null })}
                />
            )}
        </li>
    );
};

export default memo(ThreadCard);
