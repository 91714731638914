import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import ScrollToBottom from 'react-scroll-to-bottom';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import { useWebSocketMessageStream } from '../../../hooks/useWebSocketMessageStream';
import { appendModelAndToolsToRequestBody } from '../../../helpers/assistantUtils';
import { generateUUID } from '../../../helpers/generateUUID';
import { CHAT_ROLE, CHAT_TYPE, HISTORY_TAB } from '../../../constants/assistant';
import { wsFailReconnectErrorMessage } from '../../../services/websocket';

import ChatInvitingView from '../ChatInvitingView/ChatInvitingView';
import ChatInputBox from '../ChatInputBox/ChatInputBox';
import ChatContent from '../ChatContent/ChatContent';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const MainChatArea = ({
    chatType,
    selectedAiModel,
    selectedCustomBot,
    chatThreadData,
    setChatThreadData,
    setChatThreadsHistoryList,
}) => {
    const navigate = useNavigate();
    const controller = useRef(new AbortController());

    const containerRef = useRef(null);

    const assistantMessageResponse = useRef(null);
    const [messageStreamState, setMessageStreamState] = useState({
        isAssistantLoading: false,
        isStreamRunning: false,
    });

    const isStreamCanceled = useRef(false);
    const [emptyCanceledAssistantMessage, setEmptyCanceledAssistantMessage] = useState(null);

    const [submittedUserMessage, setSubmittedUserMessage] = useState(null); // submitted but not saved yet user message (post request is pending)

    // keeps data in format {selected_tool_type: {details: {}}}
    // if there are a few selected tools so the object has a few properties
    const [selectedTools, setSelectedTools] = useState({});

    const skipRefreshDataWhileIdIsChanged = useRef(false);

    const [errorAlert, setErrorAlert] = useState(null);
    const [messageSubmissionErrorData, setMessageSubmissionErrorData] = useState(null);

    const removedDataOnMessageRegenerate = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(null);

    useEffect(() => {
        if (!skipRefreshDataWhileIdIsChanged.current) {
            refreshData();
        }

        if (skipRefreshDataWhileIdIsChanged.current) {
            skipRefreshDataWhileIdIsChanged.current = false;
        }
    }, [chatThreadData?.id]);

    const refreshData = () => {
        stopWebSocketStream();
        controller.current?.abort();

        isStreamCanceled.current = false;
        setEmptyCanceledAssistantMessage(null);

        assistantMessageResponse.current = null;
        setSubmittedUserMessage(null);
        clearMessageLoadingStreamState();
        setSelectedTools({});
    };

    useEffect(() => {
        return () => {
            controller.current?.abort();
        };
    }, []);

    const isDiviBot = chatType === CHAT_TYPE.divibot;
    useEffect(() => {
        if (!isDiviBot) {
            setSelectedTools({});
        }
    }, [isDiviBot]);

    const setChatMessages = useCallback(
        (cb) => {
            setChatThreadData((prevData) =>
                !prevData
                    ? null
                    : {
                          ...prevData,
                          chat_messages: cb(prevData.chat_messages || []),
                      }
            );
        },
        [setChatThreadData]
    );

    const {
        setGenerationId,
        stopStreaming: stopWebSocketStream,
        tryReconnectIfWebsocketClosed,
    } = useWebSocketMessageStream({
        onStreamStart,
        onMessageStream,
        onStreamEnd,
        onStreamError,
    });

    function onStreamStart() {
        const assistantInitialMessage = assistantMessageResponse.current;
        if (assistantInitialMessage) {
            setChatMessages((prevMessages) => [...prevMessages, assistantInitialMessage]);
        }

        assistantMessageResponse.current = null;

        setMessageStreamState({
            isAssistantLoading: false,
            isStreamRunning: true,
        });
    }

    const updateStreamingMessage = (streamingMessageGenId, cb) => {
        setChatMessages((chatMessages) =>
            chatMessages.map((message) => {
                const isAssistantStreamingMessage =
                    message.default_version?.id === streamingMessageGenId;

                if (isAssistantStreamingMessage) {
                    return cb(message);
                }

                return message;
            })
        );
    };

    function onMessageStream({ message: streamingMessage }) {
        triggerStreamStartIfMissed();

        updateStreamingMessage(streamingMessage.gen_id, (message) => {
            const updatedMessageContent =
                (message.default_version.content || '') + streamingMessage.text;
            const updatedMessage = {
                ...message,
                default_version: {
                    ...message.default_version,
                    content: updatedMessageContent,
                },
            };

            return updatedMessage;
        });
    }

    function onStreamEnd({ message: streamingMessage }) {
        triggerStreamStartIfMissed();

        updateStreamingMessage(streamingMessage.gen_id, (message) => {
            // get the current content, and if it's empty, fill it; if not, just set the status
            if (!message.default_version.content) {
                const updatedMessageContent = streamingMessage.text;
                const updatedMessage = {
                    ...message,
                    default_version: {
                        ...message.default_version,
                        content: updatedMessageContent,
                        status: 'success',
                    },
                };
                return updatedMessage;
            } else {
                const updatedMessage = {
                    ...message,
                    default_version: {
                        ...message.default_version,
                        status: 'success',
                    },
                };
                return updatedMessage;
            }
        });

        clearMessageLoadingStreamState();
    }

    function onStreamError({ message: streamingMessage }) {
        triggerStreamStartIfMissed();

        updateStreamingMessage(streamingMessage.gen_id, (message) => {
            const errorAssistantMessage = {
                ...message,
                default_version: {
                    ...message.default_version,
                    status: 'failure',
                    streamErrorMessage: streamingMessage.text,
                },
            };
            return errorAssistantMessage;
        });

        clearMessageLoadingStreamState();
    }

    function triggerStreamStartIfMissed() {
        const isStreamStartReceived = !assistantMessageResponse.current;
        if (!isStreamStartReceived) {
            onStreamStart();
        }
    }

    function clearMessageLoadingStreamState() {
        setMessageStreamState({
            isAssistantLoading: false,
            isStreamRunning: false,
        });
    }

    const startListeningWebSockets = useCallback(
        (data) => {
            assistantMessageResponse.current = data.assistant_message;

            const generationId = data.assistant_message.default_version.id;
            setGenerationId(generationId);
        },
        [assistantMessageResponse.current, setGenerationId]
    );

    const getSubmittingMessageRequestBody = ({ message, isNewChat, selectedTools }) => {
        const requestBody = { content: message };
        if (!isNewChat) {
            requestBody.chat_thread = chatThreadData.id;
        }
        if (isNewChat && chatThreadData?.name) {
            requestBody.name = chatThreadData.name;
        }

        return appendModelAndToolsToRequestBody({
            requestBody,
            chatType,
            selectedAiModel,
            selectedCustomBot,
            selectedTools,
        });
    };

    const handleMessageSubmitResponse = useCallback(
        ({ data, messagesToAppend, isNewChat }) => {
            const isCancelledBeforeStartStreaming = !!isStreamCanceled.current;

            if (!isCancelledBeforeStartStreaming) {
                startListeningWebSockets(data);
            }

            const canceledAssistantMessage = {
                ...data.assistant_message,
                default_version: { ...data.assistant_message.default_version, status: 'success' },
            };

            if (isCancelledBeforeStartStreaming) {
                messagesToAppend.push(canceledAssistantMessage);
                isStreamCanceled.current = false;
                setEmptyCanceledAssistantMessage(null);
            }

            if (!isNewChat) {
                setChatThreadData((prevData) => ({
                    ...prevData,
                    chat_messages: [...prevData.chat_messages, ...messagesToAppend],
                }));
            }

            if (isNewChat) {
                const chatThreadData = {
                    ...data.chat_thread,
                    chat_messages: [...messagesToAppend],
                };
                setChatThreadData(chatThreadData);

                skipRefreshDataWhileIdIsChanged.current = true;

                setChatThreadsHistoryList((chatThreads) => [data.chat_thread, ...chatThreads]);

                const chatThreadId = data.chat_thread.id;
                navigate(`/assistant/chat/${chatThreadId}?tab=${HISTORY_TAB.chats}`);
            }
        },
        [
            startListeningWebSockets,
            isStreamCanceled.current,
            skipRefreshDataWhileIdIsChanged.current,
            setChatThreadData,
            setChatThreadsHistoryList,
            setEmptyCanceledAssistantMessage,
        ]
    );

    const handleMessageSubmitErrors = useCallback(
        ({ error, submittedUserMessage, isRegenerate = false, role, wsClosedError }) => {
            const errorMessage = error.response?.data?.error;
            const isBadRequestErrorWithErrorMessage =
                error.response?.status === 400 && errorMessage && typeof errorMessage === 'string';

            if (isRegenerate) {
                const { removedMessages, scrollPosition } =
                    removedDataOnMessageRegenerate.current || {};

                setChatMessages((messages) => {
                    if (role === CHAT_ROLE.user) {
                        return [...(messages?.slice(0, -1) || []), ...(removedMessages || [])];
                    }
                    return [...messages, ...(removedMessages || [])];
                });
                if (scrollPosition !== null && scrollPosition !== undefined) {
                    setScrollPosition(scrollPosition);
                }

                removedDataOnMessageRegenerate.current = null;
            }

            if (isBadRequestErrorWithErrorMessage) {
                let formattedErrorMessage = errorMessage;

                if (errorMessage.includes('Organization does not have access to model gpt-4o')) {
                    formattedErrorMessage = 'Please add an OpenAI API key to use this assistant';
                }

                setMessageSubmissionErrorData({
                    submittedUserMessage,
                    errorMessage: formattedErrorMessage,
                });
            }

            if (wsClosedError) {
                if (submittedUserMessage) {
                    setMessageSubmissionErrorData({ submittedUserMessage });
                }

                const isCancelledBeforeStartStreaming = !!isStreamCanceled.current;

                if (isCancelledBeforeStartStreaming) {
                    isStreamCanceled.current = false;
                    setEmptyCanceledAssistantMessage(null);
                }
            }

            setErrorAlert({
                message: 'Oops! Something went wrong. Please reload and try again.',
            });

            setMessageStreamState({
                isAssistantLoading: false,
                isStreamRunning: false,
            });

            setSubmittedUserMessage(null);
        },
        [
            setErrorAlert,
            setChatMessages,
            setMessageStreamState,
            setMessageSubmissionErrorData,
            removedDataOnMessageRegenerate,
        ]
    );

    const submitChatMessage = async (message) => {
        const submittedMessage = {
            id: `temp-${generateUUID()}`,
            role: CHAT_ROLE.user,
            default_version: {
                content: message,
            },
        };

        try {
            setMessageSubmissionErrorData(null);
            const isNewChat = !chatThreadData?.id;

            // show user temporary submitted message until the request returns the user message
            setSubmittedUserMessage(submittedMessage);
            setMessageStreamState({
                isAssistantLoading: true,
                isStreamRunning: false,
            });

            await tryReconnectIfWebsocketClosed();

            const newController = new AbortController();
            controller.current = newController;

            const requestBody = getSubmittingMessageRequestBody({
                message,
                isNewChat,
                selectedTools,
            });

            const { data } = await client.post(API.ROUTES.assistant.chatMessage, requestBody, {
                signal: newController.signal,
            });

            setSubmittedUserMessage(null);
            const messagesToAppend = [data.user_message];

            handleMessageSubmitResponse({ data, messagesToAppend, isNewChat });
        } catch (error) {
            if (error.message === 'canceled') {
                return; // the next request is loading
            }

            handleMessageSubmitErrors({
                error,
                submittedUserMessage: submittedMessage.default_version.content,
                wsClosedError: error.message === wsFailReconnectErrorMessage,
            });
        }
    };

    const cancelStream = () => {
        const abortedGenerationId = stopWebSocketStream();

        if (messageStreamState.isStreamRunning) {
            onStreamEnd({ message: { gen_id: abortedGenerationId } });
        }

        if (messageStreamState.isAssistantLoading) {
            setMessageStreamState({
                isAssistantLoading: false,
                isStreamRunning: false,
            });

            isStreamCanceled.current = true;
            setEmptyCanceledAssistantMessage({
                id: `temp-${generateUUID()}`,
                role: CHAT_ROLE.assistant,
                default_version: {
                    content: '',
                    type: chatType,
                    model: selectedAiModel,
                    custom_bot_version:
                        chatType === CHAT_TYPE.custom ? { custom_bot: selectedCustomBot } : null,
                    status: 'pending',
                },
            });
        }
    };

    const handleRegenerateChatMessage = useCallback(
        async ({ id, role, content }) => {
            try {
                setMessageSubmissionErrorData(null);
                setMessageStreamState({
                    isAssistantLoading: true,
                    isStreamRunning: false,
                });

                const newController = new AbortController();
                controller.current = newController;

                setChatMessages((messages) => {
                    const lastMessageIndex = messages.findIndex((message) => message.id === id);

                    if (lastMessageIndex === -1) {
                        return messages;
                    }

                    const slicedChat = messages.slice(0, lastMessageIndex);
                    const removedMessages = messages.slice(lastMessageIndex);

                    let scrollPosition = null;
                    const container = containerRef.current?.children?.[0]?.children?.[0];

                    if (container) {
                        scrollPosition = container.scrollTop;
                    }
                    removedDataOnMessageRegenerate.current = { removedMessages, scrollPosition };

                    if (role === CHAT_ROLE.user) {
                        const originalUserMessage = messages[lastMessageIndex];
                        const updatedUserMessage = {
                            ...originalUserMessage,
                            default_version: {
                                ...originalUserMessage.default_version,
                                content,
                                model: selectedAiModel,
                                status: 'success',
                            },
                        };
                        return [...slicedChat, updatedUserMessage];
                    }
                    return slicedChat;
                });

                await tryReconnectIfWebsocketClosed();

                const requestBody = appendModelAndToolsToRequestBody({
                    requestBody: { regenerate: true },
                    chatType,
                    selectedAiModel,
                    selectedCustomBot,
                    selectedTools,
                });
                if (role === CHAT_ROLE.user) {
                    requestBody.content = content;
                }

                const { data } = await client.patch(
                    `${API.ROUTES.assistant.chatMessage}${id}/`,
                    requestBody,
                    {
                        signal: newController.signal,
                    }
                );

                removedDataOnMessageRegenerate.current = null;
                handleMessageSubmitResponse({ data, messagesToAppend: [], isNewChat: false });
            } catch (error) {
                if (error?.message === 'canceled') {
                    return; // the next request is loading
                }
                handleMessageSubmitErrors({
                    error,
                    isRegenerate: true,
                    role,
                    wsClosedError: error?.message === 'Websocket connection is closed',
                });
            }
        },
        [
            chatType,
            selectedTools,
            selectedAiModel,
            selectedCustomBot,
            controller.current,
            setChatMessages,
            setMessageStreamState,
            handleMessageSubmitResponse,
            removedDataOnMessageRegenerate,
            handleMessageSubmitErrors,
            setMessageSubmissionErrorData,
        ]
    );

    const displayedChatMessages = [...(chatThreadData?.chat_messages || [])];
    if (submittedUserMessage) {
        displayedChatMessages.push(submittedUserMessage);
    }
    if (emptyCanceledAssistantMessage) {
        displayedChatMessages.push(emptyCanceledAssistantMessage);
    }

    const chatMessagesExist = !!displayedChatMessages.length;

    const containerClassName = classNames(
        'w-full max-w-[1010px] px-4 xs:px-5 mx-auto min-h-full pb-6',
        !chatMessagesExist && 'h-full'
    );

    const isAssistantMessageLoadingOrStreaming =
        messageStreamState.isAssistantLoading || messageStreamState.isStreamRunning;

    const isRegenerateMessageDisabled =
        isAssistantMessageLoadingOrStreaming || !!isStreamCanceled.current;

    return (
        <div className="h-[calc(100%-122px)] md:h-[calc(100%-62px)] w-full flex flex-col">
            <div className="flex-1 overflow-hidden" ref={containerRef}>
                <ScrollToBottom
                    className="h-full"
                    followButtonClassName="hidden"
                    initialScrollBehavior="auto"
                >
                    <div className="h-full pt-4 xs:pt-5">
                        <div className={containerClassName}>
                            {chatMessagesExist && (
                                <ChatContent
                                    chatMessages={displayedChatMessages}
                                    setChatMessages={setChatMessages}
                                    chatType={chatType}
                                    isRegenerateMessageDisabled={isRegenerateMessageDisabled}
                                    handleRegenerateChatMessage={handleRegenerateChatMessage}
                                    messageStreamState={messageStreamState}
                                    scrollPosition={scrollPosition}
                                    setScrollPosition={setScrollPosition}
                                    containerRef={containerRef}
                                />
                            )}

                            {!chatMessagesExist && (
                                <ChatInvitingView
                                    chatType={chatType}
                                    selectedCustomBot={selectedCustomBot}
                                />
                            )}
                        </div>
                    </div>
                </ScrollToBottom>
            </div>

            <ChatInputBox
                chatType={chatType}
                selectedAiModel={selectedAiModel}
                selectedCustomBot={selectedCustomBot}
                submitChatMessage={submitChatMessage}
                selectedTools={selectedTools}
                setSelectedTools={setSelectedTools}
                isAssistantMessageLoadingOrStreaming={isAssistantMessageLoadingOrStreaming}
                handleCancelStream={cancelStream}
                isCancelStreamingLoading={!!isStreamCanceled.current}
                messageSubmissionErrorData={messageSubmissionErrorData}
                setMessageSubmissionErrorData={setMessageSubmissionErrorData}
            />
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default memo(MainChatArea);
