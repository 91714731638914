import React, { useState } from 'react';
import ProcessVariableCard from '../ProcessVariableCard/ProcessVariableCard';
import ProcessVariableModal from '../ProcessVariableModal/ProcessVariableModal';
import ConfirmVariableDeleteModal from '../ConfirmVariableDeleteModal/ConfirmVariableDeleteModal';
import BlockHeader from '../BlockHeader/BlockHeader';

const ProcessVariablesBlock = ({ processId, outputs, setOutputs, isEditable }) => {
    const [variableModal, setVariableModal] = useState({
        isOpened: false,
        mode: null,
        variableData: null,
    });
    const [confirmDeleteModal, setConfirmDeleteModal] = useState({
        isOpened: false,
        variableData: null,
    });

    return (
        <div className="w-full py-5 sm:py-8 px-5 sm:px-6 bg-white rounded-2xl">
            <BlockHeader
                title="Process Variables"
                description="Pool of variables available to goals in the process"
                buttonText="New Variable"
                onButtonClick={() => setVariableModal({ isOpened: true, mode: 'create' })}
                withMarginBottom
                isEditable={isEditable}
            />

            {!!outputs?.length && (
                <ul className="flex flex-col gap-4 sm:gap-6">
                    {outputs.map((output) => (
                        <ProcessVariableCard
                            key={output.id}
                            output={output}
                            setVariableModal={setVariableModal}
                            setConfirmDeleteModal={setConfirmDeleteModal}
                            isEditable={isEditable}
                        />
                    ))}
                </ul>
            )}

            {!outputs?.length && (
                <p className="font-body-bold text-body-bold-s text-[#25324B] mt-[-16px]">
                    You don’t have any Variables yet.
                </p>
            )}

            {variableModal.isOpened && (
                <ProcessVariableModal
                    mode={variableModal.mode}
                    processId={processId}
                    variableData={variableModal.variableData}
                    outputs={outputs}
                    setOutputs={setOutputs}
                    onClose={() => setVariableModal({ isOpened: false })}
                />
            )}
            {confirmDeleteModal.isOpened && (
                <ConfirmVariableDeleteModal
                    variableType="output"
                    variableData={confirmDeleteModal.variableData}
                    processId={processId}
                    variables={outputs}
                    setVariables={setOutputs}
                    onClose={() => setConfirmDeleteModal({ isOpened: false })}
                />
            )}
        </div>
    );
};

export default ProcessVariablesBlock;
