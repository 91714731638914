import React from 'react';
import ActiveGoalsBlock from '../ActiveGoalsBlock/ActiveGoalsBlock';
import ProcessVariablesBlock from '../ProcessVariablesBlock/ProcessVariablesBlock';
import HistoryBlock from '../HistoryBlock/HistoryBlock';
import Banner from '../../../../design-system/Banner/Banner';

const ConfigureTabSection = ({ processDetail, setProcessDetail, isEditable }) => {
    const { outputs, process_versions, default_version } = processDetail;

    const setOutputs = (outputs) => {
        setProcessDetail((prevProcessData) => ({
            ...prevProcessData,
            outputs,
        }));
    };

    const uneditableBannerText = (
        <>
            This process was deployed from the{' '}
            <span className="font-body-bold text-body-bold-s">
                {default_version?.process_template_name}
            </span>{' '}
            template and is uneditable.
        </>
    );

    return (
        <div className="flex flex-col l:flex-row l:flex-start gap-5">
            <div className="w-full l:w-[calc((100%-20px)*0.57)] 3xl:w-[calc((100%-20px)*0.62)]">
                <ActiveGoalsBlock
                    processDetail={processDetail}
                    setProcessDetail={setProcessDetail}
                    isEditable={isEditable}
                />
            </div>
            <div className="w-full l:w-[calc((100%-20px)*0.43)] 3xl:w-[calc((100%-20px)*0.38)] flex flex-col gap-5">
                {!isEditable && (
                    <Banner title="Important Note" text={uneditableBannerText} color="blue" />
                )}

                <ProcessVariablesBlock
                    processId={processDetail.id}
                    outputs={outputs}
                    setOutputs={setOutputs}
                    isEditable={isEditable}
                />

                {!isEditable && <HistoryBlock processVersions={process_versions} />}
            </div>
        </div>
    );
};

export default ConfigureTabSection;
