import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ButtonIcon } from '../../design-system';
import { More2FillIcon } from '../../design-system/Icons';

const MoreOptionsControls = ({
    buttonSize = 'sm',
    containerStyles,
    popupComponent: PopupComponent,
    ...popupProps
}) => {
    const [isPopupOpened, setIsPopupOpened] = useState(false);

    const openPopup = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        setIsPopupOpened(true);
    };

    const closePopup = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        setIsPopupOpened(false);
    };

    return (
        <div className={containerStyles || 'relative max-w-fit'}>
            <ButtonIcon type="link" size={buttonSize} icon={More2FillIcon} onClick={openPopup} />

            {isPopupOpened && <PopupComponent {...popupProps} onClose={closePopup} />}
        </div>
    );
};

MoreOptionsControls.propTypes = {
    buttonSize: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
    containerStyles: PropTypes.string,
    popupComponent: PropTypes.elementType.isRequired,
};

export default MoreOptionsControls;
