import React, { useEffect } from 'react';

import { TASK_TYPES } from '../../../../constants/library';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';
import { checkAndUpdateUserInputsIfUsed } from '../../../../helpers/taskPlaygroundUtils';

import CodeEditor from '../../../../design-system/CodeEditor/CodeEditor';

const CodeBlock = ({ code, handleInputChange, fieldsErrorMessages, setUserInputs }) => {
    const { isDesktop } = useResponsiveBreakpoints();

    useEffect(() => {
        checkAndUpdateUserInputsIfUsed({
            dataArray: [code],
            setUserInputs,
            taskType: TASK_TYPES.code,
        });
    }, [code]);

    return (
        <div className="flex-grow w-full flex flex-col">
            <CodeEditor
                value={code || ''}
                setValue={(code) => handleInputChange('code', code)}
                label="Code"
                isRequired
                state={fieldsErrorMessages.code ? 'error' : 'default'}
                errorMessage={fieldsErrorMessages.code}
                withCopyButton
                autoExpand={!isDesktop}
                minHeight={200}
            />
        </div>
    );
};

export default CodeBlock;
