// import modelOptions from 'constants/modelOptions';

// export const getModelOptions = (user) => {
//   // console.log('user', user);
//     // Check if user data and available_models exist
//     if (user && user.organization && user.organization.available_models) {
//       // Map user.settings.available_models to the desired format
//       return user.organization.available_models.map((model) => ({
//         id: model.id,
//         model: model.model,
//         name: model.name,
//         provider: model.provider,
//         type: model.type,
//         token_limit: model.token_limit,
//       }));
//     } else {
//       return [];
//     }
//   };

import modelOptions from 'constants/modelOptions';
import { modelsNameMapping } from '../constants/modelsNameMapping';

export const getModelOptions = (user) => {
    return getOrgAvailableModelOptionsBasedOnFilter({ user, filter: 'none' });
};

export const getAssistantAllowedModelOptions = (user) => {
    return getOrgAvailableModelOptionsBasedOnFilter({ user, filter: 'allow_assistant' });
};

function getOrgAvailableModelOptionsBasedOnFilter({ user, filter = 'none' }) {
    // Check if user data and available_models exist
    if (user && user.organization && user.organization.available_models) {
        // Filter user's available models to include only those also present in modelOptions and by filter argument
        const filteredModels = user.organization.available_models.filter((userModel) =>
            modelOptions.some((option) => {
                if (filter === 'none') {
                    return option.id === userModel.id || option.model === userModel.model;
                }
                if (filter === 'allow_assistant') {
                    return (
                        (option.id === userModel.id || option.model === userModel.model) &&
                        option.allow_assistant
                    );
                }
            })
        );

        // Map the filtered available_models to the desired format
        return filteredModels.map((model) => {
            return {
                id: model.id,
                model: model.model,
                name: modelsNameMapping[model.model] || model.name,
                provider: model.provider,
                type: model.type,
                token_limit: model.token_limit,
            };
        });
    } else {
        return [];
    }
}
