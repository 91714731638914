import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/assistant-api';
import { HISTORY_TAB } from '../../../constants/assistant';

import { SvgIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import HistoryItemMoreOptionsPopup from '../HistoryItemMoreOptionsPopup/HistoryItemMoreOptionsPopup';
import DeleteHistoryItemConfirmationModal from '../DeleteHistoryItemConfirmationModal/DeleteHistoryItemConfirmationModal';
import InlineEditableField from '../InlineEditableField/InlineEditableField';

const ChatThreadCard = ({
    chatThead,
    setChatThreads,
    setCurrentViewingChatThreadData,
    setIsPanelClosableByClickOutside,
    closePanel,
    responsiveBreakpointsHookResponse,
}) => {
    const { chatThreadId: currentViewingChatThreadId } = useParams();

    const { id, name } = chatThead;
    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);
    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);

    const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] = useState(false);
    const [isNameEditing, setIsNameEditing] = useState(false);

    const isCurrentChatThreadIsViewingInPanel = currentViewingChatThreadId === id;

    const { isMobile, isDesktop } = responsiveBreakpointsHookResponse;

    const handleUpdateChatTheadName = async (updatedName) => {
        try {
            setChatThreads((chats) =>
                chats.map((chat) => (chat.id === id ? { ...chat, name: updatedName } : chat))
            );
            if (isCurrentChatThreadIsViewingInPanel) {
                setCurrentViewingChatThreadData((chatData) => {
                    if (!chatData) {
                        return chatData;
                    }
                    return { ...chatData, name: updatedName };
                });
            }
            await client.patch(`${API.ROUTES.assistant.chatThread}${id}/`, { name: updatedName });
        } catch (error) {
            throw error;
        }
    };

    const openMoreOptionsPopup = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsMoreOptionsPopupOpened(true);
        setIsPanelClosableByClickOutside(false);
    };

    const closeMoreOptionsPopup = (e) => {
        e?.stopPropagation();
        setIsMoreOptionsPopupOpened(false);
    };

    const closeDeleteConfirmationModal = () => {
        setIsConfirmDeleteModalOpened(false);
        setIsHovered(false);
        setIsPanelClosableByClickOutside(true);
    };

    const openDeleteConfirmationModal = () => {
        setIsConfirmDeleteModalOpened(true);
        setIsPanelClosableByClickOutside(false);
    };

    const stopEditingName = () => {
        setIsNameEditing(false);
        setIsHovered(false);
        setIsPanelClosableByClickOutside(true);
    };

    const showChatThreadDetail = (e) => {
        e?.stopPropagation();
        if (isNameEditing || isMoreOptionsPopupOpened) {
            return;
        }

        navigate(`/assistant/chat/${id}?tab=${HISTORY_TAB.chats}`);
        if (!isDesktop) {
            closePanel();
        }
    };

    const isMoreOptionsButtonDisplayed =
        !isDesktop || ((isHovered || isMoreOptionsPopupOpened) && !isNameEditing);

    const cardClassName = classNames(
        'rounded-2 flex items-center justify-between transition-colors',
        {
            'gap-1': isMoreOptionsButtonDisplayed,
            'gap-0': !isMoreOptionsButtonDisplayed,
            'cursor-pointer hover:bg-neutral-50': !isNameEditing,
            'bg-white': !isCurrentChatThreadIsViewingInPanel,
            'bg-neutral-50': isCurrentChatThreadIsViewingInPanel,
        }
    );

    return (
        <>
            <div
                className={cardClassName}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={showChatThreadDetail}
            >
                <InlineEditableField
                    value={name}
                    isEditing={isNameEditing}
                    stopEditing={stopEditingName}
                    onSave={handleUpdateChatTheadName}
                    textStyles="font-body text-body-regular-m text-neutral-500"
                    shouldEditInPopup={isMobile}
                    popupTitle="Rename Chat"
                />

                <div className="relative">
                    {isMoreOptionsButtonDisplayed && (
                        <button className="block pr-2" onClick={openMoreOptionsPopup}>
                            <SvgIcon color="#000000" icon={More2FillIcon} size="large" />
                        </button>
                    )}

                    {isMoreOptionsPopupOpened && (
                        <HistoryItemMoreOptionsPopup
                            onClose={closeMoreOptionsPopup}
                            handleRename={() => setIsNameEditing(true)}
                            openDeleteConfirmationModal={openDeleteConfirmationModal}
                            allowPanelCloseByClickOutside={() =>
                                setIsPanelClosableByClickOutside(true)
                            }
                        />
                    )}
                </div>
            </div>

            {isConfirmDeleteModalOpened && (
                <DeleteHistoryItemConfirmationModal
                    itemType="chat"
                    itemId={id}
                    itemName={name}
                    setItems={setChatThreads}
                    onClose={closeDeleteConfirmationModal}
                />
            )}
        </>
    );
};

export default ChatThreadCard;
