import { STATUS_TAB_KEYS } from '../constants/inbox';

export const getInboxRequestSearchParams = (
    requestSearchParams,
    searchQuery,
    selectedFilterOptions,
    areReadMessagesShown,
    statusTab
) => {
    const searchParams = { ...requestSearchParams };
    if (searchQuery) {
        searchParams.search = searchQuery;
    }
    if (selectedFilterOptions?.length) {
        searchParams.process__in = [...selectedFilterOptions].sort((a, b) => a - b).join(',');
    }
    if (!areReadMessagesShown && statusTab !== STATUS_TAB_KEYS.completed) {
        searchParams.is_read = false;
    }
    return searchParams;
};
