import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from '../Modal/Modal';
import Alert from '../Alert/Alert';
import { Button } from '../index';
import { ErrorWarningLineIcon } from '../Icons';
import ModalHeader from '../ModalHeader/ModalHeader';

ConfirmDeletionModalPattern.propTypes = {
    title: PropTypes.string.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    confirmationTextStart: PropTypes.string,
    confirmationTextEnd: PropTypes.string,
    entityName: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    errorAlert: PropTypes.shape({
        message: PropTypes.string.isRequired,
        statusCode: PropTypes.number,
    }),
    setErrorAlert: PropTypes.func,
    buttonsTheme: PropTypes.oneOf(['dark', 'colored']),
    customCancelButtonText: PropTypes.string,
    customConfirmButtonText: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'secondary']),
};

function ConfirmDeletionModalPattern({
    variant = 'primary',
    title,
    onClose,
    handleDelete,
    isLoading = false,
    confirmationTextStart = '',
    confirmationTextEnd = '',
    entityName = '',
    errorAlert,
    setErrorAlert,
    buttonsTheme = 'colored',
    customCancelButtonText = '',
    customConfirmButtonText = '',
}) {
    const cancelButtonType = {
        dark: 'ghost',
        colored: 'neutral',
    };
    const deleteButtonType = {
        dark: 'primary',
        colored: 'secondary',
    };

    const modalSize = {
        primary: 'extraSmall',
        secondary: 'medium',
    }[variant];

    const containerClassNames = classNames('flex flex-col', {
        'gap-8': variant === 'primary',
        'py-5 px-5 sm:px-6 md:px-8 gap-6': variant === 'secondary',
    });

    return (
        <Modal size={modalSize} onClose={onClose} resetPadding={variant === 'secondary'}>
            {variant === 'primary' && (
                <p className="font-heading-bold text-heading-bold-m text-black py-2 mb-4">
                    {title}
                </p>
            )}
            {variant === 'secondary' && <ModalHeader onClose={onClose} title={title} outlined />}

            <div className={containerClassNames}>
                <p className="font-body text-body-regular-m text-neutral-500">
                    {confirmationTextStart}{' '}
                    <span className="font-body-bold text-body-bold-m">{entityName}</span>{' '}
                    {confirmationTextEnd}
                </p>
                <div className="flex items-center justify-between">
                    <Button
                        type={cancelButtonType[buttonsTheme]}
                        size="sm"
                        text={
                            customCancelButtonText
                                ? customCancelButtonText
                                : buttonsTheme === 'dark'
                                ? 'No, Cancel'
                                : 'Cancel'
                        }
                        onClick={onClose}
                    />
                    <Button
                        type={deleteButtonType[buttonsTheme]}
                        size="sm"
                        text={customConfirmButtonText || 'Yes, Delete'}
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleDelete}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
}

export default ConfirmDeletionModalPattern;
