import React from 'react';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';
import { Input } from '../../../../design-system';
import CollapsableContainer from '../../../../components/CollapsableContainer/CollapsableContainer';

const RegexSettingsBlock = ({
    isExpanded,
    toggleExpand,
    regexPattern,
    setRegexPattern,
    setChangedTaskFields,
    fieldsErrorMessages,
    setFieldErrorMessages,
}) => {
    const handleRegexPatternChange = (e) => {
        setRegexPattern(e.target.value);
        setChangedTaskFields((prevFields) => addUniqueElementToArray('regex_pattern', prevFields));

        if (fieldsErrorMessages.regex_pattern) {
            setFieldErrorMessages((prevData) => ({ ...prevData, regex_pattern: null }));
        }
    };

    return (
        <CollapsableContainer
            title="Regex Settings"
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
        >
            <Input
                size="sm"
                label="Regex Pattern"
                name="regex_pattern"
                value={regexPattern}
                state={fieldsErrorMessages.regex_pattern ? 'error' : 'default'}
                isRequired
                placeholder="Write the regex pattern to search with i.e. <tag>(.*?)<\/tag>"
                errorMessage={fieldsErrorMessages.regex_pattern}
                onChange={handleRegexPatternChange}
            />
        </CollapsableContainer>
    );
};

export default RegexSettingsBlock;
