import React from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { ListOption, ListOptionGroup } from '../../../design-system';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';

const HistoryItemMoreOptionsPopup = ({
    onClose,
    handleRename,
    openDeleteConfirmationModal,
    allowPanelCloseByClickOutside,
}) => {
    const { width: screenWidth } = useWindowSize();
    const isMobile = screenWidth < 768;

    const handleAction = (action) => {
        action();
        onClose();
    };

    const popupWidth = screenWidth >= 1024 && screenWidth < 1280 ? 270 : 300;

    const options = (
        <>
            <ListOption
                leadingIconName="editLineIcon"
                text="Rename"
                onClick={() => handleAction(handleRename)}
            />
            <ListOption
                leadingIconName="archiveLineIcon"
                text="Archive"
                onClick={() => handleAction(openDeleteConfirmationModal)}
            />
        </>
    );

    const handleClosePopup = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        onClose(e);
        allowPanelCloseByClickOutside();
    };

    if (isMobile) {
        return (
            <MobilePopup onClose={handleClosePopup} closeButtonText="Cancel">
                <div className="flex flex-col gap-1">{options}</div>
            </MobilePopup>
        );
    }

    return (
        <div className="absolute top-[28px] right-[-12px] z-20 pb-5">
            <ListOptionGroup
                fixedWidth={popupWidth}
                listOptions={options}
                handleClose={handleClosePopup}
            />
        </div>
    );
};

export default HistoryItemMoreOptionsPopup;
