import React from 'react';

const FolderTransferFillIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M8.27874 3.33333H14.0026C14.3708 3.33333 14.6693 3.63181 14.6693 4V13.3333C14.6693 13.7015 14.3708 14 14.0026 14H2.0026C1.63442 14 1.33594 13.7015 1.33594 13.3333V2.66667C1.33594 2.29848 1.63442 2 2.0026 2H6.9454L8.27874 3.33333ZM8.0026 8H5.33594V9.33333H8.0026V11.3333L10.6693 8.66667L8.0026 6V8Z"
                fill={props.color}
            />
        </svg>
    );
};

export default FolderTransferFillIcon;
