import React from 'react';

const ArrowRightSLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M8.78126 8.00047L5.48145 4.70062L6.42425 3.75781L10.6669 8.00047L6.42425 12.2431L5.48145 11.3003L8.78126 8.00047Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ArrowRightSLineIcon;
