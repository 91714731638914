import React from 'react';

const SpaceShipLineIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 24 24"
        >
            <path
                d="M1.57339 9.95268C6.76607 6.66956 12.6155 4.8437 18.5387 4.47517C18.7009 4.46506 18.9033 4.45491 19.1462 4.44472C19.5877 4.42622 19.9605 4.76908 19.979 5.2105C19.98 5.23287 19.98 5.25523 19.979 5.27755C19.9687 5.52332 19.9585 5.72814 19.9482 5.89195C19.5787 11.8127 17.753 17.6597 14.4711 22.8504C12.6378 21.6553 10.8738 20.3095 9.19707 18.8131L4.76627 20.29C4.50429 20.3774 4.22109 20.2357 4.13376 19.9737C4.09961 19.8711 4.09954 19.7602 4.13376 19.6575L5.6107 15.2267C4.11423 13.5499 2.7685 11.786 1.57339 9.95268ZM7.10289 13.895L7.87457 14.7597L6.9798 17.4439L9.66404 16.5492L10.5288 17.3209C11.5913 18.2692 12.6958 19.1588 13.8386 19.9881C16.1206 15.831 17.4959 11.2468 17.8961 6.52761C13.177 6.9278 8.59282 8.30312 4.43571 10.5852C5.26501 11.728 6.15462 12.8325 7.10289 13.895ZM10.1815 14.2422C9.00997 13.0707 9.00993 11.1712 10.1815 9.9996C11.3531 8.82807 13.2526 8.82807 14.4241 9.99961C15.5957 11.1712 15.5957 13.0707 14.4241 14.2422C13.2525 15.4138 11.3531 15.4138 10.1815 14.2422ZM11.5957 12.828C11.9863 13.2185 12.6194 13.2185 13.0099 12.828C13.4004 12.4375 13.4005 11.8043 13.0099 11.4138C12.6195 11.0233 11.9862 11.0233 11.5957 11.4138C11.2052 11.8043 11.2053 12.4375 11.5957 12.828Z"
                fill={props.color}
            />
        </svg>
    );
};

export default SpaceShipLineIcon;
