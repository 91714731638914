import React from 'react';

const ToolIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M9.80088 4.20015C9.67873 4.32477 9.61031 4.49231 9.61031 4.66682C9.61031 4.84132 9.67873 5.00886 9.80088 5.13348L10.8675 6.20015C10.9922 6.3223 11.1597 6.39072 11.3342 6.39072C11.5087 6.39072 11.6763 6.3223 11.8009 6.20015L14.3142 3.68682C14.6494 4.42761 14.7509 5.25297 14.6052 6.05291C14.4594 6.85285 14.0734 7.58938 13.4984 8.16433C12.9234 8.73929 12.1869 9.12537 11.387 9.27112C10.587 9.41687 9.76167 9.31537 9.02088 8.98015L4.41421 13.5868C4.149 13.852 3.78929 14.001 3.41421 14.001C3.03914 14.001 2.67943 13.852 2.41421 13.5868C2.149 13.3216 2 12.9619 2 12.5868C2 12.2117 2.149 11.852 2.41421 11.5868L7.02088 6.98015C6.68566 6.23936 6.58416 5.41399 6.72991 4.61405C6.87566 3.81411 7.26174 3.07759 7.83669 2.50263C8.41165 1.92767 9.14818 1.5416 9.94812 1.39584C10.7481 1.25009 11.5734 1.35159 12.3142 1.68682L9.80755 4.19348L9.80088 4.20015Z"
                stroke={props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.80088 4.20015C9.67873 4.32477 9.61031 4.49231 9.61031 4.66682C9.61031 4.84132 9.67873 5.00886 9.80088 5.13348L10.8675 6.20015C10.9922 6.3223 11.1597 6.39072 11.3342 6.39072C11.5087 6.39072 11.6763 6.3223 11.8009 6.20015L14.3142 3.68682C14.6494 4.42761 14.7509 5.25297 14.6052 6.05291C14.4594 6.85285 14.0734 7.58938 13.4984 8.16433C12.9234 8.73929 12.1869 9.12537 11.387 9.27112C10.587 9.41687 9.76167 9.31537 9.02088 8.98015L4.41421 13.5868C4.149 13.852 3.78929 14.001 3.41421 14.001C3.03914 14.001 2.67943 13.852 2.41421 13.5868C2.149 13.3216 2 12.9619 2 12.5868C2 12.2117 2.149 11.852 2.41421 11.5868L7.02088 6.98015C6.68566 6.23936 6.58416 5.41399 6.72991 4.61405C6.87566 3.81411 7.26174 3.07759 7.83669 2.50263C8.41165 1.92767 9.14818 1.5416 9.94812 1.39584C10.7481 1.25009 11.5734 1.35159 12.3142 1.68682L9.80755 4.19348L9.80088 4.20015Z"
                stroke={props.color}
                strokeOpacity="0.2"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ToolIcon;
