import React, { useState } from 'react';
import { mutate } from 'swr';

import client from '../../services/knowledge-api';
import { API } from 'constants';

import Modal from '../../design-system/Modal/Modal';
import EscapeIcon from '../../design-system/Icons/EscapeIcon';
import TextArea from '../../design-system/TextArea/TextArea';
import Alert from '../../design-system/Alert/Alert';
import { Button, SvgIcon } from '../../design-system';
import { ErrorWarningLineIcon } from '../../design-system/Icons';
import { defaultErrorMessage } from '../../constants/errorMessages';

const EditContextModal = ({ handleClose, context, requestKey, setContext = () => {} }) => {
    const [contextValue, setContextValue] = useState(context.default_version?.value);
    const [contextValueError, setContextValueError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState(null);

    const handleChange = (e) => {
        setContextValue(e.target?.value);
        if (contextValueError) {
            setContextValueError(false);
        }
    };

    const updateContext = async () => {
        if (!contextValue) {
            setContextValueError(true);
            return;
        }
        const requestBody = {
            value: contextValue,
        };
        try {
            setIsLoading(true);
            await client.patch(`${API.ROUTES.knowledge.context}${context.id}/`, requestBody);
            if (requestKey) {
                await mutate(requestKey);
            }
            setContext((prevData) =>
                prevData.map((item) =>
                    item.id === context.id ? { ...item, value: contextValue } : item
                )
            );
            setIsLoading(false);
            handleClose();
        } catch (e) {
            setIsLoading(false);
            setErrorStatusCode(e.response.status);
        }
    };

    return (
        <>
            <Modal onClose={handleClose} size="medium">
                <div className="flex flex-col gap-8">
                    <div className="flex items-center justify-between gap-3">
                        <p className="font-heading-bold text-heading-bold-m text-neutral-400 w-[calc(100%-63px)]">
                            Editing {context.label}
                        </p>
                        <div onClick={handleClose} className="cursor-pointer">
                            <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle" />
                        </div>
                    </div>
                    <TextArea
                        name="value"
                        value={contextValue}
                        label="Add the context"
                        isRequired
                        placeholder="Describe your task and use the AI assistant to help you with that"
                        onChange={handleChange}
                        state={contextValueError ? 'error' : 'default'}
                        errorMessage={'Please fill in this field'}
                    />
                    <div className="flex items-center justify-center gap-1 xs:gap-4">
                        <Button type="ghost" size="sm" text="Cancel" onClick={handleClose} />
                        <Button
                            type="primary"
                            size="sm"
                            text="Update Context Everywhere"
                            state={isLoading ? 'loading' : 'default'}
                            onClick={updateContext}
                        />
                    </div>
                </div>
            </Modal>
            {errorStatusCode && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    statusCode={errorStatusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorStatusCode(null)}
                />
            )}
        </>
    );
};

export default EditContextModal;
