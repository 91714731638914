import React from 'react';
import { ListOption, ListOptionGroup } from '../../../design-system';

const ProcessTemplateCardMoreOptionsPopup = ({ processTemplateData, onClose }) => {
    const handleViewInGitHub = () => {
        window.open(processTemplateData.github_url, '_blank');
    };

    return (
        <div className="absolute top-8 right-1 z-55">
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="shareBoxLine"
                            text="View in GitHub"
                            isDisabled={!processTemplateData.github_url}
                            onClick={handleViewInGitHub}
                        />
                    </>
                }
                handleClose={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};

export default ProcessTemplateCardMoreOptionsPopup;
