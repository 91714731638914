import React from 'react';

const GlobeIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 15 14" fill="none">
            <g>
                <path
                    d="M7.33138 12.8333C10.553 12.8333 13.1647 10.2216 13.1647 6.99996C13.1647 3.7783 10.553 1.16663 7.33138 1.16663C4.10972 1.16663 1.49805 3.7783 1.49805 6.99996C1.49805 10.2216 4.10972 12.8333 7.33138 12.8333Z"
                    stroke={props.color}
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.49805 7H13.1647"
                    stroke={props.color}
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.33138 1.16663C8.79046 2.764 9.61965 4.83698 9.66471 6.99996C9.61965 9.16294 8.79046 11.2359 7.33138 12.8333C5.8723 11.2359 5.04311 9.16294 4.99805 6.99996C5.04311 4.83698 5.8723 2.764 7.33138 1.16663V1.16663Z"
                    stroke={props.color}
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default GlobeIcon;
