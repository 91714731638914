import React, { useState } from 'react';

import {
    openLinkEditDialog$,
    usePublisher,
    insertThematicBreak$,
    insertCodeBlock$,
} from '@mdxeditor/editor';

import ToolbarHr from './ToolbarHr';
import ToolbarLink from './ToolbarLink';
import ToolbarCodeBlock from './ToolbarCodeBlock';
import { ListOptionGroup, SvgIcon } from '../../../index';
import { ArrowDownSLineIcon } from '../../../Icons';
import LinkIcon from '../../../Icons/LinkIcon';
import CodeViewIcon from '../../../Icons/CodeViewIcon';
import SeparatorIcon from '../../../Icons/SeparatorIcon';

const ToolbarInsertCommands = ({ isMobile }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const openLinkDialog = usePublisher(openLinkEditDialog$);
    const insertThematicBreak = usePublisher(insertThematicBreak$);
    const insertCodeBlock = usePublisher(insertCodeBlock$);

    const onClose = (e) => {
        e?.stopPropagation();
        setIsPopupOpen(false);
    };

    if (isMobile) {
        const data = [
            { label: 'Link', icon: LinkIcon, action: openLinkDialog },
            { label: 'Horizontal Line', icon: SeparatorIcon, action: insertThematicBreak },
            { label: 'Code Block', icon: CodeViewIcon, action: insertCodeBlock },
        ];

        return (
            <div className="relative">
                <button
                    className="flex p-[6px] hover:bg-neutral-50"
                    onClick={() => setIsPopupOpen(true)}
                >
                    <SvgIcon color="#1F2125" icon={LinkIcon} size="medium" />
                    <SvgIcon color="#1F2125" icon={ArrowDownSLineIcon} size="medium" />
                </button>
                {isPopupOpen && (
                    <div className="absolute -left-1 z-30 top-[110%]">
                        <ListOptionGroup
                            additionalStyles="!p-2"
                            fixedWidth={170}
                            listOptions={
                                <>
                                    {data.map(({ action, label, icon }) => (
                                        <button
                                            key={label}
                                            onClick={() => {
                                                action();
                                                onClose();
                                            }}
                                            className="flex items-center gap-2 px-2 py-[5px] hover:bg-neutral-50 w-full rounded-2"
                                        >
                                            <SvgIcon color="#1F2125" icon={icon} size="medium" />
                                            <p className="text-body-regular-xs text-neutral-500 pt-[1px]">
                                                {label}
                                            </p>
                                        </button>
                                    ))}
                                </>
                            }
                            handleClose={onClose}
                        />
                    </div>
                )}
            </div>
        );
    }

    return (
        <>
            <ToolbarLink />
            <ToolbarHr />
            <ToolbarCodeBlock />
        </>
    );
};

export default ToolbarInsertCommands;
