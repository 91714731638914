import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useClickOutside } from '../../hooks';
import { useWindowSize } from '../../hooks/useWindowSize';
import ClientSideFilteredSearchBar from '../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import classNames from 'classnames';

export default function ListOptionGroup({
    groupTitle,
    listOptions, // options to show (filtered if includeClientSideFiltering = true)
    handleClose = () => {},
    fixedWidth = 310,
    includeClientSideFiltering = false,
    list = [], // the whole options list (not filtered)
    setFilteredList,
    keyToFilter = ['text'],
    closeByClickOutside = true,
    maxHeight: _maxHeight = 480,
    additionalStyles = '',
}) {
    const listRef = useRef(null);
    const { width: screenWidth } = useWindowSize();

    useClickOutside(listRef, closeByClickOutside ? handleClose : () => {});

    const width =
        screenWidth >= 480 && screenWidth < 640
            ? '75vw'
            : screenWidth >= 640
            ? `${fixedWidth}px`
            : '85vw';
    const maxHeight = typeof _maxHeight === 'number' ? `${_maxHeight}px` : _maxHeight;

    return (
        <div
            className={classNames(
                'rounded-lg border border-neutral-200 bg-white shadow-lg p-4 flex flex-col items-start gap-1 z-55 overflow-y-auto',
                additionalStyles
            )}
            ref={listRef}
            style={{ width, maxHeight, maxWidth: `${fixedWidth}px` }}
        >
            {groupTitle && <h1 className="font-body text-body-bold-m leading-6">{groupTitle}</h1>}
            {includeClientSideFiltering && (
                <div className="w-full mb-1">
                    <ClientSideFilteredSearchBar
                        list={list || []}
                        setFilteredList={setFilteredList}
                        size="xs"
                        keyToFilter={keyToFilter}
                    />
                </div>
            )}
            {listOptions}
        </div>
    );
}

ListOptionGroup.propTypes = {
    groupTitle: PropTypes.string,
    listOptions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    handleClose: PropTypes.func,
    fixedWidth: PropTypes.number,
    includeClientSideFiltering: PropTypes.bool,
    list: PropTypes.array,
    setFilteredList: PropTypes.func,
    keyToFilter: PropTypes.arrayOf(PropTypes.string),
    closeByClickOutside: PropTypes.bool,
};
