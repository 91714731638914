import React, { useState } from 'react';

import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';
import { useDraggableReorderableItem } from '../../../../hooks/useDraggableReorderableItem';

import { ButtonIcon } from '../../../../design-system';
import { DraggableIcon } from '../../../../design-system/Icons';
import NewJobFormField from '../../../../components/NewJobForm/NewJobFormField/NewJobFormField';

const DraggableFormField = ({
    input,
    setFormData,
    handleDeleteInput,
    handleDuplicateInput,
    handleEditInput,
    isEditable,
}) => {
    const { isDesktop } = useResponsiveBreakpoints();
    const [isHovered, setIsHovered] = useState(false);

    const { itemRef, dragIconRef, isDragging, handlerId } = useDraggableReorderableItem({
        itemType: 'INPUT',
        item: input,
        setData: setFormData,
    });

    const isDragAndDropEnabled = isDesktop && isEditable;

    return (
        <div
            ref={isDragAndDropEnabled ? itemRef : null}
            className={`flex gap-1.5 ${isDragging ? 'opacity-0' : 'opacity-100'} ${
                !isDragAndDropEnabled && 'lg:pl-[34px]'
            }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                ref={isDragAndDropEnabled ? dragIconRef : null}
                data-handler-id={handlerId}
                className={`cursor-grab ${isHovered ? 'opacity-100' : 'opacity-0'} ${
                    isDragAndDropEnabled ? 'block' : 'hidden'
                }`}
            >
                <ButtonIcon
                    type="neutral"
                    size="xs"
                    icon={DraggableIcon}
                    cursor="grab"
                    onClick={() => {}}
                />
            </div>

            <NewJobFormField
                input={input}
                setFormData={setFormData}
                handleDuplicateInput={handleDuplicateInput}
                handleDeleteInput={handleDeleteInput}
                handleEditInput={handleEditInput}
                withActionButtons={isEditable}
            />
        </div>
    );
};

export default DraggableFormField;
