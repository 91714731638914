import React from 'react';

const FlashlightFillIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24">
            <path d="M13 10H20L11 23V14H4L13 1V10Z" fill={props.color} />
        </svg>
    );
};

export default FlashlightFillIcon;
