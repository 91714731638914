import React, { useState } from 'react';

import * as API from '../../../constants/api';
import client from '../../../services/execute-api';

import useUser from '../../../hooks/useUser';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../../design-system/Modal/Modal';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import AddToDatasetForm from './AddToDatasetForm/AddToDatasetForm';
import SuccessBlockWithLangSmithButton from '../../../design-system/SuccessBlockWithLangSmithButton/SuccessBlockWithLangSmithButton';
import Alert from '../../../design-system/Alert/Alert';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const AddToDatasetModal = ({ workerId, workerInputs, workerOutputs, onClose }) => {
    const { user } = useUser();

    const [selectedDatasetData, setSelectedDatasetData] = useState(null);
    // keeps data in format {inputs: [{datasetKey: datasetInputKey, workerVariableId: workerInputId}], outputs: [{datasetKey: datasetOutputKey, workerVariableId: workerOutputId}]}
    const [mapping, setMapping] = useState({ inputs: null, outputs: null });
    const [langsmithUrl, setLangsmithUrl] = useState(null);

    const [formSubmissionState, setFormSubmissionState] = useState('default'); // success, loading, default
    const [errorAlert, setErrorAlert] = useState(null);

    const atLeastOneInputsMappingIsFilled = !!mapping.inputs?.some(
        (input) => !!input.workerVariableId
    );
    const atLeastOneOutputsMappingIsFilled = !!mapping.outputs?.some(
        (output) => !!output.workerVariableId
    );
    const atLeastOneMappingIsFilled =
        atLeastOneInputsMappingIsFilled || atLeastOneOutputsMappingIsFilled;

    const formatResponseToOptions = (results) =>
        results?.map(({ id, name, input_keys, output_keys }) => ({
            id,
            name,
            input_keys,
            output_keys,
        }));

    const fetchDatasetHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.execute.dataset,
        searchParams: { organization: user.organization.id },
        formatResponseToOptions,
    });

    const getRequestBody = () => {
        // Create objects of worker inputs and outputs for faster lookup
        const workerInputsValuesObj = workerInputs.reduce(
            (acc, input) => ({ ...acc, [input.id]: input.value }),
            {}
        );
        const workerOutputsValuesObj = workerOutputs.reduce(
            (acc, output) => ({ ...acc, [output.id]: output.value }),
            {}
        );

        const inputs = mapping.inputs?.reduce(
            (acc, { datasetKey, workerVariableId }) => ({
                ...acc,
                [datasetKey]: workerInputsValuesObj[workerVariableId] || null,
            }),
            {}
        );
        const outputs = mapping.outputs?.reduce(
            (acc, { datasetKey, workerVariableId }) => ({
                ...acc,
                [datasetKey]: workerOutputsValuesObj[workerVariableId] || null,
            }),
            {}
        );
        return { organization: user.organization.id, worker_id: workerId, inputs, outputs };
    };

    const handleAddToDataset = async () => {
        try {
            setFormSubmissionState('loading');
            const requestBody = getRequestBody();
            const { data } = await client.post(
                `${API.ROUTES.execute.dataset}${selectedDatasetData.id}/example/`,
                requestBody
            );
            setLangsmithUrl(data.data?.langsmith_url);
            setFormSubmissionState('success');
        } catch (e) {
            const error = e.response?.data?.error;

            if (error?.non_field_errors) {
                const { non_field_errors } = error;
                setErrorAlert({
                    message:
                        typeof non_field_errors === 'string'
                            ? non_field_errors
                            : non_field_errors?.[0],
                });
            } else {
                setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
            }
            setFormSubmissionState('default');
        }
    };

    const handleClearForm = () => {
        setSelectedDatasetData(null);
        setMapping({ inputs: null, outputs: null });
        setLangsmithUrl(null);
        setFormSubmissionState('default');
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="sm:p-2 flex flex-col gap-8">
                <ModalHeader title="Add to Dataset" onClose={onClose} />

                {(formSubmissionState === 'default' || formSubmissionState === 'loading') && (
                    <AddToDatasetForm
                        selectedDatasetData={selectedDatasetData}
                        setSelectedDatasetData={setSelectedDatasetData}
                        mapping={mapping}
                        setMapping={setMapping}
                        workerInputs={workerInputs}
                        workerOutputs={workerOutputs}
                        fetchDatasetHookResponse={fetchDatasetHookResponse}
                    />
                )}

                {formSubmissionState === 'success' && (
                    <SuccessBlockWithLangSmithButton
                        message="Added to dataset!"
                        langsmithUrl={langsmithUrl}
                    />
                )}

                <div className="flex items-center justify-between gap-3">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    {formSubmissionState !== 'success' && (
                        <Button
                            type="secondary"
                            size="sm"
                            text="Add"
                            state={
                                formSubmissionState === 'loading'
                                    ? 'loading'
                                    : atLeastOneMappingIsFilled
                                    ? 'default'
                                    : 'disabled'
                            }
                            onClick={handleAddToDataset}
                        />
                    )}
                    {formSubmissionState === 'success' && (
                        <Button
                            type="secondary"
                            size="sm"
                            text="Add New"
                            onClick={handleClearForm}
                        />
                    )}
                </div>
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default AddToDatasetModal;
