import React from 'react';
import { useSearchParams } from 'react-router-dom';

import EmptyView from '../../../design-system/EmptyView/EmptyView';
import ArchiveLineIcon from '../../../design-system/Icons/ArchiveLineIcon';
import PlusCircleIcon from '../../../design-system/Icons/PlusCircleIcon';

const BotsEmptyView = ({ setIsCreateBotModalOpen }) => {
    const [searchParams] = useSearchParams();
    const isSearchQuery = !!searchParams.get('search');

    if (isSearchQuery) {
        return (
            <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[600px]">
                No custom bots found. Please adjust your search and try again!
            </p>
        );
    }

    return (
        <EmptyView
            icon={ArchiveLineIcon}
            mainText="You don't have any Custom Bots yet."
            subtext="Try creating one by clicking “New Bot”."
            buttonText="New Bot"
            buttonLeadingIcon={PlusCircleIcon}
            handleButtonClick={() => setIsCreateBotModalOpen(true)}
            mainTextAdditionalStyles="max-w-[430px]"
        />
    );
};

export default BotsEmptyView;
