import React from 'react';

const ThreadsPageEmptyState = ({ activeTab, total, parsedParams, loading }) => {
    const emptyMessage = (() => {
        if (total === 0 && parsedParams.search) {
            return `No ${activeTab} found matching your search for ${parsedParams.search}.`;
        }
        if (total === 0 && !parsedParams.search && parsedParams.process) {
            return `No ${activeTab} found matching your selected process.`;
        }
        if (total === 0 && !parsedParams.search && !parsedParams.process && !loading) {
            return `No ${activeTab} found.`;
        }
        return null;
    })();

    return (
        <>
            {emptyMessage && (
                <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[600px]">
                    {emptyMessage}
                </p>
            )}
        </>
    );
};

export default ThreadsPageEmptyState;
