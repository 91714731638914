import React, { useState } from 'react';

import { TEMPLATES_LIBRARY_TABS } from '../../../constants/template';
import { useFetchLabels } from '../../../hooks/useFetchLabels';

import Loading from '../../../components/Loading';
import EmptyIndexPage from '../EmptyIndexPage/EmptyIndexPage';
import GoalTemplateCard from '../GoalTemplateCard/GoalTemplateCard';
import ProcessTemplateCard from '../ProcessTemplateCard/ProcessTemplateCard';
import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';

const TemplatesLibraryIndexPage = ({
    data,
    loading = false,
    page,
    activeTab,
    parsedParams,
    requestKeysToMutate,
}) => {
    const fetchAllLabelsHookResponse = useFetchLabels();
    const [alert, setAlert] = useState(null);

    if (!data?.length && loading) {
        return (
            <div className="flex flex-col p-8 m-8">
                <Loading />
            </div>
        );
    }

    if (!data.length && page === 1 && !parsedParams.search) {
        return <EmptyIndexPage activeTab={activeTab} />;
    }

    if (!data.length && page === 1 && parsedParams.search) {
        return (
            <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[480px]">
                We couldn't find any templates matching your search for {parsedParams.search}.
                Please try a different keyword.
            </p>
        );
    }

    return (
        <div className="grid grid-cols-1 gap-x-5 gap-y-5 min-[870px]:grid-cols-2 min-[1300px]:grid-cols-3 xl:gap-x-5 min-[1680px]:grid-cols-4 auto-rows-max">
            {data.map((item) => {
                return (
                    <div className="self-stretch" key={item.id}>
                        {activeTab === TEMPLATES_LIBRARY_TABS.goals ? (
                            <GoalTemplateCard
                                item={item}
                                requestKeysToMutate={requestKeysToMutate}
                                setAlert={setAlert}
                            />
                        ) : (
                            <ProcessTemplateCard
                                item={item}
                                requestKeysToMutate={requestKeysToMutate}
                                fetchAllLabelsHookResponse={fetchAllLabelsHookResponse}
                            />
                        )}
                    </div>
                );
            })}
            {alert && (
                <Alert
                    status={alert.status || 'positive'}
                    message={alert.message}
                    icon={alert.icon || CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setAlert(null)}
                />
            )}
        </div>
    );
};

export default TemplatesLibraryIndexPage;
