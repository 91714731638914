import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { VIEW_TYPES } from '../../../constants/viewTypes';
import { useViewType } from '../../../hooks/useViewType';
import {
    navActiveItemIconColor,
    navItemIconColor,
    navItemLabelColor,
} from '../../../constants/sideBar';
import { checkSpecialPermission } from '../../../helpers/checkRouteAccess';
import SvgIcon from '../../../design-system/SvgIcon/SvgIcon';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import useUser from '../../../hooks/useUser';

const SideBarNavItem = ({
    itemData = {},
    label,
    mobileVersion = false,
    extraLargeIcon = false,
    handleMobMenuClose,
}) => {
    const {
        path,
        icon,
        title,
        toolTipLabel,
        availableViewTypes = [],
        availableForStaffOnly = false,
        needsSpecialPermission = false,
        allowedPlansAndRoles = [], // this should be an array structured [{plan: "agency", roles: ["owner", "admin"]}]
        activePathPrefixes = [],
    } = itemData;
    const { user, orgPlan, userRole } = useUser();
    const { viewType } = useViewType();

    const location = useLocation();

    const isActiveLink = activePathPrefixes?.some((highlightPath) =>
        location.pathname.startsWith(`/${highlightPath}`)
    );

    //based on userRole and orgPlan
    const hasSpecialPermission = checkSpecialPermission(
        orgPlan,
        userRole,
        allowedPlansAndRoles,
        needsSpecialPermission
    );

    // based from is_staff from useUser hook
    const hasStaffAccess = availableForStaffOnly ? !!user?.is_staff : true;

    const shouldBeDisplayed =
        availableViewTypes.includes(viewType) && hasSpecialPermission && hasStaffAccess;

    const [showToolTip, setShowToolTip] = useState(false);

    if (!shouldBeDisplayed) {
        return <></>;
    }

    const linkStyles = () =>
        isActiveLink
            ? `w-9 h-9 flex justify-center items-center rounded-2 ${
                  viewType === VIEW_TYPES.admin ? 'bg-neutral-400' : 'bg-purple-100'
              }`
            : 'w-9 h-9 flex justify-center items-center';

    return (
        <>
            {mobileVersion ? (
                <li className="p-2" onClick={handleMobMenuClose}>
                    <Link
                        to={path}
                        className={`
                            flex items-center gap-[16px] font-body text-body-bold-m ${navItemLabelColor[viewType]}
                        `}
                    >
                        <span className="flex items-center justify-center w-[36px] h-[36px] relative z-[-1]">
                            <SvgIcon
                                color={navItemIconColor[viewType]}
                                size={extraLargeIcon ? 'extraLarge' : 'large'}
                                icon={icon}
                            />
                            {label > 0 && (
                                <span className="absolute top-[6px] right-[4px] w-[16px] h-[10px] rounded-[60px] bg-red-500 text-[8px] text-white font-medium font-body inline-flex justify-center items-center">
                                    {label}
                                </span>
                            )}
                        </span>
                        {title}
                    </Link>
                </li>
            ) : (
                <li className={`${shouldBeDisplayed ? 'p-2' : 'hidden'} `}>
                    <ToolTip
                        text={toolTipLabel}
                        position="center-left"
                        shift={'55px'}
                        isShown={showToolTip}
                    >
                        <NavLink
                            to={path}
                            className={linkStyles}
                            onMouseEnter={() => setShowToolTip(true)}
                            onMouseLeave={() => setShowToolTip(false)}
                        >
                            <div className="relative">
                                <SvgIcon
                                    color={
                                        isActiveLink
                                            ? navActiveItemIconColor[viewType]
                                            : navItemIconColor[viewType]
                                    }
                                    size={extraLargeIcon ? 'extraLarge' : 'large'}
                                    icon={icon}
                                />
                                {label > 0 && (
                                    <span className="absolute top-0 right-[-2px] w-[16px] h-[10px] rounded-[60px] bg-red-500 text-[8px] text-white font-medium font-body inline-flex justify-center items-center">
                                        {label}
                                    </span>
                                )}
                            </div>
                        </NavLink>
                    </ToolTip>
                </li>
            )}
        </>
    );
};

export default SideBarNavItem;
