import React from 'react';

const RefreshIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <g>
                <path
                    d="M0.664062 13.3335V9.3335H4.66406"
                    stroke={props.color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.3359 2.6665V6.6665H11.3359"
                    stroke={props.color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.3374 5.99989C2.67551 5.04441 3.25015 4.19016 4.00771 3.51683C4.76526 2.84351 5.68104 2.37306 6.66959 2.14939C7.65813 1.92572 8.68723 1.95612 9.66086 2.23774C10.6345 2.51936 11.5209 3.04303 12.2374 3.75989L15.3307 6.66655M0.664062 9.33322L3.7574 12.2399C4.47389 12.9567 5.36031 13.4804 6.33394 13.762C7.30756 14.0437 8.33666 14.0741 9.3252 13.8504C10.3138 13.6267 11.2295 13.1563 11.9871 12.4829C12.7446 11.8096 13.3193 10.9554 13.6574 9.99989"
                    stroke={props.color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default RefreshIcon;
