import React, { useRef, useState } from 'react';
import { getLabelMoreOptionPopupRef } from '../../../helpers/templatesUtils';

import Checkbox from '../../../design-system/Checkbox/Checkbox';
import { ButtonIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import { useWindowSize } from '../../../hooks/useWindowSize';

const LabelSelectorItem = ({
    label,
    isChecked,
    onCheckboxChange,
    labelMoreOptionPopup,
    setLabelMoreOptionPopup,
    selectorPopupRef,
    selectorPopupPosition,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const labelItemRef = useRef(null);

    const { width: screenWidth } = useWindowSize();

    const openMoreOptionPopup = () => {
        setIsHovered(false);
        const { position } = getLabelMoreOptionPopupRef({
            selectorPopupRef,
            selectorPopupPosition,
            labelItemRef,
            screenWidth,
        });
        setLabelMoreOptionPopup({ isOpened: true, labelData: label, position });
    };

    const title = (
        <div className="flex items-center gap-[7px]">
            <div
                className="w-[8px] min-w-[8px] h-[8px] rounded-full"
                style={{ backgroundColor: label.icon_color }}
            ></div>
            <p className="font-body text-body-regular-s text-neutral-500 select-none truncate">
                {label.name}
            </p>
        </div>
    );

    const displayHoveredState = isHovered || labelMoreOptionPopup.labelData?.id === label.id;

    return (
        <li
            className={`
                relative px-2 py-1 rounded-2 transition-colors flex items-center justify-between gap-2 min-h-[36px] ${
                    displayHoveredState ? 'bg-neutral-50' : 'bg-white'
                }
            `}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref={labelItemRef}
        >
            <div className={`${displayHoveredState ? 'max-w-[calc(100%-32px)]' : 'max-w-full'}`}>
                <Checkbox
                    name="label"
                    label={title}
                    isChecked={isChecked}
                    onChange={() => onCheckboxChange(label)}
                />
            </div>
            {displayHoveredState && (
                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={More2FillIcon}
                    onClick={openMoreOptionPopup}
                />
            )}
        </li>
    );
};

export default LabelSelectorItem;
