import React, { useEffect, useState } from 'react';

import { API } from 'constants';
import client from '../../services/library-api';
import useCustomPagination from '../../hooks/useCustomPagination';
import { mergeNewDataIntoCurrent } from '../../helpers/mergeNewDataIntoCurrent';

import Modal from '../../design-system/Modal/Modal';
import { Button, ButtonIcon, SvgIcon } from '../../design-system';
import EscapeIcon from '../../design-system/Icons/EscapeIcon';
import { ShareBoxLineIcon } from '../../design-system/Icons';
import Loading from '../Loading';

const ProtectedModeConfirmUnlockModal = ({ taskId, setProtectedMode, onClose }) => {
    const [goals, setGoals] = useState([]);
    const [page, setPage] = useState(1);

    const {
        data: results,
        loading,
        hasNextPage,
    } = useCustomPagination({
        pageIndex: page - 1,
        searchParams: {},
        client,
        route: `${API.ROUTES.library.task}${taskId}/goals/`,
    });

    useEffect(() => {
        setGoals((prevResults) => {
            if (!prevResults && !results) {
                return [];
            } else {
                return mergeNewDataIntoCurrent(prevResults || [], results || []);
            }
        });
        if (hasNextPage) {
            setPage((page) => page + 1);
        }
    }, [results]);

    const openGoalConfigurationPage = (goalId, processId) => {
        window.open(`/configure/process/${processId}/goal/${goalId}`, '_blank');
    };

    const handleUnlock = () => {
        setProtectedMode(false);
        onClose();
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="flex flex-col gap-4 p-2">
                <div>
                    <div className="flex justify-between items-center gap-2 mb-1">
                        <p className="font-heading-bold text-heading-bold-m text-neutral-400">
                            Are you sure?
                        </p>
                        <button onClick={onClose}>
                            <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle" />
                        </button>
                    </div>
                    <p className="font-body text-body-regular-xs text-neutral-500">
                        You are about to make edits to this task’s variables, which will impact the
                        following goals.
                    </p>
                </div>

                {!!goals?.length && (
                    <ul className="flex flex-col gap-4">
                        {goals.map((goal) => (
                            <li key={goal.id} className="w-full p-2 bg-neutral-50 rounded-2">
                                <div className="flex justify-between items-center gap-2 mb-4">
                                    <p className="font-body text-body-bold-s text-neutral-500 ml-[14px] relative before:content-[''] before:block before:w-[8px] before:h-[8px] before:absolute before:bg-[#1A1A1A] before:rounded-full before:left-[-14px] before:top-[5px]">
                                        {goal.name}
                                    </p>
                                    <div className="flex items-center gap-1">
                                        <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap">
                                            Goal ID{' '}
                                            <span className="text-body-bold-xs text-neutral-500">
                                                {goal.id}
                                            </span>
                                        </p>
                                        <ButtonIcon
                                            type="link"
                                            size="xs"
                                            icon={ShareBoxLineIcon}
                                            onClick={() =>
                                                openGoalConfigurationPage(goal.id, goal.process_id)
                                            }
                                        />
                                    </div>
                                </div>
                                <p className="font-body text-body-regular-s text-neutral-500">
                                    {goal.description}
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
                {loading && (
                    <div className="py-2">
                        <Loading withText={false} />
                    </div>
                )}

                <div className="flex justify-between items-center gap-2">
                    <Button type="ghost" size="sm" text="No, Cancel" onClick={onClose} />
                    <Button type="primary" size="sm" text="Yes, Confirm" onClick={handleUnlock} />
                </div>
            </div>
        </Modal>
    );
};

export default ProtectedModeConfirmUnlockModal;
