import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import './jsonEditor.css';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setSyncTimeout } from '../../helpers/setSyncTimeout';

import JsonEditorDisabledState from './JsonEditorDisabledState/JsonEditorDisabledState';

JsonEditor.propTypes = {
    mode: PropTypes.oneOf(['view', 'code']).isRequired, // if user should be able to edit or create json use "code", otherwise use "view".
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
    setValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    autoExpand: PropTypes.bool,
};

function JsonEditor({ value, setValue, mode, disabled = false, autoExpand }) {
    const valueRef = useRef(value);

    const containerRef = useRef(null);
    const editor = useRef(null);
    const isFocused = useRef(false);

    const { width: screenWidth } = useWindowSize();

    const [jsonEditorHeight, setJsonEditorHeight] = useState(0);

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            const options = {
                mode,
                onChangeText: (value) => {
                    valueRef.current = value;
                    setValue(value);

                    if (autoExpand && mode === 'code') {
                        setSyncTimeout(updateEditorHeight, 20);
                    }
                },
                search: false,
                onFocus: () => (isFocused.current = true),
                onBlur: () => (isFocused.current = false),
            };
            editor.current = new JSONEditor(container, options);
            editor.current.set(value);
        }

        return () => {
            if (editor.current) {
                editor.current.destroy();
            }
        };
    }, [containerRef]);

    useLayoutEffect(() => {
        if (JSON.stringify(valueRef.current) !== JSON.stringify(value) && editor.current) {
            editor.current.set(value);
            valueRef.current = value;
        }
    }, [JSON.stringify(value)]);

    useEffect(() => {
        const handleFormatJson = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 's') {
                event.preventDefault();

                if (!isFocused.current) return;

                try {
                    const json = editor.current.get();
                    if (typeof json === 'object') {
                        editor.current.update(json);
                    }
                } catch (error) {}
            }
        };

        window.addEventListener('keydown', handleFormatJson);

        return () => {
            window.removeEventListener('keydown', handleFormatJson);
        };
    }, []);

    function updateEditorHeight() {
        if (editor.current && mode === 'code') {
            const height = editor.current.aceEditor?.renderer?.layerConfig?.maxHeight;

            if (height) {
                setJsonEditorHeight(height);
            }
        }
    }

    useEffect(() => {
        if (autoExpand) {
            updateEditorHeight();
        }
    }, [editor.current, screenWidth, autoExpand, disabled]);

    const containerClassName = classNames('min-h-full', {
        'editor-view-mode': mode === 'view',
        'editor-code-mode': mode === 'code',
        'flex-grow': !autoExpand,
        hidden: disabled,
    });
    const style = {
        height:
            autoExpand && !disabled
                ? `${jsonEditorHeight + 100}px`
                : !autoExpand && disabled
                ? `100px` // the editor will have a height of at least 100 pixels, but it will expand to fill the entire height of its parent component due to the minHeight: '100%' property.
                : '100%',
    };

    return (
        <>
            <div className={containerClassName} ref={containerRef} style={style}></div>

            {disabled && <JsonEditorDisabledState value={value} autoExpand={autoExpand} />}
        </>
    );
}

export default JsonEditor;
