import React, { memo } from 'react';

import * as API from '../../../constants/api';
import client from '../../../services/knowledge-api';

import { SORT_TYPES } from '../../../constants/sort';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import UnconfirmedDocumentBlock from './UnconfirmedDocumentBlock/UnconfirmedDocumentBlock';
import ConfirmedDocumentBlock from './ConfirmedDocumentBlock/ConfirmedDocumentBlock';

const DocumentBlock = ({
    blockData,
    editMode = false,
    handleDeleteBlock,
    handleBlockPropertyChange,
    documentData = null,
    setLinkedDocsData,
}) => {
    const {
        properties: { document_id },
    } = blockData;

    const isDocumentConfirmed = !!document_id;

    const formatResponseToOptions = (results) =>
        results?.map(({ id, label, value }) => ({
            id,
            name: label,
            content: value || '',
        }));

    const docsOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.knowledge.document,
        searchParams: { ordering: SORT_TYPES.alphabeticalByLabel },
        formatResponseToOptions,
    });

    const setDocumentIdInBlockData = (document_id) => {
        handleBlockPropertyChange('document_id', document_id);
    };

    const handleConfirmDocument = (document) => {
        const { id, name, content } = document;
        setDocumentIdInBlockData(id);
        setLinkedDocsData((prevData) => {
            if (prevData[id]) {
                return prevData;
            }
            return { ...prevData, [id]: { label: name, content } };
        });
    };

    return (
        <>
            {isDocumentConfirmed && (
                <ConfirmedDocumentBlock
                    isEditable={editMode}
                    documentId={document_id}
                    documentData={documentData}
                    handleDeleteBlock={handleDeleteBlock}
                    setLinkedDocsData={setLinkedDocsData}
                />
            )}

            {!isDocumentConfirmed && (
                <UnconfirmedDocumentBlock
                    isEditable={editMode}
                    docsOptionsHookResponse={docsOptionsHookResponse}
                    handleConfirmDocument={handleConfirmDocument}
                    handleDeleteBlock={handleDeleteBlock}
                />
            )}
        </>
    );
};

export default memo(DocumentBlock);
