import React from 'react';
import PropTypes from 'prop-types';

SvgIcon.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge', 'rectangle']),
    icon: PropTypes.elementType.isRequired,
    color: PropTypes.string.isRequired,
};

function SvgIcon({ icon: Icon, size, color }) {
    let iconWidth = '';
    let iconHeight = '';
    if (size === 'small') {
        iconWidth = 12;
        iconHeight = 12;
    } else if (size === 'medium') {
        iconWidth = 16;
        iconHeight = 16;
    } else if (size === 'extraLarge') {
        iconWidth = 36;
        iconHeight = 36;
    } else if (size === 'rectangle') {
        iconWidth = 31;
        iconHeight = 18;
    } else {
        iconWidth = 24;
        iconHeight = 24;
    }

    return (
        <div className="grow-0 shrink-0 basis-auto flex">
            <Icon width={iconWidth} height={iconHeight} color={color} />
        </div>
    );
}

export default SvgIcon;
