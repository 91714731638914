import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TEMPLATES_LIBRARY_TABS } from '../../../constants/template';
import { createNewTemplateRoute } from '../TemplatesLibraryPage';

import { AddCircleLineIcon, TerminalBoxLineIcon } from '../../../design-system/Icons';
import { Button } from '../../../design-system';

const EmptyIndexPage = ({ activeTab }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleNewTemplateClick = () => {
        navigate(createNewTemplateRoute[activeTab], {
            state: { templatesLibraryLocation: location },
        });
    };

    return (
        <div className="flex flex-col py-[80px] items-center gap-4 self-stretch">
            <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full">
                <TerminalBoxLineIcon width={32} height={32} color={'#5E6470'} />
            </div>
            <div className="flex flex-col items-center gap-2">
                <p className="font-heading-bold text-heading-bold-m text-center">{`You don’t have any ${
                    activeTab === TEMPLATES_LIBRARY_TABS.goals ? 'Goal' : 'Process'
                } Templates yet.`}</p>
                <p className="font-body text-bold-m text-center">
                    Try creating one by clicking “New Template”
                </p>
            </div>
            <Button
                type="secondary"
                size="xs"
                text="New Template"
                leadingIcon={AddCircleLineIcon}
                onClick={handleNewTemplateClick}
            />
        </div>
    );
};

export default EmptyIndexPage;
