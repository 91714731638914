import React from 'react';
import { ListOption, ListOptionGroup } from '../../../../design-system';
import { useLocation, useNavigate } from 'react-router-dom';

const ProcessVersionMoreOptionPopup = ({
    processTemplateId,
    processTemplateVersionId,
    onClose,
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleViewTemplate = () => {
        navigate(`/templates/process/${processTemplateId}/branch/${processTemplateVersionId}`, {
            state: { from: location },
        });
    };

    return (
        <div className="absolute right-0 top-[90%] z-10">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="shareBoxLine"
                            text="View Template"
                            onClick={handleViewTemplate}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default ProcessVersionMoreOptionPopup;
