import { useEffect, useRef, useState } from 'react';

// The range number is determined by comparing the element's width against a provided array of breakpoints
export const useResponsiveRange = (breakpointsArray = [0]) => {
    const elementRef = useRef(null);
    const [rangeNumber, setRangeNumber] = useState(null); // starts from 1

    useEffect(() => {
        const updateWidth = () => {
            if (elementRef.current) {
                const width = elementRef.current.offsetWidth;

                const index = breakpointsArray.findIndex((breakpoint) => width < breakpoint);
                if (index === -1) {
                    setRangeNumber(breakpointsArray.length);
                    return;
                }

                setRangeNumber(index);
            }
        };

        updateWidth();

        window.addEventListener('resize', updateWidth);

        return () => window.removeEventListener('resize', updateWidth);
    }, [elementRef.current]);

    return [elementRef, rangeNumber];
};
