import React from 'react';
import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';
import Modal from '../../../design-system/Modal/Modal';
import Loading from '../../../components/Loading';

const JobTaskPageModalContainer = ({ onClose, title, isDataLoaded, children }) => {
    return (
        <Modal size="large" onClose={onClose} resetPadding>
            <div className="relative max-h-[80vh] sm:max-h-[68vh] overflow-y-auto hide-scrollbar">
                <div className="py-3 px-4 sm:px-8 border-b-1 border-neutral-200 flex items-center justify-between gap-3">
                    <p className="font-heading-bold text-heading-bold-m">{title}</p>
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                </div>

                {isDataLoaded && <div className="pt-5 pb-[60px] px-4 sm:px-8">{children}</div>}

                {!isDataLoaded && (
                    <div className="flex justify-center py-[48px]">
                        <Loading withText={false} />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default JobTaskPageModalContainer;
