import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import { DateTime } from 'luxon';

import { statusBadge as jobsStatusBadge } from '../../../constants/statuses';

import { Badge, ButtonIcon, SvgIcon } from '../../../design-system';
import { FlowChartIcon, More2FillIcon } from '../../../design-system/Icons';
import RenameProjectModal from '../RenameProjectModal/RenameProjectModal';
import PlayProcessIcon from '../../../design-system/Icons/PlayProcessIcon';
import ProjectCardMoreOptionsPopup from '../ProjectCardMoreOptionsPopup/ProjectCardMoreOptionsPopup';
import ConfirmArchiveProjectModal from '../ConfirmArchiveProjectModal/ConfirmArchiveProjectModal';

const ProjectCard = ({ data: project, setData: setProjects, requestKeysToMutate }) => {
    const { id, name, playbook, status, updated_at } = project;
    const location = useLocation();

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);
    const [actionModal, setActionModal] = useState({ action: null });

    const statusBadge = jobsStatusBadge[status];
    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');

    const openMoreOptionsPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setIsMoreOptionsPopupOpened(true);
    };

    const cardContainerClassName = classNames(
        'px-4 py-3 max-w-full w-full rounded-2 bg-white',
        'grid grid-rows-[auto_auto] min-[910px]:grid-rows-1 grid-cols-[32px_minmax(100px,_1fr)_28px] min-[690px]:grid-cols-[32px_minmax(100px,_1fr)_90px_28px] min-[910px]:grid-cols-[32px_minmax(100px,_1fr)_90px_222px_28px] gap-x-4 min-[1400px]:gap-x-6 gap-y-2 items-start min-[690px]:items-center'
    );

    const statusContainer = classNames(
        'flex justify-start min-h-[24px] items-center gap-4 min-[690px]:justify-end w-full row-start-2 row-end-2 col-span-2 min-[690px]:row-start-auto min-[690px]:row-end-auto min-[690px]:col-span-1'
    );

    return (
        <>
            <Link
                key={id}
                to={`/project/${id}`}
                state={{ from: location }}
                className={cardContainerClassName}
            >
                <div className="h-full place-content-center">
                    <div className="w-8 h-8 rounded-2 bg-purple-100 flex items-center justify-center">
                        <SvgIcon color="#754DCF" icon={FlowChartIcon} size="medium" />
                    </div>
                </div>

                <div className="grid grid-cols-1 min-[1100px]:grid-cols-[minmax(100px,_1.5fr)_minmax(100px,_1fr)] gap-x-4 xl:gap-x-6 gap-y-1 items-center">
                    <p className="font-body-bold text-body-bold-s text-black truncate max-w-full row-start-2 row-end-2 min-[1100px]:row-start-1 min-[1100px]:row-end-1">
                        {name}
                    </p>
                    <div className="max-w-full row-start-1 row-end-1 flex items-center">
                        <Badge
                            text={playbook?.name}
                            color="neutral"
                            leadingIcon={PlayProcessIcon}
                            leadingIconColor="#000000"
                        />
                    </div>
                </div>

                <div className={statusContainer}>
                    <Badge text={statusBadge.text} color={statusBadge.color} />
                </div>

                <div className="pt-2 min-[910px]:p-0 border-t-1 border-neutral-200 min-[910px]:border-none row-start-3 row-end-3 col-span-full min-[690px]:row-start-2 min-[690px]:row-end-2 min-[910px]:row-start-auto min-[910px]:row-end-auto min-[910px]:col-span-1">
                    <p className="font-body text-body-regular-xs text-neutral-300 truncate max-w-full">
                        Last Updated{' '}
                        <span className="font-body-bold text-body-bold-xs text-neutral-500">
                            {formattedTime}
                        </span>
                    </p>
                </div>

                <div className="relative">
                    <ButtonIcon
                        icon={More2FillIcon}
                        onClick={openMoreOptionsPopup}
                        type="link"
                        size="xs"
                    />

                    {isMoreOptionsPopupOpened && (
                        <ProjectCardMoreOptionsPopup
                            setActionModal={setActionModal}
                            onClose={() => setIsMoreOptionsPopupOpened(false)}
                        />
                    )}
                </div>
            </Link>

            {actionModal.action === 'rename' && (
                <RenameProjectModal
                    projectId={id}
                    projectName={name}
                    setProjects={setProjects}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setActionModal({ action: null })}
                />
            )}

            {actionModal.action === 'archive' && (
                <ConfirmArchiveProjectModal
                    projectId={id}
                    projectName={name}
                    setProjects={setProjects}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setActionModal({ action: null })}
                />
            )}
        </>
    );
};

export default memo(ProjectCard);
