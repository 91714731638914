import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loading from '../../components/Loading';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useClickOutside } from '../../hooks';
import { Badge } from '../index';
import ClientSideFilteredSearchBar from '../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { useClientSideFilterBar } from '../../hooks/useClientSideFilterBar';

PaginatedDropdown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            badgeLeadingIcon: PropTypes.func, // if every option has specific badge icon use this state
            badgeLeadingIconColor: PropTypes.string, // if every option has specific badge icon color use this state
            badgeColor: PropTypes.string, // if every option has specific badge color use this state
        })
    ).isRequired,
    selectedOption: PropTypes.string, // id of selected option
    optionsLoading: PropTypes.bool,
    handleOptionSelect: PropTypes.func.isRequired,
    showOptionsAsBadge: PropTypes.bool,
    badgeLeadingIcon: PropTypes.func, // if every option has the same badge icon use this state
    badgeLeadingIconColor: PropTypes.func, // if every option has the same badge icon color use this state
    badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']), // if every option has the same badge color use this state
    canLoadMore: PropTypes.bool,
    highlightSelectedOption: PropTypes.bool,
    fetchOptions: PropTypes.func,
    dropdownHeight: PropTypes.number,
    dropdownMaxHeight: PropTypes.number,
    fixedWidth: PropTypes.number,
    closeDropdown: PropTypes.func,
    includeClientSideFiltering: PropTypes.bool,
    totalOptionsCount: PropTypes.number, // is required if includeClientSideFiltering = true (the count of all options across all pages)
    startingOptionCountForFilterBar: PropTypes.number, // if includeClientSideFiltering = true, then the Filtering SearchBar will be displayed if totalOptionsCount >= startingOptionCountForFilterBar
};

function PaginatedDropdown({
    options,
    selectedOption,
    optionsLoading,
    handleOptionSelect,
    showOptionsAsBadge,
    showOptionsAsRemovableBadge,
    highlightSelectedOption = true,
    handleOptionRemove,
    badgeColor,
    badgeLeadingIcon,
    badgeLeadingIconColor,
    canLoadMore,
    dropdownHeight,
    dropdownMaxHeight = 240,
    fixedWidth = 310,
    fetchOptions,
    closeDropdown,
    includeClientSideFiltering = false,
    totalOptionsCount = 0,
    startingOptionCountForFilterBar = 6,
}) {
    const { width: screenWidth } = useWindowSize();
    const dropdownRef = useRef(null);
    useClickOutside(dropdownRef, closeDropdown);

    const showClientSideFilterBar =
        includeClientSideFiltering && totalOptionsCount >= startingOptionCountForFilterBar;

    const { filteredOptions, setFilteredOptions } = useClientSideFilterBar({
        options,
        showClientSideFilterBar,
    });

    const { guardRef, isNextPageLoading } = useIntersectionObserver({
        handleFetch: fetchOptions,
        options,
        optionsLoading,
        canLoadMore,
    });

    const width =
        screenWidth >= 480 && screenWidth < 640 ? '75vw' : screenWidth >= 640 ? fixedWidth : '85vw';

    return (
        <div
            className={`mt-1 w-full overflow-y-auto rounded-2 bg-white py-2 px-2 text-base shadow-l1 border-1 border-neutral-200 sm:text-sm flex flex-col gap-1`}
            style={{
                maxHeight: `${dropdownMaxHeight}px`,
                height: dropdownHeight ? `${dropdownHeight}px` : 'auto',
                width,
            }}
            ref={dropdownRef}
        >
            {optionsLoading && !options.length && (
                <div className="w-full min-h-[92px] h-full flex items-center justify-center">
                    <Loading withText={false} />
                </div>
            )}
            {!!options.length && (
                <>
                    {showClientSideFilterBar && (
                        <div className="pl-3 pr-2 pt-2 pb-1.5">
                            <ClientSideFilteredSearchBar
                                list={options || []}
                                setFilteredList={setFilteredOptions}
                                size="xs"
                            />
                        </div>
                    )}
                    {filteredOptions.map((option) => (
                        <div
                            key={option.id}
                            onClick={() => {
                                handleOptionSelect(option.id, option);
                            }}
                            className={classNames(
                                option.id === selectedOption && highlightSelectedOption
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-neutral-500',
                                'relative cursor-pointer select-none py-1 px-2 rounded-2 hover:bg-gray-100 hover:text-gray-900' // can be updated
                            )}
                        >
                            {showOptionsAsBadge ? (
                                <Badge
                                    text={option.name}
                                    color={option.badgeColor || badgeColor}
                                    leadingIcon={option.badgeLeadingIcon || badgeLeadingIcon}
                                    leadingIconColor={
                                        option.badgeLeadingIconColor || badgeLeadingIconColor
                                    }
                                />
                            ) : showOptionsAsRemovableBadge ? (
                                <Badge
                                    text={option.name}
                                    color={option.badgeColor || badgeColor}
                                    leadingIcon={option.badgeLeadingIcon || badgeLeadingIcon}
                                    leadingIconColor={
                                        option.badgeLeadingIconColor || badgeLeadingIconColor
                                    }
                                    removeable={option.id === selectedOption}
                                    handleRemove={handleOptionRemove}
                                    withBorder={option.id === selectedOption}
                                />
                            ) : (
                                <span
                                    className={classNames(
                                        option.id === selectedOption
                                            ? 'font-semibold'
                                            : 'font-normal',
                                        'block truncate' // can be updated
                                    )}
                                >
                                    {option.name}
                                </span>
                            )}
                        </div>
                    ))}

                    <div ref={guardRef}></div>
                    {isNextPageLoading && (
                        <div
                            className={`${
                                !!filteredOptions?.length ? 'h-[38px]' : 'h-[60px]'
                            } flex justify-center items-center`}
                        >
                            <Loading withText={false} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default PaginatedDropdown;
