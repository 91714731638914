import PropTypes from 'prop-types';
import Badge from 'design-system/Badge/Badge';
import { FullscreenLineIcon } from 'design-system/Icons';
import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import { iconNameMap } from 'design-system/Icons/GenericIcon';
import { ButtonIcon, ListOptionGroup } from '../index';
import { More2FillIcon } from '../Icons';
import LockIcon from '../Icons/LockIcon';
import ToolTip from '../ToolTip/ToolTip';
import React, { useEffect, useRef, useState } from 'react';

export default function Card(props) {
    const {
        iconText,
        iconColor = '#F0F5FE',
        title,
        description,
        openOnClick,
        tagList,
        metaTagLabel,
        metaTagText,
        leadingBadge,
        openPanelOnClick,
        withShowMoreButton = false,
        onShowMoreButtonClick,
        showMoreOptionsList,
        showMoreOpened,
        bottomRightContent,
        showFullScreenButton = true,
    } = props;

    const [showNameToolTip, setShowNameTooltip] = useState(false);
    const [footerRightSideWidth, setFooterRightSideWidth] = useState(0);

    const footerRightSideRef = useRef(null);

    useEffect(() => {
        if (footerRightSideRef.current) {
            setFooterRightSideWidth(footerRightSideRef.current.offsetWidth);
        }
    }, [footerRightSideRef, bottomRightContent]);

    return (
        <div
            className="rounded-lg bg-white min-w-72 hover:shadow-l1 cursor-pointer"
            onClick={openPanelOnClick || (() => {})}
        >
            <div className="px-2 py-2 xs:px-4 xs:pt-4 xs:pt-2">
                <div className="flex justify-start items-stretch flex-col basis-auto box-border">
                    <div className="flex justify-between items-center flex-row gap-2 box-border w-full max-w-[100%]">
                        <div
                            className="flex justify-start items-center flex-row box-border flex-grow"
                            style={{
                                width: `calc(100% - ${withShowMoreButton ? '60px' : '24px'})`,
                            }}
                        >
                            {iconText && (
                                <div
                                    className={`w-[36px] h-[36px] rounded-2 flex items-center justify-center mr-2 p-[6px]`}
                                    style={{ backgroundColor: iconColor }}
                                >
                                    <p className="grow-0 shrink-0 basis-auto box-border font-inter text-base font-bold text-white">
                                        <Emoji
                                            unified={iconText}
                                            emojiStyle={EmojiStyle.APPLE}
                                            size={22}
                                        />
                                    </p>
                                </div>
                            )}
                            <p
                                className="w-[calc(100%-44px)] flex-grow basis-auto box-border"
                                onMouseEnter={() => setShowNameTooltip(true)}
                                onMouseLeave={() => setShowNameTooltip(false)}
                            >
                                <ToolTip
                                    text={title}
                                    position="top-left"
                                    isShown={showNameToolTip}
                                    shift="-14px"
                                >
                                    <p className="w-full flex-grow basis-auto box-border text-base font-bold text-black truncate">
                                        {title}
                                    </p>
                                </ToolTip>
                            </p>
                        </div>
                        {showFullScreenButton && (
                            <button className="" onClick={openOnClick}>
                                <SvgIcon icon={FullscreenLineIcon} size="medium" color="#1f2125" />
                            </button>
                        )}
                        {withShowMoreButton && (
                            <div className="relative">
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    icon={More2FillIcon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onShowMoreButtonClick();
                                    }}
                                />
                                {showMoreOpened && <>{showMoreOptionsList}</>}
                            </div>
                        )}
                    </div>
                    <div className="min-h-10 mt-4">
                        <p className="line-clamp-2 basis-auto box-border font-inter text-body-regular-s font-normal leading-[123.94366455078125%] text-[#5e6470]">
                            {description}
                        </p>
                    </div>
                </div>
                <div
                    className={`max-w-full w-full flex justify-between items-center flex-row grow-0 shrink-0 basis-auto box-border mt-2 border-b-0 border-x-0 border-t border-[#cfd6e5] border-solid ${
                        bottomRightContent ? 'pt-1' : 'py-2'
                    }`}
                >
                    {metaTagLabel && (
                        <div
                            className="flex justify-start items-center flex-row shrink-0 box-border flex-grow truncate"
                            style={{ width: `calc(100% - ${footerRightSideWidth}px)` }}
                        >
                            <p className="box-border font-inter text-body-regular-xs font-bold text-[#1f2125]">
                                {metaTagLabel}
                            </p>
                            <div className="flex justify-start items-center flex-row flex-grow shrink-0 box-border ml-1 truncate">
                                <p className="w-full grow-0 shrink-0 box-border font-intern text-body-regular-xs font-body text-[#1f2125] truncate">
                                    {metaTagText}
                                </p>
                            </div>
                        </div>
                    )}
                    {leadingBadge && (
                        <div style={{ width: `calc(100% - ${footerRightSideWidth}px)` }}>
                            <Badge
                                text={leadingBadge.text}
                                color={leadingBadge.color || 'peach'}
                                leadingIcon={leadingBadge.icon}
                            />
                        </div>
                    )}
                    <div
                        className="flex justify-end items-center flex-row shrink-0 space-x-2"
                        ref={footerRightSideRef}
                    >
                        {tagList && tagList[0] && (
                            <Badge
                                leadingEmoji={tagList[0].emojiCode}
                                leadingIcon={tagList[0].icon ? iconNameMap[tagList[0].icon] : null}
                                text={tagList[0].label}
                                color={tagList[0].color || 'neutral'}
                            />
                        )}
                        {bottomRightContent && <>{bottomRightContent}</>}
                    </div>
                </div>
            </div>
        </div>
    );
}

Card.propTypes = {
    iconText: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    openOnClick: PropTypes.func,
    tagList: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            iconName: PropTypes.string,
            color: PropTypes.string,
            emojiCode: PropTypes.string,
        })
    ),
    leadingBadge: PropTypes.shape({
        text: PropTypes.string.isRequired,
        color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
        icon: PropTypes.func,
    }),
    metaTagLabel: PropTypes.string,
    metaTagText: PropTypes.string,
    settingOnClick: PropTypes.func,
    openPanelOnClick: PropTypes.func,
    withShowMoreButton: PropTypes.bool,
    handleShowMoreClick: PropTypes.func,
    bottomRightContent: PropTypes.node,
    showMoreOptionsList: PropTypes.node,
    onShowMoreButtonClick: PropTypes.func,
    showMoreOpened: PropTypes.bool,
};
