import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import { CloseLineIcon, Search2LineIcon } from 'design-system/Icons';
import ArrowRightIcon from 'design-system/Icons/ArrowRightIcon';
import { useSearchParams } from 'react-router-dom';

SearchBar.propTypes = {
    handleSearch: PropTypes.func,
    isModal: PropTypes.bool,
};
function SearchBar(props) {
    const { handleSearch, isModal = false } = props;

    const [searchParams, setSearchParams] = useSearchParams();
    const [inputValue, setInputValue] = useState(() =>
        isModal
            ? { value: '' }
            : {
                  value: searchParams.get('query') || '',
                  isInitial: true,
              }
    );

    useEffect(() => {
        if (!isModal) {
            setInputValue({
                value: searchParams.get('query') || '',
                isInitial: true,
            });
        }
    }, [searchParams]);

    const handleInputChange = (e) => {
        setInputValue({ value: e.target.value, isInitial: false });
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();

        setSearchParams({ query: inputValue.value.trim(), page: 1 });
        if (handleSearch) {
            handleSearch(inputValue.value);
        }
    };

    const handleClearIconClick = (e) => {
        e.preventDefault();
        setSearchParams({ query: '', page: 1 });
        setInputValue({ value: '' });
    };

    const inputClassName = classNames(
        'w-full py-[8px] pl-[46px] bg-white rounded-2 font-body text-body-regular-m text-neutral-500 placeholder-neutral-300 focus:placeholder-transparent caret-neutral-500 border-1 focus:outline-0 focus:border-1 focus:shadow-l3 border-neutral-300 focus:shadow-neutral-300 transition-all ease-in',
        {
            'pr-[12px]': !inputValue.value,
            'pr-[46px]': inputValue.value,
        }
    );

    return (
        <div>
            <form className="w-full" onSubmit={handleSearchSubmit}>
                <div className="w-full relative">
                    <input
                        className={inputClassName}
                        autoFocus={isModal}
                        type="text"
                        value={inputValue.value}
                        placeholder="Search all prompts and tasks ..."
                        onChange={handleInputChange}
                    />
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                        <div className="grow-0 shrink-0 basis-auto flex">
                            <Search2LineIcon width={20} height={20} color={'#000000'} />
                        </div>
                    </span>
                    {inputValue.value && !inputValue.isInitial && (
                        <span
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                            onClick={handleSearchSubmit}
                        >
                            <SvgIcon color="#000000" icon={ArrowRightIcon} size="large" />
                        </span>
                    )}
                    {inputValue.value && inputValue.isInitial && !isModal && (
                        <span
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                            onClick={handleClearIconClick}
                        >
                            <SvgIcon color="#000000" icon={CloseLineIcon} size="large" />
                        </span>
                    )}
                </div>
            </form>
            {isModal && (
                <p className="font-body text-body-regular-xs text-[#A0A2A5] mt-[4px]">
                    Use command + K for quick search
                </p>
            )}
        </div>
    );
}

export default SearchBar;
