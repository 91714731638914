import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../index';

EmptyView.propTypes = {
    icon: PropTypes.func.isRequired,
    mainText: PropTypes.string.isRequired,
    subtext: PropTypes.string.isRequired,
    withButton: PropTypes.bool,
    buttonText: PropTypes.string.isRequired,
    handleButtonClick: PropTypes.func.isRequired,
    buttonLeadingIcon: PropTypes.func,
    buttonHelperText: PropTypes.string,
    buttonState: PropTypes.oneOf(['disabled', 'loading', 'default']),
    mainTextAdditionalStyles: PropTypes.string,
};

function EmptyView({
    icon: Icon,
    mainText,
    subtext,
    withButton = true,
    buttonText,
    buttonLeadingIcon = null,
    buttonHelperText,
    handleButtonClick,
    buttonState = 'default',
    mainTextAdditionalStyles = '',
}) {
    const mainTextClassName = classNames(
        'font-heading-bold text-heading-bold-m text-center max-w-[420px]',
        mainTextAdditionalStyles
    );

    return (
        <div className="py-[80px] mx-auto flex-grow px-4">
            <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full mb-4 mx-auto">
                <Icon width={32} height={32} color="#5E6470" />
            </div>
            <div className="flex flex-col items-center gap-1 text-black mb-4">
                <p className={mainTextClassName}>{mainText}</p>
                <p className="font-body text-body-regular-m max-w-[400px] text-center">{subtext}</p>
            </div>
            {withButton && (
                <div className="flex justify-center">
                    <Button
                        type="primary"
                        size="xs"
                        state={buttonState}
                        text={buttonText}
                        helperText={buttonHelperText}
                        leadingIcon={buttonLeadingIcon}
                        onClick={handleButtonClick}
                    />
                </div>
            )}
        </div>
    );
}

export default EmptyView;
