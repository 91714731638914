import React, { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { useClickOutside } from '../../../hooks';
import { searchParamsKeys } from '../../../helpers/getJobsRequestQueryParams';
import { FORMATTED_STATUS_OPTIONS } from '../../../constants/jobs';
import { TAB } from '../../../constants/jobsAndProjects';

import { Badge } from '../../../design-system';
import SearchBar from '../../../design-system/SearchBar/SearchBar';
import Checkbox from '../../../design-system/Checkbox/Checkbox';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';

const FilterDropdown = ({
    activeTab,
    paginatedOptionsHookResponse,
    parsedParams,
    handleClose,
    isMobile,
    resetPageState,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const popupRef = useRef(null);

    const [searchValue, setSearchValue] = useState('');
    const loweredSearchValue = searchValue.toLowerCase();

    const activeTabFilter = {
        [TAB.jobs]: 'agent',
        [TAB.projects]: 'playbook',
    }[activeTab];

    const [selectedFilters, setSelectedFilters] = useState({
        status: parsedParams.status || [],
        [activeTabFilter]: parsedParams[activeTabFilter] || [],
    });

    const {
        options: secondGroupOptions,
        optionsLoading,
        setPage,
        canLoadMoreOptions,
    } = paginatedOptionsHookResponse;

    const groupedOptions = useMemo(
        () => [
            { groupLabel: 'status', options: FORMATTED_STATUS_OPTIONS },
            { groupLabel: activeTabFilter, options: secondGroupOptions },
        ],
        [secondGroupOptions]
    );

    const filteredGroupedOptions = useMemo(() => {
        if (!loweredSearchValue) {
            return groupedOptions;
        }

        return groupedOptions?.map(({ options, ...rest }) => ({
            ...rest,
            options: options?.filter((option) =>
                option.name?.toLowerCase()?.includes(loweredSearchValue)
            ),
        }));
    }, [groupedOptions, loweredSearchValue]);

    const groupsToDisplay =
        !loweredSearchValue || optionsLoading || canLoadMoreOptions
            ? filteredGroupedOptions.filter(
                  ({ groupLabel, options }) => groupLabel !== 'status' || !!options?.length
              )
            : filteredGroupedOptions.filter(({ options }) => !!options?.length);

    const setUpdatedFiltersToSearchParams = () => {
        const urlSearchParams = new URLSearchParams(searchParams);

        const filters = Object.entries(selectedFilters).map(([key, value]) => {
            const searchKey = searchParamsKeys[key];
            return [searchKey, value.join(',')];
        });

        filters.forEach(([key, value]) => {
            if (value) {
                urlSearchParams.set(key, value);
            }
            if (!value) {
                urlSearchParams.delete(key);
            }
        });

        if (!isMobile) {
            urlSearchParams.set('page', '1');
        }

        setSearchParams(urlSearchParams);
        resetPageState();
    };

    const handleOptionSelect = (e, groupLabel, optionId) => {
        e?.stopPropagation();
        e?.preventDefault();

        setSelectedFilters((prev) => {
            const groupFilter = prev[groupLabel];
            if (groupFilter?.includes(optionId)) {
                return { ...prev, [groupLabel]: groupFilter.filter((id) => id !== optionId) };
            }
            return { ...prev, [groupLabel]: [...prev[groupLabel], optionId] };
        });
    };

    const popupClassName = classNames(
        'absolute top-[120%] left-0  z-20 w-[85vw] min-[400px]:w-[80vw] min-[515px]:w-[392px] max-h-[360px] sm:max-h-[450px] max-w-[392px] rounded-2 bg-white shadow-l1 border-1 border-neutral-200 flex flex-col gap-3 pt-4'
    );

    const closeAndSaveFilters = () => {
        setUpdatedFiltersToSearchParams();
        handleClose();
    };

    useClickOutside(popupRef, closeAndSaveFilters);

    return (
        <div className={popupClassName} ref={popupRef}>
            <div className="px-4">
                <SearchBar
                    size="md"
                    value={searchValue}
                    placeholder="Search ..."
                    onChange={(e) => setSearchValue(e.target.value)}
                    withClearIcon
                    showClearIconIfValueIsNotEmpty
                    onClear={() => setSearchValue('')}
                    onSubmit={() => {}}
                />
            </div>

            <div className="flex-1 overflow-y-auto">
                <div className="pb-4 flex flex-col gap-3">
                    {groupsToDisplay.map(({ groupLabel, options }) => (
                        <div key={groupLabel} className="flex flex-col gap-4 px-4">
                            <p className="uppercase text-neutral-300 font-body text-body-regular-xs">
                                By {[groupLabel]}
                            </p>

                            {groupLabel === 'status' && (
                                <ul>
                                    {options.map(({ id, name, color }) => (
                                        <li
                                            key={id}
                                            className="p-2 flex gap-2 items-center hover:bg-neutral-50 rounded-2 cursor-pointer"
                                            onClick={(e) => handleOptionSelect(e, 'status', id)}
                                        >
                                            <Checkbox
                                                name={id}
                                                isChecked={
                                                    selectedFilters.status?.includes(id) ?? false
                                                }
                                                label={<Badge text={name} color={color} />}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            )}

                            {groupLabel === activeTabFilter && (
                                <div>
                                    <InfiniteScrollList
                                        handleFetch={() => setPage((page) => page + 1)}
                                        canLoadMore={canLoadMoreOptions}
                                        items={options}
                                        loading={optionsLoading}
                                        gap={0}
                                    >
                                        <ul className="flex flex-col">
                                            {options.map(({ id, name }) => {
                                                const isChecked =
                                                    selectedFilters[groupLabel]?.includes(id) ??
                                                    false;
                                                const label = (
                                                    <p className="font-body text-body-regular-s text-neutral-500">
                                                        {name}
                                                    </p>
                                                );

                                                return (
                                                    <li
                                                        key={id}
                                                        className="p-2 flex gap-2 items-center hover:bg-neutral-50 rounded-2 cursor-pointer"
                                                        onClick={(e) =>
                                                            handleOptionSelect(e, groupLabel, id)
                                                        }
                                                    >
                                                        <Checkbox
                                                            isChecked={isChecked}
                                                            name={id}
                                                            onChange={() => {}}
                                                            label={label}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </InfiniteScrollList>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FilterDropdown;
