//This function adds newData to currentData, but if currentData already contains elements with the same IDs as those in newData, it will replace them
export const mergeNewDataIntoCurrent = (currentData, newData) => {
    const newDataMap = new Map(newData.map((item) => [item.id, item]));
    const arrIDs = [];

    const updatedCurrentData = currentData.map((item) => {
        const currentItemInNewData = newDataMap.get(item.id);
        if (currentItemInNewData) {
            arrIDs.push(item.id);
            return currentItemInNewData;
        } else {
            return item;
        }
    });

    return [...updatedCurrentData, ...newData.filter((item) => !arrIDs.includes(item.id))];
};

export const mergeNewDataAtTheTopOfCurrentData = (currentData, newData) => {
    const newDataArrIDs = newData.map((item) => item.id);

    return [...newData, ...currentData.filter((item) => !newDataArrIDs.includes(item.id))];
};
