import React, { useState } from 'react';
import { ButtonIcon } from '../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon, More2FillIcon } from '../../../design-system/Icons';
import { indicatorColors } from '../../../constants/runStatus';

const CollapsibleStatusCardContainer = ({
    status,
    lastUpdatedInfo,
    collapsedCardBlock,
    expandedCardBlock,
    moreOptionsBlock = null, // pass a function here that returns markup for the more options dropdown if needed. The argument of this function is a closeMoreOptions function
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showMoreOptions, setShowMoreOptions] = useState(false);

    const toggleIsExpanded = () => {
        setIsExpanded((prevState) => !prevState);
    };

    const statusIndicatorClassName = `${
        lastUpdatedInfo ? 'w-[6px] h-[6px]' : 'w-[7px] h-[7px]'
    }  rounded-full`;

    const closeMoreOptions = () => {
        setShowMoreOptions(false);
    };

    return (
        <div className="w-full flex">
            <div
                className={`flex flex-col gap-[2px] items-center px-[10px] ${
                    lastUpdatedInfo ? 'pt-[6px]' : 'pt-[10px]'
                }`}
            >
                <div
                    className={statusIndicatorClassName}
                    style={{ backgroundColor: indicatorColors[status] || '#CFD6E5' }}
                ></div>
                <div className="flex-grow w-[1px] bg-neutral-200"></div>
            </div>
            <div className="flex-grow">
                <div>
                    {lastUpdatedInfo && (
                        <p className="font-body text-body-bold-xs text-neutral-300 mb-[10px]">
                            Last Updated{' '}
                            <span className="text-body-regular-xs">{lastUpdatedInfo}</span>
                        </p>
                    )}
                    <div
                        className={`rounded-t-2 bg-white px-3 sm:px-4 py-3 flex items-start gap-2 sm:items-center sm:gap-4 justify-between cursor-pointer ${
                            !lastUpdatedInfo && 'rounded-b-2'
                        }`}
                        onClick={toggleIsExpanded}
                    >
                        <div className="flex-grow">{collapsedCardBlock}</div>
                        <div className="flex flex-col-reverse sm:items-center gap-2 sm:gap-4 sm:flex-row">
                            <ButtonIcon
                                type="link"
                                size="xs"
                                icon={isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                            />
                            {moreOptionsBlock && (
                                <div className="relative">
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        icon={More2FillIcon}
                                        onClick={(e) => {
                                            e?.stopPropagation();
                                            setShowMoreOptions(true);
                                        }}
                                    />
                                    {showMoreOptions && moreOptionsBlock(closeMoreOptions)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {isExpanded && expandedCardBlock}
            </div>
        </div>
    );
};

export default CollapsibleStatusCardContainer;
