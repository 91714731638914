import React, { useEffect } from 'react';

import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import JsonTextareaBox from '../../../../design-system/JsonTextareaBox/JsonTextareaBox';
import { checkAndUpdateUserInputsIfUsed } from '../../../../helpers/taskPlaygroundUtils';
import { TASK_TYPES } from '../../../../constants/library';

const PlotDefinitionBlock = ({
    definition,
    handleInputChange,
    fieldsErrorMessages,
    setUserInputs,
}) => {
    const { isDesktop } = useResponsiveBreakpoints();

    useEffect(() => {
        checkAndUpdateUserInputsIfUsed({
            dataArray: [definition],
            setUserInputs,
            taskType: TASK_TYPES.plot,
        });
    }, [definition]);

    return (
        <div className="flex-grow w-full flex flex-col gap-4">
            <JsonTextareaBox
                mode="code"
                value={definition}
                setValue={(value) => handleInputChange('definition', value)}
                label="Definition"
                isRequired
                state={fieldsErrorMessages.definition ? 'error' : 'default'}
                errorMessage={fieldsErrorMessages.definition}
                withCopyButton
                containerMinHeight={250}
                tipText="Add mapped input variables by {{input-key}}"
                autoExpand={!isDesktop}
            />
        </div>
    );
};

export default PlotDefinitionBlock;
