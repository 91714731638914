import React from 'react';

const ThumbsDownIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <g>
                <path
                    d="M11.3334 1.33325H13.1134C13.4907 1.32658 13.8573 1.45867 14.1436 1.70446C14.43 1.95025 14.6161 2.29262 14.6667 2.66658V7.33325C14.6161 7.70721 14.43 8.04958 14.1436 8.29537C13.8573 8.54116 13.4907 8.67325 13.1134 8.66658H11.3334M6.66669 9.99991V12.6666C6.66669 13.197 6.8774 13.7057 7.25247 14.0808C7.62755 14.4559 8.13625 14.6666 8.66669 14.6666L11.3334 8.66658V1.33325H3.81335C3.4918 1.32961 3.17977 1.44231 2.93475 1.65058C2.68973 1.85884 2.52824 2.14864 2.48002 2.46658L1.56002 8.46658C1.53102 8.65768 1.5439 8.85279 1.59779 9.03841C1.65168 9.22403 1.74529 9.39572 1.87211 9.54157C1.99894 9.68743 2.15597 9.80396 2.33231 9.8831C2.50864 9.96225 2.70008 10.0021 2.89335 9.99991H6.66669Z"
                    stroke={props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default ThumbsDownIcon;
