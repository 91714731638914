import React, { useState } from 'react';
import classNames from 'classnames';

import Loading from '../../Loading';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import ArrowRightSLineIcon from '../../../design-system/Icons/ArrowRightSLineIcon';
import { SvgIcon } from '../../../design-system';
import { ArrowDownSLineIcon } from '../../../design-system/Icons';

const OrganizationCard = ({ membership, changeTheDefaultOrganization, orgChangeLoadingId }) => {
    const { id, child_memberships } = membership;

    const [isExpanded, setIsExpanded] = useState(
        () => !!child_memberships?.find((childMembership) => childMembership.is_default)
    );
    const Icon = isExpanded ? ArrowDownSLineIcon : ArrowRightSLineIcon;

    return (
        <li className="w-full max-w-full flex flex-col gap-1">
            <div className="w-full max-w-full py-0.5 pl-0 pr-2 flex items-center justify-between gap-0 rounded-2 bg-white hover:bg-neutral-50 transition-colors cursor-pointer">
                <button
                    className="w-[40px] min-w-[40px] pl-2 flex items-center"
                    type="button"
                    onClick={() => setIsExpanded((prev) => !prev)}
                >
                    <SvgIcon color="#000000" icon={Icon} size="large" />
                </button>

                <OrganizationItemContent
                    membership={membership}
                    isLoading={orgChangeLoadingId === id}
                    changeTheDefaultOrganization={changeTheDefaultOrganization}
                />
            </div>

            {isExpanded && !!child_memberships?.length && (
                <ul className="flex flex-col gap-1">
                    {child_memberships.map((childMembership) => {
                        return (
                            <OrganizationItemContent
                                key={childMembership.id}
                                isChild
                                membership={childMembership}
                                isLoading={orgChangeLoadingId === childMembership.id}
                                changeTheDefaultOrganization={changeTheDefaultOrganization}
                            />
                        );
                    })}
                </ul>
            )}
        </li>
    );
};

function OrganizationItemContent({
    membership,
    isLoading,
    isChild = false,
    changeTheDefaultOrganization,
}) {
    const {
        id,
        is_default,
        organization: { name },
    } = membership;

    const containerClassName = classNames(
        'flex items-center gap-2 overflow-hidden cursor-pointer',
        {
            'w-[calc(100%-40px)]': !isChild,
            'w-full max-w-full rounded-2 bg-white hover:bg-neutral-50 transition-colors pl-[40px] pr-2':
                isChild,
        }
    );

    const iconClassName = classNames(
        'w-[36px] h-[36px] min-w-[36px] rounded-2 border-1 border-neutral-50 flex justify-center items-center uppercase font-body font-semibold text-[20px] text-white',
        { 'bg-purple-500': !isChild, 'bg-neutral-200': isChild }
    );

    return (
        <div
            className={containerClassName}
            onClick={() => changeTheDefaultOrganization(id, is_default)}
        >
            <div className="w-[48px] h-[48px] min-w-[48px] flex items-center justify-center">
                <div className={iconClassName}>{name[0]}</div>
            </div>

            <p className="flex-1 font-body text-body-regular-m text-neutral-500 truncate pr-1">
                {name}
            </p>

            {is_default && <SvgIcon color="#754DCF" icon={CheckLineIcon} size="large" />}

            {isLoading && (
                <div className="w-[24px] h-[24px]">
                    <Loading withText={false} size="small" />
                </div>
            )}
        </div>
    );
}

export default OrganizationCard;
