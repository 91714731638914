import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { API } from 'constants';
import client from '../../services/inbox-api';
import { defaultErrorMessage } from '../../constants/errorMessages';

import Modal from '../../design-system/Modal/Modal';
import ThumbsUpIcon from '../../design-system/Icons/ThumbsUpIcon';
import ThumbsDownIcon from '../../design-system/Icons/ThumbsDownIcon';
import ErrorMessage from '../../design-system/ErrorMessage/ErrorMessage';
import Alert from '../../design-system/Alert/Alert';
import { Button, ButtonIcon, TextArea } from '../../design-system';
import { ErrorWarningLineIcon } from '../../design-system/Icons';

ShareAiFeedbackModal.propTypes = {
    target: PropTypes.oneOf(['inbox_message', 'thread']).isRequired,
    state: PropTypes.oneOf(['empty', 'filled']),
    initialRating: PropTypes.number, // is used if state === "filled"
    messageId: PropTypes.string, // used when target = "inbox_message"
    threadId: PropTypes.string, // used when target = "thread"
    setAlert: PropTypes.func.isRequired,
    onSuccessFeedbackShare: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

function ShareAiFeedbackModal({
    target,
    state = 'empty',
    initialRating = 0,
    messageId,
    threadId,
    setAlert,
    onSuccessFeedbackShare = () => {},
    onClose,
}) {
    const formInitialState =
        state === 'filled' ? { rating: initialRating, comment: '' } : { rating: 0, comment: '' };

    const [inputsData, setInputsData] = useState(formInitialState);
    const [errorFields, setErrorFields] = useState({ rating: false });

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleRatingChange = (rating) => {
        setInputsData((prevData) => ({ ...prevData, rating }));
        if (errorFields.rating) {
            setErrorFields((prevFields) => ({ ...prevFields, rating: false }));
        }
    };

    const handleInputChange = (e) => {
        setInputsData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

    const shareFeedback = async () => {
        if (!inputsData.rating) {
            setErrorFields({ rating: true });
            return;
        }

        const requestBody = {
            rating: inputsData.rating,
        };

        if (target === 'inbox_message') {
            requestBody.inbox_message = messageId;
        }
        if (target === 'thread') {
            requestBody.thread = threadId;
        }

        if (inputsData.comment) {
            requestBody.value = inputsData.comment;
        }

        try {
            setIsLoading(true);
            const { data } = await client.post(`${API.ROUTES.inbox.feedback}`, requestBody);
            await onSuccessFeedbackShare(data);
            setAlert({
                show: true,
                status: 'positive',
                message: 'Thanks for submitting feedback!',
            });
            onClose();
            setIsLoading(false);
        } catch (e) {
            setErrorAlert({ statusCode: e.response?.status });
            setIsLoading(false);
        }
    };

    const title = {
        empty: 'Share Feedback',
        filled: target === 'thread' ? 'Add Feedback to Thread?' : 'Add Feedback to Message?',
    };
    const ratingInputLabel = {
        empty: 'How would you rate this run?',
        filled: 'Adjust your rating?',
    };
    const commentInputLabel = {
        empty: 'Comment',
        filled: 'Add a comment?',
    };
    const approveButtonText = {
        empty: 'Share Feedback',
        filled: target === 'thread' ? 'Add to Thread' : 'Add to Message',
    };

    return (
        <Modal onClose={onClose} size="extraSmall">
            <div className="flex flex-col gap-4 mb-8">
                <p className="font-body-bold text-body-bold-m text-black py-2">{title[state]}</p>
                <div className="flex flex-col gap-2">
                    <p className="font-body text-body-bold-s text-neutral-500">
                        {ratingInputLabel[state]}
                        <sup className="text-red-500 leading-1 font-medium ml-0.5">*</sup>
                    </p>
                    <div className="flex items-center gap-2">
                        <ButtonIcon
                            type={inputsData.rating === 1 ? 'secondary' : 'neutral'}
                            size="sm"
                            icon={ThumbsUpIcon}
                            onClick={() => handleRatingChange(1)}
                        />
                        <ButtonIcon
                            type={inputsData.rating === -1 ? 'secondary' : 'neutral'}
                            size="sm"
                            icon={ThumbsDownIcon}
                            onClick={() => handleRatingChange(-1)}
                        />
                    </div>
                    {errorFields.rating && <ErrorMessage message="Please rate this run." />}
                </div>
                <TextArea
                    name="comment"
                    value={inputsData.comment}
                    label={commentInputLabel[state]}
                    placeholder="Add your feedback here (optional)"
                    onChange={handleInputChange}
                />
            </div>
            <div className="flex items-center justify-between">
                <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                <Button
                    type="secondary"
                    size="sm"
                    text={approveButtonText[state]}
                    state={isLoading ? 'loading' : 'default'}
                    onClick={shareFeedback}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
}

export default ShareAiFeedbackModal;
