import React from 'react';
import { capitalizeFirstLetter } from 'services/strings';

import { AddCircleLineIcon } from '../../../design-system/Icons';
import SharedWithIcon from '../../../design-system/Icons/SharedWithIcon';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import FlowIcon from '../../../design-system/Icons/FlowIcon';
import EmptyView from '../../../design-system/EmptyView/EmptyView';

const ConfigureEmptyView = ({ type, handleClick }) => {
    const capitalizedType = capitalizeFirstLetter(type);

    const errorSnippet = {
        process: 'Processes yet.',
        goal: 'Goals in this Process yet.',
        scenario: 'Scenarios yet.',
    }[type];

    const MainIcon =
        { process: PlayCircleFillIcon, goal: SharedWithIcon, scenario: FlowIcon }[type] ||
        SharedWithIcon;

    return (
        <EmptyView
            icon={MainIcon}
            mainText={`You don’t have any ${errorSnippet}`}
            subtext={`Try creating one by clicking “New ${capitalizedType}”.`}
            buttonText={`New ${capitalizedType}`}
            buttonLeadingIcon={AddCircleLineIcon}
            handleButtonClick={handleClick}
            buttonState={'default'}
        />
    );
};

export default ConfigureEmptyView;
