import React from 'react';

import { PLAYBOOK_BUILDER_MODE } from '../../../../constants/playbookBuilder';

import { Badge } from '../../../../design-system';
import UserBadge from '../../../../design-system/UserBadge/UserBadge';
import DetailContainer from '../../../../design-system/DetailContainer/DetailContainer';
import PlayProcessIcon from '../../../../design-system/Icons/PlayProcessIcon';
import PlayCircleFillIcon from '../../../../design-system/Icons/PlayCircleFillIcon';
import ArrowRightUpLineIcon from '../../../../design-system/Icons/ArrowRightUpLineIcon';

const JobOrProjectMainDetail = ({ type, process, playbook, created_by }) => {
    const createdBy = created_by.first_name || created_by.email;

    return (
        <>
            {type === 'job' && (
                <DetailContainer label="Agent" maxWidth="60%" desktopStartPixel={1024}>
                    <Badge
                        text={process?.name}
                        color="neutral"
                        leadingIcon={PlayCircleFillIcon}
                        leadingIconColor="#1F2125"
                        trailingIcon={ArrowRightUpLineIcon}
                        trailingIconColor="#5E6470"
                        onBadgeClick={(e) => {
                            e?.stopPropagation();
                            window.open(`/agent/${process?.id}`, '_blank');
                        }}
                    />
                </DetailContainer>
            )}

            {type === 'project' && (
                <DetailContainer label="Playbook" maxWidth="60%" desktopStartPixel={1024}>
                    <Badge
                        text={playbook?.name}
                        color="neutral"
                        leadingIcon={PlayProcessIcon}
                        leadingIconColor="#000000"
                        trailingIcon={ArrowRightUpLineIcon}
                        trailingIconColor="#5E6470"
                        onBadgeClick={(e) => {
                            e?.stopPropagation();
                            window.open(
                                `/playbook/${playbook?.id}?mode=${PLAYBOOK_BUILDER_MODE.view}`,
                                '_blank'
                            );
                        }}
                    />
                </DetailContainer>
            )}

            <DetailContainer label="Started by" maxWidth="30%" desktopStartPixel={1024}>
                <UserBadge name={createdBy} />
            </DetailContainer>
        </>
    );
};

export default JobOrProjectMainDetail;
