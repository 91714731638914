import React from 'react';
import { Link } from 'react-router-dom';
import useUser from 'hooks/useUser';
import { getModelOptions } from 'helpers/getModelOptions';
import Select from 'design-system/Select/Select';
import Input from 'design-system/Input/Input';
import PromptSettingsTemperatureSelector from '../PromptSettingsTemperatureSelector/PromptSettingsTemperatureSelector';

const PromptSettingsBox = ({
    editable = true,
    promptSettings,
    setPromptSettings,
    setEditPromptData,
    setArePromptChanges,
}) => {
    const { user } = useUser();
    const modelOptions = getModelOptions(user);

    const handleModelChange = (name, id, options) => {
        const selectedOption = options.find((option) => option.id === id);
        setPromptSettings((prevSettings) => ({
            ...prevSettings,
            [name]: selectedOption,
        }));
        setEditPromptData((prevData) => ({
            ...prevData,
            settings: {
                provider: selectedOption.provider,
                temperature: promptSettings.temperature.value || 0.0,
                max_tokens: promptSettings.maxTokens,
                type: selectedOption.type,
                model: selectedOption.model,
            },
        }));
        setArePromptChanges(true);
    };

    const handleTemperatureChange = (selectedTemperatureData) => {
        setPromptSettings((prevSettings) => ({
            ...prevSettings,
            temperature: selectedTemperatureData,
        }));
        setEditPromptData((prevData) => ({
            ...prevData,
            settings: {
                temperature: selectedTemperatureData.value,
                max_tokens: promptSettings.maxTokens || 1000,
                provider: promptSettings.model.provider,
                type: promptSettings.model.type,
                model: promptSettings.model.model,
            },
        }));
        setArePromptChanges(true);
    };

    const handleMaxTokenChange = (name, value) => {
        setPromptSettings((prevSettings) => ({
            ...prevSettings,
            [name]: parseInt(value) || 1000,
        }));
        setEditPromptData((prevData) => ({
            ...prevData,
            settings: {
                temperature: promptSettings.temperature.value,
                max_tokens: parseInt(value) || 1000,
                provider: promptSettings.model.provider,
                type: promptSettings.model.type,
                model: promptSettings.model.model,
            },
        }));
        setArePromptChanges(true);
    };

    return (
        <div className="w-full flex flex-col gap-[24px] rounded-2">
            <div className="w-full flex flex-col gap-[4px]">
                <Select
                    label="Choose a Model"
                    name="model"
                    options={modelOptions}
                    value={promptSettings.model.id}
                    onChange={(value) => handleModelChange('model', value, modelOptions)}
                    size="md"
                    state={editable && modelOptions.length ? 'default' : 'disabled'}
                />
                {!modelOptions.length ? (
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        <Link to="/profile">Please add an API key to select a model</Link>
                    </p>
                ) : (
                    promptSettings.model.id &&
                    modelOptions.find((option) => option.id === promptSettings.model.id) && (
                        <p className="font-body text-body-regular-xs text-neutral-300">
                            {promptSettings.model.name} can handle up to{' '}
                            {promptSettings.model.token_limit} tokens including prompt, inputs, and
                            outputs.
                        </p>
                    )
                )}
            </div>

            <PromptSettingsTemperatureSelector
                selectedTemperatureId={promptSettings.temperature.id}
                handleTemperatureChange={handleTemperatureChange}
                editable={editable}
            />

            <div className="w-full flex flex-col gap-[4px]">
                <Input
                    label="Set a Max Response Length (tokens)"
                    type="number"
                    value={promptSettings.maxTokens}
                    size="sm"
                    name="maxTokens"
                    state={editable ? 'default' : 'disabled'}
                    placeholder="1000"
                    onChange={(e) => handleMaxTokenChange(e.target.name, e.target.value)}
                />
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Default value is 1000
                </p>
            </div>
        </div>
    );
};

export default PromptSettingsBox;
