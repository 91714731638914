export const setSyncTimeout = (cb, delay) => {
    let animation = null;
    let animationStatus = true; // is timeout in progress
    const startTime = performance.now();
    const endTime = startTime + delay;

    const stopAnimation = () => (animationStatus = false);

    const executeAction = () => {
        const currentTime = performance.now();
        if (!animationStatus) {
            return cancelAnimationFrame(animation);
        }
        if (currentTime < endTime) {
            animation = requestAnimationFrame(executeAction);
        } else {
            cancelAnimationFrame(animation);
            cb();
        }
    };

    requestAnimationFrame(executeAction);
    return stopAnimation;
};
