import React from 'react';
import Modal from '../../design-system/Modal/Modal';
import { Button, SvgIcon } from '../../design-system';
import EscapeIcon from '../../design-system/Icons/EscapeIcon';
import ArrowRightUpLineIcon from '../../design-system/Icons/ArrowRightUpLineIcon';
import { TASK_TYPES } from '../../constants/library';
import { capitalizeFirstLetter } from '../../services/strings';

const DuplicateTaskSuccessModal = ({ handleClose, id, taskName, taskType }) => {
    const handleViewNav = () => {
        if (taskType === TASK_TYPES.prompt) {
            window.open(`/library/prompt/${id}`, '_blank');
        } else {
            window.open(`/library/task/${id}`, '_blank');
        }
    };

    const taskTypeLabel = taskType === TASK_TYPES.prompt ? 'prompt' : 'task';

    return (
        <Modal onClose={handleClose} size="medium">
            <div className="flex gap-2 justify-between min-[540px]:items-center mb-8">
                <h2 className="font-heading-bold text-heading-bold-m text-neutral-400">
                    {capitalizeFirstLetter(taskTypeLabel)} successfully duplicated!
                </h2>
                <div onClick={handleClose} className="cursor-pointer">
                    <SvgIcon color="#handleClose" icon={EscapeIcon} size="rectangle" />
                </div>
            </div>
            <p className="font-body text-body-regular-m text-neutral-400 mb-8">
                New {taskTypeLabel} <span className="text-body-bold-m">{taskName}</span> added to
                your library!
            </p>
            <Button
                type="primary"
                size="sm"
                text={`Go to ${capitalizeFirstLetter(taskTypeLabel)}`}
                trailingIcon={ArrowRightUpLineIcon}
                onClick={handleViewNav}
            />
        </Modal>
    );
};

export default DuplicateTaskSuccessModal;
