import React, { memo, useState } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { Badge, ButtonIcon, SvgIcon } from '../../../design-system';
import { DeleteBin4LineIcon } from '../../../design-system/Icons';
import UsersIcon from '../../../design-system/Icons/UsersIcon';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import DeleteProcessConfirmModal from '../DeleteProcessConfirmModal/DeleteProcessConfirmModal';

const ProcessCard = ({ process, keysToMutate }) => {
    const { id, name, goals, created_at, is_shared } = process;
    const location = useLocation();
    const navigate = useNavigate();
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);

    const activeGoalsCount = goals.length; // should be updated
    const formattedTime = DateTime.fromISO(created_at).toLocaleString();

    const handleEditProcess = () => {
        navigate(`/configure/process/${id}`, {
            state: {
                from: location,
                keysToMutate,
            },
        });
    };

    const cardContainerClassName = classNames(
        'px-4 py-3 max-w-full rounded-2 bg-white',
        'grid grid-rows-[auto_auto] md:grid-rows-1 grid-cols-[minmax(100px,_1fr)_56px] md:grid-cols-[minmax(100px,_1fr)_150px_62px] gap-x-4 gap-y-2 items-start md:items-center'
    );

    return (
        <li className={cardContainerClassName}>
            <div className="grid grid-cols-1 lg:grid-cols-[minmax(100px,_1.6fr)_minmax(100px,_1fr)] gap-x-4 gap-y-1">
                <div className="flex items-center gap-2 lg:gap-4 row-start-2 row-end-2 lg:row-start-1 lg:row-end-1">
                    <SvgIcon color={process.icon_color} icon={PlayCircleFillIcon} size="medium" />
                    <p className="flex-grow font-body text-body-bold-xs text-neutral-500 truncate">
                        {name}
                    </p>
                </div>
                <div className="row-start-1 row-end-1 flex flex-row-reverse lg:flex-row items-center gap-2 justify-end overflow-hidden min-h-[28px]">
                    {is_shared && <SvgIcon color="#000000" size="medium" icon={UsersIcon} />}
                    <Badge
                        text={`${activeGoalsCount} goal${activeGoalsCount !== 1 ? 's' : ''}`}
                        color="blue"
                    />
                </div>
            </div>
            <div className="pt-2 md:p-0 border-t-1 border-neutral-200 md:border-none row-start-2 row-end-2 col-span-2 md:row-start-auto md:row-end-auto md:col-span-1">
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Created At{' '}
                    <span className="font-body text-body-bold-xs text-neutral-500">
                        {formattedTime}
                    </span>
                </p>
            </div>

            <div className="flex items-center justify-end md:gap-1.5">
                <ButtonIcon type="link" size="xs" icon={EditLineIcon} onClick={handleEditProcess} />
                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={DeleteBin4LineIcon}
                    onClick={() => setOpenDeleteConfirmModal(true)}
                />
            </div>

            {openDeleteConfirmModal && (
                <DeleteProcessConfirmModal
                    processId={id}
                    processName={name}
                    keysToMutate={keysToMutate}
                    onClose={() => setOpenDeleteConfirmModal(false)}
                />
            )}
        </li>
    );
};

export default memo(ProcessCard);
