import React from 'react';

const CodeViewIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M11.3033 5.64301L12.2461 4.7002L15.5459 8.00002L12.2461 11.2999L11.3033 10.357L13.6603 8.00002L11.3033 5.64301ZM4.70358 5.64301L2.34656 8.00002L4.70358 10.357L3.76077 11.2999L0.460938 8.00002L3.76077 4.7002L4.70358 5.64301Z"
                fill={props.color}
            />
        </svg>
    );
};

export default CodeViewIcon;
