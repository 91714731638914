import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { changeItemsOrderUsingDragAndDrop } from '../helpers/changeItemsOrderUsingDragAndDrop';

export const useRearrangeGridItems = ({ itemType, item, setData, columns = 3 }) => {
    const itemRef = useRef(null);
    const dragIconRef = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: itemType,
        item: { order: item.order },
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover(draggingItem, monitor) {
            if (!itemRef.current) {
                return;
            }

            const dragOrder = draggingItem.order;
            const hoverOrder = item.order;

            if (dragOrder === hoverOrder) {
                return;
            }

            const sameRow = dragOrder % columns === hoverOrder % columns;
            const sameColumn = Math.ceil(dragOrder / columns) === Math.ceil(hoverOrder / columns);

            const hoverBoundingRect = itemRef.current?.getBoundingClientRect();

            const hoverBottomBoundary = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 5;
            const hoverTopBoundary = (4 * (hoverBoundingRect.bottom - hoverBoundingRect.top)) / 5;

            const hoverLeftBoundary = (hoverBoundingRect.right - hoverBoundingRect.left) / 3;
            const hoverRightBoundary =
                (9 * (hoverBoundingRect.right - hoverBoundingRect.left)) / 10;

            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;

            const outOfXRange =
                hoverClientX < hoverLeftBoundary || hoverClientX > hoverRightBoundary;
            if (!sameRow && outOfXRange) return;

            const outOfYRange =
                hoverClientY < hoverBottomBoundary || hoverClientY > hoverTopBoundary;
            if (!sameColumn && outOfYRange) return;

            changeItemsOrderUsingDragAndDrop(dragOrder, hoverOrder, setData);
            draggingItem.order = hoverOrder;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: itemType,
        item: { order: item.order },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(dragIconRef);
    drop(preview(itemRef));

    return {
        itemRef,
        dragIconRef,
        isDragging,
        handlerId,
    };
};
