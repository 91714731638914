import React from 'react';
import { SvgIcon } from '../../../design-system';
import { Robot2FillIcon } from '../../../design-system/Icons';
import LoaderFillIcon from '../../../design-system/Icons/LoaderFillIcon';

const LoadingState = ({ mode, agentName, isDiviBot = false }) => {
    const assistantLoader = (
        <div className="flex-1 flex justify-center">
            <div className="animate-icon-spin">
                <SvgIcon icon={LoaderFillIcon} size="large" color="#000000" />
            </div>
        </div>
    );

    const agentLoader = (
        <p className="flex-1 font-body text-body-regular-s text-neutral-500">
            <span className="font-body-bold text-body-bold-s">{agentName} </span>agent is processing
            your request
        </p>
    );

    const loader = {
        assistant: assistantLoader,
        agent: agentLoader,
    };

    return (
        <div
            className="w-full p-[3px] rounded-[13px]"
            style={{
                background:
                    'linear-gradient(254.3deg, #754DCF 7.32%, #F2AA85 24.99%, #D9E56E 48.37%, #33BCE7 70.35%, #754DCF 95.71%)',
                boxShadow: '0px 0px 16px 0px #754DCF33',
            }}
        >
            <div className="w-full p-3 flex items-center gap-4 bg-white rounded-2.5">
                <SvgIcon
                    color={isDiviBot ? '#754DCF' : '#1F2125'}
                    icon={Robot2FillIcon}
                    size="large"
                />

                {loader[mode]}
            </div>
        </div>
    );
};

export default LoadingState;
