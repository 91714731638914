import React from 'react';

import { TASK_TYPES } from '../../../../constants/library';
import { formatOutputValue } from '../../../../helpers/formatOutputValue';
import { formatKeyToLabel } from '../../../../helpers/formatKeyToLabel';

import { Badge } from '../../../../design-system';
import PlotView from '../../../../design-system/PlotView/PlotView';

const GenericOutputs = ({ outputs, taskType }) => {
    return (
        <>
            {!!outputs.length ? (
                <ul className="flex flex-col gap-4">
                    {outputs.map((item) => {
                        const formattedValue = formatOutputValue(item.value);
                        return (
                            <li key={item.id} className="flex flex-col gap-4">
                                <div className="flex justify-between items-center">
                                    <p className="font-body text-body-bold-s text-neutral-500">
                                        [{item.key}]
                                    </p>
                                    <Badge text={formatKeyToLabel(item.type)} color="neutral" />
                                </div>

                                {formattedValue && (
                                    <>
                                        {taskType !== TASK_TYPES.plot && (
                                            <pre className="font-body text-body-regular-s text-neutral-500 whitespace-pre-wrap">
                                                {formattedValue}
                                            </pre>
                                        )}
                                        {taskType === TASK_TYPES.plot && (
                                            <PlotView value={item.value} />
                                        )}
                                    </>
                                )}
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <p className="font-body text-body-regular-s text-neutral-500 italic">None</p>
            )}
        </>
    );
};

export default GenericOutputs;
