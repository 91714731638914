import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../Alert/Alert';
import { ErrorWarningLineIcon } from '../Icons';

const ErrorAlert = ({ errorAlert, setErrorAlert, autoCloseInMS = 3000 }) => {
    return (
        <>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={autoCloseInMS}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

ErrorAlert.propTypes = {
    errorAlert: PropTypes.shape({
        message: PropTypes.string,
        statusCode: PropTypes.string,
    }),
    setErrorAlert: PropTypes.func.isRequired,
    autoCloseInMS: PropTypes.number,
};

export default ErrorAlert;
