import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from '../../hooks/useWindowSize';

import { Input, Select } from '../index';
import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper';

MappingItem.propTypes = {
    leftSideValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    leftSideLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    rightSideValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rightSideLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    selectOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            logAs: PropTypes.string,
            badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
            leadingIcon: PropTypes.func,
            leadingIconColor: PropTypes.string,
            trailingBadge: PropTypes.shape({
                text: PropTypes.string.isRequired,
                color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
            }),
            trailingTagText: PropTypes.string,
        })
    ).isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    itemIndex: PropTypes.number.isRequired,
    mappingItemsLength: PropTypes.number.isRequired,
    tipText: PropTypes.string,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    errorMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    selectPlaceholder: PropTypes.string,
    leftColumnWidthPercentage: PropTypes.number,
    dropdownHeight: PropTypes.number,
};

function MappingItem({
    leftSideValue,
    leftSideLabel,
    rightSideValue,
    rightSideLabel,
    selectOptions,
    handleSelectChange,
    itemIndex,
    mappingItemsLength,
    tipText,
    state = 'default',
    errorMessage,
    isRequired = false,
    selectPlaceholder,
    leftColumnWidthPercentage = 50,
    dropdownHeight = 180,
}) {
    const { width: screenWidth } = useWindowSize();

    const leftSideWidth =
        screenWidth >= 480 ? `calc((100% - 12px) * ${leftColumnWidthPercentage} / 100)` : '100%';
    const rightSideWidth =
        screenWidth >= 480
            ? `calc((100% - 12px) * ${100 - leftColumnWidthPercentage} / 100)`
            : '100%';

    return (
        <FormFieldWrapper
            tipText={
                // show tip text just for the last element
                itemIndex === mappingItemsLength - 1 ? tipText : ''
            }
            state={state}
            errorMessage={errorMessage}
        >
            <div className="flex flex-col xs:flex-row xs:items-center gap-3">
                <div style={{ width: leftSideWidth }}>
                    <Input
                        size="xs"
                        state="disabled"
                        value={leftSideValue}
                        name="leftSideKey"
                        label={itemIndex === 0 || screenWidth < 480 ? leftSideLabel : ''}
                        isRequired={(itemIndex === 0 || screenWidth < 480) && isRequired}
                        onChange={() => {}}
                    />
                </div>
                <div style={{ width: rightSideWidth }}>
                    <Select
                        options={selectOptions}
                        value={rightSideValue}
                        label={itemIndex === 0 || screenWidth < 480 ? rightSideLabel : ''}
                        isRequired={(itemIndex === 0 || screenWidth < 480) && isRequired}
                        onChange={(selectedOptionId) =>
                            handleSelectChange(leftSideValue, selectedOptionId)
                        }
                        size="xs"
                        name="rightSideValue"
                        placeholder={selectPlaceholder}
                        dropdownHeight={dropdownHeight}
                    />
                </div>
            </div>
        </FormFieldWrapper>
    );
}

export default MappingItem;
