import React from 'react';

import ArrowRightSLineIcon from '../../../design-system/Icons/ArrowRightSLineIcon';

const MoveToBreadcrumbItem = ({
    locItem,
    isHidden,
    handleMove,
    isDisabled,
    isTargetFolder,
    setIsFullLocationDisplayed,
}) => {
    if (isHidden) {
        return (
            <div className="flex items-center gap-1 whitespace-nowrap">
                <button
                    onClick={() => setIsFullLocationDisplayed(true)}
                    className="outline-0 focus:outline-none"
                >
                    <p className="font-body text-body-meduim-s text-neutral-300">...</p>
                </button>
                <ArrowRightSLineIcon width={10} height={10} color="#5E6470" />
            </div>
        );
    }

    const { label } = locItem;

    if (!isTargetFolder) {
        return (
            <div className="flex items-center gap-1 whitespace-nowrap">
                <p className="font-body text-body-regular-s text-neutral-300">{label}</p>
                <ArrowRightSLineIcon width={10} height={10} color="#5E6470" />
            </div>
        );
    }

    if (isTargetFolder) {
        return (
            <button
                type="button"
                disabled={isDisabled}
                onClick={handleMove}
                className="font-body text-body-regular-m text-neutral-500 whitespace-nowrap pr-2"
            >
                {label}
            </button>
        );
    }

    return <p></p>;
};

export default MoveToBreadcrumbItem;
