import React, { useState } from 'react';
import classNames from 'classnames';

import Loading from '../../../components/Loading';
import HomeIcon from '../../../design-system/Icons/HomeIcon';
import FolderFillIcon from '../../../design-system/Icons/FolderFillIcon';
import MoveToBreadcrumbItem from '../MoveToBreadcrumbItem/MoveToBreadcrumbItem';
import { SvgIcon } from '../../../design-system';

const MoveToFolderItem = ({ folder, isLoading, isDisabled, handleMove }) => {
    const { label, isRootFolder, location } = folder;
    const Icon = isRootFolder ? HomeIcon : FolderFillIcon;

    const [isFullLocationDisplayed, setIsFullLocationDisplayed] = useState(false);

    const containerClassName = classNames(
        'max-w-full p-2 flex items-center gap-2 rounded-2 hover:bg-neutral-50 transition-colors',
        { 'bg-white': !isLoading, 'bg-neutral-50': isLoading, 'cursor-pointer': isRootFolder }
    );

    if (isRootFolder || location?.length === 1) {
        return (
            <button
                type="button"
                className={containerClassName}
                disabled={isDisabled}
                onClick={handleMove}
            >
                <IconWithFallback icon={Icon} isLoading={isLoading}>
                    <p className="font-body text-body-regular-m text-neutral-500 truncate">
                        {label}
                    </p>
                </IconWithFallback>
            </button>
        );
    }

    return (
        <div className={containerClassName}>
            <IconWithFallback icon={Icon} isLoading={isLoading}>
                <div className="flex items-center gap-1 overflow-x-auto">
                    {location?.map((locItem, index) => {
                        const isHidden = !isFullLocationDisplayed && index < location.length - 2;
                        const isTargetFolder =
                            index === location.length - 1 && locItem.id === folder.id;

                        return (
                            <MoveToBreadcrumbItem
                                key={locItem.id}
                                locItem={locItem}
                                isHidden={isHidden}
                                isTargetFolder={isTargetFolder}
                                handleMove={handleMove}
                                isDisabled={isDisabled}
                                setIsFullLocationDisplayed={setIsFullLocationDisplayed}
                            />
                        );
                    })}
                </div>
            </IconWithFallback>
        </div>
    );
};

function IconWithFallback({ children, isLoading, icon: Icon }) {
    return (
        <>
            <SvgIcon color="#754DCF" icon={Icon} size="large" />
            {!isLoading && children}
            {isLoading && (
                <div className="flex-1">
                    <Loading withText={false} size="small" />
                </div>
            )}
        </>
    );
}

export default MoveToFolderItem;
