import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { inboxSortOptions } from '../../../constants/sort';
import { parseInboxSearchParams } from '../../../helpers/parseInboxSearchParams';
import { useWindowSize } from '../../../hooks/useWindowSize';

import SearchBar from '../../../design-system/SearchBar/SearchBar';
import SortDropdown from '../SortDropdown/SortDropdown';
import MessageCard from '../../../components/MessageCard/MessageCard';
import Loading from '../../../components/Loading';
import { Button } from '../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../design-system/Icons';

const InboxMessageIndexPanel = ({
    data,
    setData,
    searchQueryInputValue,
    selectedSortOption,
    setSelectedSortOption,
    selectedFilterOptions,
    isStatusTabIndexChanging,
    setSearchQuery,
    setSearchQueryInputValue,
    selectedMessage,
    setSelectedMessage,
    shouldSetNewData,
    setPage,
    loading,
    hasNextPage,
    requestKeysToMutate,
    setNextMessageAfterMessageApproval,
    resetCurrentData,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedSearchParams = parseInboxSearchParams(searchParams);
    const { width: screenWidth } = useWindowSize();

    const [isSortDropdownOpened, setIsSortDropdownOpened] = useState(false);

    const handleChange = (e) => {
        setSearchQueryInputValue(e.target.value);
    };

    const handleSubmit = () => {
        resetCurrentData();
        setSearchQuery(searchQueryInputValue);
    };

    const handleClearSearchQuery = () => {
        resetCurrentData();
        setSearchQueryInputValue('');
        setSearchQuery(null);
    };

    const handleSortOptionSelect = (value) => {
        resetCurrentData();
        setSelectedSortOption(value);
        setIsSortDropdownOpened(false);
    };

    const handleCardClick = (item) => {
        setSelectedMessage(item);
        if (screenWidth < 1160) {
            setSearchParams({ ...parsedSearchParams, message_id: item.id });
        }
    };

    return (
        <div className="h-full bg-neutral-50 w-full min-[1160px]:w-[44%] min-[1380px]:w-[500px]">
            <div className="h-full px-5 pt-4 pb-[150px] min-[700px]:py-4 min-[900px]:py-6 min-[900px]:px-8 min-[1160px]:px-5 min-[1160px]:py-4 overflow-y-auto">
                {(!!data?.length || searchQueryInputValue) && !isStatusTabIndexChanging && (
                    <div className="flex flex-col gap-5 mb-5">
                        <div className="flex flex-col min-[450px]:flex-row min-[1160px]:max-[1169px]:flex-col gap-x-4 gap-y-2">
                            <SearchBar
                                size="sm"
                                value={searchQueryInputValue}
                                withSubmitIcon
                                withClearIcon
                                onChange={handleChange}
                                onSubmit={handleSubmit}
                                onClear={handleClearSearchQuery}
                            />
                            <div className="flex items-center gap-4 justify-end relative">
                                <Button
                                    type="link"
                                    size="xs"
                                    text="Sort"
                                    trailingIcon={
                                        isSortDropdownOpened ? ArrowUpSLineIcon : ArrowDownSLineIcon
                                    }
                                    onClick={() => setIsSortDropdownOpened(true)}
                                />
                                {isSortDropdownOpened && (
                                    <SortDropdown
                                        options={inboxSortOptions}
                                        selectedOption={selectedSortOption}
                                        handleOptionSelect={handleSortOptionSelect}
                                        onClose={(e) => {
                                            e?.stopPropagation();
                                            setIsSortDropdownOpened(false);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {!!data?.length && !shouldSetNewData && !isStatusTabIndexChanging && (
                    <div className="flex flex-col gap-2">
                        {data.map((item) => (
                            <MessageCard
                                key={item.id}
                                item={item}
                                selected={item.id === selectedMessage?.id}
                                onCardClick={() => {
                                    handleCardClick(item);
                                }}
                                setData={setData}
                                requestKeysToMutate={requestKeysToMutate}
                                setNextMessageAfterMessageApproval={
                                    setNextMessageAfterMessageApproval
                                }
                            />
                        ))}
                    </div>
                )}

                {!data?.length &&
                    (searchQueryInputValue ? (
                        <p className="font-body text-body-bold-s text-neutral-300 px-1 py-2">
                            We couldn't find any messages matching your search for{' '}
                            {searchQueryInputValue}. Please try a different keyword.
                        </p>
                    ) : !!selectedFilterOptions?.length ? (
                        <p className="font-body text-body-bold-s text-neutral-300 px-1 py-2">
                            We couldn't find any messages matching your selected process. Please try
                            to select a different process.
                        </p>
                    ) : (
                        <></>
                    ))}

                {((!!data?.length && loading && !isStatusTabIndexChanging) || shouldSetNewData) && (
                    <div className="flex justify-center mt-5">
                        <Loading withText={false} />
                    </div>
                )}
                {hasNextPage && !loading && (
                    <div className="flex justify-center mt-5">
                        <Button
                            type="link"
                            size="xs"
                            text="Load More"
                            onClick={() => setPage((prevPage) => prevPage + 1)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InboxMessageIndexPanel;
