import React, { useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../../services/assistant-api';

import { useModalAutoMinHeight } from '../../../../hooks/useModalAutoMinHeight';
import { useClientSideFilterBar } from '../../../../hooks/useClientSideFilterBar';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import { defaultErrorMessage } from '../../../../constants/errorMessages';
import { KNOWLEDGE_SOURCE_TYPE_ICON } from '../../../../constants/knowledge';

import { capitalizeFirstLetter } from '../../../../services/strings';
import { arePrimitiveArraysEqualUnordered } from '../../../../helpers/arePrimitiveArraysEqualUnordered';

import Alert from '../../../../design-system/Alert/Alert';
import Modal from '../../../../design-system/Modal/Modal';
import MobilePopup from '../../../../design-system/MobilePopup/MobilePopup';
import CheckboxListItem from '../../../../design-system/CheckboxListItem/CheckboxListItem';
import InfiniteScrollList from '../../../../components/InfiniteScrollList/InfiniteScrollList';
import ModalHeaderWithSearch from '../../../../design-system/ModalHeaderWithSearch/ModalHeaderWithSearch';
import { Button } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const EditSelectionModal = ({
    type,
    botId,
    setBotDetail,
    optionsHookResponse,
    savedSelectedSources,
    onClose,
}) => {
    const maxHeight = window.innerHeight >= 550 ? 480 : window.innerHeight - 70;
    const { isMobile } = useResponsiveBreakpoints();

    const { options, optionsLoading, totalOptions, setPage, canLoadMoreOptions } =
        optionsHookResponse;

    const [selectedItems, setSelectedItems] = useState(savedSelectedSources || []);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const showClientSideFilterBar = totalOptions >= 6;

    const { filteredOptions, setFilteredOptions } = useClientSideFilterBar({
        options,
        showClientSideFilterBar,
    });

    const { modalRef, modalMinHeight } = useModalAutoMinHeight({
        maxHeight,
        shouldFixHeight: !isMobile,
        dependenciesArray: [filteredOptions],
    });

    const handleChange = (item) => {
        setSelectedItems((prevSelected) => {
            const isSelected = prevSelected.includes(item.id);
            if (isSelected) {
                return prevSelected.filter((id) => id !== item.id);
            }

            return [...prevSelected, item.id];
        });
    };

    const handleSave = async () => {
        try {
            const nothingChanged = arePrimitiveArraysEqualUnordered(
                selectedItems,
                savedSelectedSources
            );
            if (nothingChanged) {
                onClose();
                return;
            }

            setIsLoading(true);

            const { data } = await client.patch(`${API.ROUTES.assistant.customBot}${botId}/`, {
                [type]: selectedItems,
            });

            setBotDetail((prevState) => ({
                ...prevState,
                bases: data.bases || [],
                documents: data.documents || [],
                updated_at: data.updated_at,
            }));

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const optionsListClassName = classNames('flex-1 pt-2 mt-1 md:pt-3 md:mt-2 px-5', {
        'md:pb-2': !!filteredOptions?.length,
        'min-h-[70px]': optionsLoading,
        'overflow-y-auto': isMobile || maxHeight > 400,
    });

    const topFixedContent = (
        <ModalHeaderWithSearch
            onClose={onClose}
            onCancelClick={onClose}
            title={`Select ${capitalizeFirstLetter(type)}`}
            listLabel={`All ${type}`}
            options={options}
            setFilteredOptions={setFilteredOptions}
        />
    );

    const optionsList = (
        <div className={optionsListClassName}>
            <InfiniteScrollList
                handleFetch={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                items={filteredOptions}
                loading={optionsLoading}
                gap={0}
            >
                {filteredOptions.map((item) => {
                    const { id } = item;
                    const isChecked = selectedItems.includes(id);

                    return (
                        <CheckboxListItem
                            key={id}
                            item={item}
                            handleChange={handleChange}
                            isChecked={isChecked}
                            icon={KNOWLEDGE_SOURCE_TYPE_ICON[type]}
                        />
                    );
                })}
            </InfiniteScrollList>
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                minHeight="86vh"
                maxHeight="86vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="pt-0"
                bottomButtonsCustomPaddings="px-5 pt-3"
                topFixedContent={topFixedContent}
                customGap={8}
                withCloseButton={false}
                errorAlert={errorAlert}
                setErrorAlert={setErrorAlert}
                bottomButtonsGroup={
                    <div className="flex flex-col gap-2">
                        <Button
                            type="secondary"
                            size="sm"
                            text="Save"
                            state={isLoading ? 'loading' : 'default'}
                            onClick={handleSave}
                        />
                        <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                    </div>
                }
            >
                <div className="flex flex-col bg-white">{optionsList}</div>
            </MobilePopup>
        );
    }

    return (
        <Modal onClose={onClose} resetPadding size="extraLarge">
            <div
                className="flex flex-col rounded-2"
                style={{ maxHeight: `${maxHeight}px`, minHeight: `${modalMinHeight}px` }}
                ref={modalRef}
            >
                {topFixedContent}

                {optionsList}

                <div className="px-5 pb-3 pt-4 flex items-center justify-between gap-2">
                    <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Save"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleSave}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default EditSelectionModal;
