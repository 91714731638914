import React, { useState } from 'react';
import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import { useFormState } from '../../../../hooks/useFormState';
import { Button, Input } from '../../../../design-system';
import Alert from '../../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';
import { SCENARIO_VERSION_ROUTE } from '../ScenarioDetailPage';
import client from '../../../../services/library-api';

const CreateNewVersionModal = ({ config, scenarioId, onScenarioVersionCreate, onClose }) => {
    const { formData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } = useFormState(
        { name: '' }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleCreateVersion = async () => {
        if (!formData.name) {
            setFieldErrorMessages({ name: emptyFieldErrorMessage });
            return;
        }

        const requestBody = { name: formData.name, config, scenario: scenarioId };
        try {
            setIsLoading(true);
            const { data } = await client.post(SCENARIO_VERSION_ROUTE, requestBody);
            onScenarioVersionCreate(data);
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title="Create New Version" outlined />
            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                <Input
                    size="md"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    label="Version Name"
                    isRequired
                    placeholder="Give your new version a name"
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                />

                <div className="flex items-center justify-between gap-3">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="md"
                        text="Create"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleCreateVersion}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default CreateNewVersionModal;
