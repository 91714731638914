import React, { useMemo, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/library-api';

import modelOptions from '../../../../constants/modelOptions';
import { TASK_TYPES } from '../../../../constants/library';
import { integerRegex } from '../../../../constants/regex_patterns';
import { useFetchOptionsForPaginatedSelect } from '../../../../hooks/useFetchOptionsForPaginatedSelect';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';

import CollapsableContainer from '../../../../components/CollapsableContainer/CollapsableContainer';
import Select from '../../../../design-system/Select/Select';
import GoalsMultiSelect from '../GoalsMultiSelect/GoalsMultiSelect';
import PromptSettingsTemperatureSelector from '../../../../components/PromptSettingsTemperatureSelector/PromptSettingsTemperatureSelector';
import Input from '../../../../design-system/Input/Input';

const AssistantAndAgentSettingsBlock = ({
    taskType,
    changeableData,
    setChangeableData,
    isExpanded,
    setIsExpanded,
    setChangedTaskFields,
    emptyGoalsError,
    setEmptyGoalsError,
}) => {
    const { settings, goals } = changeableData;

    // model options are modelOptions filtered where provider == openai
    const filteredModelOptions = useMemo(
        () => modelOptions.filter((option) => option.provider === 'openai'),
        []
    );

    const [selectedGoal, setSelectedGoal] = useState('');

    const setGoalOptions = (prevData, data) => {
        const newData =
            data.results?.map((item) => ({
                id: item.id,
                name: `${item.name}` + (item.process ? ` - ${item.process}` : ''),
            })) || [];
        return [...prevData, ...newData];
    };

    const {
        options: goalOptions,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: API.ROUTES.library.workflow,
        searchParams: { ordering: 'name', is_deleted: false},
        callback: setGoalOptions,
    });

    const handleSettingChange = (key, value) => {
        setChangeableData((prevData) => ({
            ...prevData,
            settings: { ...prevData.settings, [key]: value },
        }));
        setChangedTaskFields((prevFields) => addUniqueElementToArray('settings', prevFields));
    };

    const handleModelChange = (selectedModelId) => {
        const selectedOption = filteredModelOptions.find((option) => option.id === selectedModelId);

        if (selectedOption) {
            handleSettingChange('model', selectedOption);
        }
    };

    const handleTemperatureChange = (selectedTemperatureData) => {
        handleSettingChange('temperature', selectedTemperatureData);
    };

    const handleMaxTokenChange = (e) => {
        const inputValue = e.target.value;
        if (integerRegex.test(inputValue)) {
            handleSettingChange('maxTokens', parseInt(inputValue));
        }
    };

    const handleAddGoalClick = () => {
        const selectedGoalObj = goalOptions.find((item) => item.id === selectedGoal);
        if (selectedGoalObj) {
            setChangeableData((prevData) => ({
                ...prevData,
                goals: [...prevData.goals, selectedGoalObj],
            }));
            setSelectedGoal('');
        }
        setChangedTaskFields((prevFields) => addUniqueElementToArray('goals', prevFields));
        if (emptyGoalsError) {
            setEmptyGoalsError((prevData) => ({ ...prevData, goals: null }));
        }
    };

    const handleGoalBadgeRemove = (id) => {
        setChangeableData((prevData) => ({
            ...prevData,
            goals: [...prevData.goals?.filter((goal) => goal.id !== id)],
        }));
        setChangedTaskFields((prevFields) => addUniqueElementToArray('goals', prevFields));
    };

    const filteredGoalOptions = useMemo(() => {
        const addedGoalsIds = goals?.map((goal) => goal.id) || [];
        return goalOptions.filter((goal) => !addedGoalsIds.includes(goal.id));
    }, [goalOptions, goals]);

    const containerName = {
        [TASK_TYPES.assistant]: 'assistantSettings',
        [TASK_TYPES.agent]: 'agentSettings',
    };

    const toggleContainerExpand = () => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [containerName[taskType]]: !prevState[containerName[taskType]],
        }));
    };

    return (
        <CollapsableContainer
            title={`${taskType} Settings`}
            isExpanded={isExpanded[containerName[taskType]]}
            toggleExpand={toggleContainerExpand}
        >
            <div className="flex flex-col gap-4">
                {settings && (
                    <>
                        <Select
                            size="xs"
                            name="model"
                            options={filteredModelOptions}
                            value={settings.model.id}
                            label="Choose a Model"
                            isRequired
                            onChange={(value) => handleModelChange(value)}
                        />
                        {taskType === TASK_TYPES.agent && (
                            <>
                                <PromptSettingsTemperatureSelector
                                    selectedTemperatureId={settings.temperature.id}
                                    handleTemperatureChange={handleTemperatureChange}
                                    editable
                                />

                                <div className="w-full flex flex-col gap-[4px]">
                                    <Input
                                        size="sm"
                                        name="maxTokens"
                                        value={settings.maxTokens}
                                        state="default"
                                        label="Set a Max Response Length (tokens)"
                                        type="number"
                                        placeholder="1000"
                                        onChange={handleMaxTokenChange}
                                    />
                                    <p className="font-body text-body-regular-xs text-neutral-300">
                                        Default value is 1000
                                    </p>
                                </div>
                            </>
                        )}
                    </>
                )}
                {goals && (
                    <GoalsMultiSelect
                        taskType={taskType}
                        options={filteredGoalOptions}
                        optionsLoading={optionsLoading}
                        selectedGoal={selectedGoal}
                        setPage={setPage}
                        canLoadMoreOptions={canLoadMoreOptions}
                        addedGoalsBadgeInfo={goals}
                        handleSelectChange={setSelectedGoal}
                        handleAddGoalClick={handleAddGoalClick}
                        handleRemoveGoalBadge={handleGoalBadgeRemove}
                        isError={emptyGoalsError}
                        totalOptions={totalOptions}
                    />
                )}
            </div>
        </CollapsableContainer>
    );
};

export default AssistantAndAgentSettingsBlock;
