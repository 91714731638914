import React, { useState } from 'react';
import { checkIfViewModeWithEmptyPlaybookContent } from '../../../helpers/playbookBuilderUtils';

import { Button } from '../../../design-system';
import NavigationGuide from '../NavigationGuide/NavigationGuide';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';

const MobileBottomActionBar = ({ mode, playbookContentFormData, areSectionsExpandedState }) => {
    const [isNavigationGuidePopupOpened, setIsNavigationGuidePopupOpened] = useState(false);

    const isViewModeWithEmptyPlaybookContent = checkIfViewModeWithEmptyPlaybookContent(
        mode,
        playbookContentFormData
    );

    if (isViewModeWithEmptyPlaybookContent) {
        return null;
    }

    const handleClosePopup = () => {
        setIsNavigationGuidePopupOpened(false);
    };

    return (
        <>
            <div className="fixed bottom-4 left-5 sm:left-[84px] z-10">
                <Button
                    type="primary"
                    size="sm"
                    text="Navigation Guide"
                    onClick={() => setIsNavigationGuidePopupOpened(true)}
                />
            </div>

            {isNavigationGuidePopupOpened && (
                <MobilePopup onClose={handleClosePopup}>
                    <NavigationGuide
                        mode={mode}
                        playbookContent={playbookContentFormData}
                        areSectionsExpandedState={areSectionsExpandedState}
                        onExpandAndScrollComplete={handleClosePopup}
                        renderType="popup"
                    />
                </MobilePopup>
            )}
        </>
    );
};

export default MobileBottomActionBar;
