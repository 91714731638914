import React, { useState } from 'react';

import { Button } from '../../../../design-system';
import UpdateProfilePictureModal from '../UpdateProfilePictureModal/UpdateProfilePictureModal';

const UserProfilePictureBlock = ({ userData, user, setUserData }) => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    return (
        <div className="flex flex-col gap-5">
            <p className="font-body-bold text-body-bold-m text-neutral-500 whitespace-nowrap">
                Profile Picture
            </p>
            <div className="w-[100px] h-[100px] rounded-[50%] overflow-hidden bg-purple-500 flex items-center justify-center">
                {userData.profile_image ? (
                    <img
                        src={userData.profile_image}
                        alt={`Profile image of ${user.username}`}
                        className="object-contain"
                    />
                ) : (
                    <div className="w-full h-full font-body text-[50px] text-white flex items-center justify-center uppercase">
                        {user.email?.[0]}
                    </div>
                )}
            </div>
            <div>
                <Button
                    type="primary"
                    size="sm"
                    text="Update"
                    onClick={() => setIsModalOpened(true)}
                />
            </div>

            {isModalOpened && (
                <UpdateProfilePictureModal
                    onClose={() => setIsModalOpened(false)}
                    setUserData={setUserData}
                />
            )}
        </div>
    );
};

export default UserProfilePictureBlock;
