import React, { useState } from 'react';
import { ButtonIcon } from '../../../design-system';
import ArrowLeftIcon from '../../../design-system/Icons/ArrowLeftIcon';
import { ArrowRightIcon, FullscreenLineIcon, More2FillIcon } from '../../../design-system/Icons';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import UserSharedLineIcon from '../../../design-system/Icons/UserSharedLineIcon';
import MessageMoreOptionsPopup from '../MessageMoreOptionsPopup/MessageMoreOptionsPopup';

const MessageDetailControls = ({
    message,
    currentMessageIndex,
    handlePrevClick,
    handleNextClick,
    messagesLength,
    handleFullScreenClick,
    setData,
    requestKeysToMutate,
}) => {
    const [showToolTip, setShowToolTip] = useState(false);
    const assignedFrom = message.assigned_from;

    const [isMoreOptionPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);
    const shouldShowMoreOptionsButton = message.status === 'pending';

    return (
        <div className="px-4 py-3 flex justify-between items-center border-y-1 border-neutral-200">
            <div className="flex items-center gap-1">
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={ArrowLeftIcon}
                    state={currentMessageIndex === 0 ? 'disabled' : 'default'}
                    onClick={handlePrevClick}
                />
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={ArrowRightIcon}
                    state={currentMessageIndex === messagesLength - 1 ? 'disabled' : 'default'}
                    onClick={handleNextClick}
                />
            </div>
            <div className="hidden min-[1160px]:flex items-center gap-1 ">
                {assignedFrom && (
                    <ToolTip
                        text={
                            <>
                                Reassigned from <strong>{assignedFrom}</strong>
                            </>
                        }
                        position="top-right"
                        shift={'-1px'}
                        isShown={showToolTip}
                    >
                        <div
                            onMouseEnter={() => setShowToolTip(true)}
                            onMouseLeave={() => setShowToolTip(false)}
                        >
                            <ButtonIcon
                                type="link"
                                size="sm"
                                icon={UserSharedLineIcon}
                                onClick={() => {}}
                            />
                        </div>
                    </ToolTip>
                )}
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={FullscreenLineIcon}
                    onClick={handleFullScreenClick}
                />
                {shouldShowMoreOptionsButton && (
                    <div className="relative">
                        <ButtonIcon
                            type="link"
                            size="sm"
                            icon={More2FillIcon}
                            onClick={() => setIsMoreOptionsPopupOpened(true)}
                        />

                        {isMoreOptionPopupOpened && (
                            <MessageMoreOptionsPopup
                                message={message}
                                setData={setData}
                                requestKeysToMutate={requestKeysToMutate}
                                setNextMessageAfterMessageApproval={handleNextClick}
                                onClose={() => setIsMoreOptionsPopupOpened(false)}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MessageDetailControls;
