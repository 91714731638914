import React, { useState } from 'react';
import { handleCopy } from '../../helpers/handleCopy';

import NewRunContainer from 'components/NewRunContainer/NewRunContainer';
import Alert from '../../design-system/Alert/Alert';

const OutputContainer = ({
    promptId,
    promptOutputs,
    updatePromptOutputs = () => {},
    withLinkIcon = false,
    isRunning = false,
    scrollInsideTextArea,
    outputRating,
    updateOutputRating,
    resultId,
}) => {
    const [copyAlert, setCopyAlert] = useState(null);

    const handleCopyRunHistoryPageLink = () => {
        const baseUrl = window.location.origin;
        const relativeUrl = `/library/prompt/${promptId}/run/${resultId}`;
        const fullUrl = `${baseUrl}${relativeUrl}`;

        handleCopy(fullUrl, setCopyAlert);
    };

    return (
        <div className="flex-grow flex flex-col pb-[10px] pt-2 pb-[72px]">
            <h3 className="font-body text-body-bold-m text-neutral-500 uppercase mb-[18px]">
                Output
            </h3>

            <NewRunContainer
                textAreaData={promptOutputs}
                withCopyButton
                withLinkIcon={withLinkIcon}
                fullHeight
                onLinkIconClick={handleCopyRunHistoryPageLink}
                simplified={isRunning}
                updateTextAreaData={updatePromptOutputs}
                placeholder="Output will be added here"
                scrollInsideTextArea={scrollInsideTextArea}
                fixedMinHeightOfTextArea={240}
                outputRating={outputRating}
                updateOutputRating={updateOutputRating}
                resultId={resultId}
            />

            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={2500}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </div>
    );
};

export default OutputContainer;
