import React from 'react';
import ThreadCard from '../ThreadCard/ThreadCard';

const ThreadsList = ({ threads, mutate }) => {
    return (
        <div className="pl-3 pr-4 pt-4 pb-[90px] sm:px-8 sm:pt-8 sm:pb-[40px] max-w-full">
            <ul className="flex flex-col gap-2.5 max-w-full">
                {threads.map((thread) => (
                    <ThreadCard key={thread.id} thread={thread} mutate={mutate} />
                ))}
            </ul>
        </div>
    );
};

export default ThreadsList;
