import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import LoaderFillIcon from '../Icons/LoaderFillIcon';

ButtonIcon.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'neutral', 'ghost', 'link', 'grey']).isRequired,
    size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']).isRequired,
    state: PropTypes.oneOf(['disabled', 'loading', 'default']),
    icon: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    logAs: PropTypes.string,
    cursor: PropTypes.oneOf(['pointer', 'grab']),
    noRoundedLeft: PropTypes.bool,
    spinOnLoading: PropTypes.bool,
    showSpinnerOnLoading: PropTypes.bool,
};

function ButtonIcon(props) {
    const {
        type,
        size,
        state = 'default',
        icon,
        onClick,
        logAs,
        cursor = 'pointer',
        noRoundedLeft = false,
        spinOnLoading = false, // spin the current Icon
        showSpinnerOnLoading = false,
    } = props;

    const sizes = {
        lg: 'p-4',
        md: 'p-3',
        sm: 'p-2',
        xs: 'p-1.5',
    };

    const colors = {
        primary:
            state === 'disabled' ? 'bg-neutral-300 text-neutral-200' : 'bg-neutral-500 text-white',
        secondary:
            state === 'disabled' ? 'bg-purple-100 text-purple-300' : 'bg-purple-500 text-white',
        neutral:
            state === 'disabled'
                ? 'bg-neutral-100 text-neutral-200'
                : 'bg-neutral-100 text-neutral-400',
        ghost:
            state === 'disabled'
                ? 'bg-white text-neutral-300 shadow-neutral-300'
                : 'bg-white text-neutral-400 shadow-neutral-500',
        link: state === 'disabled' ? 'text-neutral-300' : 'text-neutral-400',
        grey:
            state === 'disabled'
                ? 'bg-neutral-50 text-neutral-200'
                : 'bg-neutral-50 text-neutral-400',
    };

    const shadows = {
        primary: 'focus:shadow-neutral-100',
        secondary: 'focus:shadow-purple-100',
        neutral: 'focus:shadow-neutral-200',
        ghost: 'focus:shadow-neutral-500',
        link: 'focus:shadow-none',
        grey: 'focus:shadow-neutral-200',
    };

    const iconColor = {
        primary: state === 'disabled' ? '#CFD6E5' : '#FFFFFF',
        secondary: state === 'disabled' ? '#C6B8E7' : '#FFFFFF',
        neutral: state === 'disabled' ? '#CFD6E5' : '#33373D',
        ghost: state === 'disabled' ? '#5E6470' : '#33373D',
        link: state === 'disabled' ? '#5E6470' : '#33373D',
        grey: state === 'disabled' ? '#1F2125' : '#33373D',
    };

    const iconSize = {
        lg: 'large',
        md: 'large',
        sm: 'large',
        xs: 'medium',
    };

    const cursorStyle = {
        pointer: 'cursor-pointer disabled:cursor-auto',
        grab: 'cursor-grab disabled:cursor-auto',
    };

    const buttonSize = sizes[size];
    const buttonColors = colors[type];
    const focusShadows = shadows[type];
    const mainIconColor = iconColor[type];
    const cursorClassName = cursorStyle[cursor];

    let className = classNames(
        'flex items-center justify-center gap-2 focus:border-transparent focus:outline-0 focus:shadow-l2 transition-all ease-in',
        buttonSize,
        buttonColors,
        focusShadows,
        cursorClassName,
        {
            'text-small tracking-[0.7px]': size === 'xs',
            'text-base tracking-[0.7px]': size !== 'xs',
            'shadow-inset-2': type === 'ghost',
            'hover:shadow-inset-3 disabled:shadow-inset-2': type === 'ghost' && size === 'lg',
            'rounded-2': !noRoundedLeft,
            'rounded-r-2': noRoundedLeft,
        }
    );

    return (
        <button
            disabled={state === 'disabled' || state === 'loading'}
            className={className}
            onClick={onClick}
            {...(logAs ? { 'data-dd-action-name': logAs } : {})}
        >
            {state === 'loading' && spinOnLoading ? (
                <span className="animate-spin-slow">
                    <SvgIcon icon={icon} size={iconSize[size]} color={mainIconColor} />
                </span>
            ) : state === 'loading' && showSpinnerOnLoading ? (
                <span className="animate-icon-spin">
                    <SvgIcon icon={LoaderFillIcon} size={iconSize[size]} color={mainIconColor} />
                </span>
            ) : (
                <SvgIcon icon={icon} size={iconSize[size]} color={mainIconColor} />
            )}
        </button>
    );
}

export default ButtonIcon;
