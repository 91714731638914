import InboxIndicatorIcon from '../design-system/Icons/InboxIndicatorIcon';
import ArchiveLineIcon from '../design-system/Icons/ArchiveLineIcon';
import { LightbulbFlashLineIcon } from '../design-system/Icons';
import { Settings6LineIcon } from '../design-system/Icons';
import { SpaceShipLineIcon } from '../design-system/Icons';
import { CodeViewIcon } from '../design-system/Icons';
import { Robot2LineIcon } from '../design-system/Icons';
import { FileTextIcon } from '../design-system/Icons';
import { DashboardLineIcon } from '../design-system/Icons';

export const DEFAULT_VIEW_LOCAL_STORAGE_KEY = '"default-library-page-view"';

export const LIBRARY_VIEW_TYPES = {
    personal: 'personal',
    shared: 'shared',
};

export const LIBRARY_VIEW_TYPE_LABELS = {
    personal: 'My Library',
    shared: 'Shared With Me',
};
export const LIBRARY_PAGE_NUMBER_QUERY_PARAM_NAME = 'page';
export const LIBRARY_SORT_QUERY_PARAM_NAME = 'sort';
export const LIBRARY_FILTER_COLLECTION_QUERY_PARAM_NAME = 'collection';
export const LIBRARY_FILTER_TASK_TYPE_QUERY_PARAM_NAME = 'task_type';
export const LIBRARY_SORT_TYPES = {
    alphabetical: 'name',
    created: '-created_at', // most recent first
    lastRun: '-last_run_at', // most recent first
};

export const LIBRARY_SORT_TYPES_LABELS = {
    alphabetical: 'Alphabetical',
    created: 'Most Recently Created',
    lastRun: 'Most Recently Run',
};

export const TASK_TYPES = {
    prompt: 'prompt',
    inbox: 'inbox',
    retrieval: 'retrieval',
    trigger: 'trigger',
    webhook: 'webhook',
    assistant: 'assistant',
    record: 'record',
    api: 'api',
    regex: 'regex',
    function: 'function',
    doc: 'doc',
    transform: 'transform',
    code: 'code',
    plot: 'plot',
};

export const TASK_TYPES_LABEL = {
    prompt: 'Prompt',
    inbox: 'Inbox Task',
    retrieval: 'Retrieval Task',
    trigger: 'Trigger Task',
    webhook: 'Webhook Task',
    assistant: 'Assistant Task',
    record: 'Record Task',
    api: 'API Task',
    regex: 'Regex Task',
    function: 'Function Task',
    doc: 'Document Task',
    transform: 'Transform Task',
    code: 'Code Task',
    plot: 'Plot Task',
};

export const TASK_TYPES_ICON = {
    prompt: Settings6LineIcon,
    inbox: InboxIndicatorIcon,
    retrieval: ArchiveLineIcon,
    trigger: LightbulbFlashLineIcon,
    webhook: SpaceShipLineIcon,
    assistant: Robot2LineIcon,
    record: ArchiveLineIcon,
    api: CodeViewIcon,
    regex: CodeViewIcon,
    function: Robot2LineIcon,
    doc: FileTextIcon,
    transform: CodeViewIcon,
    code: CodeViewIcon,
    plot: DashboardLineIcon,
};

export const SIMPLIFIED_TASK_TYPES = {
    prompt: 'prompt',
    nonPrompt: 'nonPrompt',
};
