import React, { useRef, useState } from 'react';

import { useClickOutside } from '../../../hooks';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import {
    handleCancelMessage,
    handleChangeMessageIsReadStatus,
    handleReassignMessage,
} from '../../../helpers/inboxUtils';

import MessageCancelationConfirmModal from '../MessageCancelationConfirmModal/MessageCancelationConfirmModal';
import ReassignModal from '../ReassignModal/ReassignModal';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { ListOption, ListOptionGroup } from '../../../design-system';

const MessageMoreOptionsPopup = ({
    message,
    setData,
    requestKeysToMutate,
    setNextMessageAfterMessageApproval,
    onClose,
}) => {
    const { is_read } = message;

    const popupRef = useRef(null);

    const [reassignModalOpened, setReassignModalOpened] = useState(false);
    const [cancelationConfirmModalOpened, setCancelationConfirmModalOpened] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleClose = (e) => {
        if (!reassignModalOpened && !cancelationConfirmModalOpened) {
            e?.stopPropagation();
            onClose();
        }
    };

    useClickOutside(popupRef, handleClose);

    const onReassignMessage = async (selectedMember) => {
        await handleReassignMessage({
            selectedMember,
            message,
            setData,
            requestKeysToMutate,
            setNextMessageAfterMessageApproval,
        });
    };

    const onCancelMessage = async () => {
        await handleCancelMessage({
            message,
            setData,
            requestKeysToMutate,
            setNextMessageAfterMessageApproval,
        });
    };

    const onChangeIsReadStatus = async (is_read) => {
        try {
            setIsLoading(true);
            await handleChangeMessageIsReadStatus({
                message,
                is_read,
                setData,
                requestKeysToMutate,
            });
            onClose();
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <div className="absolute right-0 top-[calc(100%+8px)] z-10" ref={popupRef}>
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        {is_read && (
                            <ListOption
                                leadingIconName="inboxIndicatorIcon"
                                text="Mark as unread"
                                isLoading={isLoading}
                                onClick={async () => await onChangeIsReadStatus(false)}
                            />
                        )}
                        {!is_read && (
                            <ListOption
                                leadingIconName="inboxIndicatorIcon"
                                text="Mark as read"
                                isLoading={isLoading}
                                onClick={async () => await onChangeIsReadStatus(true)}
                            />
                        )}
                        <ListOption
                            leadingIconName="squareIcon"
                            text="Cancel message"
                            onClick={() => setCancelationConfirmModalOpened(true)}
                        />
                        <ListOption
                            leadingIconName="userSharedLineIcon"
                            text="Reassign message"
                            onClick={() => setReassignModalOpened(true)}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
                closeByClickOutside={false}
            />
            {reassignModalOpened && (
                <ReassignModal
                    onReassign={onReassignMessage}
                    onClose={() => setReassignModalOpened(false)}
                    onMultiActionOptionsPanelClose={onClose}
                />
            )}
            {cancelationConfirmModalOpened && (
                <MessageCancelationConfirmModal
                    messageSubject={message?.subject}
                    onCancelMessage={onCancelMessage}
                    onClose={() => setCancelationConfirmModalOpened(false)}
                    onMultiActionOptionsPanelClose={onClose}
                />
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default MessageMoreOptionsPopup;
