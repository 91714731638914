import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import { TOOL_TYPE } from '../../../constants/assistant';
import { getAvailableModalMaxHeight } from '../../../helpers/getAvailableModalMaxHeight';

import { useClickOutside } from '../../../hooks';
import { useEscapeKeyListener } from '../../../hooks/useEscapeKeyListener';
import { useModalAutoMinHeight } from '../../../hooks/useModalAutoMinHeight';
import { useClientSideFilterBar } from '../../../hooks/useClientSideFilterBar';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { Button } from '../../../design-system';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';
import CheckboxListItem from '../../../design-system/CheckboxListItem/CheckboxListItem';
import ModalHeaderWithSearch from '../../../design-system/ModalHeaderWithSearch/ModalHeaderWithSearch';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';

const SelectDocModal = ({
    selectedDocs: _selectedDocs,
    docsOptionsHookResponse,
    clearErrorMessage,
    setSelectedTools,
    textareaHeight,
    onClose,
}) => {
    const maxHeight = getAvailableModalMaxHeight(textareaHeight);

    const { options, optionsLoading, totalOptions, setPage, canLoadMoreOptions } =
        docsOptionsHookResponse;

    const showClientSideFilterBar = totalOptions >= 6;

    const { filteredOptions, setFilteredOptions } = useClientSideFilterBar({
        options,
        showClientSideFilterBar,
    });

    const [selectedDocs, setSelectedDocs] = useState(_selectedDocs || {});

    const { isMobile } = useResponsiveBreakpoints();

    const { modalRef, modalMinHeight } = useModalAutoMinHeight({
        maxHeight: maxHeight,
        shouldFixHeight: !isMobile,
        dependenciesArray: [filteredOptions],
    });

    const handleChange = (doc) => {
        setSelectedDocs((prevSelectedDocs) => {
            const isDocSelected = !!prevSelectedDocs[doc.id];
            const updatedDocs = { ...prevSelectedDocs };

            if (isDocSelected) {
                delete updatedDocs[doc.id];
            }

            if (!isDocSelected) {
                updatedDocs[doc.id] = doc;
            }

            return updatedDocs;
        });
    };

    const saveSelectedDocs = (selectedDocs) => {
        clearErrorMessage();

        const noSelectedDocs = !Object.keys(selectedDocs).length;
        if (noSelectedDocs) {
            setSelectedTools({});
            return;
        }

        setSelectedTools((prevTools) => {
            return {
                ...prevTools,
                [TOOL_TYPE.add_documents]: { details: selectedDocs },
            };
        });
    };

    const handleCloseAndSaveSelectedDocs = useCallback(
        (e) => {
            saveSelectedDocs(selectedDocs);
            onClose(e);
        },
        [selectedDocs]
    );

    useClickOutside(modalRef, handleCloseAndSaveSelectedDocs);

    useEscapeKeyListener(handleCloseAndSaveSelectedDocs);

    const handleCancelClick = (e) => {
        const noSelectedDocs = !Object.keys(_selectedDocs).length;
        if (noSelectedDocs) {
            setSelectedTools({});
        }

        onClose(e);
    };

    const docsListClassName = classNames('flex-1 pt-2 mt-1 md:pt-3 md:mt-2 px-5', {
        'md:pb-3': !!filteredOptions?.length,
        'min-h-[70px]': optionsLoading,
        'overflow-y-auto': isMobile || maxHeight >= 300,
    });

    const topFixedContent = (
        <ModalHeaderWithSearch
            onClose={handleCloseAndSaveSelectedDocs}
            onCancelClick={handleCancelClick}
            title="Add Docs"
            listLabel="All Docs"
            options={options}
            setFilteredOptions={setFilteredOptions}
        />
    );

    const content = (
        <div className={docsListClassName}>
            <InfiniteScrollList
                handleFetch={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                items={filteredOptions}
                loading={optionsLoading}
                gap={0}
            >
                {filteredOptions.map((doc) => {
                    const { id } = doc;
                    const isChecked = !!selectedDocs[id];

                    return (
                        <CheckboxListItem
                            key={id}
                            item={doc}
                            isChecked={isChecked}
                            handleChange={handleChange}
                            icon={FileTextLineIcon}
                        />
                    );
                })}
            </InfiniteScrollList>
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={handleCloseAndSaveSelectedDocs}
                closeButtonText="Cancel"
                minHeight="86vh"
                maxHeight="86vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="pt-0"
                bottomButtonsCustomPaddings="px-5 pt-3"
                topFixedContent={topFixedContent}
                customGap={8}
                withCloseButton={false}
                bottomButtonsGroup={
                    <Button type="neutral" size="sm" text="Cancel" onClick={handleCancelClick} />
                }
            >
                <div className="flex flex-col bg-white">{content}</div>
            </MobilePopup>
        );
    }

    return (
        <div className="absolute bottom-[calc(100%+6px)] left-0 right-0 z-50">
            <div
                className="flex flex-col bg-white rounded-2 border-1 border-neutral-200 shadow-l1 overflow-y-auto"
                style={{ maxHeight: `${maxHeight}px`, minHeight: `${modalMinHeight}px` }}
                ref={modalRef}
            >
                {topFixedContent}
                {content}
            </div>
        </div>
    );
};

export default SelectDocModal;
