import React, { memo, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CHAT_ROLE } from '../../../constants/assistant';

import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../design-system/Icons';
import { ButtonIcon } from '../../../design-system';
import MessageWrapper from '../MessageWrapper/MessageWrapper';
import MilestoneCard from '../MilestoneCard/MilestoneCard';

const TriggeredTasksDetails = ({ jobData, isExpanded, toggleSectionExpand }) => {
    const {
        id,
        process: { name },
        milestones,
    } = jobData;

    const navigate = useNavigate();
    const location = useLocation();

    const toggleExpandIcon = isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    const milestonesCount = milestones?.length;
    const sortedMilestones = useMemo(
        () => [...(milestones || [])]?.sort((a, b) => a.order - b.order),
        [milestones]
    );

    const toggleExpand = (e) => {
        e.stopPropagation();
        toggleSectionExpand('triggeredTasksDetails');
    };

    const handleViewJobDetails = () => {
        navigate(`/job/${id}`, { state: { from: location } });
    };

    return (
        <MessageWrapper role={CHAT_ROLE.assistant}>
            <div className="w-full flex gap-2.5 items-start cursor-pointer" onClick={toggleExpand}>
                <p className="max-w-[calc(100%-40px)] font-body text-body-regular-s text-neutral-500 my-auto">
                    <span className="font-body-bold text-body-bold-s">{name} </span>
                    agent triggered {milestonesCount} job {milestonesCount === 1 ? 'task' : 'tasks'}
                </p>
                <ButtonIcon type="link" size="xs" icon={toggleExpandIcon} onClick={toggleExpand} />
            </div>

            {isExpanded && (
                <>
                    {!!milestonesCount && (
                        <ul className="rounded-2 overflow-hidden border-1 border-neutral-200 bg-[#F9FAFB]">
                            {sortedMilestones.map((milestone) => (
                                <MilestoneCard key={milestone.id} milestone={milestone} />
                            ))}
                        </ul>
                    )}
                    <button
                        type="button"
                        onClick={handleViewJobDetails}
                        className="block max-w-fit font-heading-bold text-[14px] font-semibold underline whitespace-nowrap outline-0 focus:outline-0"
                    >
                        View job details
                    </button>
                </>
            )}
        </MessageWrapper>
    );
};

export default memo(TriggeredTasksDetails);
