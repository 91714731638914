import React from 'react';

import NewRichTextArea from '../../../../design-system/NewRichTextArea/NewRichTextArea';

const HowItWorksTabForm = ({ formStateHookResponse }) => {
    const { formData, fieldErrorMessages, handleInputChange } = formStateHookResponse;

    return (
        <div className="h-[300px] flex flex-col">
            <NewRichTextArea
                value={formData.instructions}
                label="How it works"
                isRequired
                setValue={(value) => handleInputChange('instructions', value)}
                state={fieldErrorMessages.instructions ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.instructions}
                minHeight={270}
            />
        </div>
    );
};

export default HowItWorksTabForm;
