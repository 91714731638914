export const updateOrderKeys = (array) =>
    array.map((item, index) => (item.order !== index + 1 ? { ...item, order: index + 1 } : item));

export const insertItemAtSpecificPositionWithOrderUpdate = (array, newItem, targetOrder) => {
    const arrayWithInsertedItem = [
        ...array.slice(0, targetOrder - 1), // before the insertion point
        newItem,
        ...array.slice(targetOrder - 1), // after the insertion point
    ];
    const arrayWithUpdatedOrders = updateOrderKeys(arrayWithInsertedItem);

    return arrayWithUpdatedOrders;
};
