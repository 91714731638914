import React from 'react';
import { Badge } from '../index';
import SvgIcon from '../SvgIcon/SvgIcon';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox/Checkbox';

FilteringPopupOption.propTypes = {
    option: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'white', 'red']),
        trailingBadge: PropTypes.shape({
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])
                .isRequired,
            color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'white', 'red']),
        }),
        leadingIcon: PropTypes.func,
        leadingIconColor: PropTypes.string,
        bulletPointColor: PropTypes.string,
    }).isRequired,
    isAdded: PropTypes.bool.isRequired,
    handleRemoveOption: PropTypes.func.isRequired,
    handleAddFilterOption: PropTypes.func.isRequired,
    showAsBadge: PropTypes.bool,
    badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'white', 'red']),
    optionViewType: PropTypes.oneOf(['checkbox', 'button']),
};

function FilteringPopupOption({
    option,
    isAdded = false,
    handleRemoveOption,
    handleAddFilterOption,
    showAsBadge = false,
    badgeColor,
    optionViewType = 'button',
}) {
    const onChange = () => {
        if (isAdded) {
            handleRemoveOption(option);
        }
        if (!isAdded) {
            handleAddFilterOption(option);
        }
    };

    const optionLabel = showAsBadge ? (
        <div className="flex-grow truncate">
            <Badge
                text={option.name}
                color={option.badgeColor || badgeColor}
                leadingIcon={option.leadingIcon}
                leadingIconColor={option.leadingIconColor}
            />
        </div>
    ) : (
        <div className="flex-grow flex items-center gap-2 truncate">
            {option.leadingIcon && (
                <SvgIcon
                    icon={option.leadingIcon}
                    color={option.leadingIconColor || '#1F2125'}
                    size="large"
                />
            )}
            <div className="flex items-center gap-1.5 truncate">
                {option.bulletPointColor && (
                    <span
                        className="w-[8px] h-[8px] min-w-[8px] rounded-2"
                        style={{ backgroundColor: option.bulletPointColor }}
                    ></span>
                )}
                <p className="font-body text-body-regular-m text-neutral-500 truncate">
                    {option.name}
                </p>
            </div>

            {option.trailingBadge && (
                <Badge text={option.trailingBadge.text} color={option.trailingBadge.color} />
            )}
        </div>
    );

    return (
        <li
            className={`flex items-center justify-between p-2 ${
                optionViewType === 'checkbox' && 'gap-2'
            }`}
        >
            {optionViewType === 'checkbox' && (
                <Checkbox
                    isChecked={isAdded}
                    name="checkbox"
                    label={optionLabel}
                    onChange={onChange}
                />
            )}

            {optionViewType === 'button' && (
                <>
                    {optionLabel}
                    <button
                        type="button"
                        className="font-heading-bold text-[14px] leading-4 text-[#33373D] pl-3"
                        onClick={onChange}
                    >
                        {isAdded ? 'Remove' : 'Add'}
                    </button>
                </>
            )}
        </li>
    );
}

export default FilteringPopupOption;
