import React from 'react';

import { SvgIcon } from '../../index';
import Loading from '../../../components/Loading';

const GenericBlockLoadingState = ({ icon: Icon }) => {
    return (
        <div className="w-full max-w-full flex items-stretch border-1 border-purple-300 rounded-2 overflow-hidden min-h-[64px] z-[6]">
            <div className="self-stretch px-5 bg-purple-100 flex items-center rounded-l-[8px]">
                <SvgIcon color="#754DCF" icon={Icon} size="large" />
            </div>
            <div className="flex-1 flex justify-center">
                <Loading withText={false} />
            </div>
        </div>
    );
};

export default GenericBlockLoadingState;
