import React from 'react';
import { SvgIcon } from '../../../design-system';
import { getPromptRunRatingTableData } from '../../../helpers/getPromptRunRatingTableData';

const PromptRunRatingsTable = ({ ratings }) => {
    const tableData = getPromptRunRatingTableData(ratings);

    const rowClassName = 'flex items-center h-[54px] border-b-1 border-neutral-200';
    const cellClassName = 'font-body text-body-regular-m text-neutral-500 px-5';
    const headCellClassName = 'font-body text-body-bold-m text-neutral-500 px-5';

    return (
        <div className="w-full rounded-2 overflow-hidden shadow-inset-1 shadow-neutral-200 bg-white">
            <div className={rowClassName}>
                <div
                    className={`${headCellClassName} w-[calc(100%-110px)] xs:w-[calc(100%-142px)]`}
                >
                    Ratings
                </div>
                <div className={`${headCellClassName} w-[110px] xs:w-[142px]`}># Runs</div>
            </div>
            {tableData.map((item) => (
                <div className={rowClassName}>
                    <div
                        className={`${cellClassName} w-[calc(100%-110px)] xs:w-[calc(100%-142px)] flex items-center gap-2.5`}
                    >
                        {item.leadingIcon && (
                            <div className="w-[28px] min-w-[28px] h-[28px] flex justify-center items-center">
                                <SvgIcon
                                    size="medium"
                                    color={item.leadingIconColor}
                                    icon={item.leadingIcon}
                                />
                            </div>
                        )}
                        {item.label}
                    </div>
                    <div className={`${cellClassName} w-[110px] xs:w-[142px]`}>
                        {item.rating} {item.rating === 1 ? 'run' : 'runs'}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PromptRunRatingsTable;
