import { useState } from 'react';
import { useRecursiveDataFetch } from './useRecursiveDataFetch';

import { API } from 'constants';
import client from '../services/template-api';

export const useFetchLabels = () => {
    const [labels, setLabels] = useState([]);

    const { loading, isDataFetchCompleted, totalResults } = useRecursiveDataFetch({
        client,
        route: API.ROUTES.template.label,
        useExternalState: true,
        setData: setLabels,
    });

    return {
        labels: labels || [],
        setLabels,
        areLabelsLoading: loading,
        allLabelsAreLoaded: isDataFetchCompleted,
        totalLabels: totalResults,
    };
};
