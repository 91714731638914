import React, { useMemo, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/library-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { capitalizeFirstLetter } from '../../../services/strings';
import { BLOCK_TYPE, PLAYBOOK_ACCESS_TYPE } from '../../../constants/playbookBuilder';

import { Tabs } from '../../../design-system';
import Modal from '../../../design-system/Modal/Modal';
import Loading from '../../../components/Loading';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import ErrorMessage from '../../../design-system/ErrorMessage/ErrorMessage';
import SharingModalSharedBlock from '../SharingModalSharedBlock/SharingModalSharedBlock';

const TABS_KEYS = [PLAYBOOK_ACCESS_TYPE.private, PLAYBOOK_ACCESS_TYPE.shared];
const TABS = TABS_KEYS.map((key) => ({ key, name: capitalizeFirstLetter(key) }));

const PlaybookSharingModal = ({
    playbookDetail,
    setPlaybookDetail,
    playbookContentFormData,
    onClose,
}) => {
    const { id: playbookId, access_type, is_shared } = playbookDetail;

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const currentAccessTypeIndex = access_type === PLAYBOOK_ACCESS_TYPE.shared ? 1 : 0;

    const isDocumentBlockPresent = useMemo(() => {
        if (access_type === PLAYBOOK_ACCESS_TYPE.shared) {
            return false;
        }
        return playbookContentFormData?.some((section) =>
            section.block_content?.some((block) => block.block_type === BLOCK_TYPE.document)
        );
    }, [playbookContentFormData]);

    const handleShare = async ({ key }) => {
        const canShare =
            key === PLAYBOOK_ACCESS_TYPE.shared &&
            access_type !== PLAYBOOK_ACCESS_TYPE.shared &&
            !is_shared &&
            !isLoading;
        if (!canShare) return;

        try {
            setIsLoading(true);
            const { data } = await client.patch(`${API.ROUTES.library.playbook}${playbookId}/`, {
                access_type: PLAYBOOK_ACCESS_TYPE.shared,
            });
            setPlaybookDetail(data);
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        } finally {
            setIsLoading(false);
        }
    };

    const isErrorMessageDisplayed =
        isDocumentBlockPresent && access_type === PLAYBOOK_ACCESS_TYPE.private;

    const tabsDisabled =
        isDocumentBlockPresent ||
        access_type === PLAYBOOK_ACCESS_TYPE.shared ||
        is_shared ||
        isLoading;

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <div className="p-8 flex flex-col gap-6">
                <ModalHeader title="Playbook Sharing" onClose={onClose} />

                <div className="max-w-fit relative">
                    <Tabs
                        tabs={TABS}
                        onTabChanged={handleShare}
                        tabIndex={currentAccessTypeIndex}
                        disabled={tabsDisabled}
                    />

                    {isLoading && (
                        <div className="absolute left-[calc(100%+8px)] top-1/2 transform -translate-y-1/2">
                            <Loading withText={false} size="small" />
                        </div>
                    )}
                </div>

                {isErrorMessageDisplayed && (
                    <ErrorMessage
                        size="medium"
                        message="Please remove all document blocks from this playbook before sharing."
                    />
                )}

                {access_type === PLAYBOOK_ACCESS_TYPE.shared && (
                    <SharingModalSharedBlock
                        playbookDetail={playbookDetail}
                        setPlaybookDetail={setPlaybookDetail}
                    />
                )}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default PlaybookSharingModal;
