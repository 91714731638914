import React, { useEffect, useState } from 'react';

import { THREADS_TAB } from '../../../constants/threads';
import { mergeNewDataIntoCurrent } from '../../../helpers/mergeNewDataIntoCurrent';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import ThreadCard from '../ThreadCard/ThreadCard';
import Loading from '../../../components/Loading';
import { Button } from '../../../design-system';
import ExecutionCard from '../ExecutionCard/ExecutionCard';
import { useCollectListDataForMobileViews } from '../../../hooks/useCollectListDataForMobileViews';

const MobilePaginatedContainer = ({
    activeTab,
    data,
    shouldSetNewData,
    setShouldSetNewData,
    canLoadMore,
    setPage,
    loading,
    total,
    requestKeysToMutate,
}) => {
    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 850 });

    const [collectedData, setCollectedData] = useCollectListDataForMobileViews({
        data,
        shouldSetNewData,
        setShouldSetNewData,
    });

    if (!isMobile) {
        return <></>;
    }

    return (
        <div className="w-full flex flex-col gap-5">
            {!!collectedData?.length && (
                <div className="w-full flex flex-col gap-2">
                    {activeTab === THREADS_TAB.threads &&
                        collectedData.map((thread) => (
                            <ThreadCard
                                key={thread.id}
                                thread={thread}
                                setThreads={setCollectedData}
                                requestKeysToMutate={requestKeysToMutate[THREADS_TAB.threads] || []}
                            />
                        ))}
                    {activeTab === THREADS_TAB.executions &&
                        collectedData.map((thread) => (
                            <ExecutionCard key={thread.id} data={thread} />
                        ))}
                </div>
            )}
            {canLoadMore && total !== collectedData?.length && (
                <Button
                    type="link"
                    size="xs"
                    text="Load More"
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                />
            )}
            {loading && (
                <div className="pt-3">
                    <Loading withText={!collectedData?.length} />
                </div>
            )}
        </div>
    );
};

export default MobilePaginatedContainer;
