import React from 'react';

const CheckboxIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <g>
                <path
                    d="M6 12.5714L9.65714 16L18 8"
                    strokeWidth="1.90476"
                    strokeLinecap="square"
                    stroke={props.color}
                />
            </g>
        </svg>
    );
};

export default CheckboxIcon;
