import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { API } from 'constants';
import apiClient from 'services/unauthorized-user-api';
import useUser from 'hooks/useUser';
import { handleContactUs } from '../../helpers/handleContactUs';

import { Button, Form } from 'design-system';
import Loading from 'components/Loading';
import Input from 'design-system/Input/Input';
import Alert from 'design-system/Alert/Alert';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';

export default function Register() {
    const { user } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    const getQueryParam = (param) => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(param);
    };

    const inviteCode = getQueryParam('invite_code');

    useEffect(() => {
        if (user) {
            navigate('/library');
        } else {
            if (!inviteCode) {
                navigate('/login');
            } else {
                verifyInviteCode(inviteCode);
            }
        }
    }, [user, location.search, inviteCode]);

    const [apiResponse, setApiResponse] = useState({
        loading: false,
        success: false,
        errorMsg: null,
    });

    const [errorMessages, setErrorMessages] = useState({
        email: '',
        password1: '',
        password2: '',
        other: '',
        invite_code: '',
    });

    const [formData, setFormData] = useState({
        email: undefined,
        password1: undefined,
        password2: undefined,
        first_name: undefined,
        last_name: undefined,
        invite_code: inviteCode,
    });

    const verifyInviteCode = async (code) => {
        try {
            const response = await apiClient.get(`verify-invite/${code}/`, {
                headers: user ? { Authorization: `Token ${user.token}` } : {},
            });
            // if (user) {
            //     navigate(response.data.redirect);
            // } else {

            setFormData({ ...formData, email: response.data.email });
            // console.log(response.data);

            if (response.data.invite_status === 'completed') {
                setErrorMessages((prev) => ({
                    ...prev,
                    invite_code: {
                        messageHeader: 'INVITE ALREADY ACCEPTED',
                        messageTitle: 'This invite has already been accepted.',
                        messageBody: 'Log into your account here.',
                        buttonText: 'Login',
                        buttonAction: () => navigate('/login'),
                    },
                }));
            } else if (!response.data.is_active) {
                setErrorMessages((prev) => ({
                    ...prev,
                    invite_code: {
                        messageHeader: 'INVITE DEACTIVATED',
                        messageTitle: 'This invite has been deactivated.',
                        messageBody:
                            'Please contact your organization admin for a new invite. Or contact us!',
                        buttonText: 'Contact Us',
                        buttonAction: () => handleContactUs(),
                    },
                }));
            }
        } catch (error) {
            setErrorMessages((prev) => ({
                ...prev,
                invite_code: {
                    messageHeader: 'INVALID INVITE',
                    messageTitle: 'This invite is invalid.',
                    messageBody: 'Please contact us for help.',
                    buttonText: 'Contact Us',
                    buttonAction: () => handleContactUs(),
                },
            }));
        }
    };

    const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handleSubmit = async (e) => {
        // console.log('formData', formData);
        e.preventDefault();
        setApiResponse({
            loading: true,
            success: false,
            errorMsg: null,
        });
        setErrorMessages({
            email: '',
            password1: '',
            password2: '',
            other: '',
        });

        try {
            const response = await apiClient.post(API.ROUTES.user.register, formData);
            setApiResponse({
                loading: false,
                success: response.status === 201,
                errorMsg: null,
            });
        } catch (error) {
            const { data } = error.response;
            if (data) {
                // console.log(data);
                // Handle the specific errors based on the response from your API
                if (data.email) {
                    setErrorMessages((prev) => ({ ...prev, email: data.email[0] }));
                }
                if (data.password1) {
                    setErrorMessages((prev) => ({ ...prev, password1: data.password1[0] }));
                }
                if (data.password2) {
                    setErrorMessages((prev) => ({ ...prev, password2: data.password2[0] }));
                }
                if (data.non_field_errors) {
                    // Show an alert for password mismatch
                    setErrorMessages((prev) => ({ ...prev, other: data.non_field_errors[0] }));
                }
            }
            setApiResponse({
                loading: false,
                success: false,
                errorMsg: error.message,
            });
        }
    };

    let formBody;
    if (apiResponse.loading) {
        formBody = <Loading />;
    } else if (apiResponse.success) {
        formBody = (
            <div>
                <h2 className="font-heading-bold text-heading-bold-m text-center mb-[4px]">
                    Check your email
                </h2>
                <p className="font-body text-body-regular-m text-center max-w-[350px] mx-auto">
                    A verification email has been sent to your email address.
                </p>
            </div>
        );
    } else {
        formBody = (
            <div className="flex flex-col gap-[16px]">
                {errorMessages.other && (
                    <Alert
                        status="critical"
                        message={errorMessages.other}
                        icon={ErrorWarningLineIcon}
                        handleClose={() => setErrorMessages((prev) => ({ ...prev, other: '' }))}
                    />
                )}
                <Input
                    size="xs"
                    label="Email"
                    value={formData.email}
                    name="email"
                    type="email"
                    isRequired={true}
                    onChange={handleInputChange}
                    state={errorMessages.email ? 'error' : 'default'}
                    errorMessage={errorMessages.email}
                />
                <Input
                    size="xs"
                    label="First name"
                    value={formData.first_name}
                    name="first_name"
                    type="text"
                    isRequired={true}
                    onChange={handleInputChange}
                />
                <Input
                    size="xs"
                    label="Last name"
                    value={formData.last_name}
                    name="last_name"
                    type="text"
                    isRequired={true}
                    onChange={handleInputChange}
                />
                <Input
                    size="xs"
                    label="Password"
                    value={formData.password1}
                    name="password1"
                    isRequired={true}
                    type="password"
                    onChange={handleInputChange}
                    state={errorMessages.password1 ? 'error' : 'default'}
                    errorMessage={errorMessages.password1}
                />
                <Input
                    size="xs"
                    label="Confirm Password"
                    value={formData.password2}
                    name="password2"
                    isRequired={true}
                    type="password"
                    onChange={handleInputChange}
                    state={errorMessages.password2 ? 'error' : 'default'}
                    errorMessage={errorMessages.password2}
                />
                <div className="mx-auto">
                    <Button type="primary" text="Create Account" size="sm" isSubmit={true} />
                </div>
            </div>
        );
    }

    return (
        <div>
            {errorMessages.invite_code && (
                <div className="py-[80px] flex flex-col items-center">
                    <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-lime-500 rounded-full mb-4">
                        <ErrorWarningLineIcon width={32} height={32} color="#5E6470" />
                    </div>
                    <p className="font-body text-regular-s text-success-500 uppercase text-center mb-1">
                        {errorMessages.invite_code.messageHeader}
                    </p>
                    <p className="font-heading-bold text-heading-bold-m text-black text-center min-[350px]:w-[85%] max-w-[400px] mb-2">
                        {errorMessages.invite_code.messageTitle}
                    </p>
                    <p className="font-body text-regular-m text-black text-center min-[350px]:w-[85%] max-w-[340px] mb-6">
                        {errorMessages.invite_code.messageBody}
                    </p>
                    <Button
                        type="secondary"
                        size="md"
                        text={errorMessages.invite_code.buttonText}
                        onClick={errorMessages.invite_code.buttonAction}
                    />
                </div>
            )}
            <div className="Register mx-auto py-[80px] w-[300px] flex flex-col gap-[32px]">
                {apiResponse.success && !errorMessages.invite_code && formBody}
                {!apiResponse.success && !errorMessages.invite_code && (
                    <Form title="Create Account" onSubmit={handleSubmit}>
                        {formBody}
                    </Form>
                )}
                {!apiResponse.loading && !errorMessages.invite_code && !apiResponse.success && (
                    <div className="flex gap-[16px] justify-center">
                        <Button
                            type="link"
                            size="sm"
                            text="Forgot Password"
                            onClick={() => {
                                navigate('/password-reset');
                            }}
                        />
                        <Button
                            type="link"
                            size="sm"
                            text="Login"
                            onClick={() => {
                                navigate('/login');
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
