import ThumbsUpIcon from '../design-system/Icons/ThumbsUpIcon';
import ThumbsDownIcon from '../design-system/Icons/ThumbsDownIcon';

export const getPromptRunRatingTableData = (rating) => [
    { label: 'Not Rated', rating: rating.none },
    {
        label: 'Good',
        leadingIcon: ThumbsUpIcon,
        leadingIconColor: '#0E9F6E',
        rating: rating.good,
    },
    {
        label: 'Bad',
        leadingIcon: ThumbsDownIcon,
        leadingIconColor: '#E95B69',
        rating: rating.bad,
    },
];
