import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useClickOutside } from '../../hooks';
import { useEscapeKeyListener } from '../../hooks/useEscapeKeyListener';
import { useClientSideFilterBar } from '../../hooks/useClientSideFilterBar';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';

import { SvgIcon } from '../../design-system';
import ModalHeader from '../../design-system/ModalHeader/ModalHeader';
import MobilePopup from '../../design-system/MobilePopup/MobilePopup';
import FileTextLineIcon from '../../design-system/Icons/FileTextLineIcon';
import InfiniteScrollList from '../InfiniteScrollList/InfiniteScrollList';
import ClientSideFilteredSearchBar from '../ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';

const SelectDocModalTemplate = ({
    docsOptionsHookResponse,
    onClose,
    maxHeight = 400,
    handleDocSelect,
    modalTitle,
    withEscapeButton = true,
    desktopModalPosition = 'absolute bottom-[calc(100%+6px)] left-0 right-0 z-50',
}) => {
    const modalRef = useRef(null);
    const [modalMinHeight, setModalMinHeight] = useState(0);

    const { options, optionsLoading, totalOptions, setPage, canLoadMoreOptions } =
        docsOptionsHookResponse;

    const showClientSideFilterBar = totalOptions >= 6;

    const { filteredOptions, setFilteredOptions } = useClientSideFilterBar({
        options,
        showClientSideFilterBar,
    });

    useClickOutside(modalRef, onClose);

    useEscapeKeyListener(onClose);

    const { isMobile } = useResponsiveBreakpoints();

    useEffect(() => {
        if (modalRef.current && !isMobile) {
            const modalHeight = modalRef.current.offsetHeight;
            if (modalHeight > modalMinHeight) {
                setModalMinHeight(Math.min(modalHeight, maxHeight));
            }
        }
    }, [modalRef.current, filteredOptions]);

    const docsListClassName = classNames('flex-1 pt-2 mt-1 md:pt-3 md:mt-2 px-5', {
        'md:pb-3': !!filteredOptions?.length,
        'min-h-[70px]': optionsLoading,
        'overflow-y-auto': isMobile || maxHeight >= 300,
    });

    const topFixedContent = (
        <div className="flex flex-col">
            <ModalHeader
                onClose={onClose}
                title={modalTitle}
                outlined
                titleStyles="font-heading-bold text-heading-bold-s text-neutral-500"
                customPaddings="py-[11px] px-5"
                withEscapeButton={withEscapeButton}
            />

            <div className="pt-5 flex-1 flex flex-col gap-5">
                <div className="flex flex-col gap-4 md:gap-5 px-5">
                    <div className="flex items-center gap-3 l:gap-5">
                        <ClientSideFilteredSearchBar
                            setFilteredList={setFilteredOptions}
                            list={options}
                            withClearIcon
                            size="md"
                            keyToFilter={['label']}
                        />
                        <button
                            type="button"
                            className="font-body text-body-regular-m text-neutral-500 underline"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>

                    <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase">
                        All Docs
                    </p>
                </div>
            </div>
        </div>
    );

    const content = (
        <div className={docsListClassName}>
            <InfiniteScrollList
                handleFetch={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                items={filteredOptions}
                loading={optionsLoading}
                gap={0}
            >
                {filteredOptions.map((doc) => {
                    const { id, label } = doc;

                    return (
                        <div
                            key={id}
                            className={
                                'px-3 py-2 w-full max-w-full flex items-center gap-1 truncate rounded-[6px] hover:bg-neutral-50 cursor-pointer min-h-[37px] bg-white'
                            }
                            onClick={(event) => handleDocSelect(event, doc)}
                        >
                            <SvgIcon color="#754DCF" size="medium" icon={FileTextLineIcon} />
                            <p className="flex-1 font-body-bold text-body-bold-s text-[#25324B] truncate">
                                {label}
                            </p>
                        </div>
                    );
                })}
            </InfiniteScrollList>
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                closeButtonText="Cancel"
                minHeight="86vh"
                maxHeight="86vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="pt-0"
                bottomButtonsCustomPaddings="px-5 pt-3"
                topFixedContent={topFixedContent}
                customGap={8}
            >
                <div className="flex flex-col bg-white">{content}</div>
            </MobilePopup>
        );
    }

    return (
        <div className={desktopModalPosition}>
            <div
                className="flex flex-col bg-white rounded-2 border-1 border-neutral-200 shadow-l1 overflow-y-auto"
                style={{ maxHeight: `${maxHeight}px`, minHeight: `${modalMinHeight}px` }}
                ref={modalRef}
            >
                {topFixedContent}
                {content}
            </div>
        </div>
    );
};
export default SelectDocModalTemplate;
