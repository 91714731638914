import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SvgIcon } from '../index';

Tag.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['basic', 'ghost']),
    uppercase: PropTypes.bool,
    leadingIcon: PropTypes.func,
    leadingIconColor: PropTypes.string,
};

function Tag({
    text,
    type = 'basic',
    uppercase = false,
    leadingIcon,
    leadingIconColor = '#5E6470',
}) {
    const tagClassNames = classNames(
        'px-2 py-[3px] border-1 font-body text-neutral-300 w-fit flex items-center gap-1',
        {
            uppercase,
            'rounded-full border-neutral-200 text-body-regular-xs': type === 'basic',
            'rounded-2 border-neutral-300 text-[12px] font-semibold': type === 'ghost',
        }
    );

    return (
        <div className={tagClassNames}>
            {leadingIcon && <SvgIcon color={leadingIconColor} icon={leadingIcon} size="medium" />}
            {text}
        </div>
    );
}

export default Tag;
