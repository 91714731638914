import React, { useState } from 'react';

import { Button, ButtonIcon } from '../../../../design-system';
import { ArrowDownSLineIcon } from '../../../../design-system/Icons';
import TemplateMultiActionPopup from '../TemplateMultiActionPopup/TemplateMultiActionPopup';
import { TEMPLATE_TYPES } from '../../../../constants/template';

const TemplateActionBar = ({
    templateType,
    isLive,
    isEditAccess,
    isMainBranch,
    branchStatus,
    isLoadingBranchChange,
    handleSaveChanges,
    areChangesToSave,
    saveChangesLoading,
    changeStatusLoading,
    handleStatusChange,
    setConfirmCancelIssueModalOpened,
    setIsProcessVersionPublishingModalOpened,
}) => {
    const [multiActionPopupOpened, setMultiActionPopupOpened] = useState(false);

    // make the MultiActionPopupButton active if isEditAccess = True (means the not main branch selected and status == “open” OR status == “changes_requested” OR status == “ready_for_review”) and if areChangesToSave (means user edited some fields)
    const saveButtonState =
        !isEditAccess || isLoadingBranchChange || !areChangesToSave
            ? 'disabled'
            : saveChangesLoading
            ? 'loading'
            : 'default';

    // for goal template make the MultiActionPopupButton active if the not main and not "canceled" branch selected or if the main branch has the property is_live = True
    const openGoalTemplateMultiActionPopupButtonState =
        ((isMainBranch && isLive) || (!isMainBranch && branchStatus !== 'canceled')) &&
        !isLoadingBranchChange
            ? 'default'
            : 'disabled';

    // for process template make the MultiActionPopupButton active if the not main and not "canceled" branch selected or if the main branch has the property is_live = True
    const openProcessTemplateMultiActionPopupButtonState =
        ((isMainBranch && isLive) || (!isMainBranch && branchStatus !== 'canceled')) &&
        !isLoadingBranchChange
            ? 'default'
            : 'disabled';

    const openMultiActionPopupButtonState = {
        [TEMPLATE_TYPES.goal]: openGoalTemplateMultiActionPopupButtonState,
        [TEMPLATE_TYPES.process]: openProcessTemplateMultiActionPopupButtonState,
    };

    return (
        <div className="fixed right-4 bottom-3 z-10 p-2 rounded-2">
            <div className="flex gap-[1px] relative">
                <Button
                    type="primary"
                    size="sm"
                    text="Save"
                    state={saveButtonState}
                    noRoundedRight
                    onClick={handleSaveChanges}
                />
                <ButtonIcon
                    type="primary"
                    size="sm"
                    icon={ArrowDownSLineIcon}
                    state={openMultiActionPopupButtonState[templateType]}
                    noRoundedLeft
                    onClick={() => setMultiActionPopupOpened(true)}
                />
                {multiActionPopupOpened && (
                    <TemplateMultiActionPopup
                        templateType={templateType}
                        isEditAccess={isEditAccess}
                        isMainBranch={isMainBranch}
                        branchStatus={branchStatus}
                        areChangesToSave={areChangesToSave}
                        handleSaveChanges={handleSaveChanges}
                        saveChangesLoading={saveChangesLoading}
                        handleStatusChange={handleStatusChange}
                        changeStatusLoading={changeStatusLoading}
                        setConfirmCancelIssueModalOpened={setConfirmCancelIssueModalOpened}
                        setIsProcessVersionPublishingModalOpened={
                            setIsProcessVersionPublishingModalOpened
                        }
                        onClose={() => setMultiActionPopupOpened(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default TemplateActionBar;
