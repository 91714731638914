import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '../../design-system';
import { Robot2FillIcon } from '../../design-system/Icons';

CreditsCountBadge.propTypes = {
    cost: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
};

function CreditsCountBadge({ cost, label }) {
    return (
        <div className="flex items-center gap-1 sm:gap-2">
            <SvgIcon color="#1F2125" icon={Robot2FillIcon} size="medium" />
            <p className="font-body text-body-regular-xs text-neutral-500">
                <span className="font-body-bold text-body-bold-m">
                    {cost} {cost === 1 ? 'Credit' : 'Credits'}{' '}
                </span>
                /per {label}
            </p>
        </div>
    );
}

export default CreditsCountBadge;
