import React, { useMemo, useState } from 'react';

import * as API from '../../../../constants/api';
import client from '../../../../services/organization-api';
import { useDeleteRequest, useGetRequest } from '../../../../hooks/request';

import { handleContactUs } from '../../../../helpers/handleContactUs';
import { transformDataMembersTable } from '../../../../helpers/formatOrganizationDataForTable';

import { ORGANIZATION_PLAN, USER_ROLE } from '../../../../constants/organization';

import Table from '../../../../design-system/Table/Table';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import EditMembershipModal from '../EditMembershipModal/EditMembershipModal';

const OrganizationMembersBlock = ({
    organizationData,
    refetchOrganizationData,
    isOwnerOrAdmin,
    orgPlan,
}) => {
    const { organization, organization_members } = organizationData;
    const memberDetail = useGetRequest({}, client);
    const teamDelete = useDeleteRequest({}, client);

    const membersTableData = useMemo(
        () => transformDataMembersTable(organization_members || []),
        [organization_members]
    );

    const [editMemberModal, setEditMemberModal] = useState({
        opened: false,
        data: null,
        mode: 'create', // can be "edit"
    });
    const [deleteMembershipConfirmModal, setDeleteMembershipConfirmModal] = useState({
        opened: false,
        itemId: null,
    });

    const hasEditAccess =
        isOwnerOrAdmin &&
        (orgPlan === ORGANIZATION_PLAN.pro || orgPlan === ORGANIZATION_PLAN.agency);

    const rowActionButtons = hasEditAccess
        ? [
              {
                  action: 'edit',
                  handleAction: (row) => fetchMemberDetail(row.id),
              },
              {
                  action: 'delete',
                  handleAction: handleDeleteMemberClick,
              },
          ]
        : [];

    const tableActionButton = isOwnerOrAdmin
        ? orgPlan === ORGANIZATION_PLAN.plus
            ? {
                  buttonText: 'Contact us for Team Sharing',
                  buttonSize: 'sm',
                  onClick: handleContactUs,
              }
            : {
                  buttonText: 'Add Member',
                  buttonSize: 'sm',
                  onClick: () => {
                      setEditMemberModal({ opened: true, data: null, mode: 'create' });
                  },
              }
        : null;

    async function fetchMemberDetail(memberId) {
        const { success, response } = await memberDetail.request({
            url: `${API.ROUTES.organization.organizationMembership}${memberId}/`,
        });
        if (success) {
            setEditMemberModal({ opened: true, mode: 'edit', data: response });
        }
    }

    function handleDeleteMemberClick(row) {
        const isOwner = row.role === USER_ROLE.owner;
        if (isOwner) {
        }

        if (!isOwner) {
            setDeleteMembershipConfirmModal({
                opened: true,
                itemId: row.id,
            });
        }
    }

    const handleDeleteMembershipConfirm = async (membershipId) => {
        const { success } = await teamDelete.request({
            url: `${API.ROUTES.organization.organizationMembership}${membershipId}/`,
        });
        if (success) {
            await refetchOrganizationData();
            setDeleteMembershipConfirmModal({ opened: false });
        }
    };

    const closeEditMembershipModal = () =>
        setEditMemberModal({
            opened: false,
            data: null,
            mode: 'create',
        });

    const closeDeleteMembershipConfirmModal = () =>
        setDeleteMembershipConfirmModal({
            opened: false,
        });

    return (
        <>
            <div className="flex flex-col gap-2">
                <p className="font-body-bold text-body-bold-s text-neutral-500">
                    Organization Members
                </p>

                {membersTableData && (
                    <div className="w-full relative">
                        <Table
                            data={membersTableData}
                            size="md"
                            rowActionButtons={rowActionButtons}
                            includePagination={false}
                            columnsCount={1}
                            shift={284}
                            tableActionButton={tableActionButton}
                        />
                    </div>
                )}
            </div>

            {editMemberModal.opened && (
                <EditMembershipModal
                    data={editMemberModal.data}
                    handleSave={refetchOrganizationData}
                    mode={editMemberModal.mode}
                    organizationData={organization}
                    onClose={closeEditMembershipModal}
                />
            )}

            {deleteMembershipConfirmModal.opened && (
                <ConfirmationModal
                    onConfirm={handleDeleteMembershipConfirm}
                    itemId={deleteMembershipConfirmModal.itemId}
                    confirmText="Are you sure you want to delete this member? This will remove the member from any client organizations they are a part of."
                    onClose={closeDeleteMembershipConfirmModal}
                />
            )}
        </>
    );
};

export default OrganizationMembersBlock;
