import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { STATUS_COLORS, STATUS_LABELS, STATUS_TAB_KEYS } from '../../../constants/inbox';
import { Badge, Button } from '../../../design-system';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import InstructionsModal from '../../../components/InstructionsModal/InstructionsModal';
import InboxTaskApprovedBanner from '../InboxTaskApprovedBanner/InboxTaskApprovedBanner';

const MessageDetailHeader = ({ message, containerRef, isSimplifiedView = false }) => {
    const [searchParams] = useSearchParams();
    const isExpandedMessageDetailView = !!searchParams.get('message_id') && !isSimplifiedView;

    const { width: screenWidth } = useWindowSize();
    const viewInstructionButtonRef = useRef(null);

    const { process, subject, created_at, status, approved_by, instructions } = message;
    const formattedTime = DateTime.fromISO(created_at).toFormat('LLL dd yyyy @ h:mma');

    const [instructionModalOpened, setInstructionModalOpened] = useState(false);

    const isMobile = screenWidth < 900;
    const showViewTaskInstructionsButton =
        instructions && (isMobile || !isExpandedMessageDetailView);

    const isMessageCompleted = message.status === STATUS_TAB_KEYS.completed;
    const isApprovedBannerDisplayed =
        isMessageCompleted && message.thread && isExpandedMessageDetailView;

    return (
        <div className="w-full pb-6 border-b-1 border-neutral-200 flex flex-col gap-1 sm:gap-5">
            {process && !isSimplifiedView && (
                <div>
                    <Badge
                        text={process.name}
                        color="neutral"
                        leadingIconColor={process.icon_color}
                        leadingIcon={PlayCircleFillIcon}
                    />
                </div>
            )}
            {isApprovedBannerDisplayed && !isMobile && (
                <InboxTaskApprovedBanner threadId={message.thread} />
            )}
            <div>
                <p
                    className={classNames('w-full font-body text-body-bold-l text-black', {
                        'line-clamp-2': !isExpandedMessageDetailView,
                        'mb-1': !isSimplifiedView || !showViewTaskInstructionsButton,
                    })}
                >
                    {subject}
                </p>
                <div className="flex items-center justify-between gap-x-4 gap-y-0.5 flex-wrap">
                    <div
                        className={`flex items-center gap-1 flex-wrap ${
                            !isSimplifiedView && 'sm:py-2'
                        }`}
                    >
                        <div className="flex items-center gap-1">
                            <div
                                className="rounded-[50%] w-[8px] h-[8px] min-w-[8px]"
                                style={{ backgroundColor: STATUS_COLORS[status] }}
                            ></div>
                            <p className="font-body text-body-bold-s text-neutral-500">
                                {STATUS_LABELS[status]}{' '}
                                {approved_by && status === 'completed' && `by ${approved_by}`}
                            </p>
                        </div>

                        <p className="font-body text-body-regular-s text-neutral-300 ml-1">
                            {formattedTime}
                        </p>
                    </div>
                    {showViewTaskInstructionsButton && (
                        <div className="relative" ref={viewInstructionButtonRef}>
                            <Button
                                type="link"
                                size="xs"
                                text="View Instructions"
                                onClick={() => setInstructionModalOpened(true)}
                            />
                            {instructionModalOpened && (
                                <InstructionsModal
                                    instructions={instructions}
                                    pageContainerRef={containerRef}
                                    viewInstructionButtonRef={viewInstructionButtonRef}
                                    onClose={(e) => {
                                        e?.stopPropagation();
                                        setInstructionModalOpened(false);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>

            {isApprovedBannerDisplayed && isMobile && (
                <div className="pt-5 sm:pt-0">
                    <InboxTaskApprovedBanner threadId={message.thread} />
                </div>
            )}
        </div>
    );
};

export default MessageDetailHeader;
