import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import Button from 'design-system/Button/Button';
import { DeleteBin4LineIcon, FileCopyLineIcon } from 'design-system/Icons';
import ButtonIcon from 'design-system/ButtonIcon/ButtonIcon';
import Alert from 'design-system/Alert/Alert';
import CheckLineIcon from 'design-system/Icons/CheckLineIcon';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';
import EditIcon from 'design-system/Icons/EditIcon';
import { Badge } from '../index';
import ToolTip from '../ToolTip/ToolTip';
import { InformationLineIcon } from '../Icons';
import Loading from '../../components/Loading';
import { useWindowSize } from '../../hooks/useWindowSize';
import OutputRatingControls from '../../components/OutputRatingControls/OutputRatingControls';
import DownloadIcon from '../Icons/DownloadIcon';
import { defaultErrorMessage } from '../../constants/errorMessages';
import LinkIcon from '../Icons/LinkIcon';

NewTextAreaBox.propTypes = {
    id: PropTypes.string,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    size: PropTypes.oneOf(['sm', 'lg']),
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelToCopyAlert: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    errorMessageHighlightedStart: PropTypes.string, // bold start of error message
    readOnlyWhileError: PropTypes.bool, // disable input while is Error State
    isRequired: PropTypes.bool,
    indicator: PropTypes.string,
    statusIndicator: PropTypes.bool,
    leftButton: PropTypes.shape({
        text: PropTypes.string.isRequired,
        leadingIcon: PropTypes.func,
        trailingIcon: PropTypes.func,
        helperIcon: PropTypes.func,
        onClick: PropTypes.func,
        isSubmit: PropTypes.bool,
    }),
    rightButton: PropTypes.shape({
        text: PropTypes.string.isRequired,
        leadingIcon: PropTypes.func,
        trailingIcon: PropTypes.func,
        helperIcon: PropTypes.func,
        onClick: PropTypes.func,
        isSubmit: PropTypes.bool,
    }),
    onChange: PropTypes.func,
    withCopyButton: PropTypes.bool,
    withDeleteButton: PropTypes.bool,
    withEditButton: PropTypes.bool,
    withLinkIcon: PropTypes.bool,
    withDownloadButton: PropTypes.bool,
    downloadFileName: PropTypes.string,
    onDeleteButtonClick: PropTypes.func,
    onEditButtonClick: PropTypes.func,
    onLinkIconClick: PropTypes.func,
    fullHeight: PropTypes.bool,
    simplified: PropTypes.bool,
    darkBg: PropTypes.bool,
    lightBorder: PropTypes.bool,
    labelBadge: PropTypes.shape({
        text: PropTypes.string,
        color: PropTypes.string,
    }),
    badge: PropTypes.shape({
        text: PropTypes.string,
        color: PropTypes.string,
    }),
    info: PropTypes.shape({
        text: PropTypes.string,
        state: PropTypes.oneOf(['default', 'error']),
    }),
    isLoading: PropTypes.bool,
    scrollInsideTextArea: PropTypes.bool,
    fixedMinHeight: PropTypes.number,
    leadingIcon: PropTypes.func,
    outputRating: PropTypes.number,
    updateOutputRating: PropTypes.func,
    resultId: PropTypes.string,
    trailingTags: PropTypes.shape({
        text: PropTypes.string.isRequired,
        color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']).isRequired,
    }),
    initialMinHeight: PropTypes.number,
    customPaddings: PropTypes.string,
};

function NewTextAreaBox(props) {
    const {
        id,
        state = 'default',
        size = 'lg',
        label,
        labelBadge,
        labelToCopyAlert, // if you don't pass label, but want to use copy button in copySuccessAlert labelToCopyAlert will be displayed
        badge,
        info = {},
        name,
        value,
        placeholder,
        errorMessage,
        errorMessageHighlightedStart,
        readOnlyWhileError = false,
        isRequired = false,
        onChange,
        indicator,
        statusIndicator,
        leftButton,
        rightButton,
        withCopyButton = false,
        withDeleteButton = false,
        withEditButton = false,
        withLinkIcon = false,
        withDownloadButton = false,
        downloadFileName,
        onDeleteButtonClick,
        onEditButtonClick,
        onLinkIconClick,
        fullHeight = false,
        simplified = false, // the textarea height will not decrease when the user deletes text, but it will increase when text is added.
        darkBg = false,
        lightBorder = false,
        isLoadingId = null,
        scrollInsideTextArea = false,
        fixedMinHeight,
        initialMinHeight = 86,
        leadingIcon,
        outputRating = null,
        updateOutputRating,
        resultId,
        trailingTags = [],
        customPaddings = 'px-3 py-[18px]',
        ...otherProps
    } = props;
    const textAreaRef = useRef(null);
    const [isTextareaFocused, setTextareaFocus] = useState(false);
    const [isCopySuccessAlertShown, setIsCopySuccessAlertShown] = useState(false);
    const [isCopyErrorAlertShown, setIsCopyErrorAlertShown] = useState(false);
    const titleLabelRef = useRef(null);
    const [minHeightOfTextArea, setMinHeightOfTextArea] = useState(`${initialMinHeight}px`);
    const [showTooltip, setShwTooltip] = useState(false);

    const { width } = useWindowSize();

    const getTooltipPosition = () => {
        if (info.state === 'default') {
            if (width < 420) {
                return 'bottom-right';
            } else if (width < 1300) {
                return 'bottom-center';
            } else return 'bottom-left';
        }
        if (width < 1100) {
            return 'top-center';
        } else return 'top-left';
    };

    const handleCopy = () => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                setIsCopySuccessAlertShown(true);
            })
            .catch(() => {
                setIsCopyErrorAlertShown(true);
            });
    };

    const handleDownload = () => {
        const element = document.createElement('a');
        const file = new Blob([value], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${downloadFileName || 'file'}.txt`;
        element.classList.add('disable-click-outside');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const handleTextareaFocus = () => {
        setTextareaFocus(true);
    };

    const handleTextareaBlur = () => {
        setTextareaFocus(false);
    };

    useEffect(() => {
        if (textAreaRef.current && !scrollInsideTextArea) {
            if (!simplified) {
                textAreaRef.current.style.height = `${initialMinHeight}px`;
            }

            const scrollHeight = textAreaRef.current.scrollHeight;

            textAreaRef.current.style.height = scrollHeight + 'px';
        }
    }, [textAreaRef, value, scrollInsideTextArea, width]);

    useEffect(() => {
        if (titleLabelRef.current && fullHeight) {
            let value = `calc(100% - ${titleLabelRef.current.offsetHeight + 20}px)`;
            if (fixedMinHeight) {
                setMinHeightOfTextArea(fixedMinHeight);
            } else {
                setMinHeightOfTextArea(value);
            }
            if (scrollInsideTextArea) {
                textAreaRef.current.style.height = value;
            }
        }
    }, [titleLabelRef, fullHeight, scrollInsideTextArea]);

    let textAreaClassName = classNames(
        'textAreaBox w-full scrollbar-width-[1px] min-w-[48px] rounded-2 shadow-inset-1 resize-none text-base text-neutral-500 font-normal leading-1.25 placeholder-neutral-300 focus:placeholder-transparent caret-neutral-500 focus:outline-0',
        {
            'shadow-red-500': state === 'error',
            'shadow-neutral-200': darkBg || lightBorder,
            'shadow-neutral-300': state !== 'error' && !darkBg && !lightBorder,
            'bg-neutral-50 disabled:text-neutral-500': darkBg,
            'bg-white disabled:text-neutral-300': !darkBg,
            'overflow-auto': scrollInsideTextArea,
        },
        customPaddings
    );

    const labelClassName = classNames('mb-[10px]', {
        'mb-[6px]': size === 'sm',
        'mb-[10px]': size !== 'sm',
        'flex items-center justify-between gap-[4px] flex-wrap':
            label || indicator || statusIndicator || leadingIcon || labelBadge,
        'flex items-center justify-end':
            !label && !indicator && !statusIndicator && !leadingIcon && !labelBadge,
    });
    const buttonsClassName = classNames('flex items-center', {
        'justify-between': leftButton && rightButton,
        'justify-end': !leftButton && rightButton,
        'justify-start': leftButton && !rightButton,
    });
    const statusIndicatorClassName = classNames('w-[7px] h-[7px] rounded-full', {
        'bg-lime-500': value && !isTextareaFocused,
        'bg-peach-500': isTextareaFocused,
        'bg-neutral-300': !value && !isTextareaFocused,
    });

    const containerGap = {
        sm: 'gap-[6px]',
        lg: 'gap-[10px]',
    };

    return (
        <div
            className={`flex flex-col gap-[10px] w-full min-w-50 bg-transparent rounded-2 flex-grow ${
                containerGap[size]
            } ${fullHeight && 'h-full'}`}
        >
            <div className="flex flex-col flex-grow">
                {(label || indicator || statusIndicator || leadingIcon || labelBadge) && (
                    <div className={labelClassName} ref={titleLabelRef}>
                        {(label || indicator || statusIndicator || leadingIcon || labelBadge) && (
                            <div className="flex items-center gap-[8px]">
                                {indicator && (
                                    <span className="font-body text-body-regular-xs">
                                        {indicator}
                                    </span>
                                )}
                                {leadingIcon && (
                                    <SvgIcon color="#1F2125" icon={leadingIcon} size="medium" />
                                )}
                                {labelBadge && (
                                    <Badge text={labelBadge.text} color={labelBadge.color} />
                                )}
                                {label && (
                                    <span className="flex items-center gap-1 max-sm:flex-wrap max-sm:items-start">
                                        <span
                                            className={`font-body text-body-bold-s  ${
                                                size === 'lg' && 'sm:text-body-bold-m'
                                            }`}
                                        >
                                            {label}
                                            {isRequired && (
                                                <sup className="text-red-500 font-small leading-1 font-medium ml-0.5">
                                                    *
                                                </sup>
                                            )}
                                        </span>
                                        {(badge || info.text) && (
                                            <span className="flex items-center gap-0.5">
                                                {badge && (
                                                    <Badge
                                                        leadingIcon={badge.leadingIcon}
                                                        leadingIconColor={badge.leadingIconColor}
                                                        text={badge.text}
                                                        color={badge.color || 'blue'}
                                                    />
                                                )}
                                                {info.text && (
                                                    <ToolTip
                                                        text={info.text}
                                                        position={getTooltipPosition()}
                                                        isShown={showTooltip}
                                                        shift="-8px"
                                                    >
                                                        <div
                                                            onMouseEnter={() => setShwTooltip(true)}
                                                            onMouseLeave={() =>
                                                                setShwTooltip(false)
                                                            }
                                                        >
                                                            <SvgIcon
                                                                icon={InformationLineIcon}
                                                                size="medium"
                                                                color={
                                                                    info.state === 'error'
                                                                        ? '#E95B69'
                                                                        : '#5E6470'
                                                                }
                                                            />
                                                        </div>
                                                    </ToolTip>
                                                )}
                                            </span>
                                        )}
                                    </span>
                                )}
                                {statusIndicator && (
                                    <div className={statusIndicatorClassName}></div>
                                )}
                            </div>
                        )}
                        <div className="flex flex-row items-center gap-1">
                            {!!trailingTags?.length &&
                                trailingTags.map((item, idx) => (
                                    <Badge key={idx} text={item.text} color={item.color} />
                                ))}
                            {outputRating !== null && outputRating !== undefined && (
                                <OutputRatingControls
                                    outputRating={outputRating}
                                    updateOutputRating={updateOutputRating}
                                    resultId={resultId}
                                    useExternalState
                                    showSuccessAlert
                                />
                            )}
                            {withDownloadButton && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state="default"
                                    icon={DownloadIcon}
                                    onClick={handleDownload}
                                />
                            )}
                            {withEditButton && isLoadingId === id && <Loading withText={false} />}
                            {withEditButton && isLoadingId !== id && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state={'default'}
                                    icon={EditIcon}
                                    onClick={() => onEditButtonClick(id)}
                                />
                            )}
                            {withCopyButton && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state={!value ? 'disabled' : 'default'}
                                    icon={FileCopyLineIcon}
                                    onClick={handleCopy}
                                />
                            )}
                            {withDeleteButton && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state={'default'}
                                    icon={DeleteBin4LineIcon}
                                    onClick={() => onDeleteButtonClick(id)}
                                />
                            )}
                            {withLinkIcon && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state={'default'}
                                    icon={LinkIcon}
                                    onClick={() => onLinkIconClick(id)}
                                />
                            )}
                        </div>
                    </div>
                )}
                <textarea
                    className={textAreaClassName}
                    ref={textAreaRef}
                    required={isRequired}
                    value={value}
                    disabled={state === 'disabled' || (state === 'error' && readOnlyWhileError)}
                    name={name}
                    rows={value ? 1 : 3}
                    placeholder={placeholder}
                    onChange={onChange}
                    onFocus={handleTextareaFocus}
                    onBlur={handleTextareaBlur}
                    {...otherProps}
                    style={{
                        minHeight: minHeightOfTextArea,
                    }}
                />
            </div>

            {state === 'error' && (errorMessage || errorMessageHighlightedStart) && (
                <div className="flex gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#E95B69" size="medium" />
                    <p className="font-body text-body-regular-xs text-red-500">
                        {errorMessageHighlightedStart && (
                            <span className="text-body-bold-xs">
                                {errorMessageHighlightedStart}:
                            </span>
                        )}{' '}
                        {errorMessage}
                    </p>
                </div>
            )}

            {(leftButton || rightButton) && (
                <div className={buttonsClassName}>
                    {leftButton && (
                        <div>
                            <Button
                                type="neutral"
                                size="sm"
                                text={leftButton.text}
                                onClick={leftButton.onClick || (() => {})}
                                isSubmit={leftButton.isSubmit || false}
                                leadingIcon={leftButton.leadingIcon}
                                trailingIcon={leftButton.trailingIcon}
                                helperIcon={leftButton.helperIcon}
                                state={state === 'disabled' ? 'disabled' : 'default'}
                            />
                        </div>
                    )}
                    {rightButton && (
                        <div>
                            <Button
                                type="primary"
                                size="sm"
                                text={rightButton.text}
                                onClick={rightButton.onClick || (() => {})}
                                isSubmit={rightButton.isSubmit || false}
                                leadingIcon={rightButton.leadingIcon}
                                trailingIcon={rightButton.trailingIcon}
                                helperIcon={rightButton.helperIcon}
                                state={state === 'disabled' ? 'disabled' : 'default'}
                            />
                        </div>
                    )}
                </div>
            )}
            {isCopySuccessAlertShown && (
                <Alert
                    status="positive"
                    message={`${labelToCopyAlert || label} copied to clipboard!`}
                    icon={CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setIsCopySuccessAlertShown(false)}
                />
            )}
            {isCopyErrorAlertShown && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setIsCopyErrorAlertShown(false)}
                />
            )}
        </div>
    );
}

export default NewTextAreaBox;
