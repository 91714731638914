import React from 'react';
import { JOB_TASK_STATUS_TO_BUCKET_MAPPING } from '../../../constants/jobs';

import MilestoneBucketStatusIcon from '../../../components/MilestoneBucketStatusIcon/MilestoneBucketStatusIcon';

const MilestoneCard = ({ milestone }) => {
    const { label, status, description } = milestone;

    const bucketStatus = JOB_TASK_STATUS_TO_BUCKET_MAPPING[status];

    return (
        <li className="p-4 flex flex-col gap-4 border-b-1 last:border-b-0 border-neutral-200">
            <div className="flex gap-2">
                <MilestoneBucketStatusIcon bucketStatus={bucketStatus} />
                <p className="font-body text-body-regular-m text-neutral-500">{label}</p>
            </div>

            {description && (
                <p className="font-body text-body-regular-xs text-neutral-300">{description}</p>
            )}
        </li>
    );
};

export default MilestoneCard;
