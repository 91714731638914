// copy task config to clipboard
export const copyTaskConfig = (task, setCopySuccessAlertShown, setCopyErrorAlertShown) => {
    const taskConfig = {
        order: null,
        // get task name in lowercase and replace all non-alphanumeric characters with underscore
        name: task.name.toLowerCase().replace(/[^a-z0-9]+/g, '_'),
        task_id: task.id,
        inputs: task.inputs.reduce((inputs, input) => {
            inputs[input.key] = '';
            return inputs;
        }, {}),
        outputs: task.outputs.reduce((outputs, output) => {
            outputs[output.key] = output.type;
            return outputs;
        }, {}),
    };
    // Format the JSON with a 4-space indent
    const prettyJson = JSON.stringify(taskConfig, null, 4);

    navigator.clipboard
        .writeText(prettyJson)
        .then(() => {
            if (setCopySuccessAlertShown) {
                setCopySuccessAlertShown(true);
            }
        })
        .catch(() => {
            if (setCopyErrorAlertShown) {
                setCopyErrorAlertShown(true);
            }
        });
};
