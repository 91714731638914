export const countMarkdownTextWords = (markdownText) => {
    if (!markdownText || typeof markdownText !== 'string') {
        return 0;
    }

    // remove Markdown syntax
    const cleanText = markdownText
        .replace(/^#{1,6}\s+/gm, '') // Headers
        .replace(/<\/?ins>/g, '') // Underline
        .replace(/^\s*[-+*]\s+/gm, '') // List items
        .replace(/-{3,}/g, '') // Horizontal lines
        .replace(/`|```/g, ''); // Code blocks

    const words = cleanText.split(/\s+/).filter(Boolean);
    return words.length;
};
