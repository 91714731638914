import { useEffect, useRef } from 'react';
import { useSwrRequestKeysToMutateFromContext } from './useSwrRequestKeysToMutateFromContext';
import { addUniqueElementToArray } from '../helpers/addUniqueElementToArray';

export const useSaveInContextRequestKeysWithMultipleAccessors = ({
    key,
    pageAccessor,
    allPageAccessors = [],
    nextPageKey,
}) => {
    const { requestKeysToMutate: requestKeysToMutateFromContext, setRequestKeysToMutate } =
        useSwrRequestKeysToMutateFromContext();

    const requestKeysToMutate = useRef(
        allPageAccessors.reduce(
            (acc, accessor) => ({
                ...acc,
                [accessor]: requestKeysToMutateFromContext[accessor] || [],
            }),
            {}
        )
    );

    const saveRequestKeysToMutateInContext = () => {
        setRequestKeysToMutate((prevData) => ({
            ...prevData,
            ...requestKeysToMutate.current,
        }));
    };

    useEffect(() => {
        if (key) {
            requestKeysToMutate.current[pageAccessor] = addUniqueElementToArray(
                key,
                requestKeysToMutate.current[pageAccessor] || []
            );
        }
    }, [key]);

    useEffect(() => {
        if (nextPageKey) {
            requestKeysToMutate.current[pageAccessor] = addUniqueElementToArray(
                nextPageKey,
                requestKeysToMutate.current[pageAccessor] || []
            );
        }
    }, [nextPageKey]);

    useEffect(() => {
        // In context, saved swr keys are only stored to persist them between pages, while current swr keys will be stored in requestKeysToMutate ref (to preserve performance and avoid updating the context every time a new key is added)
        // In the context, data will only be updated when the Page is unmounted, so that it can be used subsequently.
        // on component umount update saved swr keys in context
        return () => {
            saveRequestKeysToMutateInContext();
        };
    }, []);

    return requestKeysToMutate.current;
};
