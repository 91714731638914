import React from 'react';
import {
    PROVIDERS,
    PROVIDERS_LABEL,
    PROVIDERS_LOGO,
} from '../../../../constants/organizationConnections';
import { threadStatusBadge } from '../../../../constants/threads';
import { Badge } from '../../../../design-system';

const ApiContentPiece = ({ content }) => {
    const { label, data } = content;

    const connectionItems = Array.isArray(data) ? data : [data];

    return (
        <div>
            <p className="font-body-bold text-body-bold-s text-neutral-500 mb-1.5">{label}</p>
            <ul className="p-3 bg-neutral-50 rounded-2 flex flex-col gap-3">
                {connectionItems.map((item, index) => {
                    const { provider, status, request_method } = item;
                    const statusBadge = threadStatusBadge[status];

                    return (
                        <li
                            key={index}
                            className="bg-white px-3 sm:px-4 py-2.5 sm:py-3 rounded-2 flex flex-col min-[350px]:flex-row min-[350px]:items-center gap-1.5 sm:gap-2"
                        >
                            <div className="flex-grow flex items-center gap-1.5 sm:gap-2 truncate">
                                <div className="w-[40px] min-w-[40px] h-[40px] bg-neutral-50 rounded-2 flex items-center justify-center">
                                    <img
                                        src={PROVIDERS_LOGO[provider]}
                                        width={provider === PROVIDERS.airtable ? 40 : 24}
                                        height={provider === PROVIDERS.airtable ? 40 : 24}
                                        alt={`${provider} logo`}
                                        className="max-h-[40px]"
                                    />
                                </div>
                                <div className="flex-grow truncate">
                                    <p className="font-body text-body-bold-xs text-black mb-1 truncate">
                                        {PROVIDERS_LABEL[provider]} Connection
                                    </p>
                                    <p className="font-body text-body-bold-xs text-neutral-300 truncate">
                                        <span className="font-body-bold text-body-bold-xs">
                                            {request_method}
                                        </span>{' '}
                                        request
                                    </p>
                                </div>
                            </div>

                            <div>
                                <Badge text={statusBadge?.text} color={statusBadge?.color} />
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ApiContentPiece;
