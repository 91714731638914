import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { handleCopy } from '../../helpers/handleCopy';

import Alert from '../Alert/Alert';
import { ButtonIcon } from '../index';
import { FileCopyLineIcon } from '../Icons';

const FormFieldHeader = ({
    label,
    value,
    isRequired = false,
    withCopyButton = false,
    actionsBarLeftContent = null,
    actionsBarRightContent = null,
}) => {
    const [copyAlert, setCopyAlert] = useState(null);

    const headerClassName = classNames(
        'flex items-center gap-2',
        label ? 'justify-between' : 'justify-end'
    );

    const withActionsBar = withCopyButton || actionsBarLeftContent || actionsBarRightContent;

    return (
        <>
            {(label || withActionsBar) && (
                <div className={headerClassName}>
                    {label && (
                        <div className="font-body-bold text-body-bold-s text-neutral-500">
                            {label}
                            {isRequired && <sup className="text-red-500 leading-1 ml-0.5">*</sup>}
                        </div>
                    )}

                    {withActionsBar && (
                        <div className="flex items-center gap-1">
                            {actionsBarLeftContent}
                            {withCopyButton && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state={!value ? 'disabled' : 'default'}
                                    icon={FileCopyLineIcon}
                                    onClick={() => handleCopy(value, setCopyAlert)}
                                />
                            )}
                            {actionsBarRightContent}
                        </div>
                    )}
                </div>
            )}

            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </>
    );
};

FormFieldHeader.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    isRequired: PropTypes.bool,
    withCopyButton: PropTypes.bool,
    actionsBarLeftContent: PropTypes.node,
    actionsBarRightContent: PropTypes.node,
};

export default FormFieldHeader;
