import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, ButtonIcon } from '../../../../design-system';
import { ArrowGoBackLineIcon, More2FillIcon } from '../../../../design-system/Icons';
import JobMoreOptionsPopup from '../JobMoreOptionsPopup/JobMoreOptionsPopup';
import DetailBar from '../DetailBar/DetailBar';

const JobDetailPageHeader = ({ backLinkHref, jobDetail, setActionModal }) => {
    const { name, process } = jobDetail;

    const navigate = useNavigate();
    const location = useLocation();

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);
    const [isJobDetailBarExpanded, setIsJobDetailBarExpanded] = useState(false);

    return (
        <div className="relative py-5 px-4 xs:px-5 sm:p-8 lg:py-9 lg:px-[40px] xl:py-[40px] xl:px-[60px] flex flex-col gap-4 sm:gap-5 lg:gap-6 z-10">
            <div
                className="absolute inset-0 z-[-1]"
                style={{ backgroundColor: process.icon_color, opacity: 0.2 }}
            ></div>
            <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-5">
                    <Button
                        type="link"
                        size="xs"
                        text="Back"
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate(backLinkHref, { state: { from: location } })}
                    />
                    <p className="font-body text-body-regular-s text-neutral-300">Jobs /</p>
                </div>
                <div className="relative">
                    <ButtonIcon
                        type="link"
                        size="sm"
                        icon={More2FillIcon}
                        onClick={() => setIsMoreOptionsPopupOpened(true)}
                    />
                    {isMoreOptionsPopupOpened && (
                        <JobMoreOptionsPopup
                            setActionModal={setActionModal}
                            onClose={() => setIsMoreOptionsPopupOpened(false)}
                            process={process}
                        />
                    )}
                </div>
            </div>
            <h1 className="font-heading-regular text-heading-regular-m sm:text-[28px] lg:text-heading-regular-l text-black">
                {name}
            </h1>
            <DetailBar
                type="job"
                detailData={jobDetail}
                isExpanded={isJobDetailBarExpanded}
                setIsExpanded={setIsJobDetailBarExpanded}
            />
        </div>
    );
};

export default JobDetailPageHeader;
