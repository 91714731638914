import useSWR from 'swr';

/**
 * @param {number} pageIndex - The current page of data to fetch
 * @param {function} client - The api client to use to fetch the data from eg: src/services/library-api.js
 * @param {string} route - The route to fetch the data from eg: /prompt/
 * @returns {{
 *  sortedData: Array,
 *  count: number,
 *  loading: boolean
 * }} - An object containing the sortedData, count, and loading state
 *
 * This hook uses swr https://swr.vercel.app/docs/pagination
 * Swr is a data fetching and caching library for react to
 * that can help to ensure data dispalyed to users is accurate and up to date
 *
 * It uses local storage and stores most recent data in the browsers's local storage
 * There is a heartbeat with the server that checks for updates and updates the local storage
 * IF the cache is invalidated then the component will rerender with the update data
 *
 * SWR is configurable and we can fine tune how often data is fetched on a per route basis
 * - https://swr.vercel.app/docs/global-configuration
 * - https://swr.vercel.app/docs/revalidation#revalidate-on-interval
 *
 *
 * TODO: Implement a mechanism to prevent fetching when api has returned all data
 *      That may be implmented in a componen that uses this hook or in the hook itself
 */

export const DEFAUlT_LIMIT = 12;
function getKey({ pageIndex, sortType, route }) {
    const offset = pageIndex === 0 ? 0 : DEFAUlT_LIMIT * pageIndex;
    return `${route}?limit=${DEFAUlT_LIMIT}&offset=${offset}&ordering=${sortType}`;
}

export default function usePagination({ pageIndex, sortType, client, route }) {
    const authToken = localStorage.getItem('authKey');
    if (!authToken || !authToken.length) {
        throw new Error('User not logged in.'); // TODO: handle more gracefully
    }
    const options = {
        headers: {
            Authorization: `Token ${authToken}`,
        },
    };
    const fetcher = (url) => client.get(url, options).then((res) => res.data);

    const key = getKey({ pageIndex, sortType, route });
    const { data: response, error, isLoading, isValidating } = useSWR(key, fetcher);
    if (!response || !response.results) {
        return { sortedData: null, count: 0, loading: true };
    }
    const sortedData = response.results;
    const total = response.count;
    const count = sortedData.length;
    return { sortedData, total, count, loading: false, key };
}
