import React, { memo } from 'react';
import classNames from 'classnames';

import ToolIcon from '../../Icons/ToolIcon';
import ArrowGoForwardLineIcon from '../../Icons/ArrowGoForwardLineIcon';
import ArrowGoBackLineIcon from '../../Icons/ArrowGoBackLineIcon';

const JsonEditorDisabledState = ({ value, placeholder = null, autoExpand = true }) => {
    const jsonValue = typeof value === 'string' ? value : JSON.stringify(value, null, 4);

    const containerClassName = classNames(
        'rounded-2 border border-neutral-200 flex flex-col min-h-full',
        {
            'flex-grow h-[100px]': !autoExpand,
            'h-full': autoExpand,
        }
    );

    const isPlaceholderDisplayed = !!placeholder && !value;

    const jsonContainerClassName = classNames(
        'whitespace-pre-wrap break-words p-3 flex-grow font-body leading-5 bg-neutral-50 rounded-b-2',
        {
            'text-body-regular-s text-neutral-500': !isPlaceholderDisplayed,
            'text-body-regular-m text-neutral-300': isPlaceholderDisplayed,
            'overflow-y-auto': !autoExpand,
        }
    );

    return (
        <div className={containerClassName}>
            <div className="flex items-center gap-[17px] h-[35px] min-h-[35px] px-2.5 border-b-1 border-neutral-200">
                <ToolIcon width={17} height={17} color="#5E6470" />
                <ArrowGoBackLineIcon width={17} height={17} color="#5E6470" />
                <ArrowGoForwardLineIcon width={17} height={17} color="#5E6470" />
            </div>

            <pre className={jsonContainerClassName}>
                {isPlaceholderDisplayed ? placeholder : jsonValue}
            </pre>
        </div>
    );
};

export default memo(JsonEditorDisabledState);
