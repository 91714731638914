import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import client from '../../../services/library-api';
import * as API from '../../../constants/api';

import { SORT_TYPES } from '../../../constants/sort';
import { emptyFieldErrorMessage } from '../../../constants/errorMessages';

import { useFormState } from '../../../hooks/useFormState';
import { mutateAllCashedRequest } from '../../../helpers/swrMutateUtils';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import Alert from '../../../design-system/Alert/Alert';
import Modal from '../../../design-system/Modal/Modal';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';
import { Button, Input } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const CreateScenarioModal = ({ requestKeysToMutate, onClose }) => {
    const navigate = useNavigate();

    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        {
            name: '',
            playbook: null,
        }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const formatResponseToOptions = (results) => results?.map(({ id, name }) => ({ id, name }));

    const {
        options: playbookOptions,
        optionsLoading,
        totalOptions,
        setPage,
        canLoadMoreOptions,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.playbook,
        searchParams: { ordering: SORT_TYPES.alphabetical },
        formatResponseToOptions,
    });

    const handleCreate = async () => {
        if (!formData.name || !formData.playbook) {
            setFieldErrorMessages({
                name: !formData.name ? emptyFieldErrorMessage : null,
                playbook: !formData.playbook ? 'Please select a playbook' : null,
            });
            return;
        }

        const requestBody = { ...formData, description: `Scenario for ${formData.name}` };

        try {
            setIsLoading(true);
            const { data } = await client.post(API.ROUTES.library.scenario, requestBody);
            mutateAllCashedRequest({ requestKeysToMutate });

            navigate(`/configure/scenario/${data.id}`);
        } catch (e) {
            setIsLoading(false);
        }
    };

    const increaseSelectHeightBy =
        totalOptions > 5 ? 95 : totalOptions > 1 || optionsLoading ? 60 : 0;

    return (
        <Modal onClose={onClose} size="medium" resetPadding>
            <ModalHeader onClose={onClose} title="Create Scenario" outlined />
            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-5 md:gap-6">
                <Input
                    size="md"
                    name="name"
                    value={formData.name}
                    label="Scenario Name"
                    isRequired
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    placeholder="Name your scenario"
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                />

                <PaginatedSelect
                    size="md"
                    name="playbook"
                    options={playbookOptions}
                    optionsLoading={optionsLoading}
                    value={formData.playbook}
                    label="Attach to Playbook"
                    isRequired
                    placeholder="Select a Playbook"
                    onChange={(id) => handleInputChange('playbook', id)}
                    state={fieldErrorMessages.playbook ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.playbook}
                    fetchOptions={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMoreOptions}
                    dropdownHeight={totalOptions > 5 ? 176 : 142}
                    includeClientSideFiltering
                    totalOptionsCount={totalOptions}
                    increaseComponentHeightBy={increaseSelectHeightBy}
                />

                <div className="flex items-center gap-3 justify-between">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="md"
                        state={isLoading ? 'loading' : 'default'}
                        text="Create"
                        onClick={handleCreate}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default CreateScenarioModal;
