import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import { defaultErrorMessage } from '../../../../constants/errorMessages';
import { mutateDataAfterDeletingItem } from '../../../../helpers/swrMutateUtils';

import ConfirmDeletionModalPattern from '../../../../design-system/ConfirmDeletionModalPattern/ConfirmDeletionModalPattern';

const ConfirmEntryDeletionModal = ({ entry, requestKeysToMutate, onClose }) => {
    const { id, label, base } = entry;

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleDelete = async () => {
        try {
            setIsLoading(true);

            await client.delete(`${API.ROUTES.knowledge.base}${base}/entry/${id}/`);
            mutateDataAfterDeletingItem({ id, requestKeysToMutate });

            onClose();
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
        }
    };

    return (
        <ConfirmDeletionModalPattern
            variant="secondary"
            title="Delete Entry?"
            onClose={onClose}
            confirmationTextStart="You are about to delete"
            confirmationTextEnd=", are you sure you’d like to continue? This entry will no longer be available to edit or retrieve."
            entityName={label}
            handleDelete={handleDelete}
            isLoading={isLoading}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
            buttonsTheme="colored"
        />
    );
};

export default ConfirmEntryDeletionModal;
