import React from 'react';

const ContractLeftLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <path
                d="M15.0708 4.92871L16.485 6.34293L11.8282 10.9998L21 10.9999V12.9999L11.8282 12.9998L16.485 17.6566L15.0708 19.0708L7.99979 11.9998L15.0708 4.92871ZM4 18.9997V4.99973H6V18.9997H4Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ContractLeftLineIcon;
