import React, { useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useUser from '../../../hooks/useUser';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { Button, ButtonIcon, Dropdown, Tabs } from 'design-system';
import { AddCircleLineIcon, ArrowDownSLineIcon, ArrowUpSLineIcon } from 'design-system/Icons';
import { LIBRARY_SORT_TYPES, LIBRARY_VIEW_TYPE_LABELS } from 'constants/library';
import {
    filterItems,
    LIBRARY_FILTER_INDEX_LOOKUP,
    LIBRARY_SORT_INDEX_LOOKUP,
    sortItems,
} from '../../../constants/libraryDropdownsData';
import GridIcon from '../../../design-system/Icons/GridIcon';
import ListIcon from '../../../design-system/Icons/ListIcon';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../../constants/organization';
import { parseLibrarySearchParams } from '../../../services/library-utils';
import { useClickOutside } from '../../../hooks';

const tabs = [
    { name: LIBRARY_VIEW_TYPE_LABELS.personal },
    { name: LIBRARY_VIEW_TYPE_LABELS.shared },
];

function LibraryTopControls({ onTabChanged, tabIndex, libraryView, setLibraryView }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = parseLibrarySearchParams(searchParams);

    const sortDropdownIndex = sortItems.findIndex((item) => item.value === parsedParams.sort) || 0;
    const filterDropdownIndex = filterItems.findIndex(
        (item) => item.value === parsedParams.task_type
    );

    const sortDropdownRef = useRef(null);
    const filterDropdownRef = useRef(null);
    const [showSortDropdown, setShowSortDropdown] = useState(false);
    const [showFilterDropdown, setShowFilterDropdown] = useState(false);

    const { orgPlan, userRole } = useUser();
    const { width: screenWidth } = useWindowSize();

    const viewOnlyMode = userRole === USER_ROLE.member;

    useClickOutside(
        sortDropdownRef,
        // track clicks outside the sort dropdown only when it is open
        showSortDropdown
            ? (e) => {
                  e?.stopPropagation();
                  setShowSortDropdown(false);
              }
            : // do nothing when the sort dropdown is not open
              () => {}
    );

    useClickOutside(
        filterDropdownRef,
        // track clicks outside the filter dropdown only when it is open
        showFilterDropdown
            ? (e) => {
                  e?.stopPropagation();
                  setShowFilterDropdown(false);
              }
            : // do nothing when the filter dropdown is not open
              () => {}
    );

    const onSortItemSelect = (index) => {
        const sort = LIBRARY_SORT_INDEX_LOOKUP[index] || LIBRARY_SORT_TYPES.alphabetical;
        if (parsedParams.sort !== sort) {
            setSearchParams({ ...parsedParams, sort });
        }
        setShowSortDropdown(false);
    };

    const onFilterItemSelect = (index) => {
        const task_type = LIBRARY_FILTER_INDEX_LOOKUP[index];
        if (!task_type) {
            return;
        }
        if (parsedParams.task_type === task_type) {
            // deselect the selected task type (remove task_type filter)
            const { task_type, ...restParams } = parsedParams;
            setSearchParams({ ...restParams });
        }
        if (parsedParams.task_type !== task_type) {
            // set task type filter
            setSearchParams({ ...parsedParams, task_type, page: 1 });
        }
        setShowFilterDropdown(false);
    };

    const handleNav = (path) => {
        navigate(path, {
            state: { libraryLocation: location },
        });
    };

    const toggleLibraryView = () => {
        setLibraryView((prevView) => (prevView === 'grid' ? 'list' : 'grid'));
    };

    return (
        <div className="flex flex-wrap justify-between items-center self-stretch gap-x-4 gap-y-2">
            <div className="flex items-center sm:gap-4 gap-2 max-sm:relative">
                <Tabs onTabChanged={onTabChanged} tabIndex={tabIndex} tabs={tabs} />
                <div className="flex items-center gap-0.5 sm:gap-2">
                    {orgPlan === ORGANIZATION_PLAN.agency && !viewOnlyMode && (
                        <div className="sm:relative" ref={filterDropdownRef}>
                            <Button
                                type="link"
                                size="xs"
                                text="Filter"
                                trailingIcon={
                                    showFilterDropdown ? ArrowUpSLineIcon : ArrowDownSLineIcon
                                }
                                onClick={() => setShowFilterDropdown((filter) => !filter)}
                            />
                            <div className="absolute max-sm:left-0 max-sm:bottom-0 z-56">
                                <Dropdown
                                    showDropdown={showFilterDropdown}
                                    onItemSelect={onFilterItemSelect}
                                    items={filterItems}
                                    isDropdownControlled={true}
                                    selectedOptionIndex={filterDropdownIndex}
                                    addLeadingSelectIcon
                                    showOptionsAsBadge
                                    allowDeselect
                                />
                            </div>
                        </div>
                    )}
                    <div className="sm:relative" ref={sortDropdownRef}>
                        <Button
                            type="link"
                            size="xs"
                            text="Sort"
                            trailingIcon={showSortDropdown ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                            onClick={() => setShowSortDropdown((sort) => !sort)}
                        />
                        <div className="absolute max-sm:left-0 max-sm:bottom-0 sm:max-ml:left-[-135px] z-56">
                            <Dropdown
                                showDropdown={showSortDropdown}
                                onItemSelect={onSortItemSelect}
                                items={sortItems}
                                isDropdownControlled={true}
                                selectedOptionIndex={sortDropdownIndex}
                                addLeadingSelectIcon
                            />
                        </div>
                    </div>
                </div>

                {tabIndex === 0 && screenWidth >= 768 && (
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={libraryView === 'list' ? GridIcon : ListIcon}
                        onClick={toggleLibraryView}
                    />
                )}
            </div>
            <div className="flex items-center gap-2 sm:gap-4">
                <div style={{ display: 'none' }}>
                    <Button text="Browse Templates" />
                </div>
                {orgPlan === ORGANIZATION_PLAN.agency && !viewOnlyMode && (
                    <Button
                        leadingIcon={AddCircleLineIcon}
                        onClick={() => handleNav('/library/task/new')}
                        text="New Task"
                        type="secondary"
                        size="sm"
                    />
                )}
                {!viewOnlyMode && (
                    <Button
                        leadingIcon={AddCircleLineIcon}
                        onClick={() => handleNav('/library/prompt/new')}
                        text={`New Prompt`}
                        type="primary"
                        size="sm"
                    />
                )}
            </div>
        </div>
    );
}

LibraryTopControls.propTypes = {
    onTabChanged: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
    libraryView: PropTypes.oneOf(['list', 'grid']),
    setLibraryView: PropTypes.func,
};

export default LibraryTopControls;
