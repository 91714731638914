import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useClickOutside } from '../../hooks';
import { useClientSideFilterBar } from '../../hooks/useClientSideFilterBar';

import ClientSideFilteredSearchBar from '../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import InfiniteScrollList from '../../components/InfiniteScrollList/InfiniteScrollList';
import { SvgIcon } from '../index';

// the mobile view can be updated
const ExtendedPaginatedDropdown = ({
    options,
    selectedOptionId,
    optionsLoading,
    totalOptions,
    canLoadMoreOptions,
    setOptionsNextPage,
    onOptionSelect,
    listLabel,
    optionsLeadingIcon,
    optionsLeadingIconColor,
    onCancel,
    onClose,
}) => {
    const dropdownRef = useRef(null);

    const showClientSideFilterBar = totalOptions >= 6;

    const { filteredOptions, setFilteredOptions } = useClientSideFilterBar({
        options,
        showClientSideFilterBar,
    });

    useClickOutside(dropdownRef, onClose);

    const handleOptionClick = (event, option) => {
        onOptionSelect(option);
        onClose(event);
    };

    const handleCancelClick = (event) => {
        onCancel();
        onClose(event);
    };

    const listClassName = classNames('flex-1 overflow-y-auto px-4 min-h-[40px]', {
        'pb-3': !!filteredOptions?.length,
        'min-h-[40px]': optionsLoading,
    });

    return (
        <div className="absolute top-[110%] left-0 right-0 z-50 pb-5">
            <div
                className="flex flex-col gap-5 bg-white pt-4 rounded-2 border-1 border-neutral-200 shadow-l1 max-h-[260px] overflow-y-auto"
                ref={dropdownRef}
            >
                <div className="flex flex-col gap-5 px-4">
                    <div className="flex items-center gap-3 l:gap-5">
                        <ClientSideFilteredSearchBar
                            setFilteredList={setFilteredOptions}
                            list={options}
                            withClearIcon
                            size="md"
                        />
                        <button
                            type="button"
                            className="font-body text-body-regular-m text-neutral-500 underline"
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </button>
                    </div>

                    <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase">
                        {listLabel}
                    </p>
                </div>

                <div className={listClassName}>
                    <InfiniteScrollList
                        handleFetch={() => setOptionsNextPage((page) => page + 1)}
                        canLoadMore={canLoadMoreOptions}
                        items={filteredOptions}
                        loading={optionsLoading}
                        gap={0}
                    >
                        {filteredOptions.map((option) => {
                            const { id, name, leadingIconColor } = option;
                            const isSelected = id === selectedOptionId;

                            return (
                                <div
                                    key={id}
                                    className={classNames(
                                        'px-3 py-2 w-full max-w-full flex items-center gap-1 truncate rounded-[6px] hover:bg-neutral-50 cursor-pointer min-h-[37px]',
                                        { 'bg-white': !isSelected, 'bg-neutral-50': isSelected }
                                    )}
                                    onClick={(event) => handleOptionClick(event, option)}
                                >
                                    <SvgIcon
                                        size="medium"
                                        icon={optionsLeadingIcon}
                                        color={optionsLeadingIconColor || leadingIconColor}
                                    />
                                    <p className="flex-1 font-body-bold text-body-bold-s text-[#25324B] truncate">
                                        {name}
                                    </p>
                                </div>
                            );
                        })}
                    </InfiniteScrollList>
                </div>
            </div>
        </div>
    );
};

ExtendedPaginatedDropdown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            leadingIconColor: PropTypes.string,
        })
    ).isRequired,
    selectedOptionId: PropTypes.string.isRequired,
    optionsLoading: PropTypes.bool.isRequired,
    totalOptions: PropTypes.number.isRequired,
    canLoadMoreOptions: PropTypes.bool.isRequired,
    setOptionsNextPage: PropTypes.func.isRequired,
    onOptionSelect: PropTypes.func.isRequired,
    listLabel: PropTypes.string.isRequired,
    optionsLeadingIcon: PropTypes.func,
    optionsLeadingIconColor: PropTypes.string,
    onCancel: PropTypes.string.isRequired,
    onClose: PropTypes.string.isRequired,
};

export default ExtendedPaginatedDropdown;
