import React, { memo } from 'react';

import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';
import { CloseLineIcon, InformationLineIcon } from '../../../design-system/Icons';
import { ButtonIcon, SvgIcon } from '../../../design-system';

const DocMappingItem = ({
    variableKey,
    mappedDocId,
    handleMappingChange,
    docsOptionsHookResponse,
    isVariableMissedInValue,
}) => {
    const { options, totalOptions, canLoadMoreOptions, setPage, optionsLoading } =
        docsOptionsHookResponse;

    const handleSelectDoc = (doc) => {
        handleMappingChange((prevMapping) => ({ ...prevMapping, [variableKey]: doc }));
    };

    const handleRemoveMapping = () => {
        handleMappingChange((prevMapping) => {
            const { [variableKey]: _, ...newMapping } = prevMapping;
            return newMapping;
        });
    };

    return (
        <li className="w-full">
            <div
                className="grid grid-rows-[auto_auto] md:grid-rows-1 gap-x-4 gap-y-2 items-center"
                style={{ gridTemplateColumns: '16px minmax(100px, 2fr) minmax(0, 50%) 28px' }}
            >
                {isVariableMissedInValue ? (
                    <SvgIcon color="#E95B69" icon={InformationLineIcon} size="medium" />
                ) : (
                    <SvgIcon color="#754DCF" icon={FileTextLineIcon} size="medium" />
                )}

                <p className="col-start-2 col-end-4 md:col-auto font-body text-body-regular-s text-neutral-500">
                    Select what to fill in{' '}
                    <span className="font-body-bold text-body-bold-s">[{variableKey}]</span>
                </p>

                <div className="col-span-full row-start-2 row-end-2 md:col-auto md:row-auto">
                    <PaginatedSelect
                        size="xs"
                        name="doc"
                        options={options}
                        optionsLoading={optionsLoading}
                        value={mappedDocId}
                        placeholder="Select a Document"
                        onChange={handleSelectDoc}
                        fetchOptions={() => setPage((page) => page + 1)}
                        canLoadMore={canLoadMoreOptions}
                        includeClientSideFiltering
                        totalOptionsCount={totalOptions}
                        dropdownHeight={228}
                    />
                </div>

                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={CloseLineIcon}
                    onClick={handleRemoveMapping}
                />
            </div>
        </li>
    );
};

export default memo(DocMappingItem);
