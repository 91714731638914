import React, { useState } from 'react';

import SvgIcon from '../../design-system/SvgIcon/SvgIcon';
import LockIcon from '../../design-system/Icons/LockIcon';
import UnlockIcon from '../../design-system/Icons/UnlockIcon';
import ToolTip from '../../design-system/ToolTip/ToolTip';
import ProtectedModeConfirmUnlockModal from '../ProtectedModeConfirmUnlockModal/ProtectedModeConfirmUnlockModal';

const ProtectedModeBadge = ({ taskId, protectedMode, setProtectedMode }) => {
    const [showProtectedModeTooltip, setShowProtectedModeTooltip] = useState(false);
    const [confirmUnlockModalOpened, setConfirmUnlockModalOpened] = useState(false);

    const toggleProtectedMode = (e) => {
        e?.stopPropagation();

        if (protectedMode) {
            setConfirmUnlockModalOpened(true);
        }
        if (!protectedMode) {
            setProtectedMode(true);
        }
    };

    return (
        <>
            <ToolTip
                text={protectedMode ? 'Click to unlock' : 'Click to lock'}
                position="top-right"
                isShown={showProtectedModeTooltip}
                shift={protectedMode ? '62px' : '83px'}
            >
                <div
                    className="flex items-center gap-1 px-2.5 py-1 cursor-pointer"
                    onMouseEnter={() => setShowProtectedModeTooltip(true)}
                    onMouseLeave={() => setShowProtectedModeTooltip(false)}
                    onClick={toggleProtectedMode}
                >
                    <SvgIcon
                        color="#000000"
                        icon={protectedMode ? LockIcon : UnlockIcon}
                        size="medium"
                    />
                    <span className="font-body text-body-regular-xs text-neutral-500 select-none">
                        {protectedMode ? 'Protected' : 'Un-Protected'}
                    </span>
                </div>
            </ToolTip>
            {confirmUnlockModalOpened && (
                <ProtectedModeConfirmUnlockModal
                    taskId={taskId}
                    setProtectedMode={setProtectedMode}
                    onClose={() => setConfirmUnlockModalOpened(false)}
                />
            )}
        </>
    );
};

export default ProtectedModeBadge;
