import { TASK_TYPES } from '../constants/library';
import {
    checkIfInputMissedInMessagesArray,
    getInputInitialValue,
    MISSED_INPUTS_CHECKING_FUNC,
} from './taskPlaygroundUtils';
import { TASK_TYPES_WITH_MISSED_INPUTS_WARNING } from '../constants/taskPlayground';

export const determineRequiredInputs = (func_def = {}, inputs, setIsExpanded) => {
    const { func_data, func_mapping } = func_def;
    const requiredInputs = func_data?.required_inputs || [];
    const result = requiredInputs.reduce((acc, key) => {
        const id = func_mapping[key]['id'];
        const correspondedInput = inputs.find((input) => input.id === id);
        return correspondedInput
            ? [...acc, { ...correspondedInput, state: 'default', value: '' }]
            : acc;
    }, []);
    setIsExpanded((prevData) => ({ ...prevData, requiredInputs: !!result.length }));
    return result;
};

export const determineUserInputs = (
    func_def = {},
    inputs,
    setIsExpanded,
    taskType,
    default_prompt
) => {
    const { func_data, func_mapping } = func_def;

    const userMessage = default_prompt?.default_prompt_version?.messages?.[1]?.content;
    const systemMessage = default_prompt?.default_prompt_version?.messages?.[0]?.content;
    const apiRequest = [
        func_data?.request_url,
        func_data?.request_headers,
        func_data?.request_data,
    ];
    const code = func_data.code || '';
    const definition = func_data.definition || '';

    const userInputs = func_data?.user_inputs || [];
    return userInputs.map((userInput) => {
        setIsExpanded((prevData) => ({ ...prevData, [userInput.key]: true }));
        const correspondedInputsArray = func_mapping[userInput.key] || [];
        const result = correspondedInputsArray.reduce((acc, item) => {
            const correspondedInput = inputs.find((input) => input.id === item.id);

            if (!correspondedInput) {
                return acc;
            }

            const initialValue = getInputInitialValue(correspondedInput);

            let formattedInput = {
                ...correspondedInput,
                state: 'default',
                value: initialValue,
            };

            if (TASK_TYPES_WITH_MISSED_INPUTS_WARNING.includes(taskType)) {
                const checkingDataArray = {
                    [TASK_TYPES.assistant]: [userMessage],
                    [TASK_TYPES.function]: [userMessage, systemMessage],
                    [TASK_TYPES.code]: [code],
                    [TASK_TYPES.plot]: [definition],
                    [TASK_TYPES.api]: apiRequest,
                };
                const dataArray = checkingDataArray[taskType];

                formattedInput = checkWarningForMissingInput({
                    dataArray,
                    taskType,
                    input: formattedInput,
                });
            }

            return [...acc, formattedInput];
        }, []);
        return { ...userInput, inputs: result };
    }, []);
};

export function checkWarningForMissingInput({ dataArray, taskType, input }) {
    const func = MISSED_INPUTS_CHECKING_FUNC[taskType] || checkIfInputMissedInMessagesArray;
    const isInputKeyMissed = func(dataArray, input.key);
    return { ...input, isUnused: isInputKeyMissed };
}

const mergeExistingValuesIntoNewInputs = ({
    newInputs,
    existingInputs = [],
    taskType,
    dataArray,
    shouldCheckMissedInputs = false,
}) => {
    // Create a map of existing inputs for faster lookup
    const existingInputMap = new Map(existingInputs.map((input) => [input.id, input]));

    // Merge new inputs into existing inputs
    const updatedInputs = newInputs.map((newInput) => {
        const existingInput = existingInputMap.get(newInput.id);

        if (existingInput) {
            // If the input with the same id exists, merge the new data into it
            return {
                ...existingInput,
                ...newInput,
                value: existingInput.value,
                state: 'default',
            };
        } else {
            // If it doesn't exist in the existing inputs, add it to the result
            let result = {
                ...newInput,
                state: 'default',
                value: newInput.value || getInputInitialValue(newInput),
            };

            if (
                shouldCheckMissedInputs &&
                TASK_TYPES_WITH_MISSED_INPUTS_WARNING.includes(taskType)
            ) {
                result = checkWarningForMissingInput({
                    dataArray,
                    taskType,
                    input: result,
                });
            }

            return result;
        }
    });

    return updatedInputs;
};

export const updateSpecificUserInputs = ({
    userInputKey,
    func_def = {},
    inputsFromResponse,
    existingInputs,
    shouldCheckMissedInputs = false,
    taskType,
    messagesArray = [],
    apiRequest,
    code,
    definition,
}) => {
    const { func_mapping } = func_def;
    const correspondedInputsArray = func_mapping[userInputKey];
    const updatedInputsArray = correspondedInputsArray.reduce((acc, item) => {
        const correspondedInput = inputsFromResponse.find((input) => input.id === item.id);
        return correspondedInput ? [...acc, correspondedInput] : acc;
    }, []);

    const checkingDataArray = {
        [TASK_TYPES.assistant]: messagesArray,
        [TASK_TYPES.function]: messagesArray,
        [TASK_TYPES.code]: [code],
        [TASK_TYPES.plot]: [definition],
        [TASK_TYPES.api]: apiRequest,
    };
    const dataArray = checkingDataArray[taskType];

    return mergeExistingValuesIntoNewInputs({
        newInputs: updatedInputsArray,
        existingInputs,
        taskType,
        dataArray,
        shouldCheckMissedInputs,
    });
};

export const updateRequiredInputs = (func_def = {}, inputs, existingRequiredInputs = []) => {
    // get required inputs from response and merge them into existing inputs (if existing inputs have values they will stay)
    const requiredInputsFromResponse = determineRequiredInputs(func_def, inputs, () => {});
    return mergeExistingValuesIntoNewInputs({
        newInputs: requiredInputsFromResponse,
        existingInputs: existingRequiredInputs,
    });
};

export const fillNewValuesIntoExistingRequiredInputs = (existingInputs, newInputs) => {
    // Create a map of new inputs for faster lookup
    const newInputsMap = new Map(newInputs.map((input) => [input.id, input]));

    // Merge new inputs value into existing inputs
    const updatedInputs = existingInputs?.map((input) => {
        const newInput = newInputsMap.get(input.id);

        if (newInput) {
            // If the input with the same id exists, merge the new value into it
            return {
                ...input,
                value: newInput.value,
            };
        } else {
            // If it doesn't exist return the current input
            return input;
        }
    });

    return updatedInputs;
};

export const fillNewValuesIntoExistingUserInputs = (existingInputsData, newInputs) => {
    // Create a map of new inputs for faster lookup
    const newInputsMap = new Map(newInputs.map((input) => [input.id, input]));

    return existingInputsData.map((userInput) => {
        // Merge new inputs value into existing inputs
        const updatedInputs = userInput?.inputs?.map((input) => {
            const newInput = newInputsMap.get(input.id);

            if (newInput) {
                // If the input with the same id exists, merge the new value into it
                return {
                    ...input,
                    value: newInput.value,
                };
            } else {
                // If it doesn't exist return the current input
                return input;
            }
        });

        return { ...userInput, inputs: updatedInputs };
    });
};

export const mergeExistingValuesIntoNewOutputs = ({
    existingOutputs,
    newOutputs,
    addValueFromExistingOutputs = false,
}) => {
    const existingOutputsMap = new Map(existingOutputs.map((output) => [output.id, output]));

    const updatedOutputs = newOutputs.map((newOutput) => {
        const existingOutput = existingOutputsMap.get(newOutput.id);

        if (existingOutput) {
            const res = { ...existingOutput, ...newOutput };
            if (addValueFromExistingOutputs) {
                res.value = existingOutput.value;
            }
            return res;
        } else {
            return newOutput;
        }
    });

    return updatedOutputs;
};
