import { API } from 'constants';

import FolderFillIcon from '../design-system/Icons/FolderFillIcon';
import FileTextLineIcon from '../design-system/Icons/FileTextLineIcon';

export const EMPTY_DOCUMENT_DATA = {
    label: 'Untitled Document',
    key: 'untitled_document',
};

export const ARCHIVED_FOLDER_OR_DOC_DATA_LS_KEY = 'archived-folder-or-doc-data';
export const SENT_FOLDER_OR_DOC_DATA_LS_KEY = 'sent-folder-or-doc-data';

export const CONTENT_TYPE = {
    folder: 'folder',
    document: 'document',
};

export const ROUTE = {
    [CONTENT_TYPE.folder]: API.ROUTES.knowledge.folder,
    [CONTENT_TYPE.document]: API.ROUTES.knowledge.document,
};

export const CONTENT_TYPE_ICON = {
    [CONTENT_TYPE.folder]: FolderFillIcon,
    [CONTENT_TYPE.document]: FileTextLineIcon,
};

export const CONTENT_TYPE_LABEL = {
    [CONTENT_TYPE.folder]: 'Folder',
    [CONTENT_TYPE.document]: 'Doc',
};
