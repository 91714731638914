import React, { useEffect, useState } from 'react';

import { mutate } from 'swr';
import { API } from 'constants';
import operateClient from '../../../services/operate-api';

import { COMPLETED_MESSAGES_REQUEST_SWR_KEY } from '../../../constants/inbox';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { normalizeMarkdown } from '../../../helpers/normalizeMarkdown';
import { handleCancelMessage, handleReassignMessage } from '../../../helpers/inboxUtils';

import { Button, ButtonIcon } from '../../../design-system';
import { ArrowDownSLineIcon, ErrorWarningLineIcon } from '../../../design-system/Icons';
import MultiActionOptions from '../MultiActionOptions/MultiActionOptions';
import Alert from '../../../design-system/Alert/Alert';
import DiscussLineIcon from '../../../design-system/Icons/DiscussLineIcon';
import ShareAiFeedbackModal from '../../../components/ShareAIFeedbackModal/ShareAIFeedbackModal';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import MessageContentForm from '../MessageContentForm/MessageContentForm';

const MessageContentBlock = ({
    message,
    setData,
    requestKeysToMutate,
    formData,
    setFormData,
    bodyTypeFormFieldsCount,
    setNextMessageAfterMessageApproval,
    showTaskApprovedSnackAlert,
}) => {
    const editable = message.status !== 'completed';
    const [shareAIFeedbackModalOpened, setShareAIFeedbackModalOpened] = useState(false);
    const [multiActionOptionsOpened, setMultiActionOptionsOpened] = useState(false);
    const [isApproveLoading, setIsApproveLoading] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', statusCode: null });
    const [errorFormFields, setErrorFormFields] = useState({});

    useEffect(() => {
        const content =
            message.status === 'completed' ? message.final_output : message.message_content;

        setErrorFormFields(content.reduce((acc, item) => ({ ...acc, [item.id]: false }), {}));
    }, [message]);

    const mutateToDoData = (currentData) => {
        return {
            ...currentData,
            count: currentData.count - 1,
            results: currentData.results.filter((item) => item.id !== message.id),
        };
    };

    const handleApprove = async () => {
        const areFieldsWithEmptyValues = formData.some((item) => !item.value);
        if (areFieldsWithEmptyValues) {
            setErrorFormFields(
                formData.reduce((acc, item) => ({ ...acc, [item.id]: !item.value }), {})
            );
            return;
        }

        try {
            const inputs = formData.map((item) =>
                item.action_type === 'freeform'
                    ? { ...item, value: normalizeMarkdown(item.value) }
                    : item
            );
            const requestBody = {
                message_id: message.id,
                inputs,
            };

            setIsApproveLoading(true);
            const { data: response } = await operateClient.post(
                `${API.ROUTES.operate.run}${message.run}/complete/`,
                requestBody
            );
            setNextMessageAfterMessageApproval();

            const { subject, thread } = response.data;
            showTaskApprovedSnackAlert({ subject, threadId: thread });

            setData((prevData) => prevData.filter((item) => item.id !== message.id));
            // mutate all requests from To Do tab (remove approved message if it exists in request cash and set total count = count - 1)
            requestKeysToMutate.map((key) =>
                mutate(key, mutateToDoData, {
                    revalidate: false,
                })
            );
            // revalidate request from complete tab
            mutate(COMPLETED_MESSAGES_REQUEST_SWR_KEY, {
                revalidate: true,
            });
            setIsApproveLoading(false);
        } catch (e) {
            setAlert({
                show: true,
                status: 'critical',
                message: defaultErrorMessage,
                statusCode: e.response.status,
            });
            setIsApproveLoading(false);
        }
    };

    const handleReassign = async (selectedMember) => {
        await handleReassignMessage({
            selectedMember,
            message,
            setData,
            requestKeysToMutate,
            setNextMessageAfterMessageApproval,
        });
    };

    const handleCancel = async () => {
        await handleCancelMessage({
            message,
            setData,
            requestKeysToMutate,
            setNextMessageAfterMessageApproval,
        });
    };

    return (
        <div className="w-full">
            <div className="pt-6 w-full flex flex-col mb-6">
                <MessageContentForm
                    formData={formData}
                    setFormData={setFormData}
                    editable={editable}
                    errorFormFields={errorFormFields}
                    setErrorFormFields={setErrorFormFields}
                    bodyTypeFormFieldsCount={bodyTypeFormFieldsCount}
                />
            </div>
            <div className="flex min-[1160px]:justify-end items-center gap-8">
                {message.feedback_allowed && (
                    <div className="hidden min-[1160px]:block">
                        <Button
                            type="link"
                            size="sm"
                            text="Share AI Feedback"
                            leadingIcon={DiscussLineIcon}
                            onClick={() => setShareAIFeedbackModalOpened(true)}
                        />
                    </div>
                )}
                {editable && (
                    <div className="fixed bottom-[12px] right-[16px] min-[1160px]:relative min-[1160px]:bottom-0 min-[1160px]:right-0 flex gap-[1px]">
                        <Button
                            type="secondary"
                            size="sm"
                            text="Approve"
                            noRoundedRight
                            state={isApproveLoading ? 'loading' : 'default'}
                            onClick={handleApprove}
                        />
                        <ButtonIcon
                            type="secondary"
                            size="sm"
                            icon={ArrowDownSLineIcon}
                            noRoundedLeft
                            onClick={() => setMultiActionOptionsOpened(true)}
                        />
                        {multiActionOptionsOpened && (
                            <MultiActionOptions
                                messageSubject={message?.subject}
                                onClose={(e) => {
                                    if (e) {
                                        e.stopPropagation();
                                    }
                                    setMultiActionOptionsOpened(false);
                                }}
                                onApprove={handleApprove}
                                onReassign={handleReassign}
                                onCancel={handleCancel}
                            />
                        )}
                    </div>
                )}
            </div>
            {alert.show && (
                <Alert
                    status={alert.status}
                    message={alert.message}
                    icon={alert.status === 'critical' ? ErrorWarningLineIcon : CheckLineIcon}
                    statusCode={alert.statusCode}
                    handleClose={() =>
                        setAlert({ show: false, status: null, message: '', statusCode: null })
                    }
                />
            )}
            {shareAIFeedbackModalOpened && (
                <ShareAiFeedbackModal
                    target="inbox_message"
                    messageId={message.id}
                    setAlert={setAlert}
                    onClose={() => setShareAIFeedbackModalOpened(false)}
                />
            )}
        </div>
    );
};

export default MessageContentBlock;
