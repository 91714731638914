import React from 'react';
import { ErrorWarningLineIcon } from '../../design-system/Icons';
import { Button } from '../../design-system';
import { Navigate, useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import SideBar from '../../components/SideBar/SideBar';

const NotFoundPage = () => {
    const navigate = useNavigate();
    const { user, isValidating } = useUser();

    const shouldRedirect = !isValidating && !user;

    if (shouldRedirect) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <SideBar />
            <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 px-5 py-6 sm:py-[28px] sm:px-0">
                <div className="py-[80px] flex flex-col items-center">
                    <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full mb-4">
                        <ErrorWarningLineIcon width={32} height={32} color="#5E6470" />
                    </div>
                    <p className="font-body text-regular-s text-neutral-300 uppercase text-center mb-1">
                        404 Page not found
                    </p>
                    <p className="font-heading-bold text-heading-bold-m text-black text-center min-[350px]:w-[85%] max-w-[400px] mb-2">
                        Oops! It seems like the page you're looking for doesn't exist
                    </p>
                    <p className="font-body text-regular-m text-black text-center min-[350px]:w-[85%] max-w-[340px] mb-6">
                        You can head back to the library and continue your journey from there.
                    </p>
                    <Button
                        type="secondary"
                        size="md"
                        text="Go to Your Library"
                        onClick={() => navigate('/library')}
                    />
                </div>
            </div>
        </>
    );
};

export default NotFoundPage;
