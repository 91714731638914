export const EVENT_LISTENER_VALUES = {
    on_thread_failure: 'on_thread_failure',
    on_thread_success: 'on_thread_success',
    on_thread_start: 'on_thread_start',
    on_thread_cancel: 'on_thread_cancel',
    on_thread_feedback: 'on_thread_feedback',
};

export const EVENT_LISTENER_TRIGGER_LABELS = {
    on_thread_failure: 'Thread failure',
    on_thread_success: 'Thread success',
    on_thread_start: 'Thread start',
    on_thread_cancel: 'Thread cancel',
    on_thread_feedback: 'Thread feedback',
};

export const DELIVERY_TYPES = {
    webhook: 'webhook',
};

export const eventListenerSelectOptions = [
    {
        id: EVENT_LISTENER_VALUES['on_thread_failure'],
        name: EVENT_LISTENER_TRIGGER_LABELS['on_thread_failure'],
    },
    {
        id: EVENT_LISTENER_VALUES['on_thread_feedback'],
        name: EVENT_LISTENER_TRIGGER_LABELS['on_thread_feedback'],
    },
    {
        id: EVENT_LISTENER_VALUES['on_thread_success'],
        name: EVENT_LISTENER_TRIGGER_LABELS['on_thread_success'],
    },
];

export const deliveryTypeSelectOptions = [
    {
        id: DELIVERY_TYPES.webhook,
        name: 'Webhook',
    },
];
