import React, { useState } from 'react';

import { ButtonIcon } from '../../../../design-system';
import Alert from '../../../../design-system/Alert/Alert';
import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import FileTextLineIcon from '../../../../design-system/Icons/FileTextLineIcon';
import QuickAddToDocModal from '../../../../components/QuickAddToDocModal/QuickAddToDocModal';

const notFilledStateMessage = '*Not filled yet*';

const InfoContentPiece = ({ content, setShouldClosePanelOnOutsideClick }) => {
    const { value, is_filled, label } = content;

    const [isAddToDocModalOpened, setIsAddToDocModalOpened] = useState(false);
    const [successAlert, setSuccessAlert] = useState(null);

    const openAddToDocModal = () => {
        setIsAddToDocModalOpened(true);
        setShouldClosePanelOnOutsideClick(false);
    };

    const closeAddToDocModal = () => {
        setIsAddToDocModalOpened(false);
        setShouldClosePanelOnOutsideClick(true);
    };

    const addToDocButton = (
        <ButtonIcon type="link" size="xs" icon={FileTextLineIcon} onClick={openAddToDocModal} />
    );

    return (
        <>
            <RichTextArea
                value={is_filled ? value : notFilledStateMessage}
                label={label}
                previewOnly
                autoExpand
                darkBorderColor
                customPadding="px-3 py-[18px]"
                withCopyButton={is_filled}
                markdownColor={is_filled ? '#1F2125' : '#CFD6E5'}
                additionalButtons={is_filled ? addToDocButton : null}
            />

            {isAddToDocModalOpened && (
                <QuickAddToDocModal
                    content={value}
                    setSuccessAlert={setSuccessAlert}
                    onClose={closeAddToDocModal}
                />
            )}

            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </>
    );
};

export default InfoContentPiece;
