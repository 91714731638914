import { capitalizeFirstLetter } from '../services/strings';
import { EVENT_LISTENER_TRIGGER_LABELS } from '../constants/events';

export const transformEventNotificationDataForTable = (data) => {
    const result = {
        columns: [
            {
                header: 'EVENT NAME',
                accessor: 'name',
                type: 'text',
            },
            {
                header: 'TRIGGER',
                accessor: 'trigger',
                type: 'text',
                fixedWidth: 160,
            },
            {
                header: 'DELIVERY TYPE',
                accessor: 'type',
                type: 'text',
                fixedWidth: 165,
            },
            {
                header: 'STATUS',
                accessor: 'status',
                type: 'badge',
                fixedWidth: 105,
            },
        ],
        rows: [],
    };

    data?.map((event) =>
        result.rows.push({
            id: event.id,
            name: event.name,
            trigger: EVENT_LISTENER_TRIGGER_LABELS[event.event_listener],
            type: capitalizeFirstLetter(event.delivery),
            status: event.is_live ? 'Active' : 'Inactive',
            badgeColor: event.is_live ? 'lime' : 'peach',
        })
    );

    return result;
};
