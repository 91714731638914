import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigationRestrictionContext } from '../../hooks/useNavigationRestrictionContext';

import ConfirmNavigationModal from '../ConfirmNavigationModal/ConfirmNavigationModal';
import ReactRouterPrompt from 'react-router-prompt';

const NavigationGuard = ({
    when,
    promptMessage = 'Are you sure you want to leave? We’ll keep loading your process in the background.',
    customNavigationBlockerMessageSegment = 'We’ll keep loading your process in the background.', // this prompt message will be displayed in custom ConfirmNavigationModal (in ViewTypeSwitcher, OrganizationSwitcher, ect.)
    blockingNavigationFunction, // this prop should be passed when the custom logic is needed to determine whether navigation should be restricted
    customConfirmNavigationModal: CustomConfirmNavigationModal = null,
}) => {
    // this data is used to restrict navigation if user try to change View Type or Organization
    const { isNavigationRestricted, setIsNavigationRestricted, setPromptMessage } =
        useNavigationRestrictionContext();

    useEffect(() => {
        // update Navigation Restriction state in Context to show Confirm Navigation Modal while user try to change View Type or Organization
        if (when !== isNavigationRestricted) {
            setIsNavigationRestricted(when);

            if (when) {
                setPromptMessage(customNavigationBlockerMessageSegment);
            }

            if (!when) {
                setPromptMessage(null);
            }
        }
    }, [when]);

    useEffect(() => {
        // clear context after page unmount
        return () => {
            setIsNavigationRestricted(false);
            setPromptMessage(null);
        };
    }, []);

    const checkBlockingFunction = (args) => {
        if (!args) {
            return isNavigationRestricted;
        }

        const { currentLocation, nextLocation } = args;
        if (blockingNavigationFunction) {
            return blockingNavigationFunction({
                currentLocation,
                nextLocation,
                isNavigationRestricted,
            });
        }

        return isNavigationRestricted;
    };

    const ConfirmModalComponent = CustomConfirmNavigationModal || ConfirmNavigationModal;

    return (
        <ReactRouterPrompt when={checkBlockingFunction}>
            {({ onConfirm, onCancel }) => (
                <ConfirmModalComponent
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                    text={promptMessage}
                />
            )}
        </ReactRouterPrompt>
    );
};

NavigationGuard.propTypes = {
    when: PropTypes.bool.isRequired,
    promptMessage: PropTypes.string,
    customNavigationBlockerMessageSegment: PropTypes.string,
    blockingNavigationFunction: PropTypes.func,
    customConfirmNavigationModal: PropTypes.node,
};

export default NavigationGuard;
