import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import { defaultErrorMessage } from '../../../constants/errorMessages';
import { mutateDataAfterDeletingItem } from '../../../helpers/swrMutateUtils';

import ConfirmDeletionModalPattern from '../../../design-system/ConfirmDeletionModalPattern/ConfirmDeletionModalPattern';

const DeleteBotModal = ({
    id,
    onClose,
    botName,
    requestKeysToMutate,
    onDeleteSuccess = () => {},
}) => {
    const [errorAlert, setErrorAlert] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteBot = async () => {
        try {
            setIsLoading(true);

            await client.delete(`${API.ROUTES.assistant.customBot}${id}/`);
            mutateDataAfterDeletingItem({ requestKeysToMutate, id });

            onDeleteSuccess();

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <ConfirmDeletionModalPattern
            title="Delete Bot"
            handleDelete={handleDeleteBot}
            onClose={onClose}
            isLoading={isLoading}
            confirmationTextStart="You are about to delete"
            confirmationTextEnd="are you sure you’d like to continue? This bot will no longer be available to edit or use."
            entityName={botName}
            errorAlert={errorAlert}
            variant="secondary"
            setErrorAlert={setErrorAlert}
        />
    );
};

export default DeleteBotModal;
