import React from 'react';

const StrikethroughIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M11.4359 9.33366C11.5897 9.67773 11.6667 10.0599 11.6667 10.4801C11.6667 11.3753 11.3175 12.0747 10.6191 12.5783C9.9206 13.0819 8.95567 13.3337 7.72413 13.3337C6.63116 13.3337 5.5489 13.0793 4.47728 12.5707V11.0676C5.49025 11.6525 6.5272 11.945 7.58813 11.945C9.28887 11.945 10.1419 11.4567 10.1472 10.4801C10.1472 10.0629 10.0033 9.70686 9.7154 9.41186C9.68927 9.38513 9.6626 9.35906 9.6354 9.33366H2V8.00033H14V9.33366H11.4359ZM8.71733 7.33366H5.08605C4.97107 7.22919 4.86411 7.11313 4.76517 6.98553C4.47728 6.61422 4.33333 6.16405 4.33333 5.63504C4.33333 4.81101 4.64389 4.11033 5.26499 3.53299C5.88611 2.95565 6.84707 2.66699 8.14793 2.66699C9.12893 2.66699 10.0673 2.88571 10.9629 3.32317V4.7576C10.1632 4.2998 9.2862 4.07091 8.33187 4.07091C6.67913 4.07091 5.85278 4.59228 5.85278 5.63504C5.85278 5.91481 5.99806 6.15897 6.28862 6.36752C6.57919 6.57607 6.93773 6.74266 7.3642 6.86726C7.77767 6.98806 8.22873 7.14353 8.71733 7.33366Z"
                fill={props.color}
            />
        </svg>
    );
};

export default StrikethroughIcon;
