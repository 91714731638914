import React, { useState } from 'react';
import { useViewType } from '../../../hooks/useViewType';
import { navItemIconColor, navItemLabelColor } from '../../../constants/sideBar';

import ToolTip from '../../../design-system/ToolTip/ToolTip';
import SvgIcon from '../../../design-system/SvgIcon/SvgIcon';

const SideBarNotNavLinkItem = ({
    itemData = {},
    handleClick,
    selected = false,
    extraLargeIcon = false,
    mobileVersion = false,
    handleMobMenuClose,
}) => {
    const { title, toolTipLabel, icon, availableViewTypes = [] } = itemData;
    const { viewType } = useViewType();

    const shouldBeDisplayed = availableViewTypes.includes(viewType);

    const [showToolTip, setShowToolTip] = useState(false);

    if (!shouldBeDisplayed) {
        return <></>;
    }

    const handleAction = () => {
        handleClick();
        handleMobMenuClose();
    };

    return (
        <>
            {mobileVersion ? (
                <li className="p-2 cursor-pointer" onClick={handleAction}>
                    <div
                        className={`
                            flex items-center gap-[16px] font-body text-body-bold-m ${navItemLabelColor[viewType]}
                        `}
                    >
                        <span className="flex items-center justify-center w-[36px] h-[36px] relative z-[-1]">
                            <SvgIcon
                                color={navItemIconColor[viewType]}
                                size={extraLargeIcon ? 'extraLarge' : 'large'}
                                icon={icon}
                            />
                        </span>
                        {title}
                    </div>
                </li>
            ) : (
                <li className="p-2">
                    <ToolTip
                        text={toolTipLabel}
                        position="center-left"
                        shift={'55px'}
                        isShown={showToolTip}
                    >
                        <div
                            className={`w-9 h-9 flex justify-center items-center cursor-pointer ${
                                selected && 'rounded-2 bg-neutral-400'
                            }`}
                            onClick={handleClick}
                            onMouseEnter={() => setShowToolTip(true)}
                            onMouseLeave={() => setShowToolTip(false)}
                        >
                            <SvgIcon color={navItemIconColor[viewType]} size="large" icon={icon} />
                        </div>
                    </ToolTip>
                </li>
            )}
        </>
    );
};

export default SideBarNotNavLinkItem;
