import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from './useWindowSize';

export const useTaskPlaygroundMessagesHeight = ({ messages }) => {
    const containerRef = useRef(null);
    const systemMessageRef = useRef(null);

    const [containerHeight, setContainerHeight] = useState(0);
    const [systemMessageHeight, setSystemMessageHeight] = useState(146);
    const [scrollInsideFirstMessage, setScrollInsideFirstMessage] = useState(false);

    const { width: screenWidth } = useWindowSize();

    useEffect(() => {
        if (systemMessageRef.current) {
            const height = systemMessageRef.current.clientHeight;
            setSystemMessageHeight(height);
        }
    }, [systemMessageRef, messages?.[0]]);

    useEffect(() => {
        if (containerRef.current) {
            const height = containerRef.current.clientHeight;
            setContainerHeight(height);
        }
    }, [containerRef]);

    useEffect(() => {
        if (systemMessageHeight && containerHeight) {
            if (systemMessageHeight > 0.36 * containerHeight && !scrollInsideFirstMessage) {
                setScrollInsideFirstMessage(true);
            }
        }
    }, [systemMessageHeight, containerHeight]);

    const isDesktop = screenWidth >= 1024;
    const resultedSystemMessageHeight =
        scrollInsideFirstMessage && isDesktop ? 0.36 * containerHeight : 'auto';

    return {
        containerRef,
        systemMessageRef,
        systemMessageHeight: resultedSystemMessageHeight,
        scrollInsideFirstMessage,
        isDesktop,
    };
};
