import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { EditorState } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import {
    codeBlockLanguages$,
    codeMirrorAutoLoadLanguageSupport$,
    codeMirrorExtensions$,
    iconComponentFor$,
    readOnly$,
    useCodeBlockEditorContext,
} from '@mdxeditor/editor';
import { useCellValues } from '@mdxeditor/gurx';

import { languages } from '@codemirror/language-data';
import { basicLight } from 'cm6-theme-basic-light';
import { basicSetup } from 'codemirror';

import { handleCopy } from '../../../helpers/handleCopy';
import { setSyncTimeout } from '../../../helpers/setSyncTimeout';
import { useCodeMirrorRef } from '../../../hooks/useCodeMirrorRef';

import { SvgIcon } from '../../index';
import CheckLineIcon from '../../Icons/CheckLineIcon';
import CopyIcon from '../../Icons/CopyIcon';

export const CodeMirrorEditor = ({ language, nodeKey, code, focusEmitter }) => {
    const { lexicalNode } = useCodeBlockEditorContext();
    const [readOnly, codeMirrorExtensions, autoLoadLanguageSupport] = useCellValues(
        readOnly$,
        codeMirrorExtensions$,
        codeMirrorAutoLoadLanguageSupport$,
        iconComponentFor$,
        codeBlockLanguages$
    );

    const codeMirrorRef = useCodeMirrorRef(nodeKey, 'codeblock', language, focusEmitter);
    const { setCode } = useCodeBlockEditorContext();
    const editorViewRef = useRef(null);
    const elRef = useRef(null);

    const setCodeRef = useRef(setCode);
    setCodeRef.current = setCode;
    codeMirrorRef.current = {
        getCodemirror: () => editorViewRef.current,
    };

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        let stopTimeout;
        if (copied) {
            stopTimeout = setSyncTimeout(() => setCopied(false), 2000);
        }

        return () => {
            if (stopTimeout) {
                stopTimeout();
            }
        };
    }, [copied]);

    useEffect(() => {
        void (async () => {
            const extensions = [
                ...codeMirrorExtensions,
                basicSetup,
                basicLight,
                EditorView.lineWrapping,
                EditorView.updateListener.of(({ state }) => {
                    setCodeRef.current(state.doc.toString());
                }),
            ];
            if (readOnly) {
                extensions.push(EditorState.readOnly.of(true));
            }
            if (language !== '' && autoLoadLanguageSupport) {
                const languageData = languages.find((l) => {
                    return (
                        l.name === language ||
                        l.alias.includes(language) ||
                        l.extensions.includes(language)
                    );
                });
                if (languageData) {
                    try {
                        const languageSupport = await languageData.load();
                        extensions.push(languageSupport.extension);
                    } catch (e) {
                        console.warn('failed to load language support for', language);
                    }
                }
            }
            elRef.current.innerHTML = '';
            editorViewRef.current = new EditorView({
                parent: elRef.current,
                state: EditorState.create({ doc: code, extensions }),
            });
        })();
        return () => {
            editorViewRef.current?.destroy();
            editorViewRef.current = null;
        };
    }, [readOnly, language]);

    return (
        <div
            className="_codeMirrorWrapper_uazmk_391 group"
            style={{ position: 'relative' }}
            onKeyDown={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="absolute top-1.5 right-1.5 z-10 group-hover:block hidden transition-all duration-300">
                <button
                    className={classNames(
                        'bg-[#d0d7de] rounded-[5px] p-1.5 hover:bg-[#116329] hover:text-white text-[#313336]',
                        {
                            '!bg-[#116329] !text-white': copied,
                        }
                    )}
                    onClick={() => {
                        setCopied(true);
                        handleCopy(lexicalNode.__code);
                    }}
                >
                    <SvgIcon
                        color="currentColor"
                        icon={copied ? CheckLineIcon : CopyIcon}
                        size="small"
                    />
                </button>
            </div>
            <div ref={elRef} />
        </div>
    );
};

export const PlainTextCodeEditorDescriptor = {
    // always use the editor, no matter the language or the meta of the code block
    match: (language, meta) => true,
    // You can have multiple editors with different priorities, so that there's a "catch-all" editor (with the lowest priority)
    priority: 0,
    // The Editor is a React component
    Editor: CodeMirrorEditor,
};
