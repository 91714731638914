import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { handleCreateNewDoc as _handleCreateNewDoc } from '../../../helpers/docsUtils';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import NewFolderModal from '../NewFolderModal/NewFolderModal';
import AddNewMultiActionPopup from '../AddNewMultiActionPopup/AddNewMultiActionPopup';
import { AddCircleLineIcon, ArrowDownSLineIcon } from '../../../design-system/Icons';
import { Button, ButtonIcon } from '../../../design-system';

const AddNewMultiActionButton = () => {
    const { folderId: folder } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const [isNewFolderModalOpened, setIsNewFolderModalOpened] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleCreateNewDoc = async () => {
        await _handleCreateNewDoc({ folder, setIsLoading, setErrorAlert, navigate, location });
    };

    const openPopup = () => setIsPopupOpened(true);

    const closePopup = (e) => {
        e?.stopPropagation();
        setIsPopupOpened(false);
    };

    const openNewFolderModal = () => {
        setIsPopupOpened(false);
        setIsNewFolderModalOpened(true);
    };

    return (
        <>
            <div className="w-full flex gap-[1px] bg-neutral-300 rounded-2 relative">
                <div className="flex-1 flex flex-col">
                    <Button
                        type="primary"
                        size="sm"
                        text="Add new"
                        noRoundedRight
                        leadingIcon={AddCircleLineIcon}
                        onClick={openPopup}
                    />
                </div>

                <ButtonIcon
                    type="primary"
                    size="sm"
                    icon={ArrowDownSLineIcon}
                    noRoundedLeft
                    onClick={openPopup}
                />

                {isPopupOpened && (
                    <AddNewMultiActionPopup
                        openNewFolderModal={openNewFolderModal}
                        handleCreateNewDoc={handleCreateNewDoc}
                        isLoading={isLoading}
                        onClose={closePopup}
                    />
                )}

                {isNewFolderModalOpened && (
                    <NewFolderModal onClose={() => setIsNewFolderModalOpened(false)} />
                )}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

export default AddNewMultiActionButton;
