import React from 'react';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const ThreadMoreOptionsPopup = ({ threadId, setActionModal, onClose }) => {
    const handleAction = (action) => {
        action();
        onClose();
    };

    const openThreadDetailPage = () => {
        window.open(`/thread/${threadId}`, '_blank');
    };

    return (
        <div className="absolute top-[110%] right-0 z-20">
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="shareBoxLine"
                            text="View Thread"
                            onClick={() => handleAction(openThreadDetailPage)}
                        />
                        <ListOption
                            leadingIconName="skipBackIcon"
                            text="Restart Thread"
                            onClick={() =>
                                handleAction(() => setActionModal({ action: 'restart' }))
                            }
                        />
                        <ListOption
                            leadingIconName="squareIcon"
                            text="Cancel Thread"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={() => handleAction(() => setActionModal({ action: 'cancel' }))}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};

export default ThreadMoreOptionsPopup;
