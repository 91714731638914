import React, { Fragment } from 'react';
import { BLOCK_TYPE, PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import { updateOrderKeys } from '../../../helpers/insertItemAtSpecificPositionWithOrderUpdate';

import CalloutBlock from '../../../design-system/PlaybookBuilder/CalloutBlock/CalloutBlock';
import RichTextBlock from '../../../design-system/PlaybookBuilder/RichTextBlock/RichTextBlock';
import ImageBlock from '../../../design-system/PlaybookBuilder/ImageBlock/ImageBlock';
import VideoBlock from '../../../design-system/PlaybookBuilder/VideoBlock/VideoBlock';
import DocumentBlock from '../../../design-system/PlaybookBuilder/DocumentBlock/DocumentBlock';
import AgentBlock from '../../../design-system/PlaybookBuilder/AgentBlock/AgentBlock';
import ChatPromptBlock from '../../../design-system/PlaybookBuilder/ChatPromptBlock/ChatPromptBlock';
import ProjectBlock from '../../../design-system/PlaybookBuilder/ProjectBlock/ProjectBlock';

const PlaybookContentBlock = ({
    mode,
    blockData,
    setContentBlock,
    linkedDocsDataState,
    connectedAgentsDetailedDataState,
    connectedProjectsDataState,
}) => {
    const { temporaryId } = blockData;

    const handleDeleteBlock = () => {
        setContentBlock((prevData) => {
            const filteredList = prevData.filter((block) => block.temporaryId !== temporaryId);
            return updateOrderKeys(filteredList);
        });
    };

    const setBlockProperties = (cb) => {
        setContentBlock((prevData) =>
            prevData.map((block) =>
                block.temporaryId === temporaryId
                    ? {
                          ...block,
                          properties: typeof cb === 'function' ? cb(block.properties) : cb,
                      }
                    : block
            )
        );
    };

    const handleBlockPropertyChange = (name, value) => {
        setBlockProperties((prevData) => ({ ...prevData, [name]: value }));
    };

    const blockComponent = {
        [BLOCK_TYPE.richtext]: RichTextBlock,
        [BLOCK_TYPE.image]: ImageBlock,
        [BLOCK_TYPE.video]: VideoBlock,
        [BLOCK_TYPE.callout]: CalloutBlock,
        [BLOCK_TYPE.document]: DocumentBlock,
        [BLOCK_TYPE.agent]: AgentBlock,
        [BLOCK_TYPE.project]: ProjectBlock,
        [BLOCK_TYPE.chat_prompt]: ChatPromptBlock,
    };

    const Component = blockComponent[blockData.block_type];

    const props = {
        blockData,
        editMode: mode === PLAYBOOK_BUILDER_MODE.edit,
        handleDeleteBlock,
        handleBlockPropertyChange,
    };

    if (blockData.block_type === BLOCK_TYPE.agent) {
        const [connectedAgentsDetailedData, setConnectedAgentsDetailedData] =
            connectedAgentsDetailedDataState;

        const agentId = blockData.properties.agent_id;
        if (agentId) {
            props.agentDetails = connectedAgentsDetailedData[agentId] || null;
        }

        props.setConnectedAgentsData = setConnectedAgentsDetailedData;
    }

    if (blockData.block_type === BLOCK_TYPE.project) {
        const [connectedProjectsData, setConnectedProjectsData] = connectedProjectsDataState;

        const scenarioId = blockData.properties.scenario_id;
        if (scenarioId) {
            props.projectDetails = connectedProjectsData[scenarioId] || null;
        }

        props.setConnectedProjectsData = setConnectedProjectsData;
    }

    if (blockData.block_type === BLOCK_TYPE.document) {
        const [linkedDocsData, setLinkedDocsData] = linkedDocsDataState;

        const docId = blockData.properties.document_id;
        if (docId) {
            props.documentData = linkedDocsData[docId || null];
        }

        props.setLinkedDocsData = setLinkedDocsData;
    }

    if (Component) {
        return <Component key={blockData.temporaryId} {...props} />;
    }

    return <Fragment key={blockData.temporaryId}></Fragment>;
};

export default PlaybookContentBlock;
