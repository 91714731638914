import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/operate-api';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import { useFormState } from '../../../../hooks/useFormState';
import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';

import { mutateListDataAfterRenamingItem } from '../../../../helpers/swrMutateUtils';
import { mergeTwoArraysRemovingDuplicates } from '../../../../helpers/mergeTwoArraysRemovingDuplicates';

import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import { Button } from '../../../../design-system';
import Alert from '../../../../design-system/Alert/Alert';
import Input from '../../../../design-system/Input/Input';
import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';

const RenameExecutionModal = ({ mutate, executionId, executionName, onClose }) => {
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { name: executionName }
    );

    const {
        requestKeysToMutate: { executions: requestKeysToMutateFromContext },
    } = useSwrRequestKeysToMutateFromContext();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSubmit = async () => {
        const { name } = formData;
        if (!name) {
            setFieldErrorMessages({ name: emptyFieldErrorMessage });
            return;
        }

        try {
            setIsLoading(true);

            const { data } = await client.patch(`${API.ROUTES.operate.execution}${executionId}/`, {
                name,
            });

            mutateListDataAfterRenamingItem({
                requestKeysToMutate: mergeTwoArraysRemovingDuplicates(
                    requestKeysToMutateFromContext
                ),
                id: executionId,
                updatedName: data.name,
            });

            if (mutate) {
                mutate((currentData) => ({ ...currentData, name: data.name }), {
                    revalidate: false,
                });
            }

            onClose();
        } catch (error) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
            setIsLoading(false);
        }
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="flex flex-col w-full gap-5 lg:gap-7">
                <ModalHeader title="Rename Execution" onClose={onClose} />

                <Input
                    name="name"
                    size="md"
                    value={formData.name}
                    label="Execution Name"
                    isRequired
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    placeholder="Enter execution name"
                />

                <div className="flex items-center justify-between pt-1">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="sm"
                        text="Save"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleSubmit}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default RenameExecutionModal;
