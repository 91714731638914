import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { TEMPLATE_TYPES } from '../../../../constants/template';

import ActionOption from '../../../../design-system/ActionOption/ActionOption';
import MultiActionPopup from '../../../../design-system/MultiActionPopup/MultiActionPopup';
import FileIcon from '../../../../design-system/Icons/FileIcon';
import GitPullRequest from '../../../../design-system/Icons/GitPullRequest';
import SquareIcon from '../../../../design-system/Icons/SquareIcon';
import PublishedIcon from '../../../../design-system/Icons/PublishedIcon';
import { FlashlightFillIcon } from '../../../../design-system/Icons';

const TemplateMultiActionPopup = ({
    templateType,
    onClose,
    isEditAccess,
    isMainBranch,
    branchStatus,
    areChangesToSave,
    handleSaveChanges,
    saveChangesLoading,
    handleStatusChange,
    changeStatusLoading,
    setConfirmCancelIssueModalOpened,
    setIsProcessVersionPublishingModalOpened,
}) => {
    const { goalTemplateId, processTemplateId, versionId } = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleSaveChangesBeforeNavigation = async () => {
        try {
            if (areChangesToSave) {
                setIsLoading(true);
                await handleSaveChanges({ shouldErrorBeThrown: true });
            }
            return true; // means changes were saved
        } catch (e) {
            setIsLoading(false);
            return false; // means an error occurred so changes were not saved
        }
    };

    const handleRunUnitTest = async () => {
        const areChangesSaved = await handleSaveChangesBeforeNavigation();
        if (areChangesSaved) {
            const createUnitTestPageUrl = {
                true: `/templates/goal/${goalTemplateId}/test`, // url for main branch
                false: `/templates/goal/${goalTemplateId}/test?version=${versionId}`, // url for not main branch
            };
            navigate(createUnitTestPageUrl[isMainBranch], { state: { from: location } });
        }
    };

    const handleRunIntegrationTest = async () => {
        const areChangesSaved = await handleSaveChangesBeforeNavigation();
        if (areChangesSaved) {
            const createIntegrationTestPageUrl = {
                true: `/templates/process/${processTemplateId}/test`, // url for main branch
                false: `/templates/process/${processTemplateId}/test?version=${versionId}`, // url for not main branch
            };
            navigate(createIntegrationTestPageUrl[isMainBranch], { state: { from: location } });
        }
    };

    const isSubmitPROptionDisplayed =
        branchStatus === 'open' || branchStatus === 'changes_requested';

    const isRunUnitTestsOptionDisplayed =
        (isMainBranch || branchStatus !== 'canceled') && templateType === TEMPLATE_TYPES.goal;

    const isPublishReleaseOptionDisplayed =
        branchStatus === 'merged' && templateType === TEMPLATE_TYPES.process;

    const isRunIntegrationTestOptionDisplayed =
        branchStatus !== 'canceled' && templateType === TEMPLATE_TYPES.process;

    return (
        <MultiActionPopup
            actionOptions={
                <>
                    {isEditAccess && (
                        <ActionOption
                            label="Save Changes"
                            description="This saves your changes as a commit in a draft pull request"
                            icon={FileIcon}
                            isLoading={saveChangesLoading}
                            onClose={onClose}
                            handleAction={async () => await handleSaveChanges({})}
                        />
                    )}
                    {isRunIntegrationTestOptionDisplayed && (
                        <ActionOption
                            label="Run Integration Test"
                            description="This action will take you to the Run New Integration Test Page."
                            icon={FlashlightFillIcon}
                            handleAction={handleRunIntegrationTest}
                            isLoading={isLoading}
                            onClose={onClose}
                        />
                    )}
                    {isRunUnitTestsOptionDisplayed && (
                        <ActionOption
                            label="Run Unit Tests"
                            description="This action will take you to the Create New Unit Test Page."
                            icon={FlashlightFillIcon}
                            handleAction={handleRunUnitTest}
                            isLoading={isLoading}
                            onClose={onClose}
                        />
                    )}
                    {isSubmitPROptionDisplayed && (
                        <ActionOption
                            label="Submit Pull Request"
                            description="This action submits pull request for review in GitHub"
                            icon={GitPullRequest}
                            isLoading={changeStatusLoading.ready_for_review}
                            handleAction={async () =>
                                await handleStatusChange({ status: 'ready_for_review' })
                            }
                            onClose={onClose}
                        />
                    )}
                    {isEditAccess && (
                        <ActionOption
                            label="Cancel Issue"
                            description="This action will close the issue in GitHub"
                            icon={SquareIcon}
                            handleAction={() => setConfirmCancelIssueModalOpened(true)}
                            onClose={onClose}
                        />
                    )}
                    {isPublishReleaseOptionDisplayed && (
                        <ActionOption
                            label="Publish Release"
                            description="This action will publish this version of the Process to users."
                            icon={PublishedIcon}
                            handleAction={() => setIsProcessVersionPublishingModalOpened(true)}
                            onClose={onClose}
                        />
                    )}
                </>
            }
            onClose={(e) => {
                e?.stopPropagation();
                onClose();
            }}
        />
    );
};

export default TemplateMultiActionPopup;
