export const updateInputState = (inputs, promptText) => {
    return inputs && inputs.length
        ? inputs.map((input) => {
              const result = {
                  ...input,
                  state: input?.state || 'default',
                  isRequired: true,
              };
              if (promptText !== undefined && promptText !== null) {
                  result.isMissedInPrompt = !promptText?.includes(`[${input.key}]`);
              }
              return result;
          })
        : [];
};

export const filterInputsWithFillQuerystring = (inputs) => {
    const arrayIdsFromResultData = inputs.reduce((result, item) => {
        if (item.fromResultData) {
            result.push(item.id);
        }
        return result;
    }, []);

    return inputs
        .filter((input) => input.fromResultData || !arrayIdsFromResultData.includes(input.id))
        .map((item) => {
            const { fromResultData, ...input } = item;
            return input;
        });
};
