import React from 'react';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { handleOpenStartNewJobModal } from '../../../helpers/handleOpenStartNewJobModal';

import { Button } from '../../../design-system';
import { AddLineIcon, FlashlightFillIcon } from '../../../design-system/Icons';

const PlaybooksTitleBlock = ({ openCreatePlaybookModal }) => {
    const { isDesktop } = useResponsiveBreakpoints();

    return (
        <div className="flex flex-col sm:flex-row gap-y-2.5 gap-x-4 sm:justify-between sm:items-center bg-white px-5 py-4 xs:p-6 lg:p-8">
            <div>
                <p className="font-heading-bold text-heading-bold-m md:text-heading-bold-l text-black">
                    Your Playbooks
                </p>
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Your collection of Playbooks used to train and run agents for your business.
                </p>
            </div>

            <div className="items-center gap-2 flex">
                {isDesktop && (
                    <div>
                        <Button
                            type="secondary"
                            size="sm"
                            text="New Playbook"
                            leadingIcon={AddLineIcon}
                            onClick={openCreatePlaybookModal}
                        />
                    </div>
                )}
                <div className="flex-1 sm:flex-auto sm:whitespace-nowrap flex flex-col">
                    <Button
                        type="primary"
                        size="sm"
                        text="New Job"
                        helperText={isDesktop ? '⌘J' : ''}
                        leadingIcon={FlashlightFillIcon}
                        onClick={handleOpenStartNewJobModal}
                    />
                </div>
            </div>
        </div>
    );
};

export default PlaybooksTitleBlock;
