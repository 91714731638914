import React from 'react';
import { Button } from 'design-system';
import { handleContactUs } from '../../../../helpers/handleContactUs';

const ShareBlock = () => {
    return (
        <div className="flex flex-col gap-4 xs:gap-6">
            <h3 className="font-heading-bold text-heading-bold-m text-neutral-500">Team Sharing</h3>
            <p className="font-body text-body-regular-m text-neutral-400">
                You need to have a <strong>Pro Plan</strong> to enable teams and sharing. Contact us
                if you are interested in these features.
            </p>
            <div>
                <Button type="primary" size="xs" text="Contact us" onClick={handleContactUs} />
            </div>
        </div>
    );
};

export default ShareBlock;
