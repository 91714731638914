import React from 'react';
import { ListOption, ListOptionGroup } from '../../../design-system';

const JobCardMoreOptionsPopup = ({ setActionModal, onClose }) => {
    const openModal = (action) => {
        setActionModal({ action });
        onClose();
    };

    const handleClose = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        onClose();
    };

    return (
        <div className="absolute top-[110%] right-0 z-20 pb-5" onClick={(e) => e.preventDefault()}>
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Rename"
                            onClick={() => openModal('rename')}
                        />
                        <ListOption
                            leadingIconName="skipBackIcon"
                            text="Restart"
                            onClick={() => openModal('restart')}
                        />
                        <ListOption
                            leadingIconName="inboxArchiveLineIcon"
                            text="Archive"
                            onClick={() => openModal('archive')}
                        />
                    </>
                }
                handleClose={handleClose}
            />
        </div>
    );
};
export default JobCardMoreOptionsPopup;
