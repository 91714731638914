import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/library-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { Button } from '../../../../design-system';
import Alert from '../../../../design-system/Alert/Alert';
import Modal from '../../../../design-system/Modal/Modal';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const ConfirmVariableDeleteModal = ({
    variableType,
    processId,
    variableData,
    variables,
    setVariables,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleDeleteOutput = async () => {
        try {
            setIsLoading(true);
            const requestBody = {
                outputs: variables?.filter((output) => output.id !== variableData.id) || [],
            };
            const { data } = await client.patch(
                `${API.ROUTES.library.process}${processId}/`,
                requestBody
            );
            setVariables(data.outputs);
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    const handleDeleteInput = () => {
        setVariables((prevData) => {
            const filteredDataWithUpdatedOrders = prevData
                .filter((item) => item.order !== variableData.order)
                .map((item, index) => ({ ...item, order: index + 1 }));
            return filteredDataWithUpdatedOrders;
        });

        onClose();
    };

    const handleDeleteVariable = async () => {
        if (variableType === 'input') {
            handleDeleteInput();
        }

        if (variableType === 'output') {
            await handleDeleteOutput();
        }
    };

    return (
        <Modal onClose={onClose} size="medium">
            <div className="sm:p-2">
                <p className="font-heading-bold text-heading-bold-m text-neutral-400 text-center mb-8">
                    Are you sure you want to delete {variableData.label}?
                </p>
                <div className="flex items-center justify-center gap-4">
                    <Button type="neutral" size="sm" text="No, Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Yes, Delete"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleDeleteVariable}
                    />
                </div>
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default ConfirmVariableDeleteModal;
