import { ACTION_TYPES } from '../constants/formVariables';

const allInputActionTypeOptions = Object.values(ACTION_TYPES).map((actionType) => ({
    id: actionType.value,
    name: actionType.name,
}));

export const getAllowedActionTypes = (type) => {
    return Object.values(ACTION_TYPES).reduce((acc, actionType) => {
        if (actionType.allowed_data_types?.includes(type)) {
            return [...acc, actionType.value];
        }
        return acc;
    }, []);
};

export const getInputActionTypeOptions = (type) => {
    if (!type) {
        return allInputActionTypeOptions;
    }

    return Object.values(ACTION_TYPES).reduce((acc, actionType) => {
        if (actionType.allowed_data_types?.includes(type)) {
            return [...acc, { id: actionType.value, name: actionType.name }];
        }
        return acc;
    }, []);
};
