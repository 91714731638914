import { SWRConfig } from 'swr';
// import { WebSocketProvider } from "./context/webSocketContext";
import Router from './router';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'e4ad8412-178a-4711-8de0-c6bcb8c41eca',
    // not secret
    clientToken: 'pube2e86165187e4873845202f48d03b641',
    site: 'us5.datadoghq.com',
    service: 'promptfolio',
    env: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    trackUserInteractions: true,
    // ignore noise from hot reloading in dev
    silentMultipleInit: true,
    defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

const swrOptions = {
    refreshInterval: 5 * 60 * 1000,
    fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
};

function App() {
    console.log('App running');
    return (
        <SWRConfig value={swrOptions}>
            <Router />
        </SWRConfig>
    );
}

export default App;
