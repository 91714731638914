import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal/Modal';
import { Button } from '../index';

const ConfirmationModal = ({
    onClose,
    text,
    confirmButtonState = 'default',
    handleConfirm,
    confirmButtonText,
    cancelButtonText = 'No, Cancel',
}) => {
    return (
        <Modal size="medium" onClose={onClose}>
            <div className="sm:p-2">
                <p className="font-heading-bold text-heading-bold-m text-neutral-400 mb-6">
                    {text}
                </p>
                <div className="flex justify-center items-center gap-4">
                    <Button type="neutral" size="sm" text={cancelButtonText} onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        state={confirmButtonState}
                        text={confirmButtonText}
                        onClick={handleConfirm}
                    />
                </div>
            </div>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    confirmButtonState: PropTypes.oneOf(['default', 'loading', 'error', 'disabled']),
    handleConfirm: PropTypes.func.isRequired,
    confirmButtonText: PropTypes.string.isRequired,
    cancelButtonText: PropTypes.string,
};

export default ConfirmationModal;
