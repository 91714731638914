import React from 'react';
import PropTypes from 'prop-types';

export default function Form(props) {
    const { title, description, onSubmit } = props;

    return (
        <div className="bg-transparent rounded-xl w-full">
            {(title || description) && (
                <div className="mb-[32px]">
                    {title && (
                        <h2 className="font-heading-bold text-heading-bold-m text-center">
                            {title}
                        </h2>
                    )}
                    {description && (
                        <p className="mt-[4px] font-body text-body-regular-m text-center">
                            {description}
                        </p>
                    )}
                </div>
            )}
            <form onSubmit={onSubmit} className="w-full">
                {props.children}
            </form>
        </div>
    );
}

Form.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
};
