import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon/SvgIcon';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import ErrorWarningLineIcon from '../Icons/ErrorWarningLineIcon';

const FormFieldFooter = ({ state, errorMessage, tipText, wrapperStyle = {} }) => {
    return (
        <>
            {state === 'error' && errorMessage && (
                <div style={wrapperStyle}>
                    <ErrorMessage message={errorMessage} />
                </div>
            )}

            {tipText && (state !== 'error' || !errorMessage) && (
                <div className="flex gap-1" style={wrapperStyle}>
                    <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                    <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                </div>
            )}
        </>
    );
};

FormFieldFooter.propTypes = {
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    wrapperStyle: PropTypes.object,
};

export default FormFieldFooter;
