import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/library-api';
import { useFormState } from '../../../../hooks/useFormState';

import { keyRegex } from '../../../../constants/regex_patterns';
import { defaultErrorMessage } from '../../../../constants/errorMessages';
import {
    EMPTY_FIELDS_ERROR_MESSAGE,
    GENERAL_TYPES_OPTIONS,
} from '../../../../constants/taskPlayground';

import { removeRedundantFieldsFromOutput } from '../../../../helpers/taskPlaygroundUtils';
import { mergeExistingValuesIntoNewOutputs } from '../../../../helpers/determineInputs';

import Modal from '../../../../design-system/Modal/Modal';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import { Button, Input, Select, Toggle } from '../../../../design-system';

const CreateOutputModal = ({ outputs, setOutputs, taskId, onClose }) => {
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        {
            key: '',
            label: '',
            type: null,
            items: null,
            is_required: true,
        }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const checkIfFormDataValid = () => {
        let isValid = true;

        const requiredFields =
            formData.type === 'array'
                ? ['key', 'label', 'type', 'items']
                : ['key', 'label', 'type'];

        const areEmptyFields = requiredFields?.some((field) => !formData[field]);

        if (areEmptyFields) {
            setFieldErrorMessages((prevState) => {
                const errorMessages = { ...prevState };
                requiredFields.forEach((field) => {
                    errorMessages[field] = !formData[field]
                        ? EMPTY_FIELDS_ERROR_MESSAGE[field]
                        : null;
                });
                return errorMessages;
            });
            isValid = false;
        }

        const isKeyValid = keyRegex.test(formData.key);
        if (!isKeyValid) {
            setFieldErrorMessages((prevState) => ({
                ...prevState,
                key: 'Key must only contain lowercase letters, numbers, or underscores.',
            }));
            isValid = false;
        }

        const isKeyUnique = outputs?.every((output) => output.key !== formData.key);
        if (!isKeyUnique) {
            setFieldErrorMessages((prevState) => ({
                ...prevState,
                key: 'Key must be unique.',
            }));
            isValid = false;
        }

        return isValid;
    };

    const handleAddOutput = async () => {
        try {
            const isFormDataValid = checkIfFormDataValid();
            if (!isFormDataValid) {
                return;
            }

            setIsLoading(true);
            const { key, label, type, items, is_required } = formData;
            const newOutput = {
                label,
                key,
                type,
                is_required,
                ...(type === 'array' && { items }),
            };
            const updatedOutputs = [...outputs.map(removeRedundantFieldsFromOutput), newOutput];
            const { data } = await client.patch(`${API.ROUTES.library.task}${taskId}/`, {
                output_variables: updatedOutputs,
            });
            const newOutputs = data.outputs;
            setOutputs((existingOutputs) =>
                mergeExistingValuesIntoNewOutputs({
                    existingOutputs,
                    newOutputs,
                    addValueFromExistingOutputs: true,
                })
            );

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <Modal onClose={onClose} size="medium" hideScrollbar>
            <div className="p-2 flex flex-col gap-8">
                <ModalHeader onClose={onClose} title="Add New Output Variable Input" />

                <div className="flex flex-col gap-5">
                    <Input
                        size="md"
                        name="key"
                        label="Output Variable Key"
                        value={formData.key}
                        isRequired
                        onChange={(e) => handleInputChange('key', e.target.value)}
                        state={fieldErrorMessages.key ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.key}
                        placeholder="Add variable key"
                        tipText="Output key needs to be all lowercase with no spaces and no “-”"
                    />

                    <Input
                        size="md"
                        name="label"
                        label="Output Variable Label"
                        value={formData.label}
                        isRequired
                        onChange={(e) => handleInputChange('label', e.target.value)}
                        state={fieldErrorMessages.label ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.label}
                        placeholder="Add Input Label"
                    />

                    <Select
                        size="md"
                        name="type"
                        value={formData.type}
                        options={GENERAL_TYPES_OPTIONS}
                        label="Output Variable Type"
                        onChange={(option) => handleInputChange('type', option)}
                        isRequired
                        placeholder="Select an option"
                        state={fieldErrorMessages.type ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.type}
                        dropdownHeight={180}
                        increaseComponentHeightBy={formData.type === 'array' ? 0 : 68}
                    />

                    {formData.type === 'array' && (
                        <Select
                            size="md"
                            name="items"
                            value={formData.items}
                            options={GENERAL_TYPES_OPTIONS}
                            label="Output Variable Items"
                            onChange={(option) => handleInputChange('items', option)}
                            isRequired
                            placeholder="Select an option"
                            state={fieldErrorMessages.items ? 'error' : 'default'}
                            errorMessage={fieldErrorMessages.items}
                            increaseComponentHeightBy={68}
                            dropdownHeight={180}
                        />
                    )}

                    <Toggle
                        name="is_required"
                        isSelected={formData.is_required}
                        label="Output Variable Required"
                        isRequired
                        size="sm"
                        onChange={(value) => handleInputChange('is_required', value)}
                    />
                </div>

                <div className="flex items-center justify-center gap-1 xs:gap-4">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Add New"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleAddOutput}
                    />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default CreateOutputModal;
