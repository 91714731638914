import React from 'react';

import { Separator } from '@mdxeditor/editor';

import ToolbarLists from './toolbarComponents/ToolbarLists';
import ToolbarHeadings from './toolbarComponents/ToolbarHeadings';
import ToolbarInsertCommands from './toolbarComponents/ToolbarInsertCommands';
import ToolbarBoldItalicUnderlineStrikethrough from './toolbarComponents/ToolbarBoldItalicUnderlineStrikethrough';
import { ButtonIcon } from '../../index';
import { DeleteBin4LineIcon } from '../../Icons';

const NewRichTextAreaToolbar = ({ withDeleteButton, handleDelete, isMobileViewActive }) => {
    return (
        <div className="flex justify-between items-center w-full flex-wrap">
            <div className="flex items-center flex-wrap">
                <ToolbarHeadings />
                <ToolbarBoldItalicUnderlineStrikethrough isMobile={isMobileViewActive} />
                {!isMobileViewActive && <Separator />}
                <ToolbarLists isMobile={isMobileViewActive} />
                <ToolbarInsertCommands isMobile={isMobileViewActive} />
                {!isMobileViewActive && <Separator />}
            </div>

            {withDeleteButton && handleDelete && (
                <ButtonIcon
                    type="neutral"
                    size="xs"
                    state="default"
                    icon={DeleteBin4LineIcon}
                    onClick={handleDelete}
                />
            )}
        </div>
    );
};

export default NewRichTextAreaToolbar;
