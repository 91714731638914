import React, { memo, useState } from 'react';

import client from '../../../../services/knowledge-api';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import { SORT_TYPES } from '../../../../constants/sort';
import {
    KNOWLEDGE_SOURCE_TYPE,
    KNOWLEDGE_SOURCE_TYPE_ICON,
    SOURCE_ROUTES_BY_TYPE,
} from '../../../../constants/knowledge';
import { capitalizeFirstLetter } from '../../../../services/strings';

import EditLineIcon from '../../../../design-system/Icons/EditLineIcon';
import EditSelectionModal from '../EditSelectionModal/EditSelectionModal';
import CollapsableContainer from '../../../../components/CollapsableContainer/CollapsableContainer';
import { Button, SvgIcon } from '../../../../design-system';

const KnowledgeSource = ({ type = KNOWLEDGE_SOURCE_TYPE.bases, sources, botId, setBotDetail }) => {
    const areSourcesEmpty = !sources?.length;
    const [isSelectSourcesModalOpened, setIsSelectSourcesModalOpened] = useState(false);

    const formatResponseToOptions = (results) =>
        results?.map(({ id, name, label }) => ({
            id,
            label: type === KNOWLEDGE_SOURCE_TYPE.bases ? name : label,
        }));

    const searchParams =
        type === KNOWLEDGE_SOURCE_TYPE.documents
            ? { ordering: SORT_TYPES.alphabeticalByLabel }
            : {};

    const optionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: SOURCE_ROUTES_BY_TYPE[type],
        searchParams,
        formatResponseToOptions,
    });

    return (
        <CollapsableContainer title={capitalizeFirstLetter(type)} useInternalExpandState>
            <div className="flex flex-col gap-4 mb-[-12px]">
                {!areSourcesEmpty && (
                    <ul className="flex flex-col gap-1.5">
                        {sources.map((source) => {
                            const displayedLabel =
                                type === KNOWLEDGE_SOURCE_TYPE.bases ? source.name : source.label;

                            return (
                                <li
                                    key={source.id}
                                    className="px-4 py-3 rounded-2 flex items-center gap-3 md:gap-4 bg-white"
                                >
                                    <div className="w-8 h-8 min-w-[32px] rounded-2 bg-purple-100 flex justify-center items-center">
                                        <SvgIcon
                                            color="#754DCF"
                                            size="medium"
                                            icon={KNOWLEDGE_SOURCE_TYPE_ICON[type]}
                                        />
                                    </div>

                                    <p className="font-body text-body-regular-s text-black">
                                        {displayedLabel}
                                    </p>
                                </li>
                            );
                        })}
                    </ul>
                )}

                {areSourcesEmpty && (
                    <p className="font-body text-body-regular-s text-neutral-300">
                        No connected {type} yet.
                    </p>
                )}

                <div className="flex justify-end">
                    <Button
                        type="link"
                        size="sm"
                        text={`Edit ${capitalizeFirstLetter(type)}`}
                        leadingIcon={EditLineIcon}
                        onClick={() => setIsSelectSourcesModalOpened(true)}
                    />
                </div>
            </div>

            {isSelectSourcesModalOpened && (
                <EditSelectionModal
                    botId={botId}
                    type={type}
                    savedSelectedSources={sources.map(({ id }) => id)}
                    optionsHookResponse={optionsHookResponse}
                    setBotDetail={setBotDetail}
                    onClose={() => setIsSelectSourcesModalOpened(false)}
                />
            )}
        </CollapsableContainer>
    );
};

export default memo(KnowledgeSource);
