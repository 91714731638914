import React, { useEffect, useState } from 'react';
import Panel from 'components/Panel';
import Button from 'design-system/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import NewRunContainer from 'components/NewRunContainer/NewRunContainer';
import Loading from '../../../components/Loading';
import BookmarkFillIcon from '../../../design-system/Icons/BookmarkFillIcon';
import { Badge } from '../../../design-system';
import temperatureOptions from '../../../constants/temperatureOptions';
import { Settings6LineIcon } from '../../../design-system/Icons';
import { TASK_TYPES, TASK_TYPES_ICON, TASK_TYPES_LABEL } from '../../../constants/library';

const TaskPanel = (props) => {
    const { data, id, handlePanelClose, accessLevel } = props;
    const taskId = id && parseInt(id, 10);
    const taskType = data?.task_type;
    const navigate = useNavigate();
    const location = useLocation();
    const [task, setTask] = useState(null);

    useEffect(() => {
        const temperature = data.default_prompt?.default_prompt_version?.settings?.temperature;
        const temperatureLabel =
            temperature && temperatureOptions.find((item) => item.value === temperature)?.name;
        const inputsKeyList = data.inputs?.map((input) => `[${input.key}]`) || [];
        const outputsKeyList = data.outputs?.map((output) => `[${output.key}]`) || [];
        setTask({
            name: data.name,
            description: data.description,
            iconText: data.icon_text,
            iconColor: data.icon_color || '#F0F5FE',
            promptTextData: [
                {
                    value: data.default_prompt?.default_prompt_version?.messages[0].content || '',
                    state: 'disabled',
                    label: 'Prompt',
                },
            ],
            promptId: data.default_prompt?.id,
            collections: data.collections,
            model: data.default_prompt?.default_prompt_version?.settings?.model,
            temperatureLabel,
            inputsKeyList,
            outputsKeyList,
        });
    }, []);

    const handleNavigationButtonClick = () => {
        if (taskId) {
            if (taskType === TASK_TYPES.prompt) {
                navigate(`/library/prompt/${task.promptId}`, {
                    state: { libraryLocation: location },
                });
            } 
            else {
                navigate(`/library/task/${taskId}`, {
                    state: { libraryLocation: location },
                });
            }
        }
    };

    return (
        <Panel onClose={handlePanelClose} onNavigationButtonClick={handleNavigationButtonClick}>
            {!task && (
                <div className="flex justify-center items-center font-body text-body-bold-xl text-black">
                    <Loading />
                </div>
            )}
            {task && (
                <div className="flex flex-col gap-5 h-full pb-4">
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            {task.iconText && (
                                <div
                                    className="w-[36px] h-[36px] min-w-[36px] rounded-2 flex items-center justify-center"
                                    style={{ backgroundColor: task.iconColor }}
                                >
                                    <Emoji
                                        unified={task.iconText}
                                        emojiStyle={EmojiStyle.APPLE}
                                        size={22}
                                    />
                                </div>
                            )}
                            <h2 className="font-body text-body-bold-l text-black">{task.name}</h2>
                        </div>

                        <p className="font-body text-body-regular-m text-neutral-500 ">
                            {task.description}
                        </p>

                        {!!task.collections.length && (
                            <div className="w-full flex flex-wrap gap-1">
                                {task.collections.map((item) => (
                                    <Badge
                                        key={item.id}
                                        text={item.name}
                                        color="neutral"
                                        leadingIcon={BookmarkFillIcon}
                                        leadingIconColor={item.icon_color}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                    {taskType === TASK_TYPES.prompt && (
                        <>
                            <div>
                                <p className="font-body text-body-bold-s text-neutral-500 mb-2">
                                    Prompt Settings
                                </p>
                                <div className="flex gap-1 items-center">
                                    <Badge
                                        text={task.model}
                                        color="neutral"
                                        leadingIcon={Settings6LineIcon}
                                        leadingIconColor={'#1F2125'}
                                    />
                                    <Badge
                                        text={task.temperatureLabel}
                                        color="neutral"
                                        leadingIcon={Settings6LineIcon}
                                        leadingIconColor={'#1F2125'}
                                    />
                                </div>
                            </div>

                            {accessLevel !== 'viewer' && (
                                <div className="flex-grow flex flex-col">
                                    <NewRunContainer
                                        textAreaData={task.promptTextData}
                                        updateTextAreaData={() => {}}
                                        withCopyButton
                                        fullHeight
                                    />
                                </div>
                            )}

                            <div className="flex justify-end">
                                <Button
                                    type="primary"
                                    size="xs"
                                    text={
                                        accessLevel !== 'viewer'
                                            ? 'Edit & Run Prompt'
                                            : 'Run Prompt'
                                    }
                                    onClick={handleNavigationButtonClick}
                                />
                            </div>
                        </>
                    )}
                    {taskType !== TASK_TYPES.prompt && (
                        <div className="flex flex-col gap-5 mt-1">
                            <div>
                                <p className="font-body text-body-bold-s text-neutral-500 mb-2">
                                    Task Type
                                </p>
                                <Badge
                                    text={TASK_TYPES_LABEL[taskType]}
                                    color="neutral"
                                    leadingIcon={TASK_TYPES_ICON[taskType]}
                                    leadingIconColor={'#1F2125'}
                                />
                            </div>
                            {!!task.inputsKeyList?.length && (
                                <div>
                                    <p className="font-body text-body-bold-s text-neutral-500 mb-2">
                                        Task Inputs
                                    </p>
                                    <ul className="flex flex-col items-start gap-2">
                                        {task.inputsKeyList.map((key) => (
                                            <Badge text={key} color="peach" key={key} />
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {!!task.outputsKeyList?.length && (
                                <div>
                                    <p className="font-body text-body-bold-s text-neutral-500 mb-2">
                                        Task Outputs
                                    </p>
                                    <ul className="flex flex-col items-start gap-2">
                                        {task.outputsKeyList.map((key) => (
                                            <Badge text={key} color="blue" key={key} />
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className="flex justify-end">
                                <Button
                                    type="primary"
                                    size="sm"
                                    text="Edit Task"
                                    state="disabled"
                                    onClick={() =>
                                        navigate(`/library/task/${taskId}`, {
                                            state: { libraryLocation: location },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Panel>
    );
};

export default TaskPanel;
