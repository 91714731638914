// EditableTextArea.js
import React, { useEffect, useRef, useState } from 'react';
import Button from 'design-system/Button/Button';
import ButtonIcon from 'design-system/ButtonIcon/ButtonIcon';
import EditIcon from 'design-system/Icons/EditIcon';
import classNames from 'classnames';
import CheckLineIcon from 'design-system/Icons/CheckLineIcon';
import EmojiButtonWithSelection from 'pages/LibraryPage/EmojiButtonWithSelection';
import { useWindowSize } from '../../hooks/useWindowSize';
import Alert from '../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../design-system/Icons';
import { defaultErrorMessage } from '../../constants/errorMessages';

const EditableTextArea = ({
    initialText,
    textStyle,
    onSave,
    emojiCode,
    onEmojiUpdated,
    icon: Icon,
    trailingButton,
    errorMessage = defaultErrorMessage,
    uneditableTextPart,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(initialText);
    const [editedText, setEditedText] = useState(initialText);
    const [isHover, setIsHover] = useState(false);
    const [isErrorWhileSaving, setIsErrorWhileSaving] = useState(false);
    const textAreaRef = useRef(null);
    const { width } = useWindowSize();

    useEffect(() => {
        setText(initialText);
    }, [initialText]);

    const handleSave = async () => {
        const currentText = text;

        setText(editedText);
        setIsEditing(false);
        setIsHover(false);

        const isError = await onSave(editedText); // If you want to verify that an error did not occur in request in case of an error (in the catch block), add "return true". If it is not added, the function will behave as before (the returned value would be undefined).
        if (isError) {
            // if error show error Alert and return text to previous (correct) value
            setText(currentText);
            setEditedText(currentText);
            setIsErrorWhileSaving(true);
        }
    };

    const resetText = () => {
        setEditedText(text);
        setIsEditing(false);
        setIsHover(false);
    };

    const inputClassName = classNames(
        'w-full px-[8px] py-[4px] focus:outline-0 rounded-[10px] resize-none shadow-inset-1 shadow-purple-500'
    );

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0px';
            const scrollHeight = textAreaRef.current.scrollHeight;

            textAreaRef.current.style.height = scrollHeight + 'px';
        }
    }, [textAreaRef, editedText]);

    return (
        <div className="w-full">
            <div className="flex flex-row items-center gap-x-2 gap-y-1 max-[435px]:items-start max-[435px]:flex-col">
                <div className="flex flex-row items-center space-x-2 w-full">
                    {emojiCode && (
                        <EmojiButtonWithSelection
                            initiallySelectedEmoji={emojiCode}
                            onEmojiUpdated={onEmojiUpdated}
                        />
                    )}
                    {Icon && (
                        <div className="w-8 h-8 min-w-[32px] bg-neutral-100 rounded-2 flex justify-center items-center">
                            <Icon color="#1F2125" width={16} height={16} />
                        </div>
                    )}
                    <div
                        className={`${textStyle} w-full flex-grow-1 min-w-[150px]`}
                        onMouseEnter={() => {
                            setIsHover(true);
                        }}
                        onMouseLeave={() => {
                            setIsHover(false);
                        }}
                    >
                        <div
                            className={`w-auto h-auto flex items-center ${
                                isEditing ? 'block' : 'sr-only'
                            }`}
                        >
                            <textarea
                                autoFocus={isEditing}
                                value={editedText}
                                rows={1}
                                ref={textAreaRef}
                                className={inputClassName}
                                // onBlur={() => setText(initialText)} TODO: figure out how to get the on Blur to work without also disabling the buttons
                                onChange={(e) => setEditedText(e.target.value)}
                            />
                        </div>
                        {!isEditing && (
                            <div className={`w-full relative`}>
                                {text && (
                                    <p className="w-full" onClick={() => setIsEditing(true)}>
                                        {text} {uneditableTextPart ? ` ${uneditableTextPart}` : ''}
                                    </p>
                                )}
                                {!text && <p className="w-full text-transparent">Placeholder</p>}
                                {isHover && (
                                    <div className="absolute top-0 right-0">
                                        <ButtonIcon
                                            type="secondary"
                                            size="xs"
                                            icon={EditIcon}
                                            onClick={() => {
                                                setIsEditing(true);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {trailingButton && width > 435 && (
                    <div
                        style={{
                            minWidth: trailingButton.minWidth ? trailingButton.minWidth : 'auto',
                        }}
                    >
                        <Button
                            type={trailingButton.type || 'neutral'}
                            size={trailingButton.size || 'xs'}
                            text={trailingButton.text}
                            leadingIcon={trailingButton.leadingIcon}
                            onClick={trailingButton.onClick}
                        />
                    </div>
                )}
            </div>
            {isEditing && (
                <div
                    className="flex flex-row space-x-2 mt-[4px] justify-end"
                    style={{
                        marginRight:
                            trailingButton?.minWidth && width > 435
                                ? `calc(${trailingButton?.minWidth} + 8px)`
                                : '0',
                    }}
                >
                    <Button
                        type="neutral"
                        size="xs"
                        text="Cancel"
                        onClick={(e) => {
                            e.stopPropagation();
                            resetText();
                        }}
                    />
                    <Button
                        type="secondary"
                        size="xs"
                        text="Save"
                        leadingIcon={CheckLineIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleSave();
                        }}
                    />
                </div>
            )}
            {trailingButton && width <= 435 && (
                <div className="mt-3">
                    <Button
                        type={trailingButton.type || 'neutral'}
                        size={trailingButton.size || 'xs'}
                        text={trailingButton.text}
                        leadingIcon={trailingButton.leadingIcon}
                        onClick={trailingButton.onClick}
                    />
                </div>
            )}
            {isErrorWhileSaving && (
                <Alert
                    status="critical"
                    message={errorMessage}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={4000}
                    handleClose={() => {
                        setIsErrorWhileSaving(false);
                    }}
                />
            )}
        </div>
    );
};

export default EditableTextArea;
