import { DateTime } from 'luxon';
import { DEFAULT_LIMIT } from '../hooks/useCustomPagination';
import { threadStatusBadge } from '../constants/threads';

export const transformDatasetsDataForTable = (results, page, total) => {
    const result = {
        columns: [
            {
                header: 'Name',
                accessor: 'name',
                type: 'text',
            },
            {
                header: 'Created at',
                accessor: 'createdAt',
                type: 'text',
                fixedWidth: 225,
            },
            {
                header: 'Organization',
                accessor: 'organization',
                type: 'text',
                fixedWidth: 230,
            },
            {
                header: 'Last run at',
                accessor: 'lastRunAt',
                type: 'text',
                fixedWidth: 225,
            },
            {
                header: 'Test Runs',
                accessor: 'testRuns',
                type: 'text',
                fixedWidth: 130,
            },
        ],
        rows: [],
        pagination: {
            page: page,
            limit: DEFAULT_LIMIT,
            totalItems: total,
        },
    };

    results.map((item) => {
        const createdAt = DateTime.fromISO(item.created_at).toFormat('MM/dd/yyyy, hh:mm a');
        const lastRunAt = item.last_run_at
            ? DateTime.fromISO(item.last_run_at).toFormat('MM/dd/yyyy, hh:mm a')
            : 'No runs yet';
        const testRuns = !!item.test_runs
            ? `${item.test_runs} run${item.test_runs > 1 ? 's' : ''}`
            : 'n/a';

        const row = {
            id: item.id,
            name: item.name,
            createdAt,
            organization: item.organization.name,
            organizationId: item.organization.id,
            lastRunAt,
            testRuns,
            langsmithUrl: item.langsmith_url,
            inputKeys: item.input_keys,
            outputKeys: item.output_keys,
            description: item.description,
        };

        result.rows.push(row);
    });

    return result;
};

export const transformTestRunsDataForTable = (results, page, total) => {
    const result = {
        columns: [
            {
                header: 'Run Name',
                accessor: 'name',
                type: 'text',
            },
            {
                header: 'Created at',
                accessor: 'createdAt',
                type: 'text',
                fixedWidth: 225,
            },
            {
                header: 'Template Version',
                accessor: 'templateVersion',
                type: 'text',
            },
            {
                header: 'Dataset',
                accessor: 'dataset',
                type: 'text',
            },
            {
                header: 'Status',
                accessor: 'status',
                type: 'badge',
                fixedWidth: 125,
            },
        ],
        rows: [],
        pagination: {
            page: page,
            limit: DEFAULT_LIMIT,
            totalItems: total,
        },
    };

    results.map((item) => {
        const createdAt = DateTime.fromISO(item.created_at).toFormat('MM/dd/yyyy, hh:mm a');

        const row = {
            id: item.id,
            name: item.name,
            createdAt,
            templateVersion: item.goal_template_version.name,
            dataset: item.dataset.name,
            status: threadStatusBadge[item.status]?.text,
            badgesColorsConfigObj: {
                status: threadStatusBadge[item.status]?.color || 'blue',
            },
            langsmithUrl: item.langsmith_url,
        };

        result.rows.push(row);
    });

    return result;
};
