import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import ExistingJobDetails from '../ExistingJobDetails/ExistingJobDetails';
import NewJobBlock from '../NewJobBlock/NewJobBlock';

const JobPanelMainContent = ({
    agentId,
    jobDataHookResponse,
    setJobsHistoryList,
    newJobName,
    mappedDocsLabelsState,
}) => {
    const { jobId } = useParams();

    const location = useLocation();
    const jobSubmissionExpanded = !!location.state?.isJobSubmissionDetailsExpanded;

    const { jobData } = jobDataHookResponse;

    const [isExpanded, setIsExpanded] = useState({
        jobSubmissionDetails: false,
        triggeredTasksDetails: false,
    });

    useEffect(() => {
        setIsExpanded({
            jobSubmissionDetails: jobSubmissionExpanded,
            triggeredTasksDetails: false,
        });
    }, [jobId]);

    const toggleSectionExpand = useCallback((sectionName) => {
        setIsExpanded((prevState) => ({ ...prevState, [sectionName]: !prevState[sectionName] }));
    }, []);

    return (
        <div className="h-[calc(100%-122px)] md:h-[calc(100%-62px)] w-full flex flex-col">
            <div className="h-full overflow-auto">
                <div className="w-full max-w-[1010px] px-4 xs:px-5 pt-4 sm:pt-5 pb-6 mx-auto min-h-full h-full">
                    {agentId && (
                        <NewJobBlock
                            key={`new_job_${agentId}`}
                            agentId={agentId}
                            jobDataHookResponse={jobDataHookResponse}
                            isExpanded={isExpanded}
                            toggleSectionExpand={toggleSectionExpand}
                            setJobsHistoryList={setJobsHistoryList}
                            newJobName={newJobName}
                            mappedDocsLabelsState={mappedDocsLabelsState}
                        />
                    )}

                    {jobData && !agentId && (
                        <ExistingJobDetails
                            jobData={jobData}
                            isExpanded={isExpanded}
                            toggleSectionExpand={toggleSectionExpand}
                            mappedDocsLabels={mappedDocsLabelsState[0]}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default JobPanelMainContent;
