import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import useSWR from 'swr';

import { API } from 'constants';
import client from '../../../services/report-api';

import { STATUS } from '../../../constants/statuses';

import Loading from '../../../components/Loading';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import MilestonesSection from '../JobDetailPage/MilestonesSection/MilestonesSection';
import ProjectDetailPageHeader from './ProjectDetailPageHeader/ProjectDetailPageHeader';
import RenameProjectModal from '../RenameProjectModal/RenameProjectModal';
import ConfirmArchiveProjectModal from '../ConfirmArchiveProjectModal/ConfirmArchiveProjectModal';

const fetcher = (url) => client.get(url).then((res) => res.data);

const ProjectDetailPage = () => {
    const { projectId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from ?? '/projects';

    const [shouldRefresh, setShouldRefresh] = useState(true); // if project.status === 'started' auto polling is enabled

    const requestEndpoint = `${API.ROUTES.report.project}${projectId}/`;
    const {
        data: projectDetail,
        error,
        mutate,
    } = useSWR(requestEndpoint, fetcher, {
        refreshInterval: shouldRefresh ? 1000 : 0,
    });

    // column or row view
    const [view, setView] = useState('column');
    const [actionModal, setActionModal] = useState({ action: null });

    useEffect(() => {
        if (projectDetail) {
            setShouldRefresh(projectDetail.status === STATUS.started);
        }
    }, [projectDetail]);

    if (error) {
        navigate(backLinkHref);
    }

    useDocumentTitle(projectDetail?.name);

    const pageContainerClassNames = classNames(
        'page-position bg-white flex flex-col hide-scrollbar',
        view === 'row' && 'overflow-y-auto'
    );

    return (
        <div className={pageContainerClassNames}>
            {projectDetail && (
                <>
                    <ProjectDetailPageHeader
                        projectDetail={projectDetail}
                        backLinkHref={backLinkHref}
                        setActionModal={setActionModal}
                    />

                    <MilestonesSection
                        milestones={projectDetail.milestones}
                        view={view}
                        setView={setView}
                        tabLabel="Connected Jobs"
                    />

                    {actionModal.action === 'rename' && (
                        <RenameProjectModal
                            projectId={projectId}
                            projectName={projectDetail.name}
                            mutate={mutate}
                            onClose={() => setActionModal({ action: null })}
                        />
                    )}

                    {actionModal.action === 'archive' && (
                        <ConfirmArchiveProjectModal
                            projectId={projectId}
                            projectName={projectDetail.name}
                            backLinkHref={backLinkHref}
                            onClose={() => setActionModal({ action: null })}
                        />
                    )}
                </>
            )}

            {!projectDetail && (
                <div className="flex-grow flex justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ProjectDetailPage;
