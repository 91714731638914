import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { changeItemsOrderUsingDragAndDrop } from '../helpers/changeItemsOrderUsingDragAndDrop';

export const useDraggableReorderableItem = ({ itemType, item, setData }) => {
    const itemRef = useRef(null);
    const dragIconRef = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: itemType,
        item: { order: item.order },
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover(draggingItem, monitor) {
            if (!itemRef.current) {
                return;
            }

            const dragOrder = draggingItem.order;
            const hoverOrder = item.order;

            if (dragOrder === hoverOrder) {
                return;
            }

            const hoverBoundingRect = itemRef.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragOrder < hoverOrder && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragOrder > hoverOrder && hoverClientY > hoverMiddleY) {
                return;
            }

            changeItemsOrderUsingDragAndDrop(dragOrder, hoverOrder, setData);
            draggingItem.order = hoverOrder;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: itemType,
        item: { order: item.order },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(dragIconRef);
    drop(preview(itemRef));

    return {
        itemRef,
        dragIconRef,
        isDragging,
        handlerId,
    };
};
