import React, { useState } from 'react';

import * as API from '../../../../constants/api';
import client from '../../../../services/library-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { removeRedundantFieldsFromOutput } from '../../../../helpers/taskPlaygroundUtils';

import Modal from '../../../../design-system/Modal/Modal';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import { Button } from '../../../../design-system';
import { mergeExistingValuesIntoNewOutputs } from '../../../../helpers/determineInputs';

const DeleteTaskOutputConfirmModal = ({ outputId, outputs, taskId, setOutputs, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const outputToDelete = outputs.find((output) => output.id === outputId);

    const handleDeleteOutput = async () => {
        try {
            setIsLoading(true);
            const requestBody = {
                output_variables: [
                    ...outputs.reduce((acc, item) => {
                        if (item.id === outputId) {
                            return acc;
                        } else {
                            const output = removeRedundantFieldsFromOutput(item);
                            return [...acc, output];
                        }
                    }, []),
                ],
            };
            const { data } = await client.patch(
                `${API.ROUTES.library.task}${taskId}/`,
                requestBody
            );
            setOutputs((existingOutputs) =>
                mergeExistingValuesIntoNewOutputs({
                    existingOutputs,
                    newOutputs: data.outputs,
                    addValueFromExistingOutputs: true,
                })
            );

            onClose();
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
        }
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="p-2">
                <h2 className="text-neutral-400 font-heading-bold text-heading-bold-m mb-[40px] text-center">
                    Are you sure you want to delete {outputToDelete?.label}?
                </h2>
                <div className="flex gap-8 justify-center">
                    <Button type="link" size="sm" text="No, Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Yes, Delete"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleDeleteOutput}
                    />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default DeleteTaskOutputConfirmModal;
