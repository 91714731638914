import React, { useState } from 'react';

import { handleCopy } from '../../../../helpers/handleCopy';

import Alert from '../../../Alert/Alert';
import ToolTip from '../../../ToolTip/ToolTip';
import { Button, ButtonIcon } from '../../../index';
import { ArrowDownSLineIcon, ArrowUpSLineIcon, FileCopyLineIcon } from '../../../Icons';

const CreatedPromptDefaultView = ({ promptData }) => {
    const { id, name, description, default_version } = promptData;

    const prompt = default_version?.messages[0]?.content;

    const [isExpanded, setIsExpanded] = useState(false);
    const [copyAlert, setCopyAlert] = useState(null);

    const expandButton = isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    return (
        <>
            <div className="px-5 pt-4 pb-6">
                <h4 className="font-body-bold text-body-bold-xl text-neutral-500 mb-3">{name}</h4>
                <p className="font-body text-body-regular-m text-neutral-500">{description}</p>
            </div>

            {isExpanded && (
                <div className="px-5 py-4 bg-neutral-50/50 border-t-1 border-neutral-200">
                    <p className="font-body-bold text-body-bold-s text-neutral-500 pb-3">Prompt</p>
                    <p className="font-body text-body-regular-s text-neutral-500 whitespace-pre-wrap">
                        {prompt}
                    </p>
                </div>
            )}

            <div className="px-4 py-2.5 flex flex-col-reverse items-start min-[430px]:flex-row min-[430px]:items-center justify-between gap-1 min-[1060px]:gap-2 border-t-1 border-neutral-200 rounded-b-2">
                <Button
                    type="link"
                    size="xs"
                    text={isExpanded ? 'View Less' : 'View More'}
                    trailingIcon={expandButton}
                    onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
                />

                <div className="flex items-center gap-4 min-[430px]:gap-1 min-[450px]:gap-4 lg:gap-1 min-[1035px]:gap-2 min-[1060px]:gap-4">
                    <ToolTip
                        text="Copy Prompt"
                        position="top-left"
                        shift="2px"
                        useInternalHoverStateToShowToolTip
                    >
                        <ButtonIcon
                            type="neutral"
                            size="sm"
                            icon={FileCopyLineIcon}
                            onClick={() => handleCopy(prompt, setCopyAlert)}
                        />
                    </ToolTip>

                    <Button
                        type="secondary"
                        size="sm"
                        text="Start New Thread"
                        onClick={() => window.open(`/assistant?tab=chats&prompt=${id}`, '_blank')}
                    />
                </div>
            </div>

            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </>
    );
};

export default CreatedPromptDefaultView;
