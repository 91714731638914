import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Badge, ButtonIcon } from '../../design-system';
import PlayCircleFillIcon from '../../design-system/Icons/PlayCircleFillIcon';
import { More2FillIcon } from '../../design-system/Icons';
import MessageMoreOptionsPopup from '../../pages/InboxPage/MessageMoreOptionsPopup/MessageMoreOptionsPopup';

const MessageCard = ({
    item,
    selected = false,
    onCardClick,
    setData,
    requestKeysToMutate,
    setNextMessageAfterMessageApproval,
}) => {
    const { id, subject, is_read, status, process, created_at } = item;
    const formattedTime = DateTime.fromISO(created_at).toRelative();

    const [isCardHovered, setIsCardHovered] = useState(false);
    const [isMoreOptionPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);

    const openMoreOptionsPopup = (e) => {
        e?.stopPropagation();
        setIsMoreOptionsPopupOpened(true);
    };

    return (
        <div
            className={`w-full p-3 rounded-2 bg-white border-2 cursor-pointer ${
                selected ? 'border-transparent shadow-l4 shadow-purple-300' : 'border-neutral-100'
            }`}
            onClick={() => onCardClick(id)}
            onMouseEnter={() => setIsCardHovered(true)}
            onMouseLeave={() => setIsCardHovered(false)}
        >
            <div className={`flex items-center justify-between gap-3 min-h-[28px] mb-0.5`}>
                <p
                    className={`font-body text-black flex-grow truncate customized-list-dot ${
                        is_read
                            ? 'text-body-regular-xs before:bg-neutral-100'
                            : 'text-body-bold-xs before:bg-neutral-400'
                    }`}
                >
                    {subject}
                </p>
                {status === 'pending' && (
                    <div className="relative min-h-full">
                        <div
                            className={`${
                                isCardHovered || isMoreOptionPopupOpened ? 'block' : 'hidden'
                            }`}
                        >
                            <ButtonIcon
                                type="neutral"
                                size="xs"
                                icon={More2FillIcon}
                                onClick={openMoreOptionsPopup}
                            />
                        </div>

                        {isMoreOptionPopupOpened && (
                            <MessageMoreOptionsPopup
                                message={item}
                                setData={setData}
                                requestKeysToMutate={requestKeysToMutate}
                                setNextMessageAfterMessageApproval={
                                    setNextMessageAfterMessageApproval
                                }
                                onClose={() => setIsMoreOptionsPopupOpened(false)}
                            />
                        )}
                    </div>
                )}
            </div>

            <div className="flex items-center gap-3 justify-between">
                {process && (
                    <div className="w-[calc(100%-112px)]">
                        <Badge
                            text={process.name}
                            color="neutral"
                            leadingIcon={PlayCircleFillIcon}
                            leadingIconColor={process.icon_color}
                        />
                    </div>
                )}
                <p className="font-body text-body-regular-xs text-neutral-300 w-[100px] flex justify-end ml-auto">
                    {formattedTime}
                </p>
            </div>
        </div>
    );
};

export default MessageCard;
