import React, { useState } from 'react';
import { TASK_TYPES } from '../../../../constants/library';

import { Button } from '../../../../design-system';
import GenericOutputs from '../GenericOutputs/GenericOutputs';
import CodeTaskOutputs from '../CodeTaskOutputs/CodeTaskOutputs';
import CollapsableContainer from '../../../../components/CollapsableContainer/CollapsableContainer';
import OutputRatingControls from '../../../../components/OutputRatingControls/OutputRatingControls';
import AdditionalResultDataModal from '../AdditionalResultDataModal/AdditionalResultDataModal';

const OutputBlock = ({
    outputs,
    setOutputs,
    taskType,
    resultId,
    outputRating,
    setOutputRating,
    additionalResultData,
    hasEditAccess,
    taskId,
    isExpanded,
    toggleCollapsed,
}) => {
    const [additionalResultDataModalOpened, setAdditionalResultDataModalOpened] = useState(false);

    const title = taskType === TASK_TYPES.code ? 'Output Variables' : 'Output';

    const topRightContent = (
        <div className="flex items-center gap-3" onClick={(e) => e.stopPropagation()}>
            {resultId && (
                <Button
                    type="link"
                    size="xs"
                    text="View Data"
                    onClick={() => setAdditionalResultDataModalOpened(true)}
                />
            )}
            {outputRating !== null && outputRating !== undefined && resultId && (
                <OutputRatingControls
                    outputRating={outputRating}
                    updateOutputRating={setOutputRating}
                    resultId={resultId}
                    useExternalState
                    view="buttonIcon"
                    size="xs"
                />
            )}
        </div>
    );

    return (
        <>
            {taskType !== TASK_TYPES.code && (
                <div className="w-full border-b border-neutral-200 flex flex-col gap-5 pb-5">
                    <div className="flex justify-between items-center gap-3">
                        <h3 className="font-body text-body-bold-m text-neutral-500 uppercase">
                            {title}
                        </h3>
                        {topRightContent}
                    </div>

                    <GenericOutputs outputs={outputs} taskType={taskType} />
                </div>
            )}

            {taskType === TASK_TYPES.code && (
                <CollapsableContainer
                    title={title}
                    isExpanded={isExpanded.outputs}
                    toggleExpand={() => toggleCollapsed('outputs')}
                    topRightContent={topRightContent}
                >
                    <CodeTaskOutputs
                        outputs={outputs}
                        setOutputs={setOutputs}
                        taskId={taskId}
                        hasEditAccess={hasEditAccess}
                    />
                </CollapsableContainer>
            )}
            {additionalResultDataModalOpened && (
                <AdditionalResultDataModal
                    resultId={resultId}
                    data={additionalResultData}
                    onClose={() => setAdditionalResultDataModalOpened(false)}
                />
            )}
        </>
    );
};

export default OutputBlock;
