import React, { useState } from 'react';

import * as API from '../../../constants/api';
import client from '../../../services/knowledge-api';

import { useFormState } from '../../../hooks/useFormState';
import { mutateDataAfterRenamingBase } from '../../../helpers/basesUtils';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../../design-system/Modal/Modal';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import Alert from '../../../design-system/Alert/Alert';
import { Button, Input } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const RenameBaseModal = ({ id, name, requestKeysToMutate, onClose }) => {
    const { formData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } = useFormState(
        { name }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleRename = async () => {
        if (!formData.name) {
            setFieldErrorMessages({ name: emptyFieldErrorMessage });
            return;
        }

        try {
            setIsLoading(true);

            const { data } = await client.patch(`${API.ROUTES.knowledge.base}${id}/`, {
                name: formData.name,
            });
            mutateDataAfterRenamingBase({ requestKeysToMutate, id, updatedName: data.name });

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <Modal onClose={onClose} size="medium" resetPadding>
            <ModalHeader onClose={onClose} title="Rename Base" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                <Input
                    size="md"
                    value={formData.name}
                    name="name"
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                    placeholder={name}
                />

                <div className="flex items-center justify-between">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="md"
                        text="Save"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleRename}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default RenameBaseModal;
