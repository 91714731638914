import React from 'react';

const FullscreenExitLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24">
            <path
                d="M18 7H22V9H16V3H18V7ZM8 9H2V7H6V3H8V9ZM18 17V21H16V15H22V17H18ZM8 15V21H6V17H2V15H8Z"
                fill={props.color}
            />
        </svg>
    );
};

export default FullscreenExitLineIcon;
