import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

ProgressBar.propTypes = {
    size: PropTypes.oneOf(['lg', 'md', 'sm']),
    color: PropTypes.oneOf(['purple', 'lime', 'black']),
    progressPercentage: PropTypes.number.isRequired,
    progressRounded: PropTypes.bool,
};

function ProgressBar(props) {
    const {
        size = 'md',
        progressPercentage = 100,
        color = 'purple',
        progressRounded = false,
    } = props;

    const progressBarHeight = {
        sm: 'h-1',
        md: 'h-2',
        lg: 'h-2.5',
    };

    const progressBgColor = {
        purple: 'bg-purple-500',
        lime: 'bg-lime-500',
        black: 'bg-neutral-500',
    };

    const progressClassName = classNames(
        'transition-all duration-500',
        progressBgColor[color],
        progressBarHeight[size],
        {
            'rounded-full': progressRounded,
        }
    );

    return (
        <div
            className={`w-full bg-neutral-200 rounded-full overflow-hidden ${progressBarHeight[size]}`}
        >
            <div className={progressClassName} style={{ width: `${progressPercentage}%` }}></div>
        </div>
    );
}

export default ProgressBar;
