import React, { useEffect, useState } from 'react';
import { STATUS_TAB_KEYS } from '../../../constants/inbox';

import InboxStatusTabs from '../InboxStatusTabs/InboxStatusTabs';
import AddLineIcon from '../../../design-system/Icons/AddLineIcon';
import AddProcessFiltersPopup from '../../../components/AddProcessFiltersPopup/AddProcessFiltersPopup';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import { Badge, SvgIcon, Toggle } from '../../../design-system';

const InboxSidebar = ({
    selectedStatusTab,
    onTabChanged,
    areReadMessagesShown,
    toggleShownMessagesReadState,
    selectedFilterOptions,
    setSelectedFilterOptions,
    resetCurrentData,
}) => {
    const [isFilterPopupOpened, setIsFilterPopupOpened] = useState(false);
    const [selectedFiltersInfo, setSelectedFiltersInfo] = useState([]);

    const closeFilterPopup = (e) => {
        e?.stopPropagation();
        setIsFilterPopupOpened(false);
    };

    const handleAddFilterOption = (process) => {
        resetCurrentData();

        setSelectedFilterOptions((prevFilters) => [...prevFilters, process.id]);
        setSelectedFiltersInfo((prevFilters) => [...prevFilters, process]);
    };

    const handleRemoveOption = (process) => {
        resetCurrentData();

        setSelectedFilterOptions((prevFilters) =>
            prevFilters.filter((option) => option !== process.id)
        );
        setSelectedFiltersInfo((prevFilters) =>
            prevFilters.filter((option) => option.id !== process.id)
        );
    };

    useEffect(() => {
        setSelectedFiltersInfo([]);
    }, [selectedStatusTab]);

    return (
        <div className="min-[700px]:w-[230px] min-[700px]:min-w-[230px] min-[800px]:w-[260px] min-[800px]:min-w-[260px] py-6 px-4 bg-white border-b-1 min-[700px]:border-r-1 border-neutral-200 flex flex-col gap-4">
            <h1 className="font-heading-bold text-heading-bold-m text-black">Inbox</h1>
            <div className="pb-4 border-b-1 border-neutral-200">
                <InboxStatusTabs selectedTab={selectedStatusTab} onTabChanged={onTabChanged} />
            </div>
            {selectedStatusTab !== STATUS_TAB_KEYS.completed && (
                <div className="flex items-center justify-between gap-3">
                    <p className="font-body-bold text-body-bold-xs uppercase text-neutral-300">
                        Show read
                    </p>
                    <Toggle
                        size="sm"
                        name="is_read"
                        isSelected={areReadMessagesShown}
                        onChange={toggleShownMessagesReadState}
                    />
                </div>
            )}
            <div className="relative">
                <button
                    type="button"
                    onClick={() => setIsFilterPopupOpened(true)}
                    className="w-full py-1.5 flex items-center justify-between gap-2 font-body-bold text-body-bold-xs uppercase text-neutral-300"
                >
                    Filter by Agent
                    <SvgIcon color="#5E6470" icon={AddLineIcon} size="medium" />
                </button>
                {isFilterPopupOpened && (
                    <AddProcessFiltersPopup
                        selectedOptions={selectedFilterOptions}
                        handleAddFilterOption={handleAddFilterOption}
                        handleRemoveOption={handleRemoveOption}
                        onClose={closeFilterPopup}
                    />
                )}
            </div>
            {!!selectedFiltersInfo?.length && (
                <div className="flex flex-col items-start gap-3 px-2 py-1">
                    {selectedFiltersInfo.map((process) => (
                        <Badge
                            color="neutral"
                            text={process.name}
                            leadingIconColor={process.iconColor}
                            leadingIcon={PlayCircleFillIcon}
                            removeable
                            handleRemove={() => handleRemoveOption(process)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default InboxSidebar;
