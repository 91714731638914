import React from 'react';
import { useNavigate } from 'react-router-dom';

import { formattedTabs } from '../HistorySidePanel/HistorySidePanel';
import { handleOpenStartNewJobModal } from '../../../helpers/handleOpenStartNewJobModal';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import ToolTip from '../../../design-system/ToolTip/ToolTip';
import ContractLeftLineIcon from '../../../design-system/Icons/ContractLeftLineIcon';
import EditBoxLineIcon from '../../../design-system/Icons/EditBoxLineIcon';
import { ButtonIcon, Tabs } from '../../../design-system';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const HistorySidePanelTopControls = ({
    collapsePanel,
    activeTab,
    activeTabIndex,
    handleTabChange,
}) => {
    const navigate = useNavigate();

    const { isDesktop } = useResponsiveBreakpoints();

    const handleAction = (cb) => {
        cb();

        if (!isDesktop) {
            collapsePanel();
        }
    };

    const openNewChat = () => {
        navigate(`/assistant?tab=${activeTab}`);
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
                <ToolTip
                    text="Collapse"
                    position="top-left"
                    useInternalHoverStateToShowToolTip
                    shift="4px"
                >
                    <ButtonIcon
                        type="link"
                        size="sm"
                        icon={ContractLeftLineIcon}
                        onClick={collapsePanel}
                    />
                </ToolTip>

                <div className="flex items-center gap-4">
                    <ToolTip
                        text="New Job"
                        position="top-right"
                        useInternalHoverStateToShowToolTip
                        shift="4px"
                    >
                        <ButtonIcon
                            type="link"
                            size="sm"
                            icon={FlashlightFillIcon}
                            onClick={() => handleAction(handleOpenStartNewJobModal)}
                        />
                    </ToolTip>

                    <ToolTip
                        text="New Chat"
                        position="top-right"
                        useInternalHoverStateToShowToolTip
                        shift="4px"
                    >
                        <ButtonIcon
                            type="link"
                            size="sm"
                            icon={EditBoxLineIcon}
                            onClick={() => handleAction(openNewChat)}
                        />
                    </ToolTip>
                </div>
            </div>

            <Tabs tabs={formattedTabs} tabIndex={activeTabIndex} onTabChanged={handleTabChange} />
        </div>
    );
};

export default HistorySidePanelTopControls;
