import { Button } from 'design-system';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import apiClient from 'services/user-api';
import { mutate } from 'swr';
import { API } from 'constants';

const initialOptions = {
    method: 'POST',
    url: API.ROUTES.user.logout,
    headers: { 'Content-Type': 'application/json' },
};

export default function Logout() {
    const [result, setResult] = useState({ success: false, message: null });
    // const { ws } = useWebSocket() || {};
    const handleSubmit = async () => {
        const authToken = localStorage.getItem('authKey');
        const options = {
            ...initialOptions,
            data: {},
            headers: {
                ...initialOptions.headers,
                Authorization: `Token ${authToken}`,
            },
        };

        const response = await apiClient(options);
        setResult({
            success: response.status === 200,
            message: response.data.detail,
        });
        localStorage.setItem('authKey', null);
        mutate('/user/view/', null);
        // if (ws) {
        //   ws.close();
        //   console.log("closed");
        // }
    };

    if (result.success) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="Logout py-[80px] mx-auto">
            <h1 className="font-heading-bold text-heading-bold-m text-center mb-[32px]">
                Are you sure you want to logout?
            </h1>
            <div className="flex justify-center">
                <Button type="primary" text="Yes, logout" size="sm" onClick={handleSubmit} />
            </div>
            {result.success && (
                <section>
                    <h1>Logout Results</h1>
                    <h2>{result.message}</h2>
                </section>
            )}
        </div>
    );
}
