import React from 'react';

import BotCard from '../BotCard/BotCard';
import Loading from '../../../components/Loading';
import BotsEmptyView from '../BotsEmptyView/BotsEmptyView';
import PaginatedContainer from '../../../components/PaginatedContainer/PaginatedContainer';

const BotsPaginatedContainer = ({
    page,
    botsHookResponse,
    requestKeysToMutate,
    setIsCreateBotModalOpen,
}) => {
    const { data: bots, total, count, loading } = botsHookResponse;

    if (!total && loading) {
        return (
            <div className="pt-5">
                <Loading />
            </div>
        );
    }

    const emptyStateShown = !total && !bots?.length && !loading;

    return (
        <PaginatedContainer page={page} count={count} total={total}>
            {emptyStateShown && <BotsEmptyView setIsCreateBotModalOpen={setIsCreateBotModalOpen} />}

            {!emptyStateShown && !!bots.length && (
                <ul className="grid grid-cols-1 gap-x-5 gap-y-5 md:grid-cols-2 l:grid-cols-3 xl:gap-x-5 3xl:grid-cols-4 items-stretch">
                    {bots.map((bot) => (
                        <BotCard key={bot.id} bot={bot} requestKeysToMutate={requestKeysToMutate} />
                    ))}
                </ul>
            )}
        </PaginatedContainer>
    );
};

export default BotsPaginatedContainer;
