export const parseThreadsSearchParams = (searchParams) => {
    const page = Number.parseInt(searchParams.get('page'));
    const sort = searchParams.get('sort');
    const process = searchParams.get('process');
    const search = searchParams.get('search');
    const tab = searchParams.get('tab');

    const result = { sort };

    if (page) {
        result.page = page;
    }
    if (search) {
        result.search = search;
    }
    if (process) {
        result.process = process;
    }
    if (tab) {
        result.tab = tab;
    }

    return result;
};
