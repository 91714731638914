import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_LIMIT } from '../../hooks/useCustomPagination';

import { ButtonIcon } from '../index';
import ArrowLeftSLineIcon from '../Icons/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from '../Icons/ArrowRightSLineIcon';

PaginationBar.propTypes = {
    page: PropTypes.number.isRequired,
    limit: PropTypes.number,
    total: PropTypes.number.isRequired,
    handlePreviousClick: PropTypes.func.isRequired,
    handleNextClick: PropTypes.func.isRequired,
};

function PaginationBar({
    page,
    limit = DEFAULT_LIMIT,
    total,
    handlePreviousClick,
    handleNextClick,
}) {
    const start = (page - 1) * limit + 1;

    const isLastPage = total - limit * page <= 0;
    const end = isLastPage ? total : start + limit - 1;

    const previousButtonState = start === 1 ? 'disabled' : 'default';
    const nextButtonState = isLastPage ? 'disabled' : 'default';

    return (
        <div className="py-2 px-4 flex items-center justify-end">
            <div className="flex items-center gap-1.5">
                <p className="font-body text-body-regular-xs text-neutral-300">
                    {start} - {end} of {total}
                </p>
                <ButtonIcon
                    type="neutral"
                    size="xs"
                    icon={ArrowLeftSLineIcon}
                    state={previousButtonState}
                    onClick={handlePreviousClick}
                />
                <ButtonIcon
                    type="neutral"
                    size="xs"
                    icon={ArrowRightSLineIcon}
                    state={nextButtonState}
                    onClick={handleNextClick}
                />
            </div>
        </div>
    );
}

export default PaginationBar;
