import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const DatasetsMoreOptionsPopup = ({
    data,
    onClose,
    topPosition = '40px',
    setDatasetActionModal,
}) => {
    const { goalTemplateId } = useParams();

    const navigate = useNavigate();

    const navigateToCreateUnitTestPage = () => {
        navigate(
            `/templates/goal/${goalTemplateId}/test?organization=${data.organizationId}&dataset=${data.id}`
        );
    };

    const openActionModal = (action) => {
        setDatasetActionModal({ action, data });
        onClose();
    };

    return (
        <div className="absolute right-[4px] sm:right-[36px] z-30" style={{ top: topPosition }}>
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="flashlightFillIcon"
                            text="New Test Run"
                            onClick={navigateToCreateUnitTestPage}
                        />
                        <ListOption
                            leadingIconName="plusCircleIcon"
                            text="New Record"
                            onClick={() => openActionModal('addRecord')}
                        />
                        <ListOption
                            leadingIconName="databaseIcon"
                            text="Update Dataset"
                            onClick={() => openActionModal('update')}
                        />
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete Dataset"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={() => openActionModal('delete')}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default DatasetsMoreOptionsPopup;
