import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Badge, Button } from '../../../../design-system';
import { ArrowGoBackLineIcon, FlowChartIcon } from '../../../../design-system/Icons';
import FlowIcon from '../../../../design-system/Icons/FlowIcon';
import ExecutionDetailsBlock from '../ExecutionDetailsBlock/ExecutionDetailsBlock';
import ExecutionMoreOptionButton from '../ExecutionMoreOptionButton/ExecutionMoreOptionButton';

const ExecutionPageHeader = ({ execution, backlinkHref, setActionModal }) => {
    const { name, scenario } = execution;

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="w-full max-w-full bg-white">
            <div className="max-w-full py-3 px-5 sm:px-6 border-b-1 border-neutral-200 flex items-start sm:items-center justify-between gap-3">
                <div className="max-w-[calc(100%-36px)] sm:max-w-[calc(100%-200px)] lg:max-w-[calc(100%-250px)]">
                    <div className="flex items-center mb-0.5 sm:mb-0 gap-x-5">
                        <Button
                            type="link"
                            size="xs"
                            text="Back"
                            leadingIcon={ArrowGoBackLineIcon}
                            onClick={() => navigate(backlinkHref)}
                        />
                        <p className="font-body text-body-regular-s text-neutral-300">
                            Executions /
                        </p>
                    </div>

                    <div className="max-w-full flex flex-col items-start sm:flex-row sm:items-center gap-y-0.5 gap-x-2 flex-wrap overflow-hidden">
                        <p className="font-body-bold text-body-bold-s sm:text-body-bold-l">
                            {name}
                        </p>
                        <div className="max-w-full overflow-hidden">
                            <Badge
                                text={scenario.name}
                                color="neutral"
                                leadingIcon={FlowIcon}
                                leadingIconColor="#000000"
                            />
                        </div>
                    </div>
                </div>

                <div className="fixed bottom-0 left-0 right-0 px-5 pt-3 pb-6 flex flex-col bg-white z-10 sm:static sm:p-0">
                    <Button
                        type="secondary"
                        size="xs"
                        leadingIcon={FlowChartIcon}
                        text="Scenario Configuration"
                        onClick={() =>
                            navigate(`/configure/scenario/${scenario.id}`, {
                                state: { from: location },
                            })
                        }
                    />
                </div>

                <div className="sm:hidden">
                    <ExecutionMoreOptionButton setActionModal={setActionModal} />
                </div>
            </div>

            <ExecutionDetailsBlock execution={execution} setActionModal={setActionModal} />
        </div>
    );
};

export default memo(ExecutionPageHeader);
