import useSWR from 'swr';

export function getKey({ route, searchParams = {} }) {
    if (!route) {
        return null;
    }

    const urlSearchParams =
        Object.keys(searchParams)
            .map((key) => `${key}=${searchParams[key]}`)
            .join('&') || '';

    return `${route}${urlSearchParams && `?${urlSearchParams}`}`;
}

export default function useSWRRequest({ client, route, searchParams = {} }) {
    const fetcher = (url) => client.get(url).then((res) => res.data);

    const key = getKey({ route, searchParams });
    const { data, error, isLoading, mutate } = useSWR(key, fetcher);

    return { data, error, loading: isLoading, key, mutate };
}
