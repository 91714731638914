import { createContext, useEffect, useState } from 'react';

import { API } from 'constants';
import client from '../services/knowledge-api';

import { SORT_TYPES } from '../constants/sort';
import { useResponsiveBreakpoints } from '../hooks/useResponsiveBreakpoints';

export const DocsPageContext = createContext();

export const DocsPageProvider = ({ children }) => {
    const [location, setLocation] = useState(null);

    const [sidePanelFolders, setSidePanelFolders] = useState(null);
    const [expandedFolders, setExpandedFolders] = useState({});

    const [currentRequestKeyToMutate, setCurrentRequestKeyToMutate] = useState(null);

    const { isDesktop } = useResponsiveBreakpoints();

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const { data } = await client.get(API.ROUTES.knowledge.folder, {
                    params: {
                        parent_is_null: true,
                        pagination: false,
                        ordering: SORT_TYPES.alphabeticalByLabel,
                    },
                });

                setSidePanelFolders(data);
            } catch (e) {}
        };

        if (isDesktop) {
            fetchFolders();
        }
    }, [isDesktop]);

    return (
        <DocsPageContext.Provider
            value={{
                location,
                setLocation,
                expandedFolders,
                setExpandedFolders,
                sidePanelFolders,
                setSidePanelFolders,
                currentRequestKeyToMutate,
                setCurrentRequestKeyToMutate,
            }}
        >
            {children}
        </DocsPageContext.Provider>
    );
};
