import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../../services/report-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';
import { mutateJobsIndexPageDataAfterArchivingJob } from '../../../../helpers/jobsUtils';
import ConfirmDeletionModalPattern from '../../../../design-system/ConfirmDeletionModalPattern/ConfirmDeletionModalPattern';

const ConfirmArchiveJobModal = ({
    jobName,
    jobId,
    backLinkHref,
    requestKeysToMutate = [],
    setJobs,
    onClose,
}) => {
    const navigate = useNavigate();
    const {
        requestKeysToMutate: { jobs: jobsRequestKeysToMutateFromContext },
    } = useSwrRequestKeysToMutateFromContext();

    const [errorAlert, setErrorAlert] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleArchive = async () => {
        try {
            setIsLoading(true);

            await client.delete(`${API.ROUTES.report.job}${jobId}/`);
            mutateJobsIndexPageDataAfterArchivingJob(
                [...jobsRequestKeysToMutateFromContext, ...requestKeysToMutate],
                jobId
            );
            if (setJobs) {
                setJobs((prevJobs) => prevJobs?.filter((job) => job.id !== jobId) || []);
            }

            if (backLinkHref) {
                navigate(backLinkHref, { replace: true });
            }
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    return (
        <ConfirmDeletionModalPattern
            onClose={onClose}
            handleDelete={handleArchive}
            title="Confirm Archive Job"
            confirmationTextStart="Are you sure you want to archive"
            entityName={jobName}
            confirmationTextEnd="job? This job will be canceled and will no longer be accessible."
            isLoading={isLoading}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
            buttonsTheme="dark"
            customConfirmButtonText="Yes, Archive"
        />
    );
};

export default ConfirmArchiveJobModal;
