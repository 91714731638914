import React, { useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    getDeleteExtraCommand,
    getPreviewModeExtraCommand,
    getToolbarCommands,
} from './toolbarCommands';
import './richTextArea.css';
import { defaultErrorMessage } from '../../constants/errorMessages';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';

import { FileCopyLineIcon } from '../Icons';
import SvgIcon from '../SvgIcon/SvgIcon';
import ErrorWarningLineIcon from '../Icons/ErrorWarningLineIcon';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import Alert from '../Alert/Alert';
import CheckLineIcon from '../Icons/CheckLineIcon';

RichTextArea.propTypes = {
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func,
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string,
    previewOnly: PropTypes.bool,
    tipText: PropTypes.string,
    minHeight: PropTypes.number,
    autoExpand: PropTypes.bool,
    withCopyButton: PropTypes.bool,
    labelToCopyAlert: PropTypes.string, // if you don't pass label, but want to use copy button in copySuccessAlert labelToCopyAlert will be displayed
    placeholder: PropTypes.string,
    darkBorderColor: PropTypes.bool,
    openLinksInNewTab: PropTypes.bool,
    customPadding: PropTypes.string,
    markdownColor: PropTypes.string,
    withExtendedToolBar: PropTypes.bool, // extended toolbar includes "Delete" Button and move "Change Preview" Button to the left
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // is passed in handleDelete function
    handleDelete: PropTypes.func, // is required if withExtendedToolBar = true
    previewMaxHeight: PropTypes.number,
    isMobileViewActive: PropTypes.bool,
    useInternalMobileViewState: PropTypes.bool,
    onTextAreaBlur: PropTypes.func,
    additionalButtons: PropTypes.node,
};

function RichTextArea({
    value,
    setValue,
    state = 'default',
    previewOnly = false,
    isBorderHidden = false, // for preview Only Mode (show just formatted text without "frame")
    label,
    isRequired = false,
    errorMessage,
    tipText = '',
    minHeight = 150,
    autoExpand = false, // The autoExpand prop means that the textarea height will be expanded to the content height. If autoExpand is set to false, then the height is fixed, and if the content exceeds the height, a scrollbar will appear.
    withCopyButton = false,
    labelToCopyAlert,
    placeholder = 'Write here',
    darkBorderColor = false,
    openLinksInNewTab = false,
    customPadding = '',
    markdownColor = '#1F2125',
    withExtendedToolBar = false,
    id,
    handleDelete = () => {},
    previewMaxHeight,
    isMobileViewActive = false,
    useInternalMobileViewState = true,
    onTextAreaBlur = () => {},
    additionalButtons = null,
}) {
    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 640 });

    const [mode, setMode] = useState('edit');

    const [copyStatusAlert, setCopyStatusAlert] = useState({
        show: false,
        status: null,
        message: null,
    });

    const handleCopy = () => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                setCopyStatusAlert({
                    show: true,
                    status: 'positive',
                    message: `${labelToCopyAlert || label} copied to clipboard!`,
                });
            })
            .catch(() => {
                setCopyStatusAlert({
                    show: true,
                    status: 'critical',
                    message: defaultErrorMessage,
                });
            });
    };

    const editorComponentsProp = openLinksInNewTab ? { a: LinkRenderer } : {};

    const previewOnlyBorderClassNames = classNames('bg-white py-[1px]', {
        'rounded-2 border-1': !isBorderHidden,
        'border-neutral-300': darkBorderColor,
        'border-neutral-200': !darkBorderColor,
    });
    const previewOnlyBoxClassName = classNames(
        'previewOnly overflow-y-auto h-full',
        {
            withoutPadding: isBorderHidden || !!customPadding,
        },
        customPadding
    );

    const previewOnlyBoxStyles = { color: markdownColor };
    if (previewMaxHeight) {
        previewOnlyBoxStyles.maxHeight = `${previewMaxHeight}px`;
    }

    const isMobileView = useInternalMobileViewState ? isMobile : isMobileViewActive;

    const withActionButtons = withCopyButton || !!additionalButtons;

    return (
        <div className={`flex-grow flex flex-col gap-1.5 h-full`} data-color-mode="light">
            {(label || withActionButtons) && (
                <div className={`flex items-center ${label ? 'justify-between' : 'justify-end'}`}>
                    {label && (
                        <p className="font-body-bold text-body-bold-s text-neutral-500">
                            {label}
                            {isRequired && <sup className="text-red-500 leading-1 ml-0.5">*</sup>}
                        </p>
                    )}
                    {withActionButtons && (
                        <div className="flex items-center gap-2">
                            {additionalButtons}
                            {withCopyButton && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state={!value ? 'disabled' : 'default'}
                                    icon={FileCopyLineIcon}
                                    onClick={handleCopy}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}

            <div
                className={`flex-grow ${state === 'error' && 'errorState'} ${
                    state === 'disabled' && 'disabledState'
                } ${autoExpand ? 'autoExpand' : 'noAutoExpand'} ${
                    darkBorderColor && 'darkBorderColor'
                }`}
            >
                {previewOnly ? (
                    <div className={previewOnlyBorderClassNames}>
                        <div className={previewOnlyBoxClassName} style={previewOnlyBoxStyles}>
                            <MDEditor.Markdown source={value} components={editorComponentsProp} />
                        </div>
                    </div>
                ) : (
                    <MDEditor
                        height="100%"
                        minHeight={`${minHeight}px`}
                        value={value}
                        onChange={state === 'disabled' ? () => {} : setValue}
                        visibleDragbar={false}
                        preview={mode}
                        commands={getToolbarCommands(
                            withExtendedToolBar,
                            isMobileView,
                            mode,
                            setMode
                        )}
                        extraCommands={
                            withExtendedToolBar
                                ? [getDeleteExtraCommand(() => handleDelete(id))]
                                : [getPreviewModeExtraCommand(mode, setMode)]
                        }
                        textareaProps={{ placeholder: placeholder, onBlur: onTextAreaBlur }}
                        style={{ minHeight: `${minHeight}px` }}
                        previewOptions={{
                            components: editorComponentsProp,
                        }}
                    />
                )}
            </div>

            {state === 'error' && errorMessage && <ErrorMessage message={errorMessage} />}

            {tipText !== '' && state !== 'error' && (
                <div className="flex items-center gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                    <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                </div>
            )}

            {copyStatusAlert.show && (
                <Alert
                    status={copyStatusAlert.status}
                    message={copyStatusAlert.message}
                    icon={
                        copyStatusAlert.status === 'positive' ? CheckLineIcon : ErrorWarningLineIcon
                    }
                    autoCloseInMS={3000}
                    handleClose={() => setCopyStatusAlert({ show: false })}
                />
            )}
        </div>
    );
}

function LinkRenderer(props) {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}

export default RichTextArea;
