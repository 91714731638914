import React, { useState } from 'react';
import { Tabs } from '../../../../design-system';
import PublishingForm from '../PublishingForm/PublishingForm';

const UnpublishedBlock = ({ versionData, ...publishingFormProps }) => {
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <>
            <p className="font-body text-body-regular-m text-neutral-400">
                By publishing a process version, users will immediately be able to upgrade or
                downgrade to this version of the process.{' '}
                {tabIndex === 1 && 'This cannot be undone.'}
            </p>
            <div className="max-w-max">
                <Tabs
                    tabs={[{ name: 'Private' }, { name: 'Published' }]}
                    tabIndex={tabIndex}
                    onTabChanged={(_, index) => setTabIndex(index)}
                />
            </div>
            {tabIndex === 1 && (
                <PublishingForm versionData={versionData} {...publishingFormProps} />
            )}
        </>
    );
};

export default UnpublishedBlock;
