import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Badge from 'design-system/Badge/Badge';
import ButtonIcon from 'design-system/ButtonIcon/ButtonIcon';
import DeleteBin4LineIcon from 'design-system/Icons/DeleteBin4LineIcon';
import CheckboxCircleLineIcon from 'design-system/Icons/CheckboxCircleLineIcon';
import Button from 'design-system/Button/Button';
import EyeIcon from '../Icons/EyeIcon';
import EditLineIcon from '../Icons/EditLineIcon';
import { More2FillIcon, ShareBoxLineIcon } from '../Icons';

TableRow.propTypes = {
    size: PropTypes.oneOf(['md', 'sm']),
    item: PropTypes.shape({
        //[accessor]: PropTypes.string
        badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'white', 'red']),
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            accessor: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['text', 'badge']),
            badgeColor: PropTypes.oneOf(['blue', 'peach']),
            width: PropTypes.oneOf(['full']),
        })
    ).isRequired,
    actionButtons: PropTypes.arrayOf(
        PropTypes.shape({
            action: PropTypes.oneOf([
                'edit',
                'delete',
                'done',
                'view',
                'visit',
                'moreOptions',
                'show',
            ]).isRequired,
            handleAction: PropTypes.func, // is not required if action === moreOptions
        })
    ),
    hideActionButtons: PropTypes.bool,
    useReactivateButton: PropTypes.bool,
    handleReactivateClick: PropTypes.func.isRequired,
    setMoreOptionsPopup: PropTypes.func,
};

function TableRow(props) {
    const {
        size = 'sm',
        item,
        columns,
        actionButtons,
        handleReactivateClick,
        columnWidth,
        hideActionButtons = false,
        useReactivateButton = false,
        setMoreOptionsPopup,
    } = props;
    const moreOptionsButtonRef = useRef(null);
    const [moreOptionsButtonPosition, setMoreOptionsButtonPosition] = useState(null);

    const rowClassName = classNames(
        '[&:not(:last-child)]:border-b border-neutral-200 even:bg-neutral-50',
        {
            'h-[54px]': size === 'md',
            'h-11': size === 'sm',
        }
    );

    const cellClassName = classNames('px-[20px] font-body text-body-regular-m text-neutral-500');

    const actionIcon = {
        edit: EditLineIcon,
        delete: DeleteBin4LineIcon,
        done: CheckboxCircleLineIcon,
        visit: ShareBoxLineIcon,
        moreOptions: More2FillIcon,
        show: EyeIcon,
    };

    useEffect(() => {
        if (moreOptionsButtonRef.current) {
            const rect = moreOptionsButtonRef.current.getBoundingClientRect();
            setMoreOptionsButtonPosition(rect);
        }
    }, [moreOptionsButtonRef]);

    return (
        <tr className={rowClassName}>
            {columns.map((column) => {
                const width = column.fixedWidth
                    ? `${column.fixedWidth - 40}px` // -40 px because of paddings
                    : columnWidth < 50
                    ? '200px'
                    : `${columnWidth}px`;

                return (
                    <td key={column.accessor} className={cellClassName}>
                        {column.type === 'badge' && (
                            <div
                                className={`${!column.fixedWidth && 'min-w-[200px]'}`}
                                style={{ width }}
                            >
                                <Badge
                                    text={item[column.accessor]}
                                    color={
                                        item.badgesColorsConfigObj?.[column.accessor] ||
                                        column.badgeColor ||
                                        item.badgeColor ||
                                        'blue'
                                    }
                                    leadingIcon={column.badgeIcon}
                                    leadingIconColor={column.badgeIconColor}
                                />
                            </div>
                        )}
                        {column.type === 'text' && (
                            <p
                                className={`truncate ${!column.fixedWidth && 'min-w-[200px]'}`}
                                style={{ width }}
                            >
                                {item[column.accessor]}
                            </p>
                        )}
                        {column.type === 'any' && (
                            <div
                                className={`${!column.fixedWidth && 'min-w-[200px]'}`}
                                style={{ width }}
                            >
                                {item[column.accessor]}
                            </div>
                        )}
                    </td>
                );
            })}
            {!!actionButtons.length && (
                <td className={cellClassName}>
                    {hideActionButtons ? (
                        <></>
                    ) : (
                        <>
                            {item['status'] === 'Inactive' && useReactivateButton ? (
                                <div>
                                    <Button
                                        type="link"
                                        size="xs"
                                        text="Reactivate"
                                        onClick={handleReactivateClick}
                                    />
                                </div>
                            ) : (
                                <div className="flex gap-[2px] justify-end items-center">
                                    {actionButtons.map(({ action, handleAction }) => {
                                        if (action === 'view') {
                                            return (
                                                <Button
                                                    key={action}
                                                    size="xs"
                                                    type="link"
                                                    text="View"
                                                    onClick={() => handleAction(item.id)}
                                                />
                                            );
                                        }
                                        if (action === 'moreOptions') {
                                            return (
                                                <div key={action} ref={moreOptionsButtonRef}>
                                                    <ButtonIcon
                                                        size="xs"
                                                        type="link"
                                                        icon={More2FillIcon}
                                                        onClick={() =>
                                                            setMoreOptionsPopup({
                                                                opened: true,
                                                                data: item,
                                                                topPosition:
                                                                    moreOptionsButtonPosition?.top,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            );
                                        }
                                        return (
                                            <ButtonIcon
                                                key={action}
                                                size="xs"
                                                type="link"
                                                icon={actionIcon[action] || CheckboxCircleLineIcon}
                                                onClick={() => handleAction(item)}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </>
                    )}
                </td>
            )}
        </tr>
    );
}

export default TableRow;
