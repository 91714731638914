import React from 'react';
import TabbedHeaderControl from '../../../../design-system/TabbedHeaderControl/TabbedHeaderControl';
import EscapeIcon from '../../../../design-system/Icons/EscapeIcon';
import { ButtonIcon } from '../../../../design-system';
import { CloseLineIcon } from '../../../../design-system/Icons';

const PublishingModalHeader = ({ onClose }) => {
    return (
        <div className="pt-3 px-4 xs:px-8 flex items-end gap-2 justify-between border-b-1 border-neutral-200">
            <TabbedHeaderControl tabs={['Publish']} activeTab="Publish" />
            <div className="flex items-center gap-1">
                <button onClick={onClose} className="cursor-pointer">
                    <EscapeIcon width={28} height={18} color="#5E6470" />
                </button>
                <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
            </div>
        </div>
    );
};

export default PublishingModalHeader;
