import React from 'react';
import BlockHeader from '../BlockHeader/BlockHeader';
import ProcessVersionHistoryItem from '../ProcessVersionHistoryItem/ProcessVersionHistoryItem';

const HistoryBlock = ({ processVersions }) => {
    return (
        <div className="w-full py-5 sm:py-8 px-5 sm:px-6 bg-white rounded-2xl">
            <BlockHeader
                title="History"
                description="Record of process installation and upgrades"
                isEditable={false}
            />

            <div className="mt-6">
                {!!processVersions.length && (
                    <ul className="flex flex-col gap-1">
                        {processVersions.map((processVersion, index) => (
                            <ProcessVersionHistoryItem
                                key={processVersion.id}
                                isTheOldestVersion={index === processVersions.length - 1}
                                processVersion={processVersion}
                            />
                        ))}
                    </ul>
                )}

                {!processVersions.length && (
                    <p className="font-body-bold text-body-bold-s text-neutral-300">
                        No history found
                    </p>
                )}
            </div>
        </div>
    );
};

export default HistoryBlock;
