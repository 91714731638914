import React from 'react';

import EmptyView from '../EmptyView/EmptyView';
import PlaybookCard from '../PlaybookCard/PlaybookCard';
import PaginatedContainer from '../../../components/PaginatedContainer/PaginatedContainer';
import { PAGINATION_LIMIT } from '../PlaybooksIndexPage';

const PlaybooksPaginatedList = ({
    page,
    playbooksDataResponse,
    parsedSearchQuery,
    openCreatePlaybookModal,
}) => {
    const { data: playbooks, total, count } = playbooksDataResponse;

    const emptyStateShown = !total && !playbooks?.length;

    return (
        <PaginatedContainer page={page} count={count} total={total} limit={PAGINATION_LIMIT}>
            {emptyStateShown && (
                <EmptyView
                    parsedSearchQuery={parsedSearchQuery}
                    openCreatePlaybookModal={openCreatePlaybookModal}
                />
            )}

            {!emptyStateShown && (
                <div className="grid grid-cols-1 min-[724px]:grid-cols-2 min-[1085px]:grid-cols-3 min-[1580px]:grid-cols-4 gap-x-5 gap-y-5">
                    {playbooks.map((playbook) => (
                        <PlaybookCard key={playbook.id} playbook={playbook} />
                    ))}
                </div>
            )}
        </PaginatedContainer>
    );
};

export default PlaybooksPaginatedList;
