import React from 'react';
import classNames from 'classnames';
import { JOB_TASK_BUCKET_STATUS } from '../../constants/jobs';

import { SvgIcon } from '../../design-system';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';

const MilestoneBucketStatusIcon = ({ bucketStatus }) => {
    const statusCircleClassName = classNames('mx-auto my-auto w-[18px] h-[18px] rounded-full', {
        'border-2 border-dashed border-neutral-300':
            bucketStatus === JOB_TASK_BUCKET_STATUS.pending,
        'border-[3px] border-purple-300': bucketStatus === JOB_TASK_BUCKET_STATUS.processing,
        'bg-purple-500 flex justify-center items-center':
            bucketStatus === JOB_TASK_BUCKET_STATUS.completed,
    });

    return (
        <div className="w-6 h-6 min-w-[24px]">
            <div className={statusCircleClassName}>
                {bucketStatus === JOB_TASK_BUCKET_STATUS.completed && (
                    <SvgIcon color="#FFFFFF" icon={CheckLineIcon} size="medium" />
                )}
            </div>
        </div>
    );
};

export default MilestoneBucketStatusIcon;
