import React from 'react';

import { API } from 'constants';
import client from '../../../services/inbox-api';

import useCustomPagination from '../../../hooks/useCustomPagination';
import { INBOX_STATUS_TABS, STATUS_TAB_KEYS } from '../../../constants/inbox';
import { SORT_TYPES } from '../../../constants/sort';

const InboxStatusTabs = ({ selectedTab, onTabChanged }) => {
    // it doesn't create an additional request, it's just used to always fetch the correct total inbox messages
    const { total: totalInboxMessages } = useCustomPagination({
        pageIndex: 0,
        searchParams: { ordering: SORT_TYPES.created, status: 'pending' },
        client,
        route: API.ROUTES.inbox.message,
    });
    // min height is 32px
    const tabClassName =
        'block w-full px-2 py-1 rounded-2 flex items-center gap-2 font-heading-bold text-[14px] font-semibold leading-4 text-neutral-500 uppercase cursor-pointer hover:bg-neutral-50 transition-colors';
    const selectedTabClassName = 'bg-neutral-50';
    const indicatorClassName = 'py-1 px-2 rounded-2 font-body text-[12px] font-semibold text-neutral-500';
    const neutralIndicatorClassName = 'bg-neutral-50 hover:bg-white';

    return (
        <ul className="flex flex-col gap-1">
            {INBOX_STATUS_TABS.map((tab) => {
                const isSelected = selectedTab === tab.key;
                return (
                    <li key={tab.key}>
                        <button
                            type="button"
                            className={`${tabClassName} ${isSelected && selectedTabClassName}`}
                            onClick={() => onTabChanged(tab.key)}
                        >
                            {tab.label}
                            {tab.key === STATUS_TAB_KEYS.pending && totalInboxMessages > 0 && (
                                // <span className="bg-white py-1 px-2 rounded-2 font-body text-[12px] font-semibold text-neutral-500">
                                //     {totalInboxMessages}
                                // </span>
                                <span className={`${indicatorClassName} ${!isSelected ? neutralIndicatorClassName : 'bg-white'}`}>
                                    {totalInboxMessages}
                                </span>
                            )}
                        </button>
                    </li>
                );
            })}
        </ul>
    );
};

export default InboxStatusTabs;
