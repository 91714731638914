import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePageParamSyncWithDevice = ({ parsedParams, page, setPage, isMobile }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!parsedParams.sort) {
            return;
        }

        // update page state after user used pagination on Desktop Version or after page reload on Desktop Version
        if (parsedParams.page && page !== parsedParams.page) {
            setPage(parsedParams.page);
        }

        // if it is Mobile version remove page from search params
        if (isMobile && parsedParams.page) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.delete('page');

            setSearchParams(urlSearchParams, { replace: true });
        }

        // if it is Desktop version and there is no page in search params
        if (!isMobile && !parsedParams.page) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.set('page', page || 1);

            setSearchParams(urlSearchParams, { replace: true });
        }
    }, [isMobile, parsedParams.page, parsedParams.sort]);
};
