export const formatScenarioVersionsForSelectOptions = (versions) => {
    return (
        versions?.map(({ id, name, is_live, is_default }) => ({
            id,
            name: name + (is_default ? ' (default)' : ''),
            trailingBadge: {
                text: is_live ? 'Live' : 'Draft',
                color: is_live ? 'lime' : 'neutral',
            },
        })) || []
    );
};
