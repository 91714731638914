import React, { useMemo, useState } from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';

import PaginatedSelect from '../../../../design-system/PaginatedSelect/PaginatedSelect';
import MappingItem from '../../../../design-system/MappingItem/MappingItem';

const AddToDatasetForm = ({
    selectedDatasetData,
    setSelectedDatasetData,
    mapping,
    setMapping,
    workerInputs,
    workerOutputs,
    fetchDatasetHookResponse,
}) => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const { width: screenWidth } = useWindowSize();

    const {
        options: datasetOptions,
        optionsLoading,
        totalOptions,
        setPage,
        canLoadMoreOptions,
    } = fetchDatasetHookResponse;

    const workerInputsOptions = useMemo(
        () => workerInputs.map(({ id, label, value }) => ({ id, name: label, value })),
        [workerInputs]
    );
    const workerOutputsOptions = useMemo(
        () => workerOutputs.map(({ id, label, value }) => ({ id, name: label, value })),
        [workerOutputs]
    );

    const setMappingEmptyState = (dataset) => {
        const formattedDatasetInputKeys =
            dataset.input_keys?.map((datasetKey) => ({
                datasetKey,
                workerVariableId: null,
            })) || [];
        const formattedDatasetOutputKeys =
            dataset.output_keys?.map((datasetKey) => ({
                datasetKey,
                workerVariableId: null,
            })) || [];
        setMapping({
            inputs: formattedDatasetInputKeys,
            outputs: formattedDatasetOutputKeys,
        });
    };

    const handleDatasetChange = (_, dataset) => {
        setSelectedDatasetData(dataset);
        setMappingEmptyState(dataset);
    };

    const handleMappingChange = (type, datasetKey, workerVariableId) => {
        setMapping((prevData) => ({
            ...prevData,
            [type]: prevData[type]?.map((item) =>
                item.datasetKey === datasetKey ? { datasetKey, workerVariableId } : item
            ),
        }));
    };

    // used to increase the height of the modal so that the dropdown fits entirely on the screen
    const selectMarginBottom = isDropdownOpened && !selectedDatasetData ? 78 : 0;

    return (
        <div
            className={`flex flex-col gap-4 ${
                !!selectedDatasetData && 'pb-5 border-b-1 border-neutral-200'
            }`}
            style={{
                marginBottom: selectMarginBottom,
                transition: selectedDatasetData?.id ? null : 'margin 0.2s ease-in-out',
            }}
        >
            <PaginatedSelect
                size="md"
                name="dataset"
                value={selectedDatasetData?.id}
                options={datasetOptions}
                onChange={handleDatasetChange}
                state="default"
                label="Select a Dataset"
                isRequired
                fetchOptions={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                optionsLoading={optionsLoading}
                useExternalDropdownState
                dropdownOpen={isDropdownOpened}
                setDropdownOpen={setIsDropdownOpened}
                includeClientSideFiltering
                totalOptionsCount={totalOptions}
                dropdownHeight={selectedDatasetData?.id ? 200 : 164}
            />

            {!!mapping.inputs?.length && (
                <div className="flex flex-col gap-2">
                    {mapping.inputs.map(({ datasetKey, workerVariableId }, index) => (
                        <MappingItem
                            key={datasetKey}
                            leftSideLabel="Dataset Inputs"
                            rightSideLabel="Worker Variables"
                            leftSideValue={datasetKey}
                            rightSideValue={workerVariableId}
                            selectOptions={workerInputsOptions}
                            tipText="Map each dataset input to a worker variable. Leave mapping blank to send an empty value."
                            itemIndex={index}
                            mappingItemsLength={mapping.inputs.length}
                            selectPlaceholder="Select a worker variable"
                            leftColumnWidthPercentage={screenWidth > 550 ? 36 : 40}
                            dropdownHeight={166}
                            handleSelectChange={(datasetInputKey, selectedWorkerInputId) =>
                                handleMappingChange(
                                    'inputs',
                                    datasetInputKey,
                                    selectedWorkerInputId
                                )
                            }
                        />
                    ))}
                </div>
            )}

            {!!mapping.outputs?.length && (
                <div className="flex flex-col gap-2">
                    {mapping.outputs.map(({ datasetKey, workerVariableId }, index) => {
                        const isLastElement = index === mapping.outputs.length - 1;
                        return (
                            <MappingItem
                                key={datasetKey}
                                leftSideLabel="Dataset Outputs"
                                rightSideLabel="Worker Variables"
                                leftSideValue={datasetKey}
                                rightSideValue={workerVariableId}
                                selectOptions={workerOutputsOptions}
                                tipText="Map each dataset output to a worker variable. Leave mapping blank to send an empty value."
                                itemIndex={index}
                                mappingItemsLength={mapping.outputs.length}
                                selectPlaceholder="Select a worker variable"
                                leftColumnWidthPercentage={screenWidth > 550 ? 36 : 40}
                                dropdownHeight={isLastElement ? 129 : 160}
                                handleSelectChange={(datasetOutputKey, selectedWorkerOutputId) =>
                                    handleMappingChange(
                                        'outputs',
                                        datasetOutputKey,
                                        selectedWorkerOutputId
                                    )
                                }
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default AddToDatasetForm;
