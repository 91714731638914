import React from 'react';

const RowViewTypeIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <mask id="path-1-inside-1_9833_53767" fill="white">
                <rect x="2" y="2" width="12" height="5.5" rx="1" />
            </mask>
            <rect
                x="2"
                y="2"
                width="12"
                height="5.5"
                rx="1"
                stroke={props.color}
                strokeWidth="3"
                mask="url(#path-1-inside-1_9833_53767)"
            />
            <mask id="path-2-inside-2_9833_53767" fill="white">
                <rect x="2" y="8.5" width="12" height="5.5" rx="1" />
            </mask>
            <rect
                x="2"
                y="8.5"
                width="12"
                height="5.5"
                rx="1"
                stroke={props.color}
                strokeWidth="3"
                mask="url(#path-2-inside-2_9833_53767)"
            />
        </svg>
    );
};

export default RowViewTypeIcon;
