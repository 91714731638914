import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import { iconNameMap } from 'design-system/Icons/GenericIcon';
import Loading from '../../components/Loading';
import { Badge } from '../index';
export default function ListOption({
    leadingIconName,
    leadingSelectIcon,
    text,
    showTextAsBadge, // if option text should be shown as a badge, set showTextAsBadge === true
    badgeInfo, // if showTextAsBadge === true this data will be displayed
    textColor = '#1F2125',
    hoverEnabled = true,
    trailingBadge,
    index,
    selected,
    onClick,
    logAs,
    isLoading = false,
    iconColor = '#1A1A1A', // TODO: import tailwind config and read value from there,
    iconSelectedColor = '#754DCF', // TODO: import tailwind config and read value from there
    isDisabled = false,
}) {
    const leadingIcon = iconNameMap[leadingIconName];
    const currentIconColor = selected ? iconSelectedColor : isDisabled ? '#5E6470' : iconColor;

    let leadingElement = null;
    if (leadingIcon) {
        leadingElement = <SvgIcon icon={leadingIcon} color={currentIconColor} />; // Default size is 24 x 24
    } else if (leadingSelectIcon) {
        leadingElement = (
            <input
                id={`listOption-${index}-${selected}-${text}`}
                name={`listOption-${index}-${selected}-${text}`}
                type="radio"
                text="abc123"
                value={selected}
                checked={selected}
                className="h-6 w-6 border-gray-300 text-purple-500 focus:ring-purple-500"
                onChange={() => {}}
            />
        );
    }

    function handleClick(e) {
        if (isDisabled) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        onClick(index);
    }

    const hoverClass = hoverEnabled ? 'hover:bg-gray-100 hover:text-gray-900' : '';
    const selectedHighlight = selected ? 'bg-gray-100 text-gray-900' : '';

    return (
        <div
            key={`${index}:${text}:${selected}`}
            className={`${hoverClass} ${selectedHighlight} inline-flex py-2 px-2 items-center gap-2 rounded-lg self-stretch ${
                !isDisabled && 'cursor-pointer'
            }`}
            onClick={
                isLoading
                    ? (e) => {
                          e.stopPropagation();
                      }
                    : handleClick
            }
            {...(logAs ? { 'data-dd-action-name': logAs } : {})}
        >
            {isLoading && (
                <div className="w-full h-[24px] relative">
                    <div className="absolute top-1/2 left-1/2 transform translate-y-[-50%] translate-x-[-50%]">
                        <Loading withText={false} />
                    </div>
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="flex items-center justify-center">{leadingElement}</div>
                    <div
                        className="font-body text-body-regular-m leading-6"
                        style={{ color: isDisabled ? '#5E6470' : textColor }}
                    >
                        {showTextAsBadge && badgeInfo ? (
                            <Badge
                                text={badgeInfo.label || text}
                                color={badgeInfo.color || 'neutral'}
                                leadingIcon={badgeInfo.icon}
                                leadingIconColor={badgeInfo.iconColor || '#1F2125'}
                            />
                        ) : (
                            text
                        )}
                    </div>
                    <div className="ml-auto">{trailingBadge}</div>
                </>
            )}
        </div>
    );
}

ListOption.propTypes = {
    leadingIconName: PropTypes.oneOf(Object.keys(iconNameMap)),
    leadingSelectIcon: PropTypes.bool,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    trailingBadge: PropTypes.element,
    hoverEnabled: PropTypes.bool,
    index: PropTypes.number,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    iconColor: PropTypes.string,
    iconSelectedColor: PropTypes.string,
    logAs: PropTypes.string,
    isLoading: PropTypes.bool,
    showTextAsABadge: PropTypes.bool,
    badgeInfo: PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'red']),
        icon: PropTypes.func,
        iconColor: PropTypes.string,
    }),
    isDisabled: PropTypes.bool,
};
