import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/template-api';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { formatStringToSnakeCase } from '../../helpers/formatStringToSnakeCase';
import { defaultErrorMessage } from '../../constants/errorMessages';

import Button from '../../design-system/Button/Button';
import FileIcon from '../../design-system/Icons/FileIcon';
import CreationPageContainer from '../../design-system/CreationPageContainer/CreationPageContainer';
import { Input, TextArea } from '../../design-system';

const CreateNewGoalTemplatePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.templatesLibraryLocation || '/templates/goals';

    const [inputData, setInputData] = useState({
        name: '',
        description: '',
        file_name: '',
    });
    const [errorFields, setErrorFields] = useState({
        name: false,
        description: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    const [errorAlert, setErrorAlert] = useState(null);

    useDocumentTitle('Create Goal Template');

    const handleInputChange = (e) => {
        const fieldName = e.target.name;

        if (fieldName === 'name') {
            setInputData((prevData) => ({
                ...prevData,
                name: e.target.value,
                file_name: formatStringToSnakeCase(e.target.value),
            }));
        } else {
            setInputData((prevData) => ({
                ...prevData,
                [fieldName]: e.target.value,
            }));
        }

        if (errorFields[fieldName]) {
            setErrorFields((prevFields) => ({ ...prevFields, [fieldName]: false }));
        }
    };

    const createTemplate = async () => {
        const areEmptyFields = !inputData.name || !inputData.description;
        if (areEmptyFields) {
            setErrorFields({ name: !inputData.name, description: !inputData.description });
            return;
        }

        try {
            setIsLoading(true);
            const { data } = await client.post(API.ROUTES.template.goalTemplate, inputData);

            setIsLoading(false);
            navigate(`/templates/goal/${data.id}`, {
                state: { from: backLinkHref },
            });
        } catch (error) {
            setIsLoading(false);
            if (
                error.response?.status === 403 &&
                error.response?.data?.detail === "You don't have permission to perform this action."
            ) {
                setErrorAlert({
                    message:
                        'Please connect your GitHub account to your Profile to take this action.',
                });
            } else if (
                error.response?.status === 400 &&
                error.response?.data?.error?.includes(
                    'duplicate key value violates unique constraint'
                )
            ) {
                setErrorAlert({ message: 'Please pick a unique template name and try again.' });
            } else {
                setErrorAlert({ message: defaultErrorMessage });
            }
        }
    };

    return (
        <CreationPageContainer
            title="Create New Goal Template"
            backButtonText="Back to Library"
            handleBackButtonClick={() => navigate(backLinkHref)}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
        >
            <div className="flex flex-col gap-5 mb-8">
                <Input
                    size="md"
                    name="name"
                    value={inputData.name}
                    label="Goal Template Name"
                    isRequired
                    placeholder="Compile Notes to Body From Template"
                    onChange={handleInputChange}
                    state={errorFields.name ? 'error' : 'default'}
                    errorMessage={'Please fill in this field.'}
                    tipText={`File name: ${inputData.file_name}`}
                />
                <TextArea
                    name="description"
                    value={inputData.description}
                    label="Goal Template Description"
                    isRequired
                    state={errorFields.description ? 'error' : 'default'}
                    errorMessage={'Please fill in this field.'}
                    placeholder="Compiles notes to a body variable using the template"
                    onChange={handleInputChange}
                />
            </div>
            <Button
                type="primary"
                size="sm"
                text="Create Template"
                trailingIcon={FileIcon}
                state={isLoading ? 'loading' : 'default'}
                onClick={createTemplate}
            />
        </CreationPageContainer>
    );
};

export default CreateNewGoalTemplatePage;
