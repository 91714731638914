import { realmPlugin, addImportVisitor$ } from '@mdxeditor/editor';
import { $createParagraphNode, $createTextNode } from 'lexical';

const CatchAllVisitor = {
    testNode: () => true,
    visitNode: ({ mdastNode, actions, lexicalParent }) => {
        const paragraph = $createParagraphNode();
        paragraph.append($createTextNode(mdastNode?.value || ''));
        if (process.env.NODE_ENV !== 'production') {
            console.warn(
                `unhandled node reverted to paragraph, type: ${mdastNode?.type}, value: `,
                mdastNode?.value
            );
        }
        lexicalParent.append(paragraph);
    },
};

export const catchAllPlugin = realmPlugin({
    init(realm) {
        realm.pub(addImportVisitor$, CatchAllVisitor);
    },
});
