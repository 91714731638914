import React, { useMemo } from 'react';
import AvailableMemberCard from '../AvailableMemberCard/AvailableMemberCard';

const ClientAvailableMembersBlock = ({
    availableMembers,
    parentOrganizationMembers,
    parentOrganizationName,
    isOwnerOrAdmin,
    updateClientData,
}) => {
    const formattedMembers = useMemo(() => {
        if (!parentOrganizationMembers) {
            return null;
        }

        const userMembershipMap = availableMembers.reduce((acc, member) => {
            acc[member.user.email] = member['organization-membership'];
            return acc;
        }, {});

        return parentOrganizationMembers.map(({ user }) => ({
            user,
            'organization-membership': userMembershipMap[user.email] || null,
        }));
    }, [parentOrganizationMembers, availableMembers]);

    return (
        <div>
            <p className="font-body-bold text-body-bold-s text-neutral-500 mb-2">
                Add Members from {parentOrganizationName}
            </p>

            {!!formattedMembers && (
                <ul className="flex flex-col gap-2">
                    {formattedMembers.map((item) => (
                        <AvailableMemberCard
                            key={item.user.email}
                            data={item}
                            isOwnerOrAdmin={isOwnerOrAdmin}
                            updateClientData={updateClientData}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ClientAvailableMembersBlock;
