import { useEffect, useState } from 'react';
import { DEFAULT_LIMIT } from './useCustomPagination';

function getApiUrl({ page, searchParams, route }) {
    const offset = page === 1 ? 0 : DEFAULT_LIMIT * (page - 1);
    const additionalQuery =
        Object.keys(searchParams)
            .map((key) => `${key}=${searchParams[key]}`)
            .join('&') || '';

    return `${route}?limit=${DEFAULT_LIMIT}&offset=${offset}${
        additionalQuery && `&${additionalQuery}`
    }`;
}

export const useRecursiveDataFetch = ({
    client,
    route,
    searchParams = {},
    useExternalState = false,
    setData, // pass this prop if useExternalState = true
}) => {
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(null);
    const [totalResults, setTotalResults] = useState(0);

    useEffect(() => {
        const fetchNextPageData = async (page) => {
            const newApiUrl = getApiUrl({ page, searchParams, route });
            const { data } = await client.get(newApiUrl);
            if (useExternalState) {
                setData((prevData) => [...prevData, ...data.results]);
            }
            setResults((prevData) => [...prevData, ...data.results]);
            if (data.next) {
                await fetchNextPageData(page + 1);
            }
        };

        const fetchData = async () => {
            try {
                setLoading(true);
                const apiUrl = getApiUrl({ page: 1, searchParams, route });
                const { data } = await client.get(apiUrl);
                if (useExternalState) {
                    setData(data.results);
                }
                setResults(data.results);
                setTotalResults(data.count);
                if (data.next) {
                    await fetchNextPageData(2);
                }
            } catch (e) {
                setIsError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const isDataFetchCompleted = Boolean(results && !loading);
    return { data: results, loading, isError, isDataFetchCompleted, totalResults };
};
