import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CHAT_ROLE, HISTORY_TAB } from '../../../constants/assistant';
import { handleOpenStartNewJobModal } from '../../../helpers/handleOpenStartNewJobModal';

import { Button } from '../../../design-system';
import MessageWrapper from '../MessageWrapper/MessageWrapper';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';

const DoNextSection = ({ agentId }) => {
    const navigate = useNavigate();

    const handleStartAnotherJob = () => {
        navigate(`/assistant?agent=${agentId}&tab=${HISTORY_TAB.jobs}`);
    };

    return (
        <MessageWrapper role={CHAT_ROLE.assistant}>
            <p className="font-body text-body-regular-s text-neutral-500">
                What would you like to do next?
            </p>

            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                <Button
                    type="secondary"
                    size="sm"
                    text="Start Another Job"
                    trailingIcon={PlayCircleFillIcon}
                    onClick={handleStartAnotherJob}
                />
                <Button
                    type="ghost"
                    size="sm"
                    text="Select a New Agent"
                    onClick={handleOpenStartNewJobModal}
                />
            </div>
        </MessageWrapper>
    );
};

export default DoNextSection;
