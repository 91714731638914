// Create a new file, e.g., api.js, to store your helper function
// api.js

import client from 'services/library-api';
import { API } from 'constants';
import { ErrorWarningLineIcon } from 'design-system/Icons';
import { mutate } from 'swr';
import { defaultErrorMessage } from '../constants/errorMessages';
import { TASK_TYPES } from '../constants/library';

export const duplicateTask = async ({
    taskId,
    promptId,
    promptVersionId,
    swrKey,
    setAlert,
    setAddedToMyLibrary = () => {},
    setIsLoading,
    setSuccessModal,
    orgId = null,
    taskType = TASK_TYPES.prompt,
}) => {
    try {
        setIsLoading(true);
        const requestBody = {
            prompt_id: promptId,
            prompt_version_id: promptVersionId,
            organization: orgId,
        };
        const response = await client.post(
            `${API.ROUTES.library.task}${taskId}/duplicate/`,
            requestBody
        );
        const newId =
            taskType === TASK_TYPES.prompt ? response.data.default_prompt?.id : response.data?.id;
        const newTaskName = response.data.name;
        setAddedToMyLibrary(true);
        setSuccessModal({ opened: true, id: newId, taskName: newTaskName, taskType });
        setIsLoading(false);
        if (swrKey) {
            mutate(
                swrKey,
                (currentData) => {
                    const updatedResults = currentData.results.reduce((acc, task) => {
                        if (task.id === taskId) {
                            acc.push(task, response.data);
                        } else {
                            acc.push(task);
                        }
                        return acc;
                    }, []);
                    return {
                        ...currentData,
                        count: currentData.count + 1,
                        results: updatedResults,
                    };
                },
                {
                    revalidate: true,
                }
            );
        }
    } catch (e) {
        console.log('error', e);
        setAlert({
            show: true,
            status: 'critical',
            statusCode: e.response.status,
            icon: ErrorWarningLineIcon,
            message: defaultErrorMessage,
        });
        setIsLoading(false);
    }
};
