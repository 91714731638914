import React from 'react';
import BranchAndOrgTestSettingsBlock from '../../BranchAndOrgTestSettingsBlock/BranchAndOrgTestSettingsBlock';

const IntegrationTestSettingsBlock = ({
    isExpanded,
    toggleExpand,
    versions,
    formData,
    setBlockFormData,
    fieldsErrorMessage,
    setFieldsErrorMessage,
}) => {
    const { integrationTestSettings } = formData;

    return (
        <BranchAndOrgTestSettingsBlock
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
            versions={versions}
            blockFormData={integrationTestSettings}
            setBlockFormData={setBlockFormData}
            fieldsErrorMessage={fieldsErrorMessage}
            setFieldsErrorMessage={setFieldsErrorMessage}
            isOrganizationPreselectLoading={false} // for this page organization preselect function is not available
            stopPreselecting={() => {}}
            blockTitle="INTEGRATION TEST SETTINGS"
            versionSelectLabel="Select Process Template Branch"
            organizationSelectPlaceholder="Select the organization to run this Integration Test Run in"
        />
    );
};

export default IntegrationTestSettingsBlock;
