import React from 'react';
import ListOptionGroup from '../../../design-system/ListOptionGroup/ListOptionGroup';
import ListOption from '../../../design-system/ListOption/ListOption';

const SortDropdown = ({ options, selectedOption, handleOptionSelect, onClose }) => {
    const listOptions = options.map(({ text, value }, index) => (
        <ListOption
            key={value}
            text={text}
            index={index}
            leadingSelectIcon={true}
            selected={value === selectedOption}
            onClick={() => handleOptionSelect(value)}
        />
    ));

    return (
        <div className="absolute top-[110%] right-0 z-20">
            <ListOptionGroup listOptions={listOptions} fixedWidth={280} handleClose={onClose} />
        </div>
    );
};

export default SortDropdown;
