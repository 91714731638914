import React from 'react';
import classNames from 'classnames';

import { SvgIcon } from '../../../design-system';
import { CheckboxCircleLineIcon } from '../../../design-system/Icons';

const AiModelsDropdownOption = ({ option, isSelected, handleOptionClick }) => {
    const { name } = option;

    const optionClassName = classNames(
        'w-full rounded-2 hover:bg-neutral-50 p-2 flex items-center justify-between gap-2 cursor-pointer transition-all',
        {
            'bg-white': !isSelected,
            'bg-neutral-50': isSelected,
        }
    );

    return (
        <div className={optionClassName} onClick={() => handleOptionClick(option)}>
            <p className="flex-1 truncate text-start font-body text-body-regular-m text-neutral-500">
                {name}
            </p>
            {isSelected && <SvgIcon color="#754DCF" icon={CheckboxCircleLineIcon} size="medium" />}
        </div>
    );
};

export default AiModelsDropdownOption;
