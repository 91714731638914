export const parseTemplatesLibrarySearchParams = (searchParams) => {
    const page = Number.parseInt(searchParams.get('page'));
    const sort = searchParams.get('sort');
    const search = searchParams.get('search');

    const result = { sort, page };

    if (search) {
        result.search = search;
    }

    return result;
};
