import React, { useMemo } from 'react';
import { DateTime } from 'luxon';

import client from '../../../../services/template-api';
import { TEMPLATE_TYPES, templateAPIRoutes } from '../../../../constants/template';

import FileIcon from '../../../../design-system/Icons/FileIcon';
import EditableTextArea from '../../../../components/EditableTextArea/EditableTextArea';
import Tag from '../../../../design-system/Tag/Tag';
import ProcessTemplateLabelsBlock from '../ProcessTemplateLabelsBlock/ProcessTemplateLabelsBlock';
import LayersIcon from '../../../../design-system/Icons/LayersIcon';
import GoalTemplateUsedInBlock from '../GoalTemplateUsedInBlock/GoalTemplateUsedInBlock';
import { SvgIcon } from '../../../../design-system';

const TemplateDetailsBlock = ({ templateType, templateData, editable, setTemplateData }) => {
    const {
        id,
        name,
        description,
        is_live,
        current_version,
        created_at,
        file_name,
        labels,
        used_in,
    } = templateData;

    const versionData = useMemo(
        () => [
            { name: 'Main Version', value: is_live ? current_version : 'DRAFT', showAsTag: true },
            {
                name: 'Created at',
                value: DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - hh:mm:ss a'),
            },
            {
                name: 'File name',
                value: file_name,
            },
        ],
        [templateData]
    );

    const handleTemplateEditsSave = async (fieldName, fieldValue) => {
        try {
            const requestBody = {
                [fieldName]: fieldValue,
            };
            await client.patch(`${templateAPIRoutes[templateType]}${id}/`, requestBody);
            // update main template data state
            setTemplateData((prevData) => ({ ...prevData, [fieldName]: fieldValue }));
        } catch (e) {
            return true;
        }
    };

    const templateIcon = {
        [TEMPLATE_TYPES.goal]: FileIcon,
        [TEMPLATE_TYPES.process]: LayersIcon,
    };

    return (
        <div className="flex flex-col gap-4 pb-5 border-b-1 border-neutral-200">
            {editable ? (
                <EditableTextArea
                    initialText={name}
                    textStyle="font-body-bold text-body-bold-l text-black"
                    onSave={async (value) => {
                        const isError = await handleTemplateEditsSave('name', value);
                        if (isError) {
                            return true;
                        }
                    }}
                    icon={templateIcon[templateType]}
                />
            ) : (
                <div className="flex flex-row items-center space-x-2 w-full">
                    <div className="w-8 h-8 min-w-[32px] bg-neutral-100 rounded-2 flex justify-center items-center">
                        <SvgIcon color="#1F2125" size="medium" icon={templateIcon[templateType]} />
                    </div>
                    <p className="font-body-bold text-body-bold-l text-black">{name}</p>
                </div>
            )}

            <div className="flex items-center flex-wrap gap-x-5 gap-y-2">
                {versionData?.map((item) => (
                    <div key={item.name} className="truncate">
                        <p className="font-body-bold text-body-bold-xs text-neutral-500 mb-1">
                            {item.name}
                        </p>
                        {item.showAsTag ? (
                            <Tag text={item.value} />
                        ) : (
                            <p className="w-full font-body text-body-regular-xs text-neutral-300 py-0.5 truncate">
                                {item.value}
                            </p>
                        )}
                    </div>
                ))}
            </div>

            {templateType === TEMPLATE_TYPES.goal && (
                <GoalTemplateUsedInBlock processTemplates={used_in} />
            )}

            {templateType === TEMPLATE_TYPES.process && (
                <ProcessTemplateLabelsBlock
                    processTemplateId={id}
                    labels={labels}
                    setTemplateData={setTemplateData}
                    editable={editable}
                />
            )}

            {editable ? (
                <EditableTextArea
                    initialText={description}
                    textStyle="font-body text-body-regular-m text-neutral-500"
                    onSave={async (value) => {
                        const isError = await handleTemplateEditsSave('description', value);
                        if (isError) {
                            return true;
                        }
                    }}
                />
            ) : (
                <p className="font-body text-body-regular-m text-neutral-500">{description}</p>
            )}
        </div>
    );
};

export default TemplateDetailsBlock;
