import React, { memo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DateTime } from 'luxon';
import classNames from 'classnames';

import FlowChartIcon from '../../../design-system/Icons/FlowChartIcon';
// import FlowIcon from '../../../design-system/Icons/FlowIcon';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import PlayProcessIcon from '../../../design-system/Icons/PlayProcessIcon';
import DeleteScenarioModal from '../DeleteScenarioModal/DeleteScenarioModal';
import { DeleteBin4LineIcon } from '../../../design-system/Icons';
import { Badge, ButtonIcon, SvgIcon } from '../../../design-system';
import { applyOpacityToHex } from '../../../helpers/applyOpacityToHex';

const ScenarioCard = ({ scenario, keysToMutate }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id, name, playbook, created_at } = scenario;
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const formattedTime = DateTime.fromISO(created_at).toFormat('MM/dd/yyyy');

    const cardContainerClassName = classNames(
        'px-4 py-3 max-w-full rounded-2 bg-white',
        'grid grid-rows-[auto_auto] md:grid-rows-1 grid-cols-[minmax(100px,_1fr)_56px] md:grid-cols-[minmax(100px,_1fr)_150px_62px] gap-x-4 gap-y-2 items-start md:items-center'
    );

    return (
        <li className={cardContainerClassName}>
            <div className="grid grid-cols-1 lg:grid-cols-[minmax(100px,_1.6fr)_minmax(100px,_1fr)] gap-x-4 gap-y-1">
                <div className="flex items-center gap-2 lg:gap-4 row-start-2 row-end-2 lg:row-start-1 lg:row-end-1">
                    <SvgIcon color="#000000" icon={FlowChartIcon} size="medium" />
                    <p className="font-body-bold text-body-bold-s md:text-body-bold-xs text-neutral-500 truncate">
                        {name}
                    </p>
                </div>
                <div className="row-start-1 row-end-1 flex items-center lg:justify-end overflow-hidden min-h-[28px]">
                    <Badge
                        text={playbook.name}
                        leadingIcon={PlayProcessIcon}
                        leadingIconColor="#000000"
                        color={applyOpacityToHex(playbook.icon_color, 20)}
                    />
                </div>
            </div>
            <div className="pt-2 md:p-0 border-t-1 border-neutral-200 md:border-none row-start-2 row-end-2 col-span-2 md:row-start-auto md:row-end-auto md:col-span-1">
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Created At{' '}
                    <span className="font-body-bold text-body-bold-xs text-neutral-500">
                        {formattedTime}
                    </span>
                </p>
            </div>
            <div className="flex items-center justify-end md:gap-1.5">
                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={EditLineIcon}
                    onClick={() =>
                        navigate(`/configure/scenario/${id}`, { state: { from: location } })
                    }
                />
                <ButtonIcon
                    type="link"
                    size="xs"
                    icon={DeleteBin4LineIcon}
                    onClick={() => setIsDeleteModalOpened(true)}
                />
            </div>

            {isDeleteModalOpened && (
                <DeleteScenarioModal
                    id={id}
                    name={name}
                    keysToMutate={keysToMutate}
                    onClose={() => setIsDeleteModalOpened(false)}
                />
            )}
        </li>
    );
};

export default memo(ScenarioCard);
