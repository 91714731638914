import { SORT_TYPES } from '../constants/sort';

export const getGeneralRequestQueryParams = (
    parsedSearchParams,
    defaultSortOption = SORT_TYPES.created
) => {
    const queryParams = { ordering: parsedSearchParams.sort || defaultSortOption };

    if (parsedSearchParams.search) {
        queryParams.search = parsedSearchParams.search;
    }

    return queryParams;
};
