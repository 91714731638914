import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import SettingsSidePanelWrapper from '../SettingsSidePanelWrapper/SettingsSidePanelWrapper';

import OrganizationsNavBar from '../OrganizationsNavBar/OrganizationsNavBar';

const SettingsSidePanel = ({
    isSidePanelOpened,
    setIsSidePanelOpened,
    ...organizationsHookResponse
}) => {
    const navigate = useNavigate();
    const { organizationId } = useParams();

    const isOverviewTabActive = !organizationId;
    const overviewButtonClassName = classNames(
        'font-body text-body-regular-s px-2 py-1.5 uppercase rounded-2 text-left transition-colors',
        {
            'bg-neutral-100': isOverviewTabActive,
            'bg-white': !isOverviewTabActive,
        }
    );

    const handleOverviewSelect = () => {
        navigate('/settings');
        setIsSidePanelOpened(false);
    };

    return (
        <SettingsSidePanelWrapper isOpened={isSidePanelOpened} setIsOpened={setIsSidePanelOpened}>
            <div className="w-full pt-6 pb-3 px-4 min-h-full bg-white border-r-1 border-neutral-200 flex flex-col gap-4 relative max-h-full">
                <div className="flex flex-col gap-4 pb-4 border-b-1 border-neutral-200">
                    <h3 className="font-heading-bold text-heading-bold-m">Settings</h3>

                    <button className={overviewButtonClassName} onClick={handleOverviewSelect}>
                        Overview
                    </button>
                </div>

                <OrganizationsNavBar
                    setIsSidePanelOpened={setIsSidePanelOpened}
                    {...organizationsHookResponse}
                />
            </div>
        </SettingsSidePanelWrapper>
    );
};

export default SettingsSidePanel;
