import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchBar from '../../design-system/SearchBar/SearchBar';

const ClientSideFilteredSearchBar = ({
    list = [],
    setFilteredList,
    query, // use this prop when using external Query state otherwise value would be the main query state
    setQuery, // use this prop when using external Query state otherwise value would be the main query state
    useExternalQuery = false,
    size = 'md',
    state = 'default',
    placeholder,
    keyToFilter = ['name'], //use an array to represent nested object keys; each element in the array corresponds to the subsequent level of nesting. For instance, to access 'organization.name', utilize ['organization', 'name'].
    withClearIcon = false,
}) => {
    const [value, setValue] = useState('');

    const filterList = (newValue) => {
        setFilteredList(
            list.filter((listItem) => {
                const valueToFilter = keyToFilter.reduce((element, key) => element[key], listItem);
                return valueToFilter?.toLowerCase()?.includes(newValue.toLowerCase().trim());
            })
        );
    };

    const handleChange = (newValue) => {
        if (useExternalQuery) {
            setQuery(newValue);
        } else {
            setValue(newValue);
        }
        filterList(newValue);
    };

    useEffect(() => {
        filterList(useExternalQuery ? query : value);
    }, [list]);

    return (
        <SearchBar
            size={size}
            state={state}
            value={useExternalQuery ? query : value}
            placeholder={placeholder}
            onChange={(e) => handleChange(e.target.value)}
            onSubmit={() => {}}
            withClearIcon={withClearIcon}
            showClearIconIfValueIsNotEmpty
            onClear={() => handleChange('')}
        />
    );
};

export default ClientSideFilteredSearchBar;

ClientSideFilteredSearchBar.propTypes = {
    list: PropTypes.array.isRequired,
    setFilteredList: PropTypes.func.isRequired,
    query: PropTypes.string,
    setQuery: PropTypes.func,
    useExternalQuery: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    state: PropTypes.oneOf(['default', 'disabled']),
    placeholder: PropTypes.string,
};
