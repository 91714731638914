import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';

import client from 'services/library-api';
import * as API from 'constants/api';

import Modal from 'design-system/Modal/Modal';
import Alert from 'design-system/Alert/Alert';
import { Button } from 'design-system';

import { ErrorWarningLineIcon } from 'design-system/Icons';
import { defaultErrorMessage } from '../../constants/errorMessages';

function DeleteTaskConfirmModal({
    onClose,
    swrKey,
    id,
    navigateToLibrary = true,
    taskType = 'prompt',
}) {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState(null);

    const handleTaskDelete = async () => {
        try {
            setIsLoading(true);
            setErrorStatusCode(null);
            await client.delete(`${API.ROUTES.library.task}${id}/`);
            if (swrKey) {
                mutate(
                    swrKey,
                    (currentData) => {
                        const updatedResults = currentData.results.filter((task) => task.id !== id);
                        return {
                            ...currentData,
                            count: currentData.count - 1,
                            results: updatedResults,
                        };
                    },
                    {
                        revalidate: false,
                    }
                );
            }
            if (navigateToLibrary) {
                localStorage.setItem('deletedTaskId', id);
                navigate('/library?page=1&sort=name');
            }
            setIsLoading(false);
            onClose();
        } catch (error) {
            setIsLoading(false);
            setErrorStatusCode(error.response.status);
        }
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="p-[8px]">
                <h2 className="text-neutral-400 font-heading-bold text-heading-bold-m mb-[40px] text-center">
                    Are you sure you want to delete this {taskType === 'prompt' ? 'prompt' : 'task'}
                    ?
                </h2>
                <div className="flex gap-[32px] justify-center">
                    <Button type="link" size="sm" text="No, Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Yes, Delete"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleTaskDelete}
                    />
                </div>
            </div>
            {errorStatusCode && (
                <Alert
                    status="critical"
                    icon={ErrorWarningLineIcon}
                    statusCode={errorStatusCode}
                    message={defaultErrorMessage}
                    handleClose={() => setErrorStatusCode(null)}
                />
            )}
        </Modal>
    );
}

export default DeleteTaskConfirmModal;
