import React from 'react';

import PlotView from '../../../../design-system/PlotView/PlotView';
import FormFieldHeader from '../../../../design-system/FormFieldHeader/FormFieldHeader';

const PlotContentPiece = ({ content }) => {
    const { label, data, is_filled } = content;

    if (!is_filled) {
        return (
            <div className="flex flex-col gap-2">
                <FormFieldHeader label={label} />
                <div className="px-3 py-[18px] rounded-2 border-1 border-neutral-300 font-body text-body-regular-s italic text-neutral-200">
                    Not filled yet
                </div>
            </div>
        );
    }

    const { plot } = data || {};

    return <PlotView label={label} value={plot} withDownloadButton withExpandButton outlined />;
};

export default PlotContentPiece;
