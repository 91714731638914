import React from 'react';
import { PROVIDERS, PROVIDERS_LOGO } from '../../../../constants/organizationConnections';

import { SvgIcon } from '../../../../design-system';
import PlayProcessIcon from '../../../../design-system/Icons/PlayProcessIcon';

const RecommendedConnectionsBlock = ({ connectedProviders, connectedProcesses }) => {
    return (
        <div className="ml:w-[291px] ml:min-w-[291px]">
            <p className="font-body-bold text-body-bold-m text-black mb-3">
                Recommended Connections
            </p>
            <div className="bg-neutral-50 p-3 rounded-2 mb-2">
                <p className="font-body-bold text-body-bold-s text-black mb-3">3rd Party Tools</p>
                {!!connectedProviders?.length && (
                    <ul className="flex items-center gap-3 flex-wrap">
                        {connectedProviders.map((provider) => (
                            <li
                                key={provider}
                                className="w-[40px] h-[40px] bg-white rounded-2 flex items-center justify-center"
                            >
                                <img
                                    src={PROVIDERS_LOGO[provider]}
                                    width={provider === PROVIDERS.airtable ? 40 : 24}
                                    height={provider === PROVIDERS.airtable ? 40 : 24}
                                    alt={`${provider} logo`}
                                    className="max-h-[40px]"
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="bg-neutral-50 p-3 rounded-2">
                <p className="font-body-bold text-body-bold-s text-black mb-3">
                    Connected Processes
                </p>
                <p className="font-body text-body-regular-xs text-neutral-500 mb-3">
                    Setup these connected processes to get the most out of your DiviUp Account
                </p>
                {!!connectedProcesses?.length && (
                    <ul className="flex flex-col gap-1">
                        {connectedProcesses.map((process) => (
                            <li key={process.id} className="px-4 py-3 rounded-2 bg-white">
                                <div className="flex items-center gap-2 mb-1">
                                    <SvgIcon color="#000000" size="medium" icon={PlayProcessIcon} />
                                    <p className="font-body-bold text-body-bold-xs text-black">
                                        {process.name}
                                    </p>
                                </div>
                                <p className="font-body text-body-regular-xs text-neutral-500">
                                    {process.description}
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default RecommendedConnectionsBlock;
