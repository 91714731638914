import React, { useState } from 'react';
import { ListOption, ListOptionGroup } from '../../../../design-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { duplicateTask } from '../../../../helpers/duplicateTask';
import { copyTaskConfig } from '../../../../helpers/copyTaskConfig';

const TaskOptionsPopup = ({
    task,
    taskId,
    taskType,
    requiredInputs,
    userInputs,
    outputs,
    setErrorAlert,
    setDuplicateSuccessModal,
    showDeleteConfirmModal,
    setCopyAlert,
    onClose,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '';

    const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
    const handleCopyTaskConfig = () => {
        const currentUserInputs = userInputs.reduce(
            (result, userInput) => [...result, ...userInput.inputs],
            []
        );

        const currentTask = { ...task, inputs: [...requiredInputs, ...currentUserInputs], outputs };

        copyTaskConfig(
            currentTask,
            () => setCopyAlert({ show: true, status: 'positive' }),
            () => setCopyAlert({ show: true, status: 'critical' })
        );
        onClose();
    };

    const handleDuplicateToMyLibrary = async () => {
        try {
            await duplicateTask({
                taskId,
                taskType,
                setAlert: setErrorAlert,
                setIsLoading: setIsDuplicateLoading,
                setSuccessModal: setDuplicateSuccessModal,
            });
            onClose();
        } catch (error) {
            console.log('error', error);
        }
    };
    const handleViewHistory = () => {
        navigate(`/library/task/${taskId}/history?page=runs`, { state: { libraryLocation } });
    };

    return (
        <div className="absolute right-0 bottom-full sm:bottom-[calc(100%+8px)]">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="historyIcon"
                            text="View History"
                            onClick={handleViewHistory}
                        />
                        <ListOption
                            leadingIconName="addToLibrary"
                            text="Duplicate Task"
                            isLoading={isDuplicateLoading}
                            onClick={handleDuplicateToMyLibrary}
                        />
                        <ListOption
                            leadingIconName="copyIcon"
                            text="Copy Config"
                            onClick={handleCopyTaskConfig}
                        />
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete Task"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={showDeleteConfirmModal}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default TaskOptionsPopup;
