import React from 'react';

import BotInstructionsSection from '../BotInstructionsSection/BotInstructionsSection';
import KnowledgeSection from '../KnowledgeSection/KnowledgeSection';

const BotDetailMainContent = ({ botDetail, setBotDetail }) => {
    return (
        <div className="lg:flex-1 lg:overflow-hidden">
            <div className="h-full max-h-full flex flex-col lg:flex-row">
                <div className="lg:h-full lg:flex-1 lg:overflow-y-auto bg-white">
                    <BotInstructionsSection
                        botId={botDetail.id}
                        instructionsArray={botDetail.default_version?.instructions}
                        setBotDetail={setBotDetail}
                    />
                </div>
                <div className="lg:h-full lg:flex-1 lg:overflow-y-auto bg-neutral-50">
                    <KnowledgeSection
                        botId={botDetail.id}
                        bases={botDetail.bases || []}
                        documents={botDetail.documents || []}
                        setBotDetail={setBotDetail}
                    />
                </div>
            </div>
        </div>
    );
};

export default BotDetailMainContent;
