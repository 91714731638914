import React from 'react';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { ListOption, ListOptionGroup } from '../../../design-system';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';

const AddNewMultiActionPopup = ({ isLoading, handleCreateNewDoc, openNewFolderModal, onClose }) => {
    const { isMobile } = useResponsiveBreakpoints();

    const listOptions = (
        <>
            <ListOption
                leadingIconName="fileTextLineIcon"
                text="Doc"
                isLoading={isLoading}
                onClick={handleCreateNewDoc}
            />
            <ListOption
                leadingIconName="folderFillIcon"
                text="Folder"
                onClick={openNewFolderModal}
            />
        </>
    );

    if (isMobile) {
        return (
            <MobilePopup onClose={onClose} closeButtonText="Cancel">
                {listOptions}
            </MobilePopup>
        );
    }

    return (
        <div
            className="absolute top-[calc(100%+6px)] right-0 lg:right-[unset] lg:left-1/2 lg:transform lg:translate-x-[-50%] z-20 pb-5"
            onClick={(e) => e.preventDefault()}
        >
            <ListOptionGroup fixedWidth={280} listOptions={listOptions} handleClose={onClose} />
        </div>
    );
};

export default AddNewMultiActionPopup;
