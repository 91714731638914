import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/library-api';
import reportClient from '../../services/report-api';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import useCustomPagination from '../../hooks/useCustomPagination';

import { Tabs } from '../../design-system';
import { FlowChartIcon } from '../../design-system/Icons';
import Loading from '../../components/Loading';
import AboutTab from './AboutTab/AboutTab';
import TitleBlock from '../../components/TitleBlock/TitleBlock';
import ProjectHistoryTab from './ProjectHistoryTab/ProjectHistoryTab';
import DetailPageTopNavBlock from '../../components/DetailPageTopNavBlock/DetailPageTopNavBlock';

const TABS = [{ name: 'About' }, { name: 'Project History' }];
export const PROJECTS_HISTORY_LIMIT = 10;

const ClientScenarioDetailPage = () => {
    const { scenarioId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const [scenarioDetail, setScenarioDetail] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const [currentHistoryPage, setCurrentHistoryPage] = useState(1);

    useEffect(() => {
        const fetchScenarioData = async () => {
            try {
                const { data } = await client.get(
                    `${API.ROUTES.library.clientScenario}${scenarioId}/?shared=true`
                );
                setScenarioDetail(data);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        setScenarioDetail(null);
        fetchScenarioData();
    }, [scenarioId]);

    const projectsHistoryPaginatedResponse = useCustomPagination({
        client: reportClient,
        route: API.ROUTES.report.project,
        pageIndex: currentHistoryPage - 1,
        searchParams: { scenario: scenarioId },
        limit: PROJECTS_HISTORY_LIMIT,
        autoFetchNextPage: true,
    });

    useDocumentTitle(scenarioDetail?.name);

    const playbookId = scenarioDetail?.playbook?.id;
    const backLinkHref =
        location.state?.from || playbookId ? `/playbook/${playbookId}` : '/playbooks';

    const handleStartNewProject = () => {
        navigate(`/playbook/${playbookId}/start/${scenarioId}`, {
            state: { from: location },
        });
    };

    const handleTabChanged = (_, index) => setActiveTabIndex(index);

    return (
        <div className="page-position overflow-y-auto pb-8 hide-scrollbar flex flex-col">
            {scenarioDetail ? (
                <>
                    <DetailPageTopNavBlock
                        backLinkHref={backLinkHref}
                        breadcrumbs="Playbook / Scenario Overview"
                        rightAlignedButton={{
                            type: 'neutral',
                            text: 'Start New Project',
                            trailingIcon: FlowChartIcon,
                            onClick: handleStartNewProject,
                        }}
                    />
                    <div className="w-full flex flex-col gap-5 p-5 xs:p-6 sm:p-8">
                        <TitleBlock
                            title={scenarioDetail.name}
                            withSharedIcon={scenarioDetail.is_shared}
                        />

                        <div className="max-w-max">
                            <Tabs
                                tabs={TABS}
                                tabIndex={activeTabIndex}
                                onTabChanged={handleTabChanged}
                            />
                        </div>

                        <div>
                            <div className={activeTabIndex === 0 ? 'block' : 'hidden'}>
                                <AboutTab
                                    scenarioDetail={scenarioDetail}
                                    setScenarioDetail={setScenarioDetail}
                                />
                            </div>

                            <div className={activeTabIndex === 1 ? 'block' : 'hidden'}>
                                <ProjectHistoryTab
                                    dataHookResponse={projectsHistoryPaginatedResponse}
                                    page={currentHistoryPage}
                                    setPage={setCurrentHistoryPage}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ClientScenarioDetailPage;
