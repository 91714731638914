import { useState } from 'react';

export function usePostRequest(options = {}, client) {
    return useRequest({ method: 'POST', ...options }, client);
}

export function usePutRequest(options = {}, client) {
    return useRequest({ method: 'PUT', ...options }, client);
}

export function usePatchRequest(options = {}, client) {
    return useRequest({ method: 'PATCH', ...options }, client);
}

export function useGetRequest(options = {}, client) {
    return useRequest({ method: 'GET', ...options }, client);
}

export function useDeleteRequest(options = {}, client) {
    return useRequest({ method: 'DELETE', ...options }, client);
}

export function useRequest(options = {}, client) {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    async function request(overrideOptions = {}, sync = false) {
        setLoading(true);

        try {
            const { data } = await client({ ...options, ...overrideOptions });
            if (!sync) setResponse(data);
            return { response: data, success: true };
        } catch (e) {
            setError(e.response);
            console.log('There is an error', e.response);

            return { error: e.response, success: false };
        } finally {
            if (!sync) setLoading(false);
        }
    }

    return { loading, request, error, response, setResponse, setError, setLoading };
}
