import React from 'react';

import ExtendedPaginatedDropdown from '../../../design-system/ExtendedPaginatedDropdown/ExtendedPaginatedDropdown';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const SelectAgentDropdown = ({
    selectedAgentId,
    agentOptionsHookResponse,
    handleAgentSelect,
    onClose,
}) => {
    const {
        options: agentOptions,
        optionsLoading,
        totalOptions,
        setPage,
        canLoadMoreOptions,
    } = agentOptionsHookResponse;

    return (
        <ExtendedPaginatedDropdown
            options={agentOptions}
            selectedOptionId={selectedAgentId}
            totalOptions={totalOptions}
            optionsLoading={optionsLoading}
            canLoadMoreOptions={canLoadMoreOptions}
            setOptionsNextPage={setPage}
            onOptionSelect={handleAgentSelect}
            onCancel={() => handleAgentSelect(null)}
            listLabel="All Agents"
            optionsLeadingIcon={FlashlightFillIcon}
            onClose={onClose}
        />
    );
};

export default SelectAgentDropdown;
