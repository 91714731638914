import { DEFAULT_LIMIT } from '../hooks/useCustomPagination';

export const STATUS_COLORS = {
    pending: '#F2AA85',
    completed: '#D9E56E',
};

export const STATUS_LABELS = {
    pending: 'Pending Approval',
    completed: 'Approved',
};

export const COMPLETED_AT_DATE_PLACEHOLDER = {
    pending: 'Not started',
    started: 'Pending approval',
};

export const TOTAL_MESSAGES_REQUEST_SWR_KEY = `/message/?limit=${DEFAULT_LIMIT}&offset=0&ordering=-created_at&status=pending`; // the response key used to retrieve the total number of inbox messages
export const COMPLETED_MESSAGES_REQUEST_SWR_KEY = `/message/?limit=${DEFAULT_LIMIT}&offset=0&ordering=-created_at&status=completed`;

export const STATUS_TAB_LABELS = {
    pending: 'TO DO',
    completed: 'DONE',
};

export const STATUS_TAB_KEYS = {
    pending: 'pending',
    completed: 'completed',
};

export const TAB_REQUEST_STATUS = {
    0: 'pending',
    1: 'completed',
};

export const INBOX_STATUS_TABS = [
    { key: STATUS_TAB_KEYS.pending, label: STATUS_TAB_LABELS.pending },
    { key: STATUS_TAB_KEYS.completed, label: STATUS_TAB_LABELS.completed },
];
