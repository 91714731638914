import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import SelectButton from '../../../design-system/SelectButton/SelectButton';
import AgentDropdown from '../AgentDropdown/AgentDropdown';

const AgentSelector = ({ agentsOptionsHookResponse, isJobStarted, startedJobAgentInfo }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const agentId = searchParams.get('agent');

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const { isMobile } = useResponsiveBreakpoints();

    const toggleDropdownOpened = () => {
        setIsDropdownOpened((prevState) => !prevState);
    };

    const { results: agentOptions, loading, totalResults: totalAgents } = agentsOptionsHookResponse;

    const selectedAgentOption = agentOptions?.find(({ id }) => id === agentId);

    const handleAgentSelect = (agent) => {
        const urlSearchParams = new URLSearchParams(searchParams);
        urlSearchParams.set('agent', agent);
        setSearchParams(urlSearchParams);
    };

    const selectorWidthClassName =
        'w-[300px] min-w-[300px] max-w-[300px] md:w-[240px] md:min-w-[240px] md:max-w-[240px] min-[940px]:w-[300px] min-[940px]:min-w-[300px] min-[940px]:max-w-[300px] lg:w-[240px] lg:min-w-[240px] lg:max-w-[240px] min-[1170px]:w-[300px] min-[1170px]:min-w-[300px] min-[1170px]:max-w-[300px]';

    if (isJobStarted && startedJobAgentInfo) {
        return (
            <div className={selectorWidthClassName}>
                <SelectButton
                    size="xs"
                    state="disabled"
                    selectedItem={startedJobAgentInfo}
                    placeholder="Select an Agent"
                    showAsBadge
                    badgeColor="neutral"
                />
            </div>
        );
    }

    const selectState = !!isJobStarted ? 'disabled' : 'default';

    return (
        <div className={`${selectorWidthClassName} relative`}>
            <SelectButton
                size="xs"
                isDropdownOpened={isDropdownOpened}
                toggleDropdownOpened={toggleDropdownOpened}
                placeholder="Select an Agent"
                state={selectState}
                selectedItem={selectedAgentOption}
                showAsBadge
                badgeColor="neutral"
            />

            {isDropdownOpened && (
                <AgentDropdown
                    options={agentOptions || []}
                    selectedOptionId={agentId}
                    handleOptionSelect={handleAgentSelect}
                    areOptionsLoading={loading}
                    totalOptionsCount={totalAgents || 0}
                    isMobile={isMobile}
                    onClose={(e) => {
                        e?.stopPropagation();
                        setIsDropdownOpened(false);
                    }}
                />
            )}
        </div>
    );
};

export default AgentSelector;
