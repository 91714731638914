import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { THREADS_TAB } from '../../../constants/threads';
import { capitalizeFirstLetter } from '../../../services/strings';

import { Tabs } from '../../../design-system';
import { handleChangeTab } from '../../../helpers/handleUpdateSearchParams';

export const tabs = [THREADS_TAB.threads, THREADS_TAB.executions];
const formattedTabs = tabs.map((tab) => ({ name: capitalizeFirstLetter(tab), key: tab }));

const ThreadsPageHeader = ({ activeTab, isMobile, resetPageState }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const activeTabIndex = Math.max(tabs.indexOf(activeTab), 0);

    const changeTab = (tab) => {
        handleChangeTab({
            tab,
            searchParams,
            setSearchParams,
            usePagination: !isMobile,
            usePageState: true,
            resetPageState,
            searchParamKeysToRemove: ['process', 'search'],
        });
    };

    const title = {
        [THREADS_TAB.threads]: 'Threads',
        [THREADS_TAB.executions]: 'Executions',
    }[activeTab];

    const subtitle = {
        [THREADS_TAB.threads]: 'Prompts and tasks combined to complete goals in a process.',
        [THREADS_TAB.executions]: 'Collections of Threads run automatically together.',
    }[activeTab];

    return (
        <>
            <div>
                <h1 className="font-heading-bold text-heading-bold-l text-black">{title}</h1>
                <p className="font-body text-body-regular-xs text-neutral-300">{subtitle}</p>
            </div>

            <div className="max-w-fit">
                <Tabs tabs={formattedTabs} tabIndex={activeTabIndex} onTabChanged={changeTab} />
            </div>
        </>
    );
};

export default memo(ThreadsPageHeader);
