import GitPullRequest from '../design-system/Icons/GitPullRequest';
import GitBranchIcon from '../design-system/Icons/GitBranchIcon';
import { versionStatusBadgeColor, versionStatusLabel } from '../constants/template';

export const formatVersionDataForSelectOption = (version) => ({
    id: version.id,
    name: version.github_branch_name,
    leadingIcon:
        version.status === 'merged' || version.status === 'in_review'
            ? GitPullRequest
            : GitBranchIcon,
    leadingIconColor:
        version.status === 'merged' || version.status === 'in_review' ? '#1F2125' : '#CFD6E5',
    trailingBadge: {
        text: versionStatusLabel[version.status],
        color: versionStatusBadgeColor[version.status],
    },
    trailingTagText: version.is_default ? 'MAIN' : null,
});
