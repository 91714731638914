import React from 'react';
import ColumnViewTypeIcon from '../../../../design-system/Icons/ColumnViewTypeIcon';
import RowViewTypeIcon from '../../../../design-system/Icons/RowViewTypeIcon';
import { SvgIcon } from '../../../../design-system';

const viewTypes = ['column', 'row'];

const ViewSwitcher = ({ view, setView }) => {
    const viewSwitcherClassName = 'w-7 h-7 min-w-[28px] rounded-2 flex justify-center items-center';

    const viewIcons = {
        column: ColumnViewTypeIcon,
        row: RowViewTypeIcon,
    };

    const currentViewColor = {
        true: '#1F2125',
        false: '#FFFFFF',
    };

    return (
        <div className="flex items-center gap-0.5">
            {viewTypes.map((viewItem) => {
                const isCurrentView = view === viewItem;
                return (
                    <button
                        key={viewItem}
                        className={viewSwitcherClassName}
                        style={{ backgroundColor: currentViewColor[isCurrentView] }}
                        onClick={() => setView(viewItem)}
                    >
                        <SvgIcon
                            color={currentViewColor[!isCurrentView]}
                            icon={viewIcons[viewItem]}
                            size="medium"
                        />
                    </button>
                );
            })}
        </div>
    );
};

export default ViewSwitcher;
