import React, { useState } from 'react';

import { API } from 'constants';
import operateClient from '../../../services/operate-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { useSwrRequestKeysToMutateFromContext } from '../../../hooks/useSwrRequestKeysToMutateFromContext';
import { mutateThreadsDataAfterChangingThreadStatus } from '../../../helpers/threadsUtils';

import Alert from '../../../design-system/Alert/Alert';
import Modal from '../../../design-system/Modal/Modal';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const ConfirmStatusChangeModal = ({ threadId, threadName, mutate, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const {
        requestKeysToMutate: { threads: requestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const { data } = await operateClient.patch(`${API.ROUTES.operate.thread}${threadId}/`, {
                status: 'success',
            });
            mutate(data);

            mutateThreadsDataAfterChangingThreadStatus(requestKeysToMutate, threadId, data.status);

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({
                message: defaultErrorMessage,
                statusCode: e.response?.status,
            });
        }
    };

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title="Complete Thread?" outlined />
            <div className="py-5 px-5 sm:px-6 md:px-8 flex flex-col gap-5 md:gap-6">
                <p className="font-body text-body-regular-m text-neutral-500">
                    You are about to complete{' '}
                    <span className="font-body-bold text-body-boldr-m">{threadName}</span>, are you
                    sure you’d like to continue? This will change the status but nothing else.
                </p>

                <div className="flex items-center gap-2 justify-between">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="md"
                        text="Yes, Complete"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleSubmit}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default ConfirmStatusChangeModal;
