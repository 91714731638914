import React, { useState } from 'react';

import client from '../../../services/library-api';
import * as API from '../../../constants/api';

import { TAB } from '../../../constants/jobsAndProjects';
import { SORT_TYPES } from '../../../constants/sort';

import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import FilterDropdown from '../FilterDropdown/FilterDropdown';

const filters = {
    [TAB.jobs]: ['status', 'agent'],
    [TAB.projects]: ['status', 'playbook'],
};

const FilterBlock = ({ activeTab, parsedParams, isMobile, resetPageState }) => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const selectedFiltersCount = filters[activeTab].reduce(
        (acc, filter) => acc + (parsedParams?.[filter]?.length || 0),
        0
    );

    const formatResponseToOptions = (results) => results?.map(({ id, name }) => ({ id, name }));

    const agentOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.process,
        searchParams: { ordering: SORT_TYPES.alphabetical },
        formatResponseToOptions,
    });

    const playbookOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.playbook,
        searchParams: { ordering: SORT_TYPES.alphabetical },
        formatResponseToOptions,
    });
    const closeDropdown = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsDropdownOpened(false);
    };

    const dropdownOptionsHookResponse =
        activeTab === TAB.jobs ? agentOptionsHookResponse : playbookOptionsHookResponse;

    return (
        <div className="min-[810px]:relative flex gap-1 items-center">
            <button
                className="border-0 outline-0 bg-none text-[14px] font-bold underline underline-offset-[3px]"
                onClick={() => setIsDropdownOpened(true)}
            >
                Filter
            </button>
            {!!selectedFiltersCount && (
                <div className="bg-neutral-500 min-w-[10px] h-[10px] px-0.5 rounded-full font-body-medium text-[8px] text-white flex items-center justify-center mt-0.5">
                    {selectedFiltersCount}
                </div>
            )}

            {isDropdownOpened && (
                <FilterDropdown
                    activeTab={activeTab}
                    parsedParams={parsedParams}
                    paginatedOptionsHookResponse={dropdownOptionsHookResponse}
                    handleClose={closeDropdown}
                    isMobile={isMobile}
                    resetPageState={resetPageState}
                />
            )}
        </div>
    );
};

export default FilterBlock;
