import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/library-api';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';
import { useFormState } from '../../../../hooks/useFormState';

import Alert from '../../../../design-system/Alert/Alert';
import { Button, Input, TextArea } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const ProcessSettingsBlock = ({ processDetail, setProcessDetail }) => {
    const { id: processId, name, description } = processDetail;
    const {
        formData,
        fieldErrorMessages,
        setFieldErrorMessages,
        handleInputChange,
        changedFields,
        resetChangedFields,
    } = useFormState({ name, description }, true);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSave = async () => {
        const areEmptyFields = !formData.name || !formData.description;
        if (areEmptyFields) {
            setFieldErrorMessages({
                name: !formData.name ? emptyFieldErrorMessage : null,
                description: !formData.description ? emptyFieldErrorMessage : null,
            });
            return;
        }

        try {
            setIsLoading(true);

            const requestBody = changedFields.reduce(
                (acc, field) => ({ ...acc, [field]: formData[field] }),
                {}
            );

            const { data } = await client.patch(
                `${API.ROUTES.library.process}${processId}/`,
                requestBody
            );
            setProcessDetail(data);
            resetChangedFields();
            setIsLoading(false);
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
            setIsLoading(false);
        }
    };

    return (
        <div className="p-5 sm:p-6 rounded-2xl bg-white">
            <div className="flex flex-col gap-5 items-start w-full max-w-[500px]">
                <p className="font-heading-bold text-heading-bold-s text-black">Agent Settings</p>
                <Input
                    size="xs"
                    name="name"
                    value={formData.name}
                    label="Process Name"
                    isRequired
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                />
                <TextArea
                    name="description"
                    value={formData.description}
                    label="Process description"
                    isRequired
                    state={fieldErrorMessages.description ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.description}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                />
                <Button
                    type="primary"
                    size="xs"
                    text="Save Changes"
                    state={isLoading ? 'loading' : changedFields.length ? 'default' : 'disabled'}
                    onClick={handleSave}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default ProcessSettingsBlock;
