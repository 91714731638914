import React, { memo, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/assistant-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import Alert from '../../../design-system/Alert/Alert';
import ArrowLeftSLineIcon from '../../../design-system/Icons/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from '../../../design-system/Icons/ArrowRightSLineIcon';
import Loading from '../../../components/Loading';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const AssistantMessageVersionSwitcher = ({
    currentVersionId,
    versions: _versions,
    setChatMessages,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const versionsRef = useRef(_versions);
    const versions = versionsRef.current || [];
    const versionsCount = versions.length;

    const currentVersionNumber = useMemo(
        () => versions.findIndex((version) => version === currentVersionId) + 1,
        [currentVersionId, versions]
    );

    const isPrevButtonDisabled = currentVersionNumber === 1 || isLoading;
    const isNextButtonDisabled = currentVersionNumber === versionsCount || isLoading;

    const switchVersion = async (versionId) => {
        try {
            setIsLoading(true);
            const { data } = await client.patch(
                `${API.ROUTES.assistant.chatMessageVersion}${versionId}/`,
                { is_default: true }
            );
            setChatMessages((prevMessages) =>
                prevMessages.map((message) => (message.id === data.id ? data : message))
            );
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const handlePrevClick = async () => {
        const prevVersionIndex = currentVersionNumber - 2;
        const prevVersionId = versions[prevVersionIndex];

        if (prevVersionId) {
            await switchVersion(prevVersionId);
        }
    };

    const handleNextClick = async () => {
        const nextVersionId = versions[currentVersionNumber];

        if (nextVersionId) {
            await switchVersion(nextVersionId);
        }
    };

    const labelClassName = classNames('font-body text-body-regular-xs leading-[18px]', {
        'text-transparent lg:text-neutral-400': isLoading,
        'text-neutral-400': !isLoading,
    });

    return (
        <div className="relative flex items-center">
            <button disabled={isPrevButtonDisabled} onClick={handlePrevClick}>
                <ArrowLeftSLineIcon
                    width={16}
                    height={16}
                    color={isPrevButtonDisabled ? '#CFD6E5' : '#33373D'}
                />
            </button>

            <p className={labelClassName}>
                {currentVersionNumber}/{versionsCount}
            </p>

            <button disabled={isNextButtonDisabled} onClick={handleNextClick}>
                <ArrowRightSLineIcon
                    width={16}
                    height={16}
                    color={isNextButtonDisabled ? '#CFD6E5' : '#33373D'}
                />
            </button>

            {isLoading && (
                <div className="absolute top-1/2 transform translate-y-[-50%] left-1/2 translate-x-[-50%] lg:left-[calc(100%+8px)]">
                    <Loading withText={false} size="extraSmall" />
                </div>
            )}

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default memo(AssistantMessageVersionSwitcher);
