export const STATUS = {
    pending: 'pending',
    started: 'started',
    success: 'success',
    failure: 'failure',
    canceled: 'canceled',
    archived: 'archived',
};

export const STATUS_LABEL = {
    [STATUS.pending]: 'Pending',
    [STATUS.started]: 'In Progress',
    [STATUS.success]: 'Completed',
    [STATUS.failure]: 'Error',
    [STATUS.canceled]: 'Canceled',
    [STATUS.archived]: 'Archived',
};

export const STATUS_BADGE_COLOR = {
    [STATUS.pending]: 'neutral',
    [STATUS.started]: 'peach',
    [STATUS.success]: 'lime',
    [STATUS.failure]: 'red',
    [STATUS.canceled]: 'neutral',
    [STATUS.archived]: 'neutral',
};

export const STATUS_HEX_COLOR = {
    [STATUS.pending]: '#CFD6E5',
    [STATUS.started]: '#F2AA85',
    [STATUS.success]: '#D9E56E',
    [STATUS.failure]: '#E95B69',
    [STATUS.canceled]: '#CFD6E5',
    [STATUS.archived]: '#CFD6E5',
};

export const statusBadge = Object.entries(STATUS)
    .map(([status]) => {
        return [status, { text: STATUS_LABEL[status], color: STATUS_BADGE_COLOR[status] }];
    })
    .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
    }, {});
