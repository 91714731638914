import React from 'react';
import FormFieldWrapper from '../../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import FileInput from '../../../../../design-system/FileInput/FileInput';
import { Badge, Button, Input, TextArea } from '../../../../../design-system';

const UpdateDatasetForm = ({
    datasetDetail,
    inputsData,
    formHandlers,
    handleUpdateDataset,
    formSubmissionState,
    onClose,
}) => {
    const { inputKeys, outputKeys } = datasetDetail;
    const { handleInputChange, fieldErrorMessages } = formHandlers;

    return (
        <>
            <div className="flex flex-col gap-4">
                <Input
                    size="sm"
                    name="name"
                    value={inputsData.name}
                    label="Dataset Name"
                    isRequired
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                    placeholder="Update the dataset name"
                    onChange={(e) => handleInputChange('name', e.target.value)}
                />

                <TextArea
                    name="description"
                    value={inputsData.description}
                    label="Dataset Description"
                    isRequired
                    state={fieldErrorMessages.description ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.description}
                    placeholder="Update the dataset description"
                    onChange={(e) => handleInputChange('description', e.target.value)}
                />

                <FormFieldWrapper label="Input Keys" isRequired>
                    {!!inputKeys?.length && (
                        <div className="flex items-center gap-2 flex-wrap">
                            {inputKeys.map((key) => (
                                <Badge key={key} text={key} color="neutral" />
                            ))}
                        </div>
                    )}
                    {!inputKeys?.length && (
                        <p className="font-body text-body-regular-s text-neutral-500 italic mb-[-8px]">
                            None
                        </p>
                    )}
                </FormFieldWrapper>

                <FormFieldWrapper label="Output Keys" isRequired>
                    {!!outputKeys?.length && (
                        <div className="flex items-center gap-2 flex-wrap">
                            {outputKeys.map((key) => (
                                <Badge key={key} text={key} color="neutral" />
                            ))}
                        </div>
                    )}
                    {!outputKeys?.length && (
                        <p className="font-body text-body-regular-s text-neutral-500 italic mb-[-8px]">
                            None
                        </p>
                    )}
                </FormFieldWrapper>

                <FormFieldWrapper
                    label="Dataset CSV file"
                    isRequired
                    state={fieldErrorMessages.dataset_file ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.dataset_file}
                    tipText="Note - this will replace all current data in the dataset"
                >
                    <FileInput
                        file={inputsData.dataset_file}
                        handleFile={(file) => handleInputChange('dataset_file', file)}
                        formatsArray={['.csv']}
                        placeholder="Drag & drop a file to upload or click to browse"
                    />
                </FormFieldWrapper>
            </div>

            <div className="flex items-center justify-between">
                <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                <Button
                    type="secondary"
                    size="sm"
                    text="Update"
                    state={formSubmissionState === 'loading' ? 'loading' : 'default'}
                    onClick={handleUpdateDataset}
                />
            </div>
        </>
    );
};

export default UpdateDatasetForm;
