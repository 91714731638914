import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../../services/organization-api';

import { USER_ROLE } from '../../../../constants/organization';

import Alert from '../../../../design-system/Alert/Alert';
import { defaultErrorMessage } from '../../../../constants/errorMessages';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const OPTIONS = [
    { text: 'Admin', value: USER_ROLE.admin },
    { text: 'Member', value: USER_ROLE.member },
    { text: 'No access', value: null },
];

const SelectUserRolePopup = ({ email, organizationMembership, updateClientData, onClose }) => {
    const [searchParams] = useSearchParams();
    const clientId = searchParams.get('client');

    const [isOptionLoading, setIsOptionLoading] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const changeRole = async (role, index) => {
        if (role === (organizationMembership?.role || null)) {
            onClose();
            return;
        }

        try {
            setIsOptionLoading(index);

            if (!organizationMembership) {
                await createRole(role);
            }

            if (organizationMembership && !role) {
                await deleteRole();
            }

            if (organizationMembership && role) {
                await editRole(role);
            }
            onClose();
        } catch (error) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
            setIsOptionLoading(null);
        }
    };

    async function createRole(role) {
        const { data } = await client.post(`${API.ROUTES.organization.organizationMembership}`, {
            email,
            role,
            organization: clientId,
        });
        const membership = { id: data.id, role: data.role };
        updateClientData({ clientId, email, membership });
    }

    async function editRole(role) {
        const { data } = await client.patch(
            `${API.ROUTES.organization.organizationMembership}${organizationMembership.id}/`,
            { role, organization: clientId }
        );
        const membership = { id: data.id, role: data.role };
        updateClientData({ clientId, email, membership });
    }

    async function deleteRole() {
        await client.delete(
            `${API.ROUTES.organization.organizationMembership}${organizationMembership.id}/`
        );
        updateClientData({ clientId, email, membership: null });
    }

    return (
        <div className="absolute top-[110%] right-0 z-50">
            <ListOptionGroup
                fixedWidth={160}
                listOptions={
                    <>
                        {OPTIONS.map(({ text, value }, index) => (
                            <ListOption
                                key={text}
                                text={text}
                                isLoading={isOptionLoading === index}
                                onClick={() => changeRole(value, index)}
                            />
                        ))}
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default SelectUserRolePopup;
