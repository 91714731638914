import React, { useState } from 'react';

import { Button } from '../../../../design-system';
import { AddLineIcon } from '../../../../design-system/Icons';
import TaskOutputsList from '../TaskOutputsList/TaskOutputsList';
import CreateOutputModal from '../CreateOutputModal/CreateOutputModal';
import DeleteTaskOutputConfirmModal from '../DeleteTaskOutputConfirmModal/DeleteTaskOutputConfirmModal';

const CodeTaskOutputs = ({ outputs, setOutputs, hasEditAccess = false, taskId }) => {
    const [actionModal, setActionModal] = useState({ action: null, outputId: null });

    const closeActionModal = () => setActionModal({ action: null });

    return (
        <div className="flex flex-col gap-4">
            {!!outputs?.length && (
                <TaskOutputsList outputs={outputs} setActionModal={setActionModal} />
            )}

            {hasEditAccess && (
                <div>
                    <Button
                        type="ghost"
                        size="xs"
                        text="Add New Output Variable Output"
                        leadingIcon={AddLineIcon}
                        state={hasEditAccess ? 'default' : 'disabled'}
                        onClick={() => setActionModal({ action: 'create' })}
                    />
                </div>
            )}

            {actionModal.action === 'create' && (
                <CreateOutputModal
                    outputs={outputs}
                    setOutputs={setOutputs}
                    taskId={taskId}
                    onClose={closeActionModal}
                />
            )}

            {actionModal.action === 'delete' && (
                <DeleteTaskOutputConfirmModal
                    outputs={outputs}
                    setOutputs={setOutputs}
                    taskId={taskId}
                    outputId={actionModal.outputId}
                    onClose={closeActionModal}
                />
            )}
        </div>
    );
};

export default CodeTaskOutputs;
