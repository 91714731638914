import axios from 'axios';

axios.defaults.withCredentials = true;

// function to get the auth token from local storage
const getAuthToken = () => {
    return localStorage.getItem('authKey');
};

let baseUrl;
if (process.env.NODE_ENV === 'production') {
    baseUrl = '/api/v1/';
} else {
    baseUrl = 'http://127.0.0.1:8000/api/v1/';
}

// create client constant with axios and auth token
const externalClient = axios.create({
    baseURL: baseUrl,
});

// Using request interceptor to set the auth token before each request (so that it loads for every request)
externalClient.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
});

export default externalClient;