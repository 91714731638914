import { createContext, useState } from 'react';

export const NavigationRestrictionContext = createContext();

export const NavigationRestrictionProvider = ({ children }) => {
    const [isNavigationRestricted, setIsNavigationRestricted] = useState(false);
    const [promptMessage, setPromptMessage] = useState(null);

    return (
        <NavigationRestrictionContext.Provider
            value={{
                isNavigationRestricted,
                setIsNavigationRestricted,
                promptMessage,
                setPromptMessage,
            }}
        >
            {children}
        </NavigationRestrictionContext.Provider>
    );
};
