import { Link } from 'react-router-dom';
import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import LogoIcon from 'design-system/Icons/LogoIcon';
import { useViewType } from '../../hooks/useViewType';
import { VIEW_TYPES_REDIRECT_PATH } from '../../constants/viewTypes';

function Logo({ color }) {
    const { viewType } = useViewType();

    return (
        <Link
            to={VIEW_TYPES_REDIRECT_PATH[viewType]}
            className="w-13 h-[52px] flex justify-center items-center"
        >
            <SvgIcon color={color || '#D9E56E'} size="extraLarge" icon={LogoIcon} />
        </Link>
    );
}

export default Logo;
