import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { parseSearchParams } from '../../helpers/parseSearchSortPageSearchParams';

import { API } from 'constants';
import client from '../../services/assistant-api';
import { SORT_TYPES } from '../../constants/sort';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import useCustomPagination from '../../hooks/useCustomPagination';
import { useDefaultSearchParams } from '../../hooks/useDefaultSearchParams';
import { useSwrPageRequestKeysManagingWithContextSaving } from '../../hooks/useSwrPageRequestKeysManagingWithContextSaving';

import { getGeneralRequestQueryParams } from '../../helpers/getGeneralRequestQueryParams';

import BotsPageHeader from './BotsPageHeader/BotsPageHeader';
import BotsPaginatedContainer from './BotsPaginatedContainer/BotsPaginatedContainer';
import BotActionModal from './BotActionModal/BotActionModal';

const BotsPage = () => {
    const [searchParams] = useSearchParams();
    const parsedParams = parseSearchParams(searchParams);

    const page = parsedParams.page || 1;

    const [isCreateBotModalOpen, setIsCreateBotModalOpen] = useState(false);

    const requestSearchParams = getGeneralRequestQueryParams(parsedParams, SORT_TYPES.alphabetical);

    const botsHookResponse = useCustomPagination({
        pageIndex: page - 1,
        searchParams: requestSearchParams,
        client,
        route: API.ROUTES.assistant.customBot,
        autoFetchNextPage: true,
    });
    const { key, nextPageKey } = botsHookResponse;

    const { requestKeysToMutate } = useSwrPageRequestKeysManagingWithContextSaving({
        pageAccessor: 'bots',
        key,
        nextPageKey,
    });

    useDefaultSearchParams({
        checkPage: true,
        checkSort: true,
        defaultSortType: SORT_TYPES.alphabetical,
    });

    useDocumentTitle('Bots');

    return (
        <div className="page-position max-w-full bg-neutral-50 p-4 xs:p-5 sm:p-6 lg:p-8 flex flex-col gap-5 overflow-y-auto">
            <BotsPageHeader setIsCreateBotModalOpen={setIsCreateBotModalOpen} />

            <BotsPaginatedContainer
                page={page}
                botsHookResponse={botsHookResponse}
                requestKeysToMutate={requestKeysToMutate}
                setIsCreateBotModalOpen={setIsCreateBotModalOpen}
            />

            {isCreateBotModalOpen && (
                <BotActionModal mode="create" onClose={() => setIsCreateBotModalOpen(false)} />
            )}
        </div>
    );
};

export default BotsPage;
