import React from 'react';
import PropTypes from 'prop-types';

ColorPicker.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    selected: PropTypes.string,
    handleSelect: PropTypes.func,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
};
function ColorPicker({ options, selected, handleSelect, label, isRequired = false }) {
    return (
        <div className="flex flex-col gap-2">
            {label && (
                <p className="font-body text-body-bold-s text-neutral-500">
                    {label} {isRequired && <sup className="text-red-500 leading-1">*</sup>}
                </p>
            )}
            <div className="flex flex-wrap gap-1 items-center">
                {options.map((color) => (
                    <div
                        key={color}
                        className={`w-[28px] min-w-[28px] h-[28px] rounded-[50%] flex justify-center items-center shadow-inset-2 transition-all ${
                            selected === color ? 'shadow-neutral-300' : 'shadow-transparent'
                        }`}
                        onClick={() => handleSelect(color)}
                    >
                        <div
                            className="w-[20px] h-[20px] rounded-[50%] cursor-pointer"
                            style={{ backgroundColor: color }}
                        ></div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ColorPicker;
