import { $createLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
    $createTextNode,
    $getSelection,
    $insertNodes,
    $isRangeSelection,
    COMMAND_PRIORITY_HIGH,
    COMMAND_PRIORITY_LOW,
    KEY_ESCAPE_COMMAND,
    KEY_MODIFIER_COMMAND,
    RangeSelection,
} from 'lexical';

import {
    Action,
    activeEditor$,
    Cell,
    createActiveEditorSubscription$,
    currentSelection$,
    getSelectedNode,
    getSelectionRectangle,
    IS_APPLE,
    map,
    filter,
    readOnly$,
    switchFromPreviewToLinkEdit$,
    updateLink$,
    withLatestFrom,
    Signal,
} from '@mdxeditor/editor';

function getLinkNodeInSelection(selection) {
    if (!selection) {
        return null;
    }
    const node = getSelectedNode(selection);
    if (node === null) {
        return null;
    }
    const parent = node.getParent();
    if ($isLinkNode(parent)) {
        return parent;
    } else if ($isLinkNode(node)) {
        return node;
    }
    return null;
}

export const linkDialogState$ = Cell({ type: 'inactive' }, (r) => {
    r.pub(createActiveEditorSubscription$, (editor) => {
        return editor.registerCommand(
            KEY_ESCAPE_COMMAND,
            () => {
                const state = r.getValue(linkDialogState$);
                if (state.type === 'preview') {
                    r.pub(linkDialogState$, { type: 'inactive' });
                    return true;
                }
                return false;
            },
            COMMAND_PRIORITY_LOW
        );
    });

    r.pub(createActiveEditorSubscription$, (editor) => {
        return editor.registerCommand(
            KEY_MODIFIER_COMMAND,
            (event) => {
                if (
                    event.key === 'k' &&
                    (IS_APPLE ? event.metaKey : event.ctrlKey) &&
                    !r.getValue(readOnly$)
                ) {
                    const selection = $getSelection();
                    // we open the dialog if there's an actual selection
                    // or if the cursor is inside a link
                    if ($isRangeSelection(selection)) {
                        r.pub(openLinkEditDialog$);
                        event.stopPropagation();
                        event.preventDefault();
                        return true;
                    } else {
                        return false;
                    }
                }
                return false;
            },
            COMMAND_PRIORITY_HIGH
        );
    });

    r.link(
        r.pipe(
            switchFromPreviewToLinkEdit$,
            withLatestFrom(linkDialogState$),
            map(([, state]) => {
                if (state.type === 'preview') {
                    return {
                        type: 'edit',
                        initialUrl: state.url,
                        url: state.url,
                        title: state.title,
                        linkNodeKey: state.linkNodeKey,
                        rectangle: state.rectangle,
                    };
                } else {
                    throw new Error('Cannot switch to edit mode when not in preview mode');
                }
            })
        ),
        linkDialogState$
    );

    r.sub(
        r.pipe(updateLink$, withLatestFrom(activeEditor$, linkDialogState$, currentSelection$)),
        ([payload, editor, state, selection]) => {
            const url = payload.url?.trim() ?? '';
            const title = payload.title?.trim() ?? '';

            if (url !== '') {
                if (selection?.isCollapsed()) {
                    const linkContent = title || url;
                    editor?.update(
                        () => {
                            const linkNode = getLinkNodeInSelection(selection);
                            if (!linkNode) {
                                const node = $createLinkNode(url, { title });
                                node.append($createTextNode(linkContent));
                                $insertNodes([node]);
                                node.select();
                            } else {
                                linkNode.setURL(url);
                                linkNode.setTitle(title);
                            }
                        },
                        { discrete: true }
                    );
                } else {
                    editor?.dispatchCommand(TOGGLE_LINK_COMMAND, { url, title });
                }

                r.pub(linkDialogState$, {
                    type: 'preview',
                    linkNodeKey: state.linkNodeKey,
                    rectangle: state.rectangle,
                    title,
                    url,
                });
            } else {
                if (state.type === 'edit' && state.initialUrl !== '') {
                    editor?.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                }
                r.pub(linkDialogState$, {
                    type: 'inactive',
                });
            }
        }
    );

    r.link(
        r.pipe(
            cancelLinkEdit$,
            withLatestFrom(linkDialogState$, activeEditor$),
            map(([, state, editor]) => {
                if (state.type === 'edit') {
                    editor?.focus();
                    if (state.initialUrl === '') {
                        return {
                            type: 'inactive',
                        };
                    } else {
                        return {
                            type: 'preview',
                            url: state.initialUrl,
                            linkNodeKey: state.linkNodeKey,
                            rectangle: state.rectangle,
                        };
                    }
                } else {
                    throw new Error('Cannot cancel edit when not in edit mode');
                }
            })
        ),
        linkDialogState$
    );

    r.link(
        r.pipe(
            closeLinkDialog$,
            withLatestFrom(linkDialogState$, activeEditor$),
            map(([, state]) => {
                if (state.type !== 'inactive') {
                    return {
                        type: 'inactive',
                    };
                }
            })
        ),
        linkDialogState$
    );

    r.link(
        r.pipe(
            r.combine(currentSelection$, onWindowChange$),
            withLatestFrom(activeEditor$, linkDialogState$, readOnly$),
            map(([[selection], activeEditor, _, readOnly]) => {
                if ($isRangeSelection(selection) && activeEditor && !readOnly) {
                    const node = getLinkNodeInSelection(selection);

                    if (node) {
                        return {
                            type: 'preview',
                            url: node.getURL(),
                            linkNodeKey: node.getKey(),
                            title: node.getTitle(),
                            rectangle: getSelectionRectangle(activeEditor),
                        };
                    } else {
                        return { type: 'inactive' };
                    }
                } else {
                    return { type: 'inactive' };
                }
            })
        ),
        linkDialogState$
    );
});

export const cancelLinkEdit$ = Action();

export const closeLinkDialog$ = Action();

export const onWindowChange$ = Signal();

export const openLinkEditDialog$ = Action((r) => {
    r.sub(
        r.pipe(
            openLinkEditDialog$,
            withLatestFrom(currentSelection$, activeEditor$),
            filter(([, selection]) => $isRangeSelection(selection))
        ),
        ([, selection, editor]) => {
            editor?.focus(() => {
                editor.getEditorState().read(() => {
                    const node = getLinkNodeInSelection(selection);
                    const rectangle = getSelectionRectangle(editor);
                    if (node) {
                        r.pub(linkDialogState$, {
                            type: 'edit',
                            initialUrl: node.getURL(),
                            initialTitle: node.getTitle() ?? '',
                            url: node.getURL(),
                            title: node.getTitle() ?? '',
                            linkNodeKey: node.getKey(),
                            rectangle,
                        });
                    } else {
                        r.pub(linkDialogState$, {
                            type: 'edit',
                            initialUrl: '',
                            initialTitle: '',
                            title: '',
                            url: '',
                            linkNodeKey: '',
                            rectangle,
                        });
                    }
                });
            });
        }
    );
});
