import React from 'react';
import classNames from 'classnames';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';

const SettingsSidePanelWrapper = ({ isOpened, setIsOpened, children }) => {
    const { isDesktop } = useResponsiveBreakpoints();

    if (isDesktop) {
        return <div className="w-[280px] min-w-[280px] h-full max-h-full">{children}</div>;
    }

    const closePanel = () => setIsOpened(false);

    const backdropClassName = classNames('fixed inset-y-0 z-[61] transition-colors duration-200', {
        'left-0 sm:left-[68px] right-0 bg-transparent sm:bg-black/50': isOpened,
        'left-0 right-[unset] bg-transparent sm:bg-black/0': !isOpened,
    });

    const panelNotDesktopClassName = classNames(
        'fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 sm:right-[unset] z-[62] w-full sm:w-[280px] transform overflow-x-hidden bg-white transition-all duration-[250ms]',
        {
            'translate-x-0 visible': isOpened,
            'translate-x-[-100%] sm:translate-x-[-280px] invisible': !isOpened,
        }
    );

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            closePanel();
        }
    };

    return (
        <div className={backdropClassName} onClick={handleBackdropClick}>
            <div className={panelNotDesktopClassName}>
                <div className="sm:hidden absolute top-4 right-4 z-[63]">
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={closePanel} />
                </div>

                {children}
            </div>
        </div>
    );
};

export default SettingsSidePanelWrapper;
