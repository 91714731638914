import React, { useMemo, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import {
    checkIfAllRequiredInputsAreFilled,
    checkIsFormDataValid,
    formatInputsToDictionaryFormDataView,
    getStartNewJobFormDataInitialState,
    mergeValuesIntoInputs,
    replaceFilesWithCDNUrls,
} from '../../../../helpers/startNewJobFormUtils';
import { mutateListDataAfterUpdatingItem } from '../../../../helpers/swrMutateUtils';

import Alert from '../../../../design-system/Alert/Alert';
import NewJobForm from '../../../../components/NewJobForm/NewJobForm';
import { Button } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const EntryInputsForm = ({
    schema,
    value: _value,
    baseId,
    entryId,
    hasEditAccess,
    requestKeysToMutate,
    openDeleteModal,
    onClose,
}) => {
    const isSchemaEmpty = !schema?.length;
    const value = _value || {};

    const [formData, setFormData] = useState(() => {
        const inputsWithValues = mergeValuesIntoInputs(schema, value);
        return getStartNewJobFormDataInitialState(inputsWithValues, false);
    });

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const allRequiredInputsAreFilled = useMemo(
        () => checkIfAllRequiredInputsAreFilled(formData || []),
        [formData]
    );

    if (isSchemaEmpty) {
        return (
            <p className="font-body-bold text-body-bold-s text-neutral-500">
                No additional content found for this entry
            </p>
        );
    }

    const handleSave = async () => {
        try {
            const isFormDataValid = checkIsFormDataValid(formData, setFormData);
            if (!isFormDataValid) {
                return;
            }

            setIsLoading(true);

            const formDataWithCDNUrls = await replaceFilesWithCDNUrls(formData);
            const value = formatInputsToDictionaryFormDataView(formDataWithCDNUrls);
            const { data } = await client.patch(
                `${API.ROUTES.knowledge.base}${baseId}/entry/${entryId}/`,
                {
                    value,
                }
            );
            const updatedPart = {
                default_version: data.default_version,
                updated_at: data.updated_at,
            };
            mutateListDataAfterUpdatingItem({
                requestKeysToMutate,
                id: entryId,
                updatedPart,
            });

            setIsLoading(false);
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const submitButtonState = !hasEditAccess
        ? 'disabled'
        : isLoading
        ? 'loading'
        : allRequiredInputsAreFilled
        ? 'default'
        : 'disabled';

    return (
        <>
            <NewJobForm
                formData={formData}
                setFormData={setFormData}
                allowLinkDocs={false}
                containerGap={20}
                isFormDisabled={!hasEditAccess}
            />
            <div className="absolute bottom-8 inset-x-4 sm:inset-x-8 flex items-center justify-between gap-3 pt-3 border-t-1 border-neutral-200 bg-white">
                <button
                    type="button"
                    disabled={!hasEditAccess}
                    onClick={openDeleteModal}
                    className="font-brand font-semibold text-red-500 disabled:text-red-500/50 leading-1 focus:outline-none focus:shadow-none py-3"
                >
                    Delete entry
                </button>

                <Button
                    type="primary"
                    size="md"
                    text="Save changes"
                    state={submitButtonState}
                    onClick={handleSave}
                />
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default EntryInputsForm;
