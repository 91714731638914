import React, { memo } from 'react';
import classNames from 'classnames';

import { DEFAULT_SECTION_NAME } from '../../../constants/playbookBuilder';
import { checkIfViewModeWithEmptyPlaybookContent } from '../../../helpers/playbookBuilderUtils';

const NavigationGuide = ({
    mode,
    playbookContent,
    areSectionsExpandedState,
    onExpandAndScrollComplete = () => {},
    renderType = 'block',
}) => {
    const [_, setAreSectionsExpanded] = areSectionsExpandedState;

    const isViewModeWithEmptyPlaybookContent = checkIfViewModeWithEmptyPlaybookContent(
        mode,
        playbookContent
    );

    const expandSection = (temporaryId) => {
        setAreSectionsExpanded((prevData) => ({ ...prevData, [temporaryId]: true }));
    };

    const expandAndScrollToTargetSection = (temporaryId) => {
        const targetSection = document.getElementById(temporaryId);
        if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
            expandSection(temporaryId);
            onExpandAndScrollComplete();
        }
    };

    const containerClassNames = {
        block: 'bg-neutral-400 rounded-2.5 p-5 md:p-6 flex flex-col gap-4',
        popup: 'flex flex-col gap-4',
    };

    const titleClassName = classNames('font-body uppercase', {
        'text-neutral-200 text-body-regular-xs': renderType === 'block',
        'text-neutral-300 text-body-regular-xs': renderType === 'popup',
    });

    const sectionsListClassName = classNames('flex flex-col', {
        'gap-4': renderType === 'block',
        'gap-1': renderType === 'popup',
    });

    const sectionItemClassName = classNames('font-body underline cursor-pointer', {
        'text-white text-body-regular-s': renderType === 'block',
        'text-neutral-500 text-body-regular-m p-2': renderType === 'popup',
    });

    return (
        <div className={containerClassNames[renderType]}>
            <p className={titleClassName}>Navigation Guide</p>

            {!!playbookContent.length && !isViewModeWithEmptyPlaybookContent && (
                <ul className={sectionsListClassName}>
                    {playbookContent.map((section) => (
                        <li
                            key={section.temporaryId}
                            className={sectionItemClassName}
                            onClick={() => expandAndScrollToTargetSection(section.temporaryId)}
                        >
                            {section.name || DEFAULT_SECTION_NAME}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default memo(NavigationGuide);
