import React, { useState } from 'react';
import Modal from '../../../design-system/Modal/Modal';
import ShareModalHeader from './ShareModalHeader/ShareModalHeader';
import ShareBlock from './ShareBlock/ShareBlock';

export const TABS = {
    share: 'share',
};

const ShareTaskModal = ({ onClose }) => {
    const [activeTab, setActiveTab] = useState(TABS.share);

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Modal onClose={onClose} size="medium" resetPadding={true}>
            <div className="w-full flex flex-col">
                <ShareModalHeader
                    activeTab={activeTab}
                    handleTabSelect={handleTabSelect}
                    onModalClose={onClose}
                />
                <div className="p-4 xs:p-8">{activeTab === TABS.share && <ShareBlock />}</div>
            </div>
        </Modal>
    );
};

export default ShareTaskModal;
