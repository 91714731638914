import React from 'react';

const ArrowDownSLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24">
            <path
                d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ArrowDownSLineIcon;
