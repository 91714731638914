import { formatOutputValue } from './formatOutputValue';

export const updateOutputState = (outputs) => {
    return outputs && outputs.length
        ? outputs.map((output) => ({
              ...output,
              value: formatOutputValue(output.value || ''),
              state: 'disabled',
          }))
        : [];
};
