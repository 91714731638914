import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/library-api';
import { checkIfInputtedJsonIsValid } from '../../../../helpers/settingsFormUtils';
import {
    getFormattedFormValue,
    handleRequestErrors,
    replaceFilesWithCDNUrls,
} from '../../../../helpers/startNewJobFormUtils';

import BlockHeader from '../BlockHeader/BlockHeader';
import PlusCircleIcon from '../../../../design-system/Icons/PlusCircleIcon';
import NewJobForm from '../NewJobForm/NewJobForm';
import FormInputModal from '../FormInputModal/FormInputModal';
import ConfirmVariableDeleteModal from '../ConfirmVariableDeleteModal/ConfirmVariableDeleteModal';
import Alert from '../../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import { Button } from '../../../../design-system';

const FormTabSection = ({ processDetail, isEditable }) => {
    const { id: processId, inputs } = processDetail;

    const [formData, setFormData] = useState(() => {
        // make all inputs disabled if process is not editable
        const updatedInputs = isEditable
            ? inputs
            : inputs?.map((input) => ({ ...input, state: 'disabled' }));
        return [...(updatedInputs || [])]?.sort((a, b) => a.order - b.order);
    });

    const [formInputModal, setFormInputModal] = useState({
        isOpened: false,
        mode: null,
        inputData: null,
    });
    const [confirmDeleteModal, setConfirmDeleteModal] = useState({
        isOpened: false,
        inputData: null,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const checkIsFormDataValid = () => {
        const { areErrorFields, updatedFormData } = formData.reduce(
            (acc, item) => {
                // check if inputted Json is valid
                const invalidJsonError = checkIfInputtedJsonIsValid(acc, item);
                if (invalidJsonError) {
                    return invalidJsonError;
                }
                return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
            },
            { areErrorFields: false, updatedFormData: [] }
        );

        if (areErrorFields) {
            setFormData(updatedFormData);
            return false; // means that there is a form data error
        }

        return true; // means that there are no form data errors
    };

    const getRequestBody = async () => {
        const formDataWithCDNUrls = await replaceFilesWithCDNUrls(formData);
        const inputs = formDataWithCDNUrls.map(
            ({ state, errorMessage, temporaryId, value, ...input }) => {
                // add value key if there is a value
                if (value) {
                    const formattedValue = getFormattedFormValue({ ...input, value });
                    return { ...input, value: formattedValue };
                }
                return input;
            }
        );

        return { inputs };
    };

    const clearAllErrorMessages = () => {
        setFormData((prevData) =>
            prevData.map((input) => ({ ...input, state: 'default', errorMessage: null }))
        );
    };

    const handleSaveChanges = async () => {
        try {
            clearAllErrorMessages();

            const isFormDataValid = checkIsFormDataValid(formData, setFormData);
            if (!isFormDataValid) {
                return;
            }

            setIsLoading(true);

            const requestBody = await getRequestBody();
            await client.patch(`${API.ROUTES.library.process}${processId}/`, requestBody);

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            const response = e.response?.data;
            if (typeof response?.error === 'string' && response?.error?.includes('the same key')) {
                setErrorAlert({ message: response?.error });
            } else {
                handleRequestErrors({ error: e, setErrorAlert, formData, setFormData });
            }
        }
    };

    return (
        <div className="bg-white p-5 sm:p-8 lg:pr-[40px] lg:pl-1.5 rounded-2xl w-full max-w-[800px] flex flex-col gap-7 mx-auto">
            <div className="lg:pl-[34px]">
                <BlockHeader
                    title="New Job Form"
                    description={
                        isEditable
                            ? 'Add a placeholder to set a default value. Drag and drop inputs to reorder.'
                            : 'This is a preview of the New Job form.'
                    }
                    buttonText="Add Input"
                    buttonType="secondary"
                    buttonLeadingIcon={PlusCircleIcon}
                    onButtonClick={() => setFormInputModal({ isOpened: true, mode: 'create' })}
                    isEditable={isEditable}
                />
            </div>

            <NewJobForm
                formData={formData}
                setFormData={setFormData}
                setFormInputModal={setFormInputModal}
                setConfirmDeleteModal={setConfirmDeleteModal}
                isEditable={isEditable}
            />

            <div className="py-4 lg:pl-[34px]">
                <div className="flex justify-end pt-4 border-t-1 border-neutral-200">
                    <Button
                        size="sm"
                        type="secondary"
                        text="Save Changes"
                        state={!isEditable ? 'disabled' : isLoading ? 'loading' : 'default'}
                        onClick={handleSaveChanges}
                    />
                </div>
            </div>

            {formInputModal.isOpened && (
                <FormInputModal
                    inputData={formInputModal.inputData}
                    mode={formInputModal.mode}
                    inputs={formData}
                    setInputs={setFormData}
                    onClose={() => setFormInputModal({ isOpened: false })}
                />
            )}
            {confirmDeleteModal.isOpened && (
                <ConfirmVariableDeleteModal
                    variableType="input"
                    variableData={confirmDeleteModal.inputData}
                    setVariables={setFormData}
                    onClose={() => setConfirmDeleteModal({ isOpened: false })}
                />
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={5000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default FormTabSection;
