import React from 'react';
import { TAB } from '../../../constants/jobsAndProjects';
import { handleOpenStartNewJobModal } from '../../../helpers/handleOpenStartNewJobModal';

import { FlashlightFillIcon, FlowChartIcon } from '../../../design-system/Icons';
import EmptyView from '../../../design-system/EmptyView/EmptyView';

const EmptyIndex = ({ activeTab, dataHookResponse, parsedParams }) => {
    const { total, data, loading } = dataHookResponse;

    const getEmptyStateMessageBasedOnFilters = () => {
        const noResults = total === 0 && !data?.length;

        if (noResults && parsedParams.search) {
            return `We couldn't find any ${activeTab} matching your search for ${parsedParams.search}. Please
                    try a different keyword.`;
        }
        if (noResults && parsedParams.agents && parsedParams.status && activeTab === TAB.jobs) {
            return "We couldn't find any jobs matching your selected agents and status. Please try to select a different agents or statuses.";
        }
        if (noResults && parsedParams.agents && activeTab === TAB.jobs) {
            return "We couldn't find any jobs matching your selected agents. Please try to select a different agents.";
        }
        if (
            noResults &&
            parsedParams.playbook &&
            parsedParams.status &&
            activeTab === TAB.projects
        ) {
            return "We couldn't find any projects matching your selected playbooks and statuses. Please try to select a different playbooks or statuses.";
        }
        if (noResults && parsedParams.projects && activeTab === TAB.projects) {
            return "We couldn't find any projects matching your selected playbooks. Please try to select a different playbooks.";
        }
        if (noResults && parsedParams.status) {
            return `We couldn't find any ${activeTab} matching your selected status. Please try to select a different statuses.`;
        }
        return null;
    };

    const emptyMessageBasedOnFilters = getEmptyStateMessageBasedOnFilters();
    const noResultsWithoutFilters =
        !emptyMessageBasedOnFilters && total === 0 && !data?.length && !loading;

    return (
        <>
            {emptyMessageBasedOnFilters && (
                <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[600px]">
                    {emptyMessageBasedOnFilters}
                </p>
            )}
            {noResultsWithoutFilters && activeTab === TAB.jobs && (
                <EmptyView
                    icon={FlashlightFillIcon}
                    mainText="You don’t have any Jobs yet."
                    subtext="Try creating one by clicking “New Job”."
                    buttonText="New Job"
                    handleButtonClick={handleOpenStartNewJobModal}
                    buttonLeadingIcon={FlashlightFillIcon}
                    buttonHelperText="⌘J"
                />
            )}

            {noResultsWithoutFilters && activeTab === TAB.projects && (
                <div className="py-[80px] mx-auto flex-grow px-4">
                    <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full mb-4 mx-auto">
                        <FlowChartIcon width={32} height={32} color="#5E6470" />
                    </div>
                    <div className="flex flex-col items-center gap-1 text-black mb-4">
                        <p className="font-heading-bold text-heading-bold-m text-center max-w-[420px]">
                            You don’t have any Projects yet.
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default EmptyIndex;
