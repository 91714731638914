import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import { SORT_TYPES } from '../../../constants/sort';
import { ARCHIVED_FOLDER_OR_DOC_DATA_LS_KEY } from '../../../constants/docs';

import useCustomPagination from '../../../hooks/useCustomPagination';
import { parseSearchParams } from '../../../helpers/parseSearchSortPageSearchParams';
import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useDefaultSearchParams } from '../../../hooks/useDefaultSearchParams';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { useSwrPageRequestKeysManagingWithContextSaving } from '../../../hooks/useSwrPageRequestKeysManagingWithContextSaving';

import DocsPaginatedContainer from '../DocsPaginatedContainer/DocsPaginatedContainer';
import FolderBreadcrumbs from '../FolderBreadcrumbs/FolderBreadcrumbs';
import DocsPageSearch from '../DocsPageSearch/DocsPageSearch';
import DocsPageHeader from '../DocsPageHeader/DocsPageHeader';
import AddNewMultiActionButton from '../AddNewMultiActionButton/AddNewMultiActionButton';

const FolderAndDocumentIndex = () => {
    const { folderId } = useParams();

    const [searchParams] = useSearchParams();
    const parsedParams = parseSearchParams(searchParams);

    const page = parsedParams.page || 1;

    const requestSearchParams = { sort: SORT_TYPES.alphabeticalByLabel };
    if (folderId) {
        requestSearchParams.parent = folderId;
    }
    if (parsedParams.search) {
        requestSearchParams.query = parsedParams.search;
    }

    const docsHookResponse = useCustomPagination({
        pageIndex: page - 1,
        searchParams: requestSearchParams,
        client: client,
        route: API.ROUTES.knowledge.folderDocument,
        autoFetchNextPage: true,
    });
    const { key, nextPageKey } = docsHookResponse;

    const { requestKeysToMutate } = useSwrPageRequestKeysManagingWithContextSaving({
        pageAccessor: 'folderDocs',
        key,
        nextPageKey,
    });

    const { setCurrentRequestKeyToMutate } = useDocsPageContext();

    useEffect(() => {
        if (key) {
            setCurrentRequestKeyToMutate(key);
        }
    }, [key]);

    const { isDesktop, isTablet } = useResponsiveBreakpoints();

    useDefaultSearchParams();

    useEffect(() => {
        return () => {
            localStorage.removeItem(ARCHIVED_FOLDER_OR_DOC_DATA_LS_KEY);
        };
    }, []);

    return (
        <div className="w-full max-w-full bg-neutral-50 pt-4 pb-[76px] px-5 sm:px-6 sm:pt-6 md:p-6 lg:p-8 flex flex-col gap-5 overflow-y-auto">
            <div className="flex flex-col gap-2">
                {!isDesktop && <DocsPageHeader isDesktop={isDesktop} />}

                <div className="flex items-center justify-between gap-4">
                    <DocsPageSearch folderId={folderId} />

                    {isTablet && (
                        <div className="min-w-fit max-w-fit">
                            <AddNewMultiActionButton />
                        </div>
                    )}
                </div>

                <FolderBreadcrumbs folderId={folderId} />
            </div>

            <DocsPaginatedContainer
                page={page}
                docsHookResponse={docsHookResponse}
                searchQuery={parsedParams.search}
                requestKeysToMutate={requestKeysToMutate}
            />
        </div>
    );
};

export default FolderAndDocumentIndex;
