import React from 'react';

const ArrowLeftSLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M7.21861 8.00047L10.5185 11.3003L9.57567 12.2431L5.33301 8.00047L9.57567 3.75781L10.5185 4.70062L7.21861 8.00047Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ArrowLeftSLineIcon;
