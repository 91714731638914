import React from 'react';

const H2Icon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <path
                d="M2.66927 2.66699V7.33366H7.33594V2.66699H8.66927V13.3337H7.33594V8.66699H2.66927V13.3337H1.33594V2.66699H2.66927ZM12.3359 5.33366C13.7167 5.33366 14.8359 6.45295 14.8359 7.83366C14.8359 8.40526 14.6441 8.93206 14.3213 9.35313L14.2227 9.47379L12.0255 12.0003H14.6693V13.3337H10.0026L10.0021 12.2963L13.2164 8.59906C13.3947 8.39419 13.5026 8.12653 13.5026 7.83366C13.5026 7.18933 12.9803 6.66699 12.3359 6.66699C11.7238 6.66699 11.2218 7.13839 11.1731 7.73799L11.1693 7.83366H9.83594C9.83594 6.45295 10.9552 5.33366 12.3359 5.33366Z"
                fill={props.color}
            />
        </svg>
    );
};

export default H2Icon;
