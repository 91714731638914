import React, { useState } from 'react';
import { Button, ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import UserSharedLineIcon from '../../../design-system/Icons/UserSharedLineIcon';
import ShareAiFeedbackModal from '../../../components/ShareAIFeedbackModal/ShareAIFeedbackModal';
import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import DiscussLineIcon from '../../../design-system/Icons/DiscussLineIcon';

const MobileVersionAdditionalControls = ({ message, handleCloseExpandedMessageDetailView }) => {
    const assignedFrom = message?.assigned_from;
    const isShareFeedbackButtonDisplayed = message.feedback_allowed;

    const [isShareAIFeedbackModalOpened, setIsShareAIFeedbackModalOpened] = useState(false);
    const [successAlert, setSuccessAlert] = useState(null);

    const [showToolTip, setShowToolTip] = useState(false);

    return (
        <div
            className={`min-[1160px]:hidden w-full flex px-4 pt-3 pb-2 bg-white border-b-1 border-neutral-200 ${
                assignedFrom || isShareFeedbackButtonDisplayed ? 'justify-between' : 'justify-end'
            }`}
        >
            <div className="flex items-center gap-2">
                {assignedFrom && (
                    <ToolTip
                        text={
                            <>
                                Reassigned from <strong>{assignedFrom}</strong>
                            </>
                        }
                        position="top-left"
                        shift={'3px'}
                        isShown={showToolTip}
                    >
                        <div
                            onMouseEnter={() => setShowToolTip(true)}
                            onMouseLeave={() => setShowToolTip(false)}
                        >
                            <ButtonIcon
                                type="link"
                                size="sm"
                                icon={UserSharedLineIcon}
                                onClick={() => {}}
                            />
                        </div>
                    </ToolTip>
                )}

                {isShareFeedbackButtonDisplayed && (
                    <Button
                        type="link"
                        size="sm"
                        text="Share AI Feedback"
                        leadingIcon={DiscussLineIcon}
                        onClick={() => setIsShareAIFeedbackModalOpened(true)}
                    />
                )}
            </div>

            <ButtonIcon
                type="link"
                size="sm"
                icon={CloseLineIcon}
                onClick={handleCloseExpandedMessageDetailView}
            />

            {isShareAIFeedbackModalOpened && (
                <ShareAiFeedbackModal
                    target="inbox_message"
                    messageId={message.id}
                    setAlert={setSuccessAlert}
                    onClose={() => setIsShareAIFeedbackModalOpened(false)}
                />
            )}

            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </div>
    );
};

export default MobileVersionAdditionalControls;
