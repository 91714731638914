import axios from 'axios';

// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// if environment is production, use the production API
let baseUrl;
if (process.env.NODE_ENV === 'production') {
    // TODO: replace with production API URL
    baseUrl = '/api/user/';
} else {
    baseUrl = 'http://127.0.0.1:8000/api/user/';
}

// create client constant with axios
const client = axios.create({
    baseURL: baseUrl,
});

export default client;
