import React from 'react';
import PropTypes from 'prop-types';
import client from 'services/library-api';
import { API } from 'constants';
import PaginationFooter from 'pages/LibraryPage/PaginationFooter/PaginationFooter';
import { DEFAUlT_LIMIT } from 'hooks/usePagination';
import { LIBRARY_SORT_TYPES, LIBRARY_VIEW_TYPES } from 'constants/library';
import Loading from 'components/Loading';
import TaskIndexPage from 'pages/LibraryPage/TaskIndexPage/TaskIndexPage';
import SharedTaskIndexPage from '../SharedTaskIndexPage/SharedTaskIndexPage';
import useCustomPagination from '../../../hooks/useCustomPagination';
import { getLibraryRequestQueryParams } from '../../../helpers/getLibraryRequestQueryParams';

export default function PaginatedContainer({
    libraryType,
    pageIndex,
    parsedSearchParams,
    handleNextPressed,
    handlePreviousPressed,
    filteredCollectionId,
    libraryView,
    collections,
    setCollections,
}) {
    const { count, total, loading } = useCustomPagination({
        pageIndex,
        client,
        searchParams: getLibraryRequestQueryParams(parsedSearchParams),
        route:
            // libraryType === LIBRARY_VIEW_TYPES.personal
            //     ? filteredCollectionId
            //         ? `${API.ROUTES.library.collection}${filteredCollectionId}/tasks/`
            //         : API.ROUTES.library.task
            //     : API.ROUTES.library.sharedTasks,
            filteredCollectionId
                ? `${API.ROUTES.library.collection}${filteredCollectionId}/tasks/`
                : API.ROUTES.library.task,
    });

    if (loading && libraryType !== LIBRARY_VIEW_TYPES.shared) {
        return (
            <div className="flex-grow flex items-center justify-center pb-[40px]">
                <Loading text="" />
            </div>
        );
    }

    const start = pageIndex === 0 ? 1 : pageIndex * DEFAUlT_LIMIT + 1;
    const end = total - start < count ? total : start + count - 1;
    const hasData = total > 0;

    const Page = libraryType === LIBRARY_VIEW_TYPES.personal ? TaskIndexPage : SharedTaskIndexPage;

    return (
        <main className="bg-paper min-h-screen mt-5">
            <Page
                pageIndex={pageIndex}
                libraryType={libraryType}
                libraryView={libraryView}
                collections={collections}
                setCollections={setCollections}
            />
            <div style={{ display: 'none' }}>
                <Page
                    pageIndex={pageIndex + 1}
                    libraryType={libraryType}
                    libraryView={libraryView}
                    collections={collections}
                    setCollections={setCollections}
                />
            </div>
            {hasData && libraryType !== LIBRARY_VIEW_TYPES.shared && (
                <div className="flex flex-col">
                    <PaginationFooter
                        start={start}
                        end={end}
                        total={total}
                        onNextPressed={handleNextPressed}
                        onPreviousPressed={handlePreviousPressed}
                    />
                </div>
            )}
        </main>
    );
}

PaginatedContainer.propTypes = {
    libraryType: PropTypes.oneOf(Object.values(LIBRARY_VIEW_TYPES)).isRequired,
    pageIndex: PropTypes.number.isRequired,
    sortType: PropTypes.oneOf(Object.values(LIBRARY_SORT_TYPES)).isRequired,
    handleNextPressed: PropTypes.func.isRequired,
    handlePreviousPressed: PropTypes.func.isRequired,
};
