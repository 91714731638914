import React from 'react';
import CollapsableContainer from '../../../components/CollapsableContainer/CollapsableContainer';
import SettingsForm from '../../CustomizeGoalPage/SettingsForm/SettingsForm';
import Loading from '../../../components/Loading';

const GoalSettingsBlock = ({
    isExpanded,
    toggleExpand,
    isBlockOpenable,
    formData,
    setUnitTestSettingsFormData,
}) => {
    const { settings } = formData.goalSettings;
    const setGoalSettingsFormData = (cb) => {
        setUnitTestSettingsFormData((prevData) => ({
            ...prevData,
            settings: cb(prevData.settings),
        }));
    };

    return (
        <CollapsableContainer
            title="GOAL SETTINGS"
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
            isDisabled={!isBlockOpenable}
        >
            <>
                {settings ? (
                    <SettingsForm
                        formData={settings}
                        setFormData={setGoalSettingsFormData}
                        containerGap={16}
                    />
                ) : (
                    <Loading withText={false} />
                )}
            </>
        </CollapsableContainer>
    );
};

export default GoalSettingsBlock;
