import React, { useState } from 'react';
import classNames from 'classnames';

import * as API from '../../../constants/api';
import client from '../../../services/knowledge-api';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import { TOOL_TYPE } from '../../../constants/assistant';
import { SORT_TYPES } from '../../../constants/sort';

import PlayProcessIcon from '../../../design-system/Icons/PlayProcessIcon';
import SelectDocModal from '../SelectDocModal/SelectDocModal';
import ChatInputDiviBotHeader from '../ChatInputDiviBotHeader/ChatInputDiviBotHeader';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import SelectPromptBlock from '../SelectPromptBlock/SelectPromptBlock';
import ChatInputWrapper from '../ChatInputWrapper/ChatInputWrapper';
import ArchiveLineIcon from '../../../design-system/Icons/ArchiveLineIcon';
import { Button, ButtonIcon } from '../../../design-system';

const selectedToolPlaceholder = {
    [TOOL_TYPE.query_playbooks]: 'Start chatting with your Playbooks',
    [TOOL_TYPE.query_knowledge]: 'Start chatting with your Knowledge Bases',
    [TOOL_TYPE.add_documents]: 'Start chatting or select a different doc',
};
const defaultPlaceholder = 'Start chatting with DiviBot, select a skill, or start with a Prompt';

const ChatInputDiviBotView = ({
    message,
    handleMessageChange,
    selectedTools,
    setSelectedTools,
    textareaRef,
    textareaContainerRef,
    commonButtonsGroup,
    handleFocusTextarea,
    handleSendMessageOnEnterPress,
    isMobile,
    isExpandedMode,
    setIsExpandedMode,
    handleAppendPromptToMessage,
    chatInputErrorMessage,
    clearErrorMessage,
}) => {
    const [isSelectDocModalOpened, setIsSelectDocModalOpened] = useState(false);
    const [closeExpandedModeOnOutsideClick, setCloseExpandedModeOnOutsideClick] = useState(true);

    // for now user can select just one tool
    const handleToggleToolSelect = (toolType) => {
        setSelectedTools((prevState) => {
            if (prevState[toolType]) {
                return {};
            }

            return { [toolType]: { details: {} } };
        });

        if (toolType === TOOL_TYPE.add_documents && !selectedTools[TOOL_TYPE.add_documents]) {
            openSelectDocModal();
        }

        if (toolType !== TOOL_TYPE.add_documents) {
            clearErrorMessage();
        }
    };

    const formatResponseToOptions = (results) =>
        results?.map(({ id, label }) => ({
            id,
            label,
        }));

    const docsOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.knowledge.document,
        searchParams: { ordering: SORT_TYPES.alphabeticalByLabel },
        formatResponseToOptions,
    });

    const getToolButtonType = (toolType) => (selectedTools[toolType] ? 'neutral' : 'link');

    const closeSelectDocModal = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        setIsSelectDocModalOpened(false);
    };

    function openSelectDocModal(e) {
        e?.stopPropagation();
        e?.preventDefault();

        setIsSelectDocModalOpened(true);
        setIsExpandedMode(false);
    }

    const selectedToolType = Object.keys(selectedTools || {})[0];
    const placeholder = selectedToolPlaceholder[selectedToolType] || defaultPlaceholder;

    const textareaHeight = textareaRef.current?.clientHeight;

    const selectPromptBlockProps = {
        textareaHeight,
        handleAppendPromptToMessage,
        setCloseExpandedModeOnOutsideClick,
    };

    const bottomButtonsGroup = (
        <div className="px-3 pb-2 pt-1 flex items-center justify-between gap-4">
            {!isMobile && (
                <div className="flex items-center gap-4">
                    <Button
                        type={getToolButtonType(TOOL_TYPE.query_playbooks)}
                        size="xs"
                        text="Ask Playbook"
                        leadingIcon={PlayProcessIcon}
                        onClick={() => handleToggleToolSelect(TOOL_TYPE.query_playbooks)}
                    />
                    <Button
                        type={getToolButtonType(TOOL_TYPE.query_knowledge)}
                        size="xs"
                        text="Ask Knowledge"
                        leadingIcon={ArchiveLineIcon}
                        onClick={() => handleToggleToolSelect(TOOL_TYPE.query_knowledge)}
                    />
                    <Button
                        type={getToolButtonType(TOOL_TYPE.add_documents)}
                        size="xs"
                        text="Add Docs"
                        leadingIcon={FileTextLineIcon}
                        onClick={() => handleToggleToolSelect(TOOL_TYPE.add_documents)}
                    />
                    <SelectPromptBlock {...selectPromptBlockProps} />
                </div>
            )}

            {isMobile && (
                <div className="flex items-center gap-4">
                    <ButtonIcon
                        type={getToolButtonType(TOOL_TYPE.query_playbooks)}
                        size="sm"
                        icon={PlayProcessIcon}
                        onClick={() => handleToggleToolSelect(TOOL_TYPE.query_playbooks)}
                    />
                    <ButtonIcon
                        type={getToolButtonType(TOOL_TYPE.query_knowledge)}
                        size="sm"
                        icon={ArchiveLineIcon}
                        onClick={() => handleToggleToolSelect(TOOL_TYPE.query_knowledge)}
                    />
                    <ButtonIcon
                        type={getToolButtonType(TOOL_TYPE.add_documents)}
                        size="sm"
                        icon={FileTextLineIcon}
                        onClick={() => handleToggleToolSelect(TOOL_TYPE.add_documents)}
                    />
                    <SelectPromptBlock {...selectPromptBlockProps} isMobile />
                </div>
            )}

            {commonButtonsGroup}
        </div>
    );

    const selectedDocumentsData = selectedTools[TOOL_TYPE.add_documents]?.details;
    const areSelectedDocs = !!Object.keys(selectedDocumentsData || {})?.length;

    const chatInputContainerClassName = classNames(
        'flex flex-col rounded-2 shadow-inset-1 relative z-20 bg-white transition-colors',
        {
            'shadow-neutral-200': !chatInputErrorMessage,
            'shadow-red-500': chatInputErrorMessage,
        }
    );

    return (
        <ChatInputWrapper
            message={message}
            handleMessageChange={handleMessageChange}
            placeholder={placeholder}
            chatInputErrorMessage={chatInputErrorMessage}
            bottomButtonsGroup={bottomButtonsGroup}
            isExpandedMode={isExpandedMode}
            setIsExpandedMode={setIsExpandedMode}
            isMobile={isMobile}
            closeExpandedModeOnOutsideClick={closeExpandedModeOnOutsideClick}
        >
            {areSelectedDocs && (
                <ChatInputDiviBotHeader
                    selectedDocumentsData={selectedDocumentsData}
                    isMobile={isMobile}
                    setSelectedTools={setSelectedTools}
                    openSelectDocModal={openSelectDocModal}
                    clearErrorMessage={clearErrorMessage}
                />
            )}

            <div className={chatInputContainerClassName}>
                <div className="cursor-text px-3 py-2.5" onClick={handleFocusTextarea}>
                    <div ref={textareaContainerRef}>
                        <textarea
                            ref={textareaRef}
                            value={message}
                            placeholder={placeholder}
                            rows={1}
                            onChange={handleMessageChange}
                            onKeyDown={handleSendMessageOnEnterPress}
                            className="max-w-full w-full focus:outline-0 resize-none bg-transparent placeholder-neutral-300 font-body text-body-regular-s text-neutral-500"
                        />
                    </div>
                </div>

                {bottomButtonsGroup}

                {isSelectDocModalOpened && (
                    <SelectDocModal
                        docsOptionsHookResponse={docsOptionsHookResponse}
                        selectedDocs={selectedDocumentsData || {}}
                        setSelectedTools={setSelectedTools}
                        clearErrorMessage={clearErrorMessage}
                        onClose={closeSelectDocModal}
                        textareaHeight={textareaHeight}
                    />
                )}
            </div>
        </ChatInputWrapper>
    );
};

export default ChatInputDiviBotView;
