import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as API from '../../../constants/api';
import client from '../../../services/library-api';

import { defaultErrorMessage } from '../../../constants/errorMessages';
import { collectionColorOptions } from '../../../constants/collections';
import { mutateAllCashedRequest } from '../../../helpers/swrMutateUtils';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useSwrRequestKeysToMutateFromContext } from '../../../hooks/useSwrRequestKeysToMutateFromContext';

import Button from '../../../design-system/Button/Button';
import {
    ArrowGoBackLineIcon,
    ErrorWarningLineIcon,
    FlowChartIcon,
} from '../../../design-system/Icons';
import { Input } from '../../../design-system';
import Alert from '../../../design-system/Alert/Alert';
import ColorPicker from '../../../design-system/ColorPicker/ColorPicker';

const CreateNewProcessPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const configureSearchParams = location.state?.configureSearchParams || '';

    const [inputData, setInputData] = useState({
        name: '',
        icon_color: collectionColorOptions[0],
        description: '',
    });
    const [emptyProcessNameError, setEmptyProcessNameError] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });
    const [isLoading, setIsLoading] = useState(false);

    useDocumentTitle('Create Process');

    const {
        requestKeysToMutate: { processes: requestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const handleInputChange = (e) => {
        setInputData((prevData) => ({
            ...prevData,
            name: e.target?.value,
            description: `Process for ${e.target?.value}`,
        }));
        if (emptyProcessNameError) {
            setEmptyProcessNameError(false);
        }
    };

    const handleColorSelect = (color) => {
        setInputData((prevData) => ({ ...prevData, icon_color: color }));
    };

    const handleBackPressed = () => {
        navigate(`/configure${configureSearchParams}`);
    };

    const handleCreateProcess = async () => {
        if (!inputData.name) {
            setEmptyProcessNameError(true);
            return;
        }
        try {
            setIsLoading(true);
            const { data } = await client.post(`${API.ROUTES.library.process}`, inputData);
            setIsLoading(false);
            mutateAllCashedRequest({ requestKeysToMutate });

            if (data.id) {
                navigate(`/configure/process/${data.id}`);
            }
        } catch (e) {
            setErrorAlert({ show: true, statusCode: e.response.status });
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-white w-full flex justify-center h-full px-[14px] pb-[40px] min-h-[calc(100vh-64px)]">
            <div className="w-full max-w-[800px] py-5">
                <div className="mb-[20px]">
                    <Button
                        type="link"
                        size="sm"
                        text="Back to Configure"
                        onClick={handleBackPressed}
                        leadingIcon={ArrowGoBackLineIcon}
                        trailingIcon={FlowChartIcon}
                    />
                </div>
                <div className="flex flex-col gap-[32px]">
                    <h1 className="font-heading-bold text-heading-bold-s text-black">
                        Create New Process
                    </h1>
                    <Input
                        size="sm"
                        name="name"
                        value={inputData.name}
                        label="Process Name"
                        isRequired
                        placeholder="Brand Guideline Audit"
                        onChange={handleInputChange}
                        state={emptyProcessNameError ? 'error' : 'default'}
                        errorMessage={'Please fill in this field'}
                    />
                    <div>
                        <p className="font-body text-body-bold-s text-neutral-500 mb-2">
                            Process Badge Color
                            <sup className="text-red-500 font-тщкьфд leading-1 font-medium ml-0.5">
                                *
                            </sup>
                        </p>
                        <ColorPicker
                            options={collectionColorOptions}
                            selected={inputData.icon_color}
                            handleSelect={handleColorSelect}
                        />
                    </div>
                    <div>
                        <Button
                            type="primary"
                            size="sm"
                            text="Create Process"
                            state={isLoading ? 'loading' : 'default'}
                            trailingIcon={FlowChartIcon}
                            onClick={handleCreateProcess}
                        />
                    </div>
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </div>
    );
};

export default CreateNewProcessPage;
