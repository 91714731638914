import React from 'react';

import UsersIcon from '../../design-system/Icons/UsersIcon';

const TitleBlock = ({ title, withSharedIcon = false }) => {
    return (
        <h1 className="font-heading-bold text-heading-bold-l text-black">
            {title}
            {withSharedIcon && (
                <>
                    <span className="inline-block pl-3"></span>
                    <UsersIcon
                        width={24}
                        height={24}
                        color="#000000"
                        style={{ display: 'inline' }}
                    />
                </>
            )}
        </h1>
    );
};

export default TitleBlock;
