import React, { useState } from 'react';

import {
    IS_BOLD,
    IS_ITALIC,
    IS_UNDERLINE,
    usePublisher,
    applyFormat$,
    IS_STRIKETHROUGH,
} from '@mdxeditor/editor';

import BoldIcon from '../../../Icons/BoldIcon';
import ItalicIcon from '../../../Icons/ItalicIcon';
import UnderlineIcon from '../../../Icons/UnderlineIcon';
import StrikethroughIcon from '../../../Icons/StrikethroughIcon';
import { ListOptionGroup, SvgIcon } from '../../../index';
import { ArrowDownSLineIcon } from '../../../Icons';

const data = [
    { format: IS_BOLD, format_name: 'bold', label: 'Bold', Icon: BoldIcon },
    {
        format: IS_ITALIC,
        format_name: 'italic',
        label: 'Italic',
        Icon: ItalicIcon,
    },
    {
        format: IS_UNDERLINE,
        format_name: 'underline',
        label: 'Underline',
        Icon: UnderlineIcon,
    },
    {
        format: IS_STRIKETHROUGH,
        format_name: 'strikethrough',
        label: 'Strikethrough',
        Icon: StrikethroughIcon,
    },
];

const ToolbarBoldItalicUnderlineStrikethrough = ({ isMobile = false }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const applyFormat = usePublisher(applyFormat$);

    const onClose = (e) => {
        e?.stopPropagation();
        setIsPopupOpen(false);
    };

    if (isMobile) {
        return (
            <div className="relative">
                <button
                    className="flex p-[6px] hover:bg-neutral-50"
                    onClick={() => setIsPopupOpen(true)}
                >
                    <SvgIcon color="#1F2125" icon={BoldIcon} size="medium" />
                    <SvgIcon color="#1F2125" icon={ArrowDownSLineIcon} size="medium" />
                </button>
                {isPopupOpen && (
                    <div className="absolute -left-1 z-30 top-[110%]">
                        <ListOptionGroup
                            additionalStyles="!p-2"
                            fixedWidth={170}
                            listOptions={
                                <>
                                    {data.map(({ format, format_name, label, Icon }) => (
                                        <button
                                            key={format}
                                            onClick={() => {
                                                applyFormat(format_name);
                                                onClose();
                                            }}
                                            className="flex items-center gap-2 px-2 py-[5px] hover:bg-neutral-50 w-full rounded-2"
                                        >
                                            <SvgIcon color="#1F2125" icon={Icon} size="medium" />
                                            <p className="text-body-regular-xs text-neutral-500 pt-[1px]">
                                                {label}
                                            </p>
                                        </button>
                                    ))}
                                </>
                            }
                            handleClose={onClose}
                        />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div>
            {data.map(({ format, format_name, label, Icon }) => (
                <button
                    className="p-[6px] hover:bg-neutral-50 rounded-2"
                    key={format}
                    onClick={() => applyFormat(format_name)}
                >
                    <SvgIcon color="#1F2125" icon={Icon} size="medium" />
                </button>
            ))}
        </div>
    );
};

export default ToolbarBoldItalicUnderlineStrikethrough;
