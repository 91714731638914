import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';

import {
    docItemGridColsStyles,
    nestedGridContainerStyles,
    updatedByColStyles,
} from '../DocsColumnHeader/DocsColumnHeader';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { handleExpandSidePanelFolder } from '../../../helpers/docsSidePanelUtils';
import { CONTENT_TYPE, CONTENT_TYPE_ICON } from '../../../constants/docs';

import { ButtonIcon, SvgIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import UserBadge from '../../../design-system/UserBadge/UserBadge';
import Checkbox from '../../../design-system/Checkbox/Checkbox';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import DocCardMoreOptionsPopup from '../DocCardMoreOptionsPopup/DocCardMoreOptionsPopup';
import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';

const FolderOrDocCard = ({
    item,
    isSelected = false,
    setSelectedItems,
    setActionModal,
    requestKeysToMutate,
    currentRequestKeyToMutate,
}) => {
    const { id, label, content_type, last_updated_by } = item;
    const { name, updated_at } = last_updated_by || {};

    const location = useLocation();

    const isFolder = content_type === CONTENT_TYPE.folder;

    const { location: parentLocation, setLocation, setExpandedFolders } = useDocsPageContext();

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);

    const [successAlert, setSuccessAlert] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy, HH:mm:ss');

    const openMoreOptionsPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setIsMoreOptionsPopupOpened(true);
    };

    const handleCheckboxChange = () => {
        setSelectedItems((prevSelectedIds) => {
            const alreadySelected = prevSelectedIds[id];
            const newState = { ...prevSelectedIds };

            if (alreadySelected) {
                delete newState[id];
            } else {
                newState[id] = item;
            }

            return newState;
        });
    };

    const Icon = CONTENT_TYPE_ICON[content_type] || FileTextLineIcon;

    const route = {
        [CONTENT_TYPE.folder]: `/docs/folder/${id}`,
        [CONTENT_TYPE.document]: `/doc/${id}`,
    }[content_type];

    const currentLocation = [...(parentLocation || []), { id, label }];

    const handleFolderClick = (location) => {
        if (!isFolder) {
            return;
        }

        setLocation(location);

        handleExpandSidePanelFolder({
            location: location.map((loc) => loc.id),
            setExpandedFolders,
        });
    };

    return (
        <li className="w-full relative">
            <div className="absolute top-1/2 transform translate-y-[-50%] left-4 z-40">
                <Checkbox isChecked={isSelected} name={label} onChange={handleCheckboxChange} />
            </div>

            <Link
                to={route}
                state={{ from: location }}
                onClick={() => handleFolderClick(currentLocation)}
                className={`py-3 bg-white rounded-2 ${docItemGridColsStyles}`}
            >
                <div className="opacity-0">Checkbox</div>

                <div className={nestedGridContainerStyles}>
                    <div className="max-w-full flex items-center gap-2 sm:gap-3 lg:gap-4">
                        <div className="w-[26px] h-[26px] min-w-[26px] sm:w-[32px] sm:h-[32px] sm:min-w-[32px] rounded-2 flex items-center justify-center bg-purple-100">
                            <SvgIcon icon={Icon} color="#754DCF" size="medium" />
                        </div>
                        <p className="font-body text-body-regular-s text-black flex-grow truncate">
                            {label}
                        </p>
                    </div>

                    <div className="max-w-full font-body text-body-regular-xs text-neutral-500 truncate">
                        <span className="sm:hidden">Last Update </span>
                        {formattedTime}
                    </div>
                </div>

                <div className={updatedByColStyles}>
                    <UserBadge name={name} color="purple" />
                </div>

                <div>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={openMoreOptionsPopup}
                    />
                </div>
            </Link>

            {isMoreOptionsPopupOpened && (
                <DocCardMoreOptionsPopup
                    contentType={content_type}
                    data={{ id, label, location: currentLocation }}
                    setActionModal={setActionModal}
                    setSuccessAlert={setSuccessAlert}
                    setErrorAlert={setErrorAlert}
                    requestKeysToMutate={requestKeysToMutate}
                    currentRequestKeyToMutate={currentRequestKeyToMutate}
                    onNavigateToFolderView={handleFolderClick}
                    parentLocation={parentLocation || []}
                    onClose={() => setIsMoreOptionsPopupOpened(false)}
                />
            )}

            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    description={successAlert.description}
                    position="top-center"
                    autoCloseInMS={5000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </li>
    );
};

export default memo(FolderOrDocCard);
