import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import { useFormState } from '../../../hooks/useFormState';
import { mutateListDataAfterUpdatingItem } from '../../../helpers/swrMutateUtils';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../constants/errorMessages';

import Input from '../../../design-system/Input/Input';
import Modal from '../../../design-system/Modal/Modal';
import Alert from '../../../design-system/Alert/Alert';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import { Button, TextArea } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const BotActionModal = ({
    id,
    onClose,
    mode = 'create',
    botData,
    requestKeysToMutate = [],
    setBotDetail,
}) => {
    const navigate = useNavigate();

    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        {
            name: mode === 'edit' ? botData.name : '',
            description: mode === 'edit' ? botData.description : '',
        }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);

    const handleCreateBot = async () => {
        const { data: bot } = await client.post(API.ROUTES.assistant.customBot, formData);
        navigate(`/bot/${bot.id}`);
    };

    const handleEditBot = async () => {
        const { data: bot } = await client.patch(
            `${API.ROUTES.assistant.customBot}${id}/`,
            formData
        );
        const updatedPart = {
            name: bot.name,
            description: bot.description,
            updated_at: bot.updated_at,
        };
        mutateListDataAfterUpdatingItem({ requestKeysToMutate, id, updatedPart });

        if (setBotDetail) {
            setBotDetail((prevData) => ({ ...prevData, ...updatedPart }));
        }
    };

    const actionHandler = {
        create: handleCreateBot,
        edit: handleEditBot,
    }[mode];

    const handleConfirm = async () => {
        const areEmptyFields = !formData.name || !formData.description;
        if (areEmptyFields) {
            setFieldErrorMessages({
                name: !formData.name ? emptyFieldErrorMessage : null,
                description: !formData.description ? emptyFieldErrorMessage : null,
            });
            return;
        }

        try {
            setIsLoading(true);

            await actionHandler();

            onClose();
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <Modal onClose={onClose} size="medium" resetPadding>
            <ModalHeader
                onClose={onClose}
                title={mode === 'edit' ? 'Edit Bot' : 'Create Bot'}
                outlined
            />

            <div className="py-5 px-8 flex flex-col gap-4">
                <Input
                    size="md"
                    name="name"
                    value={formData.name}
                    label="Bot Name"
                    isRequired
                    placeholder="Name your bot"
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                />

                <TextArea
                    name="description"
                    value={formData.description}
                    label="Bot Description"
                    isRequired
                    state={fieldErrorMessages.description ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.description}
                    placeholder="Add a short description about what this bot does"
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    height="120px"
                />

                <div className="flex items-center gap-3 justify-between">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="md"
                        text={mode === 'edit' ? 'Save' : 'Create'}
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleConfirm}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default BotActionModal;
