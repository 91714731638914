import React from 'react';

import { insertThematicBreak$, usePublisher } from '@mdxeditor/editor';

import { SvgIcon } from '../../../index';
import SeparatorIcon from '../../../Icons/SeparatorIcon';

const ToolbarHr = () => {
    const insertThematicBreak = usePublisher(insertThematicBreak$);
    return (
        <button className="p-[6px] hover:bg-neutral-50 rounded-2" onClick={insertThematicBreak}>
            <SvgIcon color="#1F2125" icon={SeparatorIcon} size="medium" />
        </button>
    );
};

export default ToolbarHr;
