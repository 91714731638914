import React from 'react';

const SubtractLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24">
            <path d="M5 11V13H19V11H5Z" fill={props.color} />
        </svg>
    );
};

export default SubtractLineIcon;
