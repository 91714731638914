import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import operateClient from '../../services/operate-api';

import { THREADS_SORT_TYPES, THREADS_TAB } from '../../constants/threads';
import { capitalizeStr } from '../../helpers/formatOrganizationDataForTable';
import { parseThreadsSearchParams } from '../../helpers/parseThreadsSearchParams';
import { getThreadsRequestQueryParams } from '../../helpers/getThreadsRequestQueryParams';

import useCustomPagination from '../../hooks/useCustomPagination';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useDefaultSearchParams } from '../../hooks/useDefaultSearchParams';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';
import { usePageParamSyncWithDevice } from '../../hooks/usePageParamSyncWithDevice';
import { useSaveInContextRequestKeysWithMultipleAccessors } from '../../hooks/useSaveInContextRequestKeysWithMultipleAccessors';

import DesktopPaginatedContainer from './DesktopPaginatedContainer/DesktopPaginatedContainer';
import MobilePaginatedContainer from './MobilePaginatedContainer/MobilePaginatedContainer';
import ThreadsPageHeader, { tabs } from './ThreadsPageHeader/ThreadsPageHeader';
import ThreadsTopControls from './ThreadsTopControls/ThreadsTopControls';
import ThreadsPageEmptyState from './ThreadsPageEmptyState/ThreadsPageEmptyState';

const route = {
    [THREADS_TAB.threads]: API.ROUTES.operate.thread,
    [THREADS_TAB.executions]: API.ROUTES.operate.execution,
};
const DEFAULT_TAB = THREADS_TAB.threads;
const DEFAULT_TAB_ROUTE = route[DEFAULT_TAB];

const ThreadsPage = () => {
    const [searchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseThreadsSearchParams(searchParams), [searchParams]);

    const activeTab = parsedParams.tab || DEFAULT_TAB;

    const [page, setPage] = useState(1);
    const [shouldSetNewData, setShouldSetNewData] = useState(false); // this state is for mobile version (true when we should not merge new data but replace existing data)

    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 850 });

    useDocumentTitle(capitalizeStr(activeTab));

    const dataHookResponse = useCustomPagination({
        pageIndex: page - 1 || 0,
        searchParams: getThreadsRequestQueryParams({ parsedSearchParams: parsedParams }),
        client: operateClient,
        route: route[activeTab] || DEFAULT_TAB_ROUTE,
    });
    const { data, total, count, loading, hasNextPage, key, nextPageKey } = dataHookResponse;

    const requestKeysToMutate = useSaveInContextRequestKeysWithMultipleAccessors({
        key,
        nextPageKey,
        pageAccessor: activeTab,
        allPageAccessors: tabs,
    });

    useDefaultSearchParams({
        checkPage: false,
        checkSort: true,
        defaultSortType: THREADS_SORT_TYPES.created,
        availableSortTypes: Object.values(THREADS_SORT_TYPES),
        checkTab: true,
        defaultTab: DEFAULT_TAB,
        availableTabs: tabs,
    });

    usePageParamSyncWithDevice({ parsedParams, page, setPage, isMobile });

    const resetPageState = useCallback(() => {
        setPage(1);
        setShouldSetNewData(true);
    }, [setPage, setShouldSetNewData]);

    return (
        <div className="page-position bg-neutral-50 px-4 xs:px-5 pt-4 sm:px-8 sm:pt-8 pb-[40px] overflow-y-auto flex flex-col gap-4 sm:gap-5">
            <ThreadsPageHeader
                activeTab={activeTab}
                isMobile={isMobile}
                resetPageState={resetPageState}
            />

            <ThreadsTopControls
                activeTab={activeTab}
                parsedParams={parsedParams}
                resetPageState={resetPageState}
                isMobile={isMobile}
                loading={loading}
                total={total}
            />

            <ThreadsPageEmptyState
                activeTab={activeTab}
                parsedParams={parsedParams}
                total={total}
                loading={loading}
            />

            {!isMobile && (
                <DesktopPaginatedContainer
                    activeTab={activeTab}
                    page={page}
                    data={data}
                    total={total}
                    count={count}
                    loading={loading}
                    requestKeysToMutate={requestKeysToMutate}
                />
            )}

            <MobilePaginatedContainer
                activeTab={activeTab}
                data={data}
                shouldSetNewData={shouldSetNewData}
                setShouldSetNewData={setShouldSetNewData}
                canLoadMore={hasNextPage}
                setPage={setPage}
                loading={loading}
                total={total}
                requestKeysToMutate={requestKeysToMutate}
            />
        </div>
    );
};

export default ThreadsPage;
