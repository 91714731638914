import React, { memo } from 'react';
import classNames from 'classnames';

const PromptCard = ({ prompt, handlePromptClick, isMobile }) => {
    const { name, description, default_version } = prompt;

    const content = default_version?.messages?.[0]?.content;

    const descriptionClassName = classNames('font-body text-body-regular-xs text-neutral-400', {
        'line-clamp-2': isMobile,
        'line-clamp-1': !isMobile,
    });

    return (
        <div
            className="max-w-full px-3 py-2 rounded-[6px] bg-white hover:bg-neutral-50 transition-colors cursor-pointer"
            onClick={() => handlePromptClick(content)}
        >
            <p className="font-body-bold text-body-bold-s text-black mb-1">{name}</p>
            <p className={descriptionClassName}>{description}</p>
        </div>
    );
};

export default memo(PromptCard);
